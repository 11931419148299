// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class UpdateMessageResponse {
  chatId: string;
  messageId: string;
  traceId: string;
  success: boolean;
}
