<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-banner
[type]="type$ | async"
[dismissable]="false"
[active]="active$ | async"
dataQa="settings-banner"
><p>{{ bannerText$ | async }}</p></cee-banner>
