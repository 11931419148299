// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'merc-app-launcher',
  templateUrl: './app-launcher.component.html',
  styleUrls: ['./app-launcher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppLauncherComponent implements OnInit {
  @Input() applications: Map<string, string>;
  isOpen: boolean = false;

  constructor() {
  }

  ngOnInit() {}
}
