// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AccordionIcon, RadioGroup } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { DispositionRadioSelection } from 'projects/entities/src/lib/domain/models/dispositionSelection';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { AppState, ChatActions, DispositionSelection, FeatureFlags, ProfanityService } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-dispositions',
  templateUrl: './dispositions.component.html',
  styleUrls: ['./dispositions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispositionsComponent extends SubscriberComponent implements OnInit {
  @Input() chatId: string;
  @Input() parentContainer: string;
  @Input() isV2: boolean;
  
  dispositionLabel: string = 'Dispositions';
  formattedDispositionGroups$: Observable<RadioGroup[]>;
  hasHideSummaryFeatureFlag$: Observable<boolean>;
  selectedDispositionId: string = null;
  selectedDispositionCollectionId: string = null;
  dispositionNotes: string;
  showProfanityError: boolean;
  customIcon: AccordionIcon = {
    expanded: 'minus',
    unexpanded: 'plus-circled',
  };

  constructor(
    private store: Store<AppState>,
    private profanityService: ProfanityService,

    ) {
    super();
  }

  ngOnInit() {
    this.formattedDispositionGroups$ = this.store.select(fromChat.getFormattedDispositions);
    this.hasHideSummaryFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.HideSummary));

    this.store.select(fromChat.getSelectedDisposition)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((selected: DispositionSelection) => {
        if (selected) {
          this.selectedDispositionCollectionId = selected.collectionId;
          this.selectedDispositionId = selected.disposition.id;
          this.dispositionNotes = selected.notes;
        }
        else{
          this.selectedDispositionCollectionId = null;
          this.selectedDispositionId = null;
          this.dispositionNotes = '';
        }
    });
  }

  onRadioChange(selection) {
    const selectedDispositionArgs: DispositionRadioSelection = {
      chatId: this.chatId,
      collectionId: selection.collectionValue,
      dispositionId: selection.optionValue,
      notes: this.dispositionNotes,
      parentContainer: this.parentContainer
    };
    this.store.dispatch(ChatActions.SelectedDispositions(selectedDispositionArgs));
  }

  profanityCheckNotes() {
    const illegalWords = this.profanityService.test(this.dispositionNotes);
    if (illegalWords.length > 0) {
      this.showProfanityError = true;
    } else {
      this.showProfanityError = false;
    }
    this.onRadioChange({collectionValue: this.selectedDispositionCollectionId, optionValue: this.selectedDispositionId});
    
  }
}
