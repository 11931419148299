// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatAutomationSettings } from '../domain/models/chatAutomationSettings';
import { ChatInteraction } from '../domain/models/chatInteraction';
import { ChatMessageType, CustomerActivityStatus, DataCollectionState, SenderType } from '../domain/models/enums';
import { SecureDataCollectionHelper } from './secure-data-collection-helper';
export class AbandonedChatAutomationHelper{
  // gets how long the chat should stay at the status until moving to the next
  public static getCustomerActivityStatusDuration(activityStatus: CustomerActivityStatus, warningIndex: number, automationSettings: ChatAutomationSettings): number {
    if (!automationSettings) { return 0; }

    switch (activityStatus) {
      case CustomerActivityStatus.Active:
        return automationSettings.inactive.threshold;
      case CustomerActivityStatus.Inactive:
        if (automationSettings.warnings && automationSettings.warnings.length !== 0) {
          return automationSettings.warnings[0].threshold;
        }
        else {
          return automationSettings.abandonedAutoCloseThreshold;
        }
      case CustomerActivityStatus.InactiveWarning:
        // We have an array of warnings, when we get to the last we move towards auto close
        if (!automationSettings.warnings || automationSettings.warnings.length === 0 ||
          warningIndex === (automationSettings.warnings.length - 1)) {
          return automationSettings.abandonedAutoCloseThreshold;
        }
        else {
          return automationSettings.warnings[warningIndex + 1].threshold;
        }
      case CustomerActivityStatus.InactiveClosing:
        return automationSettings.abandonedAutoCloseMessagingDuration;
      case CustomerActivityStatus.Pinned:
        return automationSettings.pinDuration;
    }
  }

  public static getLastMessageSender(chatMessages: ChatInteraction[]): SenderType {
    if (!chatMessages?.length) { return undefined; }
    const reverseMessages = chatMessages.slice().reverse().filter(m => m.type !== ChatMessageType.SummaryCard);
    const msg = reverseMessages.find(m => m.sender !== SenderType.System);
    return msg ? msg.sender : SenderType.Requester;
  }

  public static isDisabled(customerActivityStatus: CustomerActivityStatus, isItgRunning: boolean, isItgPaused: boolean, dataCollectionState: DataCollectionState){
    // will allow to flow from pinned => active but flowing to other states is not allowed
    return customerActivityStatus !== CustomerActivityStatus.Pinned &&
      (this.isDisabledDueToItg(isItgRunning, isItgPaused) || SecureDataCollectionHelper.isDataCollectionActive(dataCollectionState));
  }

  public static isDisabledDueToItg(isItgRunning: boolean, isItgPaused: boolean): boolean {
    return isItgRunning || isItgPaused;
  }
  
}
