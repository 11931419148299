// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { LoggingFactoryService, RestApiService } from '@cxt-cee-chat/merc-ng-core';
import { HttpHandler, HttpResponse } from '@angular/common/http';
import { COMCAST_BEARER_TOKEN_HANDLER } from './comcast-bearer-token-http-handler';
import { GetAccesstokenResponse } from '../domain/models/authentication/getAccesstokenResponse';
import { GetAccessToken } from '../domain/models/requests/getAccessToken';
import { GetExchangeToken } from '../domain/models/requests/getExchangeToken';
import { RefreshAccessToken } from '../domain/models/requests/refreshAccessToken';

@Injectable({
  providedIn: 'root'
})
export class FixAgentFormAuthApiService extends RestApiService {
  constructor
  (
    @Inject(COMCAST_BEARER_TOKEN_HANDLER) httpHandler: HttpHandler,
    loggingService: LoggingFactoryService
  )
  {
    super(loggingService, httpHandler);
  }

  getAccessToken(model: GetAccessToken): Promise<HttpResponse<GetAccesstokenResponse>> {
    return this.post<GetAccesstokenResponse>('formauth/getaccesstoken', model);
  }

  refreshAccessToken(model: RefreshAccessToken): Promise<HttpResponse<GetAccesstokenResponse>> {
    return this.post<GetAccesstokenResponse>('formauth/refreshaccesstoken', model);
  }

  exchangeToken(model: GetExchangeToken): Promise<HttpResponse<GetAccesstokenResponse>> {
    return this.post<any>('tokenauth/exchange', model);
  }
}
