// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { TimerComponent } from '../../timer/timer.component';

@Component({
  selector: 'merc-availability-timer',
  templateUrl: '../../timer/timer.component.html',
  styleUrls: ['../nav/nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusyTimerComponent extends TimerComponent {}
