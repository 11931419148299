// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { ManagedScript, Script, ScriptGroup, ScriptTree } from './models';
import * as fromScriptGroup from './script-group.reducer';
import * as fromScriptTree from './script-tree.reducer';
import * as fromScript from './script.reducer';
import * as fromScriptsSearch from './scripts-search-selectors';

export const selectScriptState = createFeatureSelector<AppState, fromScript.State>(fromScript.scriptsFeatureKey);
export const selectScriptGroupState = createFeatureSelector<AppState, fromScriptGroup.State>(fromScriptGroup.scriptGroupsFeatureKey);
export const selectScriptTreeState = createFeatureSelector<AppState, fromScriptTree.State>(fromScriptTree.scriptTreesFeatureKey);

export const {
  selectEntities: selectScriptEntities,
  selectAll: selectScripts,
} = fromScript.adapter.getSelectors(selectScriptState);

export const {
  selectEntities: selectScriptGroupEntities,
  selectAll: selectAllScriptGroups,
} = fromScriptGroup.adapter.getSelectors(selectScriptGroupState);

export const {
  selectEntities: selectScriptTreeEntities,
  selectAll: selectScriptTrees,
} = fromScriptTree.adapter.getSelectors(selectScriptTreeState);

export const getScript = (scriptId: string) => createSelector(
  selectScriptEntities,
  (scripts: Dictionary<Script>) => scripts[scriptId]
);

export const getScriptGroup = (scriptGroupId: string) => createSelector(
  selectScriptGroupEntities,
  (scriptGroups: Dictionary<ScriptGroup>) => scriptGroups[scriptGroupId]
);

export const getScriptTree = (scriptTreeId: string) => createSelector(
  selectScriptTreeEntities,
  (scriptTrees: Dictionary<ScriptTree>) => scriptTrees[scriptTreeId]
);

const selectManagedScript = createSelector(
  selectScriptState,
  fromScript.getManagedScript
);

export const selectManageScriptQuery = createSelector(
  selectScriptState,
  fromScript.getManageScriptQuery
);

export const isSearchingManageScripts = createSelector(
  selectManageScriptQuery,
  (query: string) => fromScriptsSearch.isSearchTermValid(query)
);

export const selectManageScriptBannerText = createSelector(
  selectManagedScript,
  (managedScript: ManagedScript) => {
    switch (managedScript?.status) {
      case 'DELETED':
        return 'Response removed.';
      case 'NEW':
        return 'New response added.';
      case 'UPDATED':
        return 'Response updated.';
      case 'NEW HEADER':
        return 'New header added. To add responses drag and drop the responses over the header bar.';
      case 'EDIT HEADER':
        return 'Header name updated.';
      case 'DELETE HEADER':
          return 'The header was deleted.';
      default:
        return '';
    }
  }
);

export const selectCurrentManagedScript = createSelector(
  selectManagedScript,
  (managedScript: ManagedScript) => managedScript?.status ? managedScript : null
);

export const selectScriptToDelete = createSelector(
  selectManagedScript,
  selectScriptEntities,
  (managedScript: ManagedScript, scripts: Dictionary<Script>) =>
    managedScript?.status === 'DELETE' ? scripts[managedScript.id] : null
);

export const selectScriptToEdit = createSelector(
  selectManagedScript,
  selectScriptEntities,
  (managedScript: ManagedScript, scripts: Dictionary<Script>) =>
    managedScript?.status === 'EDIT' ? scripts[managedScript.id] : null
);

export const getScriptStatus = (scriptId: string) => createSelector(
  getScript(scriptId),
  selectManagedScript,
  (script: Script, managedScript: ManagedScript) =>
    script && script.id === managedScript?.id ? managedScript.status : null
);

export const createFilteredScriptGroup =
  ({ scriptGroup, scripts, groupsWithScripts }: { scriptGroup: ScriptGroup, scripts: Script[], groupsWithScripts: ScriptGroup[] }): ScriptGroup => ({
    ...scriptGroup,
    scripts: scriptGroup?.scripts?.filter(id => scripts.some(script => script.id === id)) || [],
    scriptGroups: scriptGroup?.scriptGroups?.filter(id => groupsWithScripts.some(group => group.id === id)) || []
  });

export const createFilteredScriptGroups =
  ({ scriptGroups, scripts, groupsWithScripts }: { scriptGroups: ScriptGroup[], scripts: Script[], groupsWithScripts: ScriptGroup[] }): ScriptGroup[] => {
    const groupParents = scriptGroups.filter(scriptGroup =>
      scriptGroup.scriptGroups.some(id => groupsWithScripts.some(group => group.id === id)) ||
      scriptGroup.scripts.some(id => scripts.some(script => script.id === id))
    );
    return groupParents.map(scriptGroup => createFilteredScriptGroup({ scriptGroup, scripts, groupsWithScripts }));
  };
