// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { UnusedSlotTimes } from '../domain/models/unusedSlotTimes';

export class AgentHelper {
  static formatAgentSummaryTableData(data) {
    const formattedData = [];

    if (data?.businessUnits?.length) {
      data.businessUnits.forEach((group) => {
        const formattedAgentGroup = this.formatAgentSummaryAgentGroups(group.agentGroups);
        const formattedBU = this.formatAgentSummaryBusinessUnits(group, formattedAgentGroup);
  
        formattedData.push(formattedBU);
      });
    }
    
    return formattedData;
  }

  static formatAgentSummaryAgentGroups(agentGroups) {
    const formattedGroups = [];

    if (agentGroups?.length) {
      agentGroups.forEach((group) => {
        const currGroup = {
          displayText: group.name,
          data: [
            group.engagementsInQueue,
          `${group.availableAgents} / ${group.totalAgents}`,
          `${group.busyEngagementSlots} / ${group.availableEngagementSlots} / ${group.totalEngagementSlots}`
          ]
        };
  
        formattedGroups.push(currGroup);
      });
    }

    return formattedGroups;
  }

  static formatAgentSummaryBusinessUnits(bu, agentGroup) {
    const formattedBU = {
      id: bu.id,
      displayText: bu.name,
      expanded: true,
      data: [
        bu.engagementsInQueue,
        `${bu.availableAgents} / ${bu.totalAgents}`,
        `${bu.busyEngagementSlots} / ${bu.availableEngagementSlots} / ${bu.totalEngagementSlots}`
      ],
      subgroups: agentGroup
    };

    return formattedBU;
  }
  
  static getSlotsUnusedElapsedSeconds(currentTime: number , agentUnusedSlotTimes: UnusedSlotTimes){
    if (!agentUnusedSlotTimes) { return []; }    
    const elapsedTime = currentTime - agentUnusedSlotTimes.currentSlotUnavailableStartTime;    
    const slotsUnusedElapsedSeconds = [...agentUnusedSlotTimes.slotsUnusedElapsedSeconds];
    //adding the elapsed time per slot
    for (let slot = 0; slot < agentUnusedSlotTimes.availableSlots; slot++){
      slotsUnusedElapsedSeconds[slot] = slotsUnusedElapsedSeconds[slot] + elapsedTime;
    }
		return slotsUnusedElapsedSeconds;
  }
}