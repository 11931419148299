// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { DriftContent } from './drift-content';
import { GetXaSuggestionsResponse } from './responses/getXaSuggestionsResponse';

export class AddXaSuggestions extends GetXaSuggestionsResponse{
  itgDriftIntents?: DriftContent[];
  latestActiveItgMessageId?: string;
  isNewItgSuggested?: boolean;
  isSuggestionAdded?: boolean;
}
