// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatRequestApiService } from '../services/chat-request-api.service';
import { FixAgentApiService } from '../services/fix-agent-api.service';
import { FixAgentProfileService } from '../services/fix-agent-profile.service';
import { HtmlHelperService } from '../services/html-helper.service';
import { JwtService } from '../services/jwt.service';
import { UserIdentityService } from '../services/user-identity.service';
import { TokenStoreService } from '@cxt-cee-chat/merc-ng-core';
import { AgentStatePersisterService } from '../services/agent-state-persister.service';
import { ProfanityService } from '../services/profanity.service';
import { AppConfigApiService } from '../services/app-config-api.service';
import { ScriptsHelper } from '../domain/scripts/scripts-helper';
import { ScriptsService } from '../services/scripts.service';
import { PageInitializationHelperService } from '../services/page-initialization-helper.service';
import { NpsSurveyService } from '../services/nps-survey.service';
import { PerformanceApiService } from '../services/performance-api.service';
import { QueueChatRequestService } from '../services/queue-chat-request.service';
import { VoiceHubService } from '../services/voice-hub.service';

export const NG_ENTITY_MODULE_PROVIDERS = [
	ChatRequestApiService,
	VoiceHubService,
	FixAgentApiService,
	FixAgentProfileService,
	AppConfigApiService,
	HtmlHelperService,
	JwtService,
	UserIdentityService,
	TokenStoreService,
	AgentStatePersisterService,
	PerformanceApiService,
	ProfanityService,
	ScriptsService,
	ScriptsHelper,
	PageInitializationHelperService,
	NpsSurveyService,
	QueueChatRequestService
];
