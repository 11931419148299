<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-confirmation-modal
  *ngIf="(scriptGroup$ | async) as scriptGroup"
  headerText="Delete Custom Response"
  [active]="scriptGroup"
  (modalClose)="closeModal()"
  (confirmClicked)="deleteScriptGroup(scriptGroup)"
  (cancelClicked)="closeModal()">
    <p>Are you sure you want to delete this custom header?:</p>
    <p class="script-highlight-style" [innerHTML]="scriptGroup?.name | safeHtml"></p>
</cee-confirmation-modal>
