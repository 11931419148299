// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ExceptionInterceptor, LoggingFactoryService, LoggingProvider } from '@cxt-cee-chat/merc-ng-core';
import { Action } from '@ngrx/store';
import { AppState } from 'projects/entities/src/public_api';

export class ReducerMetareducerLogger implements ExceptionInterceptor<AppState>
{
    private _loggerFactory: LoggingFactoryService = null;
    private _loggingProvider: LoggingProvider = null;

    constructor(logger: LoggingFactoryService | LoggingProvider) {
        if (logger instanceof LoggingFactoryService) {
            this._loggerFactory = logger;
        }
        else if (logger instanceof LoggingProvider) {
            this._loggingProvider = logger;
        }
        else {
            throw new Error('logger must implement LoggingFactoryService or LoggingProvider and cannot be null or undefined');
        }
    }

    Intercept(state: AppState, action: Action, error: Error): AppState {
        const logMsg = `Reducer error. Action ${action.type}.`;

        if (this._loggerFactory) {
            this._loggerFactory.logException(error, logMsg);
        }
        else if (this._loggingProvider) {
            this._loggingProvider.logException(error, logMsg);
        }

        return state;
    }
}