// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AppState } from './state';
import * as fromAgent from './agent.reducer';
import * as fromAgentAuth from './auth.reducer';
import * as fromAgentAvailability from './availability.reducer';
import * as fromAgentFacingMetrics from './agent-facing-metrics/agent-facing-metrics.reducer';
import * as fromApp from './app.reducer';
import * as fromChat from './chat/chat.reducer';
import * as fromChatUi from './chat-ui/chat-ui.reducer';
import * as fromConnectAccount from './connect-account.reducer';
import * as fromHubs from './hubs.reducer';
import * as fromProfanity from './profanity.reducer';
import * as fromScriptGroup from './scripts/script-group.reducer';
import * as fromScriptTree from './scripts/script-tree.reducer';
import * as fromScript from './scripts/script.reducer';
import * as fromSettings from './settings/settings.reducer';
import * as fromUi from './ui/ui.reducer';
import * as fromSmartResponses from './smart-responses/smart-responses.reducer';
import * as fromPlaceholders from './placeholders/placeholders.reducer';
import * as fromVoiceUi from './voice/ui/ui.reducer';
import * as fromCall from './voice/call/call.reducer';
import * as fromOneCti from './one-cti/one-cti.reducer';
import { ActionReducerMap } from '@ngrx/store';
import * as fromCxGptResponses from './cxGptResponses/cx-gpt-responses.reducer';
import * as fromAskMeAnything from './ask-me-anything/ask-me-anything.reducer';
import * as fromLanguageTranslation from './language-translation/language-translation.reducer';

export const reducer: ActionReducerMap<AppState> = {
	[fromAgent.featureKey]: fromAgent.reducer,
	[fromAgentAuth.featureKey]: fromAgentAuth.reducer,
	[fromAgentAvailability.featureKey]: fromAgentAvailability.reducer,
	[fromAgentFacingMetrics.featureKey]: fromAgentFacingMetrics.reducer,
	[fromApp.featureKey]: fromApp.reducer,
	[fromChat.featureKey]: fromChat.reducer,
	[fromChatUi.chatUiFeatureKey]: fromChatUi.reducer,
	[fromConnectAccount.featureKey]: fromConnectAccount.reducer,
	[fromHubs.hubsFeatureKey]: fromHubs.reducer,
	[fromScript.scriptsFeatureKey]: fromScript.reducer,
	[fromScriptGroup.scriptGroupsFeatureKey]: fromScriptGroup.reducer,
	[fromScriptTree.scriptTreesFeatureKey]: fromScriptTree.reducer,
	[fromSettings.featureKey]: fromSettings.reducer,
	[fromSmartResponses.featureKey]: fromSmartResponses.reducer,
	[fromPlaceholders.featureKey]: fromPlaceholders.reducer,
	[fromUi.featureKey]: fromUi.reducer,
	[fromVoiceUi.featureKey]: fromVoiceUi.reducer,
	[fromCall.featureKey]: fromCall.reducer,
	profanity: fromProfanity.reducer,
  	[fromOneCti.featureKey]: fromOneCti.reducer,
	[fromCxGptResponses.featureKey]: fromCxGptResponses.reducer,
	[fromAskMeAnything.featureKey]: fromAskMeAnything.reducer,
	[fromLanguageTranslation.featureKey]: fromLanguageTranslation.reducer
};
