// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { ScriptGroup } from './models';
import { Script } from './script.model';
import * as fromScriptTree from './script-tree.reducer';
import * as fromScriptGroup from './script-group.reducer';
import * as fromScript from './script.reducer';

export const addScript = createAction(
  '[Script] Add Script',
  props<{ script: Script, scriptGroup: ScriptGroup, parentScriptGroupId: string }>()
);

export const updateScriptValue = createAction(
  '[Script] Update Script',
  props<{ id: string, scriptGroupId: string, newValue: string, newScriptGroup: ScriptGroup, newScriptGroupParentId: string }>()
);

export const moveScript = createAction(
  '[Script] Move Script',
  props<{ id: string, scriptGroupId: string, newScriptGroupId: string, scriptIds: string[]}>()
);

export const deleteScript = createAction(
  '[Script] Delete Script',
  props<{ id: string, scriptGroupId: string }>()
);

export const deleteScriptConfirmation = createAction(
  '[Script] Delete Script Confirmation',
  props<{ id: string }>()
);

export const deleteScriptConfirmationDismiss = createAction(
  '[Script] Delete Script Confirmation Dismiss'
);

export const manageScriptsClosed = createAction(
  '[Script] Manage Scripts Closed'
);

export const editScript = createAction(
  '[Script] Edit Script',
  props<{ id: string }>()
);

export const createScript = createAction(
  '[Script] Create Script',
  props<{ method: string }>()
);

export const manageScripts = createAction(
  '[Script] Manage Scripts',
  props<{userAction: boolean}>()
);

export const searchManageScripts = createAction(
  '[Mange Scripts] Search ',
  props<{ query: string }>()
);

export const hydrate = createAction('[Script] Hydrate');

export const hydrateSuccess = createAction(
  '[Script] Hydrate Success',
  props<{ scriptState: fromScript.State, scriptGroupState: fromScriptGroup.State, scriptTreeState: fromScriptTree.State }>()
);

export const hydrateFailure = createAction('[Script] Hydrate Failure');
