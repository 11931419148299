// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ProfanityList } from './models/profanityList';
import * as fromAgent from './agent.reducer';
import * as fromAgentAuth from './auth.reducer';
import * as fromAgentAvailability from './availability.reducer';
import * as fromAgentFacingMetrics from './agent-facing-metrics/agent-facing-metrics.reducer';
import * as fromApp from './app.reducer';
import * as fromChat from './chat/chat.reducer';
import * as fromChatUi from './chat-ui/chat-ui.reducer';
import * as fromConnectAccount from './connect-account.reducer';
import * as fromHubs from './hubs.reducer';
import * as fromScriptGroup from './scripts/script-group.reducer';
import * as fromScriptTree from './scripts/script-tree.reducer';
import * as fromScript from './scripts/script.reducer';
import * as fromSettings from './settings/settings.reducer';
import * as fromUi from './ui/ui.reducer';
import * as fromSmartResponses from './smart-responses/smart-responses.reducer';
import * as fromPlaceholders from './placeholders/placeholders.reducer';
import * as fromVoiceUi from './voice/ui/ui.reducer';
import * as fromCall from './voice/call/call.reducer';
import * as fromOneCti from './one-cti/one-cti.reducer';
import * as fromCxGptResponses from './cxGptResponses/cx-gpt-responses.reducer';
import * as fromAskMeAnything from './ask-me-anything/ask-me-anything.reducer';
import * as fromLanguageTranslation from './language-translation/language-translation.reducer';

export interface AppState {
	[fromAgent.featureKey]: fromAgent.State;
	[fromAgentAuth.featureKey]: fromAgentAuth.State;
	[fromAgentAvailability.featureKey]: fromAgentAvailability.State;
	[fromAgentFacingMetrics.featureKey]: fromAgentFacingMetrics.State;
	[fromApp.featureKey]: fromApp.State;
	[fromChat.featureKey]: fromChat.State;
	[fromChatUi.chatUiFeatureKey]: fromChatUi.State;
	[fromConnectAccount.featureKey]: fromConnectAccount.State;
	[fromHubs.hubsFeatureKey]: fromHubs.State;
	[fromScriptTree.scriptTreesFeatureKey]: fromScriptTree.State;
	[fromScriptGroup.scriptGroupsFeatureKey]: fromScriptGroup.State;
	[fromScript.scriptsFeatureKey]: fromScript.State;
	[fromSettings.featureKey]: fromSettings.State;
	[fromUi.featureKey]: fromUi.State;
	profanity: ProfanityList;
	[fromSmartResponses.featureKey]: fromSmartResponses.State;
	[fromPlaceholders.featureKey]: fromPlaceholders.State;
	[fromVoiceUi.featureKey]: fromVoiceUi.State;
	[fromCall.featureKey]: fromCall.State;
  	[fromOneCti.featureKey]: fromOneCti.State;
	[fromCxGptResponses.featureKey]: fromCxGptResponses.State;
	[fromAskMeAnything.featureKey]: fromAskMeAnything.State;
	[fromLanguageTranslation.featureKey]: fromLanguageTranslation.State;
}

export const initialState: AppState = {
	[fromAgent.featureKey]: fromAgent.initialState,
	[fromAgentAuth.featureKey]: fromAgentAuth.initialState,
	[fromAgentAvailability.featureKey]: fromAgentAvailability.initialState,
	[fromAgentFacingMetrics.featureKey]: fromAgentFacingMetrics.initialState,
	[fromApp.featureKey]: fromApp.initialState,
	[fromChat.featureKey]: fromChat.initialState,
	[fromChatUi.chatUiFeatureKey]: fromChatUi.initialState,
	[fromConnectAccount.featureKey]: fromConnectAccount.initialState,
	[fromHubs.hubsFeatureKey]: fromHubs.initialState,
	[fromScriptGroup.scriptGroupsFeatureKey]: fromScriptGroup.initialState,
	[fromScriptTree.scriptTreesFeatureKey]: fromScriptTree.initialState,
	[fromScript.scriptsFeatureKey]: fromScript.initialState,
	[fromSettings.featureKey]: fromSettings.initialState,
	[fromUi.featureKey]: fromUi.initialState,
	profanity: null,
	[fromSmartResponses.featureKey]: fromSmartResponses.initialState,
	[fromPlaceholders.featureKey]: fromPlaceholders.initialState,
	[fromVoiceUi.featureKey]: fromVoiceUi.initialState,
	[fromCall.featureKey]: fromCall.initialState,
  	[fromOneCti.featureKey]: fromOneCti.initialState,
	[fromCxGptResponses.featureKey]: fromCxGptResponses.initialState,
	[fromAskMeAnything.featureKey]: fromAskMeAnything.initialState,
	[fromLanguageTranslation.featureKey]: fromLanguageTranslation.initialState
};
