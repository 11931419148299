// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class AvailabilityOption {
    id: string;
    label: string;
    available: boolean;
    color: string;
    hidden: boolean;

    constructor() {
        this.hidden = false;
    }
}

export class AvailabilityColors {
    static red: string = 'red';
    static green: string = 'green';
}