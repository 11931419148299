// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromCustomResponses, fromScripts, ScriptActions, ScriptGroup } from 'projects/entities/src/lib/domain/scripts';
import { AppState } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import * as scriptGroupActions from 'projects/entities/src/lib/domain/scripts/script-group.actions';
import { CdkDragDrop } from '@angular/cdk/drag-drop';


@Component({
  selector: 'merc-script-group-manage',
  templateUrl: './script-group-manage.component.html',
  styleUrls: ['./script-group-manage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScriptGroupManageComponent implements OnInit {
  @Input() scriptGroupId: string;
  @Input() level: number = 0;
  @Output() manageEditClick: EventEmitter<any> = new EventEmitter<any>();
  public scriptGroup$: Observable<ScriptGroup>;
  public allScriptGroups$: Observable<ScriptGroup>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    const {scriptGroupId} = this;

    this.scriptGroup$ = this.store.select(fromScripts.getScriptGroup(scriptGroupId));
    this.allScriptGroups$ = this.store.select(fromCustomResponses.selectCustomScriptGroupBase);
  }

  onScriptGroupDrop(e: CdkDragDrop<ScriptGroup>) {
    const { scriptGroups, id} = e.container.data ;
    const scriptGroupIds = this._moveItemInArray(scriptGroups, e.previousIndex, e.currentIndex);
    
    this.store.dispatch(scriptGroupActions.moveScriptGroup({parentScriptGroupId: id, scriptGroupIds}));
  }

  onScriptDrop(e: CdkDragDrop<string[]>) {
    let scriptIds = [];
    const id = e.item.data;
    const scriptGroupId = e.previousContainer.id;
    const newScriptGroupId = e.container.id;
    const scripts = e.container.data;

    if (newScriptGroupId === scriptGroupId) {
      scriptIds = this._moveItemInArray(scripts, e.previousIndex, e.currentIndex);
    } else {
      scriptIds = this._insertItemInArray(scripts, id, e.currentIndex);
    }

    this.store.dispatch(ScriptActions.moveScript({id, scriptGroupId, newScriptGroupId, scriptIds}));
  }

  scriptGroupDeleteClicked () {
    const payload = {
      id: this.scriptGroupId
    };

    this.store.dispatch(scriptGroupActions.deleteScriptGroupConfirmation(payload));
  }

  scriptGroupEditClicked () {
    this.emitButtonClick();

    const payload = {
      id: this.scriptGroupId
    };
    this.store.dispatch(scriptGroupActions.editScriptGroup(payload));
  }

  emitButtonClick() {
    this.manageEditClick.emit();
  }

  getConnectedScriptGroups(scriptGroup: ScriptGroup) {
    return [...scriptGroup.scriptGroups, scriptGroup.id];
  }

  private _moveItemInArray(array: string[], previousIndex: number, currentIndex: number) {
    let newArray = [...array];
    const elm = newArray.splice(previousIndex, 1)[0];
    newArray = this._insertItemInArray(newArray, elm, currentIndex);

    return newArray;
  }

  private _insertItemInArray(array: string[], item: string, newIndex: number) {
    const newArray = [...array];
    newArray.splice(newIndex, 0, item);

    return newArray;
  }
}
