// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class Color
{
    id: number;
    name: string;
    hexCode: string;
    metadata?: Object;
}