<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  headerText="Previous Summaries"
  [customModalClass]="'merc-previous-summary-card'"  
  [active]="active$ | async" 
  (modalClose)="closeModal()"
  *ngIf="{
    chatSummary: filteredChatSummaries$ | async
  } as data"
  >
    <ng-container>
      <cee-script-search      
        [searchInput]="(filteredText)"
        [hideScriptOptions]="true"
        placeHolderText="Search Summaries"   
        (scriptSearchChange)="onInputChange($event)">
      </cee-script-search>     
      <div class="merc-previous-summary-history-wrapper"> 
      <div
        *ngFor="let summary of (data.chatSummary | sortBy:'desc':'createDate');">
        <cee-history-card class="merc-previous-summary-history-data" [active]="false" customClass="merc-previous-summary-history-card" (click)="chatSummaryClicked(summary)" [topic]="summary?.customerName"
        [channels]="summary.channel?.type | formatChannels" [conversationDateTime]="getFormattedTime(summary?.createDate, summary?.timeZoneName)">
        </cee-history-card>
      </div>
      <div *ngIf="!data.chatSummary || data.chatSummary?.length == 0" class="merc-previous-summaries-notfound">
        <label>No summaries found.</label>
      </div>
    </div>
    </ng-container>
</cee-modal>