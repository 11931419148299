<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
    selectedActiveChat : selectedActiveChat$ | async,
    defaultTab: defaultTab$ | async,
    hasSmartResponseNotification: hasSmartResponseNotification$ | async,
    hasActivitiesNotification: hasActivitiesNotification$ | async,
    showRecommendedTab: showRecommendedTab$ | async,
    isCustomScriptsEnabled: isCustomScriptsEnabled$ | async,
    placeholders: placeholders$ | async,
    tabSelected: tabSelected$ | async,
    xaSolvesFeatureFlag: xaSolvesFeatureFlag$ | async,
    askMeAnythingFeatureFlag: askMeAnythingFeatureFlag$ | async
  } as data">
<cee-tab-group
  name="chat-details"
  [tabColorId]="data.selectedActiveChat ? data.selectedActiveChat.color.id : ''"
  [activeTabTitle]="data.tabSelected"
  (tabSelected)="onTabClicked($event, data.selectedActiveChat?.chatId, data.tabSelected)"
  [notificationsEnabled]="getEnabledNotifications(data.showRecommendedTab, data.xaSolvesFeatureFlag, data.hasSmartResponseNotification, data.hasActivitiesNotification, data.askMeAnythingFeatureFlag)"
  #tabGroup>
  <cee-tab [title]="tabNames.Recommended" *ngIf="showFeatureTab(tabNames.Recommended, data.defaultTab, data?.selectedActiveChat, data.showRecommendedTab)">
    <merc-convo-recommended
      *ngIf="data?.selectedActiveChat; else emptyRecommended"
      [chatId]="data?.selectedActiveChat?.chatId"
      [placeholders]="data.placeholders"
    ></merc-convo-recommended>
    <ng-template #emptyRecommended>
      <div class="merc-empty-recommended">
        Responses will load once a conversation has begun.
      </div>
    </ng-template>
  </cee-tab>

  <ng-container>
    <cee-dropdown-tab [title]="tabNames.Responses" tooltipWidth="150" tooltipText="Responses options" tooltipDirection="bottom" [options]="scriptsOptions" *ngIf="data.isCustomScriptsEnabled; else noCustomScripts"  (optionClicked)="onScriptOptionClicked($event)">
      <ng-container *ngTemplateOutlet="scriptsDetailItem"></ng-container>
    </cee-dropdown-tab>

    <ng-template #noCustomScripts>
      <cee-tab [title]="tabNames.Responses">
        <ng-container *ngTemplateOutlet="scriptsDetailItem"></ng-container>
      </cee-tab>
    </ng-template>

    <ng-template #scriptsDetailItem>
      <div>
        <merc-convo-scripts
          [chatId]="data?.selectedActiveChat?.chatId"
          [scriptTreeId]="data.selectedActiveChat?.scriptTreeId"
        ></merc-convo-scripts>
      </div>
    </ng-template>
  </ng-container>

  <cee-tab title="Knowledge" *ngIf="data.askMeAnythingFeatureFlag">
    <merc-ask-me-anything-base></merc-ask-me-anything-base>
  </cee-tab>

  <cee-tab title="Activities" *ngIf="data.xaSolvesFeatureFlag && data?.selectedActiveChat">
    <div class="merc-chat-detail-item merc-customer-activities">
      <merc-convo-activities
        [chatId]="data?.selectedActiveChat?.chatId"
      ></merc-convo-activities>
    </div>
  </cee-tab>
</cee-tab-group>
</ng-container>
<merc-details-script-manage-panel></merc-details-script-manage-panel>
