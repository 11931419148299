<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="itgsInProgress$ | async as itgsInProgress">
  <div class="merc-activity-status-banner" *ngFor="let itgInProgress of itgsInProgress">
    <div class="merc-activity-status-banner--heading">
      <svg class="cee-icon cee-icon--md cee-icon--activity-in-prog"
        [class.cee-icon--activity-in-prog-complete]="itgInProgress.state === ItgState.Completed"
        [class.cee-icon--activity-in-prog-error]="isErrorState(itgInProgress.state)"
      >
        <use [attr.href]="'/assets/img/svg-sprite.svg#icon-' + (itgInProgress.state | formatItgState:'icon')"></use>
      </svg>
      <div class="merc-activity-status-banner--heading-text">
        <span class= "merc-activity-status-banner--title">
          {{itgInProgress.state | formatItgState:'description':itgInProgress.title}}
        </span>
        <div *ngIf="getNote(itgInProgress.state) as note"
          class="merc-activity-status-banner--note"
          [class.merc-activity-status-banner--italic]="itgInProgress.state === ItgState.Paused"
        >
          {{ note }}
        </div>
      </div>
    </div>

    <span class="merc-activity-status-actions">
      <div [ngSwitch]="itgInProgress.state">
        <ng-container *ngSwitchCase="ItgState.Running">
            <cee-button (click)="updateItg('pause', itgInProgress.title, itgInProgress.state)" type="icon-filled" size="icon"
              [tooltipText]="'Pause Autopilot'" tooltipWidth="100" tooltipDirection="bottom">
              <span class="cee-accessibly-hidden">Pause Autopilot</span>
              <svg class="cee-icon cee-icon--md">
                <use xlink:href="/assets/img/svg-sprite.svg#icon-pause"></use>
              </svg>
              </cee-button>
        </ng-container>
        <ng-container *ngSwitchCase="ItgState.Paused">
          <cee-button (click)="updateItg('resume', itgInProgress.title, itgInProgress.state)" customClass="cee-btn--secondary" type="icon-filled" size="icon"
            [tooltipText]="'Start Autopilot'" tooltipWidth="100" tooltipDirection="bottom">
            <span class="cee-accessibly-hidden">Start Autopilot</span>
            <svg class="cee-icon cee-icon--md">
              <use xlink:href="/assets/img/svg-sprite.svg#icon-play"></use>
            </svg>
          </cee-button>
        </ng-container>
        <ng-container *ngSwitchDefault>
        </ng-container>
      </div>
      <div *ngIf="getCloseTooltipText(itgInProgress.state) as closeText">
        <cee-button class="merc-close-activity-status" (click)="updateItg('cancel', itgInProgress.title, itgInProgress.state)" type="{{getCloseButtonType(itgInProgress.state)}}" size="icon"
          [tooltipText]="closeText" tooltipWidth="100" tooltipDirection="bottom">
          <span class="cee-accessibly-hidden">{{closeText}}</span>
          <svg class="cee-icon cee-icon--md">
            <use xlink:href="/assets/img/svg-sprite.svg#icon-close"></use>
          </svg>
        </cee-button>
      </div>
    </span>
  </div>
</ng-container>
