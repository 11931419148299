// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { ProfileActions, SettingsActions, UiActions } from '../actions';
import { AgentCustomSettings } from '../models/agentCustomSettings';
import { GroupAutomationSettings } from '../models/group-automation-settings';
import { SessionLockOutSettings } from '../models/sessionLockOutSettings';
import { SurveyConfiguration } from '../models/survey-configuration';
import { QualityCheckConfiguration } from '../models/qualityCheckConfiguration';
import { XaResponsesTitleMapping } from '../models/xa-responses-title-mapping';
import { ChatStartGreetings } from '../models/chat-start-greetings';
import { SchedulerIntent } from '../models/schedulerIntent';
import { ItgDetails } from '../models/itg-details';
import { LanguageTranslationSettings } from '../models/languageTranslationSettings';
import { GroupCustomerHeartbeatThreshold } from '../models/group-customer-heartbeat-threshold';

export const featureKey = 'settings';

export interface State {
  agentCustomSettings: AgentCustomSettings;
  engagementInactiveWarningCountdownDuration: number;
  featureFlags: string[];
  priorEngagementsPageSize: number;
  sessionLockOutSettings: SessionLockOutSettings;
  surveyConfiguration: SurveyConfiguration;
  xaResponsesTitleMapping: XaResponsesTitleMapping[];
  settingsNotificationDismissed: boolean;
  customerHeartbeatThreshold: GroupCustomerHeartbeatThreshold[];
  chatAutomationSettings: GroupAutomationSettings[];
  secureDataCollectionLinkReceivedMessage: string;
  secureDataCollectionCompletedMessage: string;
  disconnectTimeout: number;
  qualityCheckSettings: QualityCheckConfiguration;
  chatStartGreetings: ChatStartGreetings;
  smartResponseSourceResultCount: number;
  smartResponseDisplayCount: number;
  schedulerIntent: SchedulerIntent;
  itgList: ItgDetails[];
  languageTranslationSettings: LanguageTranslationSettings;
  askMeAnythingPromptId: string;
  enhancePromptId: string;
}

export const initialState: State = {
  engagementInactiveWarningCountdownDuration: null,
  agentCustomSettings: null,
  featureFlags: null,
  priorEngagementsPageSize: null,
  sessionLockOutSettings: null,
  surveyConfiguration: null,
  xaResponsesTitleMapping: null,
  settingsNotificationDismissed: false,
  customerHeartbeatThreshold: null,
  chatAutomationSettings: null,
  secureDataCollectionLinkReceivedMessage: null,
  secureDataCollectionCompletedMessage: null,
  disconnectTimeout: null,
  qualityCheckSettings: null,
  chatStartGreetings: null,
  smartResponseSourceResultCount: null,
  smartResponseDisplayCount: null,
  schedulerIntent: null,
  itgList: null,
  languageTranslationSettings: null,
  askMeAnythingPromptId: null,
  enhancePromptId: null
};

export const reducer = createReducer<State>(
  initialState,
  on(
    ProfileActions.Loaded,
    ProfileActions.Updated,
    (state, { payload }): State => {
      return {
        ...state,
        agentCustomSettings: payload
      };
    }
  ),
  on(
    SettingsActions.applicationConfigurationLoaded,
    (state, { payload }): State => {
      const featureFlags: string[] = payload.featureFlags?.map(f => f.key) || [];
      return {
        ...state,
        engagementInactiveWarningCountdownDuration: payload.engagementInactiveWarningCountdownDuration,
        featureFlags,
        priorEngagementsPageSize: payload.priorEngagementsPageSize,
        sessionLockOutSettings: payload.sessionLockOutSettings,
        surveyConfiguration: payload.surveyConfiguration,
        xaResponsesTitleMapping: payload.xaResponsesTitleMapping ?? [],
        customerHeartbeatThreshold: payload.customerHeartbeatThreshold ?? [],
        chatAutomationSettings: payload.chatAutomationSettings ?? [],
        secureDataCollectionCompletedMessage: payload.secureDataCollectionMessages?.completedMessage,
        secureDataCollectionLinkReceivedMessage: payload.secureDataCollectionMessages?.linkReceivedMessage,
        disconnectTimeout: payload.disconnectTimeout,
        qualityCheckSettings: payload.qualityCheckSettings,
        chatStartGreetings: payload.chatStartGreetings,
        smartResponseSourceResultCount: payload.smartResponseSourceResultCount,
        smartResponseDisplayCount: payload.smartResponseDisplayCount,
        schedulerIntent: payload.schedulerIntent,
		    languageTranslationSettings: payload.languageTranslationSettings,
        askMeAnythingPromptId: payload.askMeAnythingPromptId,
		    enhancePromptId: payload.enhancePromptId
      };
    }
  ),
  on(SettingsActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(
    UiActions.ToggleAgentSettingsPanel,
    (state): State => ({
      ...state,
      settingsNotificationDismissed: true
    })
  ),
  on(
    SettingsActions.sendNewFeatureBannerAcknowledgement,
    (state, { featureBannerAcknowledgementTimeStamp }): State => ({
      ...state,
      agentCustomSettings: {
        ...state.agentCustomSettings,
        newFeatureBannerAckTimestamp: featureBannerAcknowledgementTimeStamp.toJSON()
      }
    })
  ),
  on(SettingsActions.ItgListLoaded, (state, {list}): State => ({
    ...state,
    itgList: list
  }))
);
