// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction } from '@ngrx/store';

/**
 * Creates an action with a strongly typed payload property.
 * This is a shim to transition from merc-ng-core DomainActionFactory to standard NgRx actions.
 * @param type the description of the action
 * @returns an action creator for actions with a payload property
 * @deprecated Use NgRx createAction with props instead.
 */
 export function createPayloadAction<T>(type: string) {
	return createAction(
		type,
		(payload: T) => ({payload}),
	);
}

/**
 * Returns the payload from an action with a strongly typed payload property.
 * This is a shim to transition from merc-ng-core DomainActionFactory to standard NgRx actions.
 * @param action an action with a payload property
 * @returns the payload
 * @deprecated Use NgRx createAction with props instead.
 */
export function toPayload<T>(action: {payload: T}): T {
	return action.payload;
}
