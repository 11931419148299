// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCall from './call.reducer';
import * as fromVoiceUi from '../ui/ui.selectors';
import { AppState } from '../../state';
import { Dictionary } from '@ngrx/entity';
import { Call } from './call.model';
import { CallState, CallSummaryDisplay, SessionSummaryState } from '../../models/voice/enums';
import { CustomerInfo } from '../../models/voice/customerInfo';
import { SenderType } from '../../models/enums';
import { FeedContent } from '../../models/voice/feed-content';
import { RecommendedAction } from '../../models/voice/recommended-action';
import { VoiceHelper } from '../../../utils/voice-helper';
import { CallSummary } from '../../models/voice/call-summary';


export const selectCallFeature = createFeatureSelector<AppState, fromCall.State>(
  fromCall.featureKey
);

const {
  selectEntities: selectCallEntities,
  selectAll: selectAllCalls
} = fromCall.adapter.getSelectors(selectCallFeature);

export const getCurrentCallId = createSelector(
  selectCallFeature,
  (state: fromCall.State) => state.currentCallId
);

export const getCalls = createSelector(
  selectAllCalls,
  (calls: Call[]) => calls
);

export const getCall = (callId: string) => createSelector(
  getCalls,
  (calls: Call[]) => calls?.find(c => c.callId === callId)
);

export const getCurrentCall = createSelector(
  getCurrentCallId,
  selectCallEntities,
  (callId: string, calls: Dictionary<Call>) => calls[callId]
);

export const getCallState = createSelector(
  getCurrentCall,
  (call: Call) => call?.callState ?? CallState.Inactive
);

export const getCallStartTimestamp = createSelector(
  getCurrentCall,
  (call: Call) => call?.startTimestamp
);

export const getCallEndTimestamp = createSelector(
  getCurrentCall,
  (call: Call) => call?.endTimestamp
);

export const getCustomerInfo = createSelector(
  getCurrentCall,
  (call: Call) => call?.customerInfo
);

export const getCustomerName = createSelector(
  getCustomerInfo,
  (customerInfo: CustomerInfo) => customerInfo?.name
);

export const getTranscriptMessages = createSelector(
  getCurrentCall,
  (call: Call) => call?.transcriptMessages || []
);

export const getHighlightedMessages = createSelector(
  getCurrentCall,
  (call: Call) => call?.highlightedMessages || []
);

export const getFilteredTranscriptMessages = createSelector(
  getTranscriptMessages,
  fromVoiceUi.getIsShowAgentTranscriptEnabled,
  (transcriptMessages, isShowAgentTranscriptEnabled) => transcriptMessages.filter(m => m.sender === SenderType.Requester || isShowAgentTranscriptEnabled)
);

export const getFeedContent = createSelector(
  getCurrentCall,
  (call: Call) => call?.feedContent
);

export const getTranscriptMessageText = (utteranceSequence: number) => createSelector(
  getTranscriptMessages,
  (transcriptMessages) => transcriptMessages.find(m => m.utteranceSequence === utteranceSequence)?.message
);

export const getCallSummary = createSelector(
  getCurrentCall,
  (call: Call) => call?.callSummary
);

export const getIsAuthenticatedCall = createSelector(
  getCustomerInfo,
  (customerInfo: CustomerInfo) => VoiceHelper.isAuthenticatedCall(customerInfo)
);

export const getRecommendedAction = (id: string) => createSelector(
  getFeedContent,
  (feedContent: FeedContent[]) => (<RecommendedAction[]>feedContent)?.find(m => m.id === id)
);

export const getRecommendedActionByRequestId = (requestId: string) => createSelector(
  getFeedContent,
  (feedContent: FeedContent[]) => (<RecommendedAction[]>feedContent)?.find(m => m.requestId && m.requestId === requestId)
);

export const getQueuedCalls = createSelector(
  getCalls,
  (calls: Call[]) => calls.filter(c => c.callState === CallState.Queued)
);

export const hasQueuedCall = createSelector(
  getQueuedCalls,
  (calls: Call[]) => calls.length !== 0
);

export const getSessionSummaryState = createSelector(
  getCurrentCall,
  hasQueuedCall,
  (call: Call, queuedCall: boolean) => {
    if (queuedCall) {
      return SessionSummaryState.NextSession;
    }
    else if (call) {
      return SessionSummaryState.CloseSession;
    }

    return SessionSummaryState.Default;
  }
);

export const getIsSessionSummaryCollapsed = createSelector(
  getCurrentCall,
  (call: Call) => call?.isSessionSummaryCollapsed ?? true
);

export const getShowSessionSummaryDrawer = createSelector(
  getCurrentCall,
  (call: Call) => call?.showSessionSummaryDrawer ?? false
);

export const getShowSessionSummaryModal = createSelector(
  getCurrentCall,
  (call: Call) => call?.showSessionSummaryModal ?? false
);

export const getCallSummaryInProgressTraceId = createSelector(
  getCurrentCall,
  (call: Call) => call?.callSummaryInProgressTraceId
);

export const getCallSummaryDisplay = (callId: string) => createSelector(
  getCall(callId),
  (call: Call) => call?.callSummaryDisplay ?? CallSummaryDisplay.Panel
);

export const getIsDisplayingLoadedCallSummaryPanel = createSelector(
  getCallSummary,
  getShowSessionSummaryDrawer,
  (callSummary: CallSummary, showSessionSummaryDrawer) => (!!callSummary && !callSummary.error && showSessionSummaryDrawer)
);
