// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { ScriptsContextMenu } from '../../../models/scripts-context-menu';
import { AppState, ScriptClickEventArgs, FixAgentProfileService, ScriptQuickSendType, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { Script, ScriptGroup, ScriptsHelper } from 'projects/entities/src/lib/domain/scripts';
import * as fromScripts from 'projects/entities/src/lib/domain/scripts/scripts-selectors';
import * as fromScriptsSearch from 'projects/entities/src/lib/domain/scripts/scripts-search-selectors';
import * as fromSelectedChatScripts from 'projects/entities/src/lib/domain/scripts/selected-chat-selectors';

@Component({
  selector: 'merc-convo-script-button',
  templateUrl: './script-button.component.html',
  styleUrls: ['./script-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptButtonComponent implements OnInit {
  @Input() chatId: string;
  @Input() scriptId: string;
  @Input() scriptGroup: ScriptGroup;

  scriptValue: string = '';
  isSingleClick: boolean = true;

  public script$: Observable<Script>;
  public searchTerm$: Observable<string>;
  public scriptSentCount$: Observable<number>;
  public isScriptInUse$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private scriptsHelper: ScriptsHelper,
    private profileService: FixAgentProfileService
  ) {}

  ngOnInit() {
    this.script$ = this.store.select(fromScripts.getScript(this.scriptId));

    this.searchTerm$ = this.store.select(fromScriptsSearch.getSearchTerm);
    this.scriptSentCount$ = this.store.select(fromSelectedChatScripts.getScriptSentCount(this.scriptId));
    this.isScriptInUse$ = this.store.select(fromSelectedChatScripts.isScriptInUse(this.scriptId));
  }

  handleScriptClicked(script: any, isInUse: boolean, event: MouseEvent) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.scriptSingleClicked(script, isInUse, event);
      }
    }, 250);
  }

  scriptSingleClicked(script: any, isInUse: boolean, event: MouseEvent) {
    const customSettings = this.profileService.getAgentCustomSettings();
    const quickSend = event.shiftKey && customSettings.scriptQuickSend === ScriptQuickSendType.ShiftClick;
    this.scriptClicked(script, isInUse, quickSend);
  }

  scriptDoubleClicked(script: any, isInUse: boolean, _event: MouseEvent) {
    this.isSingleClick = false;

    const customSettings = this.profileService.getAgentCustomSettings();
    const quickSend = customSettings.scriptQuickSend === ScriptQuickSendType.DoubleClick;
    this.scriptClicked(script, isInUse, quickSend);
  }

  scriptClicked(script: Script, isInUse: boolean, quickSend: boolean) {
    if (!isInUse && this.chatId) {
      this.dispatchScriptClicked(script, quickSend);
    }
  }

  protected getScriptValue(script: Script, searchTerm: string): string {
    return this.scriptsHelper.getHighlightedScriptValue(searchTerm, script);
  }

  private dispatchScriptClicked(script: Script, quickSend: boolean) {
    const { chatId, scriptGroup, scriptId } = this;
    const { value: scriptValue } = script;

    const scriptInUse: ScriptClickEventArgs = {
      scriptId,
      scriptGroupName: scriptGroup.name,
      scriptValue,
      quickSend,
      chatId,
    };
    this.store.dispatch(
      UiActions.ScriptClicked({ scriptInUse })
    );
  }

  showContextMenu(script: Script, event: PointerEvent) {
    event.preventDefault();
    const { clientX, clientY } = event;
    const scriptsContextMenu: ScriptsContextMenu = {
      script,
      clientX,
      clientY,
      open: true
    };
    this.store.dispatch(UiActions.ToggleScriptsContextMenuAction({ scriptsContextMenu }));
  }

}
