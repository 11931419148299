<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-chat-detail-item merc-chat-detail-item--recommended" *ngIf="{
  isDisabled: isDisabled$ | async,
  hideAnimations: hideAnimations$ | async,
  showSuggestions: showSuggestions$ | async,
  suggestions: suggestions$ | async,
  showSmartResponses: showSmartResponses$ | async,
  smartResponsesModel: smartResponsesModel$ | async,
  selectedMessageId: selectedMessageId$ | async,
  bannerVm: bannerVm$ | async,
  color: (color$ | async),
  selectedMessage: (selectedMessage$ | async),
  smartResponseComponentDisplayType: smartResponseComponentDisplayType$ | async,
  smartResponseDisplayCount: smartResponseDisplayCount$ | async,
  smartResponseSplitSearchText: smartResponseSplitSearchText$ | async,
  previousActiveMessage: previousActiveMessage$ | async,
  nextActiveMessage: nextActiveMessage$ | async,
  hasLanguageTranslatorFeatureFlag: hasLanguageTranslatorFeatureFlag$ | async
} as data">
  <cee-banner
    *ngIf="data.bannerVm.visible"
    [type]="data.bannerVm.type"
    ><p class="merc-recommended-banner-text">{{data.bannerVm.text}}</p>
    <cee-button customClass="merc-recommended-banner-button" *ngIf="data.bannerVm.showTryAgain" (buttonClick)="tryAgain(data.selectedMessageId)" type="link">Try again</cee-button>
  </cee-banner>
  <div class="merc-smartagent">
    <div class="merc-smartagent-icon">
      <span>
        <svg class="cee-icon cee-icon--secondary cee-icon-smartagent--{{data.color?.id}}--{{data.selectedMessage?.sender ?? 0}}"
        [ngClass]="{'disabled': data.isDisabled}">
            <use href="/assets/img/svg-sprite.svg#icon-smartagent"></use>
        </svg>
      </span>
    </div>
    <div class="merc-smartagent-message merc-smartagent-message--{{data.color?.id}}--{{data.selectedMessage?.sender ?? 0}}"
    [ngClass]="{'disabled': data.isDisabled}"
      role="button">
      <div class="merc-smartagent-text"
        [ngClass]="{'merc-smartagent-text--disabled': data.isDisabled}"
        [innerHtml]="getDisplayMessage(data.selectedMessage, data.hasLanguageTranslatorFeatureFlag)">
      </div>
    </div>
  </div>
  <div class="merc-smart-response-navigation-buttons">
    <cee-button
    customClass="merc-message-navigation-btn"
    type="link"
    (buttonClick)="onNavigationClick(data.previousActiveMessage, previousButton)"
    tooltipText="Highlight previous message" tooltipId="previous-message" tooltipWidth="150" tooltipDirection="bottom"
    [disabled]="!data.previousActiveMessage || data.isDisabled"
    dataQa="previous-message">
      <svg class="cee-icon merc-message-nav-icon merc-message-nav-icon--previous">
        <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-next'"></use>
      </svg>
      Previous
    </cee-button>
    <svg class="cee-icon merc-message-navigation-buttons-divider" data-qa="message-navigation-buttons-divider">
      <use href="/assets/img/svg-sprite.svg#icon-line"></use>
    </svg>
    <cee-button
    customClass="merc-message-navigation-btn"
    type="link"
    (buttonClick)="onNavigationClick(data.nextActiveMessage, nextButton)"
    tooltipText="Highlight next message" tooltipId="next-message" tooltipWidth="150" tooltipDirection="bottom"
    [disabled]="!data.nextActiveMessage || data.isDisabled"
    dataQa="next-message">
      Next
      <svg class="cee-icon merc-message-nav-icon" data-qa="next-message">
        <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-next'"></use>
      </svg>
    </cee-button>
  </div>
  <div class="merc-convo-recommended-wrapper" (scroll)="onScroll($event)" #scrollWindow>
    <ul *ngIf="data.showSuggestions" class="cee-list-block">
      <ng-container *ngFor="let suggestion of data.suggestions">
        <li>
          <merc-suggestion-button
            x-test-target="suggestion-button"
            [selectedActiveChatId]="chatId"
            [suggestion]="suggestion"
            [disabled]="data.isDisabled"
          >
          </merc-suggestion-button>
        </li>
      </ng-container>
    </ul>
    <div *ngIf="data.showSmartResponses" class="merc-smart-response-block">
      <div [ngSwitch]="data.smartResponseComponentDisplayType">
        <ng-container *ngSwitchCase="smartResponseComponentDisplayType.Notification">
          <merc-smart-response-state-notification x-test-target="smart-response-notification"></merc-smart-response-state-notification>
        </ng-container>
        <ng-container *ngSwitchCase="smartResponseComponentDisplayType.SmartResponseList">
          <div *ngFor="let response of ((data.smartResponsesModel)?.smartResponses | sortBy:'asc':'type':'desc':'confidence' | takeFirst:data.smartResponseDisplayCount) as smartResponses">
            <ng-container *ngIf='response?.entities?.length > 0; else smartResponseButton'>
              <merc-edit-placeholder-tooltip [chatId]="chatId" [placeholderKeys]="response.entities" [disabled]="data.isDisabled">
                <ng-container *ngTemplateOutlet="smartResponseButton"></ng-container>
              </merc-edit-placeholder-tooltip>
            </ng-container>
            <ng-template #smartResponseButton>
              <merc-convo-smart-response-button
                x-test-target="smart-response-list"
                class="merc-smart-response"
                [chatId]="chatId"
                [smartResponse]="response"
                [placeholders]="placeholders"
                [highlightOptions]="getHighlightOptions(response.entities)"
                [searchText]="data.smartResponseSplitSearchText"
                [selectedMessageId]="data.selectedMessageId"
                [disabled]="data.isDisabled"
                [smartResponsesDisplayed]="smartResponses"
              >
              </merc-convo-smart-response-button>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <cee-loading-skeleton class="cee-loading-skeleton-container" [hideAnimation]="shouldHideAnimations(data.hideAnimations, data.smartResponseComponentDisplayType)"></cee-loading-skeleton>
          <cee-loading-skeleton class="cee-loading-skeleton-container" [hideAnimation]="shouldHideAnimations(data.hideAnimations, data.smartResponseComponentDisplayType)"></cee-loading-skeleton>
          <cee-loading-skeleton class="cee-loading-skeleton-container" [hideAnimation]="shouldHideAnimations(data.hideAnimations, data.smartResponseComponentDisplayType)"></cee-loading-skeleton>
        </ng-container>
      </div>
    </div>
  </div>
</div>
