// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CallActions from './call.actions';
import { Call } from './call.model';
import { CallState, CallSummaryDisplay, FeedContentType, RecommendedActionState } from '../../models/voice/enums';
import { RecommendedAction } from '../../models/voice/recommended-action';
import { TranscriptMessage } from '../../models/voice/transcriptMessage';
import { TranscriptMessageResponse } from '../../models/voice/responses/transcript-message-response';
import { FeedContent } from '../../models/voice/feed-content';
import { HighlightedMessage } from '../../models/voice/highlightedMessage';
import { Guid } from 'guid-typescript';
import { VoiceHelper } from '../../../utils/voice-helper';

export const featureKey = 'calls';

export interface State extends EntityState<Call> {
  currentCallId?: string;
}

export const adapter: EntityAdapter<Call> = createEntityAdapter<Call>({
  selectId: (call: Call) => call.callId
});

export const initialState: State = adapter.getInitialState({
  currentCallId: null
});

function doesCurrentCallNotExist(state: State): boolean {
  return !state.currentCallId;
}

function getCurrentCall(state: State): Call {
  return state.entities[state.currentCallId];
}

export const reducer = createReducer(
  initialState,

  on(CallActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),

  on(CallActions.newCallReceived, (state, action) => {
    const matchingCall = state.entities[action.call.callId];
    const isCallInactive = matchingCall?.callState === CallState.Inactive;

    if (matchingCall && !isCallInactive) {
      return state;
    }

    const call: Call = {
      ...action.call,
      ...(isCallInactive && matchingCall),
      callState: CallState.Queued,
      startTimestamp: action.startTimestamp
    };

    if (!state.currentCallId) {
      call.callState = CallState.Active;
      call.startTimestamp = action.startTimestamp;

      return {
        ...adapter.upsertOne(call, state),
        currentCallId: call.callId
      };
    }

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.transcriptMessageReceived, (state, { transcriptMessageResponse }) => {
    const { callId, message, isComplete, sequence, partialSequence } = transcriptMessageResponse;
    const targetCall = state.entities[transcriptMessageResponse.callId];

    // In case if we receive transcripts before the call exists
    if (!targetCall) {
      const transcriptMessage = createTranscriptMessage(transcriptMessageResponse);
      const newCall: Call = {
        callId,
        callState: CallState.Inactive,
        transcriptMessages: [transcriptMessage]
      };

      return adapter.upsertOne(newCall, state);
    }

    let transcriptMessages = [...targetCall.transcriptMessages];
    const targetMessage = transcriptMessages.find(m => m.utteranceSequence === sequence);

    if (targetMessage) {
      const isPartialSequenceHigher = partialSequence > targetMessage.partialSequence;
      const isTargetMessageComplete = targetMessage.isComplete;

      // isComplete is necessary since 0 should overrule any other sequence value
      const transcriptMessageText = !isTargetMessageComplete && (isPartialSequenceHigher || isComplete)
        ? message
        : targetMessage.message;
      const transcriptMessageComplete = isTargetMessageComplete
        ? isTargetMessageComplete
        : isComplete;
      const transcriptMessagePartialSequence = isPartialSequenceHigher
        ? partialSequence
        : targetMessage.partialSequence;

      const replacedMessage: TranscriptMessage = {
        ...targetMessage,
        message: transcriptMessageText,
        isComplete: transcriptMessageComplete,
        partialSequence: transcriptMessagePartialSequence
      };

      transcriptMessages[transcriptMessages.indexOf(targetMessage)] = replacedMessage;
    }
    else {
      const transcriptMessage = createTranscriptMessage(transcriptMessageResponse);
      transcriptMessages = [...transcriptMessages, transcriptMessage];
    }

    const call: Call = {
      callId,
      transcriptMessages
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.logFirstTranscriptMessage, (state, {callId}) => {
    const callState = state.entities[callId];
    if (!callState) { return state; }

    const call: Call = {
      callId,
      firstTranscriptMessageLogged: true
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.highlightTranscriptMessage, (state, { utteranceSequence }) => {
    if (doesCurrentCallNotExist(state)) { return state; }

    const { callId, highlightedMessages, transcriptMessages, feedContent } = getCurrentCall(state);
    const targetMessage = transcriptMessages.find(m => m.utteranceSequence === utteranceSequence);

    if (!targetMessage) {
      return state;
    }

    const newFeedContent: FeedContent[] = [...feedContent, new HighlightedMessage(targetMessage, Guid.raw())];

    const call: Call = {
      callId,
      highlightedMessages: [...highlightedMessages, utteranceSequence],
      feedContent: newFeedContent
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.unhighlightTranscriptMessage, CallActions.unhighlightTranscriptMessageFromHighlightFeed, (state, { utteranceSequence }) => {
    if (doesCurrentCallNotExist(state)) { return state; }

    const { callId, highlightedMessages, feedContent } = getCurrentCall(state);

    const call: Call = {
      callId,
      highlightedMessages: highlightedMessages?.filter(m => m !== utteranceSequence),
      feedContent: feedContent?.filter(f => (<HighlightedMessage>f).utteranceSequence !== utteranceSequence)
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.recommendedActionControlClicked, (state, {recommendedAction, requestId}) => {
    if (doesCurrentCallNotExist(state)) { return state; }
    const currentCall = getCurrentCall(state);
    const feedContent = currentCall.feedContent?.map((content: RecommendedAction) => {
      if (content.type !== FeedContentType.ItgRecommendation) { return content; }
      if (content.id === recommendedAction.id){
        // currently only supports ITG
        return {
          ...content,
          actionState: RecommendedActionState.Launched,
          requestId
        };
      }
      return content;
    });

    const call: Call = {
      callId: currentCall.callId,
      feedContent
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.callLaunchItgError, (state, {requestId}) => {
    if (doesCurrentCallNotExist(state)) { return state; }
    const currentCall = getCurrentCall(state);
    const feedContent = currentCall.feedContent?.map((content: RecommendedAction) => {
      if (content.type !== FeedContentType.ItgRecommendation) { return content; }
      if (content.requestId === requestId){
        return {
          ...content,
          actionState: RecommendedActionState.Error
        };
      }
      return content;
    });

    const call: Call = {
      callId: currentCall.callId,
      feedContent
    };

    return adapter.upsertOne(call, state);
  }),


  on(CallActions.updateRecommendedActionRating, (state, {recommendedAction, rating}) => {
    if (doesCurrentCallNotExist(state)) { return state; }
    const currentCall = getCurrentCall(state);
    const feedContent = currentCall.feedContent.map((content: RecommendedAction) => {
      if (content.type !== FeedContentType.ItgRecommendation) { return content; }
      if (content.id === recommendedAction.id){
        return {
          ...content,
          ratingState: rating
        };
      }
      return content;
    });

    const call: Call = {
      callId: currentCall.callId,
      feedContent
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.updateRecommendedActionState, (state, {requestId, actionState}) => {
    if (doesCurrentCallNotExist(state)) { return state; }
    const currentCall = getCurrentCall(state);
    const feedContent = currentCall.feedContent?.map((content: RecommendedAction) => {
      if (content.type !== FeedContentType.ItgRecommendation) { return content; }
      if (content.requestId === requestId){
        return {
          ...content,
          actionState
        };
      }
      return content;
    });

    const call: Call = {
      callId: currentCall.callId,
      feedContent
    };

    return adapter.upsertOne(call, state);
  }),
  on(CallActions.addHighlightFeedContent, (state, { feedContent }) => {
    if (doesCurrentCallNotExist(state)) { return state; }
    const currentCall = getCurrentCall(state);

    const call: Call = {
      callId: currentCall.callId,
      feedContent: [...currentCall.feedContent, feedContent]
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.toggleSessionSummaryCollapse, (state, {isCollapsed}) => {
    if (doesCurrentCallNotExist(state)) { return state; }

    const currentCall = getCurrentCall(state);
    const call: Call = {
      callId: currentCall.callId,
      isSessionSummaryCollapsed: isCollapsed
    };
    return adapter.upsertOne(call, state);
  }),

  on(CallActions.updateShowSessionSummaryModal, (state, {show}) => {
    if (doesCurrentCallNotExist(state)) { return state; }

    const currentCall = getCurrentCall(state);
    const call: Call = {
      callId: currentCall.callId,
      showSessionSummaryModal: show
    };
    return adapter.upsertOne(call, state);
  }),

  on(CallActions.getCallSummary, (state, {callId, display: callSummaryDisplay, traceId}) => {
    const currentCall = state.entities[callId];
    if (currentCall) {
      const updatedState = {
        callId: currentCall.callId,
        showSessionSummaryModal: callSummaryDisplay === CallSummaryDisplay.Modal ? true : currentCall.showSessionSummaryModal,
        callSummaryInProgressTraceId: currentCall.callSummaryInProgressTraceId ?? traceId,
        callSummaryDisplay
      };

      if (currentCall.callSummary?.error) {
        const call: Call = {
          ...updatedState,
          callSummary: null
        };
        return adapter.upsertOne(call, state);
      }

      return adapter.upsertOne(updatedState, state);
    }
    return state;
  }),

  on(CallActions.updateCallSummary, (state, {callId, issue, resolution, error}) => {
    const callUi = state.entities[callId];
    if (!callUi) { return state; }

    const updateCall: Call = {
      callId,
      callSummary: {
        issue,
        resolution,
        error
      },
      callSummaryInProgressTraceId: null
    };

    if (callUi.callSummaryInProgressTraceId && callUi.callSummaryDisplay === CallSummaryDisplay.Modal) {
      const modalOpenUpdate = {
        ...updateCall,
        showSessionSummaryDrawer: false
      };
      return adapter.upsertOne(modalOpenUpdate, state);
    }

    const panelOpenUpdate = {
      ...updateCall,
      showSessionSummaryModal: false,
      showSessionSummaryDrawer: true,
      isSessionSummaryCollapsed: false
    };
    return adapter.upsertOne(panelOpenUpdate, state);

  }),

  on(CallActions.rateSessionSummary, (state, {isPositiveRating}) => {
    const callId = state.currentCallId;
    const summary = state.entities[callId]?.callSummary;

    if (summary){
      const call: Call = {
        callId,
        callSummary: {
          ...summary,
          isPositiveRating,
          rated: true
        }
      };
      return adapter.upsertOne(call, state);
    }
    return state;
  }),

  on(CallActions.transcriptRecommendedActionReceived, (state, { transcriptRecommendedAction, callId }) => {
    const targetCall: Call = state.entities[callId];

    if (!targetCall || !transcriptRecommendedAction.actionTitle) {
      return state;
    }

    const existingRecommendation = targetCall.feedContent?.find((fc) => (<RecommendedAction>fc).intent === transcriptRecommendedAction.intent);

    if (existingRecommendation || !VoiceHelper.isAuthenticatedCall(targetCall.customerInfo)){
      return state;
    }

    const call: Call = {
      ...targetCall,
      feedContent: [...(targetCall.feedContent ?? []), transcriptRecommendedAction]
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.callSummaryError, (state, {callId}) => {
    const callState = state.entities[callId];
    if (!callState) { return state; }

    const call: Call = {
      callId,
      callSummary: {
        issue: '',
        resolution: '',
        error: true
      },
      callSummaryInProgressTraceId: null
    };

    return adapter.upsertOne(call, state);
  }),

  on(CallActions.callEnded, (state, { callId }) => {
    const currentCallEnded = callId === state.currentCallId;
    const nextCallId: string = getNextCallId(state, currentCallEnded);

    //Delete any pre-existing ended calls while retaining the most recently ended
    let updatedState = removeEndedCalls(state);

    const endedCall = {
      callId,
      callState: CallState.Ended,
      showSessionSummaryModal: false,
      showSessionSummaryDrawer: false,
      isSessionSummaryCollapsed: true,
      callSummaryInProgressTraceId: null,
    };
    updatedState = adapter.upsertOne(endedCall, updatedState);

    updatedState = {
      ...updatedState,
      currentCallId: currentCallEnded ? nextCallId : state.currentCallId,
    };

    if (nextCallId) {
      const nextCall = {
        callId: nextCallId,
        callState: CallState.Active
      };
      updatedState = adapter.upsertOne(nextCall, updatedState);
    }

    return updatedState;
  }),
);

function getNextCallId(state: State, currentCallEnded: boolean): string {
  if (!currentCallEnded) { return null; }

  const queuedCallEntity = Object.entries(state.entities)
    .find(([_, entity]) => entity.callState === CallState.Queued);
  return queuedCallEntity ? queuedCallEntity[1].callId : null;
}

function removeEndedCalls(state: State): State {
  const endedCallsIds = Object.entries(state.entities)
    .filter(([_, entity]) => entity.callState === CallState.Ended)
    .map(([, entity]) => entity.callId);

  return endedCallsIds?.length ? adapter.removeMany(endedCallsIds, state) : state;
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getCurrentCallId = (state: State) => state?.currentCallId;

export function createTranscriptMessage(transcriptMessageResponse: TranscriptMessageResponse): TranscriptMessage {
  const { messageId, sender, message, isComplete, sequence, partialSequence, offsetFromCallStartMs } = transcriptMessageResponse;
  const transcriptMessage: TranscriptMessage = {
    utteranceSequence: sequence,
    messageId,
    sender,
    message,
    offsetTime: offsetFromCallStartMs / 1000,
    isComplete,
    partialSequence
  };

  return transcriptMessage;
}
