// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { ScriptsContextMenu } from 'src/app/models/scripts-context-menu';
import { AgentAvailabilityActions, AgentActions, ChatActions, ChatUiActions, ScriptActions, UiActions, SettingsActions, PlaceholdersActions } from '../actions';
import { AvailabilityType, BounceTimerDetail, BounceTimerType, ExitAction } from '../models';
import { ChatImageMessage } from '../models/chatImageMessage';
import { ConvoMessageContextMenu } from '../models/eventArgs/convoMessageContextMenu';
import { ScriptClickEventArgs } from '../models/eventArgs/scriptClickEventArgs';
import { AgentSettingsBanner } from '../models/agent-settings-banner';
import { ConfirmAction, ItgActionType, QualityCheckResponseSource, SuggestionType } from '../models/enums';
import { ItgSuggestion } from '../models/itgSuggestion';
import { ToastDetails } from '../models/toast-details';
import { SmartResponseInUse } from '../models/smartResponseInUse';
import { AgentSettingsTabs } from '../../utils/enums';
import { CxGptResponseInUse } from '../models/cx-gpt-response-in-use';

export const featureKey = 'ui';

export interface State {
  scriptClickEventArgs: ScriptClickEventArgs;
  smartResponseInUse: SmartResponseInUse;
  agentSettingsBanner: AgentSettingsBanner;
  isCloseChatModalOpen: boolean;
  transferChatModal: ITransferChatModalEntityState;
  imageModalValue: ChatImageMessage;
  convoCardContextMenuChatId: string;
  scriptsContextMenu: ScriptsContextMenu;
  agentSettingsPanel: IAgentSettingsPanelEntityState;
  isExitCustomSettingsModalOpen: boolean;
  notificationBannerDetails: BounceTimerDetail[];
  notificationBounceType: BounceTimerType;
  notificationBannerClosedChatId: string;
  convoMessageContextMenu: ConvoMessageContextMenu;
  agentSummaryOpen: boolean;
  isSessionLockoutWarningModalOpen: boolean;
  hasChatNotification: boolean;
  itgModalOpen: boolean;
  itgModalAction: ItgActionType;
  itgModalSuggestedItg: ItgSuggestion;
  updateInlineToast: ToastDetails;
  toggleShowRateConnectionFollowUpModal: boolean;
  showRateConnectionQuality: boolean;
  toggleShowRateConnectionFollowUp: boolean;
  enableRateConnectionAdditionalFeedback: boolean;
  settingsConnectionRatingSubmitted: boolean;
  historyPanelIsOpen: boolean;
  smartResponseFilterText?: string;
  closeTransferModalOpen: boolean;
  confirmCloseTransferAction: ConfirmAction;
  showDismissSuggestionFeedback: boolean;
  dismissSuggestionFeedbackSubmitted: boolean;
  isLoadingTransferOptions?: boolean;
  previousChatSummaryCardOpen?: boolean;
  previousChatSummaryModalOpen?: boolean;
  cxGptResponseInUse?: CxGptResponseInUse;
}

export interface ITransferChatModalEntityState {
  isOpen: boolean;
  transferFailed: boolean;
}

export interface IAgentSettingsPanelEntityState {
  isOpen: boolean;
  activeTabTitle: string;
}

export const initialState: State = {
  scriptClickEventArgs: null,
  smartResponseInUse: null,
  agentSettingsBanner: {
    active: false,
    exitAction: ExitAction.Canceled
  },
  isCloseChatModalOpen: false,
  transferChatModal: {
    isOpen: false,
    transferFailed: false
  },
  imageModalValue: null,
  convoCardContextMenuChatId: null,
  scriptsContextMenu: null,
  agentSettingsPanel: {
    isOpen: false,
    activeTabTitle: ''
  },
  isExitCustomSettingsModalOpen: false,
  notificationBannerDetails: [],
  notificationBounceType: BounceTimerType.Warning,
  notificationBannerClosedChatId: null,
  agentSummaryOpen: false,
  convoMessageContextMenu: null,
  isSessionLockoutWarningModalOpen: false,
  hasChatNotification: false,
  itgModalOpen: false,
  itgModalAction: null,
  itgModalSuggestedItg: null,
  updateInlineToast: null,
  toggleShowRateConnectionFollowUpModal: false,
  showRateConnectionQuality: false,
  toggleShowRateConnectionFollowUp: false,
  enableRateConnectionAdditionalFeedback: false,
  settingsConnectionRatingSubmitted: false,
  historyPanelIsOpen: false,
  closeTransferModalOpen: false,
  confirmCloseTransferAction: null,
  showDismissSuggestionFeedback: false,
  dismissSuggestionFeedbackSubmitted: false,
  previousChatSummaryModalOpen: false,
  cxGptResponseInUse: null
};

export const reducer = createReducer<State>(
  initialState,
  on(
    UiActions.ScriptClicked,
    (state, { scriptInUse: scriptClickEventArgs }): State => {
      let notificationBannerDetails = state.notificationBannerDetails;
      if (scriptClickEventArgs?.quickSend) {
        notificationBannerDetails =  filterNotificationBannerDetails(notificationBannerDetails, scriptClickEventArgs?.chatId);
      }
      return {
        ...state,
        scriptClickEventArgs,
        notificationBannerDetails
      };
    }
  ),
  on(
    UiActions.SmartResponseClicked,
    (state, { smartResponseInUse: smartResponseInUse }): State => {
      let notificationBannerDetails = state.notificationBannerDetails;
      if (smartResponseInUse?.quickSend) {
        notificationBannerDetails = filterNotificationBannerDetails(notificationBannerDetails, smartResponseInUse?.chatId);
      }
      return {
        ...state,
        smartResponseInUse,
        notificationBannerDetails
      };
    }
  ),
  on(
    UiActions.CxGptResponseEditClicked,
    (state, { cxGptResponseInUse }): State => {    
      return {
        ...state,
        cxGptResponseInUse        
      };
    }
  ),
  on(
    UiActions.UpdateSmartResponseFilterText,
    (state, { content }): State => ({
    ...state,
    smartResponseFilterText: content
    })
  ),
  on(
    UiActions.ToggleAgentSettingsBanner,
    (state, { payload: agentSettingsBanner }): State => ({
      ...state,
      agentSettingsBanner
    })
  ),
  on(
    UiActions.GetTransferOptions,
    UiActions.RefreshTransferOptions,
    (state): State => ({
      ...state,
      isLoadingTransferOptions: true,
      transferChatModal: {
        ...state.transferChatModal,
        isOpen: true
      }
    })
  ),
  on(
    ChatActions.UpdateTransferOptions,
    (state): State => ({
      ...state,
      isLoadingTransferOptions: false
    })
  ),
  on(
    UiActions.TransferModalClosed,
    (state): State => ({
      ...state,
      transferChatModal: {
        isOpen: false,
        transferFailed: false
      }
    })
  ),
  on(
    UiActions.ClearTransferFailed,
    (state): State => ({
      ...state,
      transferChatModal: {
        ...state.transferChatModal,
        transferFailed: false
      }
    })
  ),
  on(
    ChatActions.TransferFailed,
    (state): State => ({
      ...state,
      transferChatModal: {
        ...state.transferChatModal,
        transferFailed: true
      }
    })
  ),
  on(
    ChatActions.Transfer,
    (state): State => ({
      ...state,
      transferChatModal: {
        ...state.transferChatModal,
        transferFailed: false
      }
    })
  ),
  on(
    ChatActions.Transferred,
    (state): State => ({
      ...state,
      transferChatModal: {
        ...state.transferChatModal,
        isOpen: false
      }
    })
  ),
  on(
    ChatActions.Close,
    ChatActions.CloseAsyncChat,
    UiActions.ExitPromptCloseEngagement,
    (state): State => ({
      ...state,
      isCloseChatModalOpen: false
    })
  ),
  on(
    UiActions.WrapChat,
    (state): State => ({
      ...state,
      isCloseChatModalOpen: true
    })
  ),
  on(
    UiActions.ImageClicked,
    (state, { payload: imageModalValue }): State => ({
      ...state,
      imageModalValue
    })
  ),
  on(
    UiActions.ImageModalClosed,
    (state): State => ({
      ...state,
      imageModalValue: null
    })
  ),
  on(
    UiActions.ToggleScriptsContextMenuAction,
    (state, { scriptsContextMenu }): State => ({
      ...state,
      scriptsContextMenu
    })
  ),
  on(
    ScriptActions.deleteScriptConfirmation,
    ScriptActions.editScript,
    (state): State => {
      if (!state.scriptsContextMenu) {
        return state;
      }
      return {
        ...state,
        scriptsContextMenu: {
          ...state.scriptsContextMenu,
          open: false
        }
      };
    }
  ),
  on(
    SettingsActions.sendNewFeatureBannerAcknowledgement,
    (state, {isCloseAction}): State => {
      if (isCloseAction) {
        return state;
      }
      return {
        ...state,
        agentSettingsPanel: {
          ...state.agentSettingsPanel,
          isOpen: true,
          activeTabTitle: AgentSettingsTabs.FeatureAnnouncement
        }
      };
    }
  ),
  on(
    UiActions.translateNotificationMessageClicked,
    (state): State => {
      return {
        ...state,
        agentSettingsPanel: {
          ...state.agentSettingsPanel,
          isOpen: true,
          activeTabTitle: 'Advanced Features'
        }
      };
    }
  ),
  on(
    UiActions.ToggleAgentSettingsPanel,
    (state, {isOpen}): State => {
      const activeTabTitle = isOpen ? state.agentSettingsPanel.activeTabTitle : '';
      return {
        ...state,
        agentSettingsPanel: {
          ...state.agentSettingsPanel,
          isOpen,
          activeTabTitle
        }
      };

    }
  ),
  on(
    UiActions.UpdateAgentSettingsActiveTab,
    (state, {activeTabTitle}): State => ({
      ...state,
      agentSettingsPanel: {
        ...state.agentSettingsPanel,
        activeTabTitle
      }
    })
  ),
  on(
    UiActions.exitCustomSettings,
    (state): State => ({
      ...state,
      isExitCustomSettingsModalOpen: true
    })
  ),
  on(
    UiActions.closeCustomSettingsExitModal,
    (state): State => ({
      ...state,
      isExitCustomSettingsModalOpen: false
    })
  ),
  on(
    ChatActions.Bounce,
    (state, { payload }): State => {
      return clearNotificationBannerDetails(state, payload);
    }
  ),
  on(
    UiActions.BounceTimerTriggered,
    (state, { bounceTimerDetail }): State => {
      const notificationBannerDetails = filterNotificationBannerDetails(state.notificationBannerDetails, bounceTimerDetail?.chatId);
      notificationBannerDetails.push(bounceTimerDetail);
      const notificationBounceType = bounceTimerDetail.type === BounceTimerType.Bounced ? BounceTimerType.Bounced : state.notificationBounceType;
      return {
        ...state,
        notificationBannerDetails,
        notificationBounceType
      };
    }
  ),
  on(
    ChatUiActions.agentTypingStarted,
    ChatUiActions.agentSelectedChat,
    (state, { chatId }): State => {
      return clearNotificationBannerDetails(state, chatId);
    }
  ),
  on(
    ChatActions.SendSuggestion,
    (state, { payload }): State => {
      return clearNotificationBannerDetails(state, payload.chatId);
    }
  ),
  on(
    ChatActions.Closed,
    (state, { payload }): State => {
      return clearNotificationBannerDetails(state, payload.chatId);
    }
  ),
  on(AgentAvailabilityActions.availabilityUpdated,
    (state, action): State => {
      if (action.payload.available !== AvailabilityType.Available || state.notificationBounceType === BounceTimerType.Warning){
        return state;
      }

      const notificationBannerDetails = state.notificationBannerDetails.filter(detail => detail.type !== BounceTimerType.Bounced);
      return {
        ...state,
        notificationBannerDetails,
        notificationBounceType: BounceTimerType.Warning
      };
    }
  ),
  on(UiActions.dismissBouncedNotificationBanner,
    (state): State => {
      const notificationBannerDetails = state.notificationBannerDetails.filter(detail => detail.type !== BounceTimerType.Bounced);
      return {
        ...state,
        notificationBannerDetails,
        notificationBounceType: BounceTimerType.Warning
      };
    }
  ),
  on(
    UiActions.NotificationBannerClosed,
    (state, { chatId }): State =>
    {
      const notificationBannerDetails = filterNotificationBannerDetails(state.notificationBannerDetails, chatId);
      return {
        ...state,
        notificationBannerDetails,
        notificationBannerClosedChatId: chatId
      };
    }
  ),
  on(
    UiActions.ToggleMessageContextMenu,
    (state, {convoContextMenu}): State => ({
      ...state,
      convoMessageContextMenu: convoContextMenu
    })
  ),
  on(
    ChatActions.CopyText,
    ChatActions.MaskText,
    (state): State => {
      if (!state.convoMessageContextMenu) {
        return state;
      }
      return {
        ...state,
        convoMessageContextMenu: {
          ...state.convoMessageContextMenu,
          open: false
        }
      };
    }
  ),
  on(
    UiActions.openSessionLockoutWarningModal,
    (state): State => ({
      ...state,
      isSessionLockoutWarningModalOpen: true
    })
  ),
  on(
    UiActions.closeSessionLockoutWarningModal,
    (state): State => ({
      ...state,
      isSessionLockoutWarningModalOpen: false
    })
  ),
  on(
    UiActions.ToggleAgentSummaryPanel,
    (state, {toggleAgentSummaryPanel}): State => ({
      ...state,
      agentSummaryOpen: toggleAgentSummaryPanel,
      hasChatNotification: !toggleAgentSummaryPanel ? false : null
    })
  ),
  on(
    ChatActions.NewMessage,
    ChatUiActions.nextCustomerActivityStatus,
    (state): State => {
      if (state.agentSummaryOpen) {
        return {
          ...state,
          hasChatNotification: true
        };
      }
      return state;
    }
  ),
  on(
    UiActions.ToggleItgModal,
    (state, {toggleItgModal, action, suggestedItg}): State => ({
      ...state,
      itgModalOpen: toggleItgModal,
      itgModalAction: action,
      itgModalSuggestedItg: suggestedItg
    })
  ),
  on(
    UiActions.ToggleCloseTransferModal,
    (state, {toggleCloseTransferModal, action}): State => ({
      ...state,
      closeTransferModalOpen: toggleCloseTransferModal,
      confirmCloseTransferAction: action
    })
  ),
  on(
    ChatActions.StoredTextToSend,
    (state, {storedTextToSend}): State => {
      if (storedTextToSend === null) {
        return {
          ...state,
          itgModalOpen: false,
          itgModalAction: ItgActionType.Pause
        };
      } else {
        return {
          ...state,
          itgModalOpen: true,
          itgModalAction: ItgActionType.Pause
        };
      }
    }
  ),
  on(
    SettingsActions.sendQualityCheckResponse,
    (state, {qualityCheckResponse}): State => {
      const showModal = qualityCheckResponse.source === QualityCheckResponseSource.Inline && qualityCheckResponse.rating < 4;
      return {
        ...state,
        toggleShowRateConnectionFollowUpModal: showModal,
        updateInlineToast: {
          hasIcon: 'check-circle',
          active: true,
          text: 'Rating submitted',
          dismissible: false,
          timer: 2000
        }
      };
    }
  ),
  on(
    UiActions.SettingsConnectionRatingSubmitted,
    (state): State => ({
      ...state,
      toggleShowRateConnectionFollowUpModal: false,
      updateInlineToast: {
        hasIcon: 'check-circle',
        active: true,
        text: 'Your feedback helps improve Mercury!',
        dismissible: true,
        timer: 6000
      }
    })
  ),
  on(
    UiActions.DismissFeedbackPrompt,
    (state): State => ({
      ...state,
      updateInlineToast: {
        hasIcon: '',
        active: true,
        text: '',
        dismissible: false,
        timer: 6000,
        animation: true
      },
      showRateConnectionQuality: false
    })
  ),
  on(
    UiActions.DismissInlineToast,
    (state): State => ({
      ...state,
      updateInlineToast: {
        hasIcon: '',
        active: false,
        text: '',
        dismissible: false
      }
    })
  ),
  on(
    UiActions.ToggleShowRateConnectionFollowUpModal,
    (state, {toggleShowRateConnectionFollowUpModal}): State => ({
      ...state,
      toggleShowRateConnectionFollowUpModal: toggleShowRateConnectionFollowUpModal
    })
  ),
  on(
    AgentActions.UpdateOfferQualityCheck,
    (state, {offerQualityCheck}): State => ({
      ...state,
      showRateConnectionQuality: offerQualityCheck
    })
  ),
  on(
    UiActions.ToggleShowRateConnectionFollowUp,
    (state, {toggleShowRateConnectionFollowUp, enableRateConnectionAdditionalFeedback}): State => ({
      ...state,
      toggleShowRateConnectionFollowUp: toggleShowRateConnectionFollowUp,
      enableRateConnectionAdditionalFeedback: enableRateConnectionAdditionalFeedback
    })
  ),
  on(
    UiActions.SettingsConnectionRatingSubmitted,
    (state): State => ({
      ...state,
      toggleShowRateConnectionFollowUp: false,
      settingsConnectionRatingSubmitted: true
    })
  ),
  on(ChatActions.History_EngagementSelected,
    (state): State =>  ({
      ...state,
      historyPanelIsOpen: false
    })
  ),
  on(UiActions.UpdateHistoryPanel,
    (state, { historyPanelIsOpen }): State =>  ({
      ...state,
      historyPanelIsOpen
    })
  ),
  on(
    PlaceholdersActions.submitEditPlaceholderPanel,
    (state): State => {
      if (state.smartResponseInUse?.isInEditPlaceholder){
        return {
          ...state,
          smartResponseInUse: {
            ...state.smartResponseInUse,
            isInEditPlaceholder: false
          }
        };
      }
      return state;
    }
  ),
  on(
    PlaceholdersActions.closeEditPlaceholderPanel,
    (state): State => {
      if (state.smartResponseInUse){
        return {
          ...state,
          smartResponseInUse: null
        };
      }
      return state;
    }
  ),
  on(
    ChatActions.DismissLatestSuggestions,
    (state, {sendSuggestion}): State => {
      const NoFeedbackSuggestions =  [SuggestionType.External , SuggestionType.Greeting, SuggestionType.CustomerAwaitingReply];
      return {
        ...state,        
        showDismissSuggestionFeedback: !NoFeedbackSuggestions.includes(sendSuggestion?.suggestionType) && !state.showRateConnectionQuality && !state.toggleShowRateConnectionFollowUpModal,
        dismissSuggestionFeedbackSubmitted: false
      };
    }
  ),
  on(
    AgentActions.SubmitDismissSuggestionFeedback,
    (state): State => ({
      ...state,
      dismissSuggestionFeedbackSubmitted: true
    })
  ),
  on(
    UiActions.CloseDismissSuggestionFeedback,
    (state): State => ({
      ...state,
      showDismissSuggestionFeedback: false,
      dismissSuggestionFeedbackSubmitted: false
    })
  ),
  on(
    UiActions.TogglePreviousChatSummariesPanel,
    (state, {isOpen}): State => ({
      ...state,
      previousChatSummaryCardOpen: isOpen
    })
  ),
  on(
    UiActions.UpdatePreviousChatSummaryModal,
    (state, {isOpen}): State => ({
      ...state,
      previousChatSummaryModalOpen: isOpen
    })
  )
);

function clearNotificationBannerDetails(state: State, chatId: string){
  const notificationBannerDetails = filterNotificationBannerDetails(state.notificationBannerDetails, chatId);
  return {
    ...state,
    notificationBannerDetails
  };
}
function filterNotificationBannerDetails(notificationBannerDetails: BounceTimerDetail[],  chatId: string){
  return notificationBannerDetails.filter(detail => chatId !== detail.chatId);
}
