// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { CxGptResponseVm } from '../models/cx-gpt-response';
import { CxGptResponse } from '../models/requests/get-cx-gpt-messages-request';
import { State } from './cx-gpt-responses.reducer';

export const hydrate = createAction('[CxGptResponses] Hydrate');

export const hydrateSuccess = createAction(
  '[CxGptResponses] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[CxGptResponses] Hydrate Failure');

export const chatsRemoved = createAction(
  '[CxGptResponses] Chats Removed',
  props<{ chatIds: string[] }>()
);

export const getCxGptResponses = createAction(
  '[CxGptResponses] Get CxGpt Responses',
  props<{ chatId: string, responses: CxGptResponse[] }>()
);

export const getCxGptResponsesFailed = createAction(
  '[CxGptResponses] Get CxGpt Responses Failed',
  props<{ chatId: string, messageId: string}>()
);

export const updateCxGptResponses = createAction(
  '[CxGptResponses] Update CxGpt Responses',
  props<{ chatId: string, messageId: string , response: CxGptResponse }>()
);

export const updateCxGptResponseFeedback = createAction(
  '[CxGptResponses] Update CxGpt Response Feedback',
  props<{ chatId: string, feedbackData: CxGptResponseVm }>()
);

export const cxGptResponseEditClicked = createAction(
  '[CxGptResponses] CxGpt Response Edit Clicked',
  props<{ chatId: string, cxGptResponse: CxGptResponseVm }>()
);
