// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export enum ChannelType {
    WebChat,
    Sms
}
export enum DeviceType {
    Unknown = 1,
    Desktop = 2,
    Phone = 3,
    Tablet = 4
}

// Only need to define the languages that differ from the Language enum
export enum BrowserVariationLanguage {
  Cantonese = 'zh-hk',
  ChineseSimplified = 'zh-cn',
  ChineseTraditional = 'zh-tw',
}

export enum Language {
  Unknown = 'unknown',
  Cantonese = 'yue',
  // Equivalent to zh-hans
  ChineseSimplified = 'zh',
  ChineseTraditional = 'zh-hant',
  English = 'en',
  Filipino = 'fil',
  French = 'fr',
  Spanish =  'es',
  Vietnamese = 'vi',
}

export enum TranslatedMessageType {
  Inbound = 'inbound',
  Outbound = 'outbound',
  Batch = 'batch'
}

export enum SenderType {
    FixAgent = 1,
    Requester = 2,
    XA = 3,
    System = 4,
    Mercury = 5
}

export enum SystemMessageType {
    Datapass,
    Navigation,
    Information,
    CustomerReconnect,
    CustomerDisconnect,
    CustomerExit
}

export enum AvailabilityType {
    Available = 1,
    Unavailable = 2,
    Error = 3
}

export enum ChatState {
    PendingAcceptance,              // chat is waiting to be accepted by the agent
    AcceptanceUnknown,           // accept chat call was caught with error, don't know if the call reached nuance
    Open,                           // chat is fully functional and active
    Disconnected,                    // customer disconnected
    Closed                         // chat is finished
}

export enum ChatMessageType {
    Unknown,
    Image,
    Message,
    TopicSelection,
    MediaCard,
    CalendarSelection,
    AddressUpdate,
    SummaryCard,
    TransferCard,
    XaSuggestion,
    ItgAction,
    TranslationConfig
}

export enum CalendarSelectionType {
  Reschedule,
  Schedule
}

export enum SystemInfoMessageType {
    MaximizedWindow = 1,
    MinimizedWindow = 2,
    PageNavigated = 3,
    SecureDataCollection = 4,
    PageMarker = 5
}

export enum ScriptType {
    Custom,
    System
}
export enum BounceTimerType {
    Warning,
    Bounced
}

export enum UserRole {
    Unknown = 0,
    Primary = 1,
    Secondary = 2,
    RestrictedSecondary = 3,
    UnrestrictedSecondary = 4,
    Billpay = 5,
    ManageVoice = 6,
    XHSA = 7
}

export enum SendMessageType {
    ChatLine,
    Script
}

export enum DateFormats {
    Us = 0,
    International = 1
}

export enum TimeFormats {
    Standard = 0,
    Military = 1
}
export enum UpdateCustomScriptType{
  Create,
  Update,
  Delete
}
export enum ExitAction{
  Closed,
  Canceled,
  Saved
}

export enum ScriptQuickSendType {
  ShiftClick,
  DoubleClick
}

export enum TypingStatus {
  StartsTyping,
  StopsTyping
}

export enum CustomerActivityStatus {
  Active,
  Inactive,
  InactiveWarning,
  InactiveClosing,
  Pinned,
  Unpinned,
  Closed
}

export enum UpdateMessageType {
  Merge,
  Prepend
}

export enum PageInitializationState {
  Pending,
  Success,
  Error
}

export enum TransferType {
  Transfer,
  Bounce,
  AgentDisconnect
}

export enum ChatAttachmentType{
  Unknown,
  Image
}

export enum CtiFocusApplicationType {
  Acsr = 'Acsr',
  Einstein360 = 'Einstein360',
  Einsteinchat = 'Einsteinchat',
  Ecm = 'Ecm'
}

export enum XaSuggestionActionType {
  Intent = 1,
  Utterance = 2
}

export enum LoggedOutMethod {
  ManualSettings = 'manualSettings',
  AutomaticLockout = 'automaticLockout',
  ManualLockout = 'manualLockout',
  ManualAdvAgent = 'manualAdvAgent',
  UnauthorizedCall = 'unauthorizedCall',
  InvalidToken = 'invalidToken',
  PlatformForce = 'platformForce',
  Disconnect = 'disconnect'
}

export enum ChatIdentifierType {
  ChatId = 'chatId',
  CustomerId  = 'customerId',
  AccountNumber = 'accountNumber'
}

export enum SendSuggestionSource{
  Window = 'window',
  AssistList = 'assistList',
  AutoPilot = 'autoPilot'
}

export enum ItgActionType {
  Start = 'start',
  End = 'end',
  Cancel = 'cancel',
  Pause = 'pause',
  Resume = 'resume',
  Error = 'error'
}

export enum ItgState {
  Running,
  Paused,
  Completed,
  Canceled,
  Expired,
  Error,
  SchedulerError
}

export enum SuggestionType {
  Xa,
  Itg,
  Scheduler,
  External,
  Recommendation,
  Greeting,
  ItgIntroduction,
  CustomerAwaitingReply
}

export enum DetailsSelectedTab {
  Recommended = 'Recommended',
  Responses = 'Responses',
  Activities = 'Activities',
  Knowledge = 'Knowledge'
}

export enum ChatMessageStatus {
  Pending = 1,
  Sent = 2,
  Retrying = 3,
  Error = 4,
  Failed = 5
}

export enum AgentSummaryHeaders {
  BusinessUnit = 'Business Unit',
  AgentGroup = 'Agent Group',
  ChatsinQueue = 'Chats in Queue',
  Agents = 'Agents',
  ChatSlots = 'Chat Slots'
}

export enum AgentSummarySubHeaders {
  Agents = 'Available/Total',
  ChatSlots = 'Busy/Free/Max',
  BlankSubHeader = ''
}

export enum NavigationButtons {
  Help = 'help',
  Settings = 'settings',
  Conversations = 'conversations',
  Summary = 'summary',  
  Mercury = 'logo-dark',
  PreviousSummaries= 'PreviousSummaries'
}

export enum LoginBanners {
  Disconnected = 'disconnected',
  AuthFailure = 'authFailure'
}

export enum DataCollectionState {
  Inactive = 'inactive',
  Cancelling = 'cancelling',
  Requested = 'requested',
  Requesting = 'requesting',
  Available = 'available',
  AgentCancelled = 'AgentCancelled',
  CustomerApplied = 'CustomerApplied',
  CustomerClosed = 'CustomerClosed',
  CustomerCompleted = 'CustomerCompleted',
  CustomerStarted = 'CustomerStarted',
  TokenTimedOut = 'TokenTimedOut',
  LinkSent = 'LinkSent'
}

export enum SecureDataRequestCancelReason {
  CloseChat = 'closeChat',
  DismissLink = 'dismissLink',
  TransferChat = 'transferChat'
}

export enum QualityCheckResponseSource {
  Inline = 'inline',
  Settings = 'settings'
}

export enum InboundEventOperationType {
  CloseChat = 'RequestCloseChat'
}

export enum ChatStartGreetingType {
  None,
  FirstAsync,
  SecondReconnect,
  Transfer,
  ReturningAsync
}

export enum SmartResponseType {
  Nuance,
  AppliedAi
}

export enum LoadingState {
  Pending,
  Success,
  Failed
}

export enum PlaceholderEditorState {
  MessageEditor = 'editMessage',
  QuickSend = 'quickSend',
  EditPlaceholder = 'editPlaceholder'
}

export enum PlaceholderEditorType {
  SmartResponse = 'smartResponse',
  SystemResponse = 'systemResponse'
}

export enum SmartResponseComponentDisplayType {
  Notification,
  SmartResponseList,
  Skeleton,
  Error
}

export enum SuggestionActivityAction {
  SendMessage,
  StartItg
}

export enum ConfirmAction{
  Close,
  Transfer
}

export enum StartSchedulerMethod {
  AutoPilot
}

export enum DismissSuggestionReason {
  NotRelevant = 'Not relevant to the conversation',
  NeedMoreInfo = 'Relevant but needs more information',
  Incorrect = 'Information on card was not correct',
  WrongTone = 'Did not match my style or tone/ Too robotic',
  Other = 'Reason not listed above',
  Unknown = 'Unknown'
}

export enum CloseSuggestionFeedbackMethod {
  CloseButtonFeedbackSubmitted = 'xButtonFeedbackSubmitted',
  CloseButtonFeedbackNotSubmitted = 'xButton',
  QualityCheckReplacement = 'qualityCheck',
  CloseChat = 'closeChat',
  TransferChat = 'transferChat'
}

export enum SendTaskType {
  SendMessage,
  SendXaSuggestion,
  CloseRequest,
  CloseConversation,
  SendUnifiedNotes
}