// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ScriptSent {
    id: string;
    scriptValue: string;
    scriptGroupName: string;
    timesSent: number; 
    sentMessages: SentMessage[]; 
}

export class SentMessage {
    message: string;
}