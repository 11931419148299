// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  CustomEventData,
  ICustomEventLog,
  NiagaraLogData
} from '@cxt-cee-chat/merc-ng-core';

export class CustomEventDataLog extends CustomEventData
  implements ICustomEventLog {
  operationName: string;

  constructor(name: string, dimensions: {}) {
    super();
    Object.assign(this, dimensions);
    this.name = name;
  }

  public toLogFormat() {
    const { data, ...otherData } = this;
    const flattenedData = { ...data, ...otherData };

    return {
      ...flattenedData
    };
  }

  public toAnalyticsFormat(): NiagaraLogData {
    const analyticsData = new NiagaraLogData();
    analyticsData.EventType = this.name;
    return analyticsData;
  }
}
