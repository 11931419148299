// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, forwardRef, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CeeTabPanelItemComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { AgentSettingsTabs } from 'projects/entities/src/lib/utils/enums';

@Component({
  selector: 'merc-agent-settings-feature-announcement-tab',
  templateUrl: './feature-announcement-tab.component.html',
  styleUrls: ['./feature-announcement-tab.component.scss'],
  providers: [
    {
      provide: CeeTabPanelItemComponent,
      useExisting: forwardRef(() => FeatureAnnouncementTabComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureAnnouncementTabComponent extends CeeTabPanelItemComponent implements OnInit {
  title = AgentSettingsTabs.FeatureAnnouncement;

  ngOnInit() {
  }
}
