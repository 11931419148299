// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, TokenStoreService, AccessToken, JwtTokenFactoryService, DayTimeService, DateUnitType } from '@cxt-cee-chat/merc-ng-core';
import { FixAgentFormAuthApiService } from './fix-agent-form-auth-api.service';
import { RefreshAccessToken } from '../domain/models/requests/refreshAccessToken';
import { HttpResponse } from '@angular/common/http';
import { GetAccesstokenResponse } from '../domain/models/authentication/getAccesstokenResponse';

@Injectable({
  providedIn: 'root'
})
export class NuanceFormAuthService extends AuthService {

  protected get tokenStoreKey(): string {
    return 'nuanceFormToken';
  }

  constructor(tokenStore: TokenStoreService,
              tokenFactory: JwtTokenFactoryService,
              private router: Router,
              private fixAgentAuthApiService: FixAgentFormAuthApiService,
              private timeService: DayTimeService,
              @Optional()
              @Inject('renewTokenBeforeInSeconds')
              renewTokenBeforeInSeconds?: number) {
    super(tokenStore, tokenFactory, renewTokenBeforeInSeconds);
  }


  public redirectToAuthenticationProvider(): void {
    this.router.navigate(['login']);
  }
  public storeTokenInStore(token: AccessToken): void {
    super.removeTokenFromStore();
    super.storeTokenInStore(token);
  }
  protected handleTokenRenewal(token: AccessToken): Promise<AccessToken> {
    return this.refreshToken(token);
  }

  public refreshToken(token: AccessToken): Promise<AccessToken> {
    const refreshToken = new RefreshAccessToken();
    refreshToken.accessToken = token.refreshToken;

    return new Promise<AccessToken>(
      (resolve, reject) => {
        this.fixAgentAuthApiService.refreshAccessToken(refreshToken)
        .then
        (
          (response: HttpResponse<GetAccesstokenResponse>) => {
            if (response && response.body && response.body.accessToken){
              const accessToken = new AccessToken();
              accessToken.accessToken = response.body.accessToken.token;
              const unixExpires = this.timeService.add(+response.body.accessToken.expiresIn, DateUnitType.Seconds);
              accessToken.expiresOn = this.timeService.toDate(unixExpires);
              accessToken.refreshToken = response.body.accessToken.refreshToken;
              accessToken.scope = response.body.accessToken.scope;
              accessToken.advancedAgent = response.body.accessToken.advancedAgent;
              resolve(accessToken);
            }
            else{
              reject('Unable to retrieve token');
            }
          },
          error => {
            reject(`Unable to retrieve token: ${error}`);
          }
        );
      }
    );
  }
}
