// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  ApplicationInsightsLogger,
  ConsoleLogger,
  EnvironmentService,
  LoggingFactoryService,
  NiagaraLogAnalyticsLogger,
  AuthService,
  NiagaraLogAnalyticsApiService
} from '@cxt-cee-chat/merc-ng-core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  DependencyFailureLogger,
  TelemetryItemTranslator,
  WebVitalsInitializer,
} from 'projects/entities/src/lib/services/logging/';
import { AppStateService } from 'projects/entities/src/lib/services/app-state-service';

@Component({
  selector: 'merc-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    private appStateService: AppStateService,
    private environmentService: EnvironmentService,
    private loggingFactory: LoggingFactoryService,
    private analyticsApiService: NiagaraLogAnalyticsApiService,
    @Inject(AuthService) protected authService: AuthService,
    @Inject(TelemetryItemTranslator)
    private telemetryTranslator: TelemetryItemTranslator,
    @Inject(WebVitalsInitializer)
    private webVitals: WebVitalsInitializer
  ) { }

  ngOnInit() {
    this.initLogging();
  }

  private initLogging(): void {
    const { logging } = this.environmentService;
    const { windowId } = this.appStateService;

    if (!this.environmentService.isProd()) {
      this.loggingFactory.addLoggingProvider(new ConsoleLogger());
    }

    if (!logging) {
      return;
    }

    this.loggingFactory.setGlobalLoggingLevel(logging.level);

    const appInsightsInstance = this.AddApplicationInsights();

    this.AddNiagaraLogging(appInsightsInstance);

    this.loggingFactory.addLoggingProperty('windowId', windowId);
    this.AddWebVitalsLogging(appInsightsInstance);
  }

  private AddApplicationInsights(): ApplicationInsights {
    const { logging } = this.environmentService;
    const { enabled, connectionString } = logging.applicationInsights;

    if (!(enabled && connectionString)) {
      return null;
    }

    const appInsightsInstance = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        loggingLevelTelemetry: logging.level
      }
    });

    appInsightsInstance.loadAppInsights();

    const appInsightsLogger = new ApplicationInsightsLogger(appInsightsInstance);
    this.loggingFactory.addLoggingProvider(appInsightsLogger);

    return appInsightsInstance;
  }

  private AddNiagaraLogging(appInsights: ApplicationInsights) {
    if (!this.environmentService.logging.niagaraLog.enabled) {
      return;
    }

    const niagaraLogAnalyticsLogger = new NiagaraLogAnalyticsLogger(
      this.analyticsApiService,
      this.environmentService,
      this.authService
    );
    this.loggingFactory.addLoggingProvider(niagaraLogAnalyticsLogger);

    if (appInsights) {
      // DependencyFailureLogger hooks into App Insights and will log any critical failures to Niagara/NiFi
      const dependencyFailureLogger = new DependencyFailureLogger(
        niagaraLogAnalyticsLogger,
        this.telemetryTranslator
      );
      appInsights.addTelemetryInitializer(dependencyFailureLogger.logErrorEvent);
    }
  }

  private AddWebVitalsLogging(appInsights: ApplicationInsights) {
    if (appInsights) {
      const logger = this.webVitals.createWebVitalsLogger(appInsights);
      this.webVitals.addMetricTracking(logger);
    }
  }
}
