// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, ViewEncapsulation, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { CeeAccordionComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { EngagementTranscript } from 'projects/entities/src/lib/domain/models/chatTranscript';
import { Chat, ChatTranscript,
  ChatTranscriptInteraction, DispositionSelection,
  PriorEngagementAccordionChange, AppState, ChatActions} from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-convo-prior-engagement',
  templateUrl: './convoPriorEngagement.component.html',
  styleUrls: ['./convoPriorEngagement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoPriorEngagementComponent implements OnChanges {
  @Input() transcript: ChatTranscript;
  @Input() selectedActiveChat: Chat;
  @Input() isAsync: boolean = false;
  @ViewChild('transcriptContainer') transcriptContainer: ElementRef;
  @ViewChild('transcriptAccordion') transcriptAccordion: CeeAccordionComponent;

  title: string = 'No Topic';
  isActive: boolean = false;
  transcriptSummary: any; // doing any as there is no defined model for this: check getTranscriptSummary method return
  timestamp: string;

  constructor(private ngEntityStore: Store<AppState>) {

  }

    ngOnChanges(changes: SimpleChanges){
      for (const propName in changes) {
        if (changes.hasOwnProperty(propName)) {
          switch (propName) {
            case 'transcript': {
              if (this.transcript){
                this.title = this.getTitle(this.transcript.disposition);
                this.isActive = this.transcript.expanded;
                this.transcriptSummary = this.getTranscriptSummary(this.transcript);
              }
              break;
            }
          }
        }
      }
    }

  private getTranscriptSummary(transcript: ChatTranscript){
    return [
      {
        label: 'Topic',
        value: transcript && transcript.disposition && transcript.disposition.disposition
          ? `${transcript.disposition.disposition.displayText}`
          : 'No Topic'
      },
      {
        label: 'Queue',
        value: transcript && transcript.queue ? transcript.queue.name : 'No queue'
      },
      {
        label: 'Transfers',
        value: transcript ? transcript.timesTransferred : '0'
      },
      {
        label: 'Note',
        value: transcript && transcript.notes ? transcript.notes : 'No notes'
      }
    ];
  }

  getOffsetTop() {
    return this.transcriptContainer.nativeElement.offsetTop;
  }
  getHeight(){
    return this.transcriptContainer.nativeElement.scrollHeight;
  }

  private getTitle(disposition: DispositionSelection){
    return  disposition && disposition.disposition
      ? `${disposition.collectionDisplayText} > ${disposition.disposition.displayText}`
     : `${this.transcript.queue.name}`;
  }

  public accordionChange(){
    const accordionChangeArgs = new PriorEngagementAccordionChange();
    accordionChangeArgs.priorEngagement = this.transcript;
    accordionChangeArgs.chatId = this.selectedActiveChat.chatId;
    accordionChangeArgs.isActive = !this.isActive;
    this.ngEntityStore.dispatch(ChatActions.PriorEngagementAccordionChange(accordionChangeArgs));
  }

  trackByInteraction(_index: number, interaction: ChatTranscriptInteraction){
    return interaction.timestamp;
  }

  trackByTranscript(_index: number, transcript: EngagementTranscript){
    return transcript.engagementId;
  }
}
