// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromAgentFacingMetrics from './agent-facing-metrics.reducer';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';

@Injectable({
  providedIn: 'root'
})
export class AgentFacingMetricsPersisterService extends StatePersister<fromAgentFacingMetrics.State> {
  constructor(private timeService: DayTimeService) {
    super(localStorage, fromAgentFacingMetrics.featureKey);
  }

  afterGet(state: fromAgentFacingMetrics.State): fromAgentFacingMetrics.State {
    let newState = { ...state ?? fromAgentFacingMetrics.initialState };
    const halfDayLength = 12 * 60 * 60;
    const lastActivityTimestamp = newState?.lastDataRefreshTimestamp;
    const currentTime = this.timeService.unix();

    if (!lastActivityTimestamp || currentTime - lastActivityTimestamp > halfDayLength) {
        newState = { ...fromAgentFacingMetrics.initialState };
    }

    newState.lastDataRefreshTimestamp = currentTime;

    return newState;
  }
}
