// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { ConvoMessageContextMenuService } from 'src/app/components/convo-window/convoMessageContextMenu/convoMessageContextMenu.service';
import { AgentChatMessage } from '../domain/models/agent-chat-message';
import { Chat } from '../domain/models/chat';
import { ChatMessage } from '../domain/models/chatMessage';
import { CxGptResponseVm } from '../domain/models/cx-gpt-response';
import { ChatMessageType, SenderType } from '../domain/models/enums';
import { ChatDialog, NlpTranscript, OriginalCxGptResponse, OriginalResponse, OriginalSmartResponse, SentCxGptResponse, SentSmartResponse, Suggestion } from '../domain/models/NlpTranscript';
import { Suggestion as SuggestedMessage, XaSuggestionHeading } from '../domain/models/suggestion';
import { SmartResponses } from '../domain/smart-responses/smart-responses.model';
import { ChatHelper } from '../utils/chatHelper';

@Injectable({
	providedIn: 'root'
})
export class NlpTranscriptService {

  constructor(protected maskService: ConvoMessageContextMenuService) {}

	public getNlpTranscript(chat: Chat, smartResponses: SmartResponses[], cxGptResponses: CxGptResponseVm[]): NlpTranscript {
    const transcript = new NlpTranscript();
    transcript.engagementId = chat.chatId;
    transcript.conversationId = chat.conversationId;
    transcript.sessionId = chat.xaTranscriptSessionId;
    transcript.subject = chat.contactReason;
    transcript.secureDataRequests = chat.secureDataRequests; 
    transcript.featureFlags =  chat.agentsFeatureFlags;
    transcript.events = chat.events;
    transcript.xaTranscriptEndTimestamp = chat.xaTranscriptEndTimestamp ?? 0;
    transcript.customerLanguage = ChatHelper.getCustomerQueueLanguage(chat.customerLanguage, chat.defaultGroupTranslationLanguage);

    const dialogs: ChatDialog[] = [];
    chat.messages.forEach(m => {
      const message = m as ChatMessage;
      if (this.includeInTranscript(message)) {
        const dialog = new ChatDialog();
        const textToSend = message.maskedText ? message.maskedText : message.message;
        const translatedTextToSend = message.translatedMaskedText ? message.translatedMaskedText : message.translationData?.translatedMessage?.toString() ?? null;
        dialog.speaker = SenderType[message.sender];
        dialog.timestamp = message.timestamp;
        dialog.originalResponses = this.getOriginalResponses(chat, message.message);        
        dialog.suggestions = this.getSuggestions(chat, message.messageId);
        dialog.smartResponses = this.getOriginalSmartResponses(message.messageId, smartResponses);       
        dialog.sentSmartResponses = this.getSentSmartResponses(chat, m.messageId, message);  
        dialog.cxGptResponse = this.getOriginalCxGptResponse(message.messageId, cxGptResponses);       
        dialog.sentCxGptResponse = this.getSentCxGptResponse(chat, m.messageId, message);
        dialog.messageType = message.type;
        dialog.messageId = message.messageId;

        switch (message.type){
          case ChatMessageType.XaSuggestion:
            dialog.text = this.xaSuggestionText(message.message, message.xaMessage);
            const originalXaSuggestion = chat.suggestions.find(x => x.queryId === message.queryId);
            dialog.suggestion = {
              queryId: message.queryId,
              // originalSuggestionText will be '' if the suggestion isn't found
              // possible case when xaSuggestions are lost between transfer
              // setting modified to false since we don't know
              modified: originalXaSuggestion ?
                this.isXaSuggestionModified(originalXaSuggestion.heading, message.xaMessage, originalXaSuggestion.body.message, message.message) :
                false,
                autoPilot: message.autoPilot
            };
            break;
          default:
            dialog.text = message.maskedChunks ? this.maskService.createMaskString(message.maskedChunks, textToSend) : textToSend;
            dialog.translatedText = message.translatedMaskedChunks && translatedTextToSend ? this.maskService.createMaskString(message.translatedMaskedChunks, translatedTextToSend) : translatedTextToSend;
            dialog.isHistoric = message.isHistoric ?? false;
            break;
        }
        dialogs.push(dialog);
      }
    });
    transcript.dialog = dialogs;
    return transcript;
  }

  public getMessageCount(chat: Chat) {
    return chat.messages?.filter(f => (f.sender === SenderType.FixAgent || f.sender === SenderType.Requester) && f.type === ChatMessageType.Message && (<ChatMessage>f)?.message && (f.timestamp > chat.startTimestamp))?.length ?? 0;
  }

  private includeInTranscript(chatMessage: ChatMessage): boolean {
    return this.hasMessage(chatMessage) && this.isSent(chatMessage);
  }

  private hasMessage(chatMessage: ChatMessage): boolean {
    return Boolean(chatMessage && chatMessage.message);
  }

  private isSent(chatMessage: ChatMessage): boolean {
    return ChatHelper.isMessageSent((chatMessage as AgentChatMessage).status);
  }

  private getOriginalResponses(chat: Chat, text: string): OriginalResponse[] {
    let originalResponses: OriginalResponse[] = [];

    chat.scriptsSent.forEach(s => s.sentMessages.forEach(m => {
      if (m.message === text) {
        const originalResponse = new OriginalResponse();
        originalResponse.category = s.scriptGroupName;
        originalResponse.response = s.scriptValue;
        originalResponses.push(originalResponse);
      }
    }));

    if (originalResponses.length === 0) {
      originalResponses = null;
    }

    return originalResponses;
  }

  private getOriginalSmartResponses(messageId: string, smartResponses: SmartResponses[]): OriginalSmartResponse[] {
    let originalSmartResponses: OriginalSmartResponse[] = [];

    smartResponses?.forEach(s => {
      if (s.messageId === messageId) {
        s.smartResponses.forEach(f => {
        const originalSmartResponse: OriginalSmartResponse = {
         ...f,
         messageId
        };
        originalSmartResponses.push(originalSmartResponse);
      });
      }
    });

    if (originalSmartResponses.length === 0) {
      originalSmartResponses = null;
    }

    return originalSmartResponses;
  }

  private getOriginalCxGptResponse(messageId: string, cxGptResponses: CxGptResponseVm[]): OriginalCxGptResponse {
    let originalCxGptResponse: OriginalCxGptResponse;
    const cxGptResponse = cxGptResponses?.find(f => f?.messageId === messageId);

    if (cxGptResponse) {       
      originalCxGptResponse = {
        ...cxGptResponse,
        messageId
      }; 
    }
    return originalCxGptResponse;
  }

  private getSentCxGptResponse(chat: Chat, messageId: string, message: ChatMessage): SentCxGptResponse {    
    let sentCxGptResponses: SentCxGptResponse;

    const messageSent = chat.cxGptResponseSent?.find(w => w.sentMessageId === messageId);
    if (messageSent){
      const originalText = messageSent.cxGptResponse?.text;
      sentCxGptResponses = {           
        modified: ChatHelper.isTextModified(originalText, message.message),
        originalText: originalText
      };
    }  
    return sentCxGptResponses;
  }

  private getSentSmartResponses(chat: Chat, messageId: string, message: ChatMessage): SentSmartResponse[] {    
    let sentSmartResponses: SentSmartResponse[] = [];

    chat.smartResponsesSent?.filter(w => w.sentMessageId === messageId)?.forEach(s => {         
        s.smartResponses.forEach(f => {
        const originalText = f?.value;
        sentSmartResponses.push({
          id: f?.id,        
          modified: ChatHelper.isTextModified(originalText, message.message),
          originalText: originalText,
          type: f?.type,
          parentMessageId: s.parentMessageId,
          smartResponsesDisplayed: s.smartResponsesDisplayed
        });
      });
    });
    if (sentSmartResponses.length === 0) {
      sentSmartResponses = null;
    }
    return sentSmartResponses;
  }

  private getSuggestions(chat: Chat, messageId: string): Suggestion[] {
    let suggestions: Suggestion[] = [];
    const suggestedSuggestions: SuggestedMessage[] = [...chat.suggestions, ...chat.itgSuggestions, ...chat.schedulerSuggestions];
   
    suggestedSuggestions.forEach(s => {
      if (s.messageId === messageId) {
        const suggestion = new Suggestion();
        suggestion.intent = s.intent;
        suggestion.originalText = this.xaSuggestionText(s.body.message, s.heading);
        suggestion.queryId = s.queryId;
        suggestion.suggestionType = s.suggestionType;
        suggestion.suppressed = s.suppressed;
        suggestions.push(suggestion);
      }
    });

    if (suggestions.length === 0) {
      suggestions = null;
    }

    return suggestions;
  }

  

  private xaSuggestionText(bodyText: string, heading?: XaSuggestionHeading): string{
    let text: string = heading ? `${heading.title} ${heading.message} ` : '';
    text += bodyText;
    return text;
  }

  private isXaSuggestionModified(originalHeading: XaSuggestionHeading, currentHeading: XaSuggestionHeading, originalBodyText: string, currentBodyText: string){
    let isModified: boolean = false;
    // check if the heading was removed
    isModified = originalHeading ? currentHeading === null : false;
    if (ChatHelper.isTextModified(originalBodyText, currentBodyText)){
      isModified = true;
    }
    return isModified;
  }
}

