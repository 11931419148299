<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-edit-placeholder-wrapper">
  <ng-content></ng-content>
  <cee-button *ngIf="!disabled" type="option" customClass="merc-edit-placeholder-btn" (buttonClick)="editPlaceholder()">
    <svg class="cee-icon">
      <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-edit'"></use>
    </svg>
    Edit Placeholder
  </cee-button>
</div>
