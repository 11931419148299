<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-confirmation-modal
  headerText="Are you sure you want to logout?"
  [active]="active"
  confirmText="Yes, logout"
  cancelText="No, cancel"
  [clickOutsideToClose]="false"
  (modalClose)="closeModal()"
  (confirmClicked)="logout()"
  (cancelClicked)="closeModal()"
  *ngIf="active">
  <p>After clicking logout, your session will be completed.</p>
</cee-confirmation-modal>
