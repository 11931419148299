<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-acknowledgement">
  <svg class="cee-icon" [class.icon-animation]="!noAnimation">
    <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-' + icon"></use>
  </svg>
  <span class="merc-acknowledgement--message" [class.color-animation]="!noAnimation">
    {{message}}
  </span>
</div>
