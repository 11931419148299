// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ChatActions, PlaceholdersActions, SmartResponsesActions, UiActions } from '../actions';
import { Placeholder } from './placeholder.model';
import { PlaceholderHelper } from '../../utils/placeholder-helper';
import { PlaceholderVm } from '../models/placeholder-vm';
import { PlaceholderEditorState } from '../models/enums';

export const featureKey = 'placeholders';

export interface State extends EntityState<Placeholder> {
  editPanelPlaceholders: PlaceholderVm[];
  isEditPanelOpen: boolean;
  editPanelState: PlaceholderEditorState;
  editPanelChatId: string;
}

export const adapter: EntityAdapter<Placeholder> = createEntityAdapter<Placeholder>({
  selectId: (placeholder: Placeholder) => PlaceholderHelper.getSelectId(placeholder),
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  editPanelPlaceholders: [],
  isEditPanelOpen: false,
  editPanelState: PlaceholderEditorState.MessageEditor,
  editPanelChatId: ''
});

export const reducer = createReducer(
  initialState,
  on(PlaceholdersActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(PlaceholdersActions.chatsRemoved, (state, { chatIds }) => {
    return adapter.removeMany((placeholder: Placeholder) =>
      Boolean(chatIds.find(cId => cId === placeholder.chatId)), state);
  }),
  on(ChatActions.Closed,
    ChatActions.Bounced,
    ChatActions.Transferred, (state, { payload }) => {
      const {chatId} = payload;
      return adapter.removeMany((placeholder: Placeholder) => chatId === placeholder.chatId, state);
  }),
  on(SmartResponsesActions.updateSmartResponses, (state, { chatId, response }) => {
    const placeholders = PlaceholderHelper.extractPlaceholders(response, chatId);
    return adapter.upsertMany(placeholders, state);
  }),
  on(PlaceholdersActions.openEditPlaceholderPanel, (state, {editorState, chatId, placeholderKeys}) => {
    const editPanelPlaceholders = [];
    placeholderKeys?.forEach(key => {
      const id = PlaceholderHelper.getSelectId({chatId, key});
      const entity = state.entities[id];
      if (entity){
        const { label, options } = entity;
        const selectedValue = entity.selectedValue ?? '';
        const vm: PlaceholderVm = {
          key,
          label,
          options,
          selectedValue
        };
        editPanelPlaceholders.push(vm);
      }
    });

    return {
      ...state,
      editPanelPlaceholders,
      isEditPanelOpen: true,
      editPanelState: editorState,
      editPanelChatId: chatId
    };
  }),
  on(
    PlaceholdersActions.closeEditPlaceholderPanel,
    (state): State => ({
      ...state,
      isEditPanelOpen: false
    })
  ),
  on(
    UiActions.ToggleAgentSettingsPanel,
    (state, {isOpen}): State => {
      if (isOpen){
        return {
          ...state,
          isEditPanelOpen: false
        };
      }
      return state;
  }),
  on(
    UiActions.ToggleAgentSummaryPanel,
    (state, {toggleAgentSummaryPanel: isOpen}): State => {
      if (isOpen){
        return {
          ...state,
          isEditPanelOpen: false
        };
      }
      return state;
  }),
  on(
    PlaceholdersActions.submitEditPlaceholderPanel,
    (state, { formValues }): State => {
      const updates = [];
      const chatId = state.editPanelChatId;
      state.editPanelPlaceholders.forEach((p, index) => {
        const id = PlaceholderHelper.getSelectId({chatId, key: p.key});
        const entity = state.entities[id];

        if (entity && formValues.length > index && entity.selectedValue !== formValues[index]){
          updates.push({
            ...entity,
            selectedValue: formValues[index]
          });
        }
      });

      const updatedState = adapter.upsertMany(updates, state);

      return {
        ...updatedState,
        isEditPanelOpen: false
      };
    }
  ),
  on(ChatActions.AcceptNewChat, (state, {chat, placeHolders }) => {
    if (!placeHolders) { return state; }
    const updatedPlaceHolders = PlaceholderHelper.updateChatIdInPlaceholders(chat.chatId, placeHolders);
    return adapter.upsertMany(updatedPlaceHolders, state);
  })
);
