// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Chunk } from 'highlight-words-core';
import { ChatInteraction } from './chatInteraction';
import { CalendarSelectionType, ChatMessageStatus } from './enums';
import { XaSuggestionHeading } from './suggestion';
import { ChatMessageTranslationData } from './chatMessageTranslationData';

export class ChatMessage extends ChatInteraction {
    message: string;
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    timeRange?: string;
    selectedDate?: number;
    calendarSelectionType?: CalendarSelectionType;
    topic?: string;
    /**
     * An array of chunk objects representing both masked (highlight: true) and unmasked pieces of the message.
     */
    maskedChunks?: Chunk[];
    translatedMaskedChunks?: Chunk[];
    translatedMaskedText?: string;
    maskedText?: string;
    messageId?: string;
    xaMessage?: XaSuggestionHeading;
    queryId?: string;
    intent?: string;
    autoPilot?: boolean;
    isMasking?: boolean;
    translationData?: ChatMessageTranslationData;
    status?: ChatMessageStatus;
}
