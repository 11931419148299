// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromVoiceUi from './ui.reducer';
import { AppState } from '../../state';
import { getVoiceHubConnectionState } from '../../hubs.selectors';
import { HubConnectionState } from '@cxt-cee-chat/merc-ng-core';
import { HomeHeaderErrorState } from '../../models/voice/enums';

export const selectUiFeature = createFeatureSelector<AppState, fromVoiceUi.State>(
  fromVoiceUi.featureKey
);

export const getSingleColumnHomeDisplayMode = createSelector(
  selectUiFeature,
  (state: fromVoiceUi.State) => state.singleColumnHomeDisplayMode
);

export const getIsTranscriptFeedCollapsed = createSelector(
  selectUiFeature,
  (state: fromVoiceUi.State) => state.isTranscriptFeedCollapsed
);

export const getIsShowAgentTranscriptEnabled = createSelector(
  selectUiFeature,
  (state: fromVoiceUi.State) => state.isShowAgentTranscriptEnabled
);

export const getCallErrorState = createSelector(
  selectUiFeature,
  (state: fromVoiceUi.State) => state.callErrorState
);

export const getHeaderErrorState = createSelector(
  selectUiFeature,
  getVoiceHubConnectionState,
  (state: fromVoiceUi.State, voiceHubConnectionState: HubConnectionState) => {
    if (state.headerErrorState !== HomeHeaderErrorState.None) { return state.headerErrorState; }
    const reconnecting = voiceHubConnectionState === HubConnectionState.Reconnecting;
    // TODO: reconnecting && oneCti not connected = multiple but we need to know how to detect that
    if (reconnecting){
      return HomeHeaderErrorState.Temporary;
    }
    return HomeHeaderErrorState.None;
  }
);

// Delete/Rework once arch is finalized

export const getHasRecommendationsError = createSelector(
  selectUiFeature,
  (state: fromVoiceUi.State) => state.hasRecommendationsError
);
