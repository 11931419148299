<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-chat-toolbar" *ngIf="{
	undoButtonPreviousContent: (undoButtonPreviousContent$ | async),
    showUndoButton: (showUndoButton$ | async),
    askMeAnythingRewriteContent: (askMeAnythingRewriteContent$ | async),
    enhancedChatMessage: (enhancedChatMessage$ | async),
	enhanceMessagesFlag: (enhanceMessagesFlag$ | async),
	isLoadingEnhancedChatMessage: (isLoadingEnhancedChatMessage$ | async)
  } as data">
  <div class="merc-chat-toolbar-input">
    <cee-message-editor 
    #messageEditor
    [quickResponses]="quickResponses"
    (messageEditorSend)="onMessageInputSend($event, data.askMeAnythingRewriteContent, data.enhancedChatMessage)"
    (messageEditorChange)="onMessageChange($event)"
    (sendResponsesChange)="handleSendResponsesChange($event)"
    (editorCreated)="initializeEditor()"
    [tabColor]="color ? color.id : 'default'"
    [errorMessage]="errorMessage$ | async"
    (bannerDismiss)="onBannerDismiss($event)"
    (startsTyping)="onStartsTyping()"
    (stopsTyping)="onStopsTyping()"
    [channel]="(channel$ | async)"
    [showAutocomplete]="(scriptAutocompleteFeatureFlag$ | async) && (scriptAutocompleteSetting$ | async)"
    [changeEventDelay]=300
    [placeholder]="placeholderText$ | async"
    [isTargetWindowHyperlinkEnabled]="(linkEditorTargetFeatureFlag$ | async)"
    [languageTooltipText]="(shouldTranslateMessages$ | async) ? translationLanguage : null"
    [languageTooltipDirection]="'right'"
    [infoMessage]="(infoMessage$ | async)"
    [isLoading]="(isLoading$ | async)"
    [hideAnimation]="(hideAnimation$ | async)"
    [showEnhanceMessageButton]="data.enhanceMessagesFlag"
    [isEnhanceMessageLoading]="data.isLoadingEnhancedChatMessage"
    [isEnhanceMessageButtonDisabled]="isEnhanceMessageButtonDisabled(data.enhancedChatMessage)"
    (enhanceMessageClick)="onEnhanceMessageClick($event)"
    [showUndoButton]="data.showUndoButton"
    (undoMessageClick)="onUndoMessageClick(data.undoButtonPreviousContent, data.enhancedChatMessage)"
    ></cee-message-editor>
  </div>
</div>


