// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MercuryRoutes } from '../../constants/constants';
import { AppState, AgentStatePersisterService, AgentActions, AppActions } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { DayTimeService, DecrementingTimerService, TimerFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { take } from 'rxjs/operators';
import { fromAgent, fromHubs } from 'projects/entities/src/lib/domain/selectors';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { SessionTimeoutInformation } from 'projects/entities/src/lib/domain/models/sessionTimeoutInformation';

@Component({
  selector: 'merc-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTimeoutComponent extends SubscriberComponent implements OnInit, OnDestroy {
  logoffTimer: DecrementingTimerService;
  fullName: string;
  status: string;
  currentTime: any;
  cancelText: string = 'End Session';
  submitText: string = 'Login';
  ssoLogin: boolean;
  logoffCountdown: number;

  constructor(
    protected ngEntityStore: Store<AppState>,
    private ngZone: NgZone,
    private statePersister: AgentStatePersisterService,
    private router: Router,
    private timerService: TimerFactoryService,
    private timeService: DayTimeService
  ) {
    super();
  }

  ngOnInit() {
    // for refresh, when refreshed the initialized service endpoints and hubs are lost
    const hubsInitializedSub = this.ngEntityStore.select(fromHubs.getHubsInitialized).subscribe((response) => {
      if (!response) {
        this.ngEntityStore.dispatch(AppActions.initializeSessionTimeoutPageServices());
      }
    });
    this.subscriptions.push(hubsInitializedSub);

    const sessionTimeoutInfoSub = this.ngEntityStore.select(fromAgent.getSessionTimeoutInformation)
      .pipe(take(1))
      .subscribe((sessionTimeoutInformation) => {
        this.validateRoute(sessionTimeoutInformation);
        if (!sessionTimeoutInformation) { return; }

        this.ssoLogin = this.statePersister.authenticationProvider === 'nuanceSso';
        this.fullName = sessionTimeoutInformation.fullName;
        this.status = this.statePersister.availabilityStatus.reason;

        this.logoffTimer = this.timerService.createDecrementingTimer(this.ngZone);
        const initialStart = sessionTimeoutInformation.lockoutTimestamp;
        const secondsFromStart = this.timeService.unix() - initialStart;
        const timerDuration = sessionTimeoutInformation.settingDuration - secondsFromStart;
        this.logoffTimer.start(timerDuration);

        // format time
        const ms = (Date.now() - sessionTimeoutInformation.busyTimestamp);
        const timeInMinutes = Math.floor(ms / 60000);
        this.formatStatusTime(timeInMinutes);
        this.initializeLogOffTimer();
      }
    );
    this.subscriptions.push(sessionTimeoutInfoSub);
  }

  initializeLogOffTimer(){
    const logoffSub = this.logoffTimer.timerExpired.subscribe(() => {
        this.onSessionExpire();
      });
    this.subscriptions.push(logoffSub);
  }

  formatStatusTime(time: number) {
    // +1 to account for hour spent idle in mercury
    const hrs = Math.floor(time / 60);
    const mins = Math.floor(time % 60);
    const hourDesc = hrs > 1 ? 'hrs' : 'hr';
    const minDesc = mins > 1 ? 'mins' : 'min';

    return this.currentTime = `${hrs} ${hourDesc} ${mins} ${minDesc}`;
  }

  validateRoute(sessionTimeoutInformation: SessionTimeoutInformation) {
    if (!sessionTimeoutInformation) {
      this.router.navigate([MercuryRoutes.Home]);
    } else {
      this.ngEntityStore.dispatch(AgentActions.SessionLockoutPageLoad());
    }
  }

  onCancel(){
    this.ngEntityStore.dispatch(AgentActions.cancelSessionTimeout());
    this.statePersister.clearRegisteredId();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.logoffTimer){
      this.logoffTimer.dispose();
    }
  }

  onSubmit() {
    this.statePersister.clearRegisteredId();
    this.statePersister.clearSelectedGroupIds();
    this.ngEntityStore.dispatch(AgentActions.sessionTimeoutReauthenticate());
    this.redirectTo(MercuryRoutes.Home);
  }
  redirectTo(route: string){
    window.location.href = route;
  }

  onSessionExpire() {
    this.ngEntityStore.dispatch(AgentActions.sessionTimeoutExpired());
    if (this.logoffTimer.isRunning){
      this.logoffTimer.dispose();
    }
  }
}
