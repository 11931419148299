<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  showDispositions: (showDispositions$ | async),
  hasDispositions: (hasDispositions$ | async),
  isChatModalOpen: (isChatModalOpen$ | async),
  isConversationSummaryModalOpen: (isConversationSummaryModalOpen$ | async)
} as data">
<div *ngIf="data.hasDispositions && data.hasDispositions.length > 0" class="merc-dispositions-container" [ngClass]="{ 'hide-dispositions': !data.showDispositions || data.isChatModalOpen || data.isConversationSummaryModalOpen }">    
  <cee-button type="link" (click)="toggleDispositions(data.showDispositions)" class="merc-dispositions-trigger">
    <span data-qa="dispositions">
        <svg class="cee-icon cee-icon--secondary cee-icon--md">
          <use [attr.href]="'/assets/img/svg-sprite.svg#icon-conversations'"></use>
        </svg>
          Dispositions
    </span>
    <span *ngIf="data.showDispositions">
      <span class="cee-accessibly-hidden">Close</span>
        <svg class="cee-icon">
          <use href="/assets/img/svg-sprite.svg#icon-close"></use>
        </svg> 
    </span>
  </cee-button>
  <merc-dispositions *ngIf="data.showDispositions && !data.isChatModalOpen && !data.isConversationSummaryModalOpen" [parentContainer]="'in-chat'" [chatId]="chatId"></merc-dispositions>
</div>
</ng-container>