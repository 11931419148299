// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CustomerActivityStatus } from './enums';

export class LogCustomerActivityStatus {
  chatId: string;
  status: CustomerActivityStatus;
  lastStatus?: CustomerActivityStatus;
}
