<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{activeItgs: (activeItgs$ | async), suggestedActivity: suggestedActivity$ | async} as data">
  <cee-sliding-panel
    #slidingPanel
    *ngIf="data.suggestedActivity"
    [closePanelOnDismiss]="false"
    [showDismissButton]="false"
    (panelClosed)="handlePanelClosed($event)" 
  >
    <div class="merc-activity-header">
      <ng-container *ngTemplateOutlet="connectIcon"></ng-container>
      <h3 class="cee-h3">{{data.suggestedActivity?.itgIntent?.title ?? 'Activity'}}</h3>
    </div>
    <span *ngIf="data.suggestedActivity?.itgIntent?.suggestionTitle" class="merc-activity-description">{{data.suggestedActivity?.itgIntent?.suggestionTitle}}</span> 
    <div class="merc-activity-message-text" >
      <span>Message will be sent automatically.</span>
    </div>
        <div class="cee-suggestion-message-response--data-card"><span [innerHTML]="data.suggestedActivity?.itgIntent?.suggestionMessage | safeHtml"></span></div> 
      <div class="cee-suggestion-panel-content cee-suggestion-panel-content--button">
        <cee-button type="primary" (buttonClick)="startITG(data.suggestedActivity, data.activeItgs)">
          <ng-container *ngTemplateOutlet="connectIcon"></ng-container>
          Start {{data.suggestedActivity?.itgIntent?.title ?? 'Activity'}} Auto ITG
        </cee-button>
        <cee-button type="link" (buttonClick)="dismissActivity($event, data.suggestedActivity)">
          Not at this time
        </cee-button>
      </div>   
  </cee-sliding-panel>
</ng-container>
<ng-template #connectIcon>
  <svg
    class="cee-icon cee-icon--md"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <use xlink:href="/assets/img/svg-sprite.svg#icon-connect"></use>
  </svg>
</ng-template>
