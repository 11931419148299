<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{ pinnedMessages: pinnedMessages$ | async, parentChat: chat$ | async } as data">
<div class="merc-chat-pinned-messages" *ngIf="data.pinnedMessages?.length">
  <cee-accordion 
      #pinAccordion
      [titleText]="getAccordionTitle(data.pinnedMessages)"
      [active]="false"
      [type]="'secondary'"
      (accordionChange)="onAccordionChange($event, data.pinnedMessages?.length, data.parentChat)"
  >
      <ng-container>
        <ul class="cee-list-plain">
          <li *ngFor="let message of data.pinnedMessages">
            <div class="merc-chat-pinned-messages-message">
              <ng-container *ngIf="!isSystemMessage(message); else systemMessage">
                <ng-container *ngIf="isXaResponse(message.type); else participantMessage">
                  <merc-convo-message-xa-response
                      [showPin]="false"
                      [chatMessage]="message"
                      [chatId]="data.parentChat.chatId"
                      [channelType]="data.parentChat.channel?.type"
                      [colorId]="data.parentChat.color.id"
                      [timeZone]="data.parentChat.timeZone"
                      [participants]="data.parentChat?.users"
                      >
                  </merc-convo-message-xa-response>
                </ng-container>
                <ng-template #participantMessage>
                  <merc-convo-participant-message
                      type="simple"
                      [chatMessage]="message"
                      [chatId]="data.parentChat.chatId"
                      [channelType]="data.parentChat.channel?.type"
                      [colorId]="data.parentChat.color.id"
                      [timeZone]="data.parentChat.timeZone"
                      [participants]="data.parentChat?.users"
                      dataQa="pinned-chat-message"
                      [customerDetails]="data.parentChat?.customerDetails">
                  </merc-convo-participant-message>
                </ng-template>
              </ng-container>
              
              <ng-template #systemMessage>
                <merc-convo-system-message
                  [chatMessage]="message"
                  [chatId]="data.parentChat.chatId"
                  [channelType]="data.parentChat.channel?.type"
                  [colorId]="data.parentChat.color.id"
                  [timeZone]="data.parentChat.timeZone"
                  [showPin]="false">
                </merc-convo-system-message>
              </ng-template>
              <div class="merc-chat-pinned-messages-message-actions">
                
                <cee-button
                  type="link"
                  dataQa="clear-pin"
                  size="icon" 
                  (buttonClick)="removePin(message.messageId)"
                >
                <svg class="cee-icon">
                    <use xlink:href="/assets/img/svg-sprite.svg#icon-close"></use>
                </svg>
                </cee-button>
                <cee-button
                  type="link" 
                  size="xs" 
                  (buttonClick)="onJumpToMessage(message.messageId, data.parentChat)"
                >Jump to Message</cee-button>
              </div>
              
            </div>
          </li>
        </ul>
      </ng-container>
  </cee-accordion>
  <div class="merc-chat-pinned-messages-actions" data-qa="clear-all-pins">
    <cee-button type="link" size="icon" (buttonClick)="clearPins()"
      [tooltipId]="'clear-pins'" [tooltipText]="'Clear all pins'" tooltipWidth="108">
      <svg class="cee-icon cee-icon--md cee-icon--clear">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-delete"></use>
      </svg>
    </cee-button>
  </div>
</div>
</ng-container>
