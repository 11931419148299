// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewEncapsulation,
  Input
} from '@angular/core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AppState, ChatUiActions, DispositionCategory, DispositionSelection
} from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { fromChat, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-dispositions-container',
  templateUrl: './dispositions-container.component.html',
  styleUrls: ['./dispositions-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DispositionContainerComponent extends SubscriberComponent implements OnInit {
  hasDispositions$: Observable<DispositionCategory[]>;
  isChatModalOpen$: Observable<boolean>;
  isConversationSummaryModalOpen$: Observable<boolean>;
  showDispositions$: Observable<boolean>;
  @Input() chatId: string;
  @Input() selectedDisposition: DispositionSelection;

  constructor(
    private store: Store<AppState>,
  ) {
    super();
  }
  ngOnInit() {
    this.hasDispositions$ = this.store.select(fromChat.getDispositions);
    this.showDispositions$ = this.store.select(fromChat.getShowDispositions);
    this.isChatModalOpen$ = this.store.select(fromUi.getIsCloseChatModalOpen);
    this.isConversationSummaryModalOpen$ = this.store.select(fromUi.getIsConversationSummaryModalOpen);
  }

  toggleDispositions() {
    const payload = {
      chatId: this.chatId
    };

    this.store.dispatch(ChatUiActions.toggleShowDispositions(payload));
  }
}
