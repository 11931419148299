// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SuggestionType } from './enums';

export class SuggestionBase {
  heading?: XaSuggestionHeading;
  body: SuggestionBody;
  intent?: string;
  intentValue?: string;
  visible?: boolean = true;
  suggestionType?: SuggestionType = SuggestionType.Xa;
  calendar?: SuggestionCalendar;
  suppressed?: boolean = false;  
  title: string;
}

export class TransferredSuggestion extends SuggestionBase{
  json: string;
  //this the messageId from customer message that caused suggestion
  messageId: string;
  queryId: string;
}
export class Suggestion extends TransferredSuggestion {
  isLatest: boolean;
  timesSent: number;
}

export class SuggestionElement {
  message: string;
  image: string;
  visible?: boolean = true;
}

export class SuggestionButton {
  title: string;
  visible?: boolean = true;
}

export class Option
{
    order: number;
    label: string;
    type: string;
    Visible: boolean;
    Default: boolean;
    Disabled: boolean;
    Target: string;
}

export class XaSuggestionHeading extends SuggestionElement {
  title: string;
}

export class SuggestionBody extends SuggestionElement {
  buttons: SuggestionButton[];
}

export class SuggestionCalendarOption extends Option {
  options: Option[];
 }

export class SuggestionCalendar extends SuggestionElement {
  startDate: string;
  calendarOptions: SuggestionCalendarOption[];
}

export class IntentVm {
  intentCode: string;
  title: string;
  buttonTarget: string;
  reason: string;
  suggestionMessage: string;
  suggestionTitle: string;
}
