// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CxGptFeedbackVm } from './cx-gpt-feedback';

export class CxGptResponseVm {  
  text: string;
  messageId: string;
  isLatest: boolean;
  feedback?: CxGptFeedbackVm;
  sent: boolean;
}
