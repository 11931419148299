// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { Observable } from 'rxjs/internal/Observable';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { fromCall, fromSettings, fromVoiceUi } from 'projects/entities/src/lib/domain/selectors';
import { PageNames } from 'projects/entities/src/lib/constants/constants';
import { fromHubs } from 'projects/entities/src/lib/domain/selectors';
import { AppStateService } from 'projects/entities/src/lib/services/app-state-service';
import { AppActions, CallActions, FeatureFlags, VoiceUiActions } from 'projects/entities/src/public_api';
import { takeUntil } from 'rxjs/operators';
import { SingleColumnHomeDisplayMode } from 'projects/entities/src/lib/domain/models/voice/enums';


@Component({
  selector: 'merc-voice-home',
  templateUrl: './voice-home.component.html',
  styleUrls: ['./voice-home.component.scss', '../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceHomeComponent extends SubscriberComponent implements OnInit, OnDestroy {
  singleColumnHomeDisplayMode$: Observable<SingleColumnHomeDisplayMode>;
  isTranscriptFeedCollapsed$: Observable<boolean>;
  isSessionSummaryCollapsed$: Observable<boolean>;
  showSessionSummaryDrawer$: Observable<boolean>;
  showSessionSummaryModal$: Observable<boolean>;
  hasVoiceFeatureFlag$: Observable<boolean>;

  singleColumnHomeDisplayMode = SingleColumnHomeDisplayMode;
  pageName: string = PageNames.voice;
  constructor(
    private store: Store<AppState>,
    private appStateService: AppStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.singleColumnHomeDisplayMode$ = this.store.select(fromVoiceUi.getSingleColumnHomeDisplayMode);
    this.isTranscriptFeedCollapsed$ = this.store.select(fromVoiceUi.getIsTranscriptFeedCollapsed);
    this.isSessionSummaryCollapsed$ = this.store.select(fromCall.getIsSessionSummaryCollapsed);
    this.showSessionSummaryDrawer$ = this.store.select(fromCall.getShowSessionSummaryDrawer);
    this.showSessionSummaryModal$ = this.store.select(fromCall.getShowSessionSummaryModal);

    this.hasVoiceFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.VoiceAgent));

    this.subscriptions.push(
      this.appStateService.preventMultipleInstances().subscribe()
    );

    this.store.select(fromHubs.getHubsInitialized)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (!response) {
          this.store.dispatch(AppActions.VoiceAppInitialized());
        }
      });
  }

  toggleSessionSummaryCollapse(isCurrentlyCollapsed: boolean) {
    this.store.dispatch(CallActions.toggleSessionSummaryCollapse({ isCollapsed: !isCurrentlyCollapsed }));
  }

  toggleSingleColumnDisplayMode(buttonMode: SingleColumnHomeDisplayMode, currentMode: SingleColumnHomeDisplayMode) {
    const displayMode = currentMode !== buttonMode
      ? buttonMode
      : SingleColumnHomeDisplayMode.HighlightFeed;

      this.store.dispatch(VoiceUiActions.ToggleHomeDisplayMode({ displayMode }));
  }

  getSingleColumnButtonIcon(buttonMode: SingleColumnHomeDisplayMode, currentMode: SingleColumnHomeDisplayMode): string{
    if (currentMode === buttonMode){
      return 'close';
    }
    switch (buttonMode){
      case SingleColumnHomeDisplayMode.LiveTranscript:
        return 'message';
      case SingleColumnHomeDisplayMode.SessionSummary:
        return 'news';
      default:
        return 'close';
    }
  }

  getSingleColumnButtonTooltipText(buttonMode: SingleColumnHomeDisplayMode, currentMode: SingleColumnHomeDisplayMode): string{
    const isOpen = currentMode === buttonMode;
    switch (buttonMode){
      case SingleColumnHomeDisplayMode.LiveTranscript:
        if (isOpen) { return 'Close Transcript'; }
        return 'Live Transcript';
      case SingleColumnHomeDisplayMode.SessionSummary:
        if (isOpen) { return 'Close Summary'; }
        return 'Session Summary';
      default:
        return '';
    }
  }
}

