<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  headerText="Before you go"
  [active]="active"
  [controls]="controls"
  [clickOutsideToClose]="false"
  (modalClose)="closeModal()">
  <p>Please close any open conversations.</p>
</cee-modal>
