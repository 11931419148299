<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
    convoMessageContextMenu: (convoMessageContextMenu$ | async) || {},
    isChatHubConnected: (isChatHubConnected$ | async),
    isMaskingMessage: (isMaskingMessage$ | async)
  } as data">
  <cee-context-menu
    [options]="getOptions(data.convoMessageContextMenu, data.isChatHubConnected, data.isMaskingMessage)"
    [isOpen]="data.convoMessageContextMenu.open"
    [menuX]="data.convoMessageContextMenu.clientX"
    [menuY]="data.convoMessageContextMenu.clientY"
    (menuChange)="onMenuChange($event)"
    (optionClicked)="onOptClicked($event, data.convoMessageContextMenu)">
  </cee-context-menu>
</ng-container>
