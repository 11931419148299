<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-activities" *ngIf="{
  activeITGs: (activeITGs$ | async),
  inProgressITGs: (inProgressITGs$ | async),
  suggestedITGs: (suggestedITGs$ | async),
  chatTimeZone: (chatTimeZone$ | async)
} as data">
  <ng-container *ngIf="data.inProgressITGs?.length === 0; else inProgressTemplate">
    <div class="merc-activity merc-activity--empty">
      <em>No activities are in progress.</em>
    </div>
  </ng-container>
  <ng-template #inProgressTemplate>
    <ul class="merc-activities-list">
      <ng-container *ngFor="let itg of data.inProgressITGs">
        <li>
          <div class="merc-activity">
            <svg class="cee-icon cee-icon--md cee-icon--activity-in-prog"
              [class.cee-icon--activity-in-prog-complete]="itg.state === itgState.Completed"
              [class.cee-icon--activity-in-prog-error]="isErrorState(itg.state)"
            >
              <use [attr.href]="'/assets/img/svg-sprite.svg#icon-' + (itg.state | formatItgState:'icon')"></use>
            </svg>
            <div class="merc-activity--title">
              {{itg.state | formatItgState:'description':itg.title}}
              <div class="merc-activity--timestamp">
                {{ getTimestamp(itg.actions) | formatTimeSingleHourDigit:data.chatTimeZone }}
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </ng-template>
  <ng-container *ngIf="data.suggestedITGs.length > 0">
    <ul class="merc-activities-list">
      <ng-container *ngFor="let itg of data.suggestedITGs">
        <li>
          <div class="merc-activity merc-activity--flex">
            <span>{{itg.itgIntent.title}}</span>
            <cee-button
              type="secondary"
              (click)="startITG(itg, data.activeITGs)">
              <svg class="cee-icon">
                <use [attr.href]="'/assets/img/svg-sprite.svg#icon-connect'"></use>
              </svg>
              Start ITG
            </cee-button>
          </div>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>
