// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MercuryRoutes } from '../../constants/constants';
import { Store } from '@ngrx/store';
import { AgentAuthActions, AgentStatePersisterService, AppState,
  LogHelper, AgentOperations, AgentActions, AppActions } from 'projects/entities/src/public_api';
import { AuthService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { AgentGroup } from 'projects/entities/src/lib/domain/models/agentGroup';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { PageInitializationItem } from 'projects/entities/src/lib/domain/models/page-initialization-item';
import { PageInitializationConstants } from 'projects/entities/src/lib/constants/page-initialization.constants';
import { LoggedOutMethod } from 'projects/entities/src/lib/domain/models/enums';
import { fromAgent, fromApp, fromHubs } from 'projects/entities/src/lib/domain/selectors';
import { takeUntil } from 'rxjs/operators';
import { CheckBoxOption } from 'src/app/models/check-box-option';

@Component({
  selector: 'merc-advanced-login',
  templateUrl: './advancedLogin.component.html',
  styleUrls: ['../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedLoginComponent extends SubscriberComponent implements OnInit {
  showCheckboxError: boolean = false;
  isRouted: boolean = false;
  groupTotal: number = 0;
  options: CheckBoxOption[] = [];
  selectAllGroups: boolean = false;
  isPartialChecked: boolean = false;
  buttonDisabled: boolean = true;
  confirmLogoutModalActive: boolean = false;

  constructor(
    private router: Router,
    private statePersister: AgentStatePersisterService,
    protected ngEntityStore: Store<AppState>,
    private loggingFactory: LoggingFactoryService,
    private changeDetectionRef: ChangeDetectorRef,
	@Inject(AuthService) protected authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    this.validateAdvancedAgent();
    // allows app to initialize on refresh
    this.ngEntityStore.select(fromHubs.getHubsInitialized)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
          if (!response) {
              this.ngEntityStore.dispatch(AppActions.AppInitialized());
          }
      });

    // loads agentgroups
    this.ngEntityStore.dispatch(AgentActions.LoadAgentGroups());

    this.ngEntityStore.select(fromAgent.getAgentGroups)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((groups: AgentGroup[]) => {
        const agentOptions = [];
          groups.forEach(option => {
          agentOptions.push(option);
        });
        this.options = this.getOptions(agentOptions);
        this.changeDetectionRef.detectChanges();
      });

    this.ngEntityStore.select(fromApp.getPageInitializationErrors)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((pageInitErrors: PageInitializationItem[]) => {
        const agentGroupError = pageInitErrors.find(x => x.eventName === PageInitializationConstants.GetAgentGroups);
        if (agentGroupError){
          // if get agent groups fails, set the selectedGroups to [] (nuance will log them in with all groups)
          // then send the agent to the home page where they will be logged in
          this.statePersister.storeSelectedGroups([]);
          this.router.navigate([MercuryRoutes.Home]);
        }
      });
  }

  onSubmit() {
    if (this.options.length > 0) {
      const selectedAgentGroups = [];
        this.options.forEach(option => {
            if (option.checked === true) {
                delete option.checked;
                selectedAgentGroups.push(option);
            }
          });
        this.statePersister.storeSelectedGroups(selectedAgentGroups);

        this.isRouted = true;
        window.location.href = MercuryRoutes.Home;

        const logDimensions = Object.assign( {
            agentGroups: this.options,
            selectedAgentGroups,
            selectAll: this.selectAllGroups
        });
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.AdvancedLoginSubmit, logDimensions);
    } else {
        this.showCheckboxError = true;
    }
  }

  updateCheckAll() {
    this.groupTotal = this.options.filter(option => {
        return option.checked === true;
    }).length;

    this.selectAllGroups = this.groupTotal === this.options.length;
    this.isPartialChecked = this.groupTotal > 0 && this.groupTotal < this.options.length;
    this.updateButton();
  }

  checkAll() {
    if (this.selectAllGroups) {
        this.options.forEach(checkbox => checkbox.checked = true);
        this.groupTotal = this.options.length;
    } else {
        this.options.forEach(checkbox => checkbox.checked = false);
        this.groupTotal = 0;
        this.isPartialChecked = false;
    }

    this.updateButton();
  }

  updateButton() {
    this.buttonDisabled = this.groupTotal <= 0;
    this.updateGroupTotal();
  }

  updateGroupTotal() {
      return this.groupTotal;
  }

  logoutClicked(){
    this.confirmLogoutModalActive = true;
  }

  onCancelLogout() {
    this.confirmLogoutModalActive = false;
  }

  onConfirmLogout() {
    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.AdvancedLoginCancel);
    this.ngEntityStore.dispatch(AgentAuthActions.loggedOut(LoggedOutMethod.ManualAdvAgent));
    this.confirmLogoutModalActive = false;
  }

  private getOptions(agentGroups): CheckBoxOption[] {
    const agentOptions: CheckBoxOption[] = [];

    if (agentGroups && agentGroups.length > 0) {
        agentGroups.forEach(agentGroup => {
        const currentGroup: CheckBoxOption = {
          id: agentGroup.id,
          displayName: agentGroup.displayName,
          checked: false
        };
        agentOptions.push(currentGroup);
      });
    }
    return agentOptions;
  }

  private validateAdvancedAgent(): void {
    this.authService.getToken().then(token => {
        if (!token.advancedAgent) {
            this.router.navigate([MercuryRoutes.Home]);
        } else {
          const logDimensions = Object.assign({
            loginMethod: this.statePersister.authenticationProvider
          });
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.AdvancedLoginPageLoad, logDimensions);
        }
    });
  }
}
