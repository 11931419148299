// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AppState, ScriptActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { Script, ScriptsHelper } from 'projects/entities/src/lib/domain/scripts';
import * as fromScripts from 'projects/entities/src/lib/domain/scripts/scripts-selectors';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'merc-convo-script-panel-button',
  templateUrl: './script-panel-button.component.html',
  styleUrls: ['./script-panel-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptPanelButtonComponent implements OnInit {
  @Input() script: string;
  @Output() manageEditClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isCustomScript: boolean = false;
  public searchTerm$: Observable<string>;
  public scriptStatus$: Observable<string>;
  public script$: Observable<Script>;

  constructor(
    private store: Store<AppState>,
    private scriptsHelper: ScriptsHelper,
  ) {}

  ngOnInit() {
    const { script } = this;

    if (!script) {
      return;
    }

    this.script$ = this.store.select(fromScripts.getScript(script));
    this.searchTerm$ = this.store.select(fromScripts.selectManageScriptQuery);
    this.scriptStatus$ = this.store.select(fromScripts.getScriptStatus(script));
    
    this.isCustomScript = true;
  }

  scriptEditClicked() {
    this.manageEditClick.emit(true);

    const id = this.script;
    this.store.dispatch(ScriptActions.editScript({ id }));
  }

  scriptDeleteClicked() {
    const id = this.script;
    this.store.dispatch(ScriptActions.deleteScriptConfirmation({ id }));
  }

  public getScriptValue(searchTerm: string, script: Script): string {
    return this.scriptsHelper.getHighlightedScriptValue(searchTerm, script);
  }

}
