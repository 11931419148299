// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { ChatActions, CxGptResponsesAction } from '../actions';
import { LoadingState } from '../models/enums';
import { CxGptResponses } from './cx-gpt-responses.model';
import { CxGptResponseVm } from '../models/cx-gpt-response';
export const featureKey = 'cxGptResponses';

export interface State extends EntityState<CxGptResponses> { }

export const adapter: EntityAdapter<CxGptResponses> = createEntityAdapter<CxGptResponses>({
  selectId: (cxGptResponses: CxGptResponses) => cxGptResponses.chatId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({});

function getCxGptResponsesById({ chatId }: { chatId: string; }, state: State): CxGptResponses {
  return state.entities[chatId];
}

export const reducer = createReducer(
  initialState,
  on(CxGptResponsesAction.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(CxGptResponsesAction.chatsRemoved, (state, { chatIds }) => {
    return adapter.removeMany((cxGptResponses: CxGptResponses) =>
      Boolean(chatIds.find(cId => cId === cxGptResponses.chatId)), state);
  }),
  on(ChatActions.Closed,
    ChatActions.Bounced,
    ChatActions.Transferred, (state, { payload }) => {
      const {chatId} = payload;
      return adapter.removeMany((cxGptResponses: CxGptResponses) => chatId === cxGptResponses.chatId, state);
  }),
  on(CxGptResponsesAction.getCxGptResponses, (state, { chatId }) => {
    const cxGptResponsesData = getCxGptResponsesById({chatId}, state);
    const cxGptResponses = clearIsLatest(cxGptResponsesData?.cxGptResponses);
    const update: CxGptResponses = {
      chatId,      
      state: LoadingState.Pending,
      cxGptResponses
    };
    return adapter.upsertOne(update, state);
  }),
  on(CxGptResponsesAction.getCxGptResponsesFailed, (state, { chatId }) => {   
    const update: Update<CxGptResponses> = {
      id: chatId,
      changes: {
        state: LoadingState.Failed
      },
    };
    return adapter.updateOne(update, state);
  }),
  
  on(ChatActions.SendCxGptResponse, (state, { chatMessage }) => {
    const cxGptResponsesData = getCxGptResponsesById({chatId: chatMessage.chatId}, state);
    const cxGptResponsesUpdated = cxGptResponsesData.cxGptResponses.map((cxGptResponse: CxGptResponseVm) => {      
        return {
          ...cxGptResponse,
          sent: cxGptResponse?.isLatest
        };
    });  
    const update: Update<CxGptResponses> = {
      id: chatMessage.chatId,
      changes: {
        cxGptResponses : cxGptResponsesUpdated
      }
    };
    return adapter.updateOne(update, state);
  }),
  
  on(CxGptResponsesAction.updateCxGptResponses, (state, { chatId, messageId, response }) => {   
    const cxGptResponseData = getCxGptResponsesById({chatId}, state);
    const cxGptResponse: CxGptResponseVm = {
      messageId,
      isLatest: true,
      text: response?.text,
      sent: false
    };
    const cxGptResponses = [].concat(cxGptResponseData.cxGptResponses, cxGptResponse);
    const update: Update<CxGptResponses> = {
      id: chatId,
      changes: {
        state: LoadingState.Success,
        cxGptResponses
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(CxGptResponsesAction.updateCxGptResponseFeedback, (state, { chatId, feedbackData }) => {   
    const cxGptResponsesData = getCxGptResponsesById({chatId}, state);
    const cxGptResponsesUpdated = cxGptResponsesData.cxGptResponses.map((cxGptResponse: CxGptResponseVm) => {      
        return {
          ...cxGptResponse,
          feedback : cxGptResponse?.isLatest ? feedbackData?.feedback : cxGptResponse?.feedback
        };
    });  
    const update: Update<CxGptResponses> = {
      id: chatId,
      changes: {
        cxGptResponses : cxGptResponsesUpdated
      }
    };
    return adapter.updateOne(update, state);
  })
);
function clearIsLatest(cxGptResponses: CxGptResponseVm[]) {
  return cxGptResponses?.map((cxGptResponse) => {
    return {
      ...cxGptResponse,
      isLatest: false
    };
  });
}


