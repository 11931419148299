// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { ChannelType } from 'projects/entities/src/public_api';

/*
 * Formats a chat channel to be in an array
 * Usage:
 *   Channel | string[]
*/

@Pipe({
  name: 'formatChannels'
})
export class FormatChannelsPipe implements PipeTransform {
  transform(type: ChannelType) {
    const channels = [];

    switch (type)
    {
      case ChannelType.WebChat:
        channels.push('web');  
        break;
      case ChannelType.Sms:
        channels.push('sms');
        break;
    }

    return channels;
  }
}
