// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Input} from '@angular/core';
import { CloseAsyncChat } from 'projects/entities/src/lib/domain/models/closeAsyncChat';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, ChatActions, ChatOperations, FeatureFlags, LogActions, ProfanityService, UiActions } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { CloseMethod } from '@cxt-cee-chat/merc-pattern-lib';
import { CloseChat } from 'projects/entities/src/lib/domain/models/closeChat';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { DispositionSelection } from 'projects/entities/src/lib/domain/models';
import { LogInfo, LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { Observable } from 'rxjs/internal/Observable';
import { ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { ChatSummaryData } from 'projects/entities/src/lib/domain/models/chatSummaryData';
import { timer } from 'rxjs';
import { LoadingState } from 'projects/entities/src/lib/domain/models/enums';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'merc-end-session',
  templateUrl: './end-session.component.html',
  styleUrls: ['./end-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EndSessionComponent extends SubscriberComponent implements OnInit {
  active: boolean = false;
  isAsync: boolean;
  asyncResolution: string;
  disableConfirmation: boolean;
  copySummaryTooltipText: string = '';
  selectedDisposition: DispositionSelection;
  notesInputText: string = '';
  chatSummary$: Observable<ChatSummaryData>;
  hasDispositions$: Observable<boolean>;
  hasUnifiedNotesFeature$: Observable<boolean>;
  chatId$: Observable<string>;
  chatSummaryPayload$: Observable<string>;

  showDelayMessage: boolean = false;
  timeoutMessageDuration: number = 10000;

  loadingState = LoadingState;

  options = [
    {
      id: '',
      label: 'Please select a status'
    },
    {
      id: 'unresolved',
      label: 'Paused - Customer is not done yet'
    },
    {
      id: 'resolved',
      label: 'Resolved - Customer is all set'
    }
  ];

  @Input() chatId: string;

  constructor(
    private store: Store<AppState>,
    private clipboardService: ClipboardService,
    private changeDetection: ChangeDetectorRef,
    private profanityService: ProfanityService
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(fromChat.getSelectedChatIsAsync)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((isAsync: boolean) => {
        this.isAsync = isAsync;
        this.changeDetection.detectChanges();
      });

    this.store.select(fromChat.getSelectedDisposition)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((disposition: DispositionSelection) => {
        if (this.isAsync) { return; }

        if (disposition && disposition.collectionId) {
          const notes = disposition.notes ?? '';
          const profanity = this.profanityService.test(notes);
          this.selectedDisposition = disposition;

          if (profanity.length > 0) {
            this.disableConfirmation = true;
          } else {
            this.disableConfirmation = false;
          }
        } else {
          this.disableConfirmation = true;
        }
      });

    this.store.dispatch(UiActions.GetChatSummaryData({ chatId: this.chatId, traceId: Guid.raw() }));

    const delayMessageTimerSub = timer(this.timeoutMessageDuration).subscribe(() => {
      this.showDelayMessage = true;
      this.changeDetection.detectChanges();
    });
    this.subscriptions.push(delayMessageTimerSub);
    this.chatSummary$ = this.store.select(fromChat.getChatSummaryData(this.chatId));
    this.hasDispositions$ = this.store.select(fromChat.hasDispositions);
    this.hasUnifiedNotesFeature$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.UnifiedNotes));
    this.chatSummaryPayload$ = this.store.select(fromChat.getSummaryPayload(this.chatId));
    this.resetForm();
  }

  closeModal(method: CloseMethod): void {
    if (method !== CloseMethod.submit) {
      this.store.dispatch(UiActions.ExitPromptCloseEngagement({ method }));
    }
  }

  copyToClipboard(isAsync: boolean, hasDispositions: boolean, summaryText: string, summaryPayload: string, hasUnifiedNotesFeature: boolean) {
    let source = isAsync ? 'async resolved' : 'chat end';
    const operationName = ChatOperations.NotesCopied;
    const notes = hasUnifiedNotesFeature ? summaryPayload : this.notesInputText;
    const text = `Summary: ${summaryText}\nNotes: ${notes}\nChatId: ${this.chatId}`;
  
    if (isAsync && this.asyncResolution === 'unresolved') {
      source = 'async unresolved';
    } else if (hasDispositions) {
      source = 'disposition';
    }
  
    this.clipboardService.copyTo(text);
    this.copySummaryTooltipText = 'Copied!';
  
    const logPayload: LogInfo = {
      logType: LogType.chat,
      operationName,
      data: {
        source
      },
    };
  
    this.store.dispatch(LogActions.logSelectedChatAction({ logPayload }));
  }

  resetCopyText() {
    this.copySummaryTooltipText = '';
  }

  positiveRating() {
    const useNotes = true;
    this.store.dispatch(ChatActions.rateSessionSummary({ isPositiveRating: true, chatId: this.chatId, useNotes }));
  }

  negativeRating() {
    const useNotes = true;
    this.store.dispatch(ChatActions.rateSessionSummary({ isPositiveRating: false, chatId: this.chatId, useNotes }));
  }
 
  onTextFieldChange(fieldType: string, text: string) { 
    switch (fieldType) {
      case 'notes':
        this.notesInputText = text;
        break;
    }
  }  

  public closeChat(chatSummaryPayload: string): void {
    if (this.isAsync) {
      this.closeAsyncChat(chatSummaryPayload);
    } else {
      const closeChat: CloseChat = {
        chatId: this.chatId,
        dispositions: this.selectedDisposition
      };
      this.store.dispatch(ChatActions.Close(closeChat));
    }
  }

  private resetForm(): void {
    this.active = true;
    this.asyncResolution = '';
    this.changeDetection.detectChanges();
  }

  private closeAsyncChat(chatSummaryPayload: string): void {
    const resolved = this.asyncResolution === 'resolved';
    const closeModel: CloseAsyncChat = {
      chatId: this.chatId,
      resolved: resolved,
      contactReason: this.notesInputText ? this.notesInputText : chatSummaryPayload
    };
    this.store.dispatch(ChatActions.CloseAsyncChat(closeModel));
  }

  asyncOptionChange(selectedId: string) {
    this.store.dispatch(ChatActions.resetSessionSummaryRating({chatId: this.chatId}));
    this.asyncResolution = selectedId;
  }

  onCancelChatSummaryDataClicked() {
    this.store.dispatch(UiActions.CancelGetChatSummaryData({chatId: this.chatId}));
  }

  retryClicked(chatSummary: ChatSummaryData) {
    if (chatSummary.hasError){
      this.store.dispatch(UiActions.GetChatSummaryData({chatId: this.chatId, isRetry: true, traceId: Guid.raw()}));
    }
    else {
      this.store.dispatch(UiActions.RetryAutoSaveSummary({chatId: this.chatId}));
    }
  }

  shouldShowRetry(chatSummary: ChatSummaryData): boolean {
    if (chatSummary?.hasError){
      return !chatSummary?.getSummaryNumRetries;
    }
    return !chatSummary?.autoSaveNumRetries;
  }
}