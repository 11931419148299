// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Color } from './color';

export class TrackColorUse {
  useCount: number;
  color: Color;
}
