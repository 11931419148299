// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CustomEventDataLog } from './custom-event-data-log';

export class RenderEvent extends CustomEventDataLog{
  time: string;

  constructor(name: string, dimensions: {}) {
    super(name, dimensions);
    Object.assign(this, dimensions);
    this.name = name;
  }
}