// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {ChatState, CtiFocusApplicationType, CustomerActivityStatus} from 'projects/entities/src/lib/domain/models/enums';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Store} from '@ngrx/store';

import {
  AppState,
  ChatInteraction,
  hideAnimations,
  getLastChatMessage,
  getChatState, ChatUiActions, getAgentRespondedStatus,
  ChatActions,
  Chat,
  ChatAutomationSettings,
  ChatContext,
  ChatOperations, getChatAutomationSettings,
  getChatChannel,
  getChatCustomerActivityStatus,
  getChatCustomerName,
  getChatDeviceType,
  getChatNotificationCount,
  isChatPinned,
  isCustomerTyping,
  isSelectedChat,
  SystemMessageType,
  FeatureFlags
} from 'projects/entities/src/public_api';
import {SubscriberComponent} from 'src/app/subscribed-container';
import {distinctUntilChanged, filter, map, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {
  ACSR, CTI_NOT_CONNECTED_TOOLTIP, ACCOUNT_NOT_CONNECTED_TOOLTIP, ECM, EINSTEIN360,
  EINSTEINCHAT,
  PIN,
  PIN_DISABLED_TOOLTIP,
  PIN_DISABLED_TOOLTIP_SUBTEXT,
  PIN_DISABLED_RESPOND_TOOLTIP_SUBTEXT,
  PIN_SVG_CLASS_PINNED,
  PIN_SVG_CLASS_UNPINNED,
  PIN_SVG_PATH_PINNED,
  PIN_SVG_PATH_UNPINNED,
  SECURE_DATA_COLLECTION,
  TYPING_ELLIPSIS_ANIMATED_CLASS,
  TYPING_ELLIPSIS_UNANIMATED_CLASS
} from './constants';
import {ObservableHelper} from 'src/testing/observable-helper';
import {ConvoCardService} from './convo-card.service';
import {ConvoMedium} from './convo-medium';
import {DayTimeService} from '@cxt-cee-chat/merc-ng-core';
import {CtiFocusApplication} from '../../../../../projects/entities/src/lib/domain/models/ctiFocusApplication';
import {logChatAction} from '../../../../../projects/entities/src/lib/domain/log-actions.actions';
import {LogInfo, LogType} from '../../../../../projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { CustomerActivityTimerDetails } from 'projects/entities/src/lib/domain/models/customer-activity-timer-details';


@Component({
  selector: 'merc-convo-card',
  templateUrl: './convo-card.component.html',
  styleUrls: ['./convo-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoCardComponent extends SubscriberComponent implements OnInit{
  public pinDisabledTooltipText: string = PIN_DISABLED_TOOLTIP;
  public pinDisabledTooltipSubtext: string = PIN_DISABLED_TOOLTIP_SUBTEXT;
  public pinDisabledRespondTooltipSubtext: string = PIN_DISABLED_RESPOND_TOOLTIP_SUBTEXT;

  private _chatId: string;
  public chatContext: ChatContext;

  @Input() public chat: Chat;
  @Input() hasAbandonedChatAutomation: boolean;
  @Input() hasContextMenu: boolean;


  public isSelected$: Observable<boolean>;
  public notificationCount$: Observable<number>;
  public customerName$: Observable<string>;
  public ellipsisClass$: ReplaySubject<string>;
  public customerIsTyping$: Observable<boolean>;
  public isPinned$: Observable<boolean>;
  public automationSettings$: Observable<ChatAutomationSettings>;
  public previewText$: ReplaySubject<string>;
  public conversationInfo$: Observable<ConvoMedium[]>;
  public showInactiveWarningPinTimer$: Observable<boolean>;
  public hasSecureDataCollectionFeatureFlag$: Observable<boolean>;
  public secureDataCollectionOptionText$: Observable<string>;
  public isSecureDataCollectionButtonEnabled$: Observable<boolean>;
  public isAccountConnected$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  public isCtiConnected$: Observable<boolean>;
  public isItgRunning$: Observable<boolean>;
  public hasEinsteinChatFeatureFlag$: Observable<boolean>;
  public isAutomationPinningEnabled$: Observable<boolean>;
  public hasCustomerRespondedToAgentInitialMessage$: Observable<boolean>;
  public hasAgentRespondedToCustomer$: Observable<boolean>;
  public customerActivityStatus$: Observable<CustomerActivityStatus>;

  public pinToolTipText$: ReplaySubject<string>;
  public pinToolTipSubtext$: ReplaySubject<string>;
  public pinSvgClass$: ReplaySubject<string>;
  public pinSvgPath$: ReplaySubject<string>;

  public convoColorId: number;
  public automationSettings: ChatAutomationSettings;
  public ctiNotConnectedTooltip: string = CTI_NOT_CONNECTED_TOOLTIP;
  public accountNotConnectedTooltip: string = ACCOUNT_NOT_CONNECTED_TOOLTIP;

  public get conversationColorClass(): string {
    return `cee-card--conversation--${this.convoColorId}`;
  }

  // context Menu variables
  disabledText = 'No options available';
  highLightColor: string;

  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService,
    private componentService: ConvoCardService,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    super();

    this.previewText$ = new ReplaySubject<string>();
    this.conversationInfo$ = new ReplaySubject<ConvoMedium[]>();
    this.ellipsisClass$ = new ReplaySubject<string>();
    this.pinToolTipText$ = new ReplaySubject<string>();
    this.pinSvgClass$ = new ReplaySubject<string>();
    this.pinSvgPath$ = new ReplaySubject<string>();
  }

  public ngOnInit(): void {
    this._chatId = this.chat.chatId;
    if (!this.chatContext){
      this.chatContext = this.componentService.getChatContext(this.chat, this.hasAbandonedChatAutomation);
    }

    this.convoColorId = this.chat.color.id;
    this.notificationCount$ = this.store.select(getChatNotificationCount(this._chatId));
    this.customerName$ = this.store.select(getChatCustomerName(this._chatId));
    this.isSelected$ = this.store.select(isSelectedChat(this._chatId));
    this.customerIsTyping$ = this.store.select(isCustomerTyping(this._chatId));
    this.isPinned$ = this.store.select(isChatPinned(this._chatId));
    this.automationSettings$ = this.store.select(getChatAutomationSettings(this._chatId));
    this.showInactiveWarningPinTimer$ = this.store.select(fromChat.getChatShowInactiveWarningPinTimer(this._chatId));
    this.hasSecureDataCollectionFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.SecureDataCollection));
    this.isCtiConnected$ = this.store.select(fromChat.getIsOneCtiConnected(this._chatId));
    this.isSecureDataCollectionButtonEnabled$ = this.store.select(fromChat.getSecureDataCollectionButtonEnabled(this._chatId));
    this.isAccountConnected$ = this.store.select(fromChat.getSelectedChatIsAccountConnected).pipe(distinctUntilChanged());
    this.isAuthenticated$ = this.store.select(fromChat.getSelectedChatAuthenticated).pipe(distinctUntilChanged());
    this.secureDataCollectionOptionText$ = this.store.select(fromChat.getSecureDataCollectionButtonText(this._chatId));
    this.isItgRunning$ = this.store.select(fromChat.getIsItgRunning(this._chatId));
    this.hasEinsteinChatFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.EinsteinChat));
    this.isAutomationPinningEnabled$ = this.store.select(fromChat.getIsAutomationPinningEnabled(this._chatId));
    this.hasCustomerRespondedToAgentInitialMessage$ = this.store.select(fromChat.getHasCustomerRespondedToAgentInitialMessage(this._chatId));
    this.hasAgentRespondedToCustomer$ = this.store.select(fromChat.getHasAgentRespondedToCustomer(this._chatId));
    this.customerActivityStatus$ = this.store.select(getChatCustomerActivityStatus(this._chatId));

    const agentResponded = this.store.select(getAgentRespondedStatus(this._chatId)).subscribe((status) => {
      if (status) {
        this.stopBounceTimer();
      }
    });
    this.subscriptions.push(agentResponded);

    if (this.hasAbandonedChatAutomation){
      this.store.select(fromChat.getChatCustomerActivityTimerDetails(this._chatId))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((timerDetails: CustomerActivityTimerDetails) => {
          // when duration is 0, we just need to stop the timer,
          // if there is a value we should start the timer with the proper duration
          if (this.chatContext.customerActivityTimer){
            if (this.chatContext.customerActivityTimer.isRunning){
              this.chatContext.customerActivityTimer.stop();
              this.chatContext.engagementInactiveWarningTimer.stop();
            }
            if (timerDetails?.duration){
              this.componentService.startCustomerActivityTimer(this.chatContext, timerDetails);
            }
          }
        });
    }

    this.automationSettings$
      .pipe(
        filter((automationSettings) => Boolean(automationSettings)),
        take(1),
        takeUntil(this.destroyed$))
      .subscribe((automationSettings: ChatAutomationSettings) => {
        this.automationSettings = automationSettings;
        if (automationSettings?.pinningEnabled) {
          const pinSub = this.isPinned$.subscribe(pinned => {
              let toolTipText, pinSvgClass, pinSvgPath;

              if (pinned) {
                pinSvgClass = PIN_SVG_CLASS_PINNED;
                pinSvgPath = PIN_SVG_PATH_PINNED;
                toolTipText = this.componentService.getPinnedTooltipText(automationSettings.pinDuration);
              }
              else {
                pinSvgClass = PIN_SVG_CLASS_UNPINNED;
                pinSvgPath = PIN_SVG_PATH_UNPINNED;
                toolTipText = this.componentService.getUnpinnedTooltipText(automationSettings.pinDuration);
              }
              this.pinToolTipText$.next(toolTipText);
              this.pinSvgClass$.next(pinSvgClass);
              this.pinSvgPath$.next(pinSvgPath);

            });
          this.subscriptions.push(pinSub);

          const timeRemainingSub = this.chatContext.customerActivityTimer?.value
            .pipe(withLatestFrom(this.isPinned$), takeUntil(this.destroyed$))
            .subscribe(([timeRemaining, pinned]) => {
              if (pinned) {
                this.pinToolTipText$.next(this.componentService.getPinnedTooltipText(timeRemaining));
                // force change detection because timers are running outside of angular zone
                this.changeDetectionRef.detectChanges();
              }
            });
          // was pushing 'undefined' to subscriptions since we do a null check for customerActivityTimer
          if (timeRemainingSub) { this.subscriptions.push(timeRemainingSub); }
        }
      });

    const hideAnimationsSub = this.store
      .select(hideAnimations)
      .pipe(distinctUntilChanged())
      .subscribe((hide: boolean) => {
        const ellipsisClass = hide ? TYPING_ELLIPSIS_UNANIMATED_CLASS : TYPING_ELLIPSIS_ANIMATED_CLASS;
        this.ellipsisClass$.next(ellipsisClass);
      });
    this.subscriptions.push(hideAnimationsSub);

    const activityStatusSub = combineLatest([
      this.store.select(getLastChatMessage(this._chatId,
        (msg: ChatInteraction) => {
          return msg.systemType !== SystemMessageType.Navigation;
        })),
      this.customerActivityStatus$,
      this.store.select(fromChat.getChatAutomationWarningIndex(this._chatId)),
      this.store.select(fromChat.getRunningItg(this._chatId)),
      this.store.select(fromChat.getSecureDataCollectionState(this._chatId)),
      //Todo: replace this with a new selector that checks both the flag and setting once setting is hooked up
      this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator))
    ])
      .subscribe(([lastMessage, status, warningIndex, isItgRunning, secureDataCollectionState, hasLanguageTranslatorFeatureFlag]) => {
        const previewText = this.componentService.getPreviewText(this.chat, status, lastMessage, this.automationSettings, warningIndex, isItgRunning, secureDataCollectionState, hasLanguageTranslatorFeatureFlag);
        this.previewText$.next(previewText ?? '');
      });
    this.subscriptions.push(activityStatusSub);

    this.subscriptions.push(this.store.select(fromChat.getChatMessages(this._chatId))
      .subscribe((messages) => {
        const responseTimerValue = this.componentService.getResponseTimerStartValue(messages ?? []);
        if (this.chatContext.agentResponseTimer.isRunning){
          this.chatContext.agentResponseTimer.stop();
        }
        if (responseTimerValue >= 0) {
          this.chatContext.agentResponseTimer.start(responseTimerValue);
        }
      })
    );

    this.conversationInfo$ = combineLatest([
      this.store.select(getChatChannel(this._chatId)),
      this.store.select(getChatDeviceType(this._chatId))
      ]).pipe(
        filter(([channel, deviceType]) => channel && deviceType ? true : false),
        map(([channel, deviceType]) =>
          this.componentService.getConvoMediums(channel, deviceType)
        )
      );

    const chatStateSub = this.store.select(getChatState(this._chatId)).subscribe((state: ChatState) => {
        switch (state){
          case ChatState.Disconnected:
          case ChatState.Closed: {
            if (this.chatContext.chatDurationTimer.isRunning) {
              this.chatContext.chatDurationTimer.stop();
            }
            if (this.chat.autoCloseChat && !this.chatContext.autoCloseTimer.isRunning){
              this.chatContext.autoCloseTimer.start(0, this.chat.autoCloseTimer);
            }
            break;
          }
          default: {
            if (!this.chatContext.chatDurationTimer.isRunning) {
              this.chatContext.chatDurationTimer.start(this.timeService.unix() - this.chat.startTimestamp);
            }
            if (this.chat.autoCloseChat && this.chatContext.autoCloseTimer.isRunning){
              this.chatContext.autoCloseTimer.stop();
            }
            break;
          }
        }
      });
    this.subscriptions.push(chatStateSub);
  }

  public onClick() {
    const { chat } = this.chatContext;
    const { chatId } = chat;
    const lastViewedTimestamp = this.timeService.unix();
    this.store.dispatch(ChatUiActions.agentSelectedChat({ chatId, lastViewedTimestamp }));
    this.stopBounceTimer();
  }

  public async onPinClick() {
    const isPinned: boolean = !await ObservableHelper.getValue<boolean>(this.isPinned$);
    const pinnedElapsed = isPinned ? 0 : await ObservableHelper.getNumber(this.chatContext.customerActivityTimer.value);
    this.store.dispatch(ChatUiActions.chatAutomationPinClicked({chatId: this._chatId, isPinned, pinnedElapsed}));
    if (isPinned){
      this.chatContext.engagementInactiveWarningTimer.stop();
    }
  }

  private stopBounceTimer(): void{
    if (this.chatContext?.bounceTimer?.isRunning) {
      this.chatContext.bounceTimer.stop();
      // force change detection because timers are running outside of angular zone
      this.changeDetectionRef.detectChanges();
    }
  }

  focusButtonClick(){
    const focusModel = new CtiFocusApplication();
    focusModel.chatId = this._chatId;
    const logPayload  = {
      logType: LogType.chat,
      operationName: ChatOperations.ApplicationFocus, 
      chat: this.chat,
      data: {
        method: 'card-button',
        appName: EINSTEIN360
      }
    };
    focusModel.applicationFocusType = CtiFocusApplicationType.Einstein360;
    this.store.dispatch(ChatActions.CtiApplicationFocus(focusModel));
    this.store.dispatch(logChatAction({logPayload}));

  }

  onOptClicked(optionId) {
    const focusModel = new CtiFocusApplication();
    let logPayload: LogInfo;
    const logDimensions: any = {
      method: 'card-menu',
       appName: optionId
    };
    focusModel.chatId = this._chatId;
    switch (optionId){
      case EINSTEIN360:
        focusModel.applicationFocusType = CtiFocusApplicationType.Einstein360;
        this.store.dispatch(ChatActions.CtiApplicationFocus(focusModel));
        break;
      case EINSTEINCHAT:
        this.store.dispatch(ChatActions.EinsteinChatButtonClicked());
        break;
      case ECM:

        this.store.dispatch(ChatActions.ECMButtonClicked());
        break;
      case ACSR:
        focusModel.applicationFocusType = CtiFocusApplicationType.Acsr;
        this.store.dispatch(ChatActions.CtiApplicationFocus(focusModel));
        break;
      case PIN:
        this.onPinClick();
        break;
      case SECURE_DATA_COLLECTION:
        this.store.dispatch(ChatActions.StartDataCollectionRequest({chatId: this._chatId, startTime: this.timeService.unix()}));
        break;
    }
    //log the option clicked we will combine these in the next PR
    logPayload = {
      logType: LogType.chat,
      operationName: ChatOperations.ApplicationFocus,
      chat: this.chat,
      data: logDimensions
    };
    this.store.dispatch(logChatAction({ logPayload }));
  }

  showClosingTimer(customerActivityStatus: CustomerActivityStatus, timerValue: number) {
    return customerActivityStatus === CustomerActivityStatus.InactiveClosing && timerValue <= 15;
  }
}
