<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<!-- TODO: is there a better way to reuse subscriptions. maybe https://ngrx.io/guide/component/let or a wrapper component? -->
<div class="merc-chat-detail-item merc-chat-detail-item--responses">
<ng-container *ngIf="{
  isSearching: (isSearching$ | async),
  showSystemScripts: (showSystemScripts$ | async),
  enableCustomScripts: (enableCustomScripts$ | async),
  customScriptGroups: (customScriptGroups$ | async)
} as data">
<div>
  <cee-script-search
    #scriptSearch
    [isExpanded]="(isExpandAllScripts$ | async) || data.isSearching"
    [scriptSearchId]="scriptSearchId"
    [showSystemScripts]="data.showSystemScripts"
    [searchInput]="(searchTerm$ | async)"
    [hideShowSystemScriptOption]="!data.enableCustomScripts"
    [hideScriptOptions]="!chatId"
    (scriptSearchChange)="onSearchChange($event)"
    (scriptExpandedChange)="onExpandedChange(data.isSearching, $event)"
    (scriptSystemChange)="onSystemScriptChange()">
  </cee-script-search>
  <div *ngIf="data.isSearching">
    <merc-convo-script-search-results-banner
      [hasCustomScriptGroups]="data.customScriptGroups.length > 0"
      [searchSystemScriptsCount]="(scriptFilterCount$ | async)"
      [showSystemScripts]="data.showSystemScripts"
      (clearSearchClicked)="clearSearch()"
      (toggleShowSystemScripts)="onSystemScriptChange()">
    </merc-convo-script-search-results-banner>
  </div>
</div>
  <div [hidden]="!chatId" class="merc-scripts-content" (scroll)="onScroll($event)"  #scrollWindow>
    <ng-container *ngIf="data.enableCustomScripts && data.customScriptGroups" >
      <ng-container *ngIf="data.customScriptGroups.length === 0" >
        <cee-accordion
        [titleText]="'Custom Responses'"
        [customClass]="'cee-capitalize'"
        [active]=""
        [type]="">
        <ng-container >
          <div *ngIf="!data.isSearching" class="cee-scripts-message">
            You haven't created any custom responses yet! Select Create custom response in the Responses submenu to begin.
          </div>
        </ng-container>
      </cee-accordion>
      </ng-container>
      <ng-container *ngFor="let scriptGroup of data.customScriptGroups" >
        <merc-convo-script-group
          [chatId]="chatId"
          [chatScriptTreeId]="scriptTreeId"
          [scriptTreeId]="scriptGroup.scriptTreeId"
          [scriptGroupId]="scriptGroup.id">
        </merc-convo-script-group>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data.showSystemScripts">
      <ng-container *ngFor="let scriptGroup of (systemScriptGroups$ | async)" >
        <merc-convo-script-group
          [chatId]="chatId"
          [chatScriptTreeId]="scriptTreeId"
          [scriptTreeId]="scriptGroup.scriptTreeId"
          [scriptGroupId]="scriptGroup.id">
        </merc-convo-script-group>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
</div>

<merc-convo-script-context-menu></merc-convo-script-context-menu>
