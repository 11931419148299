// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, BannerTypes, UiActions } from 'projects/entities/src/public_api';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { Observable } from 'rxjs';
import { fromUi } from 'projects/entities/src/lib/domain/selectors';

@Component({
  selector: 'merc-header-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationBannerComponent extends SubscriberComponent implements OnInit {
  bannerType: BannerTypes = BannerTypes.Error;

  bannerText$: Observable<string>;
  showBanner$: Observable<boolean>;

  constructor(
    private store: Store<AppState>
  )
  {
    super();
  }

  ngOnInit() {
    this.bannerText$ = this.store.select(fromUi.getNotificationBounceText);
    this.showBanner$ = this.store.select(fromUi.getShowNotificationBanner);
  }

  onDismiss() {
    this.store.dispatch(UiActions.dismissNotificationBanner());
  }
}
