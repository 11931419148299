// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SystemMessageType, SystemInfoMessageType } from './enums';
import { ChatMessage } from './chatMessage';

export class SystemChatMessage extends ChatMessage {
    systemType: SystemMessageType;
    systemInfoMessageType?: SystemInfoMessageType;
}
