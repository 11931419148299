// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { SuggestionType } from 'projects/entities/src/lib/domain/models/enums';
import { SendSuggestion } from 'projects/entities/src/lib/domain/models/requests/send-suggestion';
import { Suggestion, XaSuggestionHeading } from 'projects/entities/src/lib/domain/models/suggestion';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { AppState, ChatActions, ChatHelper } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SuggestionService } from 'src/app/services/suggestion.service';

@Component({
  selector: 'merc-convo-suggestions',
  templateUrl: './convo-suggestions.component.html',
  styleUrls: ['./convo-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoSuggestionsComponent implements OnInit {
  @Input() chatId: string;
  public latestSuggestion$: Observable<Suggestion[]>;
  public hideAnimation$: Observable<boolean>;
  SuggestionType = SuggestionType;

  constructor(private store: Store<AppState>,
    private suggestionService: SuggestionService,
    private timeService: DayTimeService) {
   }

  ngOnInit() {
    this.latestSuggestion$ = this.store
      .select(fromChat.getLatestSuggestions)
      .pipe(distinctUntilChanged());

    this.hideAnimation$ = this.store
      .select(fromSettings.hideAnimations);
  }

  handlePanelSend(_event: any, suggestion: Suggestion) {
    this.suggestionService.handleSendSuggestion(_event, suggestion, this.chatId);
  }

  formatDataCard(xaSuggestionHeading: XaSuggestionHeading) {
    if (xaSuggestionHeading) {
      let result = xaSuggestionHeading.title ? `<strong>${xaSuggestionHeading.title}</strong>\n` : '';
      result += xaSuggestionHeading.message;
      return result;
    }
  }

  getTitle(title: string, suggestionType: SuggestionType){
    return ChatHelper.getSuggestionTitle(title, suggestionType);
  }

  handlePanelClosed(_event: any) {
    this.store.dispatch(ChatActions.ClearLatestSuggestions(this.chatId));
  }

  handlePanelDismissed(_event: any, suggestion: Suggestion) {
    const useSuggestionArgs = Object.assign(
      new SendSuggestion(),
      suggestion,
      {chatId: this.chatId}
    );
    this.store.dispatch(ChatActions.DismissLatestSuggestions({sendSuggestion: useSuggestionArgs, isIgnored: false, timestamp: this.timeService.unix()}));
  }

  public trackByQueryId(_index: number, suggestion: Suggestion) {
    return suggestion.queryId;
  }
}
