// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-settings-banner',
  templateUrl: './settings-banner.component.html',
  styleUrls: ['./settings-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsBannerComponent extends SubscriberComponent implements OnInit {
  active$: Observable<boolean>;
  type$: Observable<string>;
  bannerText$: Observable<string>;

  constructor(
    protected ngEntityStore: Store<AppState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.active$ = this.ngEntityStore.select(fromUi.getAgentSettingsBannerActive);
    this.type$ = this.ngEntityStore.select(fromUi.getAgentSettingsBannerExitType);
    this.bannerText$ = this.ngEntityStore.select(fromUi.getAgentSettingsBannerText);
  }
}
