// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { NotificationService, TitleNotificationSettings, AudioService, PlayAudioOptions, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { BrowserNotificationSettings } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { AudioNotification } from '../models/audioNotification';
import {
  AppState, AgentCustomSettings, Chat,
  Customer, ChatMessageType, SenderType, ChatMessage,
  ChatInteraction, ChatHelper, UiActions, CallActions, ChatUiActions, ChatActions
} from 'projects/entities/src/public_api';
import { AppConfigService } from './app-config.service';
import { fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { RecommendedAction } from 'projects/entities/src/lib/domain/models/voice/recommended-action';

@Injectable({
  providedIn: 'root'
})
export class MercuryNotificationService {
  private newMessageTitleSettings: TitleNotificationSettings = {
    notificationText: MercuryNotifications.NewMessage,
    blink: true,
    blinkIntervalInSeconds: 2,
    onlyNotifyOnBlur: true,
    revertOnFocus: true,
    timeoutInSeconds: Number.MAX_SAFE_INTEGER
  };
  private newConversationTitleSettings: TitleNotificationSettings = {
    notificationText: MercuryNotifications.NewConversation,
    blink: true,
    blinkIntervalInSeconds: 2,
    onlyNotifyOnBlur: true,
    revertOnFocus: true,
    timeoutInSeconds: Number.MAX_SAFE_INTEGER
  };

  private customerAwaitingReplySettings: TitleNotificationSettings = {
    notificationText: MercuryNotifications.CustomerAwaitingReply,
    blink: true,
    blinkIntervalInSeconds: 2,
    onlyNotifyOnBlur: true,
    revertOnFocus: true,
    timeoutInSeconds: Number.MAX_SAFE_INTEGER
  };

  public showNotifications: boolean = true;
  public playNewMessageActiveChatSound: boolean = true;
  public playNewConversationSound: boolean = true;
  public playNewMessageInactiveChatSound: boolean = true;
  public playOutgoingMessageSound: boolean = true;
  public playChatEndsSound: boolean = true;
  public playBouncedChatSound: boolean = false; // TODO: missing UI flag for this
  public playChatUnpinnedSound: boolean = true;
  public playTestSound: boolean = true;
  public playCustomerAwaitingReplySound: boolean = true;

  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService,
    private notificationService: NotificationService,
    private audioService: AudioService,
    private appConfig: AppConfigService)
  {
    this.store
      .select(fromSettings.getCustomSettings)
      .pipe(distinctUntilChanged())
      .subscribe((settings: AgentCustomSettings) =>
      {
        if (settings) {
          this.showNotifications = settings.desktopNotifications;
          this.playNewMessageActiveChatSound = settings.soundNewMessageCurrent;
          this.playNewConversationSound = settings.soundNewConversation;
          this.playNewMessageInactiveChatSound = settings.soundNewMessageOther;
          this.playOutgoingMessageSound = settings.soundOutgoingMessage;
          this.playChatEndsSound = settings.soundConversationEnds;
          this.playChatUnpinnedSound = true;
          this.playCustomerAwaitingReplySound = settings.soundCustomerAwaitingReply;
        }
        if (settings?.soundMuteAll) {
          this.playNewMessageActiveChatSound = false;
          this.playNewConversationSound = false;
          this.playNewMessageInactiveChatSound = false;
          this.playOutgoingMessageSound = false;
          this.playChatEndsSound = false;
          this.playChatUnpinnedSound = false;
          this.playCustomerAwaitingReplySound = false;
        }
      });
  }

  public triggerNewChatNotifications(chat: Chat): void {
    this._do(() => {
      const customer = ChatHelper.getCustomerUser(chat);
      if (customer) {
        const body = MercuryNotifications.Browser.CreateNewConversationBody(customer, chat);
        const browserNotificationSettings = this._createDefaultBrowserNotificationSettings(chat, MercuryNotifications.NewConversation, body);
        this.notificationService.showBrowserNotification(browserNotificationSettings);
      }

      this.notificationService.showTitleNotification(this.newConversationTitleSettings);
    });

    this.playSoundNotification(AudioNotification.NewChat);
  }

  public triggerNewMessageNotifications(chatMessage: ChatInteraction, chat: Chat, isChatSelected: boolean): void {
    const message = chatMessage as ChatMessage;
    const isNavigation = ChatHelper.isNavigation(message);
    const isXA = ChatHelper.isXaMessage(message);
    const isDatapass = ChatHelper.isDataPass(message);
    const isSystemMessage = isNavigation || isXA || isDatapass;

    this._do(() => {
      const customer = ChatHelper.getCustomerUser(chat);

      if (customer && message && !isSystemMessage) {
          const body = MercuryNotifications.Browser.CreateNewMessageBody(customer, message);
          const settings = this._createDefaultBrowserNotificationSettings(chat, MercuryNotifications.NewMessage, body);
          this.notificationService.showBrowserNotification(settings);
      }

      this.notificationService.showTitleNotification(this.newMessageTitleSettings);
    });

    if (!isSystemMessage){
      const sound: AudioNotification = isChatSelected ? AudioNotification.NewMessageCurrent : AudioNotification.NewMessageOther;
      this.playSoundNotification(sound);
    }
  }

  public triggerCustomerAwaitingReplyNotifications(chat: Chat, time: number): void {   

    this._do(() => {
      const customer = ChatHelper.getCustomerUser(chat);
    
      const body = MercuryNotifications.Browser.CreateCustomerAwiaitngReplyBody(customer, time);
      const settings = this._createCustomerAwaitingReplyNotificationSettings(chat, MercuryNotifications.CustomerAwaitingReply, body);
      this.notificationService.showBrowserNotification(settings);    

      this.notificationService.showTitleNotification(this.customerAwaitingReplySettings);
    });

    this.playSoundNotification(AudioNotification.CustomerAwaitingReply);
  }

  public triggerOutgoingMessageSentNotifications(): void {
    this.playSoundNotification(AudioNotification.OutgoingMessage);
  }

  public triggerChatEndsNotifications(): void {
    this.playSoundNotification(AudioNotification.ChatEnds);
  }

  public triggerChatBouncedNotifications(): void {
    this.playSoundNotification(AudioNotification.BouncedChat);
  }

  public triggerChatUnpinnedNotifications(): void {
    this.playSoundNotification(AudioNotification.UnpinnedChat);
  }

  public triggerTestSound(playOptions?: PlayAudioOptions): void {
    this.playSoundNotification(AudioNotification.TestSound, playOptions);
  }

  public triggerRecommendedActionNotification(recommendedAction: RecommendedAction, callId: string): void {
    // don't need to wrap in this._do, since voice users do not currently have a concept of settings
    if (!recommendedAction.actionTitle || !recommendedAction.description) { return; }

    const recommendedActionNotificationSettings = this._createRecommendedActionNotificationSettings(recommendedAction, callId);

    this.notificationService.showBrowserNotification(recommendedActionNotificationSettings);
  }

  private playSoundNotification(notificationType: AudioNotification, playOptions?: PlayAudioOptions): void {
    switch (notificationType) {
      case AudioNotification.NewChat:
        if (this.playNewConversationSound) {
          this.audioService.playAudioFromUrl(this.appConfig.newChatSound);
        }
        break;
      case AudioNotification.NewMessageOther:
        if (this.playNewMessageInactiveChatSound) {
          this.audioService.playAudioFromUrl(this.appConfig.newMessageInactiveChatSound);
        }
        break;
      case AudioNotification.NewMessageCurrent:
        if (this.playNewMessageActiveChatSound) {
          this.audioService.playAudioFromUrl(this.appConfig.newMessageActiveChatSound);
        }
        break;
      case AudioNotification.OutgoingMessage:
        if (this.playOutgoingMessageSound) {
          this.audioService.playAudioFromUrl(this.appConfig.outgoingMessageSound);
        }
        break;
      case AudioNotification.ChatEnds:
        if (this.playChatEndsSound) {
          this.audioService.playAudioFromUrl(this.appConfig.chatEndsSound);
        }
        break;
      case AudioNotification.BouncedChat:
        if (this.playBouncedChatSound) {
          this.audioService.playAudioFromUrl(this.appConfig.bouncedChatSound);
        }
        break;
      case AudioNotification.UnpinnedChat:
        if (this.playChatUnpinnedSound) {
          this.audioService.playAudioFromUrl(this.appConfig.unpinnedChatSound);
        }
        break;
      case AudioNotification.TestSound:
        if (this.playTestSound) {
          this.audioService.playAudioFromUrl(this.appConfig.testSound, null, playOptions);
        }
        break;
      case AudioNotification.CustomerAwaitingReply:
        if (this.playCustomerAwaitingReplySound) {
          this.audioService.playAudioFromUrl(this.appConfig.customerAwaitingReplySound);
        }
        break;
    }
  }

  private _do(action: Function): void
  {
    if (this.showNotifications) { action(); }
  }

  private _createDefaultBrowserNotificationSettings(chat: Chat, title: string, body: string): BrowserNotificationSettings {
    const browserNotificationSettings = new BrowserNotificationSettings(title, body,
      (clickedChat) => this.onDefaultBrowserNotificationClick(clickedChat)
    );
    browserNotificationSettings.onlyNotifyOnBlur = true;
    browserNotificationSettings.clickData = chat;
    return browserNotificationSettings;
  }

  onDefaultBrowserNotificationClick(clickedChat: Chat): void{
    const { chatId } = clickedChat;
    const lastViewedTimestamp = this.timeService.unix();
    this.store.dispatch(ChatUiActions.agentSelectedChat({ chatId, lastViewedTimestamp }));
    this.store.dispatch(UiActions.NotificationBannerClosed({ chatId }));
    window.focus();
  }

  private _createRecommendedActionNotificationSettings(recommendedAction: RecommendedAction, callId: string): BrowserNotificationSettings {
    const recommendedActionNotificationSettings = new BrowserNotificationSettings(
      recommendedAction.actionTitle,
      recommendedAction.description,
      (data) => this.onRecommendedActionNotificationClick(data)
    );
    recommendedActionNotificationSettings.onlyNotifyOnBlur = true;
    recommendedActionNotificationSettings.clickData = {recommendedAction, callId};
    return recommendedActionNotificationSettings;
  }

  onRecommendedActionNotificationClick(data: {recommendedAction: RecommendedAction, callId: string}): void {
    this.store.dispatch(CallActions.recommendedActionDesktopNotificationClicked(data));
    window.focus();
  }

  private _createCustomerAwaitingReplyNotificationSettings(chat: Chat, title: string, body: string): BrowserNotificationSettings {
    const customerAwaitingReplyNotificationSettings = new BrowserNotificationSettings(
      title,
      body,
      (data) => this.onCustomerAwaitingReplyNotificationClick(data)
    );
    customerAwaitingReplyNotificationSettings.onlyNotifyOnBlur = false;
    customerAwaitingReplyNotificationSettings.clickData = {chatId: chat.chatId};
    return customerAwaitingReplyNotificationSettings;
  }

  onCustomerAwaitingReplyNotificationClick(data: {chatId: string}): void {
    const lastViewedTimestamp = this.timeService.unix();
    this.store.dispatch(ChatUiActions.agentSelectedChat({ chatId: data.chatId, lastViewedTimestamp }));
    this.store.dispatch(ChatActions.CustomerAwaitingReplyNotificationClicked(data));
    window.focus();
  }
}

export const MercuryNotifications = {
  NewMessage: 'New Message',
  NewConversation: 'New Conversation',
  CustomerAwaitingReply: 'Customer Awaiting Response',
  Browser: {
    CreateNewConversationBody: (customer: Customer, chat: Chat): string => {
      let body = 'You have a new conversation!';
      const newMsg: ChatInteraction = chat?.messages?.find(x => x.sender === SenderType.Requester);

      if (customer && newMsg) {
        let msg: string = '';
        switch (newMsg.type) {
          case ChatMessageType.Message:
            msg = (<ChatMessage>newMsg).message;
            break;
          case ChatMessageType.Image:
            msg = 'Image';
            break;
        }
        body = `${customer.name}: ${msg}`;
      }
      else {
        body = `${customer.name} wants to chat!`;
      }

      return body;
    },
    CreateNewMessageBody: (customer: Customer, message: ChatInteraction): string => {
      let body: string = null;
      const chatMessage = message as ChatMessage;
      if (customer) {
        if (message.sender === SenderType.System) {
          body = `${customer.name}: ${chatMessage.message}`;
        }
        else {
          switch (message.type) {
            case ChatMessageType.Message:
              const messageText = chatMessage.translationData?.translatedMessage ? chatMessage.translationData?.translatedMessage : chatMessage.message;
              body = `${customer.name} said "${messageText}"`;
              break;
            case ChatMessageType.Image:
              body = `${customer.name} sent an image`;
              break;
          }
        }
      }
      return body;
    },
    CreateCustomerAwiaitngReplyBody: (customer: Customer, time: number): string => {
      let body: string = null;
      if (customer) {
         if (time === 0 ){
              body = `It's been a while since your last response to ${customer.name}`;            
         }
           else{
            body = `It's been over ${time} minutes since your last response to ${customer.name}`; 
           }    
      }  
      return body;
    }
  }  
};
