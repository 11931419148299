<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  headerText="Tell us what you think!"
  [active]="active"
  [controls]="controls"
  [customCloseButtonClick]="surveyDismissed.bind(this, 'closeButton')"
  [customOutsideClick]="surveyIgnored.bind(this)"
  [customModalClass]="'merc-survey-popover'"
  [customBackdropClass]="'cee-modal-backdrop--hidden'"
>
  <div>
    <p>
      Mercury Feedback &mdash; Answer some quick questions about how Mercury works for you, in less than 3 minutes.
    </p>
  </div>
</cee-modal>
