// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PageInitializationHelper } from '../utils/page-initialization-helper';
import * as fromApp from './app.reducer';
import { PageInitializationState } from './models/enums';
import { PageInitializationItem } from './models/page-initialization-item';
import { AppState } from './state';

const selectAppFeature = createFeatureSelector<AppState, fromApp.State>(fromApp.featureKey);

export const selectUpdatesInProgressTraceId = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.updatesInProgressTraceId
);

export const getRetrievingUpdates = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.checkForUpdates
);

export const getLastUpdatesCompleteTimestamp = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.lastUpdatesCompleteTimestamp
);

export const getPageInitializationState = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.pageInitializationState
);

export const getPageName = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.pageName
);

export const getIsPageInitializationPending = createSelector(
  getPageInitializationState,
  getPageName,
  (pageInitStates: PageInitializationItem[], pageName: string) => PageInitializationHelper.isPageInitPending(pageName, pageInitStates)
);

export const getPageInitializationErrors = createSelector(
  getPageInitializationState,
  (pageInitStates: PageInitializationItem[]) => pageInitStates.filter(x => x.state === PageInitializationState.Error)
);

export const getPageInitializationErrorList = createSelector(
  getPageInitializationErrors,
  (errorStates: PageInitializationItem[]) => errorStates.map(x => x.eventName + ' failed')
);

export const getHasCriticalPageInitializationError = createSelector(
  getPageInitializationErrors,
  getPageName,
  (errorStates: PageInitializationItem[], pageName: string) => Boolean(errorStates.find(x => PageInitializationHelper.isCritical(pageName, x.eventName)))
);

export const getPageInitializationSucceeded = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.pageInitializationSucceeded
);

export const getPendingServerSync = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.pendingServerSync
);

export const getServerSyncTimestamp = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.serverSyncTimestamp
);

export const getUpdatesData = createSelector(
  selectUpdatesInProgressTraceId,
  getRetrievingUpdates,
  getLastUpdatesCompleteTimestamp,
  (updatesInProgressTraceId: string, retrievingUpdates: boolean, lastUpdatesCompleteTimestamp: number) => {
    return { updatesInProgressTraceId, retrievingUpdates, lastUpdatesCompleteTimestamp };
  }
);

export const getShouldOneCtiHydrateChatStart = createSelector(
  selectAppFeature,
  (state: fromApp.State) => state.shouldOneCtiHydrateChatStart
);
