// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'merc-voice-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VoiceAcknowledgementComponent implements OnInit {
  @Input() icon: string = 'smile';
  @Input() message: string = 'Nice! You launched a recommended action.';
  @Input() noAnimation: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
