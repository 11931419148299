// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Chunk, combineChunks, findChunks } from 'highlight-words-core';
import { HighlightOptions } from 'src/app/models/highlight-options';
import { HighlightWordsUtils } from './highlight-words-utils';
import { StringHelper } from './string-helper';

export interface AllChunks {
  replacedTextToHighlight: string;
  filledInChunks: Chunk[];
  replacementChunks: ReplacementChunk[];
}

export interface ReplacementChunk extends Chunk {
  __mercury_replacement: HighlightOptions; // added field to ease processing the results
  start: number;
  end: number;
  highlight: boolean;
}

export class HighlightWords {
  /**
   * Creates an array of chunk objects representing both highlightable and non highlightable
   * pieces of text that match each search word and replacement.
   * highlighting matches will override any matches from replacements.
   * highlighting will highlight and text replaced by matches in replacements.
   * @param textToHighlight
   * @param highlighting
   * @param replacements
   * @returns
   */
  public static findAll(textToHighlight: string, highlighting: string[], replacements: HighlightOptions[]): AllChunks {
    if (!textToHighlight) {
      return {
        replacedTextToHighlight: textToHighlight,
        filledInChunks: [],
        replacementChunks: []
      };
    }

    const replacementChunks = this.createReplacementChunks(replacements, textToHighlight);

    // update textToHighlight w/ replacement values
    const replacedTextToHighlight = this.replaceAllMatches(replacements, textToHighlight);

    // create highlighting chunks using the replaced textToHighlight
    const highlightingChunks = this.createHighlightingChunks(highlighting, replacedTextToHighlight);

    // ensure that highlighted chunks take precedence over replacement chunks
    this.adjustOverlappingChunks(highlightingChunks, replacementChunks);

    // combine all chunks w/ remaining text
    const filledInChunks = HighlightWordsUtils.createFilledInChunks(replacedTextToHighlight, highlightingChunks, replacementChunks);

    return {
      replacedTextToHighlight,
      filledInChunks,
      replacementChunks
    };
  }

  private static createReplacementChunks(replacements: HighlightOptions[], textToHighlight: string): ReplacementChunk[] {
    const replacementChunks = !replacements?.length ? [] :
      HighlightWordsUtils.excludeOverlappingChunks(findChunks({
        searchWords: replacements.map(r => r.matchText), autoEscape: true,
        textToHighlight
      }));

    if (replacementChunks.length === 0) {
      return [];
    }

    let totalOffset = 0;

    return replacementChunks.map(chunk => {
      const { end, start } = chunk;
      const replacement = this.getReplacementByMatchText(chunk, replacements, textToHighlight);
      const offset = this.getOffset(replacement);

      // shift chunk start/end to account for cumulative replacement(s) text length
      chunk.start = start + totalOffset;
      totalOffset += offset;
      chunk.end = end + totalOffset;

      return {
        ...chunk,
        __mercury_replacement: replacement
      };
    });
  }

  private static getReplacementByMatchText(chunk: Chunk, replacements: HighlightOptions[], textToHighlight: string): HighlightOptions {
    const { end, start } = chunk;
    const chunkText = textToHighlight.substring(start, end);
    // search by matchText ignoring case
    return replacements.find(r => r.matchText.localeCompare(chunkText, undefined, { sensitivity: 'base' }) === 0);
  }

  private static getOffset(replacement: HighlightOptions) {
    const { matchText, replaceText } = replacement ?? {};
    return replaceText == null ? 0 : replaceText.length - matchText.length;
  }

  /**
   * Create an array of chunks for the highlight matchText (will combine chunks that overlap into single chunks)
   * @param highlighting
   * @param textToHighlight
   * @returns
   */
  private static createHighlightingChunks(searchWords: string[], textToHighlight: string): Chunk[] {
    return combineChunks({
      chunks: findChunks({
        autoEscape: true,
        searchWords: searchWords ?? [],
        textToHighlight
      })
    });
  }

  private static replaceAllMatches(replacements: HighlightOptions[], textToHighlight: string): string {
    replacements?.forEach(replacement => {
      const { matchText, replaceText } = replacement;
      if (!(replaceText == null)) {
        textToHighlight = StringHelper.replaceAll(textToHighlight, matchText, replaceText);
      }
    });
    return textToHighlight;
  }

  /**
   * adjust chunk boundaries to handle overlapping chunks (highlights will override replacements)
   * @param highlightingChunks
   * @param replacementChunks
   */
  private static adjustOverlappingChunks(highlightingChunks: Chunk[], replacementChunks: Chunk[]) {
    let highlightingChunkIndex = 0;
    let replacementChunksIndex = 0;

    while (true) {
      const highlightingChunk = highlightingChunks[highlightingChunkIndex];
      const replacementChunk = replacementChunks[replacementChunksIndex];

      if (!highlightingChunk && !replacementChunk) {
        break;
      }

      if (HighlightWordsUtils.chunkStartsBefore(highlightingChunk, replacementChunk)) {
        this.adjustReplacementForOverlappingHighlight(highlightingChunk, replacementChunk);

        highlightingChunkIndex++;
      }
      else if (HighlightWordsUtils.chunkStartsBefore(replacementChunk, highlightingChunk)) {
        this.adjustReplacementThatOverlapsHighlight(replacementChunk, highlightingChunk, replacementChunks, replacementChunksIndex);

        replacementChunksIndex++;
      }
      else {
        // something went wrong. break to avoid infinite loop
        console.warn('failed to increment chunk index');
        break;
      }
    }
  }

  private static adjustReplacementForOverlappingHighlight(highlightingChunk: Chunk, replacementChunk: Chunk) {
    if (HighlightWordsUtils.chunkSurrounds(highlightingChunk, replacementChunk)) {
      // replacementChunk will be ignored since it is completely inside a highlightingChunk
      replacementChunk.start = replacementChunk.end = highlightingChunk.end;
    }
    else if (HighlightWordsUtils.chunkEndsIn(highlightingChunk, replacementChunk)) {
      // shorten replacementChunk to start after highlightingChunk
      replacementChunk.start = highlightingChunk.end;
    }
  }

  private static adjustReplacementThatOverlapsHighlight(replacementChunk: Chunk, highlightingChunk: Chunk, replacementChunks: Chunk[], replacementChunksIndex: number) {
    if (HighlightWordsUtils.chunkEndsIn(replacementChunk, highlightingChunk)) {
      replacementChunk.end = highlightingChunk.start;
    }
    else if (HighlightWordsUtils.chunkSurrounds(replacementChunk, highlightingChunk)) {
      // split replacementChunk in two (one chunk before highlight and one after)
      const newReplacementChunk: Chunk = {
        ...replacementChunk,
        start: highlightingChunk.end
      };
      replacementChunks.splice(replacementChunksIndex + 1, 0, newReplacementChunk);

      replacementChunk.end = highlightingChunk.start;
    }
  }

  /**
   * if chunk is highlighted return the associated custom class
   * otherwise return null.
   * @param chunk
   * @param replacementChunks
   * @param highlighting
   * @returns CSS class name
   */
  public static getClassName(chunk: Chunk, replacementChunks: ReplacementChunk[], highlightingCustomClass: string): string {
    const { highlight, start, end } = chunk;

    if (!highlight) {
      return null;
    }

    const replacementChunk = replacementChunks?.find(c => c.start === start && c.end === end);

    if (replacementChunk) {
      const replacement = replacementChunk.__mercury_replacement;
      return replacement?.customClass;
    }

    return highlightingCustomClass;
  }
}
