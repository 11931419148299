// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export default class Constants {
    static ConsumerId = '2F8F4AC1-894A-E911-B8B3-00155DC3367C';
    static AgentUserAliasId = 'FF5D39D0-6E4F-E911-B8B3-00155DC3367C';
    static AgentDisconnectTimeout: number = 15000;
}

export class MercuryRoutes {
  static SsoLogin: string = 'login/sso';
  static FormLogin: string = 'login';
  static AdvancedLogin: string = 'login/advanced';
  static SessionTimeoutComponent: string = 'login/session-timeout';
  static AppStopped: string = 'stopped';
  static Home: string = '';
  static SsoResponse: string = 'auth/sso/response';
  static VoiceSsoResponse: string = 'auth/sso/response/voice';
  static Voice: string = 'voice';
}

export const Colors = [
  {
    id: 0,
    name: 'Pink',
    hexCode: '#cc007a'
  },
  {
    id: 1,
    name: 'Purple',
    hexCode: '#8e26a2'
  },
  {
    id: 2,
    name: 'Green',
    hexCode: '#0e736c'
  },
  {
    id: 3,
    name: 'Orange',
    hexCode: '#d96c00'
  },
  {
    id: 4,
    name: 'Violet',
    hexCode: '#403aa6'
  },
  {
    id: 5,
    name: 'Blue',
    hexCode: '#0f6291'
  }
];

export const AppSettings = {
  stackedMessageThreshold: 60,
  statusMetricTimerInterval: 5 * 60 * 1000, // 5 minutes
};

export const ScriptsConstants = {
  SEARCHING_LENGTH: 2
};

export const StyleClasses = {
  Critical: 'cee-tag--system-critical',
  Warn: 'cee-tag--system-warn',
  Secondary: 'cee-tag--secondary'
};

export const ChatLoadingConfiguration = {
  UuiWaitTimeInSeconds: 5,
  SendAutoGreetingWaitTimeInSeconds: 1
};
