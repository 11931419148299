<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-call-header"
  *ngIf="{
    customerName: (customerName$ | async),
    currentCallId: (currentCallId$ | async),
    callState: (callState$ | async),
    isDisplayingLoadedCallSummaryPanel: (isDisplayingLoadedCallSummaryPanel$ | async),
    hasQueuedCall: (hasQueuedCall$ | async)
  } as data">
  <div class="merc-call-header--call-details">
    <div class="merc-call-header--caller-info">
      <ng-container *ngIf="isActiveCall(data.callState); else noCallerInfo">
        <cee-avatar [customerNumber]="2" [customerName]="getCustomerName(data.customerName)">
        </cee-avatar>
        <span class="merc-call-header--caller-name">{{ getCustomerName(data.customerName) }}</span>
      </ng-container>
      <ng-template #noCallerInfo>
        <div class="merc-call-header--no-caller-avatar"></div>
        <span class="merc-call-header--no-caller-name">No Call in Progress</span>
      </ng-template>
    </div>
    <merc-cust-call-timer class="merc-call-header--timer" [timerRef]="callTimer"></merc-cust-call-timer>
  </div>

  <div class="merc-close-button-container" *ngIf="data.currentCallId">
    <cee-button-v2
      [type]="data.hasQueuedCall ? 'primary' : 'secondary'"
      customClass="merc-close-session"
      (buttonClick)="closeCall(data.currentCallId, data.isDisplayingLoadedCallSummaryPanel)">
      {{ data.hasQueuedCall ? 'Next Session' : 'Close' }}
    </cee-button-v2>
  </div>
</div>


