// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class TimestampHelper {
  static getSecondsSinceTimestampInMilliseconds(timestamp: number){
    return Math.floor((Date.now() - timestamp) / 1000);
  }

  static getRoundedTimeAgo(milliseconds: number): string {
    // calculations use the milliseconds and divide by amt to get the correct floored/rounded value

    if (!milliseconds) { return this.getRoundedTimeText(0, 'minute'); }
    const minutes: number = Math.floor(milliseconds / 60000); 
    if (minutes < 60){
      return this.getRoundedTimeText(minutes, 'minute');
    }

    const hours: number = Math.round(milliseconds / (60 * 60000));
    if (hours < 24){
      return this.getRoundedTimeText(hours, 'hour');
    }

    const days: number = Math.floor(milliseconds / (24 * 60 * 60000));
    return this.getRoundedTimeText(days, 'day');
  }

  private static getRoundedTimeText(num: number, timeUnitText: string): string{
    return `${num} ${timeUnitText}${num === 1 ? '' : 's'} ago`;
  }

  static formatTimestampToHHMMSS(timestamp: number): string {
    const hours = Math.floor(timestamp / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((timestamp % 3600) / 60).toString().padStart(2, '0');
    const seconds = Math.floor((timestamp % 60)).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }
}
