// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class NumberHelper {
  /**
   * @param n decimal value between 0 and 1 for the probability
   */
  public static probability(n: number): boolean {
    return Boolean(n) && Math.random() <= n;
  }
}
