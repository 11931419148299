<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-login-background cee-theme-dark merc-app-stopped">
  <div class="merc-app-stopped-wrapper">
    <img
      src="../../../assets/img/mercury-with-text.png"
      alt="Mercury Logo"
      class="merc-login-image"
    />
    <div>
      <h1 class="cee-h3">Mercury was opened elsewhere</h1>
      <p class="cee-body-detail">
        Looks like Mercury is open in another tab. You can safely close this
        tab, then continue your session in the other tab.
      </p>
    </div>
  </div>
</div>
