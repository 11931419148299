// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProfanityList } from '../domain/models/profanityList';
import { AppState } from '../domain/state';
import { fromProfanity } from '../domain/selectors';

const expressionPrefix = new RegExp('\\^\\(\\?\\:\\.\\*\\(\\?\\:\\\\d\\|\\\\W\\)\\)\\?', 'gi');
const expressionSuffix = new RegExp('\\(\\?\\:\\(\\?\\:\\\\d\\|\\\\W\\)\\.\\*\\)\\?\\$', 'gi');
const escape = /[-\/\\^$*+?.()|[\]{}]/g;

@Injectable({
    providedIn: 'root'
})
export class ProfanityService {
  private _wordsRegEx: RegExp[] = [];
  private _expressions: RegExp[] = [];
  constructor(
    private _appStore: Store<AppState>
  ){
    this._appStore.select(fromProfanity.selectProfanityList).subscribe((profanityList: ProfanityList) => {
        if (profanityList){
          this._expressions = profanityList.expressions.map(exp => new RegExp(exp, 'gi'));
          this._wordsRegEx = [];
          profanityList.words.forEach(word => {
            let wordExp;
            if (word.match(escape)) {
              const escapedWord = this._escapeRegex(word);
              wordExp = new RegExp(escapedWord, 'gi');
            }
            else {
              wordExp = new RegExp('\\b(' + word + ')\\b', 'gi');
            }
            this._wordsRegEx.push(wordExp);
          });
        }
    });
  }

  public test(testString: string): string[] {
    // checks the test strings against the list of profane words and expressions
    // returns a list of illegal words that matched
    const result = [];

    if (!testString) {
      return result;
    }

    this._wordsRegEx.forEach(exp => {
      const expMatch = testString.match(exp);
      if (expMatch !== null){
        const match = expMatch[0];
        if (!this._containsMatch(match, result)) { result.push(match); }
      }
    });

    this._expressions.forEach(exp => {
      const expMatch = testString.match(exp);
      if (expMatch !== null){
        //parse word out of expression
        let word = exp.source;
        word = word.replace(expressionPrefix, '');
        word = word.replace(expressionSuffix, '');

        if (!this._containsMatch(word, result)) { result.push(word); }
      }
    });

    return result;
  }

  private _containsMatch(match: string, matchList: string[]): boolean{
    return matchList.includes(match);
  }

  private _escapeRegex(str: string){
    return str.replace(escape, '\\$&');
  }
}
