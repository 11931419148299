// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChatMessageType, DetailsSelectedTab, LoadingState, SenderType, SmartResponseComponentDisplayType } from 'projects/entities/src/lib/domain/models/enums';
import { RecommendedTabBannerVm } from 'projects/entities/src/lib/domain/models/recommended-tab-banner-vm';
import { Suggestion } from 'projects/entities/src/lib/domain/models/suggestion';
import { Placeholder } from 'projects/entities/src/lib/domain/placeholders/placeholder.model';
import { ChatInteraction, ChatMessage, Color } from 'projects/entities/src/public_api';
import { fromChat, fromSettings, fromSmartResponses, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { SmartResponses } from 'projects/entities/src/lib/domain/smart-responses/smart-responses.model';
import { AppState, Chat, ChatUiActions, FeatureFlags, SmartResponsesActions } from 'projects/entities/src/public_api';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { HighlightOptions } from 'src/app/models/highlight-options';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { PLACEHOLDER_HIGHLIGHT_CLASS } from './convo-recommended.constants';
import { SelectMessageMethod } from 'projects/entities/src/lib/domain/models/logDimensions/select-message-log';
import { SmartResponsesHelper } from 'projects/entities/src/lib/utils/smart-responses-helper';

@Component({
  selector: 'merc-convo-recommended',
  templateUrl: './convo-recommended.component.html',
  styleUrls: ['./convo-recommended.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoRecommendedComponent extends SubscriberComponent implements OnInit {
  hideAnimations$: Observable<boolean>;
  smartResponseComponentDisplayType$: Observable<SmartResponseComponentDisplayType>;
  smartResponsesModel$: Observable<SmartResponses>;
  suggestions$: Observable<Suggestion[]>;
  smartResponseSplitSearchText$: Observable<string[]>;
  showSuggestions$: Observable<boolean>;
  showSmartResponses$: Observable<boolean>;
  selectedMessageId$: Observable<string>;
  color$: Observable<Color>;
  selectedMessage$: Observable<ChatInteraction>;
  isDisabled$: Observable<boolean>;
  bannerVm$: Observable<RecommendedTabBannerVm>;
  selectedActiveChat$: Observable<Chat>;
  scroll$: BehaviorSubject<number>;
  smartResponseDisplayCount$: Observable<number>;
  previousActiveMessage$: Observable<ChatInteraction>;
  nextActiveMessage$: Observable<ChatInteraction>;
  //Todo: replace this with a new selector that checks both the flag and setting once setting is hooked up
  hasLanguageTranslatorFeatureFlag$: Observable<boolean>;

  smartResponseComponentDisplayType = SmartResponseComponentDisplayType;
  loadingState = LoadingState;
  previousButton = SelectMessageMethod.PrevButton;
  nextButton = SelectMessageMethod.NextButton;

  @ViewChild('scrollWindow') private scrollWindow!: ElementRef;

  @Input() chatId: string;
  @Input() placeholders: Placeholder[];

  constructor(
    private store: Store<AppState>,
    private ngZone: NgZone,
  ) {
    super();
  }

  ngOnInit() {
    this.scroll$ = new BehaviorSubject<number>(0);
    this.hideAnimations$ = this.store.select(fromSettings.hideAnimations);
    this.smartResponseComponentDisplayType$ = this.store.select(fromSmartResponses.getSmartResponseComponentDisplayType);
    this.smartResponsesModel$ = this.store.select(fromSmartResponses.getFilteredSmartResponsesForSelectedChatMessage).pipe(distinctUntilChanged());
    this.suggestions$ = this.store.select(fromChat.getSelectedMessageIdSuggestions);
    this.smartResponseSplitSearchText$ = this.store.select(fromSmartResponses.selectSmartResponseSplitSearchText);
    this.showSmartResponses$ = this.store.select(fromSettings.showSmartResponses);
    this.showSuggestions$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.XaResponse));
    this.selectedMessageId$ = this.store.select(fromChat.getSelectedChatSelectedMessageId);
    this.color$ = this.store.select(fromChat.getSelectedChatColor).pipe(distinctUntilChanged());
    this.selectedMessage$ = this.store.select(fromChat.getSelectedChatSelectedChatMessage).pipe(distinctUntilChanged());
    this.isDisabled$ = this.store.select(fromChat.getIsSelectedChatItgInProgress);
    this.bannerVm$ = this.store.select(fromUi.getRecommendedPanelBannerVm);
    this.selectedActiveChat$ =  this.store.select(fromChat.getSelectedChat);
    this.smartResponseDisplayCount$ = this.store.select(fromSettings.getSmartResponseDisplayCount);
    this.previousActiveMessage$ = this.store.select(fromChat.getSelectedChatSelectedPreviousMessage);
    this.nextActiveMessage$ = this.store.select(fromChat.getSelectedChatSelectedNextMessage);
    this.hasLanguageTranslatorFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator));


    this.selectedActiveChat$
      .pipe(
        debounceTime(50),
        filter((chat) => Boolean(chat)),
        map((chat) => chat.chatId),
        distinctUntilChanged(),
        concatLatestFrom(() =>
          this.store.select(fromChat.getDetailsPanelScrollPosition),
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(([_, scrollPosition]) => {
          if (this.scrollWindow) {
            this.ngZone.runOutsideAngular(() => {
              setTimeout(() => {
                this.scrollWindow.nativeElement.scrollTop = scrollPosition ?? 0;
              });
            });
          }
      });

      this.scroll$
      .pipe(
        debounceTime(50),
        concatLatestFrom(() => [this.selectedActiveChat$,
          this.store.select(fromChat.getDetailsPanelTabSelected)]),
        takeUntil(this.destroyed$))
      .subscribe( ([scrollPosition, chat, selectedTab]) => {
        if (chat && selectedTab === DetailsSelectedTab.Recommended) {
          this.store.dispatch(ChatUiActions.updateDetailsPanelScrollPos({chatId: chat.chatId, detailsPanelScrollPosition: scrollPosition}));
        }
    });
  }

  onScroll($event) {
    const scrollValue = $event.target.scrollTop ?? 0;
    this.scroll$.next(scrollValue);
  }

  shouldHideAnimations(hideAnimations: boolean, componentDisplayType: SmartResponseComponentDisplayType) {
    return hideAnimations || componentDisplayType === SmartResponseComponentDisplayType.Error;
  }

  getHighlightOptions(entities: string[]): HighlightOptions[]{
    return entities?.map(e => {
      let replaceText = '';
      let customClass = PLACEHOLDER_HIGHLIGHT_CLASS;
      const placeholder = this.placeholders.find(p => p.key === e);
      if (placeholder){
        replaceText = placeholder.selectedValue ? placeholder.selectedValue : `{${placeholder.label}}`;
        customClass = placeholder.selectedValue ? `${PLACEHOLDER_HIGHLIGHT_CLASS}--selected` : PLACEHOLDER_HIGHLIGHT_CLASS;
      }
      const options: HighlightOptions = {
        matchText: e,
        replaceText,
        customClass
      };
      return options;
    });
  }

  tryAgain(messageId: string): void{
    this.store.dispatch(SmartResponsesActions.getSmartResponses({chatId: this.chatId, messageId}));
  }

  onNavigationClick(chatMessage: ChatMessage, selectionMethod: SelectMessageMethod): void {
    const canSearchSmartResponses = (chatMessage.type === ChatMessageType.Message || chatMessage.type === ChatMessageType.XaSuggestion || chatMessage.autoPilot)
          && chatMessage.sender !== SenderType.System && chatMessage.sender !== SenderType.XA;
    this.store.dispatch(ChatUiActions.messageSelected({ chatId: this.chatId, chatMessage, canSearchSmartResponses, selectionMethod }));
  }

  getDisplayMessage(msg: ChatMessage, hasLanguageTranslatorFeatureFlag: boolean){
    return (SmartResponsesHelper.getSmartResponseMessage(msg, hasLanguageTranslatorFeatureFlag));
  }
}
