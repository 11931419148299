<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<nav class="merc-header">
  <h1 class="cee-h1">{{ getScreenHeader$ | async }}</h1>

<div class="merc-form">
  <div class="merc-connection-notification" *ngIf="(isChatConnectionPoor$ | async)"
  [ceeTooltip]="{ id: 'wifiWarningIconTooltip', text: wifiWarningTooltipText, direction: 'bottom', width: 265, customClass:'merc-chat-connection-tooltip' }">
    <svg class="cee-alert-icon merc-alert-warning" viewBox="0 0 16 16" id="wifiwarningicon">
      <use
        href="/assets/img/svg-sprite.svg#icon-wifi-alert-fill"
      ></use>
    </svg>
    <span class="merc-connection-notification-text">Poor Connection</span>
  </div>
  <div class="merc-connection-notification" *ngIf="!(isChatHubConnected$ | async)"
  [ceeTooltip]="{ id: 'wifiErrorIconTooltip', text: wifiErrorTooltipText, direction: 'bottom', width: 265, customClass:'merc-chat-connection-tooltip' }">
    <svg class="cee-alert-icon merc-alert-error" viewBox="0 0 16 16" id="wifierroralerticon">
      <use
        href="/assets/img/svg-sprite.svg#icon-wifi-alert-outline"
      ></use>
    </svg>
    <span class="merc-connection-notification-text">No Connection</span>
  </div>
  <div class="merc-connection-notification" *ngIf="!(isOneCtiConnected$ | async)"
  [ceeTooltip]="{ id: 'alerticonTooltip', text: ctiNotConnectedTooltipText, direction: 'bottom', width: 265, customClass:'merc-chat-connection-tooltip' }">
    <svg class="cee-alert-icon merc-alert-error" viewBox="0 0 16 16" id="alerticon">
      <use
        href="/assets/img/svg-sprite.svg#icon-alert-fill"
      ></use>
       </svg>
      <span class="merc-connection-notification-text">OneCTI Disconnected</span>
    </div>
    <div class="merc-metric-divider" *ngIf="!(isOneCtiConnected$ | async) || !(isChatHubConnected$ | async) || (isChatConnectionPoor$ | async)"></div>  
  <div class="header-metrics">
      <span id="agent-chat-count"
      tabindex="0"
      *ngIf="{
          agentAvailableTime: (agentAvailableTime$ | async),
          agentUnavailableTime: (agentUnavailableTime$ | async),
          agentAvgResponseTime: (agentAvgResponseTime$ | async),
          agentAvgHandleTime: (agentAvgHandleTime$ | async),
          completedChatCount: (completedChatCount$ | async),
          transferredChatCount: (transferredChatCount$ | async)
      } as data"
      class="merc-chat-count"
      [ceeTooltip]="{ id:'conversationsCompletedTooltip', minWidth: 224, direction: 'bottom', 
      text: getAgentMetricHeaders(),
      supplementaryText: getAgentMetricData(data.completedChatCount, data.transferredChatCount, data.agentAvailableTime, data.agentUnavailableTime, data.agentAvgResponseTime, data.agentAvgHandleTime),
      customClass: 'merc-agent-metrics-tooltip' }">
        <svg class="cee-icon" viewBox="0 0 16 16">
          <use
            href="/assets/img/svg-sprite.svg#icon-conversations-complete"
          ></use>
        </svg>
        {{ this.completedChatCount$ | async }}
      </span>
    </div>
    <div class="merc-metric-divider" *ngIf="availabilityTimer && availabilityTimer.isRunning"></div>
    <div
        class="merc-availability-timer-wrapper"
        *ngIf="availabilityTimer && availabilityTimer.isRunning"
        [class.has-chats]="hasChats$ | async" busy-timer>
        <merc-availability-timer
        [ceeTooltip]="{ id:'busy-timer', text: 'Busy Timer', minWidth: 100, direction: 'bottom' }"
        [timerRef]="availabilityTimer"
        ></merc-availability-timer>
    </div>
    <form>
      <ng-container *ngIf="!availabilityError; else error">
        <div>
          <merc-agent-availability-selector
            [options]="options"
            [selected]="selectedValue"
            [hasChats]="hasChats$ | async"
          >
          </merc-agent-availability-selector>
        </div>
      </ng-container>
      <ng-template #error>
        <span class="merc-inline-error--init-error">
          <svg class="cee-icon" viewBox="0 0 16 16">
            <use href="/assets/img/svg-sprite.svg#icon-exclamation"></use>
          </svg>
          Session Error.
          <button class="cee-btn cee-btn--link" (click)="onViewDetails()">
            View Details
          </button>
        </span>
      </ng-template>
    </form>
  </div>
</nav>

<cee-modal
  headerText="Session Error"
  [active]="showErrorModal"
  [clickOutsideToClose]="true"
  (modalClose)="onCloseModal()"
>
  <div class="cee-initialization-error-modal">
    <p>
      Something went wrong. If this problem continues, please contact your
      supervisor for assistance.
    </p>
    <p class="cee-error">{{ availabilityErrorReason }}</p>
    <div class="cee-modal-footer">
      <div class="cee-modal-footer-buttons">
        <cee-button type="primary" (buttonClick)="onRefresh()">
          <svg class="cee-icon cee-icon--md" viewBox="0 0 16 16">
            <use href="/assets/img/svg-sprite.svg#icon-refresh"></use>
          </svg>
          Reload Mercury
        </cee-button>
      </div>
    </div>
  </div>
</cee-modal>
