// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { ChatContext } from 'projects/entities/src/lib/domain/models/chat-context';
import { Subject } from 'rxjs';

import { StyleHelper } from 'src/app/utils/style-helper';
import { TimerComponent } from '../../timer/timer.component';

@Component({
  selector: 'merc-agent-response-timer',
  templateUrl: '../../timer/timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AgentResponseTimerComponent extends TimerComponent implements OnDestroy {
  private _destroyed$: Subject<{}>;

  constructor(changeDetector: ChangeDetectorRef, timeHelper: TimeHelperService) {
    super(changeDetector, timeHelper);
    this._destroyed$ = new Subject<{}>();
  }

  @Input()
  public set chatContext(chatCtx: ChatContext)
  {
    this.timerRef = chatCtx.agentResponseTimer;
    this.styleClass = StyleHelper.getThresholdCriticalityStyleObservable(
      this.timerRef.value,
      chatCtx.chat.agentResponseMidSlaInSeconds,
      chatCtx.chat.agentResponseMaxSlaInSeconds,
      this._destroyed$
    );
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(null);
  }
}
