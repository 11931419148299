// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NotificationService, MaskingService, TokenStoreService, SsoAuthGuardService, AudioService, ClipboardService, LoggingFactoryService, EnvironmentService, InterceptionMetaReducer } from '@cxt-cee-chat/merc-ng-core';
import { MercuryNotificationService } from 'src/app/services/mercury-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { AppState, AppStoppedGuard, Color, ColorEnumeratorService, COLOR_ENUMERATOR_SERVICE_COLORS_INJECTION_TOKEN, SigRConfig } from 'projects/entities/src/public_api';
import { Colors } from '../constants/constants';
import { NUANCE_BEARER_TOKEN_HANDLER, NuanceBearerTokenHttpHandler } from 'projects/entities/src/lib/services/nuance-bearer-token-http-handler';
import { COMCAST_BEARER_TOKEN_HANDLER, ComcastBearerTokenHttpHandler } from 'projects/entities/src/lib/services/comcast-bearer-token-http-handler';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { TelemetryItemTranslator, WebVitalsInitializer, WebVitalsTranslator } from 'projects/entities/src/lib/services/logging/';
import { ThemeService } from '../services/theme.service';
import { ServiceConfig } from 'projects/entities/src/lib/domain/models/service-config';
import { AppConfigService } from '../services/app-config.service';
import { ConvoCardService } from '../components/convo-list/convo-card/convo-card.service';
import { NlpTranscriptService } from 'projects/entities/src/lib/services/nlp-transcript.service';
import { MetaReducer, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { ReducerMetareducerLogger } from '../ngrx/reducer-metareducer-logger';
import * as localforage from 'localforage';
import { ChatPersisterService, CHAT_STORAGE_INJECTION_TOKEN } from 'projects/entities/src/lib/domain/chat/chat-persister.service';
import { FormBuilder } from '@angular/forms';
import { CallHeaderService } from '../components/voice/call-header/call-header.service';

const chatRequest: SigRConfig = {
    endpoint: environment.sigR.chatRequest.endpoint
};

const voiceHub: SigRConfig = {
    endpoint: environment.sigR.voiceHub.endpoint
};

const fixAgentAuthRest: ServiceConfig = {
    endpoint: environment.apiServices.fixAgentAuth.endpoint
};

const fixAgentFormAuthRest: ServiceConfig = {
    endpoint: environment.apiServices.fixAgentAuth.endpoint
};

const fixAgentRest: ServiceConfig = {
    endpoint: environment.apiServices.fixAgent.endpoint
};

const customerInfo: ServiceConfig = {
    endpoint: environment.apiServices.customerInfo.endpoint
};

const appConfigApi: ServiceConfig = {
    endpoint: environment.apiServices.appConfig.endpoint
};

const niagaraLogApi: SigRConfig = {
    endpoint: environment.sigR.niagaraLog.endpoint
};

const appConfigService: AppConfigService = new AppConfigService();
appConfigService.chatRequest = chatRequest;
appConfigService.voiceHub = voiceHub;
appConfigService.fixAgentAuthRest = fixAgentAuthRest;
appConfigService.fixAgentFormAuthRest = fixAgentFormAuthRest;
appConfigService.fixAgentRest = fixAgentRest;
appConfigService.customerInfo = customerInfo;
appConfigService.appConfig = appConfigApi;
appConfigService.siteId = environment.siteId;
appConfigService.useTestHarness = environment.sigR.useTestHarness;
appConfigService.appLauncher = environment.appLauncher;
appConfigService.niagaraLog = niagaraLogApi;
appConfigService.newChatSound = environment.audio.newChat;
appConfigService.bouncedChatSound = environment.audio.bouncedChat;
appConfigService.chatEndsSound = environment.audio.chatEnds;
appConfigService.newMessageActiveChatSound = environment.audio.newMessageCurrent;
appConfigService.newMessageInactiveChatSound = environment.audio.newMessageOther;
appConfigService.outgoingMessageSound = environment.audio.outgoingMessage;
appConfigService.unpinnedChatSound = environment.audio.unpinnedChat;
appConfigService.testSound = environment.audio.testSound;
appConfigService.npsSurveyUrlBase = environment.npsSurveyUrlBase;
appConfigService.oneCtiClientName = environment.onecti.clientName;
appConfigService.oneCtiChatRegisterUrl = environment.onecti.chatRegisterUrl;
appConfigService.oneCtiRegisterUrl = environment.onecti.registerUrl;
appConfigService.uatBugTrackingUrl = environment.uatBugTrackingUrl;
appConfigService.releaseDate = environment.releaseDate;
appConfigService.releaseLabel = environment.releaseLabel;
appConfigService.featureAnnouncementText = environment.featureAnnouncementText;
appConfigService.einsteinChatUrl = environment.einsteinChatUrl;
appConfigService.ecmUrl = environment.ecmUrl;
appConfigService.customerAwaitingReplySound = environment.audio.customerAwaitingReply;
appConfigService.askMeAnythingUrl = environment.askMeAnythingUrl;

const environmentService = new EnvironmentService();
environmentService.environment = environment.env;
environmentService.logging = environment.logging;

const convoColors: Color[] = Colors
    .map(key => {
        const c = new Color();
        c.hexCode = key.hexCode;
        c.id = key.id;
        c.name = key.name;
        return c;
    });

const tokenStore: TokenStoreService = new TokenStoreService(sessionStorage);

const loggingFactory: LoggingFactoryService = new LoggingFactoryService();
const audioService: AudioService = new AudioService(loggingFactory);

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.auth.comcastAadSso.clientId,
            authority: environment.auth.comcastAadSso.authority,
            redirectUri: environment.auth.comcastAadSso.redirectPath
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        }
    };
}

function getMetaReducers(loggingFactoryService: LoggingFactoryService): MetaReducer<AppState>[] {
    const metaReducerInterceptor = new InterceptionMetaReducer();
    const errorLoggingInterceptor = new ReducerMetareducerLogger(loggingFactoryService);
    metaReducerInterceptor.ErrorInterceptors.push(errorLoggingInterceptor);
    const bound = metaReducerInterceptor.safelyReduce.bind(metaReducerInterceptor);

    return [
        bound
    ];
}

export const APP_MODULE_PROVIDERS = [
    {
        provide: AppConfigService,
        useValue: appConfigService
    },
    ThemeService,
    NotificationService,
    MercuryNotificationService,
    SsoAuthGuardService,
    AppStoppedGuard,
    JwtHelperService,
    {
        provide: TokenStoreService,
        useValue: tokenStore
    },
    MaskingService,
    { provide: 'maskCharacter', useValue: 'X' },
    {
        provide: AudioService,
        useValue: audioService
    },
    ClipboardService,
    {
        provide: LoggingFactoryService,
        useValue: loggingFactory
    },
    {
        provide: EnvironmentService,
        useValue: environmentService
    },
    // color enum service
    {
        provide: COLOR_ENUMERATOR_SERVICE_COLORS_INJECTION_TOKEN,
        useValue: convoColors
    },
    ColorEnumeratorService,
    // end color enum service
    {
        provide: NUANCE_BEARER_TOKEN_HANDLER,
        useClass: NuanceBearerTokenHttpHandler
    },
    {
        provide: COMCAST_BEARER_TOKEN_HANDLER,
        useClass: ComcastBearerTokenHttpHandler
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    TelemetryItemTranslator,
    WebVitalsInitializer,
    WebVitalsTranslator,
    ConvoCardService,
    CallHeaderService,
    NlpTranscriptService,
    {
        provide: CHAT_STORAGE_INJECTION_TOKEN,
        useFactory: () => ChatPersisterService.createLocalForageInstance(localforage, environment.indexedDbOptions)
    },
    {
        provide: USER_PROVIDED_META_REDUCERS,
        deps: [
            LoggingFactoryService
        ],
        useFactory: getMetaReducers
    },
    FormBuilder
];
