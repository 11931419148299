// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { AgentOperations } from '../constants/event-logs.constants';
import { HubName } from '../constants/hub-names';
import { HubsActions } from '../domain/actions';
import { HubEchoData } from '../domain/models/hub-echo-data';
import { AppState } from '../domain/state';
import { LogHelper } from '../utils/logHelper';
import { NuanceCredentialHubService } from './nuance-credential/nuance-credential-hub-service';

@Injectable({
  providedIn: 'root'
})
export class EchoService {
  constructor(
		protected store: Store<AppState>,
   ) { }

  public async sendEcho(
    hub: NuanceCredentialHubService,
    logService: LoggingFactoryService,
    sessionId?: string
  ) {
    return this.callServer('Echo', hub, logService, sessionId);
  }

  public async sendEventHubEcho(
    hub: NuanceCredentialHubService,
    logService: LoggingFactoryService,
    sessionId: string
  ): Promise<HubEchoData> {
    return this.callServer('EchoEventHub', hub, logService, sessionId);
  }

  private async callServer(
    serverOperationName: 'Echo' | 'EchoEventHub',
    hub: NuanceCredentialHubService,
    logService: LoggingFactoryService,
    sessionId?: string
  ) {
    const { hubName } = hub;
    const startTime = new Date();
    const start = performance.now();

    await hub.callServer(serverOperationName, { message: 'echo', sessionId, startTime });

    const elapsedTime = performance.now() - start;

    LogHelper.logAgentEvents(
      logService,
      this.getLogOperationName(serverOperationName),
      {
        elapsedTime: performance.now() - start,
        ...(sessionId && { sessionId }),
        startTime,
        hubName
      },
      true
    );

    const echo: HubEchoData = {
        hubName: hubName as HubName,
        startDateTime: startTime.toISOString(),
        duration: elapsedTime,
        sessionId
    };

    this.store.dispatch(HubsActions.echoCompleted({ echo }));
    return echo;
  }

  private getLogOperationName(serverOperationName: string) {
    return serverOperationName === 'EchoEventHub'
      ? AgentOperations.EchoEventHub
      : AgentOperations.Echo;
  }
}
