// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { SmartResponseStateNotificationVm } from 'projects/entities/src/lib/domain/models/smart-response-state-notification-vm';
import { fromSmartResponses } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-smart-response-state-notification',
  templateUrl: './smart-response-state-notification.component.html',
  styleUrls: ['./smart-response-state-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartResponseStateNotificationComponent implements OnInit {
  smartResponseStateNotificationVm$: Observable<SmartResponseStateNotificationVm>;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.smartResponseStateNotificationVm$ = this.store.select(fromSmartResponses.getSmartResponseStateNotificationVm);
  }
}
