// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  Chat, AppState, getNonPendingChats
} from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';

@Component({
  selector: 'merc-convo-history',
  templateUrl: './convoHistory.component.html',
  styleUrls: ['./convoHistory.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoHistoryComponent extends SubscriberComponent implements OnInit {
  chats$: Observable<Chat[]>;
  selectedChatId$: Observable<string>;

  selectedAsyncPriorEngagementId: string;

  constructor(
    private store: Store<AppState>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.chats$ = this.store
      .select(getNonPendingChats);

    this.selectedChatId$ = this.store
      .select(fromChat.getSelectedChatId)
      .pipe(
        distinctUntilChanged()
      );
  }

  public trackByChatId(_index: number, chat: Chat) {
    return chat.chatId;
  }
}
