// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Environment, LoggingLevel } from '@cxt-cee-chat/merc-ng-core';

export const environment = {
  production: false,
  env: Environment.Dev,
  logging: {
    level: LoggingLevel.Info,
    applicationInsights: {
      enabled: true,
      connectionString: '[replace:logging:appInsights:connStr]'
    },
    niagaraLog: {
      enabled: true
    }
  },
  sigR: {
    chatRequest: {
      endpoint: '[replace:sigR:chatRequest:endpoint]'
    },
    voiceHub: {
      endpoint: '[replace:sigR:voiceHub:endpoint]'
    },
    fixAgent: {
      endpoint: '[replace:sigR:fixAgent:endpoint]'
    },
    reportingAgent: {
      endpoint: '[replace:sigR:reporting:endpoint]'
    },
    appConfig: {
      endpoint: '[replace:sigR:appConfig:endpoint]'
    },
    niagaraLog: {
      endpoint: '[replace:sigR:niagaraLog:endpoint]'
    },
    useTestHarness: false
  },
  apiServices: {
    fixAgentAuth: {
      endpoint: '[replace:service:fixAgentAuth:endpoint]'
    },
    customerInfo: {
      endpoint: '[replace:service:customerInfo:endpoint]'
    },
    fixAgent: {
      endpoint: '[replace:service:fixAgent:endpoint]'
    },
    appConfig: {
      endpoint: '[replace:service:appConfig:endpoint]'
    }
  },
  siteId: '10006690',
  audio: {
    newChat: '/assets/sounds/ui_loading.wav',
    newMessageOther: '/assets/sounds/notification_simple-01.wav',
    newMessageCurrent: '/assets/sounds/navigation_forward-selection-minimal.wav',
    outgoingMessage: '/assets/sounds/navigation_backward-selection-minimal.wav',
    chatEnds: '/assets/sounds/navigation_transition-right.wav',
    bouncedChat: '/assets/sounds/alert_error-01.wav',
    unpinnedChat: '/assets/sounds/state-change_confirm-down_01.wav',
    testSound: '/assets/sounds/hero_decorative-celebration-03.wav',
    customerAwaitingReply: '/assets/sounds/notification_high-intensity.wav'
  },
  auth: {   
    comcastAadSso: {
      clientId: '[replace:comcastClientId]',
      redirectPath: '[replace:auth:comcastAadSso:redirectUri]',
      authority: 'https://login.microsoftonline.com/906aefe9-76a7-4f65-b82d-5ec20775d5aa/'
    },
    useComcastSso: true
  },
  onecti: {
    clientName: 'Mercury',
    chatRegisterUrl: 'wss://localhost:40005',
    registerUrl: 'wss://localhost:40003'
  },
  appLauncher: [
    ['Einstein360', 'https://einstein360a.cable.comcast.com/einstein_nxtgen?accountnumber='],
    ['Timeline', 'https://cable.customer-timeline.com/events/for/account/']
  ],
  indexedDbOptions: <LocalForageOptions> {
    name: 'Mercury',
  },
  npsSurveyUrlBase: 'https://survey.medallia.com/?Mercury&',
  uatBugTrackingUrl: 'https://app.smartsheet.com/b/form/64f1ef2b8cbe49e19ad51499eb7fff32',
  releaseDate: '2024-05-21T04:00:00Z',
  releaseLabel: '2024.06.01',
  featureAnnouncementText: 'Mercury 24.6.1 is now available! Click here to learn more about the changes!',
  einsteinChatUrl: 'https://ec.comcast.com/request-chat-support',
  ecmUrl: 'https://ecm.comcast.com/ecm',
  askMeAnythingUrl: 'https://einstein360a.cable.comcast.com/albert?documentId='
};
