// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CloseMethod } from '@cxt-cee-chat/merc-pattern-lib';
import { createAction, props } from '@ngrx/store';
import { createPayloadAction } from '../action-adapters';
import {
  BounceTimerDetail,
  ChatImageMessage,
  ScriptClickEventArgs,
  AgentSettingsBanner,
  Chat
} from '../models';
import { ConvoMessageContextMenu } from 'projects/entities/src/public_api';
import { ScriptsContextMenu } from 'src/app/models/scripts-context-menu';
import { CloseSuggestionFeedbackMethod, ConfirmAction, ItgActionType } from '../models/enums';
import { ItgSuggestion } from '../models/itgSuggestion';
import { SmartResponseInUse } from '../models/smartResponseInUse';
import { CxGptResponseInUse } from '../models/cx-gpt-response-in-use';
import { PreviousChatSummary } from '../models/previousChatSummary';

export const ScriptClicked = createAction(
  '[Ui] Script Clicked',
  props<{ scriptInUse: ScriptClickEventArgs }>()
);

export const SmartResponseClicked = createAction(
  '[Ui] Smart Response Clicked',
  props<{ smartResponseInUse: SmartResponseInUse, placeholderKeys: string[] }>()
);

export const CxGptResponseEditClicked = createAction(
  '[Ui] CxGpt Response Edit Clicked',
  props<{ cxGptResponseInUse: CxGptResponseInUse }>()
);

export const ToggleAgentSettingsBanner = createPayloadAction<
  AgentSettingsBanner
>('[Ui] Toggle Agent Settings Banner');

export const GetTransferOptions = createAction('[Ui] Get Transfer Options');

export const RefreshTransferOptions = createAction('[Ui] Refresh Transfer Options');

export const TransferModalClosed = createAction('[Ui] Transfer Modal Closed');

export const ImageModalClosed = createAction('[Ui] Image Modal Closed');

export const ClearTransferFailed = createAction('[Ui] Clear Transfer Failed');

export const ImageClicked = createPayloadAction<ChatImageMessage>(
  '[Ui] Image Clicked'
);

export const WrapChat = createAction('[Ui] Wrap Chat');

export const ExitPromptCloseEngagement = createAction(
  '[Ui] Exit Prompt Close Engagement',
  props<{ method: CloseMethod }>()
);

export const ToggleScriptsContextMenuAction = createAction(
  '[UI] Toggle Scripts ContextMenu Action',
  props<{ scriptsContextMenu: ScriptsContextMenu }>()
);

export const ToggleAgentSettingsPanel = createAction(
  '[UI] Toggle AgentSettings Panel',
  props<{ isOpen: boolean }>()
);

export const UpdateAgentSettingsActiveTab = createAction(
  '[UI] Update Agent Settings Active Tab',
  props<{ activeTabTitle: string }>()
);

export const exitCustomSettings = createAction(
  '[UI] Exit Custom Settings'
);

export const closeCustomSettingsExitModal = createAction(
  '[UI] Close Custom Settings Exit Modal'
);

export const BounceTimerTriggered = createAction(
  '[UI] Bounce Timer Triggered',
  props<{ bounceTimerDetail: BounceTimerDetail }>()
);

export const dismissNotificationBanner = createAction(
  '[UI] Dismiss Notification Banner'
);

export const dismissBouncedNotificationBanner = createAction(
  '[UI] Dismiss Bounced Notification Banner'
);

export const NotificationBannerClosed = createAction(
  '[UI] Notification Banner Closed',
  props<{ chatId: string }>()
);

export const ToggleMessageContextMenu = createAction(
  '[UI] Toggle Message ContextMenu',
  props<{ convoContextMenu: ConvoMessageContextMenu }>()
);

export const ToggleAgentSummaryPanel = createAction(
  '[UI] ToggleAgentSummaryPanel',
  props<{ toggleAgentSummaryPanel: boolean }>()
);

export const openSessionLockoutWarningModal = createAction(
  '[Ui] Open Session Lockout Warning Modal',
  props<{ elapsedTime: number }>()
);

export const closeSessionLockoutWarningModal = createAction(
  '[Ui] Close Session Lockout Warning Modal',
  props<{ elapsedTime: number, warningElapsedTime: number }>()
);

export const ToggleItgModal = createAction(
  '[UI] ToggleItgModal',
  props<{ toggleItgModal: boolean, action: ItgActionType, suggestedItg?: ItgSuggestion }>()
);

export const ToggleCloseTransferModal = createAction(
  '[UI] ToggleCloseTransferModal',
  props<{ toggleCloseTransferModal: boolean, action?: ConfirmAction }>()
);

export const DismissInlineToast = createAction(
  '[UI] DismissInlineToast',
  props<{ dismissToast: boolean }>()
);

export const DismissFeedbackPrompt = createAction(
  '[UI] DismissFeedbackPrompt',
  props<{ dismissFeedbackPrompt: boolean, chatId: string, qualityCheckDateTime: Date }>()
);

export const ToggleShowRateConnectionFollowUpModal = createAction(
  '[UI] toggleShowRateConnectionFollowUpModal',
  props<{ toggleShowRateConnectionFollowUpModal: boolean }>()
);

export const ToggleShowRateConnectionFollowUp = createAction(
  '[UI] toggleShowRateConnectionFollowUp',
  props<{ toggleShowRateConnectionFollowUp: boolean, enableRateConnectionAdditionalFeedback: boolean }>()
);

export const SettingsConnectionRatingSubmitted = createAction(
  '[UI] Settings Connection Rating Submitted'
);

export const UpdateHistoryPanel = createAction(
  '[UI] updateHistoryPanel',
  props<{ historyPanelIsOpen: boolean }>()
);

export const UpdateSmartResponseFilterText = createAction(
  '[ChatUi] Update SmartResponse Filter Text',
  props<{ content: string}>()
);

export const CloseDismissSuggestionFeedback = createAction(
  '[Agent] Close Dismiss Suggestion Feedback',
  props<{ closeMethod: CloseSuggestionFeedbackMethod, chat: Chat }>()
);

export const GetChatSummaryData = createAction(
  '[Ui] Get Chat Summary Data',
  props<{ chatId: string, isRetry?: boolean, isAsyncEngagementsRequest?: boolean, traceId: string }>()
);

export const CancelGetChatSummaryData = createAction(
  '[Ui] Cancel Get Chat Summary Data',
  props<{ chatId: string }>()
);

export const SendAutoSaveSummary = createAction(
  '[Ui] Send Auto Save Summary',
  props<{ chatId: string }>()
);

export const BackgroundAutoSaveSummaryTriggered = createAction(
  '[Ui] Background Auto Save Summary Triggered',
  props<{ pendingChatSummaryData: PreviousChatSummary }>()
);

export const BackgroundAutoSaveSummarySuccessful = createAction(
  '[Ui] Background Auto Save Successful',
  props<{ chatId: string, summary: string, createDate: number }>()
);

export const BackgroundAutoSaveSummaryFailed = createAction(
  '[Ui] Background Auto Save Failed',
  props<{ chatId: string }>()
);

export const UpdateAutoSaveSummary = createAction(
  '[Ui] Update Auto Save Summary',
  props<{ chatId: string }>()
);

export const AutoSaveSummaryFailed = createAction(
  '[Ui] Auto Save Summary Failed',
  props<{ chatId: string }>()
);

export const RetryAutoSaveSummary = createAction(
  '[Ui] Retry Auto Save Summary',
  props<{ chatId: string }>()
);

export const TogglePreviousChatSummariesPanel = createAction(
  '[Ui] Toggle Previous Chat Summaries Panel',
  props<{ isOpen: boolean }>()
);

export const UpdatePreviousChatSummaryModal = createAction(
  '[Ui] Update Previous Chat Summary Modal',
  props<{ chatId: string, isOpen: boolean }>()
);

export const translateNotificationMessageClicked = createAction(
  '[Ui] Translate Notification Message Clicked'
);
