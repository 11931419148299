// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatInteractionRequest } from './requests/chat-interact-request';

export class UpdateAccountNumber extends ChatInteractionRequest{
  accountNumber: string;
  displayName: string;
  authenticated: boolean;
  manualConnect: boolean;
}
