// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';

import {
  Store
} from '@ngrx/store';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AgentActions,
  AgentCustomSettings,
  AppState, Chat, ChatOperations, FeatureFlags, getNonPendingChats, hasFeatureFlag, ProfileActions} from 'projects/entities/src/public_api';
import { Observable, of } from 'rxjs';
import { fromAgent, fromAgentAvailability, fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { LogActions } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-convo-list-base',
  templateUrl: './convo-card-list-base.component.html',
  styleUrls: ['./convo-card-list-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoCardListBaseComponent extends SubscriberComponent implements OnInit {
  chats$: Observable<Chat[]>;
  continuedChats$: Observable<Chat[]>;
  newChats$: Observable<Chat[]>;
  inactiveChats$: Observable<Chat[]>;
  hasAnyContinuedChats$: Observable<boolean>;
  maxChats$: Observable<number>;
  maxExtraChats$: Observable<number>;
  extraChatsRequested$: Observable<boolean>;
  hasAbandonedChatAutomation$: Observable<boolean>;
  hasConvoCardContextMenu$: Observable<boolean> = of(false);
  agentCustomSettings$: Observable<AgentCustomSettings>;
  copyTooltipText: string;
  chatId$: Observable<string>;


  constructor(
    private clipboardService: ClipboardService,
    private store: Store<AppState>) {
    super();
  }

  public ngOnInit(): void {
    this.chatId$ = this.store.select(fromChat.getSelectedChatId);
    this.chats$ = this.store.select(getNonPendingChats);
    this.maxChats$ = this.store.select(fromAgent.getMaxChats);
    this.maxExtraChats$ = this.store.select(fromAgent.getMaxExtraChats);
    this.extraChatsRequested$ = this.store.select(fromAgentAvailability.getAvailableForExtraChats);
    this.continuedChats$ = this.store.select(fromChat.getContinuedChats);
    this.newChats$ = this.store.select(fromChat.getNewChats);
    this.inactiveChats$ = this.store.select(fromChat.getInactiveChats);
    this.hasAnyContinuedChats$ = this.store.select(fromChat.hasAnyContinuedChats);
    

    this.hasAbandonedChatAutomation$ = this.store
      .select(hasFeatureFlag(FeatureFlags.AbandonedChatAutomation));

    this.hasConvoCardContextMenu$ = this.store
      .select(hasFeatureFlag(FeatureFlags.ApplicationFocus));

    this.agentCustomSettings$ = this.store.select(fromSettings.getCustomSettings);
  }

  onVolumeChange(soundbarVolume) {
    const isMuted = soundbarVolume === 0;
    this.store.dispatch(ProfileActions.saveVolume({volume: soundbarVolume, isMuted, source: 'main'}));
  }

  onMuted(isMuted) {
    this.store.dispatch(ProfileActions.muteVolume({isMuted}));
  }

  toggleRequestChats(event) {
    const enabled =  event.target.checked;
    this.store.dispatch(AgentActions.RequestExtraChats({enabled}));
  }

  public trackByChatId(_index: number, chat: Chat) {
    return chat.chatId;
  }

  copyToClipboard(chatId: string){
    const logPayload = {
      logType: LogType.chat,
      operationName: ChatOperations.CopyChatId
    };
    this.store.dispatch(LogActions.logSelectedChatAction({ logPayload }));

    this.clipboardService.copyTo(chatId);
    this.copyTooltipText = 'Copied!';
  }

  resetCopyChatId(){
    this.copyTooltipText = '';
  }

  resetCopyText(){
    this.copyTooltipText = '';
  }
}
