// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { TokenStoreService, AuthService, JwtTokenFactoryService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Router } from '@angular/router';
import { AgentStatePersisterService,
    AuthenticationProviderTypes, NuanceFormAuthService } from 'projects/entities/src/public_api';
import { FixAgentFormAuthApiService } from 'projects/entities/src/lib/services/fix-agent-form-auth-api.service';
import { ComcastNuanceSsoAuthService } from 'projects/entities/src/lib/services/comcast-nuance-auth-sso-service';
import { ComcastSsoAuthService } from 'projects/entities/src/lib/services/comcast-auth-sso-service';

const authenticationServiceFactory = (
    tokenStore: TokenStoreService,
    jwtTokenFactory: JwtTokenFactoryService,
    agentStatePersister: AgentStatePersisterService,
    fixAgentFormAuthRestApiService: FixAgentFormAuthApiService,
    comcastSsoAuthService: ComcastSsoAuthService,
    router: Router,
    timeService: DayTimeService
    ) =>
    {
        const renewTokenBeforeExpiryInSeconds = (2 * 60);
        switch (agentStatePersister.authenticationProvider) {
            case AuthenticationProviderTypes.NuanceForm: {
                return new NuanceFormAuthService(tokenStore, jwtTokenFactory, router, fixAgentFormAuthRestApiService, timeService, renewTokenBeforeExpiryInSeconds);
            }
            case AuthenticationProviderTypes.ComcastNuanceSso:               
            case AuthenticationProviderTypes.NuanceSso:
            default: {              
                return new ComcastNuanceSsoAuthService(tokenStore, jwtTokenFactory, router, fixAgentFormAuthRestApiService, timeService, comcastSsoAuthService, renewTokenBeforeExpiryInSeconds);
            }
        }
    };

export let authenticationProvider = {
    provide: AuthService,
    useFactory: authenticationServiceFactory,
    deps: [TokenStoreService, JwtTokenFactoryService, AgentStatePersisterService, FixAgentFormAuthApiService, ComcastSsoAuthService, Router, DayTimeService]
};
