// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgentFacingMetricsHelper } from '../../utils/agent-facing-metrics-helper';
import * as fromAgentFacingMetrics from './agent-facing-metrics.reducer';
import { ChatMetricsData } from '../models/agent-metrics-data';
import { AppState } from '../state';

export const selectAgentFacingMetricsFeature = createFeatureSelector<
  AppState,
  fromAgentFacingMetrics.State
>(fromAgentFacingMetrics.featureKey);

export const
  getChatsCompleted = createSelector(
    selectAgentFacingMetricsFeature,
    (metrics: fromAgentFacingMetrics.State) => metrics.chatsCompleted
  );

  export const getLastStatusMetricTimestamp = createSelector(
    selectAgentFacingMetricsFeature,
    (metrics: fromAgentFacingMetrics.State) => metrics.lastStatusMetricTimestamp ?? 0
  );

  export const getMetricsDurationAvailable = createSelector(
    selectAgentFacingMetricsFeature,
    (metrics: fromAgentFacingMetrics.State) => metrics.durationAvailable ?? 0
  );

  export const getMetricsDurationUnavailable = createSelector(
    selectAgentFacingMetricsFeature,
    (metrics: fromAgentFacingMetrics.State) => metrics.durationUnavailable ?? 0
  );

  export const getChatMetrics = createSelector(
    selectAgentFacingMetricsFeature,
    (metrics: fromAgentFacingMetrics.State) => metrics.chatMetrics
  );

  export const getMetricsFirstResponseTime = (chatId: string) => createSelector(
    getChatMetrics,
    (chatMetrics: Record<string, ChatMetricsData>) => chatMetrics?.[chatId]?.firstResponseTime ?? 0
  );

  export const getMetricsAvgResponseTime = createSelector(
    getChatMetrics,
    (chatMetrics: Record<string, ChatMetricsData>) => AgentFacingMetricsHelper.getMetricsAvgTime(chatMetrics, true)
  );

  export const getMetricsAvgHandleTime = createSelector(
    getChatMetrics,
    (chatMetrics: Record<string, ChatMetricsData>) => AgentFacingMetricsHelper.getMetricsAvgTime(chatMetrics, false)
  );

  export const getMetricsChatsTransferred = createSelector(
    selectAgentFacingMetricsFeature,
    (metrics: fromAgentFacingMetrics.State) => metrics.chatsTransferred ?? 0
  );
