// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AgentActions, AppState, UserIdentityService } from 'projects/entities/src/public_api';
import { tap } from 'rxjs/operators';

@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_Agent {
    constructor(
		protected ngEntityStore: Store<AppState>,
		protected actions: Actions,
        protected loggingFactory: LoggingFactoryService,
        protected userIdentityService: UserIdentityService
	) { }

    agentMetricsLoaded$ = createEffect(() => 
        this.actions.pipe(
            ofType(AgentActions.AgentMetricsLoaded),
            tap(({payload}) => {
                this.userIdentityService.updateName(payload.agentFullName);
                this.loggingFactory.addLoggingProperty('callCenter', payload.callCenter);
                this.loggingFactory.addLoggingProperty('ntOrBpId', payload.ntOrBpId);
                this.loggingFactory.addLoggingProperty('supervisor', payload.supervisor);
                this.loggingFactory.addLoggingProperty('supplierId', payload.supplierId);
            })
        ),
        { dispatch: false }
    );
}