<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div [class]="'cee-card cee-card--conversation ' + conversationColorClass"
  [ngClass]="{'cee-card--active': isSelected$ | async }" data-qa="conversation-card"
  *ngIf="{isAuthenticated: isAuthenticated$ | async} as data">

  <div class="cee-card-notification-container" *ngIf="(notificationCount$ | async); let notificationCount">
    <span class="cee-card-notification">
      {{ notificationCount }}
    </span>
  </div>
  <div class="cee-card--conversation-heading">
    <div class="cee-card-width100">
      <div class="cee-card-inline">
        <h2>
          <cee-customer-avatar *ngIf="!(customerIsTyping$ | async)" [customerNumber]="convoColorId"
            [customerName]="customerName$ | async" [selected]="isSelected$ | async">
          </cee-customer-avatar>
          <ng-container *ngIf="(customerIsTyping$ | async)">
            <ng-container *ngIf="(ellipsisClass$ | async); let ellipsisClass">
              <div class="cee-card-convo-timer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 8" class="cee-icon cee-icon--ellipsis">
                  <g>
                    <circle class="{{ ellipsisClass }}" cx="5" cy="3" r="2.5" />
                    <circle class="{{ ellipsisClass }}" cx="12" cy="3" r="2.5" />
                    <circle class="{{ ellipsisClass }}" cx="19" cy="3" r="2.5" />
                  </g>
                </svg>
              </div>
            </ng-container>
          </ng-container>
          <button class="cee-card-action convo-card-customerName" (click)="onClick()" data-qa="customer-name">
            {{ customerName$ | async }}
          </button>
        </h2>
      </div>
      <div class="cee-card--right-icons">
        <div class="merc-pin-timer-container"
          [ngClass]="{'merc-pin-timer-container--has-background': showInactiveWarningPinTimer$ | async}">
          <merc-cust-card-timer *ngIf="showInactiveWarningPinTimer$ | async"
            [timerRef]="chatContext.engagementInactiveWarningTimer"></merc-cust-card-timer>
          <div class="merc-card-pin"
            *ngIf="hasAbandonedChatAutomation && (isAutomationPinningEnabled$ | async)">
            <button
              *ngIf="(hasCustomerRespondedToAgentInitialMessage$ | async) && ((hasAgentRespondedToCustomer$ | async) || (isPinned$ | async)); else disabledPinButton"
              class="cee-btn cee-btn--icon cee-btn-pin"
              (click)="onPinClick()"
              [ceeTooltip]="{
              text: pinToolTipText$ | async,
              direction: 'bottom',
              width: 200
              }"
          >
              <svg class="cee-icon" [ngClass]="pinSvgClass$ | async">
              <use [attr.href]="pinSvgPath$ | async"></use>
              </svg>
          </button>
          <ng-template #disabledPinButton>
            <button
                class="cee-btn cee-btn--icon cee-btn-pin"
                [ceeTooltip]="{
                text: pinDisabledTooltipText,
                supplementaryText: (hasCustomerRespondedToAgentInitialMessage$ | async) ? pinDisabledRespondTooltipSubtext : pinDisabledTooltipSubtext,
                direction: 'bottom',
                width: 200
                }"
            >
                <svg class="cee-icon cee-icon-disabled">
                <use
                    xlink:href="/assets/img/svg-sprite.svg#icon-pin-disabled"
                ></use>
                </svg>
            </button>
          </ng-template>
          </div>
        </div>

        <div class="merc-context--focus">
          <button id="focusButton" *ngIf="hasContextMenu" class="cee-btn cee-btn--icon" (click)="focusButtonClick()"
            [ceeTooltip]="{
              text: data.isAuthenticated ? 'Einstein 360' : 'Unable to focus account. Please access in Einstein manually.',
              type: data.isAuthenticated ? 'default' : 'error',
              direction: 'bottom',
              width: data.isAuthenticated ? 100 : 190}">
            <svg class="cee-icon cee-card--nav-icons" viewBox="0 0 17 14">
              <path class="hover-hide" fill-rule="evenodd"
                d="M1.432 3.174c.258 0 .47.191.504.44l.005.068v8.348h11.3c.258 0 .47.192.504.44l.005.07c0 .257-.191.47-.44.503l-.069.005H1.611a.688.688 0 01-.683-.613l-.004-.075V3.682c0-.28.227-.508.508-.508zM16.235 0c.38 0 .689.308.689.688v9.236c0 .38-.309.688-.689.688H4.047a.688.688 0 01-.688-.688V.688c0-.38.308-.688.688-.688zm-.33 3.969H4.376l.001 5.626h11.53V3.969zm.001-2.952H4.376v1.935h11.53V1.017z" />
              <path class="hover-show" fill-rule="evenodd"
                d="M.5086648 3.6620547c.257517 0 .4703392.190662.5040212.438033l.0046435.0687703v8.3173765l11.3003366.000159c.2575172 0 .4703392.190662.5040213.4380331l.0046435.0687702c0 .2565747-.1913623.4686179-.439642.5021767L12.3176661 13.5H.6881935c-.3547402 0-.6467917-.2674199-.6841553-.6109631L0 12.814325V4.168858c0-.2798997.227737-.5068033.5086648-.5068033zM15.3118065.5c.3455262 0 .6315779.2537084.6807317.584351L16 1.185675v9.2019569c0 .3442616-.2546403.6292665-.5864973.6782405l-.1016962.0074344H3.1238c-.3455261 0-.6315779-.2537083-.6807317-.584351l-.0074618-.101324V1.185675c0-.3442617.2546403-.6292666.5864973-.6782405L3.1238.5h12.1880065zm-.3277817 2.9414463H3.4538578v1.0136064h11.530167V3.4414463z" />
            </svg>
          </button>
        </div>
        <cee-dropdown-menu x-test-target="contextMenuDropDown" *ngIf="hasContextMenu" labelText="Context Menu"
          labelIcon="kebab" [hasNoPadding]="true" tooltipText="More options" tooltipDirection="bottom">
          <span class="cee-context-menu-title">
            {{ customerName$ | async }}
          </span>
          <cee-button *ngIf="hasEinsteinChatFeatureFlag$ | async; else ecmButton" type="dropdown-item"
            (click)="onOptClicked('einsteinchat')" size="sm" role="option">
            <svg class="cee-icon cee-icon--windows">
              <use xlink:href="/assets/img/svg-sprite.svg#icon-echatwindows"></use>
            </svg> Einstein Chat
          </cee-button>
          <ng-template #ecmButton>
            <cee-button type="dropdown-item" (click)="onOptClicked('ecm')" size="sm" role="option">
              <svg class="cee-icon cee-icon--windows">
                <use xlink:href="/assets/img/svg-sprite.svg#icon-echatwindows"></use>
              </svg> ECM
            </cee-button>
          </ng-template>
          <cee-button type="dropdown-item" (click)="onOptClicked('einstein360')" size="sm" role="option" x-test-target="einstein360DropdownButton"
            [tooltipText]="data.isAuthenticated ? '' : 'Unable to focus account. Please access in Einstein manually.'"
            tooltipDirection="bottom"
            tooltipWidth="190"
            tooltipType="error">
            <svg class="cee-icon cee-icon--windows">
              <use xlink:href="/assets/img/svg-sprite.svg#icon-windows"></use>
            </svg> Einstein 360
          </cee-button>
          <cee-button type="dropdown-item" (click)="onOptClicked('ACSR')" role="option">
            <svg class="cee-icon cee-icon--windows">
              <use xlink:href="/assets/img/svg-sprite.svg#icon-windows"></use>
            </svg> ACSR
          </cee-button>
          <ng-container *ngIf="hasSecureDataCollectionFeatureFlag$ | async">
            <cee-button (click)="onOptClicked('secureDataCollection')" type="dropdown-item" role="option"
              *ngIf="(isSecureDataCollectionButtonEnabled$ | async) && (data.isAuthenticated || (isAccountConnected$ | async)); else disabledSecureDataCollection"
              x-test-target="secureDataCollectionButton">
              <svg class="cee-icon cee-icon--secure-lock" x-test-target="secureDataCollectionLockOutline">
                <use xlink:href="/assets/img/svg-sprite.svg#icon-lock-outline"></use>
              </svg>
              {{ secureDataCollectionOptionText$ | async }}
            </cee-button>

            <ng-template #disabledSecureDataCollection>
                <cee-button (click)="onOptClicked('secureDataCollection')" type="dropdown-item" role="option"
                  disabled="true" x-test-target="secureDataCollectionButton"
                  [ceeTooltip]="{ text: ((secureDataCollectionOptionText$ | async) === 'Link Request Pending') ? null : (isSecureDataCollectionButtonEnabled$ | async) ? accountNotConnectedTooltip : ctiNotConnectedTooltip, width: 400, direction: 'bottom' }">
                  <svg class="cee-icon cee-icon--secure-lock"
                    x-test-target="secureDataCollectionLockFill">
                    <use xlink:href="/assets/img/svg-sprite.svg#icon-lock-fill"></use>
                  </svg>
                  {{ secureDataCollectionOptionText$ | async }}
                </cee-button>
            </ng-template>

          </ng-container>
        </cee-dropdown-menu>
      </div>
    </div>
  </div>
  <div class="cee-card--conversation-body" [innerHTML]="previewText$ | async | safeHtml" data-qa="conversation-body">
  </div>
  <div class="cee-card--conversation-footer">
    <div>
      <ul class="cee-card--conversation-meta">
        <li class="device-icon" *ngFor="let item of conversationInfo$ | async">
          <button class="cee-btn cee-btn--icon" [attr.data-qa]="item.text"
          [ceeTooltip]="{ id: item.text, text: item.text, direction: 'bottom' }">
            <svg class="cee-icon cee-icon--md" width="16" height="16" viewBox="0 0 16 16">
              <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-'+item.iconPath"></use>
            </svg>
            <span class="cee-accessibly-hidden">{{ item.text }}</span>
          </button>
        </li>
        <li class="cee-card-convo-timer">
          <merc-cust-card-timer [timerRef]="chatContext.chatDurationTimer" [ceeTooltip]="{ id: 'convo-timer', text: 'Conversation Timer', width: 100, direction: 'bottom' }"></merc-cust-card-timer>
        </li>
      </ul>
    </div>
    <div>
      <ul class="cee-card--conversation-meta">
        <li class="cee-card-bounce-timer">
          <div class="bounce-timer"bounce-timer
            *ngIf="chatContext?.bounceTimer?.isRunning">
            <merc-bounce-timer [chatContext]="chatContext"
            [ceeTooltip]="{ text: 'Bounce Timer', direction: 'left' }">
            </merc-bounce-timer>
          </div>
        </li>
        <li>
          <div class="response-timer" agent-timer
            *ngIf="chatContext?.agentResponseTimer?.isRunning && !(chatContext?.bounceTimer?.isRunning) && !(isItgRunning$ | async)">
            <merc-agent-response-timer [ceeTooltip]="{ text: 'Response Timer', direction: 'bottom' }"
              [chatContext]="chatContext">
            </merc-agent-response-timer>
          </div>
        </li>
        <li>
          <div class="async-closing-timer" async-closing-timer
            *ngIf="chat.isAsync && chatContext?.customerActivityTimer?.isRunning && showClosingTimer((customerActivityStatus$ | async), (chatContext.customerActivityTimer.value | async))">
            <merc-async-closing-timer [ceeTooltip]="{ text: 'Time remaining', width: 90, direction: 'bottom' }"
              [chatContext]="chatContext">
            </merc-async-closing-timer>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
