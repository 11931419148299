// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CustomEventDataLog } from './custom-event-data-log';
import { EventLogNames } from '../../../constants/event-logs.constants';

export class ErrorEvent extends CustomEventDataLog {
  constructor(dimensions: {}) {
    super(EventLogNames.ErrorEvent, dimensions);
  }
}
