// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../../services/state-persister';
import * as fromVoiceUi from './ui.reducer';

@Injectable({
  providedIn: 'root'
})
export class VoiceUiPersisterService extends StatePersister<fromVoiceUi.State> {
  constructor() {
    super(localStorage, fromVoiceUi.featureKey);
  }
}
