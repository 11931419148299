// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { ConnectAccountActions } from './actions';
import { AccountUsersResponse } from './models/responses/accountUsersResponse';
import { UnlinkAccountResponse } from './models/responses/unlink-account-response';

export const featureKey = 'connectAccount';

export interface State {
  accountUsersResponse: AccountUsersResponse;
  isModalOpen: boolean;
  unlinkResponse: UnlinkAccountResponse;
}

export const initialState: State = {
  accountUsersResponse: null,
  isModalOpen: false,
  unlinkResponse: null
};

export const reducer = createReducer<State>(
  initialState,
  on(
    ConnectAccountActions.ToggleModalOpen,
    (state, { payload }): State => ({
      ...state,
      isModalOpen: payload,
      unlinkResponse: null
    })
  ),
  on(
    ConnectAccountActions.GetChatAccountUsers,
    (state): State => ({
      ...state,
      accountUsersResponse: null
    })
  ),
  on(
    ConnectAccountActions.UpdateAccountUsersResponse,
    (state, { payload }): State => ({
      ...state,
      accountUsersResponse: payload
    })
  ),
  on(
    ConnectAccountActions.Unlink,
    (state): State => ({
      ...state,
      unlinkResponse: null
    })
  ),
  on(
    ConnectAccountActions.UpdateUnlinkAccountResponse,
    (state, { payload }): State => ({
      ...state,
      unlinkResponse: payload
    })
  )
);
