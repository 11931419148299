// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { AvailabilityChange } from '../domain/models/availabilityChange';
import { AgentGroup } from '../domain/models/agentGroup';

@Injectable({
  providedIn: 'root'
})
export class AgentStatePersisterService {
  private LOCAL_STORAGE_KEY: string = 'agent-state';

  constructor() { }

  public storeRegisteredId(registeredId: string) {
    const state = this._readFromStorage();
    state.registeredId = registeredId;
    this._writeToStorage(state);
  }

  public storeUsername(username: string) {
    const state = this._readFromStorage();
    state.username = username;
    this._writeToStorage(state);
  }

  public storeAvailability(status: AvailabilityChange) {
    const state = this._readFromStorage();
    state.availability = status;
    this._writeToStorage(state);
  }

  public storeAuthenticationProvider(authenticationProvider: string){
    const state = this._readFromStorage();
    state.authenticationProvider = authenticationProvider;
    this._writeToStorage(state);
  }

  public storeSelectedGroups(selectedGroups: AgentGroup[]) {
    const state = this._readFromStorage();
    state.selectedGroups = selectedGroups;
    this._writeToStorage(state);
  }

  public storeUnavailableTimestamp(timestamp: number) {
    const state = this._readFromStorage();
    state.unavailableTimestamp = timestamp;
    this._writeToStorage(state);
  }

  public clearRegisteredId()
  {
    const state = this._readFromStorage();
    if (state && state.registeredId) {
      state.registeredId = null;
      this._writeToStorage(state);
    }
  }

  public clearUsername()
  {
    const state = this._readFromStorage();
    if (state && state.username)
    {
      state.username =  null;
      this._writeToStorage(state);
    }
  }

  public clearSelectedGroupIds()
  {
    const state = this._readFromStorage();
    if (state && state.selectedGroups)
    {
      state.selectedGroups =  null;
      this._writeToStorage(state);
    }
  }

  public clearUnavailableTimestamp() {
    const state = this._readFromStorage();
    if (state?.unavailableTimestamp)
    {
      state.unavailableTimestamp =  null;
      this._writeToStorage(state);
    }
  }

  /** Clears the registeredId, username and availablity */
  public clearAgentData() {
    const state = this._readFromStorage();
    if (state)
    {
      if (state.username) {
        state.username =  null;
      }
      if (state.registeredId) {
        state.registeredId = null;
      }
      if (state.availability) {
        state.availability = null;
      }
      this._writeToStorage(state);
    }
  }

  /** Clears the registeredId, username, availablity and authenticationProvider */
  public clearAll()
  {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }

  get registeredId(): string {
    const state = this._readFromStorage();
    return state ? state.registeredId : null;
  }

  get username(): string {
    const state = this._readFromStorage();
    return state ? state.username : null;
  }

  get availabilityStatus(): AvailabilityChange {
    const state = this._readFromStorage();
    return state && state.availability ? state.availability : null;
  }

  get authenticationProvider(): string {
    const state = this._readFromStorage();
    return state ? state.authenticationProvider : null;
  }

  get selectedGroups(): any {
    const state = this._readFromStorage();
    return state ? state.selectedGroups : false;
  }

  get unavailableTimestamp(): number {
    const state = this._readFromStorage();
    return state ? state.unavailableTimestamp : null;
  }

  private _writeToStorage(state: PersistentState): void {
    state.lastStored = new Date();
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(state));
  }

  private _readFromStorage(): PersistentState {
    const storedData: any = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    const state = new PersistentState();

    if (storedData) {
      Object.assign(state, JSON.parse(storedData));
    }

    return state;
  }
}

class PersistentState {
  public registeredId: string;
  public username: string;
  public lastStored: Date;
  public selectedGroups: AgentGroup[];
  public availability: AvailabilityChange;
  public authenticationProvider: string = 'nuanceSso';
  public unavailableTimestamp: number;
}
