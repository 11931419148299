// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ExitAction } from './enums';

export class AgentSettingsBanner {
  active: boolean;
  exitAction: ExitAction;
}
