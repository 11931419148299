// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class SmartResponsesConstants {
  static minWordCount = 200;

  static speakerId = {
    customer: 'CUSTOMER',
    agent: 'AGENT'
  };

  static type = {
    customerSent: 'customerChatlineSent',
    agentSent: 'agentChatlineSent'
  };

  static notifications = {
    historic: {
      icon: '/assets/img/svg-sprite.svg#icon-history-2',
      primaryText: 'Message is from a previous conversation',
      secondaryText: 'Try selecting a message from the current conversation for recommendations.'
    },
    empty: {
      icon: '/assets/img/svg-sprite.svg#icon-message-question',
      primaryText: 'No recommendations for this message',
      secondaryText: 'Select previous messages to view past recommendations.'
    },
    filteredEmpty: {
      icon: '/assets/img/svg-sprite.svg#icon-message-question',
      primaryText: 'No recommendations found',
      secondaryText: 'Try clearing the message editor to see all recommendations.'
    }
  };
}