// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { AvailabilityOption } from 'src/app/models/availability-options';
import { State } from './agent-facing-metrics.reducer';

export const UpdateAgentStateSettings = createAction(
  '[AgentFacingMetrics] Update UpdateAgentStateSettings',
  props<{ maxChats: number, maxExtraChats: number, enableCustomScripts: boolean, availabilityOptions: AvailabilityOption[], agentNetworkId: string, pernrId: string, userGuid: string }>()
);

export const hydrate = createAction('[AgentFacingMetrics] Hydrate');

export const hydrateSuccess = createAction(
  '[AgentFacingMetrics] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[AgentFacingMetrics] Hydrate Failure');

export const updateMetricStatusTimer = createAction(
  '[AgentFacingMetrics] Update Metric Status Timer',
  props<{ available: boolean, durationAdded: number, latestTimestamp: number }>()
);

export const resetLastMetricStatusTimestamp = createAction(
  '[AgentFacingMetrics] Reset Last Metric Status Timestamp',
  props<{ timestamp: number }>()
);

export const updateMetricResponseTime = createAction(
  '[AgentFacingMetrics] Update Metric Response Time',
  props<{ chatId: string, duration: number }>()
);

export const updatePostChatMetrics = createAction(
  '[AgentFacingMetrics] Update Post Chat Metrics',
  props<{ chatId: string, duration: number, isTransfer: boolean }>()
);
