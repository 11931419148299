// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';

export class ActionHelper {
  static createMockAction(type: string, payload?: any){
    return of({type: type, payload: payload});
  }

  static createMockPayloadAction(action: TypedAction<string>, payload?: any) {
    return of({ type: action.type, payload: payload });
  }
}
