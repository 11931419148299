<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  *ngIf="showModal$ | async"
  [active]="showModal$ | async"
  (modalClose)="closeModal()"
  [clickOutsideToClose]="false"
  [customModalClass]="'merc-rate-connection-modal-followup'"
  [customBackdropClass]="'cee-modal-backdrop--hidden merc-rate-connection-followup-backdrop'"
>
  <merc-rate-connection-followup [chatId]="chatId" (closeFeedback)="closeModal()" [showCloseButton]="true"></merc-rate-connection-followup>
  <div class="merc-followup-support">Need immediate support? Contact <cee-button type="link" size="sm" (click)="onMySupportClick()">mySupport</cee-button></div>
</cee-modal>
