// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { GetSmartResponsesResponse } from '../models/responses/get-smart-responses-responses';
import { State } from './smart-responses.reducer';

export const hydrate = createAction('[SmartResponses] Hydrate');

export const hydrateSuccess = createAction(
  '[SmartResponses] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[SmartResponses] Hydrate Failure');

export const chatsRemoved = createAction(
  '[SmartResponses] Chats Removed',
  props<{ chatIds: string[] }>()
);

export const getSmartResponses = createAction(
  '[SmartResponses] Get Smart Responses',
  props<{ chatId: string, messageId: string }>()
);

export const getSmartResponsesFailed = createAction(
  '[SmartResponses] Get Smart Responses Failed',
  props<{ chatId: string, messageId: string}>()
);

export const updateSmartResponses = createAction(
  '[SmartResponses] Update Smart Responses',
  props<{ chatId: string, messageId: string, response: GetSmartResponsesResponse, detailsPanelDefaultTab: string }>()
);
