// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {Chat} from './chat';

export enum LogType {
  agent,
  chat,
  debug,
  error,
  call
}

export interface LogInfo{
  logType: LogType;
  operationName: string;
  data?: any;
  chat?: Chat;
}
