// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import {
    AgentAuthActions, AgentOperations, AgentStatePersisterService, AppActions, AppState, LogHelper
} from 'projects/entities/src/public_api';
import { tap } from 'rxjs/operators';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_App {
    constructor(
        protected ngEntityStore: Store<AppState>,
        protected actions: Actions,
        private loggingFactory: LoggingFactoryService,
        private agentStatePersisterService: AgentStatePersisterService,
        protected config: AppConfigService,
    ) { }

    appInit$ = createEffect(() =>
        this.actions.pipe(
            ofType(AppActions.AppInitialized),
            tap(() => {
                this.ngEntityStore.dispatch(AppActions.InitializeService());
                this.ngEntityStore.dispatch(AgentAuthActions.verifySession());
            })
        ),
        { dispatch: false }
    );

    voiceAppInit$ = createEffect(() =>
        this.actions.pipe(
            ofType(AppActions.VoiceAppInitialized),
            tap(() => {
                this.ngEntityStore.dispatch(AppActions.InitializeVoiceService());
                this.ngEntityStore.dispatch(AgentAuthActions.verifySession());
            })
        ),
        { dispatch: false }
    );
    logAppStopped$ = createEffect(() =>
        this.actions.pipe(
            ofType(AppActions.AppStopped),
            concatLatestFrom(() => this.ngEntityStore.pipe(select(fromChat.selectChatIds))),
            tap(([{ sourceWindowId }, engagementIds]) => {
                const { registeredId: sessionId, username: userId } = this.agentStatePersisterService;

                const logData = {
                    sourceWindowId,
                    engagementIds,
                    sessionId,
                    userId,
                };
                LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ApplicationStopped, logData);
            })
        ),
        { dispatch: false }
    );

}
