// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiResponses } from '../constants/cti.constants';
import { CtiResponse } from './cti-response';

export class CtiSecureDataCollectionInitiatedResponse extends CtiResponse {
  messageName: string = CtiResponses.ChatDialogSecureCollectionInitiated;
  status: string;

  public constructor(sessionId: string, status: string){
    super(sessionId, status);
  }
}
