<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-login-background cee-theme-dark">
  <div class="merc-login-logo">
    <img
      src="../../../assets/img/mercury-with-text.png"
      class="merc-login-image"
    />
  </div>
  <div class="merc-login-form-wrapper">
    <form
      class="merc-login-form merc-login-form--compact merc-padding"
    >
      <h1 class="cee-h1">Welcome Agent,</h1>
      <label class="cee-form-label" for="login-group"
        >Select one or more agent group(s) to join:</label
      >
      <div *ngIf="options.length === 0; else agent_group_form" class="cee-form-row">Agent groups loading...</div>
      <ng-template #agent_group_form>
        <div class="cee-form-row cee-form-row--lined" [ngClass]="{ 'has-error': showCheckboxError }">
            <div class="cee-form-row--group">
            <input
                id="checkbox-all-options"
                type="checkbox"
                name="checkbox-all"
                value="option-all"
                class="cee-checkbox"
                [(ngModel)]="selectAllGroups"
                (ngModelChange)="checkAll()"
            />
            <label
                id="checkbox-all-options-label"
                class="cee-checkbox-label"
                for="checkbox-all-options"
                [ngClass]="{'indeterminate': isPartialChecked}"
                >All agent groups</label
            >
            <div class="cee-form-row--group" *ngFor="let option of options">
                <input
                id="{{ option.id }}"
                type="checkbox"
                (change)="updateCheckAll()"
                name="{{
                    option.displayName.toLowerCase().split(' ').join('-')
                }}"
                value="{{ option.id }}"
                [(ngModel)]="option.checked"
                class="cee-checkbox cee-checkbox--suboption"
                />
                <label class="cee-checkbox-label" for="{{ option.id }}">{{
                option.displayName
                }}</label>
            </div>
            </div>
        </div>
        <p
        *ngIf="showCheckboxError"
        id="username-error-msg"
        class="cee-form-error-msg"
        >
            Please select at least one group.
        </p>
      </ng-template>
      <div class="merc-login-form-submit">
        <cee-button type="link" size="sm" (buttonClick)="logoutClicked()"
          >Logout</cee-button
        >
        <cee-button
          [disabled]="buttonDisabled"
          type="primary"
          size="sm"
          (buttonClick)="onSubmit()"
          dataQa="join-groups"
          >Join ({{ groupTotal }}) groups</cee-button
        >
      </div>
    </form>
  </div>
  <merc-agent-settings-confirm-logout-modal
    [active]="confirmLogoutModalActive"
    (modalClose)="onCancelLogout()"
    (modalConfirm)="onConfirmLogout()"
  ></merc-agent-settings-confirm-logout-modal>
</div>
