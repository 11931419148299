// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatTranscriptResponse } from './chat-transcript-response';

export class GetEngagementByIdResponse {
  chatId: string;
  value: ChatTranscriptResponse;
}


export class GetEngagementsResponse {
  chatId: string;
  value: ChatTranscriptResponse[];
}
