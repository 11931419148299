// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, ViewEncapsulation, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ConvoMessageComponent } from '../convoMessage/convoMessage.component';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { SystemChatMessage, SystemMessageType, ChatMessageType, AppState, AgentCustomSettings, showCustomerActions, ChatUiActions, FeatureFlags, ChatHelper, LogActions, AgentOperations } from 'projects/entities/src/public_api';
import { CalendarSelectionType, ChannelType, SystemInfoMessageType } from 'projects/entities/src/lib/domain/models/enums';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';


@Component({
  selector: 'merc-convo-system-message',
  templateUrl: './convoSystemMessage.component.html',
  styleUrls: ['./convoSystemMessage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoSystemMessageComponent extends ConvoMessageComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  chatMessage: SystemChatMessage;
  @Input() showPin: boolean = true;
  isNavigation: boolean = false;
  isXA: boolean = false;
  isItgAction: boolean = false;
  typeString: string = 'info';
  toAction: any;
  isDatapass: boolean =  false;
  isShowPageNavigation$: Observable<boolean>;
  isShowCustomerActions$: Observable<boolean>;
  isPinned$: Observable<boolean>;
  hasPinFeatureFlag$: Observable<boolean>;

  constructor(
    timeHelper: TimeHelperService,
    store: Store<AppState>,
    private ngEntityStore: Store<AppState>,
    private elementReference: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) { super(timeHelper, store); }

  ngOnChanges(changes: SimpleChanges){
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'chatMessage': {
            this.isNavigation = ChatHelper.isNavigation(this.chatMessage);
            this.isXA = ChatHelper.isXaMessage(this.chatMessage);
            this.isDatapass = ChatHelper.isDataPass(this.chatMessage);
            this.isItgAction = ChatHelper.isItgAction(this.chatMessage);
            this.typeString = this.getTypeString();
            this.toAction = this.getToAction();
            break;
          }
          case 'parentChat': {
            if (changes.parentChat && changes.parentChat.previousValue && !changes.parentChat.previousValue.timeZone && changes.parentChat.currentValue && changes.parentChat.currentValue.timeZone) {
              this.toAction = this.getToAction();
              break;
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    const { chatId, messageId } = this.chatMessage;
    this.isShowPageNavigation$ = this.getPageNavigationSetting();
    this.isShowCustomerActions$ = this.ngEntityStore.select(showCustomerActions);
    this.hasPinFeatureFlag$ = this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.MessagePinning));

    this.isPinned$ = this.ngEntityStore.select(fromChat.isMessagePinned(chatId, messageId));
    this.getShowPin();
  }

  togglePin(isPinned: boolean) {
    const {chatId, messageId} = this.chatMessage;
    if (isPinned) {
      this.ngEntityStore.dispatch(ChatUiActions.pinMessage({chatId, messageId, source: 'datapass'}));
    } else {
      this.ngEntityStore.dispatch(ChatUiActions.unpinMessage({chatId, messageId, source: 'message'}));
    }
  }

   ngAfterViewInit() {
    this.changeDetector.detectChanges();
    const systemMessageLink: HTMLElement = this.elementReference.nativeElement.querySelector('.merc-convo-system-anchor');
    if (systemMessageLink){
      systemMessageLink.addEventListener('click',  this.pageNavigatedLink.bind(this));
    }
  }

  getShowPin () {
    this.showPin = this.showPin && !this.isXA && !this.isItgAction;
  }

  private getPageNavigationSetting(): Observable<boolean> {
    return this.ngEntityStore.pipe(
      select(fromSettings.getCustomSettings),
      map((settings) => this.showPageNavigation(settings))
    );
  }

  private showPageNavigation(settings: AgentCustomSettings): boolean {
    const isNavigateMessage = this.chatMessage.systemInfoMessageType === SystemInfoMessageType.PageNavigated;
    return !isNavigateMessage || (isNavigateMessage && (settings === null || settings.showPageNavigation === null || settings.showPageNavigation));
  }

  getToAction(): any {
    const channelName = this.channelType === ChannelType.WebChat ? 'web' : 'mobile';
    const formattedTime = this.getFormattedTime(this.chatMessage.timestamp);

    switch (this.chatMessage.systemType){
      case SystemMessageType.Datapass:
        return {
          message: this.chatMessage.message,
          title: this.chatMessage.title,
          datetime: formattedTime
      };
      case SystemMessageType.Navigation:
        if (this.chatMessage.systemInfoMessageType === SystemInfoMessageType.PageNavigated){
          return {
            message: `<a class="merc-convo-system-anchor" target="_blank" href="${this.chatMessage.message}" data-qa="customer-navigation">${this.chatMessage.message}</a>`,
            dataQa: 'customer-navigation',
            datetime: formattedTime
          };
        }
    }

    switch (this.chatMessage.type) {
      default: return {
        message: this.chatMessage.message,
        datetime: formattedTime
      };
      case ChatMessageType.AddressUpdate:
        return {
          message: 'Address Updated',
          datetime: formattedTime,
          content: [this.chatMessage.address,
            `${this.chatMessage.city}, ${this.chatMessage.state} ${this.chatMessage.zipCode}`],
          size: 'large',
          icon: 'xa-address',
          channel: channelName
        };
      case ChatMessageType.TopicSelection:
        return {
          message: this.chatMessage.topic,
          datetime: formattedTime,
          size: 'large',
          icon: 'xa-action',
          channel: channelName
        };
      case ChatMessageType.CalendarSelection:
        return {
          message: this.chatMessage.calendarSelectionType === CalendarSelectionType.Reschedule ? 'Appointment rescheduled' : 'Appointment scheduled',
          datetime: formattedTime,
          content: [this.getFormattedDate(this.chatMessage.selectedDate), this.chatMessage.timeRange, `${this.chatMessage.address},
            ${this.chatMessage.city}, ${this.chatMessage.state} ${this.chatMessage.zipCode}`],
          size: 'large',
          icon: 'xa-calendar',
          channel: channelName
        };
        case ChatMessageType.ItgAction:
          return {
            message: this.chatMessage.message,
            datetime: formattedTime,
            size: 'large',
            icon: 'connect',
            metaInfo: '(Only visible to you)',
            channel: channelName
          };
    }
  }

  pageNavigatedLink(){
    const logPayload = {
      logType: LogType.agent,
      operationName: AgentOperations.OutboundLinkClick,
      data: {
        source: 'datapass',
        url: this.chatMessage.message
      }
    };

    this.ngEntityStore.dispatch(LogActions.logAgentAction({logPayload}));
  }

  private getTypeString(): string {
    switch (this.chatMessage.systemType) {
      default:
      case SystemMessageType.Information:
        return 'info';
      case SystemMessageType.CustomerReconnect: return 'success';
      case SystemMessageType.CustomerDisconnect:
      case SystemMessageType.CustomerExit:
        return 'critical';
    }
  }
}
