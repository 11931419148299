// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { AccessToken } from '@cxt-cee-chat/merc-ng-core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserIdentityService {
	username: string;
	fullName = new BehaviorSubject<string>('');
	registeredId: string;


	constructor(
		public jwtService: JwtService
	) {	}

	public Hydrate(token: AccessToken) {
		const payload = this.jwtService.Decode(token.accessToken);
		this.username = payload && payload.user_name ? payload.user_name : '';
	}

	updateName(name: string) {
		this.fullName.next(name);
	}
}
