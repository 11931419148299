// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AutoGreetMessageConstants } from '../constants/auto-greet-messages.constants';
import { SuggestionTitles } from '../constants/constants';
import { ChatGreetingEvents } from '../constants/event-logs.constants';
import { Chat } from '../domain/models';
import { ChatStartGreetingType } from '../domain/models/enums';
export class ChatStartGreetingHelper {
  public static GetChatStartGreetingType(chat: Chat): ChatStartGreetingType {
    if (chat?.isAsync){
      if (!chat.asyncEngagements || chat.asyncEngagements.length === 0){
        return ChatStartGreetingType.FirstAsync;
      } else {
        return ChatStartGreetingType.ReturningAsync;
      }
    }
    if (chat?.isReconnectEngagement && chat?.asyncEngagements?.length === 1){
      return ChatStartGreetingType.SecondReconnect;
    }
    if (chat?.transferred){
      return ChatStartGreetingType.Transfer;
    }
    return ChatStartGreetingType.None;
  }

  public static GetChatStartGreeting(chatStartGreetingType: ChatStartGreetingType, newAsyncGreeting: string, returningDisconnectGreeting: string, agentName: string, customerName: string): string{
    switch (chatStartGreetingType) {
      case ChatStartGreetingType.FirstAsync:
        return newAsyncGreeting;
      case ChatStartGreetingType.ReturningAsync:
        return AutoGreetMessageConstants.ReturnAsync(customerName, agentName);
      case ChatStartGreetingType.SecondReconnect:
        return returningDisconnectGreeting; 
      case ChatStartGreetingType.Transfer:
          return AutoGreetMessageConstants.Transferred(agentName);
      default:
        return '';
    }
  }

  public static GetChatStartGreetingTitle(chatStartGreetingType: ChatStartGreetingType) {
    switch (chatStartGreetingType) {
      case ChatStartGreetingType.SecondReconnect:
        return SuggestionTitles.customerReturned;
      case ChatStartGreetingType.Transfer:
        return SuggestionTitles.transferred;
      case ChatStartGreetingType.FirstAsync:
      case ChatStartGreetingType.ReturningAsync:
      default:
        return SuggestionTitles.recommendedResponse;
    }
  }

  public static GetChatGreetingEvents(chatStartGreetingType: ChatStartGreetingType) {
    switch (chatStartGreetingType) {
      case ChatStartGreetingType.Transfer:
        return ChatGreetingEvents.Transfer;
      case ChatStartGreetingType.FirstAsync:
        return ChatGreetingEvents.FirstAsync;
      case ChatStartGreetingType.ReturningAsync:
        return ChatGreetingEvents.ReturningAsync;
      default:
        return ChatGreetingEvents.WebReconnect;
    }
  }
}
