<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="isXaResponse(chatMessage.type); else nonXaResponse">
  <merc-convo-message-xa-response
    [isMessageSelected]="(canSelectMessage$ | async) && (isSelectedMessageId$ | async)"
    [canSelectMessage]="(canSelectMessage$ | async)"
    [chatMessage]="chatMessage"
    [chatId]="chatId"
    [channelType]="channelType"
    [colorId]="colorId"
    [timeZone]="timeZone">
  </merc-convo-message-xa-response>

</ng-container>
<ng-template #nonXaResponse>
  <merc-convo-participant-message *ngIf="!this.isSystemMessage"
      [isMessageSelected]="(canSelectMessage$ | async) && (isSelectedMessageId$ | async)"
      [canSelectMessage]="(canSelectMessage$ | async)"
      [chatMessage]="chatMessage"
      [chatId]="chatId"
      [channelType]="channelType"
      [colorId]="colorId"
      [timeZone]="timeZone"
      [previousMessage]="previousMessage"
      [participants]="participants"
      [customerDetails]="customerDetails">
  </merc-convo-participant-message>
  <ng-container *ngIf="isSystemMessage">
    <merc-convo-system-message
      [chatMessage]="chatMessage"
      [chatId]="chatId"
      [channelType]="channelType"
      [colorId]="colorId"
      [timeZone]="timeZone">
    </merc-convo-system-message>
  </ng-container>
</ng-template>
