// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TimerComponent } from '../../timer/timer.component';

@Component({
  selector: 'merc-cust-card-timer',
  templateUrl: '../../timer/timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustCardTimerComponent extends TimerComponent { }
