// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import {LogInfo} from './models/LogTypeInterfaces';

export const logChatAction = createAction(
  '[Log] Chat Event',
  props< {logPayload: LogInfo }>()
);

export const logSelectedChatAction = createAction(
  '[Log] Selected Chat Event',
  props< {logPayload: LogInfo }>()
);

export const logAgentAction = createAction(
  '[Log] Agent Event',
  props< {logPayload: LogInfo }>()
);

export const logDebug = createAction(
  '[Log] Debug',
  props< {logPayload: LogInfo }>()
);

export const logCurrentCallAction = createAction(
  '[Log] Current Call Event',
  props< {logPayload: LogInfo }>()
);
