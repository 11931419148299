// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector : 'merc-convo-window-base',
  templateUrl: './convoBase.component.html',
  styleUrls: ['./convoBase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoBaseComponent {

}
