// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { AgentOperations, CallOperations } from '../../../constants/event-logs.constants';
import { LogHelper } from '../../../utils/logHelper';
import { SingleColumnHomeDisplayMode } from '../../models/voice/enums';
import { fromCall, fromVoiceUi } from '../../selectors';
import { AppState } from '../../state';
import * as VoiceUiActions from './ui.actions';
import { VoiceUiPersisterService } from './voice-ui-persister.service';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class VoiceUiEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: VoiceUiPersisterService,
    private loggingFactory: LoggingFactoryService,
    ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(VoiceUiActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? VoiceUiActions.hydrateSuccess({ state })
          : VoiceUiActions.hydrateFailure();
      })
    )
  );

  saveInStorage$ = createEffect(
    () =>
      this.action.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
        ofType(VoiceUiActions.hydrateSuccess, VoiceUiActions.hydrateFailure),
        switchMap(() => this.store.pipe(select(fromVoiceUi.selectUiFeature))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(state => {
          this.persisterService.storeState(state);
        })
      ),
    { dispatch: false }
  );

  logShowLiveTranscriptToggle$ = createEffect(() =>
    this.action.pipe(
      ofType(VoiceUiActions.ToggleLiveTranscriptCollapse),
      tap(({ isCollapsed, isBottomButton }) => {
        const logDimensions = {
          buttonPosition: isBottomButton ? 'bottom' : 'top',
          isCollapsed
        };
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ShowLiveTranscript, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logHideAgentTranscriptToggle$ = createEffect(() =>
    this.action.pipe(
      ofType(VoiceUiActions.ToggleShowAgentTranscript),
      tap(({ isEnabled }) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.HideAgentTranscript, { agentTranscriptHidden: !isEnabled });
      })
    ),
    { dispatch: false }
  );

  logFloatingDisplayButtonsToggle$ = createEffect(() =>
    this.action.pipe(
      ofType(VoiceUiActions.ToggleHomeDisplayMode),
      tap(({ displayMode }) => {
        const logDimensions = {
          summaryShown: displayMode === SingleColumnHomeDisplayMode.SessionSummary,
          transcriptShown: displayMode === SingleColumnHomeDisplayMode.LiveTranscript
        };

        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.FloatingToggleClicked, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logJumpToLatest$ = createEffect(() =>
    this.action.pipe(
      ofType(VoiceUiActions.JumpToLatestClicked),
      concatLatestFrom(() => [
        this.store.select(fromCall.getCurrentCall)
      ]),
      tap(([{ source }, call]) => {
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.JumpedToLatest, call, { source });
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return VoiceUiActions.hydrate();
  }
}
