// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { ErrorMessages } from 'projects/entities/src/lib/constants/error-messages';
import { AgentChatMessage } from 'projects/entities/src/lib/domain/models/agent-chat-message';
import { XaSuggestionChatMessage } from 'projects/entities/src/lib/domain/models/xa-suggestion-chat-message';
import { XaSuggestionHeading } from 'projects/entities/src/lib/domain/models/suggestion';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { AppState, ChatActions, ChatHelper, ChatUiActions, FeatureFlags } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ConvoMessageComponent } from '../convoMessage/convoMessage.component';
import { ConvoMessageService } from '../convoMessage/convoMessage.service';

@Component({
  selector: 'merc-convo-message-xa-response',
  templateUrl: './convo-message-xa-response.component.html',
  styleUrls: ['./convo-message-xa-response.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoMessageXaResponseComponent extends ConvoMessageComponent implements OnInit, OnChanges {
  @Input() chatMessage: XaSuggestionChatMessage;
  @Input() showPin: boolean = true;
  @Input() isMessageSelected: boolean = false;
  @Input() canSelectMessage: boolean = false;

  hasPinFeatureFlag$: Observable<boolean>;
  isPinned$: Observable<boolean>;
  failedStateMessage: string = ErrorMessages.sendMessageFailed;
  _showPin: boolean;
  hideAnimations$: Observable<boolean>;

  translatedMessageText$: Observable<string>;

  constructor(timeHelper: TimeHelperService,
    store: Store<AppState>,
    private convoMessageService: ConvoMessageService){
    super(timeHelper, store); }

  ngOnInit() {
    this.hasPinFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.MessagePinning));
    this.hideAnimations$ = this.store.select(fromSettings.hideAnimations);
    const { chatId } = this;
    this.isPinned$ = this.store.select(fromChat.getPinnedMessageIds(chatId))
      .pipe(
        map((pinnedMessageIds: string[]) => {
          return ChatHelper.isMessagePinned(pinnedMessageIds, this.chatMessage.messageId);
        }),
        distinctUntilChanged()
      );
    this._showPin = this.getShowPin();
    this.translatedMessageText$ = this.store.select(fromChat.getTranslatedMessageText(chatId, this.chatMessage.traceId, this.chatMessage.messageId));
  }

  ngOnChanges(changes: SimpleChanges){
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'chatMessage': {
            this._showPin = this.getShowPin();
            break;
          }
        }
      }
    }
  }

  togglePin(isPinned: boolean) {
    const {chatId, messageId, intent} = this.chatMessage;
    if (isPinned) {
      this.store.dispatch(ChatUiActions.pinMessage({chatId, messageId, source: 'agent', intent: intent }));
    } else {
      this.store.dispatch(ChatUiActions.unpinMessage({chatId, messageId, source: 'message'}));
    }
  }

  formatDataCard(xaSuggestionHeading: XaSuggestionHeading) {
    if (xaSuggestionHeading) {
      let result = xaSuggestionHeading.title ? `<strong>${xaSuggestionHeading.title}</strong>\n` : '';
      result += xaSuggestionHeading.message;
      return result;
    }
  }

  getShowPin(): boolean {
    const status = (this.chatMessage as AgentChatMessage).status;
    return ChatHelper.showMessagePin(this.chatId, this.chatMessage.chatId, status) && this.showPin;
  }

  selectMessage() {
    // Top card shouldn't be selectable, so the agent's xa response should always pass true
    const isValidMessageType = true;
    this.convoMessageService.selectMessage(this.canSelectMessage, this.isMessageSelected, this.chatId, this.chatMessage, isValidMessageType);
  }

  retrySendXaSuggestion(){
    this.store.dispatch(ChatActions.retrySendXaSuggestion({chatMessage: this.chatMessage, newTraceId: Guid.raw() }));
  }

  deleteMessage() {
    this.store.dispatch(ChatActions.deleteChatMessage({ chatMessage: this.chatMessage }));
  }
}
