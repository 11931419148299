// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { ChatUi } from './chat-ui.model';
import * as ChatUiActions from './chat-ui.actions';
import { AskMeAnythingActions, ChatActions, ProfanityActions, SmartResponsesActions, UiActions } from '../actions';
import { PriorEngagementConstants } from '../../constants/constants';
import { ChatMessageType, CustomerActivityStatus, DetailsSelectedTab, ItgActionType, ItgState, SenderType, XaSuggestionActionType } from '../models/enums';
import { ItgMetadata } from '../models/itgMetadata';
import { GetPriorEngagementsLoadingData } from '../models/getPriorEngagements';
import { UnFocusedChat } from '../models/unFocusedChat';
import { AbandonedChatAutomationHelper } from '../../utils/abandoned-chat-automation-helper';
import { ObjectHelper } from '../../utils/object-helper';
import { ItgHelper } from '../../utils/itg-helper';
import { SecureDataCollectionHelper } from '../../utils/secure-data-collection-helper';
import { GetXaSuggestionsMessage } from '../models/getXaSuggestionsMessage';
import { MessageInputErrorMessages, MessageInputInfoMessages } from '../../constants/error-messages';

export const chatUiFeatureKey = 'chatUi';

export interface State extends EntityState<ChatUi> {
  selectedChatId: string | null;
  selectedChatTimeStamp: number | null;
  unFocusedChat: UnFocusedChat;
}

export const adapter: EntityAdapter<ChatUi> = createEntityAdapter<ChatUi>({
  selectId: (chatUi: ChatUi) => chatUi.chatId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedChatId: null,
  selectedChatTimeStamp: null,
  unFocusedChat: null
});

export const reducer = createReducer(
  initialState,
  on(ChatUiActions.agentTypingStarted, (state, action) => {
    const { chatId } = action;
    const chatUi: ChatUi = {
      chatId,
      isAgentTyping: true,
      hasAgentResponded: true
    };
    return adapter.upsertOne(chatUi, state);
  }),
  on(UiActions.ScriptClicked, (state, action) => {
    const { chatId, quickSend } = action?.scriptInUse;
    return updateHasAgentResponded(state, chatId, quickSend);
  }),
  on(UiActions.SmartResponseClicked, (state, action) => {
    const { chatId, quickSend } = action?.smartResponseInUse;
    return updateHasAgentResponded(state, chatId, quickSend);
  }),
  on(ChatActions.SendSuggestion, (state, action) => {
    return updateHasAgentResponded(state, action?.payload?.chatId, true);
  }),
  on(ChatActions.SendMessage, (state, action) => {
    const updatedState = updateHasAgentResponded(state, action?.chatMessage?.chatId, true);
    return updatedState;
  }),
  on(ChatUiActions.agentTypingStopped, (state, action) => {
    const { chatId } = action;
    const chatUi: ChatUi = {
      chatId,
      isAgentTyping: false
    };
    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.customerTypingStatusChanged, (state, { customerTypingStatus }) => {
    const { chatId, isCustomerTyping } = customerTypingStatus;
    const chatUi: ChatUi = {
      chatId,
      isCustomerTyping: isCustomerTyping
    };
    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.customerTypingStatusExpired, (state, { chatId }) => {
    const chatUi: ChatUi = {
      chatId,
      isCustomerTyping: null
    };
    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatActions.CustomerDisconnected,
    (state, { payload: chatId }): State => {
      const chatUi: ChatUi = {
        chatId,
        isCustomerTyping: false
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.agentSelectedChat, (state, { chatId, lastViewedTimestamp }) => {
    return selectChat(state, chatId, lastViewedTimestamp, true);
  }),
  on(ChatActions.Closed,
    ChatActions.Bounced,
    ChatActions.Transferred, (state, { payload }) => {
      const {chatId} = payload;
      return adapter.removeOne(chatId, state);
  }),
  on(ChatUiActions.chatsRemoved, (state, { chatIds }) => {
    const chatsRemovedState = adapter.removeMany(chatIds, state);
    const selectedChatId = state.selectedChatId;

    if (selectedChatId && !chatsRemovedState.entities[selectedChatId]){
      return {
        ...chatsRemovedState,
        selectedChatId: null
      };
    }

    return chatsRemovedState;
  }),
  on(ChatUiActions.chatViewed, (state, { chatId, lastViewedTimestamp }) => {
    const chatUi: ChatUi = {
      chatId,
      lastViewedTimestamp
    };
    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.searchScripts, (state, action) => {
    const { chatId, query: scriptSearchTerm } = action;
    const chatUi: ChatUi = {
      chatId,
      scriptSearchTerm,
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.selectChat, (state, { chatId, lastViewedTimestamp }) => {
    return selectChat(state, chatId, lastViewedTimestamp);
  }),
  on(ChatUiActions.pinMessage, (state, action) => {
    const { chatId, messageId } = action;

    const { pinnedMessages } = state.entities[chatId] ?? {};
    const updatedPinnedArray = pinnedMessages ? [...pinnedMessages, messageId] : [messageId];

    const chatUi: ChatUi = {
      chatId,
      pinnedMessages: updatedPinnedArray
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.unpinMessage, (state, action) => {
    const { chatId, messageId } = action;
    const { pinnedMessages } = state.entities[chatId] ?? {};
    const chatUi: ChatUi = {
      chatId,
      pinnedMessages: pinnedMessages?.filter(m => m !== messageId)
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.clearPinnedMessages, (state, action) => {
    const { chatId } = action;
    const chatUi: ChatUi = {
      chatId,
      pinnedMessages: []
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(ChatActions.UpdateAccountNumber, (state, action) => {
    const priorEngagementsLoadingData: GetPriorEngagementsLoadingData = {
      pageNumber: 1,
      fetchTimestamp: null
    };
    const chatUi: ChatUi = {
      chatId: action.payload.chatId,
      isLoadingPriorEngagements: true,
      priorEngagementsLoadingData
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatActions.GetPriorEngagements, (state, action) => {
    const { chat, loadNextPage, fetchTimestamp } = action.payload;
    const currentPageNumber = state.entities[chat.chatId]?.priorEngagementsLoadingData?.pageNumber ?? 1;
    const priorEngagementsLoadingData = loadNextPage ? {
      pageNumber: currentPageNumber + 1,
      fetchTimestamp
    } : {
      pageNumber: Math.min(currentPageNumber, PriorEngagementConstants.initialMaxPagesLoaded),
      fetchTimestamp: null
    };

    const chatUi: ChatUi = {
      chatId: chat.chatId,
      isLoadingPriorEngagements: true,
      priorEngagementsLoadingData
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatActions.SetPriorEngagements,
    (state, { payload }): State => {
      const { chatId, totalEngagements } = payload;
      const chatUi: ChatUi = {
        chatId,
        isLoadingPriorEngagements: false,
        totalEngagements: totalEngagements
      };

      return adapter.upsertOne(chatUi, state);
  }),
  on(ChatActions.GetXaSuggestions,
    (state, { chatId, message }): State => {
      const itgs = state.entities[chatId]?.itgsInProgress ?? [];

      if (itgs.length === 0){
        return state;
      }

      const itgsInProgress = itgs.map<ItgMetadata>(itg => {
        if (itg.state === ItgState.Running){
          return {
            ...itg,
            pendingAutopilotCustomerMessage: message
          };
        }
        return itg;
      });

      const chatUi: ChatUi = {
        chatId,
        itgsInProgress
      };

      return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.startItg,
    (state, { chatId, itgSuggestion, timestamp }): State => {
      const title = itgSuggestion.itgIntent.title;
      const itgs = state.entities[chatId]?.itgsInProgress ?? [];
      const exists = itgs?.find(itg => itg.title === title);
      const message: GetXaSuggestionsMessage = {
        actionType: XaSuggestionActionType.Intent,
        message: itgSuggestion.itgIntent.buttonTarget,
        messageId: itgSuggestion.messageId
      };

      const startAction = { type: ItgActionType.Start, timestamp };
      let itgsInProgress;

      itgsInProgress = itgs.map<ItgMetadata>(itg => {
        const newItg = {...itg};
        if (newItg.title === title){
          newItg.state = ItgState.Running;
          newItg.actions = [...newItg.actions, startAction];
          newItg.lastCustomerMessageDuringAutoPilot = message;
        }
        else if (newItg.state === ItgState.Running || newItg.state === ItgState.Paused){
          newItg.state = ItgState.Canceled;
          newItg.actions = [...newItg.actions, { type: ItgActionType.Cancel, timestamp }];
        }
        return newItg;
      });

      if (!exists){
        const newItg: ItgMetadata = {
          title,
          actions: [startAction],
          state: ItgState.Running,
          lastCustomerMessageDuringAutoPilot: message
        };
        itgsInProgress = [...itgsInProgress, newItg];
      }

      const chatUi: ChatUi = {
        chatId,
        itgsInProgress,
        detailsPanelTabSelected: DetailsSelectedTab.Activities,
        hasActivitiesNotification: false
      };

      return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.itgCompleted,
    ChatUiActions.itgSchedulerCompleted,
    (state, { chatId, title, timestamp }): State => {
      const updatedItgState = updateItg(state, chatId, title, ItgState.Completed, ItgActionType.End, timestamp);
      return restartAcaAtActive(chatId, timestamp, updatedItgState);
    }
  ),
  on(ChatUiActions.pauseItg,
    (state, { chatId, title, timestamp }): State => updateItg(state, chatId, title, ItgState.Paused, ItgActionType.Pause, timestamp)
  ),
  on(ChatUiActions.resumeItg,
    (state, { chatId, title, timestamp }): State => updateItg(state, chatId, title, ItgState.Running, ItgActionType.Resume, timestamp)
  ),
  on(ChatUiActions.cancelItg,
    (state, { chatId, title, timestamp }): State => updateItg(state, chatId, title, ItgState.Canceled, ItgActionType.Cancel, timestamp)
  ),
  on(ChatUiActions.itgError,
    (state, { chatId, title, timestamp }): State => updateItg(state, chatId, title, ItgState.Error, ItgActionType.Error, timestamp)
  ),
  on(ChatUiActions.itgSchedulerError,
    (state, { chatId, title, timestamp }): State => updateItg(state, chatId, title, ItgState.SchedulerError, ItgActionType.Error, timestamp)
  ),
  on(ChatActions.AddXaSuggestions,
    (state, { payload }): State => {
      if (Boolean(payload?.itgDriftIntents?.length)) {
        return state;
      }

      const { chatId } = payload;
      const itgs = state.entities[chatId]?.itgsInProgress ?? [];

      const itgsInProgress = itgs.map<ItgMetadata>(itg => {
        if (itg.state === ItgState.Running){
          return {
            ...itg,
            lastCustomerMessageDuringAutoPilot: itg.pendingAutopilotCustomerMessage
          };
        }
        return itg;
      });

      const chatUi: ChatUi = {
        chatId,
        itgsInProgress
      };

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.dismissItg,
    (state, { chatId, title }): State => {
      const itgs = state.entities[chatId]?.itgsInProgress ?? [];

      if (itgs.length === 0){
        return state;
      }

      const itgsInProgress = itgs.map<ItgMetadata>(itg => {
        const newItg = {...itg};

        if (newItg.title === title){
          newItg.dismissed = true;
        }
        return newItg;
      });

      const chatUi: ChatUi = {
        chatId,
        itgsInProgress
      };

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.itgContinueWithScheduler,
    (state, { chatId, title }): State => {
      const itgs = state.entities[chatId]?.itgsInProgress ?? [];

      if (itgs.length === 0){
        return state;
      }

      const itgsInProgress = itgs.map<ItgMetadata>(itg => {
        const newItg = {...itg};

        if (newItg.title === title){
          newItg.isScheduling = true;
        }
        return newItg;
      });

      const chatUi: ChatUi = {
        chatId,
        itgsInProgress
      };

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.toggleSuggestionsIsExpanded,
    (state): State => {
      const chatId = getSelectedChatId(state);
      if (!chatId){
        return state;
      }
      const isExpanded = state.entities[chatId]?.isSuggestionsExpanded;
      const isSuggestionsExpanded = !isExpanded;
      const chatUi: ChatUi = {
        chatId,
        isSuggestionsExpanded
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.updateDetailsPanelScrollPos,
    (state, {chatId, detailsPanelScrollPosition}): State => {

      const chatUi: ChatUi = {
        chatId,
        detailsPanelScrollPosition
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.updateAmaPanelScrollPos,
    (state, {chatId, scrollPosition}): State => {

      const chatUi: ChatUi = {
        chatId,
        askMeAnythingPanelScrollPosition: scrollPosition
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.updateDetailsPanelTabSelected,
    (state, {chatId, detailsPanelTabSelected}): State => {

      const chatUi: ChatUi = {
        chatId,
        detailsPanelTabSelected
      };

      if (detailsPanelTabSelected === DetailsSelectedTab.Recommended) {
        chatUi.hasSmartResponseNotification = false;
      }

      if (detailsPanelTabSelected === DetailsSelectedTab.Activities) {
        chatUi.hasActivitiesNotification = false;
      }

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(AskMeAnythingActions.search,
    (state, {chatId, isCustomerQuestion}): State => {
      if (isCustomerQuestion) {
        const chatUi: ChatUi = {
          chatId,
          detailsPanelTabSelected: DetailsSelectedTab.Knowledge
        };
  
        return adapter.upsertOne(chatUi, state);
      }

      return state;
    }
  ),
  on(ChatUiActions.toggleShowDispositions,
    (state, {chatId}): State => {
      if (!chatId){
        return state;
      }
      const isExpanded = state.entities[chatId]?.showDispositions;
      const showDispositions = !isExpanded;
      const chatUi: ChatUi = {
        chatId,
        showDispositions
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.toggleScriptGroup, (state, { chatId, scriptGroupId }) => {
    const entity = state.entities[chatId];
    const { expandAllScriptGroups } = entity || {};

    let expandedScriptGroups = entity?.expandedScriptGroups || [];
    let collapsedScriptGroups = entity?.collapsedScriptGroups || [];

    const appearsExpanded = expandedScriptGroups.includes(scriptGroupId) || (expandAllScriptGroups && !collapsedScriptGroups.includes(scriptGroupId));

    if (appearsExpanded) {
      expandedScriptGroups = expandedScriptGroups.filter(i => i !== scriptGroupId);
      collapsedScriptGroups = [...collapsedScriptGroups, scriptGroupId];
    }
    else {
      expandedScriptGroups = [...expandedScriptGroups, scriptGroupId];
      collapsedScriptGroups = collapsedScriptGroups.filter(i => i !== scriptGroupId);
    }

    const chatUi: ChatUi = {
      chatId,
      expandedScriptGroups,
      collapsedScriptGroups
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.expandAllScriptGroups, (state, { chatId }) => {

    const chatUi: ChatUi = {
      chatId,
      expandedScriptGroups: [],
      collapsedScriptGroups: [],
      expandAllScriptGroups: true,
      isSuggestionsExpanded: true
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.collapseAllScriptGroups, (state, { chatId }) => {

    const chatUi: ChatUi = {
      chatId,
      expandedScriptGroups: [],
      collapsedScriptGroups: [],
      expandAllScriptGroups: false,
      isSuggestionsExpanded: false
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.toggleViewSystemScripts, (state, { chatId }) => {
    const entity = state.entities[chatId];
    const { hideSystemScripts } = entity || {};

    const chatUi: ChatUi = {
      chatId,
      hideSystemScripts: !hideSystemScripts,
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.updateTextEditorContent, (state, { chatId, content }) => {
    const chatUi: ChatUi = {
      chatId,
      textEditorContent: content
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatActions.UpdateSmartResponseFeedBack, (state, { chatId }) => {
    const chatUi: ChatUi = {
      chatId,
      displayedSmartResponses: [],
      displayedFilteredSmartResponses: []
    };

    return adapter.upsertOne(chatUi, state);
  }),
  on(ChatUiActions.UpdateDisplayedSmartResponse, (state, {chatId, recommendationIds}) => {
      const entity = state.entities[chatId];
      if (!recommendationIds || !entity){ return state; }

      const displayedSmartResponses: string[] = entity.displayedSmartResponses ? [...entity.displayedSmartResponses] : [];
      const displayedFilteredSmartResponses: string[] = entity.displayedFilteredSmartResponses ? [...entity.displayedFilteredSmartResponses] : [];

      recommendationIds.forEach(r => {
        if (entity.textEditorContent && displayedFilteredSmartResponses.indexOf(r) === -1) {
            displayedFilteredSmartResponses.push(r);
        }

        if (displayedSmartResponses.indexOf(r) === -1) {
          displayedSmartResponses.push(r);
        }
      });

      const chatUi: ChatUi = {
        chatId,
        displayedFilteredSmartResponses,
        displayedSmartResponses
      };

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.updateAutomationWarningIndex,
    (state, { chatId, index: warningIndex, currentTime }): State => {
      const entity = state.entities[chatId];
      if (!entity) { return state; }

      const duration = AbandonedChatAutomationHelper.getCustomerActivityStatusDuration(entity.customerActivityStatus, warningIndex, entity.automationSettings);
      const chatUi: ChatUi = {
        chatId,
        abandonedChatAutomationTimerDetails: {
          activityTimestamp: currentTime,
          duration
        },
        warningIndex
      };

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.updateCustomerActivityStatus,
    (state, { chatId, status, lastStatus, stopTimer, currentTime }): State => {
      const entity = state.entities[chatId];
      if (!entity) { return state; }

      const newPinCount = status === CustomerActivityStatus.Pinned ? (entity.pinCount ?? 0) + 1 : entity.pinCount;
      const pinTimestamp = status === CustomerActivityStatus.Pinned ? currentTime : entity.lastPinClickTimestamp;
      const unPinTimestamp = lastStatus === CustomerActivityStatus.Pinned ? currentTime : entity.lastUnPinClickTimestamp;

      // when the chat goes to active, we want to stop the timer if the customer was last to respond
      const duration = stopTimer ? 0 : AbandonedChatAutomationHelper.getCustomerActivityStatusDuration(status, entity.warningIndex, entity.automationSettings);

      const chatUi: ChatUi = {
        chatId,
        customerActivityStatus: status,
        pinCount: newPinCount,
        lastPinClickTimestamp: pinTimestamp,
        lastUnPinClickTimestamp: unPinTimestamp,
        warningIndex: 0,
        abandonedChatAutomationTimerDetails: {
          activityTimestamp: currentTime,
          duration
        },
        showInactiveWarningPinTimer: false
      };

      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.restartAbandonedChatAutomation,
    (state, { currentTime }): State => {
      // if the customerActivityTimerExpiry is at 0, it is stopped and does not need to be updated
      const updates: Update<ChatUi>[] = Object.entries(state.entities)
        .filter(([_, chat]) =>  Boolean(chat?.abandonedChatAutomationTimerDetails?.duration))
        .map(([key, chat]) => {
          return {
            id: key,
            changes: {
              abandonedChatAutomationTimerDetails: {
                activityTimestamp: currentTime,
                duration: AbandonedChatAutomationHelper.getCustomerActivityStatusDuration(chat.customerActivityStatus, chat.warningIndex, chat.automationSettings)
              },
              warningIndex: 0,
              showInactiveWarningPinTimer: false
            }
        };
      });
      return adapter.updateMany(updates, state);
    }
  ),
  on(ChatUiActions.showInactiveWarningPinTimer,
    (state, {chatId}): State => {
      const chatUi: ChatUi = {
        chatId,
        showInactiveWarningPinTimer: true
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatActions.ChatAccepted,
    (state, {chat, chatAutomationSettings}): State => {
      const {chatId, agentGroupId, isAsync} = chat;
      const globalAutomationSettings = chatAutomationSettings.find(settings => settings.agentGroupId === 'default' && settings.isAsync === isAsync);
      const agentGroupAutomationSettings = chatAutomationSettings.find(settings => settings.agentGroupId === agentGroupId && settings.isAsync === isAsync);

      // should always exist, is required in GetApplicationConfigurations
      let automationSettings = globalAutomationSettings?.automationSettings;
      if (agentGroupAutomationSettings){
        // if there are overrides for the agent group, apply them
        automationSettings = ObjectHelper.merge(globalAutomationSettings.automationSettings, agentGroupAutomationSettings.automationSettings);
      }

      const chatUi: ChatUi = {
        chatId,
        customerActivityStatus: CustomerActivityStatus.Active,
        abandonedChatAutomationTimerDetails: {
          activityTimestamp: 0,
          duration: 0
        },
        lastUnPinClickTimestamp: 0,
        lastPinClickTimestamp: 0,
        pinCount: 0,
        automationSettings
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatActions.AddGreetingSuggestion,
    (state, {chatId}): State => {
      const chatUi: ChatUi = {
        chatId,
        isGreetingSuggestionPreviouslyOffered: true
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.messageSelected,
    (state, {chatId, chatMessage, canSearchSmartResponses}): State => {
      const entity = state.entities[chatId];
      let displayedSmartResponses = entity?.displayedSmartResponses ?? [];
      let displayedFilteredSmartResponses = entity?.displayedFilteredSmartResponses ?? [];
      if (entity?.selectedMessage?.chatMessage?.messageId !== chatMessage?.messageId && canSearchSmartResponses)
      {
        displayedSmartResponses = [];
        displayedFilteredSmartResponses = [];
      }

      const chatUi: ChatUi = {
        chatId,
        selectedMessage: {
          chatMessage,
          canSearchSmartResponses,
          isHistoric: chatMessage?.isHistoric
        },
        displayedSmartResponses,
        displayedFilteredSmartResponses
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatActions.NewMessage,
    (state, { payload }): State => {
      const { chatId, messageId, sender, type } = payload;
      const entity = state.entities[chatId];
      const latestMessageId = entity?.latestMessageId;
      const selectedMessageId = entity?.selectedMessage?.chatMessage?.messageId;
      const autoSelect = !ItgHelper.isItgInProgress(entity) && (!selectedMessageId || latestMessageId === selectedMessageId);
      if (sender === SenderType.Requester && type === ChatMessageType.Message){
        const chatUi: ChatUi = {
          chatId,
          selectedMessage: autoSelect ? {
            chatMessage: payload,
            canSearchSmartResponses: true,
            isHistoric: false
          } : entity?.selectedMessage,
          inProgressAgentMessageTraceIds: [],
          displayedFilteredSmartResponses: [],
          displayedSmartResponses: [],
          latestMessageId: messageId,
          hasCustomerRespondedToAgentInitialMessage: entity?.hasAgentResponded,
          hasAgentRespondedToCustomer: false,
        };
        return adapter.upsertOne(chatUi, state);
      }
      return state;
    }
  ),
  on(ChatActions.SendMessage, ChatActions.SendSmartResponse, ChatActions.SendCxGptResponse,
    (state, { chatMessage }): State => updatesInProgressAgentMessageTraceIdsSendMessage(chatMessage, state)
  ),
  on(ChatActions.SendSuggestion,
    (state, { payload }): State => updatesInProgressAgentMessageTraceIdsSendMessage(payload, state)
  ),
  on(ChatActions.messageSent,
    (state, { chatId, traceId, messageId, chatMessage }): State => {
      const entity = state.entities[chatId];
      const currentInProgressAgentMessageTraceIds = entity?.inProgressAgentMessageTraceIds;
      if (currentInProgressAgentMessageTraceIds){
        const messageIndex = currentInProgressAgentMessageTraceIds.lastIndexOf(traceId);
        const inProgressAgentMessageTraceIds = [...currentInProgressAgentMessageTraceIds];
        let latestMessageId = entity.latestMessageId;
        let selectedMessageId = entity.selectedMessage?.chatMessage?.messageId;
        let canSearchSmartResponses = false;

        if (messageIndex >= 0){
          inProgressAgentMessageTraceIds.splice(0, messageIndex + 1);
          selectedMessageId = messageId;
          latestMessageId = messageId;
          canSearchSmartResponses = !!selectedMessageId;
        }

        const chatUi: ChatUi = {
          chatId,
          inProgressAgentMessageTraceIds,
          selectedMessage: !ItgHelper.isItgInProgress(entity) ? {
            chatMessage: {
              ...chatMessage,
              messageId: selectedMessageId
            },
            canSearchSmartResponses,
            isHistoric: false
          } : entity.selectedMessage,
          displayedFilteredSmartResponses: [],
          displayedSmartResponses: [],
          latestMessageId,
          hasAgentRespondedToCustomer: entity?.hasCustomerRespondedToAgentInitialMessage
        };
        return adapter.upsertOne(chatUi, state);
      }
      return state;
    }
  ),
  on(ChatActions.deleteChatMessage,
    (state, { chatMessage }): State => {
      const { chatId, traceId } = chatMessage;
      let inProgressAgentMessageTraceIds = state.entities[chatId]?.inProgressAgentMessageTraceIds;
      if (inProgressAgentMessageTraceIds){
        inProgressAgentMessageTraceIds = inProgressAgentMessageTraceIds.filter(tId => tId !== traceId);
        const chatUi: ChatUi = {
          chatId,
          inProgressAgentMessageTraceIds
        };
        return adapter.upsertOne(chatUi, state);
      }
      return state;
    }
  ),
  on(ChatActions.retrySendMessage,
    ChatActions.retrySendXaSuggestion,
    (state, { chatMessage, newTraceId }): State => {
      const { chatId, traceId } = chatMessage;
      const traceIds = state.entities[chatId]?.inProgressAgentMessageTraceIds;
      if (traceIds){
        const inProgressAgentMessageTraceIds = [...traceIds];
        const prevTraceIdIndex = inProgressAgentMessageTraceIds.lastIndexOf(traceId);
        if (prevTraceIdIndex >= 0){
          inProgressAgentMessageTraceIds[prevTraceIdIndex] = newTraceId;
        }
        const chatUi: ChatUi = {
          chatId,
          inProgressAgentMessageTraceIds
        };
        return adapter.upsertOne(chatUi, state);
      }
      return state;
    }
  ),
  on(SmartResponsesActions.updateSmartResponses,
    (state, { chatId, detailsPanelDefaultTab }): State => {
      const selectedTab = state.entities[chatId]?.detailsPanelTabSelected;

      const shouldAddNotification = selectedTab
        ? selectedTab !== DetailsSelectedTab.Recommended
        : detailsPanelDefaultTab !== DetailsSelectedTab.Recommended;

      if (shouldAddNotification) {
        const chatUi: ChatUi = {
          chatId,
          hasSmartResponseNotification: true
        };
        return adapter.upsertOne(chatUi, state);
      }

      return state;
    }
  ),

  on(ChatActions.AddRecommendation,
    (state, { addRecommendation } ): State => {
      return updateActivitiesNotification(state, addRecommendation.chatId, addRecommendation.isNewItgSuggested, addRecommendation.isNewItgAdded);
    }
  ),
  on(ChatActions.AcceptNewChat,
    (state, { chat, itgsInProgress }): State => {
      if (itgsInProgress) {
        const chatUi: ChatUi = {
          chatId: chat.chatId,
          itgsInProgress
        };
        return adapter.upsertOne(chatUi, state);
      }

      return state;
    }
  ),
  on(ChatActions.UpdateSecureDataCollectionState,
    (state, { chatId, requestState, timestamp }): State => {
      const isDataCollectionFinished = SecureDataCollectionHelper.isDataCollectionFinished(requestState);
      if (!isDataCollectionFinished) { return state; }
      return restartAcaAtActive(chatId, timestamp, state);
    }
  ),
  on(ProfanityActions.reportIllegalWords,
    (state, { chatId, errorMessage}): State => {
      const chatUi: ChatUi = {
        chatId,
        messageInputErrorMessage: errorMessage
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatActions.MaskTextFailed,
    (state, { maskText }): State => {
      const chatUi: ChatUi = {
        chatId: maskText.chatId,
        messageInputErrorMessage: MessageInputErrorMessages.maskingFailed
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.clearMessageInputErrorMessage,
    (state, { chatId }): State => {
      const chatUi: ChatUi = {
        chatId,
        messageInputErrorMessage: null
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatActions.MaskText,
    (state, { maskText }): State => {
      const { chatId } = maskText;
      const messageInputErrorMessage = state.entities[chatId]?.messageInputErrorMessage;
      if (messageInputErrorMessage && messageInputErrorMessage === MessageInputErrorMessages.maskingFailed)
      {
        const chatUi: ChatUi = {
          chatId,
          messageInputErrorMessage: null
        };
        return adapter.upsertOne(chatUi, state);
      }
      return state;
    }
  ),
  on(AskMeAnythingActions.rewriteAnswer, (state, {chatId}) => {
    const chatUi: ChatUi = {
      chatId,
      isAskMeAnythingRewriteLoading: true,
      askMeAnythingRewriteContent: null,
      showMessageInputUndoButton: false
    };
    return adapter.upsertOne(chatUi, state);
  }),
  on(AskMeAnythingActions.addRewrite,
    (state, { chatId, rewrite }): State => {
      const chatUi: ChatUi = {
        chatId,
        isAskMeAnythingRewriteLoading: false,
        messageInputInfoMessage: MessageInputInfoMessages.generativeContent,
        askMeAnythingRewriteContent: rewrite.rewriteAnswer
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatActions.UpdateEnhanceChatMessage,
    (state, { chatId, error }): State => {
      const chatUi: ChatUi = {
        chatId,
        messageInputInfoMessage: error ? '' : MessageInputInfoMessages.generativeContent,
        messageInputErrorMessage: error ? MessageInputErrorMessages.enhanceError : ''
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(AskMeAnythingActions.rewriteError,
    (state, { chatId }): State => {
      const chatUi: ChatUi = {
        chatId,
        messageInputErrorMessage: MessageInputErrorMessages.generateResponseError,
        isAskMeAnythingRewriteLoading: false
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.clearMessageInputInfoMessage,
    (state, {chatId}): State => {
      const chatUi: ChatUi = {
        chatId,
        messageInputInfoMessage: null,
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.messageInputUndoClicked,
    ChatUiActions.clearAskMeAnythingRewriteAndEnhanceContent,
    (state, {chatId}): State => {
      const chatUi: ChatUi = {
        chatId,
        messageInputInfoMessage: null,
        askMeAnythingRewriteContent: null,
        showMessageInputUndoButton: false,
        undoButtonPreviousContent: null
      };
      return adapter.upsertOne(chatUi, state);
    }
  ),
  on(ChatUiActions.updateUndoButtonPreviousContent,
    (state, {chatId, content, scriptsInUse, smartResponseInUse, suggestionInUse}): State => {
      const showMessageInputUndoButton = !smartResponseInUse && !suggestionInUse && (!scriptsInUse || scriptsInUse.length === 0);

      const chatUi: ChatUi = {
        chatId,
        undoButtonPreviousContent: content,
        showMessageInputUndoButton
      };
      return adapter.upsertOne(chatUi, state);
    })
);

function updateActivitiesNotification(state: State, chatId: string, isNewItgSuggested: boolean, isSuggestionAdded: boolean){
  if (!(isNewItgSuggested && isSuggestionAdded)) { return state; }
  
  const selectedTab = state.entities[chatId]?.detailsPanelTabSelected;
  const shouldAddNotification = selectedTab !== DetailsSelectedTab.Activities;

  if (shouldAddNotification) {
    const chatUi: ChatUi = {
      chatId: chatId,
      hasActivitiesNotification: true
    };
    return adapter.upsertOne(chatUi, state);
  }

  return state;
}

function selectChat(state: State, chatId: string, lastViewedTimestamp: number, hasAgentResponded?: boolean ): State {
  const update: ChatUi[] = [];
  const { selectedChatId } = state;
  let { selectedChatTimeStamp } = state;
  let unFocusedChat: UnFocusedChat = null;

  if (selectedChatId && selectedChatId !== chatId) {
    // if switching chats, update the previously selected chat's lastViewedTimestamp
    update.push({
      chatId: selectedChatId,
      lastViewedTimestamp,
    });

    if (selectedChatTimeStamp){
      unFocusedChat = {
        chatId: selectedChatId,
        focusedTimestamp: selectedChatTimeStamp
      };
      selectedChatTimeStamp = lastViewedTimestamp;
    }
  }
  if (!selectedChatId){
    selectedChatTimeStamp = lastViewedTimestamp;
  }

  update.push({
    chatId,
    lastViewedTimestamp,
    hasAgentResponded,
  });

  return {
    ...adapter.upsertMany(update, state),
    selectedChatId: chatId,
    unFocusedChat,
    selectedChatTimeStamp
  };
}

function updateItg(state: State, chatId: string, title: string, itgState: ItgState, action: ItgActionType, timestamp: number): State{
  const itgs = state.entities[chatId]?.itgsInProgress ?? [];

  if (itgs.length === 0){
    return state;
  }

  const itgsInProgress = itgs.map<ItgMetadata>(itg => {
    const newItg = {...itg};

    if (newItg.title === title){
      newItg.state = itgState;
      newItg.actions = [...itg.actions, { type: action, timestamp }];
    }
    return newItg;
  });

  const chatUi: ChatUi = {
    chatId,
    itgsInProgress
  };

  return adapter.upsertOne(chatUi, state);
}

function updatesInProgressAgentMessageTraceIdsSendMessage({chatId, traceId}: {chatId: string, traceId?: string}, state: State): State{
  const currentInProgressAgentMessageTraceIds = state.entities[chatId]?.inProgressAgentMessageTraceIds;
  const inProgressAgentMessageTraceIds = currentInProgressAgentMessageTraceIds
                                        ? [...currentInProgressAgentMessageTraceIds, traceId]
                                        : [traceId];
  const chatUi: ChatUi = {
    chatId,
    inProgressAgentMessageTraceIds
  };
  return adapter.upsertOne(chatUi, state);
}

function updateHasAgentResponded(state: State, chatId: string, quickSend: boolean){
  if (!quickSend){  return state; }

  const chatUi: ChatUi = {
    chatId,
    hasAgentResponded: true,
    hasMessageSent: true
  };
  return adapter.upsertOne(chatUi, state);
}

function restartAcaAtActive(chatId: string, timestamp: number, state: State): State{
  const entity = state.entities[chatId];
  // when the ITG is complete restart ACA at active unless pinned
  if (!entity || entity.customerActivityStatus === CustomerActivityStatus.Pinned) { return state; }

  const chatUi: ChatUi = {
    chatId,
    customerActivityStatus: CustomerActivityStatus.Active,
    abandonedChatAutomationTimerDetails: {
      activityTimestamp: timestamp,
      duration: AbandonedChatAutomationHelper.getCustomerActivityStatusDuration(CustomerActivityStatus.Active, 0, entity.automationSettings)
    },
    warningIndex: 0,
    showInactiveWarningPinTimer: false
  };
  return adapter.upsertOne(chatUi, state);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getSelectedChatId = (state: State) => state?.selectedChatId;
export const getUnFocusedChat = (state: State) => state?.unFocusedChat;
