// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SsoLoginComponent } from './components/sso-login/sso-login.component';
import { LoginComponent } from './components/login/login.component';
import { AdvancedLoginComponent } from './components/advancedLogin/advancedLogin.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { AppStoppedComponent } from './components/app-stopped/app-stopped.component';
import { MercuryRoutes } from './constants/constants';
import { AppStoppedGuard, ComcastNuanceAuthGuard, NuanceAuthGuardService } from 'projects/entities/src/public_api';
import { VoiceHomeComponent } from './components/voice-home/voice-home.component';
import { environment } from 'src/environments/environment';
const authGuard = environment.auth.useComcastSso ? ComcastNuanceAuthGuard : NuanceAuthGuardService;

const routes: Routes = [
  { path: MercuryRoutes.SsoLogin, component: SsoLoginComponent , pathMatch: 'full' },
  { path: MercuryRoutes.FormLogin, component: LoginComponent , pathMatch: 'full' },
  { path: MercuryRoutes.AdvancedLogin, component: AdvancedLoginComponent , pathMatch: 'full', canActivate: [authGuard] },
  { path: MercuryRoutes.AppStopped, component: AppStoppedComponent, pathMatch: 'full', canActivate: [AppStoppedGuard] },
  { path: MercuryRoutes.SessionTimeoutComponent, component: SessionTimeoutComponent , pathMatch: 'full' },
  { path: MercuryRoutes.Home, component: HomeComponent, pathMatch: 'full', canActivate: [AppStoppedGuard, authGuard], runGuardsAndResolvers: 'always' },
  { path: MercuryRoutes.Voice, component: VoiceHomeComponent, pathMatch: 'full', canActivate: [AppStoppedGuard, authGuard], runGuardsAndResolvers: 'always' },
  { path: MercuryRoutes.SsoResponse, redirectTo: MercuryRoutes.Home },  //component doesnt matter; just want to hit the auth guard again
  { path: MercuryRoutes.VoiceSsoResponse, redirectTo: MercuryRoutes.Voice  },
  { path: '**', redirectTo: MercuryRoutes.Home }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
