// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction } from '@ngrx/store';
import { createPayloadAction } from './action-adapters';
import { GetChatAccountUsers as GetChatAccountUsersModel } from './models/requests/getChatAccountUsers';
import { AccountUsersResponse as AccountUsersResponseModel } from './models/responses/accountUsersResponse';
import { UnlinkAccountResponse as UnlinkAccountResponseModel } from './models/responses/unlink-account-response';

export const ToggleModalOpen = createPayloadAction<boolean>(
  '[Connect Account] Toggle Modal Open'
);

export const GetChatAccountUsers = createPayloadAction<
  GetChatAccountUsersModel
>('[Connect Account] Get Chat Account Users');

export const UpdateAccountUsersResponse = createPayloadAction<
  AccountUsersResponseModel
>('[Connect Account] Update Account Users Response');

export const Unlink = createAction('[Connect Account] Unlink');

export const UpdateUnlinkAccountResponse = createPayloadAction<
  UnlinkAccountResponseModel
>('[Connect Account] Update Unlink Account Response');
