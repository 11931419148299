// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Observable, Subscriber } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { StyleClasses } from '../constants/constants';

export class StyleHelper {
  public static getThresholdCriticalityStyleObservable(
    subject: Observable<number>,
    warningThreshold: number,
    dangerThreshold: number,
    destroyed$: Observable<{}>): Observable<string> {
      return new Observable<string>((observer: Subscriber<string>) => {
        subject
          .pipe(
            map((value: number) => {
              if (value >= dangerThreshold) {
                return StyleClasses.Critical;
              }
              else if (value >= warningThreshold) {
                return StyleClasses.Warn;
              }
              else {
                return StyleClasses.Secondary;
              }
            }),
            distinctUntilChanged(),
            takeUntil(destroyed$))
          .subscribe((styleClass: string) => {
            observer.next(styleClass);
          });
      });
  }
}
