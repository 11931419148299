// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class CtiRequest{    
    sessionId: string;
    messageName: string;
    
    public constructor (sessionId: string) {       
        this.sessionId = sessionId;
   }
}