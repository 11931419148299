// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class NpsSurvey {
  public static readonly QueryStringKeys = {
    AgentGroups: 'agentgroupdisplay',
    BusinessUnits: 'businessunitname',
    AgentId: 'agentid',
    UserName: 'username',
    TemplateId: 'TemplateID',
    Tool: 'Tool',
    FeatureFlags: {
      AbandonedChatAutomation: 'ffACA',
      XaResponses: 'ffxaResponses',
      XaSolves: 'ffxaSolves'
    }
  };
  public static readonly QueryStringValues = {
    Yes: 'Yes',
    No: 'No'
  };
}
