// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { TimeZone } from 'projects/entities/src/public_api';

/*
 * Formats the time to h:mm A z
 * Usage:
 *   number | formatTimeSingleHourDigit:'timeZone'
*/


@Pipe({name: 'formatTimeSingleHourDigit'})
export class FormatTimeSingleHourDigitPipe implements PipeTransform {
  constructor(private timeHelper: TimeHelperService){
  }

  transform(unix: number, timeZone: TimeZone): string {
    return timeZone
      ? this.timeHelper.formatTimeSingleHourDigit(
          unix,
          timeZone.name
        )
      : this.timeHelper.formatTimeSingleHourDigit(unix);
  }
}
