// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { CeeSlidingPanelComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { logSelectedChatAction } from 'projects/entities/src/lib/domain/log-actions.actions';
import {  ItgActionType, SuggestionActivityAction, SuggestionType } from 'projects/entities/src/lib/domain/models/enums';
import { ItgMetadata } from 'projects/entities/src/lib/domain/models/itgMetadata';
import { ItgSuggestion } from 'projects/entities/src/lib/domain/models/itgSuggestion';
import {  LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { IntentVm, Suggestion } from 'projects/entities/src/lib/domain/models/suggestion';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import { AppState, ChatActions, ChatOperations, ChatUiActions, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SuggestionService } from 'src/app/services/suggestion.service';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-convo-activity-suggestion',
  templateUrl: './convo-activity-suggestion.component.html',
  styleUrls: ['./convo-activity-suggestion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoActivitySuggestionComponent extends SubscriberComponent implements OnInit {
  @Input() chatId: string;
  suggestedActivity$: Observable<ItgSuggestion>;
  activeItgs$: Observable<ItgMetadata>;
  @ViewChild('slidingPanel') slidingPanel: CeeSlidingPanelComponent;

  suggestionActivityAction = SuggestionActivityAction;

  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService,
    private suggestionService: SuggestionService
  ) {
    super();
  }

  ngOnInit() {
    this.suggestedActivity$ = this.store
      .select(fromChat.getSuggestedActivityByChatId(this.chatId))
      .pipe(distinctUntilChanged());

    // TODO: move SuggestionActivityAction to state instead of being a local variable
    // current actions would need a source added to it, and log dispatches would need their own new actions
    // this is a quick fix to have the subscription change to send message anytime the suggested activity is changed
    this.activeItgs$ = this.store
      .select(fromChat.getSelectedChatItgRunningOrPaused)
      .pipe(distinctUntilChanged());
  }

  startITG(itgSuggestion: ItgSuggestion, activeItg: ItgMetadata ) {
    const itgIntent = itgSuggestion?.itgIntent;
    if (activeItg) {
      this.store.dispatch(UiActions.ToggleItgModal({
        toggleItgModal: true,
        action: ItgActionType.Start,
        suggestedItg: itgSuggestion
      }));
    } else {
      this.onSendMessage({ quickSend: true }, itgIntent);
      this.store.dispatch(ChatUiActions.startItg({
        itgSuggestion: itgSuggestion,
        chatId: this.chatId,
        timestamp: this.timeService.unix(),
        source: 'suggestion'
      }));
    }

    this.slidingPanel.closePanel();
  }
  
  dismissActivity(_event: any, suggestedActivity: ItgSuggestion) {
    const logPayload = {
      operationName: ChatOperations.DismissItgSuggestion,
      logType: LogType.chat,
      data: {
        intent: suggestedActivity.intent,
        queryId: suggestedActivity.queryId
      }
    };

    this.store.dispatch(logSelectedChatAction({logPayload}));
    this.slidingPanel.closePanel();
  }

  onSendMessage(_event: any, itgIntent: IntentVm) {
    if (!itgIntent) { return; }
    const { suggestionMessage: message, suggestionTitle: title, intentCode: intent } = itgIntent;
    const suggestion: Suggestion = {
      title,
      intent,
      isLatest: true,
      json: null,
      timesSent: 0,
      messageId: null,
      queryId: null,
      body: {
        message,
        image: null,
        buttons: null
      },
      suggestionType: SuggestionType.ItgIntroduction
    };
    this.suggestionService.handleSendSuggestion(_event, suggestion, this.chatId);
  }

  handlePanelClosed(_event: any) {
    this.store.dispatch(ChatActions.ClearLatestItgSuggestions({chatId: this.chatId}));
  }

  public trackByQueryId(_index: number, suggestion: ItgSuggestion) {
    return suggestion.queryId;
  }
}
