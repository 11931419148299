// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { MercuryRoutes } from 'src/app/constants/constants';
import { AppStateService } from '../services/app-state-service';

@Injectable({
  providedIn: 'root'
})
export class AppStoppedGuard implements CanActivate {
  constructor(
    private appStateService: AppStateService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const isStopped = this.appStateService.isStopped();

    if (route?.routeConfig?.path === MercuryRoutes.AppStopped) {
      return isStopped || this.router.parseUrl(MercuryRoutes.Home);
    }

    return isStopped ? this.router.parseUrl(MercuryRoutes.AppStopped) : true;
  }
}
