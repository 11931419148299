// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { HubConnectionUpdate } from './models/hub-connection-data';
import { HubEchoData } from './models/hub-echo-data';

export const initializeHubs = createAction('[Hubs] Initialize Hubs');

export const hubsInitialized = createAction('[Hubs] Hubs Initialized');

export const connectChatRequestHub = createAction(
  '[Hubs] Connect Chat Request Hub'
);

export const connectVoiceHub = createAction(
  '[Hubs] Connect Voice Hub'
);

export const connectNiagaraLogHub = createAction(
  '[Hubs] Connect Niagara Log Hub'
);

export const disconnectChatRequestHub = createAction(
  '[Hubs] Disconnect Chat Request Hub'
);

export const disconnectVoiceHub = createAction(
  '[Hubs] Disconnect Voice Hub'
);

export const disconnectNiagaraLogHub = createAction(
  '[Hubs] Disconnect Niagara Log Hub'
);

export const echoCompleted = createAction(
  '[Hubs] Echo Completed',
  props<{echo: HubEchoData}>()
);

export const chatRequestHubStateUpdated = createAction(
  '[Hubs] Chat Request Hub State Updated',
  props<HubConnectionUpdate>()
);

export const voiceHubStateUpdated = createAction(
  '[Hubs] Voice Hub State Updated',
  props<HubConnectionUpdate>()
);

export const niagaraLogHubStateUpdated = createAction(
  '[Hubs] Niagara Log Hub State Updated',
  props<HubConnectionUpdate>()
);

export const showConnectionLostModal = createAction('[Hubs] Show Connection Lost Modal');
