<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="(smartResponseStateNotificationVm$ | async) as notification">
  <div class="smart-response-status-notification" x-test-target="notification">
    <div class="cee-icon-container">
      <svg class="cee-icon">
        <use [attr.xlink:href]="notification.icon"></use>
      </svg>
    </div>
    <span class="information-text information-text--primary">{{ notification.primaryText }}</span>
    <span class="information-text information-text--secondary">{{ notification.secondaryText }}</span>
  </div>
</ng-container>