// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { WebSocketFactory } from './websocket.factory';
import * as Rx from 'rxjs';

/**
 * Angular WebSocket client which is responsible to provide the interface between Angular Service and websocket server  over WSS/TLS.
 * With WebSockets, the client and server are able to share data back and forth simultaneously (Full Duplex) over the same connection.
 */
@Injectable({
	providedIn: 'root'
})
export class WebSocketClient extends WebSocketFactory {
	private webSocket: Rx.ReplaySubject<MessageEvent>;

	/**
	 * The connect method allows us to connect to any WebSocket url.
	 * if custom websocket protocol is needed.
	 * so that a single server can implement multiple WebSocket sub-protocols.
	 * you might want one server to be able to handle different types of interactions depending on the specified protocol.
	 * If you don't specify a protocol string, an empty string is assumed.
	 */
	public connect(
		wssUrl: string,
		protocols?: string | Array<string>
	): Rx.ReplaySubject<MessageEvent> {
		if (!this.webSocket) {
			this.webSocket = this.create(wssUrl, protocols);
		}
		return this.webSocket;
	}

	public disconnect() {
		this.webSocket = null;
	}
}
