// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlaceholderHelper } from '../../utils/placeholder-helper';
import { PlaceholderEditorState } from '../models/enums';
import { getSelectedChatId } from '../chat.selectors';
import { AppState } from '../state';
import { Placeholder } from './placeholder.model';
import * as fromPlaceholders from './placeholders.reducer';

export const selectPlaceholdersFeature = createFeatureSelector<AppState, fromPlaceholders.State>(fromPlaceholders.featureKey);

const {
  selectAll: selectAllPlaceholders
} = fromPlaceholders.adapter.getSelectors(selectPlaceholdersFeature);

export const getAllPlaceholders = createSelector(
  selectAllPlaceholders,
  (placeholders: Placeholder[]) => placeholders
);

export const getAllPlaceholdersForChatId = (chatId: string) => createSelector(
  getAllPlaceholders,
  (placeholders: Placeholder[]) => placeholders.filter(placeholder => placeholder.chatId === chatId)
);

export const getAllPlaceholdersForSelectedChatId = createSelector(
  getSelectedChatId,
  getAllPlaceholders,
  (chatId: string, placeholders: Placeholder[]) => placeholders.filter(placeholder => placeholder.chatId === chatId)
);

export const getEditPlaceholderPanelPlaceholderVms = createSelector(
  selectPlaceholdersFeature,
  (state: fromPlaceholders.State) => state.editPanelPlaceholders
);

export const getIsEditPlaceholderPanelOpen = createSelector(
  selectPlaceholdersFeature,
  (state: fromPlaceholders.State) => state.isEditPanelOpen
);

export const getEditPlaceholderPanelState = createSelector(
  selectPlaceholdersFeature,
  (state: fromPlaceholders.State) => state.editPanelState
);

export const getEditPlaceholderPanelTitle = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.getEditPlaceholderTitle(state)
);

export const getEditPlaceholderPanelDescription = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.getEditPlaceholderDescription(state)
);

export const getEditPlaceholderErrorMessage = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.getEditPlaceholderErrorMessage(state)
);

export const getEditPlaceholderProfanityErrorMessage = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.getEditPlaceholderProfanityErrorMessage(state)
);

export const getEditPlaceholderSubmitButtonText = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.getEditPlaceholderSubmitButtonText(state)
);

export const getEditPlaceholderShowSendIcon = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.getEditPlaceholderShowSendIcon(state)
);

export const getEditPlaceholderValidationRequired = createSelector(
  getEditPlaceholderPanelState,
  (state: PlaceholderEditorState) => PlaceholderHelper.shouldValidatePlaceholder(state)
);

export const getEditPlaceholderPanelChatId = createSelector(
  selectPlaceholdersFeature,
  (state: fromPlaceholders.State) => state.editPanelChatId
);
