// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createAction, props, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { MercuryRoutes } from 'src/app/constants/constants';
import { AppActions } from '../domain/actions';
import { AppState } from '../domain/state';
import { BroadcastChannelService } from './broadcast-channel-service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private readonly appStoppedStorageKey = 'isAppStopped';
  public readonly windowId = Guid.raw().replace(/-/g, '');

  private appStarted = createAction(
    '[App Broadcast] Started',
    props<{ windowId: string }>()
  );
  
  constructor(
    private appStore: Store<AppState>,
    private router: Router,
    private broadcastChannelService: BroadcastChannelService,
  ){}

  public isStopped(): boolean {
    return sessionStorage.getItem(this.appStoppedStorageKey) === true.toString();
  }

  private setIsStopped() {
    sessionStorage.setItem(this.appStoppedStorageKey, true.toString());
  }

  public preventMultipleInstances(): Observable<MessageEvent> {
    // notify other browser windows/tabs that the app has started
    const { windowId } = this;
    this.broadcastChannelService.publish(this.appStarted({ windowId }));

    // listen for AppStarted events from other browser windows/tabs
    return this.broadcastChannelService.messages().pipe(
      filter(msg => msg.data.type === this.appStarted.type),
      tap(({ data }) => {
        this.stopApp(data);
      }));
  }

  private stopApp(data: { windowId: any; }) {
    const { windowId: sourceWindowId } = data;

    this.setIsStopped();

    this.appStore.dispatch(AppActions.AppStopped({ sourceWindowId }));
    this.router.navigate([MercuryRoutes.AppStopped]);
  }
}
