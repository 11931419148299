// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { CallOperations, ChatOperations, EventLogNames } from '../constants/event-logs.constants';
import { ChatMessage, SendSuggestion } from '../domain/models';
import { Chat } from '../domain/models/chat';
import { SuggestionType, TranslatedMessageType } from '../domain/models/enums';
import { AgentEvent } from '../domain/models/events/agent-event';
import { ChatEvent } from '../domain/models/events/chat-event';
import { ErrorEvent } from '../domain/models/events/error-event';
import { DismissedGreetingSuggestionLog } from '../domain/models/logDimensions/greeting-suggestion-log';
import { CallEvent } from '../domain/models/voice/events/call-event';
import { Call } from '../domain/voice/call/call.model';
import { NavigatorHelper } from './navigatorHelper';
import { ChatHelper } from './chatHelper';

export class LogHelper {
  static logChatEvent(logService: LoggingFactoryService, operationName: string, chat: Chat, data: any = {}) {
    const logDimensions = {
      engagementId: chat?.chatId,
      operationName: operationName,
      conversationId: chat?.conversationId,
    };
    if (!chat?.isAsync) {
      delete logDimensions.conversationId;
    }
    Object.assign(logDimensions, { data: data });
    logService.logEvent(new ChatEvent(EventLogNames.ChatEvent, logDimensions));
  }

  static logCallEvent(logService: LoggingFactoryService, operationName: string, call: Call, data: any = {}) {
    const logDimensions = {
      callId: call?.callId,
      ucid: call?.ucid,
      operationName: operationName
    };
    Object.assign(logDimensions, { data: data });
    logService.logEvent(new CallEvent(EventLogNames.CallEvent, logDimensions));
  }

  static logAgentEvents(logService: LoggingFactoryService, operationName: string, data: any = {}, includeNetworkInformation: boolean = false) {
    const logDimensions = this._createLogDimensions(operationName, data, includeNetworkInformation);
    logService.logEvent(new AgentEvent(logDimensions));
  }

  static logDebug(logService: LoggingFactoryService, operationName: string, data: any = {}, includeNetworkInformation: boolean = false) {
    const logDimensions = this._createLogData(data, includeNetworkInformation);
    logService.logDebug(operationName, logDimensions);
  }

  static logErrorEvent(logService: LoggingFactoryService, operationName: string, data: any = {}) {
    const logDimensions = this._createLogDimensions(operationName, data, true);
    logService.logEvent(new ErrorEvent(logDimensions));
  }

  static logCtiMessageChatEvent(logService: LoggingFactoryService, chat: Chat, message: string, type: string) {
    const isResponse = (message.indexOf('Response') !== -1);
    const logDimensions = {
      message: isResponse
        ? message.replace(/Response$/i, '')
        : message,
      type
    };

    LogHelper.logChatEvent(logService, isResponse ? ChatOperations.InformationDeliveryResponse : ChatOperations.InformationDeliveryRequest, chat, logDimensions);
  }

  static logCtiMessageCallEvent(logService: LoggingFactoryService, call: Call, message: string, type: string) {
    const isResponse = (message.indexOf('Response') !== -1);
    const logDimensions = {
      message: isResponse
        ? message.replace(/Response$/i, '')
        : message,
      type
    };

    LogHelper.logCallEvent(logService, isResponse ? CallOperations.InformationDeliveryResponse : CallOperations.InformationDeliveryRequest, call, logDimensions);
  }

  static logDismissedSuggestion(logService: LoggingFactoryService, chat: Chat, sendSuggestion: SendSuggestion, isIgnored: boolean) {
    switch (sendSuggestion.suggestionType) {
      case SuggestionType.Greeting: {
        const dismissedGreetingLogDimensions: DismissedGreetingSuggestionLog = {
          isIgnored,
          messageText: sendSuggestion.body.message
        };

        this.logChatEvent(logService, ChatOperations.DismissChatStartGreeting, chat, dismissedGreetingLogDimensions);
        break;
      }
      default: {
        const logDimensions = {
          queryId: sendSuggestion.queryId,
          intent: sendSuggestion.intent,
          isIgnored,
          title: sendSuggestion.title,
          type: SuggestionType[sendSuggestion.suggestionType]
        };

        LogHelper.logChatEvent(logService, ChatOperations.DismissSuggestion, chat, logDimensions);
      }
    }
  }

  static logTranslationRequest(logService: LoggingFactoryService, chat: Chat, messageType: TranslatedMessageType, isRetry: boolean){
    if (!chat){
      return;
    }

    const agentLanguage = 'en';
    const customerLanguage = ChatHelper.getCustomerQueueLanguage(chat.customerLanguage, chat.defaultGroupTranslationLanguage);
    // Inbound or Batch should always be translated to the agent's language
    const isOutboundMessage = messageType === TranslatedMessageType.Outbound;

    const logDimensions = {
      engagementId: chat?.chatId,
      ...(chat?.isAsync && { conversationId: chat.conversationId }),
      fromLanguage: isOutboundMessage ? agentLanguage : customerLanguage,
      toLanguage: isOutboundMessage ? customerLanguage : agentLanguage,
      messageType,
      retry: isRetry
    };
    LogHelper.logChatEvent(logService, ChatOperations.TranslationRequest, chat, logDimensions);
  }

  static logTranslationError(logService: LoggingFactoryService, chatMessage: ChatMessage, chat: Chat, errorMsg?: string){
    if (!chat){
      return;
    }
    const errorMessage = errorMsg ? errorMsg : chatMessage?.translationData?.errorMessage;
    const logDimensions = {
      engagementId: chat.chatId,
      ...(chat?.isAsync && { conversationId: chat.conversationId }),
      errorMessage
    };
    LogHelper.logChatEvent(logService, ChatOperations.TranslationError, chat, logDimensions);
  }

  private static _createLogDimensions(operationName: string, data: any = {}, includeNetworkInformation: boolean) {
    return {
      operationName,
      data: this._createLogData(data, includeNetworkInformation)
    };
  }

  private static _createLogData(data: any = {}, includeNetworkInformation: boolean) {
    return {
      ...data,
      ...includeNetworkInformation && { networkInfo: NavigatorHelper.getNetworkInformation() }
    };
  }
}
