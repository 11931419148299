// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { AvailabilityOption } from 'src/app/models/availability-options';
import { AgentActions, AgentAuthActions, ChatActions, ProfileActions, SettingsActions, UiActions } from './actions';
import { SendSuggestion } from './models';
import { UnusedSlotTimes } from './models/unusedSlotTimes';
import { AgentGroup } from './models/agentGroup';
import { AgentStat } from './models/AgentStatModel';
import { BusinessUnit } from './models/business-unit';
import { PreviousChatSummary } from './models/previousChatSummary';
import { QualityCheckFollowupResponse } from './models/qualityCheckFollowupResponse';
import { QualityCheckResponse } from './models/qualityCheckResponse';
import { GetAgentAvailabilitySummaryResponse } from './models/responses/get-agent-availability-summary-response';
import { GetAgentSalesConversionMetricsResponse } from './models/responses/get-agent-sales-conversion-metrics-response';
import { SessionTimeoutInformation } from './models/sessionTimeoutInformation';
import { SurveyResponse } from './models/SurveyResponse';
import { UserAttributes } from './models/userAttributes';

export const featureKey = 'agent';

export interface State {
  agentGroups: AgentGroup[];
  avgEngagementHandleTime: AgentStat;
  avgInitialAgentResponseTime: AgentStat;
  completedChatCount: number;
  enableCustomScripts: boolean;
  selectedAgentGroups: AgentGroup[];
  businessUnits: BusinessUnit[];
  agentStatsRetrievalError: boolean;
  surveyPrompt: SurveyResponse;
  isLockedOut: boolean;
  availabilityOptions: AvailabilityOption[];
  bounceChatId: string;
  sessionTimeoutInformation: SessionTimeoutInformation;
  maxChats: number;
  maxExtraChats: number;
  agentAvailabilitySummary: GetAgentAvailabilitySummaryResponse;
  offerFirstQualityCheck?: boolean;
  lastQualityCheckDateTime?: string;
  lastQualityCheckResponse?: QualityCheckResponse;
  lastQualityCheckFollowupResponse?: QualityCheckFollowupResponse;
  lastClosedChatId: string;
  agentSalesConversionMetrics: GetAgentSalesConversionMetricsResponse;
  lastDismissedSuggestion: SendSuggestion;
  agentNetworkId: string;
  pernrId: string;
  previousChatSummaries: PreviousChatSummary[];
  pendingPreviousChatSummaries: PreviousChatSummary[];
  selectedPreviousChatSummaryId: string;
  previousChatSummaryFilterText: string;
  unusedSlotTimes: UnusedSlotTimes;
  userAttributes: UserAttributes;
  isAgentUnauthorized: boolean;
}

export const initialState: State = {
  completedChatCount: 0,
  enableCustomScripts: true,
  agentGroups: [],
  agentStatsRetrievalError: false,
  avgEngagementHandleTime: {
    statValue: 0,
    statName: 'avgEngagementHandleTime',
    statDisplayName: 'Avg. Handle Time'
  },
  avgInitialAgentResponseTime: {
    statValue: 0,
    statName: 'avgInitialAgentResponseTime',
    statDisplayName: 'Avg. Response Time'
  },
  selectedAgentGroups: [],
  businessUnits: [],
  surveyPrompt: { dismissed: null, ignored: null },
  isLockedOut: false,
  availabilityOptions: [],
  bounceChatId: null,
  sessionTimeoutInformation: null,
  maxChats: 0,
  maxExtraChats: 0,
  agentAvailabilitySummary: null,
  offerFirstQualityCheck: null,
  lastQualityCheckResponse: null,
  lastQualityCheckFollowupResponse: null,
  lastClosedChatId: null,
  agentSalesConversionMetrics: null,
  lastDismissedSuggestion: null,
  agentNetworkId: null,
  pernrId: null,
  previousChatSummaries: [],
  pendingPreviousChatSummaries: [],
  selectedPreviousChatSummaryId: null,
  previousChatSummaryFilterText: '',
  unusedSlotTimes: null,
  userAttributes: null,
  isAgentUnauthorized: false,
};

export const reducer = createReducer<State>(
  initialState,

  on(
    AgentActions.AgentMetricsLoaded,
    (state, { payload }): State => {
      const { businessUnits } = payload;
      return {
        ...state,
        businessUnits
      };
    }
  ),
  on(
    AgentActions.UpdateCompletedChatCount,
    (state, action): State => ({
      ...state,
      completedChatCount: action.payload
    })
  ),
  on(
    AgentActions.GetAgentAnalyticsFailed,
    (state): State => ({
      ...state,
      agentStatsRetrievalError: true
    })
  ),
  on(
    AgentActions.UpdateAgentAnalytics,
    (state, { payload }): State => ({
      ...state,
      // we need to update the state of our error state to false
      avgEngagementHandleTime: {
        statValue: payload.avgEngagementHandleTime,
        statName: 'avgEngagementHandleTime',
        statDisplayName: 'Avg. Handle Time'
      },
      avgInitialAgentResponseTime: {
        statValue: payload.avgInitialAgentResponseTime,
        statName: 'avgInitialAgentResponseTime',
        statDisplayName: 'Avg. Response Time'
      },
      agentStatsRetrievalError: false
    })
  ),
  on(
    AgentActions.UpdateAgentGroups,
    (state, action): State => ({
      ...state,
      agentGroups: action.payload
    })
  ),
  on(
    AgentActions.UpdateSelectedAgentGroups,
    (state, action): State => ({
      ...state,
      selectedAgentGroups: action.payload
    })
  ),
  on(
    AgentActions.UpdateSurveyPrompt,
    (state, action): State => ({
      ...state,
      surveyPrompt: action.payload
    })
  ),
  on(
    AgentActions.UpdateLockout,
    (state, { isLockedOut }): State => ({
      ...state,
      isLockedOut,
      sessionTimeoutInformation: isLockedOut ? state.sessionTimeoutInformation : null
    })
  ),
  on(
    AgentActions.cancelSessionTimeout,
    AgentActions.sessionTimeoutExpired,
    AgentActions.sessionTimeoutReauthenticate,
    (state): State => ({
      ...state,
      isLockedOut: false,
      sessionTimeoutInformation: null
    })
  ),
  on(
    AgentActions.UpdateBounceChatId,
    (state, action): State => ({
      ...state,
      bounceChatId: action.chatId
    })
  ),
  on(AgentActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(
    AgentAuthActions.lockout,
    (state, { sessionTimeoutInformation }) => ({
      ...state,
      sessionTimeoutInformation,
      isAgentUnauthorized: false
  })),
  on(
    ChatActions.Transferred,
    (state): State => {
      const completedChatCount: number = state.completedChatCount + 1;
      return {
        ...state,
        completedChatCount
      };
    }
  ),
  on(
    AgentActions.UpdateAgentStateSettings,
    (state, { maxChats, maxExtraChats, enableCustomScripts, availabilityOptions, agentNetworkId, pernrId }): State => ({
      ...state,
      maxChats: maxChats,
      maxExtraChats: maxExtraChats,
      enableCustomScripts: enableCustomScripts,
      availabilityOptions: availabilityOptions,
      agentNetworkId,
      pernrId
    })
  ),
  on(
    AgentActions.UpdateAgentAvailabilitySummary,
    (state, { agentAvailabilityResponse }): State => ({
      ...state,
     agentAvailabilitySummary: agentAvailabilityResponse
    })
  ),
  on(
    AgentActions.UpdateAgentSalesConversionMetrics,
    (state, { agentSalesConversionMetrics }): State => ({
      ...state,
     agentSalesConversionMetrics
    })
  ),
  on(
    AgentActions.UpdateOfferQualityCheck,
    (state, { offerFirstQualityCheck, chatId }): State => ({
      ...state,
     offerFirstQualityCheck,
     lastClosedChatId: chatId
    })
  ),
  on(
    SettingsActions.sendQualityCheckResponse,
    (state, { qualityCheckResponse, qualityCheckDateTime }): State => ({
      ...state,
     lastQualityCheckResponse: qualityCheckResponse,
     lastQualityCheckDateTime: qualityCheckDateTime.toJSON()
    })
  ),
  on(
    UiActions.DismissFeedbackPrompt,
    (state, { qualityCheckDateTime }): State => ({
      ...state,
     lastQualityCheckDateTime: qualityCheckDateTime.toJSON()
    })
  ),
  on(
    SettingsActions.sendQualityCheckFollowupResponse,
    (state, { qualityCheckFollowupResponse, qualityCheckDateTime }): State => ({
      ...state,
      lastQualityCheckFollowupResponse: qualityCheckFollowupResponse,
      lastQualityCheckDateTime: qualityCheckDateTime.toJSON()
    })
  ),
  on(
    ProfileActions.Loaded,
    (state, data): State => ({
      ...state,
      lastQualityCheckDateTime: data.payload.lastQualityCheckDateTime
    })
  ),
  on(
    ChatActions.DismissLatestSuggestions,
    (state, data): State => ({
      ...state,
      lastDismissedSuggestion: data.sendSuggestion
    })
  ),
  on(
    AgentActions.UpdatePreviousChatSummaries,
    (state, chatSummary): State => { 
      const summaries =  state.previousChatSummaries; 
      const previousChatSummaries = [
        ...summaries,
        chatSummary.chatSummary
      ];  
      return {
        ...state,
        previousChatSummaries
      };
    }
  ),
  on(
    UiActions.BackgroundAutoSaveSummaryTriggered,
      (state, summaryData): State => { 
        const pendingSummaries =  state.pendingPreviousChatSummaries; 
        const pendingPreviousChatSummaries = [
          ...pendingSummaries,
          summaryData.pendingChatSummaryData
        ];  
        return {
          ...state,
          pendingPreviousChatSummaries 
        };
      }
  ),
  on(
    UiActions.BackgroundAutoSaveSummarySuccessful,
      (state, newSummaryData): State => { 
        const summary =  state.pendingPreviousChatSummaries.find(s => s.chatId === newSummaryData.chatId);
        if (!summary) {
          return state;
        }

        const newPreviousSummary: PreviousChatSummary = {
          ...summary,
          summary: newSummaryData.summary,
          createDate: newSummaryData.createDate,
        };

        const summaries = state.previousChatSummaries;
        const previousChatSummaries = [
          ...summaries,
          newPreviousSummary
        ];

        const newPendingSummaries = state.pendingPreviousChatSummaries.filter(s => s.chatId !== newSummaryData.chatId);
        return {
          ...state,
          previousChatSummaries,
          pendingPreviousChatSummaries: newPendingSummaries
        };
      }
  ),
  on(
    UiActions.BackgroundAutoSaveSummaryFailed,
      (state, payload): State => {
        const chatId = payload.chatId;
        const newPendingSummaries = state.pendingPreviousChatSummaries.filter(s => s.chatId !== chatId);
        return {
          ...state,
          pendingPreviousChatSummaries: newPendingSummaries
        };
      }
  ),
  on(
    UiActions.UpdatePreviousChatSummaryModal,
    (state, {chatId}): State => ({  
      ...state,
      selectedPreviousChatSummaryId: chatId
    })
  ),
  on(
    AgentActions.UpdatePreviousChatSummaryFilterText,
    (state, {filteredText}): State => ({  
      ...state,
      previousChatSummaryFilterText: filteredText.toLowerCase()
    })
  ),
  on(
    AgentActions.UpdateUnusedSlotTimes,
    (state, {unusedSlotTimes}): State => ({
      ...state,
      unusedSlotTimes
    })
  ),
  on(
    AgentActions.UpdateAgentUserAttributes,
    (state, {agentAttributes}): State => ({
      ...state,
      userAttributes: agentAttributes
    })
  ),
  on(
    AgentActions.updateIsAgentUnauthorized,
    (state, {isAgentUnauthorized}): State => ({
      ...state,
      isAgentUnauthorized
    })
  ),
);
