// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { TimeZone } from 'projects/entities/src/public_api';

/*
 * Formats a date using a 
 * from earliest to most recent
 * Usage:
 *   EngagementTranscript[] | sortAsyncEngagement
*/


@Pipe({name: 'formatTimeSingleHourDigitShortMonthParen'})
export class FormatTimeSingleHourDigitShortMonthParenPipe implements PipeTransform {
  constructor(private timeHelper: TimeHelperService){
  }

  transform(unix: number, timeZone: TimeZone): string {
    return timeZone
      ? this.timeHelper.formatTimeSingleHourDigitShortMonthParen(
          unix,
          timeZone.name
        )
      : this.timeHelper.formatTimeSingleHourDigitShortMonthParen(unix);
  }
}
