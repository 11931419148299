<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-rate-connection">
  <cee-star-rating
  (ratingUpdated)="updateRating($event)"
  [ratingOptions]="ratingOptions"
  [ratingLabel]="ratingLabel"
  ></cee-star-rating>
</div>