// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiFocusApplicationType } from './enums';

export class CtiFocusApplication {
  chatId: string;
  applicationFocusType: CtiFocusApplicationType;  
}
