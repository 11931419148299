// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../services/state-persister';
import * as fromAgentAvailability from './availability.reducer';
@Injectable({
  providedIn: 'root'
})
export class AvailabilityPersisterService extends StatePersister<fromAgentAvailability.State> {
  constructor() {
    super(sessionStorage, fromAgentAvailability.featureKey);
  }
}
