// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Chunk, fillInChunks } from 'highlight-words-core';

export class HighlightWordsUtils {
  /**
   * combine chunks to highlight
   * with chunks that represent the bits of text between the highlighted text.
   * @param textToHighlight
   * @param chunks1
   * @param chunks2
   * @returns all chunks including highlights, replacements, and text in between
   */
  public static createFilledInChunks(
    textToHighlight: string,
    chunks1: Chunk[],
    chunks2: Chunk[]
  ): Chunk[] {
    return fillInChunks({
      chunksToHighlight: [...chunks1, ...chunks2].sort(
        (first, second) => first.start - second.start
      ),
      totalLength: textToHighlight ? textToHighlight.length : 0
    });
  }

  /**
   * remove any overlapping chunks (first chunk wins)
   * modified from combineChunks: https://github.com/bvaughn/highlight-words-core/blob/eb170f8a78c7926b613e72733267f3243696113c/src/utils.js#L46
   * @param chunks
   * @returns
   */
  public static excludeOverlappingChunks(chunks: Chunk[]): Chunk[] {
    return chunks
      .sort((first, second) => first.start - second.start)
      .reduce((processedChunks, nextChunk) => {
        // First chunk just goes straight in the array...
        if (processedChunks.length === 0) {
          return [nextChunk];
        } else {
          // ... subsequent chunks get checked to see if they overlap...
          const prevChunk = processedChunks.pop();
          if (nextChunk.start < prevChunk.end) {
            // discard nextChunk since it overlaps with prevChunk
            processedChunks.push(prevChunk);
          } else {
            processedChunks.push(prevChunk, nextChunk);
          }
          return processedChunks;
        }
      }, []);
  }

  public static chunkStartsBefore(chunk1: Chunk, chunk2: Chunk): Boolean {
    if (!chunk1) {
      return false;
    }
    if (!chunk2) {
      return true;
    }
    return chunk1.start <= chunk2.start;
  }

  public static chunkEndsIn(chunk1: Chunk, chunk2: Chunk): Boolean {
    if (!chunk1 || !chunk2) {
      return false;
    }
    return chunk1.end >= chunk2.start && chunk1.end <= chunk2.end;
  }

  public static chunkSurrounds(chunk1: Chunk, chunk2: Chunk): Boolean {
    if (!chunk1 || !chunk2) {
      return false;
    }
    return chunk1.start <= chunk2.start && chunk1.end >= chunk2.end;
  }

  public static maskHighlights(textToMask: string, chunks: Chunk[], maskChar = 'x') {
    if (this.isInvalid(chunks, textToMask)) {
      return textToMask;
    }

    return chunks.reduce((acc, chunk) => {
      const { end, highlight, start } = chunk;
      const text = highlight
        ? createMask(start, end)
        : textToMask.substring(start, end);

      return `${acc}${text}`;
    }, '');

    function createMask(start: number, end: number) {
      const length = end - start;
      return maskChar.repeat(length);
    }
  }

  public static wrapHighlights(
    textToHighlight: string,
    chunks: Chunk[],
    sanitizeChunkedText = HighlightWordsUtils.defaultSanitizeText,
    options: IWrapHighlightsOptions = { tag: 'span', className: 'cee-masked' }
  ): string {

    if (this.isInvalid(chunks, textToHighlight)) {
      return textToHighlight;
    }

    return chunks.reduce((acc, chunk) => {
      const { end, highlight, start } = chunk;
      let text = textToHighlight.substring(start, end);
      
      text = sanitizeChunkedText(text);

      if (highlight) {
        text = `<${options.tag} class='${options.className}'>${text}</${options.tag}>`;
      }

      return `${acc}${text}`;
    }, '');
  }

  public static defaultSanitizeText(text: string): string {
    return text;
  }

  private static isInvalid(chunks: Chunk[], textToMask: string) {
    return !chunks?.length || !textToMask;
  }
}

export interface IWrapHighlightsOptions {
  tag: string; 
  className: string;
}
