// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import {
  ResponseHandler,
  LoggingFactoryService} from '@cxt-cee-chat/merc-ng-core';
import { GetAgentMetrics } from '../domain/models/getAgentMetrics';
import { ReportProfanity } from '../domain/models/reportProfanity';
import { NUANCE_BEARER_TOKEN_HANDLER } from './nuance-bearer-token-http-handler';
import { HttpHandler, HttpResponse } from '@angular/common/http';
import { UnauthorizedVerifySession } from '../domain/models/unauthorizedVerifySession';
import { NuanceCredentialRestApiService } from './nuance-credential/nuance-credential-rest-api-service';
import { NuanceCredentialRequestService } from './nuance-credential/nuance-credential-request-service';
import { UpdateCustomSettings } from '../domain/models/requests/updateCustomSettings';
import { UpdateCustomScripts } from '../domain/models/requests/updateCustomScripts';
import { ProfanityList } from '../domain/models/profanityList';
import { SetAgentAvailabilityRequest } from '../domain/models/requests/set-agent-availability-request';
import { GetAgentGroupsResponse } from '../domain/models/responses/get-agent-groups-response';
import { ScriptsResponse } from '../domain/models/responses/scripts-response';
import { GetSystemScripts } from '../domain/models/requests/getSystemScripts';
import { GetAgentAnalyticsResponse } from '../domain/models/getAgentAnalyticsResponse';
import { Store } from '@ngrx/store';
import { AppState } from '../domain/state';
import { AgentAuthActions } from '../domain/actions';
import { GetAgentAvailabilitySummary } from '../domain/models/requests/getAgentAvailabilitySummary';
import { RequestExtraChats } from '../domain/models/requestExtraChats';
import { GetAgentSalesConversionMetrics } from '../domain/models/getAgentSalesConversionMetrics';
import { GetAgentUserAttributes } from '../domain/models/getAgentUserAttributes';
import { GetAgentUserAttributesResponse } from '../domain/models/getAgentUserAttributesResponse';

@Injectable({
  providedIn: 'root'
})
export class FixAgentApiService extends NuanceCredentialRestApiService {
  private _unauthorizedVerifySessionHandler: ResponseHandler;

  constructor(protected nuanceCredentialRequestService: NuanceCredentialRequestService,
    @Inject(NUANCE_BEARER_TOKEN_HANDLER) httpHandler: HttpHandler,
    loggingService: LoggingFactoryService,
    protected ngEntityStore: Store<AppState>) {
    super(nuanceCredentialRequestService, loggingService, httpHandler);
    this._unauthorizedVerifySessionHandler = new ResponseHandler();
    this._unauthorizedVerifySessionHandler.handle401 = (opName, data) => {
      const operationData: UnauthorizedVerifySession = {
        operationName: opName,
        data: data
      };
      this.ngEntityStore.dispatch(AgentAuthActions.unauthorizedVerifySession(operationData)); 
    };
  }

  public setAvailability(model: SetAgentAvailabilityRequest): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/setavailability', model, this._unauthorizedVerifySessionHandler);
  }
  public getScripts(model: GetSystemScripts): Promise<HttpResponse<ScriptsResponse>> {
    return this.postWithCredentials('fixagent/getscripts', model, this._unauthorizedVerifySessionHandler);
  }
  public getCustomScripts(): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/getcustomscripts', {}, this._unauthorizedVerifySessionHandler);
  }
  public updateCustomScripts(model: UpdateCustomScripts): Promise<HttpResponse<ScriptsResponse>> {
    return this.postWithCredentials('fixagent/updatecustomscripts', model, this._unauthorizedVerifySessionHandler);
  }
  public getAgentSettings(model: any): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/getsettings', model, this._unauthorizedVerifySessionHandler);
  }
  public getAgentCustomSettings(): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/getcustomsettings', {}, this._unauthorizedVerifySessionHandler);
  }
  public updateCustomSettings(model: UpdateCustomSettings): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/updatecustomsettings', model, this._unauthorizedVerifySessionHandler);
  }
  public getAgentAnalytics(model: GetAgentMetrics): Promise<HttpResponse<GetAgentAnalyticsResponse>> {
    return this.post('fixagent/getagentanalytics', model);
  }
  public getAgentMetrics(model: GetAgentMetrics): Promise<HttpResponse<any>> {
    return this.post('fixagent/getagentmetrics', model);
  } 
  public getProfanityList(): Promise<HttpResponse<ProfanityList>>{
    return this.get('fixagent/getprofanityblacklist');
  }
  public reportProfanity(model: ReportProfanity): Promise<HttpResponse<any>>{
    return this.postWithCredentials('fixagent/reportprofanity', model);
  }
  public getAgentGroups(): Promise<HttpResponse<GetAgentGroupsResponse>>{
    return this.postWithCredentials('fixagent/getagentgroups', {});
  }
  public requestExtraChats(model: RequestExtraChats): Promise<HttpResponse<any>>{
    return this.postWithCredentials('fixagent/extraChat', model);
  }
  public getAgentAvailabilitySummary(model: GetAgentAvailabilitySummary): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/getagentavailabilitysummary', model);
  }
  public getAgentSalesConversionMetrics(model: GetAgentSalesConversionMetrics): Promise<HttpResponse<any>> {
    return this.postWithCredentials('fixagent/getagentsalesconversionmetrics', model);
  }
  public getAgentUserAttributes(model: GetAgentUserAttributes): Promise<HttpResponse<GetAgentUserAttributesResponse>> {
    return this.postWithCredentials('fixagent/getAgentUserAttributes', model);
  }
}
