// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { BounceTimerType } from './enums';

export class BounceTimerDetail {
    chatId: string;
    type: BounceTimerType;
    constructor(data: any) {
        this.chatId = data.chatId;
        this.type = data.type;
    }
}





