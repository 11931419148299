// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  ResponseHandler,
  HubService,
  HubResponse,
  LoggingFactoryService
} from '@cxt-cee-chat/merc-ng-core';
import { NuanceCredentialRequestService } from './nuance-credential-request-service';

@Injectable({
  providedIn: 'root'
})
export abstract class NuanceCredentialHubService extends HubService {
  constructor(
    protected nuanceCredentialRequestService: NuanceCredentialRequestService,
    loggingService: LoggingFactoryService
  ) {
    super(loggingService);
  }

  protected async callServerWithCredentials<T = any>(
    operationName: string,
    model?: any,
    responseHandler?: ResponseHandler
  ): Promise<HubResponse<T>> {
    const request = await this.nuanceCredentialRequestService.createRequest(model);
    return this.callServer<T>(operationName, request, responseHandler);
  }
}
