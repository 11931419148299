// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, ViewChild, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { ButtonControl, ButtonType, CeeTabPanelComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { LoggingFactoryService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { ReturnState } from '../../../models/return-state';
import { AgentAuthActions, AgentCustomSettings, AgentOperations, AppState, ExitAction, FixAgentProfileService, LogHelper, ProfileActions, SettingsActions, AgentSettingsBanner, UiActions, UserIdentityService, FeatureFlags } from 'projects/entities/src/public_api';
import { AgentActionTabs } from 'projects/entities/src/lib/utils/enums';
import { fromSettings, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { LoggedOutMethod } from 'projects/entities/src/lib/domain/models/enums';
import { Observable } from 'rxjs';
import { ObjectHelper } from 'projects/entities/src/lib/utils/object-helper';
import { NpsSurveyData } from 'projects/entities/src/lib/domain/models/nps-survey-data';
import { NpsSurveyService } from 'projects/entities/src/lib/services/nps-survey.service';

const CANCEL_TEXT = 'Cancel';
const SAVE_TEXT = 'Save';
const TABS_WITHOUT_CONTROLS = ['Feedback', 'What\'s new'];

@Component({
  selector: 'merc-agent-settings-base',
  templateUrl: './agent-settings-base.component.html',
  styleUrls: ['./agent-settings-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentSettingsBaseComponent extends SubscriberComponent implements OnInit {
  @ViewChild('tabPanel') tabPanel: CeeTabPanelComponent;

  @Input() surveyUrl: string;
  @Input() hasActiveChats: boolean;
  agentCustomSettings: AgentCustomSettings;
  confirmLogoutModalActive: boolean = false;
  closeChatsModalActive: boolean = false;
  _initAgentCustomSettings: AgentCustomSettings;
  secondaryHeader: string = '<div class="settings-opening">Hello</div>';
  logoutTab: string = AgentActionTabs.Logout;
  surveyTab: string = AgentActionTabs.TakeSurvey;

  returnState: ReturnState;
  recentTabClicked = '';
  controls: ButtonControl[];

  active$: Observable<boolean>;
  isExitCustomSettingsModalOpen$: Observable<boolean>;
  activeTabTitle$: Observable<string>;
  qualityCheckFeatureFlag$: Observable<boolean>;
  featureAnnouncementFeatureFlag$: Observable<boolean>;
  npsSurveyData$: Observable<NpsSurveyData>;

  constructor(
    private fixAgentProfileService: FixAgentProfileService,
    private ngEntityStore: Store<AppState>,
    private identityService: UserIdentityService,
    private loggingFactory: LoggingFactoryService,
    private timeService: DayTimeService,
    private npsSurveyService: NpsSurveyService
    ) {
      super();
    }

  ngOnInit() {
    this.ngEntityStore.select(fromSettings.getCustomSettings).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(() => {
      this.resetSettings();
    });

    this.active$ = this.ngEntityStore.select(fromUi.getIsAgentSettingsPanelOpen).pipe(distinctUntilChanged(), takeUntil(this.destroyed$));
    this.isExitCustomSettingsModalOpen$ = this.ngEntityStore.select(fromUi.getIsExitCustomSettingsModalOpen);
    this.activeTabTitle$ = this.ngEntityStore.select(fromUi.getAgentSettingsPanelActiveTabTitle);
    this.qualityCheckFeatureFlag$ = this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.QualityCheck));
    this.identityService.fullName.pipe(filter((value) => value !== ''), take(1))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(name => {
        this.secondaryHeader = `<div class="settings-opening">Hello,</div><div data-qa="agent-name">${name}</div>`;
      });
    this.featureAnnouncementFeatureFlag$ = this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.FeatureBanner));
    this.npsSurveyData$ = this.ngEntityStore.select(fromSettings.getNpsSurveyData);
  }

  updateReturnState() {
    switch (this.returnState) {
      case ReturnState.Close:
        this.ngEntityStore.dispatch(UiActions.ToggleAgentSettingsPanel({ isOpen: false }));
        break;
      case ReturnState.Tab:
        this.ngEntityStore.dispatch(UiActions.UpdateAgentSettingsActiveTab({ activeTabTitle: this.recentTabClicked }));
        break;
    }
  }

  onCloseExitModal(exitAction: ExitAction){
    switch (exitAction){
      case ExitAction.Saved:
        this.onSaveSettings();
        this.updateReturnState();
        break;
      case ExitAction.Canceled:
        this.onCancelChanges();
        this.updateReturnState();
        break;
      // no changes necessary on ExitAction.Closed
    }
  }

  resetSettings() {
    // original to keep for comparing
    this._initAgentCustomSettings = {...this.fixAgentProfileService.getAgentCustomSettings()};
    // copy to be modified
    this.agentCustomSettings = {...this._initAgentCustomSettings};
  }

  onModalClose() {
    if (this.noPendingChanges()){
      this.resetSettings();
      this.ngEntityStore.dispatch(UiActions.ToggleAgentSettingsPanel({ isOpen: false }));
      this.closeSettingsBanner();
    }
    else{
      this.returnState = ReturnState.Close;
      this.ngEntityStore.dispatch(UiActions.exitCustomSettings());
    }
  }

  onTabClicked(tabTitle: string){
    const activeTab = this.tabPanel.tabs.toArray().find(tab => tab.active === true);
    this.recentTabClicked = this.isNoContentTab(tabTitle) ? '' : tabTitle;

    if (activeTab && activeTab.title === tabTitle) { return; } // active tab has not changed

    if (!this.noPendingChanges()){ // has pending changes, show save modal
      this.returnState = ReturnState.Tab;
      this.ngEntityStore.dispatch(UiActions.exitCustomSettings());
    }
    else if (tabTitle === AgentActionTabs.Logout) {
      this.logoutClicked();
    }
    else if (tabTitle === AgentActionTabs.TakeSurvey) {
      const logDimensions = {
        surveyUrl: this.surveyUrl,
        method: 'settings'
      };
      LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.TakeSurvey, logDimensions);

      const surveyTimestamp = this.timeService.unix();
      this.ngEntityStore.dispatch(SettingsActions.updateLastSurveyCompleteDate({ surveyTimestamp }));
    }
    else { // no pending changes, tab with content, set it as the active tab
      this.closeSettingsBanner();
      this._setControls(tabTitle);
      
      this.ngEntityStore.dispatch(UiActions.UpdateAgentSettingsActiveTab({activeTabTitle: tabTitle}));
    }
  }

  logoutClicked(){
    this.confirmLogoutModalActive = true;
  }

  onSaveControlClicked() {
    this.onSaveSettings();
    const bannerToggle = new AgentSettingsBanner();
    bannerToggle.active = true;
    bannerToggle.exitAction = ExitAction.Saved;
    this.ngEntityStore.dispatch(UiActions.ToggleAgentSettingsBanner(bannerToggle));
  }

  onCancelControlClicked() {
    this.onCancelChanges();
    this.ngEntityStore.dispatch(UiActions.UpdateAgentSettingsActiveTab({activeTabTitle: ''}));
  }

  onSaveSettings() {
    this._initAgentCustomSettings = {...this.agentCustomSettings};
    this.ngEntityStore.dispatch(ProfileActions.Update(this.agentCustomSettings));
    this._setControls();
  }

  onCancelChanges() {
    this.agentCustomSettings = {...this._initAgentCustomSettings};
    this.closeSettingsBanner();
    this._setControls();
  }

  onSettingsModelChanged(settingsModel: AgentCustomSettings){
    this.agentCustomSettings = {...settingsModel};
    this.closeSettingsBanner();
    this._setControls();
  }

  closeSettingsBanner(){
    const bannerToggle = new AgentSettingsBanner();
    bannerToggle.active = false;
    bannerToggle.exitAction = ExitAction.Canceled;
    this.ngEntityStore.dispatch(UiActions.ToggleAgentSettingsBanner(bannerToggle));
  }

  onConfirmLogout() {
    this.confirmLogoutModalActive = false;
    if (this.hasActiveChats){
      //show close out chats modal
      this.closeChatsModalActive = true;
    }
    else {
      //proceed logout process
      this.ngEntityStore.dispatch(AgentAuthActions.logOut(LoggedOutMethod.ManualSettings));
    }
  }

  onCancelLogout() {
    this.confirmLogoutModalActive = false;
  }

  onCloseChatsModalClose() {
    this.closeChatsModalActive = false;
  }

  isNoContentTab(tabTitle: string): boolean{
    return tabTitle === AgentActionTabs.Logout || tabTitle === AgentActionTabs.TakeSurvey;
  }

  noPendingChanges(): boolean {
    if (this.agentCustomSettings && this._initAgentCustomSettings){
      return ObjectHelper.shallowEqual(this.agentCustomSettings, this._initAgentCustomSettings);
    }
    else {
      return !this._initAgentCustomSettings && !this.agentCustomSettings;
    }
  }

  shouldShowSurvey(npsSurveyData: NpsSurveyData): boolean {
    const {surveyConfiguration, lastSurveyCompleteDate, featureFlags, agentGroups, businessUnits} = npsSurveyData;
    const shouldShowSurvey = this.npsSurveyService.showNpsSurvey(surveyConfiguration, lastSurveyCompleteDate, featureFlags, agentGroups, businessUnits, false);
    return shouldShowSurvey;
  }

  private _setControls(tabTitle?: string) {
    const isDisabled = this.noPendingChanges();
    if ( tabTitle && TABS_WITHOUT_CONTROLS.includes(tabTitle)) {
      this.controls = [];
    } else {
      this.controls = [{
        text: CANCEL_TEXT,
        type: ButtonType.Link,
        dataQa: 'cancel',
        disabled: false,
        controlFunction: () => this.onCancelControlClicked()
      },
      {
        text: SAVE_TEXT,
        type: ButtonType.Primary,
        dataQa: 'save',
        disabled: isDisabled,
        controlFunction: () => this.onSaveControlClicked()
      }];
    }
    
  }
}
