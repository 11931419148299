// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { ButtonControl, ButtonType } from '@cxt-cee-chat/merc-pattern-lib';
import { AgentActions, AgentOperations, AgentStatePersisterService, AppState, AvailabilityChange, AvailabilityType, LogHelper, SettingsActions } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SurveyResponse } from 'projects/entities/src/lib/domain/models/SurveyResponse';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { fromAgentAvailability } from 'projects/entities/src/lib/domain/selectors';

@Component({
  selector: 'merc-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SurveyModalComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @Input() surveyUrl: string;
  @Input() active: boolean;
  isAsync: boolean;
  asyncContactReason: string;
  asyncResolution: string;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  controls: ButtonControl[] = [];

  constructor(
    private agentStatePersister: AgentStatePersisterService,
    private appStore: Store<AppState>,
    private loggingFactory: LoggingFactoryService,
    private timeService: DayTimeService
    ) {
    super();
  }

  ngOnInit() {
    this.controls = [{
      text: 'No Thanks',
      type: ButtonType.Link,
      disabled: false,
      dataQa: 'dismiss-survey',
      controlFunction: () => this.surveyDismissed('cancelButton')
    },
    {
      text: 'Complete Survey',
      type: ButtonType.Primary,
      icon: 'link-out',
      disabled: false,
      dataQa: 'complete-survey',
      controlFunction: () => this.launchSurvey()
    }];



    const isAvailable = this.agentStatePersister.availabilityStatus && this.agentStatePersister.availabilityStatus.available === AvailabilityType.Available;

    if (isAvailable) {
      this.surveyIgnored();
    } else {
      const logDimensions = {
        surveyUrl: this.surveyUrl,
        method: 'prompt'
      };

      LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.PromptSurvey, logDimensions);
    }

    // show survey on status change to unavailable if previously ignored
    this.appStore.select((fromAgentAvailability.getAvailability)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((availability: AvailabilityChange) => {
        if (availability && availability.available === AvailabilityType.Unavailable){
          const surveyArgs: SurveyResponse = {
            ignored: false
          };

          this.appStore.dispatch(AgentActions.UpdateSurveyPrompt(surveyArgs));
        }
      });
  }

  surveyDismissed(method) {
  // active action
    const surveyArgs: SurveyResponse = {
      dismissed: true
    };
    this.appStore.dispatch(AgentActions.UpdateSurveyPrompt(surveyArgs));

    const logDimensions = {
      surveyUrl: this.surveyUrl,
      method: method
    };

    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.DismissSurvey, logDimensions);

    this.closeModal();
  }

  surveyIgnored(): void {
  // passive action
    const surveyArgs: SurveyResponse = {
      ignored: true
    };

    this.appStore.dispatch(AgentActions.UpdateSurveyPrompt(surveyArgs));

    const logDimensions = {
      surveyUrl: this.surveyUrl,
      method: 'ignore'
    };

    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.DismissSurvey, logDimensions);

    this.closeModal();
  }

  closeModal(): void {
    this.modalClose.emit(true);
    this.active = false;
  }

  launchSurvey(): void {
    const surveyArgs: SurveyResponse = {
      dismissed: true
    };
    this.appStore.dispatch(AgentActions.UpdateSurveyPrompt(surveyArgs));

    window.open(this.surveyUrl, '_blank');

    const surveyTimestamp = this.timeService.unix();

    this.appStore.dispatch(SettingsActions.updateLastSurveyCompleteDate({ surveyTimestamp }));

    const logDimensions = {
      surveyUrl: this.surveyUrl,
      method: 'prompt'
    };

    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.TakeSurvey, logDimensions);

    this.closeModal();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.dispose();
  }
}
