// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export class ObservableHelper {
    public static async getNumber<TObs = any>(obs: Observable<TObs>): Promise<number> {
        return new Promise<number>((resolve) => {
            obs
                .pipe(take(1))
                .subscribe((val: TObs) => {
                    let num: number;

                    if (Array.isArray(val)) {
                        num = (<any[]>val).length;
                    }
                    else {
                        num = <unknown>val as number;
                    }

                    resolve(num);
                });
        });
    }

    public static async getValue<TObs>(obs: Observable<TObs>): Promise<TObs> {
        return new Promise<TObs>((resolve => {
            obs
                .pipe(take(1))
                .subscribe((val: TObs) => {
                    resolve(val);
                });
        }));
    }
}