<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-rating-follow-up">
  <div *ngIf="(additionalFeedbackEnabled$ | async) || (isModal$ | async)">
    <div class="merc-rating-follow-up-description">
      <p class="cee-h4">Can you tell us more?</p>
      <p>
        Which of the following are impacting your experience? (Select all that
        apply)
      </p>
    </div>
    <div class="merc-rating-follow-up-form">
      <div class="merc-config-followup-options">
        <div *ngFor="let option of (options$ | async)" class="cee-form-row--group">
          <input
            id="{{ option.value }}"
            type="checkbox"
            name="{{ option.value }}"
            value="{{ option.value }}"
            (change)="updateFeedback($event)"
            class="cee-checkbox"
          />
          <label class="cee-checkbox-label" for="{{ option.value }}">{{
            option.label
          }}</label>
        </div>
      </div>
      <div class="cee-form-row--group">
        <input
          id="other"
          type="checkbox"
          name="other"
          value="other"
          (change)="updateShowTextArea($event)"
          class="cee-checkbox"
        />
        <label class="cee-checkbox-label" for="other">Other</label>
      </div>
      <div *ngIf="showTextArea">
        <textarea
          class="cee-form-input"
          [ngClass]="{ 'has-error': showErrorMessage }"
          [(ngModel)]="otherDescription"
          (ngModelChange)="isValidDescription()"
          rows="4"
          cols="50"
          aria-describedby="followup-error-msg"
        ></textarea>
        <p
          *ngIf="showErrorMessage"
          class="cee-form-error-msg"
          [ngClass]="{ error: showErrorMessage }"
          id="followup-error-msg"
        >
          You will need to remove any inappropriate words before sending your
          message.
        </p>
      </div>
    </div>
  </div>
  <div class="merc-rating-follow-up-form-submit">
    <cee-button
      *ngIf="showCloseButton"
      type="link"
      size="sm"
      (buttonClick)="onCloseFeedback()"
      >Close</cee-button
    >
    <cee-button
      [disabled]="submitDisabled"
      type="primary"
      size="sm"
      (buttonClick)="sendFeedback()"
      >Share Feedback</cee-button
    >
  </div>
</div>
