// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HubConnectionState } from '@cxt-cee-chat/merc-ng-core';
import { createReducer, on } from '@ngrx/store';
import { AgentAuthActions, ChatActions, HubsActions } from './actions';
import { HomeHeaderErrorState } from './models/voice/enums';

export const featureKey = 'agentAuthentication';

export interface State {
  attemptingLogIn: boolean;
  loggedIn: boolean;
  loggingOut: boolean;
  continuedSession: boolean;
  verifyingSession: boolean;
  isFormAuthenticationSuccess?: boolean;
  sessionValidated: boolean;
  voiceSessionId: string;
  avayaAgentId: string;
  phoneExtension: string;
}

export const initialState: State = {
  attemptingLogIn: false,
  loggedIn: false,
  loggingOut: false,
  continuedSession: false,
  verifyingSession: false,
  isFormAuthenticationSuccess: null,
  sessionValidated: false,
  voiceSessionId: null,
  avayaAgentId: null,
  phoneExtension: null
};

export const reducer = createReducer<State>(
  initialState,
  on(
    AgentAuthActions.logIn,
    AgentAuthActions.reLogIn,
    (state): State => ({
      ...state,
      attemptingLogIn: true
    })
  ),
  on(
    AgentAuthActions.verifyingSession,
    (state, {verifyingSession}): State => ({
      ...state,
      verifyingSession,
      sessionValidated: verifyingSession ? false : state.sessionValidated
    })
  ),
  on(
    AgentAuthActions.loggedIn,
    (state): State => ({
      ...state,
      attemptingLogIn: false,
      loggedIn: true,
      sessionValidated: true
    })
  ),
  on(
    AgentAuthActions.reLoggedIn,
    (state): State => ({
      ...state,
      attemptingLogIn: false,
      loggedIn: true
    })
  ),
  on(
    AgentAuthActions.logOut,
    (state): State => ({
      ...state,
      loggingOut: true
    })
  ),
  on(
    AgentAuthActions.loggedOut,
    (state): State => ({
      ...state,
      loggedIn: false,
      loggingOut: false
    })
  ),
  on(
    AgentAuthActions.setContinuedSession,
    (state, {continuedSession}): State => ({
      ...state,
      continuedSession,
      sessionValidated: true
    })
  ),
  on(
    AgentAuthActions.formAuthentication,
    (state, action): State => ({
      ...state,
      isFormAuthenticationSuccess: action.success
    })
  ),
  on(
    ChatActions.JoinChats,
    (state, {chatIds}): State => {
      if (chatIds.length === 0){
        return {
          ...state,
          sessionValidated: true
        };
      }
      return state;
    }
  ),
  on(
    ChatActions.JoinChatsResponse,
    (state): State => ({
      ...state,
      sessionValidated: true
    })
  ),
  on(
    HubsActions.chatRequestHubStateUpdated,
    (state, action): State => {
      if (action.connectionState === HubConnectionState.Reconnected){
        return {
          ...state,
          sessionValidated: false
        };
      }
      return state;
    }
  ),
  on(
    AgentAuthActions.createVoiceSession,
    (state, { sessionId, errorState }): State => {
      if (errorState === HomeHeaderErrorState.None){
        return {
          ...state,
          voiceSessionId: sessionId
        };
      }
      return state;
    }
  ),
  on(
    AgentAuthActions.oneCtiRegistered,
    (state, { avayaAgentId, phoneExtension }): State => {
      return {
        ...state,
        avayaAgentId,
        phoneExtension
      };
    }
  ),
  on(AgentAuthActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
);
