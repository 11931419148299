<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="(chat$ | async) as chat">
    <div class="merc-convo-details">

        <div class="merc-convo-details--content">
          <div class="merc-convo-details--item" *ngIf="(customer$ | async) as customer" x-test-target="customer-name">
            <label class="cee-eyebrow">Chatting with</label>
            <p class="cee-h2 merc-customer-name" data-qa="details-customer-name">{{ customer.name | titlecase }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.channel?.device?.operatingSystem">
            <label class="cee-eyebrow">Operating System</label>
            <p class="cee-h4" data-qa="details-operating-system">{{ chat.channel?.device?.operatingSystem }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.channel?.device?.name || chat.channel?.device?.version">
            <label class="cee-eyebrow">Browser Type</label>
            <p class="cee-h4" data-qa="details-browser-type">{{ getBrowserType(chat.channel?.device) }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="getDevice(chat.channel?.type) as deviceType" x-test-target="device-type">
            <label class="cee-eyebrow">Device Type</label>
            <p class="cee-h4" data-qa="details-device-type">{{ deviceType }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.channel?.device?.phoneNumber">
            <label class="cee-eyebrow">Customer Phone Number</label>
            <p class="cee-h4">{{ chat.channel?.device?.phoneNumber }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.chatType">
            <label class="cee-eyebrow">Chat Type</label>
            <p class="cee-h4" data-qa="details-chat-type">{{ chat.chatType }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.locale">
            <label class="cee-eyebrow">Locale</label>
            <p class="cee-h4" data-qa="details-locale">{{ chat.locale }}</p>
          </div>
        </div>

        <div class="merc-convo-details--content">
          <div class="merc-convo-details--item" *ngIf="chat.chatId">
            <label class="cee-eyebrow">Chat ID</label>
            <cee-button type="link"
            (buttonClick)="copyToClipboard(chat.chatId, 'chatId')"
            (mouseout)="resetCopyText('Chat ID', 'chatId')"
            [tooltipText]="chatIdTooltipText"
            tooltipWidth="125"
            tooltipId="selectedActiveChat?.chatId + '-copy-btn'"
            tooltipDirection="bottom">
            {{ chat.chatId }} <svg class="cee-icon cee-icon--secondary cee-icon--md">
              <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy'"></use>
            </svg></cee-button>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.nuanceCustomerId">
            <label class="cee-eyebrow">Customer ID</label>
            <cee-button type="link"
              (buttonClick)="copyToClipboard(chat.nuanceCustomerId, 'customerId')"
              (mouseout)="resetCopyText('Customer ID', 'customerId')"
              [tooltipText]="customerIdTooltipText"
              tooltipWidth="150"
              tooltipId="chat.nuanceCustomerId + '-copy-btn'"
              tooltipDirection="bottom">
              {{ chat.nuanceCustomerId }} <svg class="cee-icon cee-icon--secondary cee-icon--md">
                <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy'"></use>
              </svg>
            </cee-button>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.accountNumber">
            <label class="cee-eyebrow">Account Number</label>
            <cee-button type="link"
              (buttonClick)="copyToClipboard(chat.accountNumber,'accountNumber')"
              (mouseout)="resetCopyText('Account Number', 'accountNumber')"
              [tooltipText]="chatAccountTooltipText" tooltipWidth="175" tooltipId="chat.accountNumber + '-copy-btn'" tooltipDirection="bottom">
              {{ chat.accountNumber }} <svg class="cee-icon cee-icon--secondary cee-icon--md">
                <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy'"></use>
              </svg>
            </cee-button>
          </div>
          <div class="merc-convo-details--item" *ngIf="chat.businessRule">
            <label class="cee-eyebrow">Business Rule</label>
            <p class="cee-h4" data-qa="details-business-rule">{{ chat.businessRule }}</p>
          </div>
          <div class="merc-convo-details--item" *ngIf="(agentGroup$ | async) as agentGroup" x-test-target="agentGroup-name">
              <label class="cee-eyebrow">Agent Group</label>
              <p class="cee-h4" data-qa="details-agent-group">{{ agentGroup.displayName }}</p>
          </div>
        </div>
      </div>

      <div class="merc-convo-details--item merc-convo-details--item-bottom" *ngIf="getPageMarker(chat.pageMarkers) as pageMarker">
        <label class="cee-eyebrow">Page Marker</label>
        <p class="merc-page-marker-text"
          [ngClass]="{'collapsed': !isPageMarkersExpanded, 'expanded': isPageMarkersExpanded}"
          data-qa="details-page-markers"
          #pageMarkerText>{{ pageMarker }}</p>
        <cee-button
          class="merc-show-more-page-markers"
          type="link"
          (buttonClick)="expandPageMarkers()"
          [ngClass]="{'hidden': !shouldDisplayShowMorePageMarkers(isOpen)}">Show More</cee-button>
      </div>

</ng-container>
