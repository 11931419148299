// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Chat, ChatMessage } from '../domain/models';
import { CxGptResponse, GetCxGptMessagesRequest } from '../domain/models/requests/get-cx-gpt-messages-request';
import { ChatHelper } from './chatHelper';
import { SmartResponsesHelper } from './smart-responses-helper';

export class CxGptHelper {
  public static createRequest(chat: Chat): GetCxGptMessagesRequest {
    return {
      chatMessages: this.getPreviousMessages(chat)
    };
  }
  public static getPreviousMessages(chat: Chat): CxGptResponse[]{
    const asyncMessages = ChatHelper.getAsyncTranscriptMessages(chat.asyncEngagements);
    const chatMessages = ChatHelper.getXaTranscriptRemovedInteractions(chat.messages, chat.xaTranscriptEndTimestamp);
    const messages = [...chatMessages, ...asyncMessages];
    ChatHelper.sortMessagesByTimestamp(messages);

    const filteredMessages = messages.filter(m => SmartResponsesHelper.shouldAddDialog(m));

    return filteredMessages.map(fm => this.toRequestFormat(fm as ChatMessage));
  }

  private static toRequestFormat(chatMessage: ChatMessage): CxGptResponse {
    return {
      text: chatMessage.message,
      sender: chatMessage.sender
    };
  }
}
