<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-script-panel-button cee-btn--script" *ngIf="script">   
  <span class="merc-script-panel-button-text">
    <span class="merc-script-panel-button-indicator" *ngIf="(scriptStatus$ | async) as scriptStatus" [innerHtml]="scriptStatus" data-qa="script-status"></span>    
    <span [ngClass]="{'merc-script-span-padding' : scriptStatus}" class="script-highlight-style" [innerHtml]="getScriptValue(searchTerm$ | async, script$ | async) | safeHtml"></span>
  </span>
  <span class="merc-script-panel-button-option">
    <cee-button *ngIf="isCustomScript" type="icon" (click)="scriptEditClicked()" dataQa="edit-script">
      <svg class="cee-icon cee-icon--secondary">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-edit"></use>
      </svg>
    </cee-button>
    <cee-button *ngIf="isCustomScript" type="icon" (click)="scriptDeleteClicked()" dataQa="delete-script">
      <svg class="cee-icon cee-icon--secondary">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-delete"></use>
      </svg>
    </cee-button>
  </span>
</div>

