// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiResponses } from '../constants/cti.constants';
import { CtiRequest } from './cti-request';

export class CtiChatResponse extends CtiRequest{ 
    sessionId: string;
    messageName: string = CtiResponses.ChatDialogWSResponse;
    requestId: string;
    dataString: string;
    actionResult: string = CtiResponses.Success;
    
    public constructor (requestId: string, sessionId: string, dataString: string) {
        super(sessionId);
        this.requestId = requestId;       
        this.dataString = dataString;
   }
}