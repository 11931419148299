// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class SystemMessages {
  static PageMarker: string = 'Page Marker';
  static PageMarkers: string = 'Page Markers';
  static InboundCloseChat: string = 'Conversation closed: customer is speaking with a voice agent.';
  static CustomerLeft: string = 'Customer left the conversation';
}
