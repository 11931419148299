// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatState, ConfirmAction } from 'projects/entities/src/lib/domain/models/enums';
import { fromChat, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs/internal/Observable';
import { SuggestionService } from 'src/app/services/suggestion.service';

@Component({
  selector: 'merc-convo-header-confirm-close-transfer-modal',
  templateUrl: './convo-header-confirm-close-transfer-modal.component.html',
  styleUrls: ['./convo-header-confirm-close-transfer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvoHeaderConfirmCloseTransferModalComponent implements OnInit {
  @Input() active: boolean = true;
  confirmAction$: Observable<ConfirmAction>;
  chatState$: Observable<ChatState>;
  isAsync$: Observable<boolean>;
  hasDispositions$: Observable<boolean>;
  selectedChatId$: Observable<string>;

  constructor(private store: Store<AppState>,
    private suggestionService: SuggestionService
  ) {
  }

  ngOnInit(): void {
    this.confirmAction$ = this.store.select(fromUi.getConfirmCloseTransferAction);
    this.chatState$ = this.store.select(fromChat.getSelectedChatState);
    this.isAsync$ = this.store.select(fromChat.getSelectedChatIsAsync);
    this.hasDispositions$ = this.store.select(fromChat.hasDispositions);
    this.selectedChatId$ = this.store.select(fromChat.getSelectedChatId);
  }

  public closeModal() {
    this.store.dispatch(UiActions.ToggleCloseTransferModal({ toggleCloseTransferModal: false }));
  }

  public confirm(action: ConfirmAction, chatState: ChatState, isAsync: boolean, hasDispositions: boolean, chatId: string): void {
    this.suggestionService.handleConfirmAction(action, chatState, isAsync, hasDispositions, chatId);
    this.closeModal();
  }
}