// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { CallActions, LogActions } from 'projects/entities/src/lib/domain/actions';
import { LogInfo, LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { CallSummary } from 'projects/entities/src/lib/domain/models/voice/call-summary';
import { SessionSummaryState, CallSummaryDisplay } from 'projects/entities/src/lib/domain/models/voice/enums';
import { fromCall } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { CallOperations } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-session-summary',
  templateUrl: './session-summary.component.html',
  styleUrls: ['./session-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SessionSummaryComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @Input() sessionSummaryState: SessionSummaryState = SessionSummaryState.Default;
  isSessionSummaryCollapsed$: Observable<boolean>;
  currentCallId$: Observable<string>;
  callSummary$: Observable<CallSummary>;
  customerName$: Observable<string>;

  copySummaryTooltipText: string = '';

  SessionSummaryState = SessionSummaryState;

  constructor(
    private clipboardService: ClipboardService,
    private store: Store<AppState>,
    ) {
    super();
  }

  ngOnInit() {
    this.isSessionSummaryCollapsed$ = this.store.select(fromCall.getIsSessionSummaryCollapsed);
    this.currentCallId$ = this.store.select(fromCall.getCurrentCallId);
    this.callSummary$ = this.store.select(fromCall.getCallSummary);
    this.customerName$ = this.store.select(fromCall.getCustomerName);
  }

  public toggleSessionSummaryCollapse(isCurrentlyCollapsed: boolean) {
    this.store.dispatch(CallActions.toggleSessionSummaryCollapse({ isCollapsed: !isCurrentlyCollapsed }));
  }

  copyToClipboard(issue: string, resolution: string){
    const text = `${issue} ${resolution}`;
    this.clipboardService.copyTo(text);
    this.copySummaryTooltipText = 'Copied!';
    const logPayload: LogInfo = {
      logType: LogType.call,
      operationName: CallOperations.SummaryCopied
    };
    this.store.dispatch(LogActions.logCurrentCallAction({ logPayload }));
  }

  resetCopyText(){
    this.copySummaryTooltipText = '';
  }

  positiveRating(){
    this.store.dispatch(CallActions.rateSessionSummary({isPositiveRating: true}));
  }

  negativeRating(){
    this.store.dispatch(CallActions.rateSessionSummary({isPositiveRating: false}));
  }

  getDescription(): string{
    const defaultText = 'Review and copy this summary to your notes in Einstein 360 before closing.';
    switch (this.sessionSummaryState){
      case SessionSummaryState.CloseSession:
        return `${defaultText} This will not disconnect the call.`;
      case SessionSummaryState.NextSession:
        return `${defaultText} Session for next call will load.`;
      default:
        return defaultText;
    }
  }

  showCallerName(): boolean{
    switch (this.sessionSummaryState){
      case SessionSummaryState.CloseSession:
      case SessionSummaryState.NextSession:
        return true;
      default:
        return false;
    }
  }

  retrySessionSummary(callId: string){
    const display = this.sessionSummaryState === SessionSummaryState.Default ? CallSummaryDisplay.Panel : CallSummaryDisplay.Modal;
    this.store.dispatch(CallActions.getCallSummary({callId, display, traceId: Guid.raw(), isRetry: true}));
  }
}

