// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatUi } from '../domain/chat-ui/chat-ui.model';
import { ChatInteraction } from '../domain/models';
import { ItgState, SenderType } from '../domain/models/enums';
import { ItgMetadata } from '../domain/models/itgMetadata';
import { SchedulerIntent } from '../domain/models/schedulerIntent';
import { SchedulerSuggestion } from '../domain/models/schedulerSuggestion';
import { SuggestionBase } from '../domain/models/suggestion';

export class ItgHelper {
  public static getItgsInProgressForActivityBar(itgs: ItgMetadata[]){
    return itgs?.filter(itg => itg.state !== ItgState.Canceled && !itg.dismissed);
  }

  public static isItgInProgress(chatUi: ChatUi): boolean {
    return Boolean(chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Running || itg.state === ItgState.Paused));
  }

  public static isErrorState(state: ItgState): boolean {
    switch (state){
      case ItgState.Error:
      case ItgState.Expired:
      case ItgState.SchedulerError:
        return true;
      default:
        return false;
    }
  }

  public static isItgStateInProgress(itgState: ItgState): boolean {
    return itgState === ItgState.Running || itgState === ItgState.Paused;
  }

  public static isSchedulerStartIntent(schedulerIntentConfig: SchedulerIntent, suggestion: SuggestionBase){
    return suggestion.intent === schedulerIntentConfig.startContentCode;
  }

  public static isSchedulerEndIntent(schedulerIntentConfig: SchedulerIntent, schedulerSuggestion: SchedulerSuggestion){
    return schedulerSuggestion.intentValue === schedulerIntentConfig.endIntentCode;
  }

  public static shouldSyncItg(
    isItgRunning: boolean,
    getServerTranscriptMessages: () => ChatInteraction[],
    stateTranscriptMessages: ChatInteraction[]): {shouldSync: boolean, syncMessage?: ChatInteraction}
  {
    if (!isItgRunning) { return { shouldSync: false }; }
    // getServerTranscriptMessages is a function so that it will not process the response into interactions unless needed
    const serverTranscriptMessages = getServerTranscriptMessages();
    const reverseServerMessages = serverTranscriptMessages.slice().reverse();
    const reverseStateMessages = stateTranscriptMessages?.slice()?.reverse();
    const latestServerMessage = reverseServerMessages.find(m => m.sender !== SenderType.System);
    const latestStateMessage = reverseStateMessages?.find(m => m.sender !== SenderType.System);

    // if the latest server message is older than the latest state message we don't need to sync
    if (!latestServerMessage
      || (latestStateMessage && latestServerMessage.timestamp < latestStateMessage.timestamp)
      || latestServerMessage.sender !== SenderType.Requester) {
        return { shouldSync: false };
    }

    // if the latest server messages already exists in state messages, no need to sync
    const messageExists = stateTranscriptMessages?.find(m => m.messageId === latestServerMessage.messageId);
    if (messageExists) { return { shouldSync: false }; }

    return { shouldSync: true, syncMessage: latestServerMessage };
  }
}
