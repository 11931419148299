// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { OneCtiActions } from '../actions';

export const featureKey = 'one-cti';

export interface State {
  isListenerServiceConnected: boolean;
  isChatServiceConnected: boolean;
  isOneCtiReconnect: boolean;
}

export const initialState: State = {
  isListenerServiceConnected: false,
  isChatServiceConnected: false,
  isOneCtiReconnect: false
};

export const reducer = createReducer<State>(
  initialState,
  on(
    OneCtiActions.UpdateChatServiceConnection,
    (state, {isConnected: isChatServiceConnected}): State => ({
      ...state,
      isChatServiceConnected
    })
  ),
  on(
    OneCtiActions.UpdateListenerServiceConnection,
    (state, {isConnected: isListenerServiceConnected}): State => ({
      ...state,
      isListenerServiceConnected
    })
  ),
  on(
    OneCtiActions.UpdateReconnectOneCtiComplete,
    (state): State => ({
      ...state,
      isOneCtiReconnect: false
    })
  ),
  on(
    OneCtiActions.ReconnectOneCti,
    (state): State => ({
      ...state,
      isOneCtiReconnect: true
    })
  )
);
