<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal [headerText]="'Conversation Summary'" [active]="active$ | async"  
  (modalClose)="closeModal()" [customModalClass]="'merc-previous-summary-modal'">
  <ng-container *ngIf="{
      chatSummary: chatSummary$ | async
    } as data">    
    <div class="merc-previous-customer-info-heading">      
      <cee-customer-avatar
        [customerName]="data.chatSummary?.customerName"
        [customerNumber]="data.chatSummary?.color?.id">
      </cee-customer-avatar>
      <div class="merc-flex-baseline">  
        <div>
          <span class="merc-customer-name">{{ data.chatSummary?.customerName }}</span>
          <span class="merc-customer-account-number" *ngIf="!data.chatSummary?.accountNumber">{{ data.chatSummary?.chatId }}</span>
        </div>
      </div>  
    </div>
    <div class="merc-previous-summary-modal-copy">  
      <div class="merc-previous-summary-modal-chatId">
        <span>CHAT ID</span>
        <cee-button-v2 type="link"   
            tooltipDirection="top"
            tooltipAppearanceDelayMs="0"
            tooltipPersistDirective="true"
            (buttonClick)="copyToClipboard(data.chatSummary.chatId, 'chatId')"
            (mouseout)="resetCopyText()"
            [tooltipText]="copyChatIdTooltipText"
            >
          <span>{{ data.chatSummary?.chatId }}</span>
          <svg class="cee-icon">
            <use xlink:href="/assets/img/svg-sprite.svg#icon-copy-v2"></use>
          </svg>
        </cee-button-v2>
      </div>
      <div class="merc-previous-summary-modal-accountnumber" *ngIf="data.chatSummary?.accountNumber">
        <span>ACCOUNT NUMBER</span>
        <cee-button-v2 type="link"   
            tooltipDirection="top"
            tooltipAppearanceDelayMs="0"
            tooltipPersistDirective="true"
            (buttonClick)="copyToClipboard(data.chatSummary?.accountNumber, 'accountNumber')"
            (mouseout)="resetCopyText()"
            [tooltipText]="copyAccountNumberTooltipText">
          <span>{{ data.chatSummary?.accountNumber }}  </span>
          <svg class="cee-icon">
            <use xlink:href="/assets/img/svg-sprite.svg#icon-copy-v2"></use>
          </svg>
        </cee-button-v2>
      </div>
    </div>
    <div class="merc-previous-summary-modal-summary cee-form-row">  
        <cee-textarea [name]="notes" label="NOTES" [readonly]="true" [disabled]="true" [textInput]="data.chatSummary?.summary ?? ''">
        </cee-textarea>
    </div>
    <div class="merc-previous-summary-modal-footer">
      <cee-button-v2 class="merc-tool-tip" type="link" tooltipId="copy-summary"
        tooltipDirection="bottom" tooltipAppearanceDelayMs="0" tooltipPersistDirective="true"
        (buttonClick)="copyToClipboard(data.chatSummary?.summary, 'notes')"
        (mouseout)="resetCopyText()"
        [tooltipText]="copySummaryTooltipText">
        <svg class="cee-icon cee-icon--md">
          <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy'"></use>
        </svg> <span>Copy to Clipboard</span>
      </cee-button-v2>
    </div>
  </ng-container>
</cee-modal>
