// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromScript from './script.reducer';

@Injectable({
  providedIn: 'root'
})
export class ScriptPersisterService extends StatePersister<fromScript.State> {
  constructor() {
    super(sessionStorage, fromScript.scriptsFeatureKey);
  }
}
