// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState, ScriptActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { fromCustomResponses, ScriptGroup, ScriptGroupActions } from 'projects/entities/src/lib/domain/scripts/';

@Component({
  selector: 'merc-convo-script-group-confirm-delete-modal',
  templateUrl: './script-group-confirm-delete-modal.component.html',
  styleUrls: ['./script-group-confirm-delete-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptGroupConfirmDeleteModalComponent implements OnInit {
  public scriptGroup$: Observable<ScriptGroup>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.scriptGroup$ = this.store.select(fromCustomResponses.selectScriptGroupToDelete);
  }

  closeModal(): void {
    this.store.dispatch(ScriptActions.deleteScriptConfirmationDismiss());
  }

  public deleteScriptGroup(scriptGroup: ScriptGroup): void {
    const { id } = scriptGroup;
    this.store.dispatch(ScriptGroupActions.deleteScriptGroup({ id }));
  }
}
