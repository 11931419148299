<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="!(pageInitSucceeded$ | async); else pageContent">
  <div class="merc-login-background cee-theme-dark">
    <div class="merc-login-logo">
      <img
        src="../../../assets/img/mercury-with-text.png"
        class="merc-login-image"
      />
    </div>
    <div class="merc-login-form merc-login-form--initialization merc-padding">
      <ng-container *ngIf="pageInitCriticalError$ | async; else loading">
        <div class="merc-initialization-image--error">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 53">
            <path
              fill="#FF9271"
              fill-rule="nonzero"
              d="M56.58 35.5c4.650238 0 8.42 3.769762 8.42 8.42v.16c0 2.233123-.887105 4.374783-2.466161 5.953839C60.954783 51.612895 58.813123 52.5 56.58 52.5h-3.16c-2.233123 0-4.374783-.887105-5.953839-2.466161C45.887105 48.454783 45 46.313123 45 44.08v-.16c0-4.650238 3.769762-8.42 8.42-8.42h3.16zm0 1.5h-3.16c-3.82181 0-6.92 3.09819-6.92 6.92v.16c0 3.82181 3.09819 6.92 6.92 6.92h3.16c1.835298 0 3.595427-.729069 4.893179-2.026821S63.5 45.915298 63.5 44.08v-.16c0-1.835298-.729069-3.595427-2.026821-4.893179S58.415298 37 56.58 37zm.64 3.69l1.06 1.06L56.05 44l2.22 2.22-1.06 1.06L55 45l-2.23 2.25-1.06-1.06L53.93 44l-2.22-2.25 1.06-1.06L55 42.91l2.22-2.22zM32 38c2.067082.016537 3.73095 1.702721 3.72 3.76984-.011056 2.067119-1.692779 3.735495-3.75992 3.73004C29.892939 45.494399 28.22 43.817148 28.22 41.75l.03-.03C28.266475 39.660657 29.940592 38 32 38zm-.015-14.313729c5.81283 0 11.387195 2.310952 15.495 6.423729l-3.72 3.75C40.647458 30.732826 36.412123 28.982509 32 29c-4.417519-.030113-8.663293 1.70925-11.79 4.83l-3.72-3.72c4.107805-4.112777 9.68217-6.423729 15.495-6.423729zm-.12-11.625828c8.872608 0 17.381723 3.525071 23.655 9.799557L51.8 25.7c-5.284179-5.292573-12.456104-8.266616-19.935-8.266616-7.478896 0-14.650821 2.974043-19.935 8.266616l-3.72-3.84c6.273277-6.274486 14.782392-9.799557 23.655-9.799557zm.09-11.595929C43.916816.464514 55.386416 5.227047 63.83 13.7l-3.69 3.72C52.695186 9.927655 42.562191 5.725378 32 5.75v.03C21.431755 5.739419 11.287085 9.931252 3.83 17.42L.08 13.7C8.523584 5.227047 19.993184.464514 31.955.464514z"
            />
          </svg>
        </div>
        <h2 class="cee-h2">
          {{errorMessage}}
        </h2>
        <div class="merc-initialization-message--error">
          <ng-container *ngFor="let error of pageInitErrorList$ | async">
            <p>
              {{error}}
            </p>
          </ng-container>
        </div>
        <cee-button id="try-again-button" (buttonClick)="onTryAgain()">
          <svg class="cee-icon">
            <use href="/assets/img/svg-sprite.svg#icon-refresh"></use>
          </svg>
          Try Again
        </cee-button>
        <cee-button id="mySupport-button" *ngIf="true || (isMySupportFlagOn$ | async)" type="secondary" (buttonClick)="onPageInitMySupportClicked()">
          <svg class="cee-icon">
            <use href="/assets/img/svg-sprite.svg#icon-help-fill"></use>
          </svg>
          mySupport
        </cee-button>
      </ng-container>
      <ng-template #loading>
        <h2 class="cee-h2">Session Loading...</h2>
        <div class="cee-initialization-loading-indicator">
          <cee-loading-indicator></cee-loading-indicator>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #pageContent>
  <ng-content></ng-content>
</ng-template>
