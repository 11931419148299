<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="isNavigation && (isShowPageNavigation$ | async) || isXA || isItgAction">
    <cee-customer-actions 
      [ActionList]="[toAction]" 
      [showLinked]="false"
      [showPin]="showPin && (hasPinFeatureFlag$ | async)"
      [isPinned]="isPinned$ | async"
      [alignment]="isItgAction ? 'right' : 'left'"
      (pin)="togglePin($event)"
      ></cee-customer-actions>
</ng-container>
<ng-container *ngIf="!isNavigation && !isXA && !isDatapass && !isItgAction">
    <cee-conversation-state-message [text]="chatMessage?.message" [timestamp]="getFormattedTime(chatMessage?.timestamp)" [type]="typeString"></cee-conversation-state-message>
</ng-container>
<ng-container *ngIf="isDatapass && (isShowCustomerActions$ | async)">
    <cee-customer-actions 
    [hasBackground]="true" 
    [showLinked]="false" 
    [ActionList]="[toAction]"
    [showPin]="showPin && (hasPinFeatureFlag$ | async)"
    [isPinned]="isPinned$ | async"
    (pin)="togglePin($event)"
    > </cee-customer-actions>
</ng-container>
