// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export interface ScriptGroup {
  id: string;
  scriptCollectionId: string; // id to roundtrip w/ API calls
  name: string;
  scriptGroups: string[];
  scripts: string[];
  scriptTreeId: string; // "foreign key" to make it easier to query
}

export function generateMockScriptGroup(): ScriptGroup {
  return {
    id: 'scriptGroup1',
    scriptCollectionId: 'scriptCollection1',
    name: 'scriptGroupName',
    scriptGroups: [],
    scripts: [],
    scriptTreeId: 'scriptTree1',
  };
}
