// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, UiActions } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-rate-connection-modal',
  templateUrl: './rate-connection-modal.component.html',
  styleUrls: ['./rate-connection-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RateConnectionModalComponent implements OnInit {
  active: boolean = true;
  @Input() chatId: string;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
  }


  closeModal(dismissFeedbackPrompt: boolean): void {
    this.store.dispatch(UiActions.DismissFeedbackPrompt({dismissFeedbackPrompt, chatId: this.chatId, qualityCheckDateTime: new Date() }));
    this.modalClose.emit(true);
  }
}
