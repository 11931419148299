// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CallSummary } from '../../models/voice/call-summary';
import { CustomerInfo } from '../../models/voice/customerInfo';
import { CallState, CallSummaryDisplay } from '../../models/voice/enums';
import { FeedContent } from '../../models/voice/feed-content';
import { TranscriptMessage } from '../../models/voice/transcriptMessage';

export class Call {
  callId: string;
  callState?: CallState = CallState.Queued;
  customerInfo?: CustomerInfo;
  sessionId?: string;
  uui?: string;
  ucid?: string;
  transcriptMessages?: TranscriptMessage[] = [];
  highlightedMessages?: number[] = [];
  feedContent?: FeedContent[] = [];
  startTimestamp?: number;
  endTimestamp?: number;
  callSummary?: CallSummary;
  customerContext?: string;
  isSessionSummaryCollapsed?: boolean;
  showSessionSummaryDrawer?: boolean;
  callSummaryInProgressTraceId?: string | null;
  callSummaryDisplay?: CallSummaryDisplay;
  showSessionSummaryModal?: boolean;
  firstTranscriptMessageLogged?: boolean;
}
