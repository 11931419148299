// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatMessage } from '../chatMessage';
import { MaskText } from '../mask-text';

export class ConvoMessageContextMenu {
    selectedText: string;
    isSelectionFullyMasked: boolean;
    maskText: MaskText;
    clientX: number;
    clientY: number;
    open: boolean;
    chatMessage: ChatMessage;
}
