// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ChatGetUpdateSettings {
  /**
   * How long to wait in seconds before checking
   * if AppActions.UpdatesComplete fired
   */
  static WaitTimeInSeconds: number = 45;

  /**
   * The number seconds to look back when determining if
   * an update has completely recently enough
   */
  static LastUpdateCompletedMaxAgeInSeconds: number = 30;
}

export class GetActiveEngagementsSettings{
  /**
   * How long to wait in seconds before allowing GetActiveEngagements to be called again
   * so we don't spam the server if the agent has bad connectivity
   */
   static WaitTimeInSeconds: number = 10;

   /**
   * How long to wait in seconds before retrying the GetActiveEngagements call
   * while the connection is not connected
   */
   static TimeBetweenRetryInSeconds: number = 5;
}

export class LockoutVerifySessionSettings {
  /**
   * How long to wait in seconds before firing
   * AgentAuthActions.LockoutPersistSession
   */
   static WaitTimeInSeconds: number = 30;
}

export class SendChatMessageSettings {
  /**
   * maximum number of retries possible before the message should go from Error state to Failed state
   */
  static MaxRetryAttempts: number = 3;
}

export class ConnectionLostModalSettings {
  /**
   * how long to wait before showing the connection lost modal
  */
  static WaitTimeInSeconds: number = 15;

  static ThrottleConfig = {
    leading: false,
    trailing: true
  };
}

export class AskMeAnythingSettings {
  /**
   * How long to wait before auto dismissing the error banner
   */
  static ErrorBannerDismissTimeInSeconds: number = 5;
}