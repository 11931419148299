// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class UuiHelper {
  static getContextCode(uui: string): string {
    const uuiCodes = uui?.split(',');
    if (!uuiCodes || uuiCodes.length <= 7) { return null; }
    return uuiCodes[7]?.slice(0, 2);
  }
}
