<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-confirmation-modal
  headerText="You have unsaved changes"
  [active]="active"
  confirmText="Save Changes"
  cancelText="Don't Save"
  [controlAutoClose]="false"
  [clickOutsideToClose]="false"
  (modalClose)="closeModal()"
  (confirmClicked)="saveSettings()"
  (cancelClicked)="cancelSettings()">
  <p>Do you want to save your changes before closing?</p>
</cee-confirmation-modal>
