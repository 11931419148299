<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-login-background cee-theme-dark">
    <div class="merc-login-logo">
        <img src="../../../assets/img/mercury-with-text.png" class="merc-login-image"/>
    </div>
    <merc-login-banner></merc-login-banner>
    <div class="merc-login-form merc-padding">
        <cee-button type="primary" size="sm" (buttonClick) ="onLoginClick()" dataQa="login">Login</cee-button>
    </div>   
    <div class="login-switch-link">
        <cee-button type="link" (buttonClick)="switchToLogin()" dataQa="manual-login">Manual Login</cee-button>
      </div>
</div>

