// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ScriptActions, ScriptTreeActions } from './actions';
import { ScriptTree } from './models';

export const scriptTreesFeatureKey = 'scriptTrees';

export interface State extends EntityState<ScriptTree> { }

export const adapter: EntityAdapter<ScriptTree> = createEntityAdapter<ScriptTree>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(ScriptTreeActions.upsertScriptCollection,
    (state, { scriptTree }) => adapter.upsertOne(scriptTree, state)
  ),
  on(ScriptTreeActions.deleteScriptCollection,
    (state, { scriptTreeId }) => adapter.removeOne(scriptTreeId, state)
  ),
  on(ScriptActions.hydrateSuccess, (state, action) => {
    return action.scriptTreeState ? action.scriptTreeState : state;
  })
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
