<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-login-background cee-theme-dark">
  <div class="merc-login-logo">
    <img
      src="../../../assets/img/mercury-with-text.png"
      class="merc-login-image"
    />
  </div>
  <div class="merc-login-form-wrapper merc-session-timeout">
    <form
      class="merc-login-form merc-login-form--compact merc-padding"
    >
      <cee-banner
        id="session-timeout-banner"
        [type]="'warning'"
      >
      You have been logged out due to inactivity. You can continue your previous session by logging back in, otherwise the session will end automatically.
      </cee-banner>
      <div class="cee-form-row cee-form-row--lined">
        <p class="cee-body">
          <span class="cee-descriptor">Last user:</span><span> {{fullName}}</span>
        </p>
        <p class="cee-body">
          <span class="cee-descriptor">Last status:</span><span> {{status}}</span>
        </p>
        <p class="cee-body">
          <span class="cee-descriptor">Status time:</span><span> {{currentTime}}</span>
        </p>
      </div>
      <div *ngIf="ssoLogin" class="merc-login-form-submit">
          <cee-button type="secondary" size="sm" (buttonClick)="onCancel()"
            >{{cancelText}}</cee-button
          >
          <cee-button type="primary" size="sm" (buttonClick)="onSubmit()"
            >{{submitText}}</cee-button
          >
      </div>
      <merc-login-form *ngIf="!ssoLogin" [cancelText]="cancelText"></merc-login-form>
    </form>
  </div>
</div>
