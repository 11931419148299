// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ItgSuggestion } from '../itgSuggestion';

export class RecommendationsResponse {
  chatId: string;
  suggestion: ItgSuggestion;
  intents: string[];
  message: string;
  messageId: string;
  queryId: string;
  sessionId: string;
  json: string;  
}

