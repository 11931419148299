// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { DataCollectionState } from './enums';

export class SecureDataCollection {
    constructor() {
        this.dataCollectionState = DataCollectionState.Inactive;
    }

    dataCollectionState: DataCollectionState;
    dataCollectionUrl?: string;
    startTime?: number;
}

export class SecureDataCollectionInfo {
    chatId: string;
    collectionUrl: string;
    requestId: string;
}