// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../domain/state';
import { fromAgentAuth } from '../domain/selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { HubResponse } from '@cxt-cee-chat/merc-ng-core';
import { SendTaskType } from '../domain/models/enums';

const numAutoRetries: number = 1;

@Injectable({
  providedIn: 'root'
})
export class QueueChatRequestService {
  private tasks: SendTask<HubResponse>[] = [];
  private isProcessingTask = false;
  private processMessages = false;

  constructor(
    private _appStore: Store<AppState>,
  ) {
    this._appStore.select(fromAgentAuth.chatRequestProcessable)
      .pipe(distinctUntilChanged())
      .subscribe((processable: boolean) => {
        this.processMessages = processable;
        if (processable) {
          this.processNext();
        }
      });
  }

  public addTask(
    promise: () => Promise<HubResponse>,
    onSuccess: (response: HubResponse, numTries: number) => void = () => { },
    onFailure: (response: HubResponse, numTries: number) => void = () => { },
    type: SendTaskType,
    chatId: string
  ) {
    const task: SendTask<HubResponse> = {
      call: promise,
      tries: 0,
      onSuccess,
      onFailure,
      type,
      chatId
    };
    this.tasks.push(task);
    this.processNext();
  }

  public hasTaskForChat(chatId: string, predicate: (taskType: SendTaskType) => boolean): boolean{
    return Boolean(this.tasks.find(t => t.chatId === chatId && predicate(t.type)));
  }

  private processNext() {
    if (this.tasks.length === 0) { return; }
    if (this.isProcessingTask) { return; }
    if (!this.processMessages) { return; }

    this.isProcessingTask = true;

    const task = this.tasks[0];

    task.call()
      .then((response: HubResponse) => {
        if (response.success) {
          this.taskSucceeded(task, response);
        }
        else {
          this.taskFailed(task, response);
        }
      })
      .catch((response: HubResponse) => {
        this.taskFailed(task, response);
      })
      .finally(() => {
        this.isProcessingTask = false;
        this.processNext();
      });
  }

  private taskFailed(task: SendTask<HubResponse>, response: HubResponse) {
    task.tries++;

    if (task.tries > numAutoRetries) {
      task.onFailure(response, numAutoRetries);
      this.tasks = this.tasks.slice(1);
    }
  }

  private taskSucceeded(task: SendTask<HubResponse>, response: HubResponse){
    task.onSuccess(response, task.tries);
    this.tasks = this.tasks.slice(1);
  }
}

class SendTask<T> {
  call: () => Promise<T>;
  tries: number;
  onSuccess?: (response: T, numTries: number) => void;
  onFailure?: (response: T, numTries: number) => void;
  type: SendTaskType;
  chatId: string;
}
