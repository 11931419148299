<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div #engagementContainer>
  <cee-accordion
    #engagementAccordion
    [titleText]="title"
    [dateTime]="timestampStart"
    [channels]="channels"
    [disabled]="true"
    type="conversation"
  >
    <div
      *ngFor="
        let interaction of engagement.interactions;
        let i = index;
        trackBy: trackByInteraction
      "
    >
      <merc-convo-message
        [chatMessage]="interaction"
        [chatId]="selectedActiveChat.chatId"
        [channelType]="selectedActiveChat.channel?.type"
        [colorId]="selectedActiveChat.color.id"
        [timeZone]="selectedActiveChat.timeZone"
        [previousMessage]="engagement.interactions[i - 1]"
        [participants]="engagement.participants"
        [customerDetails]="engagement.customerDetails">
      </merc-convo-message>
    </div>
    <cee-conversation-state-message *ngIf="!isResolved" [text]="'Closed as unresolved'" [timestamp]="timestampEnd"></cee-conversation-state-message>
  </cee-accordion>
</div>
