// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class VoiceHubConstants {
  public static readonly Callbacks = {
    Echo: 'MercuryEcho',
    CallSummary: 'CallSummary',
    TranscriptMessage: 'TranscriptMessage',
    RecommendedAction: 'RecommendedAction'
  };
}
