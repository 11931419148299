// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';

/*
 * take first x elements in an array with the input number
 * Usage:
 *   array | takeFirst : 'numElements'
*/

@Pipe({
  name: 'takeFirst'
})
export class TakeFirstPipe implements PipeTransform {
  transform(arr: any[], numElements: number) {
    if (!arr) { return arr; }
    if (!numElements && numElements !== 0) { return arr; }

    return arr.slice(0, numElements);
  }
}
