// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { throwError } from 'rxjs';


export class WebSocketErrorHandler {
	public handleWebSocketError(webSocketError: any, _causedBy: string): any {
		return throwError(webSocketError || 'Unknown Websocket Server Error');
	}
}
