// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { ChatInteraction, ChatMember } from 'projects/entities/src/public_api';

/*
 * Formats incoming data
 * to be used in a transfer data card
 * Usage:
 *   EngagementTranscript[] | sortAsyncEngagement
*/


@Pipe({name: 'formatTransferData'})
export class FormatTransferDataPipe implements PipeTransform {
  constructor(){}

  transform(message: ChatInteraction, participants: ChatMember[], queueName: string) {
    return [
      {
        label: 'From',
        value: this.getAgentDisplayName(message.senderId, participants)
      },
      {
        label: 'Previous Queue',
        value: message.previousQueue?.name ? message.previousQueue.name : 
               message.queue?.name ? message.queue.name : 
               queueName ? queueName : 'N/A'
      },
      {
        label: 'Note',
        dataQa: 'transfer-note',
        value: message.notes ? message.notes : 'N/A'
      }
    ];
  }

  getAgentDisplayName(senderId: string, participants: ChatMember[]) {
  if (senderId && participants) {
        const participant = participants.find(
          user => user.id === senderId
        );
        return participant && participant.displayName
          ? participant.displayName
          : 'N/A';
      }
      return 'N/A';
  }
}
