<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-banner [type]="bannerType" [active]="showBanner$ | async" [dismissModalActive]="showBanner$ | async"
  [dismissable]="true" (bannerDismiss)="onDismiss()">
  {{ bannerText$ | async }}
</cee-banner>
