// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';

/*
 * Sorts array in asc/desc order or sorts array of objects in asc/desc order based on property name
 * defaults to asc if no order given
 * Usage:
 *   "let a of oneDimensionalArray | sortBy: 'order'"
 *   "let a of arrayOfObjects | sortBy: 'order':'propertyName'"
*/

@Pipe({name: 'sortBy'})
export class SortByPipe implements PipeTransform {
  transform(values: any[], order: string = 'asc', prop?: string, secondaryOrder?: string, secondaryProp?: string): any[] {
    if (!values) { return values; } // empty
    if (values.length <= 1) { return values; } // one values

    const valuesCopy = values.slice(); // copy array to avoid mutating state

    if (order === 'asc') {
      return this.sortAsc(valuesCopy, prop, secondaryOrder, secondaryProp);
    } else {
      return this.sortDesc(valuesCopy, prop, secondaryOrder, secondaryProp);
    }
  }


  sortAsc(vals, sortProp?, secondaryOrder?, secondaryProp?) {
    if (sortProp) {
      vals.sort((a, b) => {
        if (a[sortProp] > b[sortProp]) {
          return 1;
        }

        if (b[sortProp] > a[sortProp]) {
          return -1;
        }

        if (secondaryProp && a[sortProp] === b[sortProp]) {
          return this.sortSecondaryProp(a, b, secondaryOrder, secondaryProp);
        }

        return 0;
      });
    } else {
      vals.sort((a, b) => {
        if (a > b) {
          return 1;
        }
  
        if (b > a) {
          return -1;
        }
        return 0;
      });
    }
    return vals;
  }

  sortDesc(vals, sortProp?, secondaryOrder?, secondaryProp?) {
    if (sortProp) {
      vals.sort((a, b) => {
        if (a[sortProp] > b[sortProp]) {
          return -1;
        }

        if (b[sortProp] > a[sortProp]) {
          return 1;
        }

        if (secondaryProp && a[sortProp] === b[sortProp]) {
          return this.sortSecondaryProp(a, b, secondaryOrder, secondaryProp);
        }

        return 0;
      });
    } else {
      vals.sort((a, b) => {
        if (a > b) {
          return -1;
        }
  
        if (b > a) {
          return 1;
        }
        return 0;
      });
    }
    return vals;
  }

  sortSecondaryProp(a, b, secondaryOrder, secondaryProp) {
    if (secondaryOrder === 'desc') {
      if (a[secondaryProp] > b[secondaryProp]) {
        return -1;
      }

      if (b[secondaryProp] > a[secondaryProp]) {
        return 1;
      }
    }
    else {
      if (a[secondaryProp] > b[secondaryProp]) {
        return 1;
      }

      if (b[secondaryProp] > a[secondaryProp]) {
        return -1;
      }
    }

    return 0;
  }
}