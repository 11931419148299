// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AccountUser, AccountUsersResponse,
  UpdateAccountNumber, GetChatAccountUsers, UserRole, AppState, ConnectAccountActions, ChatActions } from 'projects/entities/src/public_api';
import { ButtonControl, ButtonType } from '@cxt-cee-chat/merc-pattern-lib';
import { SelectOption } from 'src/app/models/select-option';
import { UnlinkAccountResponse } from 'projects/entities/src/lib/domain/models/responses/unlink-account-response';
import { BehaviorSubject, Subscriber, Observable } from 'rxjs';
import { fromChat, fromConnectAccount } from 'projects/entities/src/lib/domain/selectors';

const connectButtonText: string = 'Connect';

@Component({
  selector: 'merc-connect-account-modal',
  templateUrl: './connect-account.component.html',
  styleUrls: ['./connect-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectAccountComponent extends SubscriberComponent implements OnInit, AfterViewChecked  {
  @ViewChild('accountNumberElm') accountNumberElm: ElementRef;
  @ViewChild('form') ngForm: NgForm;
  active: boolean = false;
  errorMessage: string = '';
  showErrorMessage: boolean = false;
  validatedAccountNumber: string = '';
  validAccountNumber: boolean = false;
  accountNumberInput: string = '';
  currentAccountNumber$: Observable<string>;
  lookupAccountNumber: string;
  displayNameInput: string = '';
  selectedUserId: string = '';
  isLookingUpUsers: boolean = false;
  hasLookedUpUsers: boolean = false;
  unlinkSuccessful$: Observable<UnlinkAccountResponse>;
  options: SelectOption[] = [];
  controls$: Observable<ButtonControl[]>;

  private setFocus: boolean = false;
  private users: AccountUser[] = [];
  private selectedActiveChatId: string;
  private wasCanceled: boolean = false;
  private setControls$: BehaviorSubject<boolean>;

  constructor(
    private ngEntityStore: Store<AppState>,
    private changeDetection: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.ngEntityStore.select(fromChat.getSelectedChatId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((chatId: string) => {
        // not used in the HTML
        this.selectedActiveChatId = chatId;
      }
    );

    this.ngEntityStore.select(fromConnectAccount.getAccountUsersResponse)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: AccountUsersResponse) => {
        if (data && this.selectedActiveChatId === data.chatId) {
          this.isLookingUpUsers = false;
          this.hasLookedUpUsers = true;

          this.users = data.accountUsers;
          this.options = this.getOptions();
          this.showErrorMessage = !data.isValidAccount;
          if (data.isValidAccount) {
            this.validatedAccountNumber = this.accountNumberInput;
          }
          else {
            this.validatedAccountNumber = '';
            this.selectedUserId = '';
            this.errorMessage = 'This account could not be found. Please re-enter and try again.';
          }
          this.changeDetection.detectChanges();
        }
      }
    );

    this.setControls$ = new BehaviorSubject<boolean>(false);
    this.controls$ = new Observable<ButtonControl[]>((subscriber: Subscriber<ButtonControl[]>) => {
      this.setControls$
        .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
        .subscribe((validData: boolean) => {
          subscriber.next([
            {
              text: 'Cancel',
              type: ButtonType.Secondary,
              disabled: false,
              dataQa: 'cancel',
              controlFunction: () => this.closeModal()
            },
            {
              text: connectButtonText,
              type: ButtonType.Primary,
              disabled: !validData,
              dataQa: 'connect-account',
              controlFunction: () => this.onConfirm()
            }]);
        });
    });

    this.currentAccountNumber$ = this.ngEntityStore.select(fromChat.getSelectedChatAccountNumber).pipe(distinctUntilChanged());
    this.unlinkSuccessful$ = this.ngEntityStore.select(fromConnectAccount.getUnlinkAccountResponse);
    this.openAndResetModal();
  }

  ngAfterViewChecked() {
    if (this.accountNumberElm && this.accountNumberElm.nativeElement && !this.setFocus && this.active) {
        this.accountNumberElm.nativeElement.focus();
    }
  }

  openAndResetModal() {
    this.active = true;
    this.validatedAccountNumber = '';
    this.displayNameInput = '';
    this.selectedUserId = '';
    this.users = [];
    this.accountNumberInput = '';
    this.showErrorMessage = false;
    this.isLookingUpUsers = false;
    this.hasLookedUpUsers = false;
    this.wasCanceled = true;
    this.setFocus = false;
    this.setControls$.next(false);
    this.validAccountNumber = false;
    this.changeDetection.detectChanges();
  }

  closeModal() {
    this.ngEntityStore.dispatch(ConnectAccountActions.ToggleModalOpen(false));
  }

  validateModalData() {
    let isValid: boolean;
    if (this.lookupAccountNumber === this.accountNumberInput){
      if (this.selectedUserId === 'Other') {
        isValid = this.displayNameInput !== '';
      }
      else {
        isValid = this.selectedUserId !== '';
      }
    }
    else {
      isValid = false;
    }
    this.setControls$.next(isValid);
  }

  onAccountNumberInput_Focus() {
    this.showErrorMessage = false;
    this.wasCanceled = false;
    this.setFocus = true;
  }

  onAccountNumberInput_Blur() {
    if (!this.wasCanceled) {
      if (this.ngForm.controls['connect-account-number'] && !this.ngForm.controls['connect-account-number'].dirty) {
        this.setFocus = false;
        this.ngForm.controls['connect-account-number'].markAsDirty();
      } else {
        this.setFocus = true;
        this.accountValidationWithSetError();
      }
      this.changeDetection.detectChanges();
    }
  }

  onConfirm(){
    this._onSave();
    this.closeModal();
  }

  onUnlink() {
    this.showErrorMessage = false;
    this.ngEntityStore.dispatch(ConnectAccountActions.Unlink());
  }

  onIdentitySelect(selectedIdentityId: string) {
    this.selectedUserId = selectedIdentityId;
    this.validateModalData();
    this.changeDetection.detectChanges();
  }

  onLookupAccount() {
    if (!this.accountValidationWithSetError()) {
      this._searchAccount(this.accountNumberInput);
      this.lookupAccountNumber = this.accountNumberInput;
    }

    this.displayNameInput = '';
    this.selectedUserId = '';
    this.changeDetection.detectChanges();
  }

  validateAccountNumber() {
    this.isValidAccountNumber();
    this.validateModalData();
    this.changeDetection.detectChanges();
  }

  isValidAccountNumber(): boolean {
    const validAccountRegex = RegExp(/^(\d{10}|\d{16})$/);
    this.validAccountNumber = validAccountRegex.test(this.accountNumberInput);
    return this.validAccountNumber;
  }

  private accountValidationWithSetError(): boolean {
    this.showErrorMessage = !this.isValidAccountNumber() && !this.wasCanceled;

    if (this.showErrorMessage) {
      this.errorMessage = 'Please enter a valid account number (10 or 16 digits)';
    }
    return this.showErrorMessage;
  }

  private _searchAccount(accountNumber: string): void {
    if (!this.isValidAccountNumber()) {
      this.showErrorMessage = true;
      return;
    }

    this.isLookingUpUsers = true;
    this.users = [];
    this.options = this.getOptions();

    const getAccountUsersModel: GetChatAccountUsers = {
      chatId: this.selectedActiveChatId,
      accountNumber: accountNumber
    };

    this.ngEntityStore.dispatch(ConnectAccountActions.GetChatAccountUsers(getAccountUsersModel));
  }

  private getOptions(): SelectOption[]{
    const tempOptions = [];
    tempOptions.push({ label: 'Select identity', id: '' });
    if (this.users && this.users.length > 0) {
      this.users.forEach(u => {
        const fn = u.firstName ? u.firstName : '';
        const ln = u.lastName ? u.lastName : '';
        const opt = {
          label: `${fn} ${ln}`,
          id: u.userId
        };
        if (u.roles.includes(UserRole.Primary)) {
          opt.label = opt.label.concat(' (Primary)');
        }
        tempOptions.push(opt);
      });
    }

    tempOptions.push({ label: 'Other', id: 'Other' });

    return tempOptions;
  }

  private _onSave(): void {
    const updateAccountNumberArgs = new UpdateAccountNumber();
    if (this.selectedUserId === 'Other') {
      updateAccountNumberArgs.displayName = this.displayNameInput;
    }
    else {
      const selectedUser = this.users.find(u => u.userId === this.selectedUserId);
      if (selectedUser) {
        updateAccountNumberArgs.displayName = `${selectedUser.firstName} ${selectedUser.lastName}`;
      }
    }

    updateAccountNumberArgs.accountNumber = this.validatedAccountNumber;
    updateAccountNumberArgs.chatId = this.selectedActiveChatId;
    updateAccountNumberArgs.authenticated = false;
    updateAccountNumberArgs.manualConnect = true;
    this.ngEntityStore.dispatch(ChatActions.UpdateAccountNumber(updateAccountNumberArgs));
  }
}
