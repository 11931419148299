// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TimeHelperService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AppState,
  LogHelper, UiActions, AgentOperations, AgentActions
} from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { fromAgent, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { PreviousChatSummary } from 'projects/entities/src/lib/domain/models/previousChatSummary';

@Component({
  selector: 'merc-previous-summary-card',
  templateUrl: './previous-summary-card.component.html',
  styleUrls: ['./previous-summary-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviousSummaryCardComponent extends SubscriberComponent implements OnInit {
  active$: Observable<boolean>;
  chatSummaries: PreviousChatSummary[]; 
  filteredChatSummaries$: Observable<PreviousChatSummary[]>;
  filteredText: string;

  constructor(
    private store: Store<AppState>,
    private timeHelper: TimeHelperService,    
    private loggingFactory: LoggingFactoryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.active$ = this.store.select(fromUi.isPreviousChatSummaryCardOpen);
    this.filteredChatSummaries$ = this.store.select(fromAgent.getFilteredPreviousChatsummaries);
  }

  onInputChange(filteredText: string) {
    this.store.dispatch(AgentActions.UpdatePreviousChatSummaryFilterText({filteredText} )); 
  }

  public chatSummaryClicked(summary: PreviousChatSummary): void {   
    this.store.dispatch(UiActions.UpdatePreviousChatSummaryModal({chatId: summary.chatId, isOpen: true} ));      
    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.PreviousSummariesClicked, { chatId: summary.chatId });
  }

  public getFormattedTime(unix: number, timeZoneName: string): string {
    return this.timeHelper.formatDateTimeShortMonth(unix, timeZoneName);
  }

  public closeModal(): void {
    this.store.dispatch(UiActions.TogglePreviousChatSummariesPanel({isOpen: false} ));
  }
}
