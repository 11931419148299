<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-live-transcript-drawer-container" [ngClass]="{'collapsed': data.isTranscriptFeedCollapsed}"
  *ngIf="{
    isTranscriptFeedCollapsed: (isTranscriptFeedCollapsed$ | async)
  } as data">
  <div class="merc-live-transcript-drawer" *ngIf="!data.isTranscriptFeedCollapsed; else collapsed_live_transcript">
    <merc-live-transcript-header [shouldShowExpandCollapse]="true"></merc-live-transcript-header>
    <merc-live-transcript class="merc-live-transcript-wrapper"></merc-live-transcript>
  </div>

  <ng-template #collapsed_live_transcript>
    <div class="merc-live-transcript-drawer merc-live-transcript-drawer--collapsed">
      <cee-button size="link" type="icon" (buttonClick)="toggleTransferFeedCollapse(data.isTranscriptFeedCollapsed)"
        customClass="merc-user-voice-button merc-collapse-expand-button" dataQa="expand-live-transcript">
        <svg class="cee-icon collapsed">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-next"></use>
        </svg>
      </cee-button>
      <cee-button type="icon" customClass="merc-user-voice-button merc-transcript-expand-button"
      (buttonClick)="toggleTransferFeedCollapse(data.isTranscriptFeedCollapsed, true)" dataQa="expand-live-transcript-secondary">
        <svg class="cee-icon">
          <use href="/assets/img/svg-sprite.svg#icon-message"></use>
        </svg> Transcript
      </cee-button>
    </div>
  </ng-template>
</div>
