<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-convo-card-list">
  <ng-container *ngIf="hasAbandonedChatAutomation$ | async; else noAca">
    <ng-template  *ngTemplateOutlet="requestChatToggle"></ng-template>
    <ng-container *ngIf="(hasAnyContinuedChats$ | async)">
      <merc-cust-card-list listTitle="Continued conversations">
        <ng-container *ngIf="(continuedChats$ | async)?.length; else noContinuedChats">
            <merc-convo-card
              *ngFor="let chat of continuedChats$ | async; trackBy: trackByChatId"
              [chat]="chat"
              [hasAbandonedChatAutomation]="hasAbandonedChatAutomation$ | async"
              [hasContextMenu]="hasConvoCardContextMenu$ | async"
              test-target="continuedChats"
            >
            </merc-convo-card>
          </ng-container>
        </merc-cust-card-list>
        <ng-template #noContinuedChats>
          <p class="merc-convo-card-list--message">
            <i test-target="noContinuedChats">You have no continued conversations.</i>
          </p>
        </ng-template>
    </ng-container>
    <merc-cust-card-list listTitle="New conversations" test-target="aca">
      <ng-container *ngIf="(newChats$ | async)?.length; else noNewChats">
        <merc-convo-card
          test-target="acaNewChat"
          *ngFor="let chat of newChats$ | async; trackBy: trackByChatId"
          [chat]="chat"
          [hasAbandonedChatAutomation]="hasAbandonedChatAutomation$ | async"
          [hasContextMenu]="hasConvoCardContextMenu$ | async"
        >
        </merc-convo-card>
      </ng-container>
      <ng-template #noNewChats>
        <p class="merc-convo-card-list--message">
          <i test-target="noNewChats">You have no new conversations.</i>
        </p>
      </ng-template>
    </merc-cust-card-list>
    <merc-cust-card-list listTitle="Inactive conversations" dataQaTitle="inactive-conversations">
      <ng-container
        *ngIf="(inactiveChats$ | async)?.length; else noInactiveChats"
      >
        <merc-convo-card
          *ngFor="let chat of inactiveChats$ | async; trackBy: trackByChatId"
          [chat]="chat"
          [hasAbandonedChatAutomation]="hasAbandonedChatAutomation$ | async"
          [hasContextMenu]="hasConvoCardContextMenu$ | async"
          test-target="acaInactiveChat"
        >
        </merc-convo-card>
      </ng-container>
      <ng-template #noInactiveChats>
        <p class="merc-convo-card-list--message">
          <i test-target="noInactiveChats" data-qa="no-inactive-chats"
            >You have no inactive conversations.</i
          >
        </p>
      </ng-template>
    </merc-cust-card-list>
  </ng-container>

  <ng-template #noAca>
    <merc-cust-card-list test-target="no-aca">
      <ng-template *ngTemplateOutlet="requestChatToggle"></ng-template>
      <ng-container *ngIf="(chats$ | async)?.length; else noConvos">
        <merc-convo-card
          *ngFor="let chat of chats$ | async; trackBy: trackByChatId"
          [chat]="chat"
          [hasContextMenu]="hasConvoCardContextMenu$ | async"
          [hasAbandonedChatAutomation]="hasAbandonedChatAutomation$ | async"
        >
        </merc-convo-card>
      </ng-container>
      <ng-template #noConvos>
        <p>
          <i class="cee-accessibly-hidden" test-target="noChats"
            >No active conversations.</i
          >
        </p>
      </ng-template>
    </merc-cust-card-list>
  </ng-template>
  <merc-inline-toast></merc-inline-toast>
  <div class="merc-convo-list-footer">
    <div class="merc-sound-bar-wrapper">
      <merc-sound-bar
        [volume]="(agentCustomSettings$ | async)?.soundVolume"
        [isMuted]="(agentCustomSettings$ | async)?.soundMuteAll"
        [settings]="agentCustomSettings$ | async"
        (volumeChanged)="onVolumeChange($event)"
        (volumeMuted)="onMuted($event)"
      ></merc-sound-bar>
    </div>

    <div class="merc-chat-id" *ngIf="(chatId$ | async) as chatId">   
      <div class="merc-chat-footer--chat-id">
        <cee-button dataQa='copyId' type="icon"
          [tooltipText]="copyTooltipText"
          tooltipId="copy-chat-id"
          tooltipDirection="top"
          tooltipAppearanceDelayMs="0"
          (buttonClick)="copyToClipboard(chatId)"
          tooltipPersistDirective="true"
          (mouseout)="resetCopyText()">
        <span>Chat ID: {{ chatId }} </span>
        <svg class="cee-icon">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-copy-v2"></use>
        </svg>
        </cee-button>
      </div>
    </div>
  </div>
</div>

<ng-template #requestChatToggle>
  <div
    *ngIf="(maxExtraChats$ | async) > 0"
    class="merc-request-chats"
  >
    <div class="cee-form-row--group">
      <input
        id="request-more-chats"
        type="checkbox"
        name="request-chats"
        value="option-one"
        class="cee-switch"
        [disabled]="(maxChats$ | async) > (chats$ | async)?.length"
        (change)="toggleRequestChats($event)"
        [checked]="extraChatsRequested$ | async"
      />
      <label class="cee-switch-label" for="request-more-chats"
        >Request more chats</label
      >
    </div>
  </div>
</ng-template>
