// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpHandler, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggingFactoryService, RestApiService } from '@cxt-cee-chat/merc-ng-core';
import { ItgDetails } from '../domain/models/itg-details';
import { GetApplicationConfigurationByUser } from '../domain/models/requests/getApplicationConfigurationByUser';
import { ApplicationConfigurationResponse } from '../domain/models/responses/application-configuration-response';
import { NUANCE_BEARER_TOKEN_HANDLER } from './nuance-bearer-token-http-handler';

@Injectable({
  providedIn: 'root'
})
export class AppConfigApiService extends RestApiService {
  constructor(
    @Inject(NUANCE_BEARER_TOKEN_HANDLER) httpHandler: HttpHandler,
    loggingService: LoggingFactoryService) {
      super(loggingService, httpHandler);
  }

  public getApplicationConfigurationByUser(model: GetApplicationConfigurationByUser): Promise<HttpResponse<ApplicationConfigurationResponse>>
  {
    return this.post('configuration/getApplicationConfigurations', model);
  }

  public itgList(): Promise<HttpResponse<ItgDetails[]>>
  {
    return this.get('configuration/itgList');
  }
}
