// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ChatActions } from '../actions';
import { fromChat } from '../selectors';
import { AppState } from '../state';
import { ChatUiPersisterService } from './chat-ui-persister.service';
import * as ChatUiActions from './chat-ui.actions';
import { LogHelper } from '../../utils/logHelper';
import { AgentOperations, ChatOperations } from '../../constants/event-logs.constants';
import { DetailsSelectedTab } from '../models/enums';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class ChatUiEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: ChatUiPersisterService,
    private loggingFactory: LoggingFactoryService,
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatUiActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? ChatUiActions.hydrateSuccess({ state })
          : ChatUiActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(
    () =>
      this.action.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
        ofType(ChatUiActions.hydrateSuccess, ChatUiActions.hydrateFailure),
        switchMap(() => this.store.pipe(select(fromChat.selectChatUiState))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(state => {
          this.persisterService.storeState(state);
        })
      ),
    { dispatch: false }
  );

  updateActiveEngagements$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.updateActiveEngagements),
      concatLatestFrom(() =>
        this.store.select(fromChat.selectOrphanedChatUiChatIds)
      ),
      filter(([, chatIds]) => chatIds.length > 0),
      map(([, chatIds]) => {
        return ChatUiActions.chatsRemoved({ chatIds });
      })
    )
  );

  chatSelected$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatUiActions.selectChat, ChatUiActions.agentSelectedChat),
      tap(({chatId}) => {
        this.loggingFactory.setPageView(chatId ?? '');
      })
    ),
    {dispatch: false}
  );

  logKnowledgeFocused$ = createEffect(() =>
  this.action.pipe(
    ofType(ChatUiActions.updateDetailsPanelTabSelected),
    concatLatestFrom(({chatId}) => [
      this.store.select(fromChat.getChat(chatId))]
    ),
    tap(([{chatId, detailsPanelTabSelected}, chat]) => {
      if (detailsPanelTabSelected === DetailsSelectedTab.Knowledge){
        const logDimensions = {
        ...(chatId != null && { engagementId: chatId }),
        ...(chat?.isAsync && { conversationId: chat.conversationId })
        };
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.KnowledgeFocused, logDimensions);
      }
    })
  ),
  {dispatch: false}
  );

  messageInputUndoClicked$ = createEffect(() => 
    this.action.pipe(
      ofType(ChatUiActions.messageInputUndoClicked),
      concatLatestFrom(({chatId}) => this.store.select(fromChat.getChat(chatId))),
      tap(([{isEnhance}, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.MessageEditorUndoClicked, chat, { method: isEnhance ? 'Enhance' : 'AskMeAnything' });
      })
    ),
    {dispatch: false}
  );

  ngrxOnInitEffects(): Action {
    return ChatUiActions.hydrate();
  }
}
