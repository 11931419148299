<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-chat">
  <div id="merc-chat-window-wrapper" *ngIf="{
    isTranslatedChat: isTranslatedChat$ | async,
    botConversationSummary: botConversationSummary$ | async,
    asyncEngagementsSummaryPayload: asyncEngagementsSummaryPayload$ | async
  } as data">
    <cee-button id="merc-jump-to-latest" (click)="jumpToLatest()" [hidden]="!(showJumpToLatestButton$ | async)" dataQa="jump-to-latest">
      <svg class="cee-icon">
        <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-arrow'"></use>
      </svg>
      Jump to Latest
    </cee-button>

    <div id="merc-chat-window" class="animate-scroll" #scrollWindow>
      <div [attr.id]="'chat' + chat.chatId">

        <!--chat loading-->
        <ng-container *ngIf="chat.isLoading || (isLoadingPriorEngagements$ | async)">
          <div id="chat-window-loading" class="cee-chat-window--loading">
            <cee-loading-indicator [hideAnimation]="hideAnimation$ | async" [loadingText]="'Loading chat history'"></cee-loading-indicator>
            <p class="cee-body">Loading chat history</p>
          </div>
        </ng-container>
        <!--end chat loading-->

        <!--prior engagements-->
        <div id="merc-load-more-conversations" *ngIf="(isLazyLoadEnabled$ | async) && !(isLoadingPriorEngagements$ | async) && (chat.authenticated || chat.isAccountConnected)">
          <cee-button id="load-more-conversations-button" type="secondary" *ngIf="!(allEngagementsLoaded$ | async)" (click)="loadNextEngagements()">
            Load more conversations
          </cee-button>
          <div id="all-engagements-loaded" *ngIf="(allEngagementsLoaded$ | async)" class="cee-h2 merc-engagements-loaded">
            You're all caught up!
          </div>
        </div>

        <ng-container
          *ngFor="let transcript of (chat.engagementHistory | sortBy:'asc':'createDate'); index as i; trackBy: trackByTranscriptId">
          <merc-convo-prior-engagement #priorEngagement [attr.id]="transcript.id" [transcript]="transcript"
            [selectedActiveChat]="chat">
          </merc-convo-prior-engagement>
        </ng-container>
        <!-- end prior engagements-->

        <!-- Async Engagements -->
        <ng-container *ngIf="chat.isAsync || chat.isReconnectEngagement">
          <ng-container
            *ngFor="let engagement of (chat.asyncEngagements | sortAsyncEngagement ); trackBy: trackByEngagementId">
            <merc-convo-async-engagement #asyncEngagement [attr.id]="engagement.engagementId" [engagement]="engagement" [selectedActiveChat]="chat">
            </merc-convo-async-engagement>
          </ng-container>
        </ng-container>
        <!-- end Async Engagements -->

        <ng-container>
          <div #currentChat>
            <cee-accordion disabled="{{true}}" titleText="No topic identified"
              [dateTime]="chat.startTimestamp | formatDateShortMonth: chat.timeZone"
              [channels]="chat.channel?.type | formatChannels" type="conversation">

              <div
                *ngFor="let message of chatMessages$ | async as messages; let messageIndex = index; trackBy: trackBySelectedChatCurrentMessage"
                #messages
                [attr.id]="message.messageId"
                [ngClass]="{'merc-system-message': isSystemSenderType(message.sender)}"
                 >
                 
                <ng-container [ngSwitch]="message.type">
                  <ng-container *ngSwitchCase="messageType.TransferCard">
                    <div class="merc-info-block" x-test-target="transfer-card">
                      <cee-information-blocks [title]="message.title"
                        dataQaTitle="transfer-title"
                        [colorId]="chat.color.id"
                        type="info"
                        [infoList]="message | formatTransferData: chat.users : chat.queueName"
                        [shouldShowTranslationText]="data.isTranslatedChat"
                        dataQaContent="transfer-content"
                        (translateNotificationClick)="translateNotificationMessageClicked()">
                      </cee-information-blocks>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="messageType.TranslationConfig">
                    <div class="merc-info-block cee-info-block merc-translation-config-block" *ngIf="isTranslatedChat$ | async">
                      <div class="cee-form-row--group">
                        <input
                          id="translate-conversation-toggle"
                          type="checkbox"
                          name="toggle-translation"
                          class="cee-switch"
                          (change)="onTranslationToggle($event)"
                          [checked]="isChatTranslationConfigEnabled$ | async"
                          data-qa="translate-conversation-toggle"
                        />
                        <label class="cee-switch-label" for="translate-conversation-toggle">
                          <svg class="cee-icon">
                            <use xlink:href="/assets/img/svg-sprite.svg#icon-language-globe"></use>
                          </svg>
                          Automatically translate this conversation
                        </label>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="messageType.SummaryCard">
                    <ng-container
                    *ngIf="chat.contactReason && !(chat.asyncEngagements && chat.asyncEngagements.length > 0)">
                      <div class="merc-info-block" x-test-target="contact-reason">
                        <cee-information-blocks
                        [title]="'New Conversation'"
                        dataQaTitle="summary-title"
                        type="info"
                        [infoList]="[{label: 'Contact Reason', value: chat.contactReason}]"
                        [shouldShowTranslationText]="data.isTranslatedChat"
                        dataQaContent="summary-content"
                        (translateNotificationClick)="translateNotificationMessageClicked()">
                        </cee-information-blocks>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="data.botConversationSummary && (showBotConversationSummary$ | async)">
                      <div class="merc-info-block merc-info-block--summary" x-test-target="bot-summary">
                        <cee-information-blocks 
                          [title]="'Xfinity Assistant Summary'" 
                          dataQaTitle="bot-summary" 
                          type="content"
                          dataQaContent="bot-summary-content" 
                          [colorId]="chat.color.id" 
                          [hideAnimation]="hideAnimation$ | async">
                            <div cee-info-block-content class="merc-info-block--summary-content">
                              <p>{{data.botConversationSummary}}</p>
                            </div>
                        </cee-information-blocks>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="(chat.isAsync || chat.isReconnectEngagement) && chat.asyncEngagements?.length > 0">
                      <div class="merc-info-block merc-info-block--reconnect" x-test-target="async-engagements"> 
                        <cee-information-blocks
                        [title]="getAsyncSummaryTitle((lastAsyncMessageSenderId$ | async))"
                        [type]="isAsyncSummaryLoading(chat.unresolvedContactReason, chat.asyncEngagementsSummaryData) ? 'loading' : 'content'"
                        [hideAnimation]="hideAnimation$ | async"
                        dataQaTitle="reconnected-title"
                        [colorId]="chat.color.id"
                        dataQaContent="reconnect-content">
                          <div cee-info-block-content 
                            class="merc-info-block--reconnect-content">
                            <p>{{getSummaryText(chat.unresolvedContactReason, data.asyncEngagementsSummaryPayload, chat.contactReason)}}</p>
                            <i>{{getLastAsyncTimeAgo(chat.startTimestamp, (lastAsyncMessageTimestamp$ | async))}}</i>
                            <ng-container *ngIf="chat.unresolvedContactReason || data.asyncEngagementsSummaryPayload">
                              <hr/>
                              <div class="merc-info-block--reconnect-summary-rating" *ngIf="chat.asyncEngagementsSummaryData?.rated; else rateSummary">
                                <span *ngIf="chat.asyncEngagementsSummaryData.isPositiveRating">Thanks for your feedback!</span>
                                <span *ngIf="!chat.asyncEngagementsSummaryData.isPositiveRating">Your feedback helps up improve.</span>
                              </div>
                              <ng-template #rateSummary>
                                <cee-thumb-rating
                                  [isV2]="false"
                                  ratingLabel="Is this summary helpful?"
                                  (positiveRating)="positiveSummaryRating()"
                                  (negativeRating)="negativeSummaryRating()">
                                </cee-thumb-rating>
                              </ng-template>
                            </ng-container>
                          </div>
                          <div [cee-info-block-menu]
                            class="merc-info-block-menu">
                            <ng-container *ngIf="chat.isAsync && chat.asyncEngagements.length !== 1; else jumpToStartButton">
                              <cee-dropdown-menu [hideLabel]="false" labelText="Jump to" #asyncJump>
                                <ng-container
                                  *ngFor="let asyncEngagement of (chat.asyncEngagements | sortAsyncEngagement); let i = index">
                                  <cee-button type="dropdown-item" size="sm" role="option"
                                    (click)="asyncEngagementClicked(asyncEngagement)">
                                    {{i === 0 ? 'Start of conversation' : (asyncEngagement.createDate |
                                    formatTimeSingleHourDigitShortMonthParen:chat.timeZone )}}
                                  </cee-button>
                                </ng-container>
                              </cee-dropdown-menu>
                            </ng-container>
                            <ng-template #jumpToStartButton>
                              <cee-button type="link" size="sm"
                                (click)="asyncEngagementClicked(chat.asyncEngagements[0], false)">
                                Jump to start
                              </cee-button>
                            </ng-template>
                          </div>
                        </cee-information-blocks>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <merc-convo-message
                      [chatMessage]="message"
                      [chatId]="chat.chatId"
                      [channelType]="chat.channel?.type"
                      [colorId]="chat.color.id"
                      [timeZone]="chat.timeZone"
                      [previousMessage]="messages[messageIndex - 1]"
                      [participants]="chat.users"
                      [customerDetails]="chat.customerDetails">
                    </merc-convo-message>
                  </ng-container>
                </ng-container>
              </div>
            </cee-accordion>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="merc-chat-window-top">
    <merc-convo-pinned-messages
      [chatId]="chat.chatId"
      (jumpToMessage)="onJumpToMessage($event)"
    ></merc-convo-pinned-messages>
    <ng-container *ngIf="bannerDetails$ | async as bannerDetails">
      <cee-banner [type]="bannerDetails.type" [active]="bannerDetails.active" [dismissable]="bannerDetails.dismissible" (bannerDismiss)="onBannerDismiss()">
        {{ bannerDetails.text }}
      </cee-banner>
    </ng-container>
  </div>
  <div class="merc-convo-typing" [ngClass]="{'hide': !(selectedChatCustomerIsTyping$ | async)}">
    {{customerName}} is typing<span [hidden]="(hideAnimation$ | async)">...</span>
  </div>
  <div>
  <!-- XA suggestions -->
  <div class="merc-suggestions">
    <merc-convo-suggestions [chatId]="chat.chatId"></merc-convo-suggestions>
    <merc-convo-activity-suggestion *ngIf="(xaSolvesFeatureFlag$ | async)" [chatId]="chat.chatId"></merc-convo-activity-suggestion>
    <merc-convo-activity-status [chatId]="chat.chatId"></merc-convo-activity-status>
  </div>
    <merc-convo-message-input
      [chatId]="chat.chatId"
      [color]="chat.color"
      [conversationId]="chat.conversationId"
      [messagingEnabled]="chat.messagingEnabled"
      [scriptTreeId]="chat.scriptTreeId"
    ></merc-convo-message-input>
  </div>
</div>
