// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Script } from 'projects/entities/src/lib/domain/scripts';

export class ScriptsContextMenu {
    script: Script;
    clientX: number;
    clientY: number;
    open: boolean;
}
