// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { CallState, HomeHeaderErrorState, SingleColumnHomeDisplayMode } from '../../models/voice/enums';
import { State } from './ui.reducer';

export const hydrate = createAction('[VoiceUi] Hydrate');

export const hydrateSuccess = createAction(
  '[VoiceUi] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[VoiceUi] Hydrate Failure');

export const ToggleHomeDisplayMode = createAction(
  '[VoiceUi] Toggle Home Display Mode',
  props<{ displayMode: SingleColumnHomeDisplayMode }>()
);

export const ToggleLiveTranscriptCollapse = createAction(
  '[VoiceUi] Live Transcript Collapse Toggle',
  props<{ isCollapsed: boolean, isBottomButton?: boolean }>()
);

export const ToggleShowAgentTranscript = createAction(
  '[VoiceUi] Toggle Show Agent Transcript',
  props<{ isEnabled: boolean }>()
);

export const UpdateHeaderErrorState = createAction(
  '[VoiceUi] Update Header Error State',
  props<{ errorState: HomeHeaderErrorState }>()
);

export const UpdateCallState = createAction(
  '[VoiceUi] Update Call State',
  props<{ callState: CallState }>()
);

export const JumpToLatestClicked = createAction(
  '[VoiceUi] Jump to Latest',
  props<{ source: string }>()
);
