// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromCxGptResponses from './cx-gpt-responses.reducer';

@Injectable({
  providedIn: 'root'
})
export class CxGptResponsesPersisterService extends StatePersister<fromCxGptResponses.State> {
  constructor() {
    super(localStorage, fromCxGptResponses.featureKey);
  }
}
