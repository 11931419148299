// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {logAgentAction, logChatAction, logCurrentCallAction, logSelectedChatAction} from './log-actions.actions';
import {tap} from 'rxjs/operators';
import {LogHelper} from '../utils/logHelper';
import {LoggingFactoryService} from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { AppState } from './state';
import { fromCall, fromChat } from './selectors';
import { LogActions } from './actions';


@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Log {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private loggingFactory: LoggingFactoryService) {}

  logChatEvent$ = createEffect(() => this.actions$.pipe(
    ofType(logChatAction),
    tap(
      ({logPayload}) => {
         LogHelper.logChatEvent(this.loggingFactory, logPayload.operationName, logPayload.chat, logPayload.data);
      }
    )
  ),
    { dispatch: false }
  );

  logSelectedChatEvent$ = createEffect(() => this.actions$.pipe(
    ofType(logSelectedChatAction),
    concatLatestFrom(_action => [
      this.store.select(fromChat.getSelectedChat)
    ]),
    tap(
      ([{logPayload}, selectedChat]) => {
         LogHelper.logChatEvent(this.loggingFactory, logPayload.operationName, selectedChat, logPayload.data);
      }
    )
  ),
    { dispatch: false }
  );

  logAgentEvent$ = createEffect(() => this.actions$.pipe(
    ofType(logAgentAction),
    tap(
      ({logPayload}) => {
         LogHelper.logAgentEvents(this.loggingFactory, logPayload.operationName, logPayload.data);
      }
    )
  ),
    { dispatch: false }
  );

  logDebug$ =
    createEffect(() => this.actions$.pipe(
      ofType(LogActions.logDebug),
      tap(
        ({logPayload}) => {
          LogHelper.logDebug(this.loggingFactory, logPayload.operationName, logPayload.data);
        }
      )
    ),
    { dispatch: false }
  );


  logCurrentCallEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logCurrentCallAction),
      concatLatestFrom(() => this.store.select(fromCall.getCurrentCall)),
      tap(
        ([{logPayload}, currentCall]) => {
          LogHelper.logCallEvent(this.loggingFactory, logPayload.operationName, currentCall, logPayload.data);
        }
      )
    ),
    { dispatch: false }
  );
}
