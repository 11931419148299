// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { PriorEngagement } from './priorEngagement';
import { ChatInteraction } from './chatInteraction';
import { XaSuggestionHeading } from './suggestion';
import { Chunk } from 'highlight-words-core';
import { ChatMessageTranslationData } from './chatMessageTranslationData';

export class ChatTranscript extends PriorEngagement {
  id: string;
  transcripts: EngagementTranscript[];
}

export class EngagementTranscript extends PriorEngagement {
    engagementId: string;
    interactions: ChatTranscriptInteraction[];
    escalated: boolean;
    transferred: boolean;
    xaTranscriptEndTimestamp: number;
}

export class AsyncEngagementTranscript extends EngagementTranscript {
  conversationId: string;
  unresolvedContactReason: string;
}

export class ChatTranscriptInteraction extends ChatInteraction {
    senderId: string;
    maskedChunks?: Chunk[];
    translationData?: ChatMessageTranslationData;
}

export class ChatTranscriptMessage extends ChatTranscriptInteraction {
    message: string;
}

export class ChatTranscriptAttachment extends ChatTranscriptInteraction{
    retrievalUrl: string;
    fileName: string;
}

export class ChatTranscriptXaSuggestionMessage extends ChatTranscriptMessage {
  queryId: string;
  xaMessage: XaSuggestionHeading;
}
