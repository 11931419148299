// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AccessToken } from '@cxt-cee-chat/merc-ng-core';
import { createAction, props } from '@ngrx/store';
import { createPayloadAction } from './action-adapters';
import { State } from './auth.reducer';
import { LoggedOutMethod } from './models/enums';
import { GetAccessToken } from './models/requests/getAccessToken';
import { SessionTimeoutInformation } from './models/sessionTimeoutInformation';
import { UnauthorizedVerifySession as UnauthorizedVerifySessionModel } from './models/unauthorizedVerifySession';
import { HomeHeaderErrorState } from './models/voice/enums';

export const formAuthenticate = createPayloadAction<GetAccessToken>(
  '[Agent Authentication] Form Authenticate'
);

export const formAuthenticated = createPayloadAction<AccessToken>(
  '[Agent Authentication] Form Authenticated'
);

export const hydrate = createAction('[Agent Authentication] Hydrate');

export const hydrateSuccess = createAction(
  '[Agent Authentication] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[Agent Authentication] Hydrate Failure');

export const logIn = createAction('[Agent Authentication] Log In');

export const reLogIn = createAction('[Agent Authentication] Re-Log In');

export const verifySession = createAction(
  '[Agent Authentication] Verify Session'
);

export const unauthorizedVerifySession = createPayloadAction<
  UnauthorizedVerifySessionModel
>('[Agent Authentication] Unauthorized Verify Session');

export const loggedIn = createAction('[Agent Authentication] Logged In');

export const reLoggedIn = createAction('[Agent Authentication] Re-Logged In');

export const logOut = createPayloadAction<LoggedOutMethod>('[Agent Authentication] Log Out');

export const loggedOut = createPayloadAction<LoggedOutMethod>('[Agent Authentication] Logged Out');

export const setContinuedSession = createAction(
  '[Agent Authentication] Set Continued Session',
  props<{ continuedSession: boolean}>()
);

export const verifyingSession = createAction(
  '[Agent Authentication] Verifying Session',
  props<{ verifyingSession: boolean }>()
);

export const clearSession = createAction(
  '[Agent Authentication] Clear Session'
);

export const formAuthentication = createAction(
  '[Agent Authentication] FormAuthentication Success',
  props<{ success: boolean }>()
);

export const lockout = createAction('[Agent Authentication] Lockout', props<{ sessionTimeoutInformation: SessionTimeoutInformation }>());

export const lockoutPersistSession = createAction('[Agent Authentication] Lockout Persist Session');

export const createVoiceSession = createAction('[Agent Authentication] Create Voice Session',
  props<{ sessionId: string, errorState: HomeHeaderErrorState }>()
);

export const oneCtiRegistered = createAction(
  '[Agent Authentication] One Cti Registered',
  props<{ avayaAgentId: string, phoneExtension: string }>()
);
