// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { VoiceHubService } from '../../../services/voice-hub.service';
import { CallActions } from '../../actions';
import { AppState } from '../../state';
import { fromAgentAuth, fromCall, fromSettings } from '../../selectors';
import { MercuryCtiChatService } from 'src/app/services/mercury-cti-chat.service';
import { CallSummaryDisplay, FeedContentType, RatingState, RecommendedActionType } from '../../models/voice/enums';
import { CtiLaunchItgRequest } from '../../models/voice/requests/cti-launch-itg-request';
import { VoiceHelper } from '../../../utils/voice-helper';
import { LogHelper } from '../../../utils/logHelper';
import { AgentOperations, CallOperations } from '../../../constants/event-logs.constants';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { CallPersisterService } from './call-persister.service';
import { SenderType } from '../../models';
import { CallHeaderService } from 'src/app/components/voice/call-header/call-header.service';
import { MercuryNotificationService } from 'src/app/services/mercury-notification.service';
import { CallNotFoundLog } from '../../models/voice/logDimensions/call-not-found-log';


/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Call {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private voiceHubService: VoiceHubService,
    private ctiService: MercuryCtiChatService,
    private loggingFactory: LoggingFactoryService,
    private persisterService: CallPersisterService,
    private callHeaderService: CallHeaderService,
    private notificationService: MercuryNotificationService
  ) { }

  hydrate$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? CallActions.hydrateSuccess({ state })
          : CallActions.hydrateFailure();
      })
    )
  );

  saveInStorage$ = createEffect(() =>
    this.actions.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
      ofType(CallActions.hydrateSuccess, CallActions.hydrateFailure),
      switchMap(() => this.store.pipe(select(fromCall.selectCallFeature))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(state => {
        this.persisterService.storeState(state);
      })
    ),
    { dispatch: false }
  );

  sendNewCallCtiResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.newCallReceived),
      concatLatestFrom(({ call }) => [
        this.store.pipe(select(fromCall.getCall(call.callId))),
        this.store.pipe(select(fromCall.getCurrentCallId)),
        this.store.pipe(select(fromSettings.getFeatureFlags))
      ]),
      tap(([{ call }, storedCall, activeCallId, featureFlags]) => {
        const isNewCallSuccessful = !!storedCall;

        if (isNewCallSuccessful) {
          if (storedCall.callId === activeCallId) {
            this.callHeaderService.startCallTimer(storedCall);
          }

          const logDimensions = {
            activeFeatureFlags: featureFlags,
            activeCallId,
            uui: call.uui,
            customerContext: call.customerContext
          };
          LogHelper.logCallEvent(this.loggingFactory, CallOperations.CallStart, storedCall, logDimensions);
        }
      })
    ),
    { dispatch: false }
  );

  handleEndCall$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.callEnded),
      concatLatestFrom(({ callId }) => [
        this.store.select(fromCall.getCall(callId)),
        this.store.select(fromCall.getCurrentCallId),
        this.store.select(fromCall.getCurrentCall)
      ]),
      tap(([{ source }, endedCall, nextCallId, nextCall]) => {
        this.callHeaderService.resetCallTimer();

        if (nextCallId) {
          this.callHeaderService.startCallTimer(nextCall);
        }

        const logDimensions = {
          nextCallId,
          source
        };
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.CloseSession, endedCall, logDimensions);
      })
    ),
    { dispatch: false }
  );

  getCallSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.getCallSummary),
      concatLatestFrom(({ callId }) => [
        this.store.select(fromCall.getCall(callId)),
        this.store.select(fromAgentAuth.getVoiceSessionId),
        this.store.select(fromAgentAuth.getPhoneExtension),
        this.store.select(fromCall.getCallSummary),
        this.store.select(fromCall.getCallSummaryInProgressTraceId)
      ]),
      tap(([{ callId, traceId, isRetry }, call, sessionId, phoneExtension, callSummary, callSummaryInProgressTraceId]) => {
        if (!call) {
          this.logCallNotFound({ callId, action: CallActions.getCallSummary.type });
          return;
        }
        if (callSummaryInProgressTraceId !== traceId || (callSummary && callSummary.error === false)){
          return;
        }
        if (isRetry){
          LogHelper.logCallEvent(this.loggingFactory, CallOperations.RetryGetCallSummary, call);
        }
        this.voiceHubService.getCallSummary({ phoneExtension, sessionId, callId }).then(({ success }) => {
          if (!success) {
            this.store.dispatch(CallActions.callSummaryError({ callId }));
          }
        }).catch(() => {
          this.store.dispatch(CallActions.callSummaryError({ callId }));
        });
      })
    ),
    { dispatch: false }
  );

  callSummaryError$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.callSummaryError),
      concatLatestFrom(({ callId }) => this.store.select(fromCall.getCall(callId))),
      tap(([, call]) => {
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.CallSummaryError, call);
      })
    ),
    { dispatch: false }
  );

  launchItgError$ = createEffect(() =>
  this.actions.pipe(
    ofType(CallActions.callLaunchItgError),
    concatLatestFrom(({ requestId }) => [
      this.store.select(fromCall.getCurrentCall),
      this.store.select(fromCall.getRecommendedActionByRequestId(requestId)),
    ]),
    filter(([, , recommendedAction]) => !!recommendedAction),
    tap(([{ requestId }, call]) => {
      LogHelper.logCallEvent(this.loggingFactory, CallOperations.CallLaunchItgError, call, { requestId });
    })
  ),
  { dispatch: false }
);

  transcriptRecommendedActionReceived$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.transcriptRecommendedActionReceived),
      concatLatestFrom(({callId}) => [
        this.store.select(fromCall.getCurrentCall),
        this.store.select(fromCall.getCall(callId))
      ]),
      tap(([{ transcriptRecommendedAction, callId }, currentCall, call]) => {
        const isRecommendedActionAdded = call?.feedContent?.find(fc => fc.id === transcriptRecommendedAction.id);
        if (currentCall?.callId === callId && isRecommendedActionAdded) {
          this.notificationService.triggerRecommendedActionNotification(transcriptRecommendedAction, callId);
        }

        const isAuthenticatedCall = VoiceHelper.isAuthenticatedCall(call?.customerInfo);
        const { type, intent, message } = transcriptRecommendedAction;
        const logDimensions = {
          type: VoiceHelper.getRecommendedActionLoggingTypeName(type),
          utterance: message ?? 'unsupplied',
          intent,
          isSuppressed: !isAuthenticatedCall
        };

        LogHelper.logCallEvent(this.loggingFactory, CallOperations.SuggestionOffered, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  handleRecommendedActionControlClick$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.recommendedActionControlClicked),
      concatLatestFrom(() => this.store.select(fromCall.getCurrentCall)),
      tap(([{ recommendedAction, requestId }, call]) => {
        switch (recommendedAction.actionType) {
          case RecommendedActionType.Itg: {
            const dataString = JSON.stringify({ itgId: recommendedAction.intent });
            this.ctiService.sendCtiCallMessage(new CtiLaunchItgRequest(call.ucid, btoa(dataString), requestId, call.uui, call.customerInfo?.accountNumber), call);
            const logDimensions = {
              intent: recommendedAction.intent,
              requestId: requestId
            };
            LogHelper.logCallEvent(this.loggingFactory, CallOperations.ITGLaunched, call, logDimensions);
          }
        }
      })
    ),
    { dispatch: false }
  );

  logRecommendedActionRatingFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.updateRecommendedActionRating),
      concatLatestFrom(() => [
        this.store.select(fromCall.getCurrentCall)
      ]),
      tap(([{ recommendedAction, rating }, call]) => {
        switch (recommendedAction.type) {
          case FeedContentType.ItgRecommendation: {
            const logDimensions = {
              isPositive: rating === RatingState.Positive,
              intent: recommendedAction.intent
            };
            LogHelper.logCallEvent(this.loggingFactory, CallOperations.ITGFeedback, call, logDimensions);
          }
        }
      })
    ),
    { dispatch: false }
  );

  updateCallSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.updateCallSummary),
      concatLatestFrom(({ callId }) => [
        this.store.select(fromCall.getCall(callId)),
        this.store.select(fromCall.getCallSummaryDisplay(callId))
      ]),
      tap(([{ issue, resolution, error }, call, display]) => {
        const trigger = display === CallSummaryDisplay.Modal ? 'button' : 'external';
        const logDimensions = {
          issue,
          resolution,
          error,
          trigger
        };
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.CallSummary, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  rateSessionSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.rateSessionSummary),
      concatLatestFrom(() => this.store.select(fromCall.getCurrentCall)),
      tap(([{ isPositiveRating: isPositive }, call]) => {
        const logDimensions = {
          isPositive
        };
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.SummaryFeedback, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  collapseSessionSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.toggleSessionSummaryCollapse),
      concatLatestFrom(() => this.store.select(fromCall.getCurrentCall)),
      tap(([{ isCollapsed }, call]) => {
        const logDimensions = {
          isCollapsed
        };
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.CollapseSummary, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  cancelSessionSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.updateShowSessionSummaryModal),
      concatLatestFrom(() => this.store.select(fromCall.getCurrentCall)),
      tap(([{ source }, call]) => {
        const logDimensions = {
          source
        };
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.CancelSummaryModal, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logToggleHighlightTranscriptEntry$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        CallActions.highlightTranscriptMessage,
        CallActions.unhighlightTranscriptMessage,
        CallActions.unhighlightTranscriptMessageFromHighlightFeed
        ),
      concatLatestFrom(() => [
        this.store.select(fromCall.getCurrentCall)
      ]),
      tap(([{ type, sender }, call]) => {
        const logDimensions = {
          wasHighlighted: type === CallActions.highlightTranscriptMessage.type,
          sender: sender === SenderType.FixAgent ? 'agent' : 'customer',
          source: type === CallActions.unhighlightTranscriptMessageFromHighlightFeed.type ? 'highlightFeed' : 'liveTranscript'
        };

        LogHelper.logCallEvent(this.loggingFactory, CallOperations.HighlightTranscriptEntry, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  setCallTimer$ = createEffect(() =>
  this.actions.pipe(
    ofType(
      CallActions.hydrateSuccess
      ),
    concatLatestFrom(() => [
      this.store.select(fromCall.getCurrentCall)
    ]),
    tap(([, call]) => {
      if (call) {
        this.callHeaderService.startCallTimer(call);
      }
      else {
        this.callHeaderService.resetCallTimer();
      }
    })
  ),
  { dispatch: false }
);

  recommendedActionDesktopNotificationClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.recommendedActionDesktopNotificationClicked),
      concatLatestFrom(({callId}) => this.store.select(fromCall.getCall(callId))),
      tap(([{recommendedAction}, call]) => {
        const logDimensions = {
          title: recommendedAction.actionTitle
        };

        LogHelper.logCallEvent(this.loggingFactory, CallOperations.ITGNotificationClicked, call, logDimensions);
      })
    ),
    { dispatch: false }
  );

  transcriptMessageReceived$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.transcriptMessageReceived),
      concatLatestFrom(({transcriptMessageResponse}) => this.store.select(fromCall.getCall(transcriptMessageResponse.callId))),
      tap(([{transcriptMessageResponse}, call]) => {
        if (call?.transcriptMessages?.length && !call.firstTranscriptMessageLogged){
          this.store.dispatch(CallActions.logFirstTranscriptMessage({callId: transcriptMessageResponse.callId}));
        }
      })
    ),
    { dispatch: false }
  );

  logFirstTranscriptMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(CallActions.logFirstTranscriptMessage),
      concatLatestFrom(({callId}) => this.store.select(fromCall.getCall(callId))),
      tap(([, call]) => {
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.FirstTranscriptMessage, call);
      })
    ),
    { dispatch: false }
  );

  private logCallNotFound(logData: CallNotFoundLog) {
    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.CallNotFound, logData);
  }

  ngrxOnInitEffects(): Action {
    return CallActions.hydrate();
  }
}
