// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { LoginBanners } from '../domain/models/enums';

export class LoginBannerHelper {
  static getBannerContentByQueryString(params) {
    const paramsValue = params.bannerReason;
    const disconnectedContent = {
      type: 'error',
      active: true,
      bannerText: 'Your internet connection was lost and you have been logged out. Any active conversations you had have been assigned to new agents. If this continues, contact your supervisor.'
    };

    const authFailure = {
      type: 'error',
      active: true,
      bannerText: 'There was no profile found for you. Please submit a MyComcastAccess request or contact your supervisor for assistance.'
    };

    switch (paramsValue) {
      case LoginBanners.Disconnected:
        return disconnectedContent;
      case LoginBanners.AuthFailure:
        return authFailure;
    }
  }
}
