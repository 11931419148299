// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class NavigatorHelper {
  /**
   * get the window.navigator.connection
   * TODO: navigator.connection is not available as strongly typed but maybe some day
   * @returns a NetworkInformation object {@link https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation NetworkInformation}
   */
  static getNetworkInformation(): NetworkInfo {
    const connection = window.navigator['connection'];

    if (!connection) {
      return undefined;
    }

    const {
      downlink,
      downlinkMax,
      effectiveType,
      rtt,
      saveData,
      type
    } = connection;

    return {
      downlink,
      downlinkMax,
      effectiveType,
      rtt,
      saveData,
      type
    };
  }
}

/**
 * TODO: remove and use TS class when available
 */
export interface NetworkInfo {
  downlink: number;
  downlinkMax: number;
  effectiveType: string;
  rtt: number;
  saveData: boolean;
  type: string;
}
