<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="cee-app-launcher">  
  <cee-dropdown-menu [isOpen]="isOpen" labelText="Apps" [hideLabel]="false">      
      <a *ngFor="let app of applications | keyvalue"
        class="cee-app-launcher-link"
        href="{{ app.value }}" target="_blank">
        {{ app.key }}
      </a>
  </cee-dropdown-menu>
</div>
