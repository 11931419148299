// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { Chat, Customer, AppState, ChannelType, BrowserDeviceInformation } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { AgentGroup } from 'projects/entities/src/lib/domain/models/agentGroup';
import { distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import { ChatIdentifierType } from 'projects/entities/src/lib/domain/models/enums';
import { SMS_TEXT, STANDARD_TEXT } from './constants';

@Component({
  selector: 'merc-convo-details-tab',
  templateUrl: './convo-details.component.html',
  styleUrls: ['./convo-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoDetailsComponent extends SubscriberComponent implements OnInit {
  customer$: Observable<Customer>;
  chat$: Observable<Chat>;
  agentGroup$: Observable<AgentGroup>;
  chatIdTooltipText: string = 'Copy Chat Id';
  chatAccountTooltipText: string = 'Copy Account Number';
  customerIdTooltipText: string = 'Copy Customer Id';
  copiedSVG: string = 'Copied <svg class="cee-icon cee-icon--md"><use xlink:href="/assets/img/svg-sprite.svg#icon-check-circle"></use></svg>';
  isPageMarkersExpanded: boolean = false;

  @Input() isOpen: boolean;

  @ViewChild('pageMarkerText') public pageMarkerText!: ElementRef;

  constructor(
      private clipboardService: ClipboardService,
      private ngEntityStore: Store<AppState>,
    ) {
      super();
    }

  ngOnInit() {
    this.agentGroup$ =  this.ngEntityStore.select(fromChat.getSelectedAgentGroup);
    this.customer$ = this.ngEntityStore.select(fromChat.getSelectedChatCustomer).pipe(distinctUntilChanged());
    this.chat$ = this.ngEntityStore.select(fromChat.getSelectedChat).pipe(distinctUntilChanged());
  }

  getDevice(type: ChannelType): string {
    switch (type) {
      case ChannelType.WebChat:
        return STANDARD_TEXT;
      case ChannelType.Sms:
        return SMS_TEXT;
      default:
        return null;
    }
  }

  getBrowserType(device: BrowserDeviceInformation) {
    const name = device.name;
    const version = device.version;

    if (name && version) {
      return `${name} ${version}`;
    } else if (name && !version) {
      return `${name}`;
    } else if (!name && version) {
      return `${version}`;
    }
  }

  copyToClipboard(text, id){
    this.clipboardService.copyTo(text);

    switch (id) {
      case ChatIdentifierType.ChatId:
        this.chatIdTooltipText = this.copiedSVG;
        break;
      case ChatIdentifierType.AccountNumber:
        this.chatAccountTooltipText = this.copiedSVG;
        break;
      case ChatIdentifierType.CustomerId:
        this.customerIdTooltipText = this.copiedSVG;
        break;
    }
  }

  resetCopyText(text, id){
    switch (id) {
      case ChatIdentifierType.ChatId:
        this.chatIdTooltipText = `Copy ${text}`;
        break;
      case ChatIdentifierType.AccountNumber:
        this.chatAccountTooltipText = `Copy ${text}`;
        break;
      case ChatIdentifierType.CustomerId:
        this.customerIdTooltipText = `Copy ${text}`;
        break;
    }
  }

  getPageMarker(pageMarkers: string[]) {
    if (!pageMarkers?.length) {
      return null;
    }

    return pageMarkers.join(', ');
  }

  expandPageMarkers() {
    this.isPageMarkersExpanded = true;
  }

  shouldDisplayShowMorePageMarkers(isDropdownOpen: boolean) {
    return isDropdownOpen && this.isPageMarkerOverflowed();
  }

  private isPageMarkerOverflowed() {
    return this.pageMarkerText?.nativeElement?.clientHeight < this.pageMarkerText?.nativeElement?.scrollHeight;
  }
}
