// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AvailabilityOption } from 'src/app/models/availability-options';
import * as fromAgentAvailability from './availability.reducer';
import { AvailabilityChange, AvailabilityType } from './models';
import { fromAgent } from './selectors';
import { AppState } from './state';

export const selectAgentAvailability = createFeatureSelector<
  AppState,
  fromAgentAvailability.State
>(fromAgentAvailability.featureKey);

export const getAvailability = createSelector(
  selectAgentAvailability,
  (availability: fromAgentAvailability.State) => availability.availability
);

export const getAvailabilityData = createSelector(
  [getAvailability, fromAgent.getAvailabilityOptions],
  (availability: AvailabilityChange, availabilityOptions: AvailabilityOption[]) => {
    return {
      availability,
      availabilityOptions
    };
  }
);

export const getSettingAvailability = createSelector(
  selectAgentAvailability,
  (availability: fromAgentAvailability.State) =>
    availability.settingAvailability
);

export const getSettings = createSelector(
  selectAgentAvailability,
  (availability: fromAgentAvailability.State) => availability.settings
);

export const getAvailableForExtraChats = createSelector(
  selectAgentAvailability,
  (state: fromAgentAvailability.State) => state.availableForExtraChats
);

export const getChatsClosedSinceAvailable = createSelector(
  selectAgentAvailability,
  (state: fromAgentAvailability.State) => state.chatsClosedSinceAvailable
);

export const isAgentAvailable = createSelector(
  selectAgentAvailability,
  (state: fromAgentAvailability.State) => state.availability?.available === AvailabilityType.Available
);

export const wasPreviousStatusAvailable = createSelector(
  selectAgentAvailability,
  (state: fromAgentAvailability.State) => state.wasPreviousStatusAvailable ?? false
);
