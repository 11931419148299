// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { PageNames } from '../constants/constants';
import { PageInitializationConstants } from '../constants/page-initialization.constants';
import { PageInitializationState } from '../domain/models/enums';
import { PageInitializationItem } from '../domain/models/page-initialization-item';

export class PageInitializationHelper {
  static isCritical(pageName: string, pageInitItem: string): boolean{
    const criticalItems = this.getCriticalItems(pageName);
    return criticalItems.some(i => i === pageInitItem);
  }

  static isPageInitPending(pageName: string, pageInitializationStates: PageInitializationItem[]): boolean{
    const numCriticalItems = this.getCriticalItems(pageName).length;
    const criticalItems = pageInitializationStates.filter(s => this.isCritical(pageName, s.eventName));
    if (criticalItems.length < numCriticalItems){
      return true;
    }
    const criticalPendingItems = criticalItems.filter(p => p.state === PageInitializationState.Pending);
    if (criticalPendingItems.length > 0){
      return true;
    }
    return false;
  }

  static updatePageInitializationStates(states: PageInitializationItem[], update: PageInitializationItem): PageInitializationItem[] {
    const _states = [...states];
    const pageInitItemIndex = _states.findIndex(item => item.eventName === update.eventName);

    if (pageInitItemIndex !== -1){
      _states[pageInitItemIndex] = update;
    }
    else {
      _states.push(update);
    }
    return _states;
  }

  static getCriticalItems(pageName: string): string[]{
    switch (pageName){
      case PageNames.voice:
        return [
          PageInitializationConstants.ChatHubConnection,
          PageInitializationConstants.ApplicationConfiguration,
          PageInitializationConstants.AgentSettings,
          PageInitializationConstants.VerifySessionLogIn,
          PageInitializationConstants.VoiceHubConnection,
          PageInitializationConstants.ItgList
        ];
      case PageNames.home:
      default:
        return [
          PageInitializationConstants.ChatHubConnection,
          PageInitializationConstants.ApplicationConfiguration,
          PageInitializationConstants.AgentSettings,
          PageInitializationConstants.VerifySessionLogIn,
          PageInitializationConstants.CustomSettings
        ];
    }
  }

}
