// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ChatPinInfo
{
    public chatId: string;
}

export class UnpinnedChat extends ChatPinInfo
{
    public pinnedElapsed: number;
    public manualUnpin: boolean;
}