// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SmartResponseType } from './enums';

export class SmartResponseVm {
  id: string;
  category: string;
  confidence: number;
  value: string;
  answerType: string;
  type: SmartResponseType;
  entities?: string[];
  searchText?: string;
  modelId?: string;
}
