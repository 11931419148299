// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { ProfanityActions } from './actions';
import { ProfanityList } from './models/profanityList';

export const initialState: ProfanityList = null;

export const reducer = createReducer<ProfanityList>(
  initialState,
  on(
    ProfanityActions.Loaded,
    (_state, { profanityList }): ProfanityList => profanityList
  )
);
