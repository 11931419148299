// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { ChatUi } from '../chat-ui/chat-ui.model';
import { getSelectedChat } from '../chat.selectors';
import { Chat } from '../models/chat';
import { ScriptSent } from '../models/scriptSent';
import { ScriptInUse } from '../models/setScriptInUse';
import { fromChat } from '../selectors';
import { Script, ScriptGroup, ScriptTree } from './models';
import * as fromScriptsSearch from './scripts-search-selectors';
import * as fromScripts from './scripts-selectors';
import { createFilteredScriptGroup, createFilteredScriptGroups } from './scripts-selectors';

const getSelectedChatScriptTreeId = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.scriptTreeId
);

const getSelectedChatScriptTree = createSelector(
  getSelectedChatScriptTreeId,
  fromScripts.selectScriptTreeEntities,
  (scriptTreeId: string, scriptTrees: Dictionary<ScriptTree>) => scriptTreeId && scriptTrees[scriptTreeId]
);

const selectScriptGroupIds = createSelector(
  getSelectedChatScriptTree,
  (scriptTree: ScriptTree) => scriptTree?.scriptGroups || []
);

const selectScriptGroups = createSelector(
  selectScriptGroupIds,
  fromScripts.selectAllScriptGroups,
  (ids: string[], scriptGroups: ScriptGroup[]) => scriptGroups.filter(sg => ids.includes(sg.id))
);

export const selectSystemScripts = createSelector(
  getSelectedChatScriptTreeId,
  fromScripts.selectScripts,
  (scriptTreeId: string, scripts: Script[]) =>
    scriptTreeId ? scripts.filter(script => script.scriptTreeId === scriptTreeId) : []
);

export const selectFilteredScriptGroups = createSelector(
  selectScriptGroups,
  fromScriptsSearch.selectFilteredScripts,
  fromScriptsSearch.selectScriptGroupsContainingFilteredScripts,
  (scriptGroups: ScriptGroup[], scripts: Script[], groupsWithScripts: ScriptGroup[]) =>
    createFilteredScriptGroups({ scriptGroups, scripts, groupsWithScripts })
);

export const getFilteredScriptGroup = (scriptGroupId: string) => createSelector(
  fromScripts.getScriptGroup(scriptGroupId),
  fromScriptsSearch.selectScriptGroupsContainingFilteredScripts,
  fromScriptsSearch.selectFilteredScripts,
  (scriptGroup: ScriptGroup, groupsWithScripts: ScriptGroup[], scripts: Script[]) =>
    scriptGroup && createFilteredScriptGroup({ scriptGroup, scripts, groupsWithScripts })
);

export const selectFilteredScriptCount = createSelector(
  getSelectedChatScriptTreeId,
  fromScriptsSearch.selectFilteredScripts,
  (scriptTreeId: string, scripts: Script[]) =>
    scriptTreeId ? scripts.filter(script => script.scriptTreeId === scriptTreeId).length : 0
);

export const selectExpandAllScripts = createSelector(
  fromChat.getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.expandAllScriptGroups || false
);

export const showSystemScripts = createSelector(
  fromChat.getSelectedChatUi,
  (chatUi: ChatUi) => !chatUi?.hideSystemScripts
);

export const isScriptGroupExpanded = ({ scriptGroupId }: {scriptGroupId: string}) => createSelector(
  fromChat.getSelectedChatUi,
  (selectedChatUi: ChatUi) => {
    const { expandedScriptGroups, collapsedScriptGroups } = selectedChatUi || {};
    const { expandAllScriptGroups } = selectedChatUi || {};

    if (expandAllScriptGroups) {
      // don't expand groups that were explicitly collapsed
      return Boolean(!collapsedScriptGroups?.includes(scriptGroupId));
    }

    return Boolean(expandedScriptGroups?.includes(scriptGroupId));
  }
);

export const isScriptGroupActive = ({ scriptGroupId }: {scriptGroupId: string}) => createSelector(
  isScriptGroupExpanded({ scriptGroupId }),
  fromScriptsSearch.isSelectedChatSearchingScripts,
  (isExpanded: boolean, isSearching: boolean) => isExpanded || isSearching
);

const getSentScripts = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.scriptsSent || []
);

const getScriptSent = (scriptId: string) => createSelector(
  getSentScripts,
  (sentScripts: ScriptSent[]) => sentScripts.find(s => s.id === scriptId)
);

export const getScriptSentCount = (scriptId: string) => createSelector(
  getScriptSent(scriptId),
  (sentScript: ScriptSent) => sentScript?.timesSent || 0
);

const getScriptsInUse = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.scriptsInUse || []
);

export const isScriptInUse = (scriptId: string) => createSelector(
  getScriptsInUse,
  (scripts: ScriptInUse[]) => scripts.some(s => s.scriptId === scriptId)
);
