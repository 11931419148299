// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class StringUtil {
	private static preserveCamelCase(str) {
		let isLastCharLower = false;
		let isLastCharUpper = false;
		let isLastLastCharUpper = false;

		for (let i = 0; i < str.length; i++) {
			const c = str[i];

			if (isLastCharLower && /[a-zA-Z]/.test(c) && c.toUpperCase() === c) {
				str = str.substr(0, i) + '-' + str.substr(i);
				isLastCharLower = false;
				isLastLastCharUpper = isLastCharUpper;
				isLastCharUpper = true;
				i++;
			} else if (
				isLastCharUpper &&
				isLastLastCharUpper &&
				/[a-zA-Z]/.test(c) &&
				c.toLowerCase() === c
			) {
				str = str.substr(0, i - 1) + '-' + str.substr(i - 1);
				isLastLastCharUpper = isLastCharUpper;
				isLastCharUpper = false;
				isLastCharLower = true;
			} else {
				isLastCharLower = c.toLowerCase() === c;
				isLastLastCharUpper = isLastCharUpper;
				isLastCharUpper = c.toUpperCase() === c;
			}
		}
		return str;
	}

	public static toCamelCase(str: any) {
		if (arguments.length > 1) {
			str = Array.from(arguments).map(item => {
				return typeof item !== 'undefined' && item !== null
					? item
							.trim()
							.filter(x => x.length)
							.join('-')
					: '';
			});
		} else {
			if (str) {
				str = str.trim();
			}
		}

		if (str && str.length === 0) {
			return '';
		}

		if (str && str.length === 1) {
			return str.toLowerCase();
		}

		if (/^[a-z0-9]+$/.test(str)) {
			return str;
		}

		const hasUpperCase = str && str !== str.toLowerCase();

		if (hasUpperCase) {
			str = StringUtil.preserveCamelCase(str);
		}

		return str !== null
			? str
					.replace(/^[_.\- ]+/, '')
					.toLowerCase()
					.replace(/[_.\- ]+(\w|$)/g, (_m, p1) => p1.toUpperCase())
			: '';
	}
}
