// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { ButtonControl, CeeTextEditorComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { FeedbackOptions } from 'projects/entities/src/lib/constants/constants';
import { CheckboxDetails } from 'projects/entities/src/lib/domain/models/checkboxDetails';
import { CxGptResponseVm } from 'projects/entities/src/lib/domain/models/cx-gpt-response';
import { CxGptResponseInUse } from 'projects/entities/src/lib/domain/models/cx-gpt-response-in-use';
import { ChatMessageStatus, ChatMessageType, SenderType } from 'projects/entities/src/lib/domain/models/enums';
import { fromChat, fromCxGptResponses, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { AppState, Chat, ChatActions, SendChatMessage, SendMessageType, UiActions } from 'projects/entities/src/public_api';
import {  Observable, of } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { updateCxGptResponseFeedback } from 'projects/entities/src/lib/domain/cxGptResponses/cx-gpt-responses.actions';

@Component({
  selector: 'merc-cxgpt-details',
  templateUrl: './cxgpt-details.component.html',
  styleUrls: ['./cxgpt-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CxgptDetailsComponent extends SubscriberComponent implements OnInit {
  public showFeedbackModal: boolean = false;
  options$: Observable<CheckboxDetails[]>;
  @Input() active: boolean = false;
  @Output() manageEditClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  controls: ButtonControl[] = [];
  title$: Observable<string>;
  body$: Observable<string>;
  feedback: CheckboxDetails[] = [];
  checkedFeedback: { [value: string]: boolean } = {};
  @ViewChild(CeeTextEditorComponent, { static: false }) ceeTextEditorComponent!: CeeTextEditorComponent;
  isLoading: boolean;
  optionalFeedback: string;
  cxGptResponse$: Observable<CxGptResponseVm>;
  scriptTreeId: string;
  hideAnimations$: Observable<boolean>;
  selectedActiveChat$: Observable<Chat>;
  isCxgptResponseFailed$: Observable<boolean>;
  
  
  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedActiveChat$ =  this.store.select(fromChat.getSelectedChat);
    this.hideAnimations$ = this.store.select(fromSettings.hideAnimations);
    this.feedback = this.formatOptions(FeedbackOptions);
    this.options$ = of(this.feedback);  
    this.cxGptResponse$ = this.store.select(fromCxGptResponses.getLatestCxGptResponsesForSelectedChatId); 
    this.isCxgptResponseFailed$ = this.store.select(fromCxGptResponses.IsCxGptResponseFailed);
}

  formatOptions(options: string[]): CheckboxDetails[] {
    return options.map((option) => ({
      value: option,
      label: option,
      checked: false, 
    }));
  }

  closeModal(){
    this.showFeedbackModal = false;
  }
 
  openFeedback(response: CxGptResponseVm) {
    if (!response.feedback) { 
      this.showFeedbackModal = true;
  } }

  populateEditor(response: CxGptResponseVm, selectedChat: Chat) {
    const cxGptResponseInUse: CxGptResponseInUse = {
      chatId: selectedChat.chatId,
      cxGptResponse: response
    };
    this.store.dispatch(UiActions.CxGptResponseEditClicked({cxGptResponseInUse}));
  }
 
  sendMessage(response: CxGptResponseVm, selectedChat: Chat) {
    this.sendCxGptResponse(response, selectedChat);   
  }

  sendCxGptResponse(response: CxGptResponseVm, selectedChat: Chat) {
    
    const chatMessage: SendChatMessage = {
      chatId: selectedChat.chatId,
      message: response.text,
      traceId: Guid.raw(),
      timestamp: this.timeService.unix(),
      scriptTreeId: selectedChat.scriptTreeId,
      sendMessageType: SendMessageType.ChatLine,
      status: ChatMessageStatus.Pending,
      sender: SenderType.FixAgent,
      type: ChatMessageType.Message
    };
    this.store.dispatch(ChatActions.SendCxGptResponse({chatMessage, cxGptResponse: response}));
  }

  submitFeedback(response: CxGptResponseVm, selectedChat: Chat) {
    const selectedFeedback = Object.keys(this.checkedFeedback).filter(key => this.checkedFeedback[key]);
    
     const feedbackData: CxGptResponseVm = {
        ...response,
        feedback: {message: selectedFeedback,
        additionalFeedback: this.optionalFeedback}
      }; 
    this.store.dispatch(updateCxGptResponseFeedback({ chatId: selectedChat.chatId, feedbackData}));

    this.feedback = this.formatOptions(FeedbackOptions);
    this.options$ = of(this.feedback); 
    this.optionalFeedback = '';
    this.closeModal();
  }
 
  updateFeedback(event, option) {
    this.checkedFeedback = {};
  this.checkedFeedback[option.value] = event.target.checked;
}

  onFeedbackChange(event) {
   this.optionalFeedback = event; 
    
  }
}

