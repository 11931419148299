// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SendSuggestionSource, SuggestionType } from '../enums';
import { SuggestionBody, XaSuggestionHeading } from '../suggestion';

export class SendSuggestion {
  title?: string;
  heading?: XaSuggestionHeading;
  body: SuggestionBody;
  json: string;
  chatId: string;
  messageId?: string;
  queryId?: string;
  isModified?: boolean;
  source?: SendSuggestionSource;
  intent?: string;
  traceId?: string;
  removeCustomerButtons?: boolean;
  timestamp?: number;
  suggestionType?: SuggestionType = SuggestionType.Xa;
  translateTo?: string;
}
