// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { CeeScriptSearchComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AppState, FeatureFlags, ProfanityService, ScriptActions, ScriptType,
} from 'projects/entities/src/public_api';
import { Script, ScriptGroup, ManagedScript, ScriptGroupActions } from 'projects/entities/src/lib/domain/scripts/';
import * as fromScripts from 'projects/entities/src/lib/domain/scripts/scripts-selectors';
import * as fromCustomResponses from 'projects/entities/src/lib/domain/scripts/custom-responses-selectors';
import { defaultCustomScriptGroup, defaultCustomScriptTreeId } from 'projects/entities/src/lib/domain/scripts/custom-responses.constants';
import { ScriptsService } from 'projects/entities/src/lib/services/scripts.service';
import { CeeTypeaheadSearchComponent } from '@cxt-cee-chat/merc-pattern-lib/components/forms/cee-typeahead-search/cee-typeahead-search.component';
import { fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { ErrorMessages } from 'projects/entities/src/lib/constants/error-messages';

@Component({
  selector: 'merc-details-script-manage-panel',
  templateUrl: './script-manage-panel.component.html',
  styleUrls: ['./script-manage-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScriptManagePanelComponent extends SubscriberComponent implements OnInit, OnDestroy, AfterViewInit {
  public scriptSearchId = 'manage-panel';
  @ViewChild('scriptSearch')
  scriptSearchComponent: CeeScriptSearchComponent;
  @ViewChild('typeaheadSearch') typeaheadSearchComponent: CeeTypeaheadSearchComponent;

  public isSaveDisabled: boolean = true;
  public errorMessage: string = null;
  public textPlaceholder: string = 'Write the main text of your custom response here...';

  public isSearching$: Observable<boolean>;
  public searchTerm$: Observable<string>;
  public scriptFilterCount$: Observable<number>;
  public scripts$: Observable<Script[]>;
  public bannerText$: Observable<string>;
  public scriptToEdit$: Observable<Script>;
  public managedScript$: Observable<ManagedScript>;
  public scriptGroup$: Observable<ScriptGroup>;
  public customScriptGroups$: Observable<ScriptGroup[]>;
  public managedScriptGroup$: Observable<ManagedScript>;
  public hideAnimations$: Observable<boolean>;
  public linkEditorTargetFeatureFlag$: Observable<boolean>;

  private searchSubject = new Subject();
  private isPanelClick: boolean = false;
  private editorText: string;
  newScriptGroup;

  constructor(
    private store: Store<AppState>,
    private profanityService: ProfanityService,
    private scriptsService: ScriptsService
  ) {
    super();
  }

  ngOnInit() {
    this.linkEditorTargetFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LinkEditorTarget));
    this.isSearching$ = this.store.select(fromScripts.isSearchingManageScripts);
    this.searchTerm$ = this.store.select(fromScripts.selectManageScriptQuery);
    this.scriptFilterCount$ = this.store.select(fromCustomResponses.selectFilteredManageScriptsCount);
    this.scripts$ = this.store.select(fromCustomResponses.selectFilteredManageScripts);
    this.bannerText$ = this.store.select(fromScripts.selectManageScriptBannerText);
    this.scriptToEdit$ = this.store.select(fromScripts.selectScriptToEdit);
    this.managedScript$ = this.store.select(fromScripts.selectCurrentManagedScript);
    this.scriptGroup$ = this.store.select(fromCustomResponses.selectCustomScriptGroupBase);
    this.customScriptGroups$ = this.store.select(fromCustomResponses.selectCustomScriptGroups);
    this.managedScriptGroup$ = this.store.select(fromCustomResponses.selectCurrentManagedScriptGroup);
    this.hideAnimations$ = this.store.select(fromSettings.hideAnimations);

    this.subscriptions.push(
      this.searchSubject
        .pipe(debounceTime(250))
        .subscribe((query: string) => {
          this.store.dispatch(ScriptActions.searchManageScripts({ query }));
        })
    );
  }

  ngAfterViewInit () {
    this.textPlaceholder = 'Write the main text of your custom response here...';
  }

  hideEditor(managedScript: ManagedScript): boolean {
    const { status } = managedScript;
    return !(status === 'CREATE' || status === 'EDIT');
  }

  onModalClose() {
    this.clearSearch();
    this.clearEditorText();
    this.store.dispatch(ScriptActions.manageScriptsClosed());
  }

  notifyPanelClick(isManagePanelClick: boolean) {
    this.isPanelClick = isManagePanelClick;
  }

  backToManagePopup() {
    if (this.isPanelClick) {
      this.store.dispatch(ScriptActions.manageScripts({userAction: false}));
      this.isPanelClick = false;
      this.clearEditorText();
    } else {
      this.onModalClose();
    }
  }

  onAddScript() {
    this.notifyPanelClick(true);
    this.clearEditorText();
    this.store.dispatch(ScriptActions.createScript({method: 'manager'}));
  }

  onAddHeader() {
    this.notifyPanelClick(true);
    this.store.dispatch(ScriptGroupActions.createScriptGroup({method: 'manager'}));
  }

  onEditorChange(script: Script, managedScript: ManagedScript, newScriptGroup: ScriptGroup, editorChangeEvent: any) {
    this.editorText = editorChangeEvent.text.trim() ? editorChangeEvent.html : null;
    this.errorMessage = '';
    this.setSaveDisabled(script, managedScript, newScriptGroup);
  }

  getModalHeader(managedScript: ManagedScript, managedScriptGroup: ManagedScript) {
    if (managedScript && !this.hideEditor(managedScript) ){
      return managedScript.status === 'CREATE' ? 'Create new response' : 'Edit response';
    } else if (managedScriptGroup && !this.hideEditor(managedScriptGroup)) {
        return managedScriptGroup.status === 'CREATE' ? 'Create new custom header' : 'Edit header';
    } else {
      return 'Manage responses';
    }
  }

  onSave(managedScript: ManagedScript, script: Script, scriptGroup: ScriptGroup) {
    const profanity = this.profanityService.test(this.editorText);
    if (profanity.length) {
      this.errorMessage = ErrorMessages.profanityCheckFailedOnSave;
      this.isSaveDisabled = true;
      return;
    }
    
    let newScriptGroupId: string = scriptGroup?.id ?? defaultCustomScriptGroup.id;
    let newScriptGroup = scriptGroup;
    if (scriptGroup && !scriptGroup.id && scriptGroup.name) {
      newScriptGroupId = this.scriptsService.generateCustomScriptGroupId(defaultCustomScriptGroup.id);
      newScriptGroup = this._createNewScriptGroup(scriptGroup.name, newScriptGroupId);
    }

    switch (managedScript.status) {
      case 'EDIT':
        const newValue = this.editorText;
        this.store.dispatch(ScriptActions.updateScriptValue(
          {
            id: script.id,
            scriptGroupId:
            script.scriptGroupId,
            newValue,
            newScriptGroup,
            newScriptGroupParentId: defaultCustomScriptTreeId
          }
        ));
        break;
      case 'CREATE':
        const newScript: Script = {
          id: Guid.raw(),
          type: ScriptType.Custom,
          scriptGroupId: newScriptGroupId,
          scriptTreeId: defaultCustomScriptTreeId,
          name: null,
          value: this.editorText,
        };
        this.store.dispatch(ScriptActions.addScript(
          {
            script: newScript,
            scriptGroup: newScriptGroup,
            parentScriptGroupId: defaultCustomScriptTreeId
          }
        ));
        this.clearEditorText();
        break;
    }
  }

  private _createNewScriptGroup(name: string, id: string): ScriptGroup {
    const newScriptGroup: ScriptGroup = {
      id: id,
      scriptCollectionId: id,
      name: name,
      scriptGroups: [],
      scripts: [],
      scriptTreeId: defaultCustomScriptTreeId,
    };
    return newScriptGroup;
  }

  onInputChangeScriptGroup(script: Script, managedScript: ManagedScript, event: ScriptGroup) {
    this.newScriptGroup = event?.name ? event : defaultCustomScriptGroup;
    this.setSaveDisabled(script, managedScript, this.newScriptGroup);
  }

  setSaveDisabled(script: Script, managedScript: ManagedScript, newScriptGroup: ScriptGroup) {
    const isScriptGroupUnchanged = newScriptGroup?.id === script?.scriptGroupId;
    const isTextUnchanged = !this.editorText || this.editorText === script?.value;
    const isEditScreen = managedScript?.status === 'EDIT';

    const nextSaveDisabled = isEditScreen ? isScriptGroupUnchanged && isTextUnchanged : isTextUnchanged;
    this.isSaveDisabled = nextSaveDisabled;
  }

  onSearchChange(searchInput: string) {
    const searchTerm = searchInput || '';
    this.searchSubject.next(searchTerm.trim());
  }

  clearSearch() {
    this.scriptSearchComponent?.cancelSearch();
  }

  clearEditorText() {
    this.editorText = '';
    this.typeaheadSearchComponent?.clearSearch();
    this.errorMessage = '';
    this.isSaveDisabled = true;
  }
}
