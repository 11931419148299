// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TimeHelperService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  Chat, ChatTranscript, AppState, PriorEngagementAccordionChange,
  LogHelper, ChatOperations, SelectedHistoryEngagement, ChatActions
} from 'projects/entities/src/public_api';
import { SelectedHistoryEngagementSource } from 'projects/entities/src/lib/domain/models/selected-history-engagement';

@Component({
  selector: 'merc-history-card-container',
  templateUrl: './convo-history-card-container.component.html',
  styleUrls: ['./convo-history-card-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoHistoryCardContainerComponent extends SubscriberComponent {
  @Input() chat: Chat;

  constructor(
    private store: Store<AppState>,
    private timeHelper: TimeHelperService,
    private loggingFactory: LoggingFactoryService
  ) {
    super();
  }

  public currentEngagementClicked(): void {
    this._dispatchSelectedEvent(true);
  }

  public priorEngagementClicked(engagement: ChatTranscript): void {
    this._dispatchSelectedEvent(false, engagement);

    if (!engagement.expanded) {
      const accordionChangeArgs = new PriorEngagementAccordionChange();
      accordionChangeArgs.priorEngagement = engagement;
      accordionChangeArgs.chatId = this.chat.chatId;
      accordionChangeArgs.isActive = true;
      this.store.dispatch(ChatActions.PriorEngagementAccordionChange(accordionChangeArgs));
    } else {
      const logDimensions: any = { isAsync: this.chat.isAsync };
      if (!engagement.isAsync) {
        logDimensions.NextEngagementId = engagement.id;
      } else {
        logDimensions.NextConversationId = engagement.id;
      }
      LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ViewHistoricTranscript, this.chat, logDimensions);
    }
  }

  public getFormattedTime(unix: number): string {
    return this.timeHelper.formatDateTimeShortMonth(unix, this.chat.timeZone?.name);
  }

  public trackByEngagementChat(_index: number, engagementChat: ChatTranscript) {
    return engagementChat.id;
  }

  private _dispatchSelectedEvent(isCurrentChat: boolean, priorEngagement?: ChatTranscript) {
    const args = new SelectedHistoryEngagement();
    args.isCurrentChat = isCurrentChat;
    args.chatId = this.chat.chatId;
    args.source = SelectedHistoryEngagementSource.HistoryTab;

    if (!isCurrentChat) {
      args.isAsync = priorEngagement.isAsync;
      args.priorEngagementId = priorEngagement.id;
    }

    this.store.dispatch(ChatActions.History_EngagementSelected(args));
  }
}
