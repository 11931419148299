// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class SelectedHistoryEngagement {
    isCurrentChat: boolean;
    chatId: string;
    priorEngagementId?: string;
    isAsync: boolean = false;
    source: SelectedHistoryEngagementSource;
}

export enum SelectedHistoryEngagementSource {
    HistoryTab,
    ChatWindowScrolling
}