// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

// import { AppConfigUtil } from "./app-config.util";

export class AppUtil {
	private static overrideLogFlag: boolean = null; //assigning default value to overrideLogFlag as defined in app.json 'debug' property.

	/**
	 * This function return consoleLogflag value, to determine whether console logging is ON or OFF.
	 *
	 * @returns consoleLogFlag
	 */
	public static getConsoleLogFlag(): boolean {
		let consoleLogFlag = false;
		if (this.getOverrideLogFlag() === null && this.getAppConfigLogFlag()) {
			consoleLogFlag = true;
		} else if (this.getOverrideLogFlag()) {
			consoleLogFlag = true;
		}
		return consoleLogFlag;
	}

	/**
	 * This function will toggle overrideLogFlag
	 */
	public static toggleOverrideLogFlag(): void {
		if (this.getOverrideLogFlag()) {
			this.setOverrideLogFlag(false);
		} else {
			this.setOverrideLogFlag(true);
		}
	}

	public static isEmptyObject(val: any): boolean {
		return Boolean(val && typeof val === 'object' && Object.keys(val).length === 0);
	}

	/**
	 * Function log is a replacement for console.log
	 */
	public static log(...args): void {
		if (this.getConsoleLogFlag()) {
			// tslint:disable-next-line
			console.log.apply(console, args);
		}
	}

	/**
	 * This function is used to get appConfigLogFlag based on property 'debug' defined in app.json(AppConfigUtil.getProperties().debug).
	 */
	private static getAppConfigLogFlag(): boolean {
		// const appProperties = AppConfigUtil.getProperties();
		return false;
	}

	/**
	 * This function is used to get overrideLogFlag.
	 */
	private static getOverrideLogFlag(): boolean {
		return AppUtil.overrideLogFlag;
	}

	/**
	 * This function is used to set overrideLogFlag.
	 */
	private static setOverrideLogFlag(inOverrideLogFlag: boolean): void {
		AppUtil.overrideLogFlag = inOverrideLogFlag;
	}
}
