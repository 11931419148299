// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IncrementingTimerService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { CallActions } from 'projects/entities/src/lib/domain/actions';
import { CallState, CallSummaryDisplay } from 'projects/entities/src/lib/domain/models/voice/enums';
import { fromCall } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { VoiceHelper } from 'projects/entities/src/lib/utils/voice-helper';
import { Observable } from 'rxjs';
import { CallHeaderService } from './call-header.service';

@Component({
  selector: 'merc-voice-call-header',
  templateUrl: './call-header.component.html',
  styleUrls: ['./call-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VoiceCallHeaderComponent implements OnInit {
  callState$: Observable<CallState>;
  customerName$: Observable<string>;
  currentCallId$: Observable<string>;
  hasQueuedCall$: Observable<boolean>;
  isDisplayingLoadedCallSummaryPanel$: Observable<boolean>;

  callTimer: IncrementingTimerService;
  callState = CallState;

  constructor(
    private store: Store<AppState>,
    private componentService: CallHeaderService
  ) { }

  ngOnInit() {
    this.callTimer = this.componentService.getCallTimer();
    this.callState$ = this.store.select(fromCall.getCallState);
    this.customerName$ = this.store.select(fromCall.getCustomerName);
    this.currentCallId$ = this.store.select(fromCall.getCurrentCallId);
    this.hasQueuedCall$ = this.store.select(fromCall.hasQueuedCall);
    this.isDisplayingLoadedCallSummaryPanel$ = this.store.select(fromCall.getIsDisplayingLoadedCallSummaryPanel);
  }

  closeCall(callId: string, shouldSkipSummaryModal: boolean) {
    if (shouldSkipSummaryModal) {
      this.store.dispatch(CallActions.callEnded({callId, isManual: true, source: 'closeSession'}));
    }
    else {
      this.store.dispatch(CallActions.getCallSummary({callId, display: CallSummaryDisplay.Modal, traceId: Guid.raw()}));
    }
  }

  getCustomerName(customerName: string) {
    return VoiceHelper.getCustomerName(customerName);
  }

  isActiveCall(callState: CallState){
    return !VoiceHelper.isCallInactive(callState);
  }
}
