// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromPlaceholders from './placeholders.reducer';

@Injectable({
  providedIn: 'root'
})
export class PlaceholdersPersisterService extends StatePersister<fromPlaceholders.State> {
  constructor() {
    super(localStorage, fromPlaceholders.featureKey);
  }

  afterGet(state: fromPlaceholders.State): fromPlaceholders.State {
    if (state) {
      state.isEditPanelOpen = false;
    }
    return state;
  }
}
