// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromAskMeAnything from './ask-me-anything.reducer';

@Injectable({
  providedIn: 'root'
})
export class AskMeAnythingPersisterService extends StatePersister<fromAskMeAnything.State> {
  constructor() {
    super(sessionStorage, fromAskMeAnything.featureKey);
  }

  afterGet(state: fromAskMeAnything.State): fromAskMeAnything.State {
    if (state) {
      state.feedbackSearchId = '';
      state.isFeedbackModalActive = false;
    }
    return state;
  }
}
