// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap, delay, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { CeeMessageEditorComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AppState, ProfanityService,
  ChannelType, ChatMessageType, SenderType, SendMessageType,
  SendChatMessage, ChatUiActions, isSelectedChat, isMessagingEnabled, getChatChannel, Channel, ChatHelper, ChatActions, Color, FeatureFlags, ProfanityActions, LogActions, ChatOperations
} from 'projects/entities/src/public_api';
import { ViewEncapsulation } from '@angular/core';
import { SMS_TEXT, WEB_TEXT } from './constants';
import { SendSuggestion } from 'projects/entities/src/lib/domain/models/requests/send-suggestion';
import { Guid } from 'guid-typescript';
import { fromChat, fromPlaceholders, fromSettings, fromUi,  } from 'projects/entities/src/lib/domain/selectors';
import { SuggestionButton } from 'projects/entities/src/lib/domain/models/suggestion';
import { CustomerResponseButtonChange } from 'projects/entities/src/lib/domain/models/customerResponseButtonChange';
import { ChatMessageStatus } from 'projects/entities/src/lib/domain/models/enums';
import { fromScriptAutoComplete, fromScripts, Script, ScriptGroup } from 'projects/entities/src/lib/domain/scripts';
import { Dictionary } from '@ngrx/entity';
import { concatLatestFrom } from '@ngrx/effects';
import { PlaceholderHelper } from 'projects/entities/src/lib/utils/placeholder-helper';
import { SmartResponseInUse } from 'projects/entities/src/lib/domain/models/smartResponseInUse';
import { SmartResponseToSendVm } from 'projects/entities/src/lib/domain/models/smart-response-to-send-vm';
import { CxGptResponseInUse } from 'projects/entities/src/lib/domain/models/cx-gpt-response-in-use';
import { Language } from 'projects/entities/src/lib/domain/models/enums';
import { ScriptInUse } from 'projects/entities/src/lib/domain/models/setScriptInUse';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';

@Component({
  selector: 'merc-convo-message-input',
  templateUrl: './convoMessageInput.component.html',
  styleUrls: ['./convoMessageInput.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConvoMessageInputComponent extends SubscriberComponent implements OnInit {
  @ViewChild('messageEditor') messageEditor: CeeMessageEditorComponent;
  private _focusSubject = new Subject();
  private _isAgentTyping: boolean = false;
  private _sendMessageType: SendMessageType = SendMessageType.ChatLine;
  @Input() public chatId: string;
  @Input() public color: Color;
  @Input() public conversationId: string;
  @Input() public messagingEnabled: boolean;
  @Input() public scriptTreeId: string;
  content = '';
  itgIsRunning: boolean = false;
  autocompleteSuggestion: Script;
  hasAutocompleteFeatureFlag: boolean;
  scriptGroups: Dictionary<ScriptGroup>;
  suggestionInUse: SendSuggestion;
  removeCustomerButtons: boolean = false;
  quickResponses: SuggestionButton[] = [];
  smartResponseInUse: SmartResponseInUse;
  cxGptResponseInUse: CxGptResponseInUse;

  channel$: Observable<string>;
  linkEditorTargetFeatureFlag$: Observable<boolean>;
  scriptAutocompleteFeatureFlag$: Observable<boolean>;
  scriptAutocompleteSetting$: Observable<boolean>;
  enhanceMessagesFlag$: Observable<boolean>;
  shouldAutoEnhanceMessages$: Observable<boolean>;
  placeholderText$: Observable<string>;
  errorMessage$: Observable<string>;
  enhancedChatMessage$: Observable<string>;
  isLoadingEnhancedChatMessage$: Observable<boolean>;
  shouldTranslateMessages$: Observable<boolean>;
  translationLanguage: string;
  hideAnimation$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  infoMessage$: Observable<string>;
  showUndoButton$: Observable<boolean>;
  undoButtonPreviousContent$: Observable<string>;
  askMeAnythingRewriteContent$: Observable<string>;
  translationLanguage$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private profanityService: ProfanityService,
    private timeService: DayTimeService
  ) {
    super();
  }

  ngOnInit() {
    this.hideAnimation$ = this.store.select(fromSettings.hideAnimations);
    this.linkEditorTargetFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LinkEditorTarget));
    this.scriptAutocompleteFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.ScriptAutocomplete));
    this.enhanceMessagesFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.EnhanceMessages));
    this.shouldAutoEnhanceMessages$ = this.store.select(fromChat.isAutoEnhanceMessagesEnabled());
    this.scriptAutocompleteSetting$ = this.store.select(fromSettings.getShowAutocomplete);
    this.shouldTranslateMessages$ = this.store.select(fromChat.shouldTranslateMessages(this.chatId));
    this.infoMessage$ = this.store.select(fromChat.getChatMessageInputInfoMessage(this.chatId));
    this.isLoading$ = this.store.select(fromChat.getIsChatMessageInputLoading(this.chatId));
    this.undoButtonPreviousContent$ = this.store.select(fromChat.getUndoButtonPreviousContent(this.chatId));

    const scriptAutocompleteFFSub = this.scriptAutocompleteFeatureFlag$.subscribe((hasAutocompleteFeatureFlag) => {
      this.hasAutocompleteFeatureFlag = hasAutocompleteFeatureFlag;
    });
    this.subscriptions.push(scriptAutocompleteFFSub);

    const hasItgRunning = this.store.select(fromChat.getIsItgRunning(this.chatId))
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        concatLatestFrom(_ => [
          this.store.select(fromChat.getStoredTextToSend(this.chatId)),
          this.store.select(fromChat.getAskMeAnythingRewriteContent(this.chatId)),
          this.store.select(fromChat.getEnhancedChatMessage(this.chatId))
        ]))
      .subscribe(([itgIsRunning, storedTextToSend, askMeAnythingRewriteContent, enhancedChatMessageContent]) => {
      this.itgIsRunning = itgIsRunning;
      if (this.itgIsRunning || !storedTextToSend) { return; }

      if (storedTextToSend.isQuickSend){
        this._handleScriptResponseEvent(
          storedTextToSend.text,
          storedTextToSend.isQuickSend,
          SendMessageType.Script,
          (message, type) => this.sendMessage(message, type)
        );
      }
      else{
        this.onMessageInputSend(storedTextToSend.text, askMeAnythingRewriteContent, enhancedChatMessageContent);
      }
    });
    this.subscriptions.push(hasItgRunning);

    const scriptAutocompleteSuggestionSub =
    this.store.select(fromScriptAutoComplete.selectFirstAutocompleteScript)
      .pipe(
        concatLatestFrom(_ => this.store.select(fromChat.getSelectedChatId)))
      .subscribe(([autocompleteSuggestion, selectedChatId]) => {
      if (this.messageEditor && this.messageEditor._editor && this.chatId === selectedChatId) {
        const isTab = this.messageEditor._editor?.editor?.delta?.ops?.find(op => op.insert && (typeof op.insert === 'string') && op.insert.indexOf('\t') >= 0);
        if (!isTab && !(this.messageEditor._editor.root.innerHTML === autocompleteSuggestion?.value)) {
          const data = {
            content: this.messageEditor._editor.editor.delta,
            suggestion: autocompleteSuggestion
          };
          this.messageEditor.insertAutocompleteSuggestion(data);
          this.autocompleteSuggestion = autocompleteSuggestion;
        }
      }
    });
    this.subscriptions.push(scriptAutocompleteSuggestionSub);

    const scriptGroupsSub = this.store.select(fromScripts.selectScriptGroupEntities).subscribe((scriptGroups) => {
      this.scriptGroups = scriptGroups;
    });
    this.subscriptions.push(scriptGroupsSub);

    this._focusSubject
      .pipe(
        delay(50),
        tap(() => {
          this.focusEditor();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.store.select(fromUi.getClickedScript)
      .pipe(
          takeUntil(this.destroyed$),
          concatLatestFrom(() => this.store.select(fromScriptAutoComplete.selectFirstAutocompleteScript))
      )
      .subscribe(([scriptClickArgs, autocompleteSuggestion]) => {
        if (scriptClickArgs?.chatId === this.chatId) {
          const { scriptValue, quickSend } = scriptClickArgs;
          if (quickSend && this.itgIsRunning){
            this.store.dispatch(ChatActions.StoredTextToSend({chatId: this.chatId, storedTextToSend: {text: scriptValue, isQuickSend: true}}));
            return;
          }
          if (autocompleteSuggestion && !quickSend){
            this.clearAutocompleteValue();
          }
          this._handleScriptResponseEvent(
            scriptValue,
            quickSend,
            SendMessageType.Script,
            (message, type) => this.sendMessage(message, type)
          );
        }
      });

      this.store.select(fromUi.getClickedSmartResponse)
      .pipe(takeUntil(this.destroyed$),
        concatLatestFrom(() => this.store.select(fromPlaceholders.getAllPlaceholdersForChatId(this.chatId))),
        filter(([smartResponseClickArgs]) => Boolean(smartResponseClickArgs))
      ).subscribe(([smartResponseClickArgs, placeholders]) => {
        const { quickSend, smartResponse, chatId, isInEditPlaceholder} = smartResponseClickArgs;
        const { value, entities } = smartResponse;

        if (chatId === this.chatId && !isInEditPlaceholder) {
          if (!quickSend){
            this.clearText();
            this.clearScriptsInUse();
            this.clearClickedSuggestion();
          }
          this.smartResponseInUse = smartResponseClickArgs;

          this._handleScriptResponseEvent(
            PlaceholderHelper.updateWithPlaceholders(value, entities, placeholders),
            quickSend,
            SendMessageType.ChatLine,
            (message) => this.sendSmartResponse(message)
          );
        }
      });

      
      this.store.select(fromUi.getClickedCxGptResponse)
      .pipe(takeUntil(this.destroyed$),
        filter((cxGptResponseClickArgs) => Boolean(cxGptResponseClickArgs))
      ).subscribe((cxGptResponseClickArgs) => {
        const {  cxGptResponse, chatId} = cxGptResponseClickArgs;

        if (chatId === this.chatId) {
          this.clearText();
          this.clearScriptsInUse();
          this.clearClickedSuggestion();
          
          this.cxGptResponseInUse = cxGptResponseClickArgs;
          this.insertScript(cxGptResponse.text);

          this._sendMessageType = SendMessageType.ChatLine;
          //focus called with delay so text can populate quill first
          this._focusSubject.next(null);
        }
      });

    const clickedSuggestionSub = this.store.select(fromChat.getClickedSuggestion(this.chatId))
      .pipe(distinctUntilChanged())
      .subscribe((clickedSuggestion: SendSuggestion) => {
        if (clickedSuggestion?.body?.buttons) {
          this.quickResponses = clickedSuggestion.body.buttons;
        }

        if (this.messageEditor) {
          this._handleSuggestionEvent(clickedSuggestion);
        } else {
          // quick fix for xa suggestions not persisting through refresh, TODO: remove once that is fixed
          this.store.dispatch(ChatActions.ClearClickedSuggestion(this.chatId));
        }
      });
    this.subscriptions.push(clickedSuggestionSub);

    const sendQuickResponsesSub = this.store.select(fromChat.getSendQuickResponses(this.chatId))
      .pipe(distinctUntilChanged())
      .subscribe((sendQuickResponse: boolean) => {
        // if box is checked to true, do not remove buttons
          this.removeCustomerButtons = !sendQuickResponse;
      });
    this.subscriptions.push(sendQuickResponsesSub);

    const selectedSub = this.store.select(isSelectedChat(this.chatId))
      .pipe(distinctUntilChanged())
      .subscribe((isSelected: boolean) => {
        if (isSelected) {
          this._focusSubject.next(null);
        }
      });
    this.subscriptions.push(selectedSub);

    const messagingEnabledSub = this.store.select(isMessagingEnabled(this.chatId))
      .pipe(distinctUntilChanged())
      .subscribe((messagingEnabled: boolean) => {
        if (this.messageEditor) {
          this.messageEditor.editorText = messagingEnabled ? this.messageEditor.editorText : '';
        }

        this.enableEditor(messagingEnabled);
      });
    this.subscriptions.push(messagingEnabledSub);

    const bannerSub = this.store.select(fromUi.getNotificationBannerClosed).subscribe((chatId: string) => {
      if (chatId === this.chatId) {
        this._focusSubject.next(null);
      }
    });
    this.subscriptions.push(bannerSub);

    this.channel$ = this.store.select(getChatChannel(this.chatId))
      .pipe(distinctUntilChanged(), map((channel: Channel) => {
        return this.getChannel(channel);
      }));
    
    this.askMeAnythingRewriteContent$ = this.store.select(fromChat.getAskMeAnythingRewriteContent(this.chatId));
    const askMeAnythingRewriteContentSub = this.askMeAnythingRewriteContent$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        filter((content) => Boolean(content)),
        concatLatestFrom(() => this.store.select(fromChat.getScriptsInUseByChatId(this.chatId)))
      )
      .subscribe(([content, scriptsInUse]) => {
        this.handleContentFill(content, scriptsInUse);
      });
    this.subscriptions.push(askMeAnythingRewriteContentSub);

    this.placeholderText$ = this.store.select(fromChat.getChatMessageEditorPlaceholder(this.chatId));
    this.errorMessage$ = this.store.select(fromChat.getChatMessageInputErrorMessage(this.chatId));

    this.enhancedChatMessage$ = this.store.select(fromChat.getEnhancedChatMessage(this.chatId));
    this.enhancedChatMessage$
    .pipe(
      distinctUntilChanged(),
      filter((content) => Boolean(content)),
      concatLatestFrom(() => this.store.select(fromChat.getScriptsInUseByChatId(this.chatId)))
    ).subscribe(([content, scriptsInUse]) => {
        this.handleContentFill(content, scriptsInUse);
      });
    this.isLoadingEnhancedChatMessage$ = this.store.select(fromChat.getIsLoadingEnhancedChatMessage(this.chatId));

    this.translationLanguage$ = this.store.select(fromChat.getTranslationLanguage(this.chatId));
    this.translationLanguage$
      .pipe(
        distinctUntilChanged(),
        filter((language) => language && language !== Language.English && language !== Language.Unknown),
      ).subscribe((language) => {
        this.translationLanguage = 'Messages sent to customer will be translated to ' + this.getLanguageString(Language, language);
      });
    this.showUndoButton$ = this.store.select(fromChat.showMessageInputUndoButton(this.chatId));
  }

  public getLanguageString(enumType: any, value: string): string | undefined {
    for (const key in enumType) {
      if (enumType[key] === value) {
        return key;
      }
    }
    return undefined; 
  }

  public onBannerDismiss(type: string) {
    switch (type){
      case 'info': 
        this.store.dispatch(ChatUiActions.clearMessageInputInfoMessage({ chatId: this.chatId }));
        break;
      case 'error':
      default:
        this.store.dispatch(ChatUiActions.clearMessageInputErrorMessage({ chatId: this.chatId }));
        break;
    }
  }

  dismissErrorBanner(){
    this.onBannerDismiss('error');
  }

  onStartsTyping() {
    this.toggleAgentTypingStatus(true);
  }

  onStopsTyping() {
    this.toggleAgentTypingStatus(false);
  }

  onEnhanceMessageClick(message: string) {
    this.store.dispatch(ChatActions.EnhanceChatMessage({chatId: this.chatId, message}));
  }

  onUndoMessageClick(previousContent: string, enhancedChatMessage: string){
    this.insertScript(previousContent ?? '', true);
    this._focusSubject.next(null);
    this.store.dispatch(ChatUiActions.messageInputUndoClicked({chatId: this.chatId, isEnhance: !!enhancedChatMessage }));
  }

  private getChannel(channel: Channel): string {
    if (channel){
      switch (channel.type) {
        case ChannelType.Sms:
          return SMS_TEXT;
        case ChannelType.WebChat:
        default:
          return WEB_TEXT;
      }
    }
    else {
      return WEB_TEXT;
    }
  }

  public onMessageChange(message: any): void {
    const messageText = message.text;
    if (this.hasAutocompleteFeatureFlag && typeof messageText === 'string') {
      // handle case when autocomplete suggestion is accepted
      this._handleAutocomplete(message);

      // update editor text in state to check for autocomplete suggestions against
      const newLineIndex = messageText?.indexOf('\n') >= 0 ? messageText.indexOf('\n') : messageText.length; // Location of new line char
      this.store.dispatch(ChatUiActions.updateTextEditorContent({ chatId: this.chatId, content: messageText.slice(0, newLineIndex)}));
    }


    // clear scripts & suggestions & smart response
    if (message.html === null || message.html === '') {
      this.clearScriptsInUse();
      this.clearClickedSuggestion();
      this.clearSmartResponseInUse();
      this.clearCxGptResponseInUse();
    }
  }

  private _handleAutocomplete(message: any): void {
    if (message.delta?.ops[1]?.insert === '\t' && this.autocompleteSuggestion && this.autocompleteSuggestion.value) {
      const scriptInUse = {
        chatId: this.chatId,
        scriptId: this.autocompleteSuggestion.id,
        scriptGroupName: this.scriptGroups[this.autocompleteSuggestion.scriptGroupId].name,
        scriptGroupId: this.autocompleteSuggestion.scriptGroupId,
        scriptValue: this.autocompleteSuggestion.value,
        isAutocomplete: true,
        scriptType: this.autocompleteSuggestion.type
      };
      const finalSuggestion = {
        ...this.autocompleteSuggestion,
        value: this.autocompleteSuggestion.value.indexOf('<p>') === 0 ? this.autocompleteSuggestion.value : `<p>${this.autocompleteSuggestion.value}</p>`
      };

      // complete the suggestion only if the message is not already sent
      if (this.messageEditor._editor.root.innerText !== '\n') {
        this.messageEditor.handleAutocompleteTab(finalSuggestion);
        this.store.dispatch(ChatActions.SetScriptInUse({scriptInUse}));
        this._sendMessageType = SendMessageType.Script;
      }
    }
  }

  public onMessageInputSend(message: string, askMeAnythingRewriteContent: string, enhancedChatMessage: string, shouldAutoEnhance?: boolean): void {
    if (this.itgIsRunning) {
      // store message to send via modal action
      this.store.dispatch(ChatActions.StoredTextToSend({chatId: this.chatId, storedTextToSend: {text: message, isQuickSend: false}}));
    } else if (message !== null && message !== '') {
      const hasProfanity = this.reportProfanity(message, false);
      if (!hasProfanity) {
        // detectChanges() to force ng to update its bindings
        // so this.content and messageEditor.editorText are the same at this point
        // and setting this.content = '' below will be considered
        // a new value by the child control(s)
        this.changeDetector.detectChanges();

        this.dismissErrorBanner();
        this.clearText();

        if (this.suggestionInUse && this.suggestionInUse !== null) {
          this.sendSuggestion(message);
          return;
        }
        if (this.smartResponseInUse){
          this.sendSmartResponse(message);
          return;
        }
        if (this.cxGptResponseInUse){
          this.sendCxGptResponse(message);
          return;
        }

        this.sendMessage(message, this._sendMessageType, shouldAutoEnhance);

        if (askMeAnythingRewriteContent && askMeAnythingRewriteContent !== message){
          this.store.dispatch(LogActions.logSelectedChatAction({logPayload: {logType: LogType.chat, operationName: ChatOperations.AskMeAnythingRewriteModified}}));
        }

        if (enhancedChatMessage){
          const isEdited = enhancedChatMessage !== message;
          //Add dimensions only if edited
          const logDimensions = {
            editedMessage: message,
            enhancedMessage: enhancedChatMessage
          };
          this.store.dispatch(LogActions.logSelectedChatAction({logPayload: {logType: LogType.chat, operationName: ChatOperations.EnhanceResponseSent, data: isEdited ? logDimensions : null}}));
        }
      }
    }
  }

  private reportProfanity(message: string, isQuickSend: boolean): boolean {
    const { chatId } = this;
    const illegalWords = this.profanityService.test(message);
    const hasIllegalWord = (illegalWords.length > 0);

    if (hasIllegalWord) {
      const errorMessage = isQuickSend ?
        'You will need to remove any inappropriate words before sending your response.' :
        'You will need to remove any inappropriate words before sending your message.';

      this.store.dispatch(ProfanityActions.reportIllegalWords({ chatId, message, illegalWords, errorMessage }));
    }

    return hasIllegalWord;
  }

  clearText() {
    this.messageEditor._editor
      .setContents([
        {insert: '' },
        { insert: '\n' }
      ], 'api');
  }

  private _handleScriptResponseEvent(message: string, quickSend: boolean, messageType: SendMessageType, quickSendAction: (message: string, type: SendMessageType) => void) {
    if (quickSend) {
      if (!this.reportProfanity(message, true)) {
        this.dismissErrorBanner();
        quickSendAction(message, messageType);
      }
    }
    else {
      this.insertScript(message);

      this._sendMessageType = messageType;
      //focus called with delay so text can populate quill first
      this._focusSubject.next(null);
    }
  }

  clearAutocompleteValue(){
    if (!this.messageEditor?._editor?.editor?.delta) { return; }
    const data = {
      content: this.messageEditor._editor.editor.delta,
      suggestion: null
    };
    this.messageEditor.insertAutocompleteSuggestion(data);
  }

  private insertScript(scriptValue, replaceText?: boolean) {
    const scriptParagraph = scriptValue.indexOf('<p>') >= 0 ? scriptValue : `<p>${scriptValue}</p>`;
    const editorText = this.messageEditor?.getText();

    if (editorText !== null && editorText?.length > 0 && !replaceText) {
      this.messageEditor._editor.root.innerHTML += scriptParagraph;
    } else {
      this.messageEditor._editor.root.innerHTML = scriptParagraph;
    }
  }

  private _handleSuggestionEvent(args: SendSuggestion) {
    this.suggestionInUse = args;
    if (args) {
      const insertArgs = {
        messageText: args.body.message,
        dataCardContent: null
      };
      if (args.heading) {
        insertArgs.dataCardContent = '<strong>' + args.heading.title + '</strong>\n' + args.heading.message;
      }

      this.messageEditor.insertDataCard(insertArgs);
    }
  }

  private clearScriptsInUse() {
    if (this._sendMessageType === SendMessageType.Script) {
      const { chatId } = this;
      this._sendMessageType = SendMessageType.ChatLine;
      this.store.dispatch(ChatActions.ClearScriptsInUse({ chatId }));
    }
  }

  clearClickedSuggestion() {
    if (this.suggestionInUse) {
      this.store.dispatch(ChatActions.ClearClickedSuggestion(this.chatId));
      this.quickResponses = [];
    }
  }

  clearSmartResponseInUse() {
    this.smartResponseInUse = null;
  }

  clearCxGptResponseInUse() {
    this.cxGptResponseInUse = null;
  }

  handleSendResponsesChange(_event: boolean) {
    const quickResponseArgs: CustomerResponseButtonChange = {
      chatId: this.chatId,
      sendButtons: _event
    };
    this.store.dispatch(ChatActions.UpdateSendCustomerQuickResponses(quickResponseArgs));
  }

  sendMessage(message: string, type: SendMessageType, shouldAutoEnhance?: boolean) {
    const chatMessage: SendChatMessage = {
      chatId: this.chatId,
      conversationId: this.conversationId,
      message: message,
      timestamp: this.timeService.unix(),
      sender: SenderType.FixAgent,
      type: ChatMessageType.Message,
      sendMessageType: type, // TODO set in reducer and retrieve in effect?
      scriptTreeId: this.scriptTreeId,
      traceId: Guid.raw(),
      status: ChatMessageStatus.Pending,
      shouldAutoEnhance
    };
    
    this.store.dispatch(ChatActions.SendMessage({ chatMessage }));
    this._sendMessageType = SendMessageType.ChatLine;
    this.store.dispatch(ChatUiActions.clearAskMeAnythingRewriteAndEnhanceContent({chatId: this.chatId}));
  }

  sendSuggestion(editedMessage: string) {
    let { heading, isModified } = this.suggestionInUse;
    let sendMessage = editedMessage;

    // strip data card
    const dataCardIndex = editedMessage.indexOf('</span><p>');
    if (dataCardIndex < 0) {
      isModified = heading ? true : false;
      heading = null;
    } else {
      sendMessage = sendMessage.slice(dataCardIndex + 10);
    }

    // check if modified
    if (ChatHelper.isTextModified(this.suggestionInUse.body.message, sendMessage)) {
        isModified = true;
    }

    const sendSuggestionArgs: SendSuggestion = {
      ...this.suggestionInUse,
      body: {
        ...this.suggestionInUse.body,
        message: sendMessage
      },
      heading,
      isModified,
      removeCustomerButtons: this.removeCustomerButtons,
      timestamp: this.timeService.unix(),
      traceId: Guid.raw(),
    };

    // Send Via Action
    this.store.dispatch(ChatActions.SendSuggestion(sendSuggestionArgs));

    // Clear Selection
    this.clearClickedSuggestion();
  }

  sendSmartResponse(message: string) {
    const { chatId, smartResponseInUse, scriptTreeId } = this;
    const smartResponseToSend: SmartResponseToSendVm = {
      ...smartResponseInUse.smartResponse,
      parentMessageId: smartResponseInUse.parentMessageId,
      smartResponsesDisplayed: smartResponseInUse?.smartResponsesDisplayed?.map(s => s.id)?.join(',')
    };
    const chatMessage: SendChatMessage = {
      chatId,
      message,
      traceId: Guid.raw(),
      timestamp: this.timeService.unix(),
      scriptTreeId,
      sendMessageType: this._sendMessageType,
      status: ChatMessageStatus.Pending,
      sender: SenderType.FixAgent,
      type: ChatMessageType.Message
    };
    this.store.dispatch(ChatActions.SendSmartResponse({chatMessage, smartResponseToSend}));
  }

  sendCxGptResponse(message: string) {
    const { chatId, cxGptResponseInUse, scriptTreeId } = this;
    
    const chatMessage: SendChatMessage = {
      chatId,
      message,
      traceId: Guid.raw(),
      timestamp: this.timeService.unix(),
      scriptTreeId,
      sendMessageType: this._sendMessageType,
      status: ChatMessageStatus.Pending,
      sender: SenderType.FixAgent,
      type: ChatMessageType.Message
    };
    this.store.dispatch(ChatActions.SendCxGptResponse({chatMessage, cxGptResponse: cxGptResponseInUse.cxGptResponse}));
  }

  toggleAgentTypingStatus(isTyping: boolean) {
    if (this._isAgentTyping === isTyping) {
      return;
    }

    this._isAgentTyping = isTyping;
    this.updateAgentTypingStatus(isTyping);
  }

  updateAgentTypingStatus(isTyping: boolean) {
    const { chatId } = this;
    const action = isTyping
      ? ChatUiActions.agentTypingStarted
      : ChatUiActions.agentTypingStopped;

    this.store.dispatch(action({ chatId }));
  }

  initializeEditor() {
    this.enableEditor(this.messagingEnabled);
  }

  private enableEditor(enable: boolean) {
    if (this.messageEditor) {
      this.messageEditor.enableEditor(enable);
    }
  }

  focusEditor() {
    if (this.messageEditor) {
      this.messageEditor.focusEditor();
    }
  }

  handleContentFill(content: string, scriptsInUse: ScriptInUse[]){
    const text = this.messageEditor?.getText();
        
    this.store.dispatch(ChatUiActions.updateUndoButtonPreviousContent({
      scriptsInUse: scriptsInUse,
      smartResponseInUse: this.smartResponseInUse,
      suggestionInUse: this.suggestionInUse,
      chatId: this.chatId, 
      content: text
    }));

    this.clearSmartResponseInUse();
    this.clearScriptsInUse();
    this.clearClickedSuggestion();
    this.insertScript(content, true);
    this._focusSubject.next(null);
  }

  isEnhanceMessageButtonDisabled(enhancedChatMessage: string) {
    const text = this.messageEditor?.getText();
	return enhancedChatMessage && enhancedChatMessage === text;
  }
}
