// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable, Inject } from '@angular/core';
import { NiagaraLogAnalyticsLogger } from '@cxt-cee-chat/merc-ng-core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryItemTranslator } from './telemetry-item-translator';

@Injectable({
  providedIn: 'root'
})
export class DependencyFailureLogger {
  constructor(
    @Inject(NiagaraLogAnalyticsLogger)
    private niagaraLogAnalyticsLogger: NiagaraLogAnalyticsLogger,
    @Inject(TelemetryItemTranslator)
    private translator: TelemetryItemTranslator
  ) {}

  public logErrorEvent = (telemetryItem: ITelemetryItem): void => {
    const [isValid, event] = this.translator.tryCreateErrorEvent(telemetryItem);

    if (isValid) {
      this.niagaraLogAnalyticsLogger.logEvent(event);
    }
  }
}
