// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy, ElementRef, AfterViewInit } from '@angular/core';
import { AppState, ChatUiActions, FixAgentProfileService, ScriptQuickSendType, UiActions } from 'projects/entities/src/public_api';
import { SmartResponseVm } from 'projects/entities/src/lib/domain/models/smart-response-vm';
import { SmartResponseInUse } from 'projects/entities/src/lib/domain/models/smartResponseInUse';
import { HighlightOptions } from 'src/app/models/highlight-options';
import { Placeholder } from 'projects/entities/src/lib/domain/placeholders/placeholder.model';
import { PlaceholderHelper } from 'projects/entities/src/lib/utils/placeholder-helper';
import { SMART_RESPONSE_HIGHLIGHT_CLASS } from '../convo-recommended/convo-recommended.constants';

@Component({
  selector: 'merc-convo-smart-response-button',
  templateUrl: './smart-response-button.component.html',
  styleUrls: ['./smart-response-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartResponseButtonComponent implements OnInit, AfterViewInit {
  @Input() chatId: string;
  @Input() smartResponse: SmartResponseVm;
  @Input() highlightOptions: HighlightOptions[] = [];
  @Input() searchText: string[] = [];
  @Input() placeholders: Placeholder[];
  @Input() selectedMessageId: string;
  @Input() disabled: boolean = false;
  @Input() smartResponsesDisplayed: SmartResponseVm[];

  public highlightCustomClass: string =  SMART_RESPONSE_HIGHLIGHT_CLASS;
  isSingleClick: boolean = true;

  constructor(
    private store: Store<AppState>,
    private profileService: FixAgentProfileService,
    private element: ElementRef
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    const target = this.element.nativeElement.querySelector('.cee-btn--response');
    const config = {
      rootMargin: '0px',
      threshold : [0]
    };
    const isIntersecting = ( entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;
    const observer =  new IntersectionObserver((entries) => {
      const intersectedEntries = entries ? entries.filter( x => isIntersecting(x)) : [];
      if (intersectedEntries.length > 0) {        
        const recommendationIds = intersectedEntries.map(entry => entry.target.id).filter(id => Boolean(id));
        if (recommendationIds.length > 0) {
          this.store.dispatch(ChatUiActions.UpdateDisplayedSmartResponse({chatId: this.chatId, recommendationIds}));
        }
      }   
    }, config);
    observer.observe(target);
  }

  handleResponseClicked(event: MouseEvent) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.responseSingleClicked(event);
      }
    }, 250);
  }

  responseSingleClicked(event: MouseEvent) {
    const customSettings = this.profileService.getAgentCustomSettings();
    const quickSend = event.shiftKey && customSettings.scriptQuickSend === ScriptQuickSendType.ShiftClick;
    this.responseClicked(quickSend);
  }

  responseDoubleClicked(_event: MouseEvent) {
    this.isSingleClick = false;

    const customSettings = this.profileService.getAgentCustomSettings();
    const quickSend = customSettings.scriptQuickSend === ScriptQuickSendType.DoubleClick;
    this.responseClicked(quickSend);
  }

  responseClicked(quickSend: boolean) {
      this.dispatchResponseClicked(this.smartResponse, quickSend);
  }

  private dispatchResponseClicked(smartResponse: SmartResponseVm, quickSend: boolean) {
    const {chatId, placeholders, selectedMessageId, smartResponsesDisplayed} = this;
    const smartResponseInUse: SmartResponseInUse = {
      smartResponse,
      chatId,
      quickSend,
      isInEditPlaceholder: PlaceholderHelper.shouldEditSmartResponsePlaceholder(smartResponse.entities, placeholders),
      parentMessageId: selectedMessageId,
      smartResponsesDisplayed
    };

    this.store.dispatch(
      UiActions.SmartResponseClicked({ smartResponseInUse, placeholderKeys: smartResponse.entities })
    );
  }

}
