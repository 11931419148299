// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { ChatInteraction, SendSuggestion, TypingStatusChanged } from '../models';
import { DriftContent } from '../models/drift-content';
import { CustomerActivityStatus, DetailsSelectedTab } from '../models/enums';
import { ItgSuggestion } from '../models/itgSuggestion';
import { SelectMessageMethod } from '../models/logDimensions/select-message-log';
import { State } from './chat-ui.reducer';
import { SmartResponseInUse } from '../models/smartResponseInUse';
import { ScriptInUse } from '../models/setScriptInUse';

export const agentTypingStarted = createAction(
  '[ChatUi] Agent Typing Started',
  props<{ chatId: string }>()
);

export const agentTypingStopped = createAction(
  '[ChatUi] Agent Typing Stopped',
  props<{ chatId: string }>()
);

export const customerTypingStatusChanged = createAction(
  '[ChatUi] Customer Typing Status Changed',
  props<{ customerTypingStatus: TypingStatusChanged }>()
);

export const customerTypingStatusExpired = createAction(
  '[ChatUi] Customer Typing Status Expired',
  props<{ chatId: string }>()
);

export const agentSelectedChat = createAction(
  '[ChatUi] Agent Selected Chat',
  props<{ chatId: string, lastViewedTimestamp: number }>()
);

export const chatsRemoved = createAction(
  '[ChatUi] Chats Removed',
  props<{ chatIds: string[] }>()
);

export const chatViewed = createAction(
  '[ChatUi] Chat Viewed',
  props<{ chatId: string, lastViewedTimestamp: number }>()
);

export const searchScripts = createAction(
  '[ChatUi] Search Scripts',
  props<{ chatId: string, query: string }>()
);

export const selectChat = createAction(
  '[ChatUi] Select Chat',
  props<{ chatId: string, lastViewedTimestamp: number }>()
);

export const pinMessage = createAction(
  '[ChatUi] Pin Message',
  props<{chatId: string, messageId: string, source: string, intent?: string}>()
);

export const unpinMessage = createAction(
  '[ChatUi] Unpin Message',
  props<{chatId: string, messageId: string, source: string}>()
);

export const clearPinnedMessages = createAction(
  '[ChatUi] Clear Pinned Messages',
  props<{chatId: string}>()
);

export const updateDetailsPanelScrollPos = createAction(
  '[ChatUi] Update Details Panel Scroll Position',
  props<{chatId: string, detailsPanelScrollPosition: number}>()
);

export const updateAmaPanelScrollPos = createAction(
  '[ChatUi] Update Ama Panel Scroll Position',
  props<{chatId: string, scrollPosition: number}>()
);

export const updateDetailsPanelTabSelected = createAction(
  '[ChatUi] Update Details Panel Tab Selected',
  props<{chatId: string, detailsPanelTabSelected: DetailsSelectedTab}>()
);

export const toggleShowDispositions = createAction(
  '[ChatUi] Toggle Show Dispositions',
  props<{chatId: string}>()
);

export const toggleScriptGroup = createAction(
  '[ChatUi] Script Group Toggle',
  props<{ chatId: string, scriptGroupId: string }>()
);

export const expandAllScriptGroups = createAction(
  '[ChatUi] Expand All Script Groups',
  props<{ chatId: string }>()
);

export const collapseAllScriptGroups = createAction(
  '[ChatUi] Collapse All Script Groups',
  props<{ chatId: string }>()
);

export const toggleViewSystemScripts = createAction(
  '[ChatUi] View System Scripts Toggle',
  props<{ chatId: string }>()
);

export const hydrate = createAction('[ChatUi] Hydrate');

export const hydrateSuccess = createAction(
  '[ChatUi] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[ChatUi] Hydrate Failure');

export const startItg = createAction('[ChatUi] Start Itg', props<{ chatId: string, itgSuggestion: ItgSuggestion, timestamp: number, source: string }>());

export const pauseItg = createAction('[ChatUi] Pause Itg', props<{ chatId: string, title: string, timestamp: number, driftIntents?: DriftContent[], errorMessage?: string }>());

export const resumeItg = createAction('[ChatUi] Resume Itg', props<{ chatId: string, title: string, timestamp: number }>());

export const cancelItg = createAction('[ChatUi] Cancel Itg', props<{ chatId: string, title: string, timestamp: number }>());

export const itgError = createAction('[ChatUi] Itg Error', props<{ chatId: string, title: string, timestamp: number, message: string }>());

export const itgCompleted = createAction('[ChatUi] Itg Completed', props<{ chatId: string, title: string, timestamp: number }>());

export const itgSchedulerCompleted = createAction('[ChatUi] Itg Scheduler Completed', props<{ chatId: string, title: string, timestamp: number, contentCode: string }>());

export const itgSchedulerError = createAction('[ChatUi] Itg Scheduler Error', props<{ chatId: string, title: string, timestamp: number, contentCode: string }>());

export const itgContinueWithScheduler = createAction('[ChatUi] Itg Continue With Scheduler', props<{ chatId: string, title: string, timestamp: number }>());

export const dismissItg = createAction('[ChatUi] Dismiss Itg', props<{ chatId: string, title: string }>());

export const toggleSuggestionsIsExpanded = createAction('[ChatUi] Toggle Suggestions Is Expanded');

export const updateTextEditorContent = createAction('[ChatUi] Update Text Editor Content', props<{chatId: string, content: string}>());

export const nextCustomerActivityStatus = createAction(
  '[ChatUi] Next Customer Activity Status',
  props<{ chatId: string }>()
);

export const updateCustomerActivityStatus = createAction(
  '[ChatUi] Update Customer Activity Status',
  props<{ chatId: string, status: CustomerActivityStatus, lastStatus: CustomerActivityStatus, stopTimer: boolean, currentTime: number }>()
);

export const updateAutomationWarningIndex = createAction(
  '[ChatUi] Update Automation Warning Index',
  props<{ chatId: string, index: number, currentTime: number }>()
);

export const restartAbandonedChatAutomation = createAction(
  '[ChatUi] Restart Customer Activity Status',
  props<{ currentTime: number }>()
);

export const chatAutomationPinClicked = createAction(
  '[ChatUi] Chat Automation Pin Clicked',
  props<{ chatId: string, isPinned: boolean, pinnedElapsed: number }>()
);

export const showInactiveWarningPinTimer = createAction(
  '[ChatUi] Show Inactive Warning Pin Timer',
  props<{ chatId: string }>()
);

export const messageSelected = createAction(
  '[ChatUi] Message Selected',
  props<{ chatId: string, chatMessage: ChatInteraction, canSearchSmartResponses: boolean, selectionMethod: SelectMessageMethod }>()
);

export const UpdateDisplayedSmartResponse = createAction(
  '[Chat] Update Displayed Smart Response',
  props<{ chatId: string, recommendationIds: string[]}>()
);

export const clearMessageInputErrorMessage = createAction(
  '[Chat] Clear Message Input Error Message',
  props<{ chatId: string }>()
);

export const clearMessageInputInfoMessage = createAction(
  '[ChatUi] Clear Message Input Info Message',
  props<{ chatId: string }>()
);

export const updateUndoButtonPreviousContent = createAction(
  '[ChatUi] Update Undo Button Previous Content',
  props<{ chatId: string, content: string, scriptsInUse: ScriptInUse[], smartResponseInUse: SmartResponseInUse, suggestionInUse: SendSuggestion }>()
);

export const messageInputUndoClicked = createAction(
  '[ChatUi] Message Input Undo Clicked',
  props<{ chatId: string, isEnhance: boolean }>()
);

export const clearAskMeAnythingRewriteAndEnhanceContent = createAction(
  '[ChatUi] Clear Ask Me Anything Rewrite And Enhance Content',
  props<{ chatId: string }>()
);