// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Disposition } from './disposition';

export class DispositionCategory {
    id: string;
    displayText: string;
    dispositions: Disposition[];

    constructor() {
        this.dispositions = [];
    }
}