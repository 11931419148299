// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { ItgActionType, ItgState } from 'projects/entities/src/lib/domain/models/enums';
import { ItgMetadata } from 'projects/entities/src/lib/domain/models/itgMetadata';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import { ItgHelper } from 'projects/entities/src/lib/utils/itg-helper';
import { AppState, ChatUiActions, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'merc-convo-activity-status',
  templateUrl: './convo-activity-status.component.html',
  styleUrls: ['./convo-activity-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoActivityStatusComponent implements OnInit {
  @Input() chatId: string;
  itgsInProgress$: Observable<ItgMetadata[]>;
  bannerType: string = 'connect';
  ItgState = ItgState;

  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService
  ) { }

  ngOnInit() {
    this.itgsInProgress$ = this.store
      .select(fromChat.getItgsInProgressForActivityBar(this.chatId))
      .pipe(distinctUntilChanged());
  }

  updateItg(action: ItgActionType, title: string, itgState: ItgState) {
    const payload = {
      chatId: this.chatId,
      title,
      timestamp: this.timeService.unix()
    };

    if (action === ItgActionType.Pause) {
      this.store.dispatch(ChatUiActions.pauseItg(payload));
    } else if (action === ItgActionType.Resume) {
      this.store.dispatch(ChatUiActions.resumeItg(payload));
    } else if (action === ItgActionType.Cancel) {
      switch (itgState){
        case ItgState.Completed:
        case ItgState.Error:
        case ItgState.Expired:
        case ItgState.SchedulerError:
          this.store.dispatch(ChatUiActions.dismissItg({chatId: this.chatId, title}));
          break;
        default:
          this.store.dispatch(UiActions.ToggleItgModal({toggleItgModal: true, action}));
          break;
      }

    }
  }

  getNote(itgState: ItgState): string{
    switch (itgState) {
      case ItgState.Expired:
      case ItgState.Error:
        return 'Please refresh and resume troubleshooting in Einstein 360.';
      case ItgState.SchedulerError:
        return 'Please schedule the appointment in Einstein360.';
      case ItgState.Paused:
        return 'Once you resume the ITG the last step will automatically be repeated';
      default:
        return '';
    }
  }

  isErrorState(itgState: ItgState): boolean {
    return ItgHelper.isErrorState(itgState);
  }

  getCloseButtonType(itgState: ItgState): string {
    if (ItgHelper.isItgStateInProgress(itgState)) {
      return 'icon-filled';
    }

    return 'link';
  }

  getCloseTooltipText(itgState: ItgState): string {
    if (ItgHelper.isItgStateInProgress(itgState)) {
      return 'Cancel Autopilot';
    }

    return 'Dismiss';
  }
}
