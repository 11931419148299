// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { ItgState } from 'projects/entities/src/lib/domain/models/enums';

/*
 * Formats a date using a
 * from earliest to most recent
 * Usage:
 *   EngagementTranscript[] | sortAsyncEngagement
*/


@Pipe({name: 'formatItgState'})
export class FormatItgState implements PipeTransform {
  constructor(){
  }

  transform(state: ItgState, format: 'description' | 'bannerType' | 'icon' | 'showTimestamp', title?: string): string {
    if (format === 'description') {
      switch (state) {
        case ItgState.Running:
          return `${title} in progress`;
        case ItgState.Paused:
          return `${title} paused`;
        case ItgState.Completed:
          return `${title} complete`;
        case ItgState.Canceled:
          return `${title} abandoned`;
        case ItgState.Error:
        case ItgState.Expired:
          return `Something went wrong. ${title} cannot be run at this time.`;
        case ItgState.SchedulerError:
          return 'Something went wrong. The customer is unable to schedule their own appointment';
      }
    } else if (format === 'bannerType') {
      switch (state) {
        case ItgState.Running:
          return 'connect';
        case ItgState.Paused:
          return 'info';
        case ItgState.Completed:
          return 'success';
      }
    } else if (format === 'icon') {
      switch (state) {
        case ItgState.Running:
          return 'connect';
        case ItgState.Completed:
          return 'check-circle';
        case ItgState.Canceled:
        case ItgState.Error:
        case ItgState.Expired:
        case ItgState.Paused:
        case ItgState.SchedulerError:
          return 'info';
      }
    }
  }
}
