// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastDetails } from 'projects/entities/src/lib/domain/models/toast-details';
import { fromSettings, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-inline-toast',
  templateUrl: './inline-toast.component.html',
  styleUrls: ['./inline-toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InlineToastComponent extends SubscriberComponent implements OnInit, OnDestroy {
  toastDetails$: Observable<ToastDetails>;
  hideAnimations$: Observable<boolean>;

  constructor(
    private appStore: Store<AppState>,
    private ngZone: NgZone,
  ) {
    super();
   }

  ngOnInit() {
    this.toastDetails$ = this.appStore.select(fromUi.getToastDetails);
    this.toastDetails$.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(data => {
      if (data?.timer) {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.appStore.dispatch(UiActions.DismissInlineToast({dismissToast: true}));
          }, data.timer);
        });
     }
    });
    this.hideAnimations$ = this.appStore.select(fromSettings.hideAnimations);
  }

  onDismiss() {
    this.appStore.dispatch(UiActions.DismissInlineToast({dismissToast: true}));
  }

  triggerOpenSettings() {
    this.appStore.dispatch(UiActions.ToggleAgentSettingsPanel({ isOpen: true }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
