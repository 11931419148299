// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy,  Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { SendSuggestionSource, SuggestionType } from 'projects/entities/src/lib/domain/models/enums';
import { SendSuggestion } from 'projects/entities/src/lib/domain/models/requests/send-suggestion';
import { Suggestion } from 'projects/entities/src/lib/domain/models/suggestion';
import { AppState, ChatActions, ChatHelper, FixAgentProfileService, ScriptQuickSendType } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-suggestion-button',
  templateUrl: './suggestion-button.component.html',
  styleUrls: ['./suggestion-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SuggestionButtonComponent implements OnInit {
  @Input() selectedActiveChatId: string;
  @Input() suggestion: Suggestion;
  @Input() disabled: boolean = false;
  show: boolean = false;

  SuggestionType = SuggestionType;

  constructor(private profileService: FixAgentProfileService,
    private ngEntityStore: Store<AppState>,
    private timeService: DayTimeService) { }

  ngOnInit() {
  }

  getTitle(title: string, suggestionType: SuggestionType){
    return ChatHelper.getSuggestionTitle(title, suggestionType);
  }

  suggestionClicked(event: MouseEvent, suggestion: Suggestion) {
    // avoids clicking on parent when clicking show/hide button and X button
    const target = <HTMLElement>event.target;
    if (target?.classList.contains('merc-xa-show-more') || target?.classList.contains('merc-hide-suggestion')) {
      return;
    }

    const customSettings = this.profileService.getAgentCustomSettings();
    const useSuggestionArgs: SendSuggestion = {
      ...suggestion,
      chatId: this.selectedActiveChatId,
      source: SendSuggestionSource.AssistList,
      traceId: Guid.raw()
    };

    if (event.shiftKey && customSettings.scriptQuickSend === ScriptQuickSendType.ShiftClick) {
      useSuggestionArgs.isModified = false;
      useSuggestionArgs.timestamp = this.timeService.unix();
      this.ngEntityStore.dispatch(ChatActions.SendSuggestion(useSuggestionArgs));
    } else {
      this.ngEntityStore.dispatch(ChatActions.ClickSuggestion(useSuggestionArgs));
    }
    this.ngEntityStore.dispatch(ChatActions.ClearLatestSuggestions(this.selectedActiveChatId));
  }

  hideSuggestion(suggestion: Suggestion){
    this.ngEntityStore.dispatch(ChatActions.hideSuggestion({chatId: this.selectedActiveChatId, suggestion: suggestion}));
  }
}
