<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-template #innerTemplate>
  <h2 class="cee-h2 merc-agent-settings-tab-header">What's new</h2>
  <div class="merc-agent-settings-tab-body merc-agent-settings-tab-body--no-footer merc-feature-announcement-body">
    <div class="merc-feature-announcement-content">

      <h3 class="merc-feature-title">Agent Facing Metrics</h3>
      <p class="merc-feature-text">Agent Facing Metrics that are displayed in Mercury will now reflect the full day instead of session.</p>
      <img class="merc-feature-image" src="../../../../assets/img/feature-announcements/24.6.1/Agent-Facing-Metrics2x.png" alt="Agent Facing Metrics" />
      
      <h3 class="merc-feature-title">Recommended Greeting for Returning Async Conversations</h3>
      <p class="merc-feature-text">Agents will get a recommended message above the text editor for returning async conversations.</p>
      <img class="merc-feature-image" src="../../../../assets/img/feature-announcements/24.6.1/Release-Copy-Recommended-Greeting-for-Returning-Async-Conversations2x.png" alt="Recommended Greeting for Returning Async Conversations" />

      <h3 class="merc-feature-title">Inactive Timer before Auto-pause</h3>
      <p class="merc-feature-text">Mercury will now display a countdown timer for inactive async chats that indicates the amount of time remaining before they are auto-paused.</p>
      <img class="merc-feature-image" src="../../../../assets/img/feature-announcements/24.6.1/Inactive-Timer-before-Auto-pause2x.png" alt="Inactive Timer before Auto-pause" />
      
      <br/><br/>
      <i class="merc-feature-text">Refer to <a href="https://einstein360a.cable.comcast.com/albert?documentId=HOW13588" target="_blank">HOW13588</a> for details on bug fixes and previous Mercury release notes.</i>

    </div>
  </div>
</ng-template>

<!-- 
  Note Template:
    <h3 class="merc-feature-title">Paste Title here</h3>
    <p class="merc-feature-text">Paste Body here</p>
    <img class="merc-feature-image" src="../../../../assets/img/[image file here]" alt="alt text here" />
 -->

 <!-- Remember to edit the environment.default.ts file! -->