// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../domain/state';
import { AccessToken, AuthService, DateUnitType, DayTimeService, Environment, EnvironmentService, LoggingFactoryService, ResponseHandler, TokenStoreService } from '@cxt-cee-chat/merc-ng-core';
import { AgentActions, AgentAuthActions, AppActions, ChatActions, SettingsActions, toPayload } from './actions';
import { Inject, Injectable } from '@angular/core';
import { GetAccessToken } from './models/requests/getAccessToken';
import { LoginResponse } from './models/authentication/logInResponse';
import { LogIn } from './models/authentication/logIn';
import { ReLogIn } from './models/authentication/reLogIn';
import { AvailabilityType, LoggedOutMethod } from './models/enums';
import { VerifySession } from './models/authentication/verifySession';
import { AgentDefaultAvailabilityReasons, Constants, PageNames, StartGetUpdatesSources, StopGetUpdatesSources } from '../constants/constants';
import { LogHelper } from '../utils/logHelper';
import { AgentOperations, ChatOperations, ErrorEvents } from '../constants/event-logs.constants';
import { HttpResponse } from '@angular/common/http';
import { GetAccesstokenResponse } from './models/authentication/getAccesstokenResponse';
import { VerifySessionResponse } from './models/authentication/verifySessionResponse';
import { UnauthorizedVerifySession } from './models/unauthorizedVerifySession';
import { PageInitializationConstants } from '../constants/page-initialization.constants';
import { SetAgentAvailabilityRequest } from './models/requests/set-agent-availability-request';
import { fromAgent, fromAgentAuth, fromAgentAvailability, fromApp, fromChat, fromSettings } from './selectors';
import { getIsFormAuthenticationSuccess } from './auth.selectors';
import { FixAgentAuthApiService } from '../services/fix-agent-auth-api.service';
import { FixAgentFormAuthApiService } from '../services/fix-agent-form-auth-api.service';
import { UserIdentityService } from '../services/user-identity.service';
import { FixAgentApiService } from '../services/fix-agent-api.service';
import { NuanceFormAuthService } from '../services/nuance-form-auth.service';
import { AgentStatePersisterService } from '../services/agent-state-persister.service';
import { PageInitializationHelperService } from '../services/page-initialization-helper.service';
import { ChatPersisterService } from 'projects/entities/src/lib/domain/chat/chat-persister.service';
import { ChatUiPersisterService } from './chat-ui/chat-ui-persister.service';
import { Router } from '@angular/router';
import { MercuryRoutes } from 'src/app/constants/constants';
import { LockoutVerifySessionSettings } from '../constants/application-health.constants';
import { AuthPersisterService } from './auth/auth-persister.service';
import * as fromAuth from './auth.reducer';
import { FeatureFlags } from '../constants/featureFlags.constants';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Auth {
  constructor(
    private ngEntityStore: Store<AppState>,
    private actions: Actions,
    private authRestApiService: FixAgentAuthApiService,
    private fixagentFormAuthRestApiService: FixAgentFormAuthApiService,
    private userIdentityService: UserIdentityService,
    private fixAgentApiService: FixAgentApiService,
    private formAuthService: NuanceFormAuthService,
    private agentStatePersister: AgentStatePersisterService,
    private chatPersisterService: ChatPersisterService,
    private chatUiPersisterService: ChatUiPersisterService,
    private loggingFactory: LoggingFactoryService,
    @Inject(AuthService) private authService: AuthService,
    private tokenStore: TokenStoreService,
    private pageInitHelper: PageInitializationHelperService,
    private timeService: DayTimeService,
    private router: Router,
    private persisterService: AuthPersisterService,
    private environmentService: EnvironmentService,
  ) { }

  // AGENT AUTHENTICATION
  formAuthenticate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.formAuthenticate),
      map(action => toPayload<GetAccessToken>(action)),
      tap((token) => {
        const comcastToken = this.tokenStore.getToken<AccessToken>(Constants.ComcastToken).accessToken;
        const getAccesstokenRequest = {...token, accessToken : comcastToken };
        this.fixagentFormAuthRestApiService.getAccessToken(getAccesstokenRequest)
          .then
          (
            (response: HttpResponse<GetAccesstokenResponse>) => {
              if (response && response.body && response.body.accessToken) {
                const accessToken = new AccessToken();
                accessToken.accessToken = response.body.accessToken.token;
                const unixExpires = this.timeService.add(+response.body.accessToken.expiresIn, DateUnitType.Seconds);
                accessToken.expiresOn = this.timeService.toDate(unixExpires);
                accessToken.refreshToken = response.body.accessToken.refreshToken;
                accessToken.scope = response.body.accessToken.scope;
                accessToken.advancedAgent = response.body.accessToken.advancedAgent;
                this.ngEntityStore.dispatch(AgentAuthActions.formAuthenticated(accessToken));

                this.ngEntityStore.dispatch(AgentAuthActions.formAuthentication({success: true}));
              }
              else {
                this.ngEntityStore.dispatch(AgentAuthActions.formAuthentication({success: false}));
              }
            }
          )
          .catch(() => this.ngEntityStore.dispatch(AgentAuthActions.formAuthentication({success: false})));
      })
    ), { dispatch: false }
  );

  formAuthenticated$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.formAuthenticated),
      map(action => toPayload<AccessToken>(action)),
      tap((token) => {
        this.formAuthService.storeTokenInStore(token);
      })
    ), { dispatch: false }
  );

  logIn$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.logIn),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromAgent.getSelectedAgentGroups),
        this.ngEntityStore.select(fromAgentAvailability.getAvailability),
        this.ngEntityStore.select(getIsFormAuthenticationSuccess)
      ]),
      tap(([, agentGroups, defaultAvailability, isFormAuth]) => {
        this.authService.getToken()
          .then(token => {
            const creds: LogIn = {
              username: this.userIdentityService.username,
              accessToken: token.accessToken,
              agentGroupIds: agentGroups.map(m => m.id),
              isFormAuthenticated: Boolean(isFormAuth)
            };
            this.pageInitHelper.updateStatePending(PageInitializationConstants.VerifySessionLogIn);
            const logInPromise = this.authRestApiService.logIn(creds);
            logInPromise.then((logInResponse: HttpResponse<LoginResponse>) => {
              const responseModel: LoginResponse = logInResponse.body;

              // per business, no agents should be logging in as authenticated
              // if they are, we need to immediately change them to busy
              if (responseModel.automaticallySetToAvailable)
              {
                const setUnavail: SetAgentAvailabilityRequest = {
                  available: false,
                  reason: AgentDefaultAvailabilityReasons.Busy,
                  currentReason: AgentDefaultAvailabilityReasons.Available
                };

                this.agentStatePersister.storeAvailability({
                  reason: AgentDefaultAvailabilityReasons.Busy,
                  available: AvailabilityType.Unavailable,
                  timestamp: Date.now()
                });
                this.fixAgentApiService.setAvailability(setUnavail);
              } else {
                this.agentStatePersister.storeAvailability({
                  reason: defaultAvailability.reason,
                  available: defaultAvailability.available,
                  timestamp: Date.now()
                });
              }

              if (logInResponse && logInResponse.body && logInResponse.body.agentId) {
                this.userIdentityService.registeredId = logInResponse.body.agentId;
                this.ngEntityStore.dispatch(AgentAuthActions.loggedIn());
                this._logLogin();
              }
            });

            this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.VerifySessionLogIn, logInPromise);
          });
      })
    ), { dispatch: false }
  );

  reLogIn$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.reLogIn),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromAgentAvailability.getAvailability),
        this.ngEntityStore.select(fromChat.getChats),
        this.ngEntityStore.select(fromAgent.getSelectedAgentGroups),
        this.ngEntityStore.select(getIsFormAuthenticationSuccess)
      ]),
      tap(([, availability, currentChats, agentGroups, isFormAuthenticated]) => {
        this.authService.getToken()
          .then(token => {
            const creds: ReLogIn = {
              username: this.userIdentityService.username,
              accessToken: token.accessToken,
              reLogin: true,
              available: AvailabilityType.Available === availability.available,
              reason: availability.reason,
              agentGroupIds: agentGroups.map(m => m.id),
              isFormAuthenticated: Boolean(isFormAuthenticated)
            };
            this.authRestApiService.reLogIn(creds).then((loginResponse: HttpResponse<LoginResponse>) => {
              if (loginResponse?.body?.agentId) {
                this.userIdentityService.registeredId = loginResponse.body.agentId;
                this.ngEntityStore.dispatch(AgentAuthActions.reLoggedIn());
                this._logLogin();

                this.ngEntityStore.dispatch(AppActions.StartGetUpdates({source: StartGetUpdatesSources.reLogIn}));

                const chatIdsToJoin = [];
                // pending chats should have the previous eng ids
                currentChats.forEach(chat => {
                  const chatExists = loginResponse.body.pendingChats?.find(chatId => chat.chatId === chatId);
                  if (chatExists) {
                    chatIdsToJoin.push(chat.chatId);
                  }
                  else {
                    // if the chat is not within the pending chats array we are not connected to it on the nuance side
                    // so we need to remove it from the desktop
                    this.ngEntityStore.dispatch(ChatActions.Closed(chat));
                    LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatLost, chat);
                  }
                });
                this.ngEntityStore.dispatch(ChatActions.JoinChats({chatIds: chatIdsToJoin}));
              }

            });
          });
      })
    ),
    { dispatch: false }
  );

  verifySession$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.verifySession),
      concatLatestFrom(_action => this.ngEntityStore.select(fromAgentAuth.getLogInStatus)),
      tap(([, loggedIn]) => {
        this.getTokenCatchError(token => {
          this.userIdentityService.Hydrate(token);
          if (this.agentStatePersister.registeredId && this.agentStatePersister.username) {
            if (loggedIn) { // when hub reconnects
              this._callVerifySession(token, loggedIn);
            }
            else { //on initial page load
              this.pageInitHelper.updateStatePending(PageInitializationConstants.VerifySessionLogIn);
              const verifySessionPromise = this._callVerifySession(token, loggedIn);
              this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.VerifySessionLogIn, verifySessionPromise);
            }
          }
          else if (!token.advancedAgent) {
            this.ngEntityStore.dispatch(AgentAuthActions.logIn());
          }
        });
      })
    ),
    { dispatch: false }
  );

  unauthorizedVerifySession$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.unauthorizedVerifySession),
      map(action => toPayload<UnauthorizedVerifySession>(action)),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromAgentAuth.getLogInStatus),
        this.ngEntityStore.select(fromAgentAuth.getVerifyingSession),
        this.ngEntityStore.select(fromAgentAuth.getLoggingIn)
      ]),
      tap(([unauthorizedVerifySessionData, loggedIn, verifyingSession, loggingIn]) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.UnauthorizedVerifySession, { message: unauthorizedVerifySessionData.operationName });
        if (verifyingSession || loggingIn) { return; }
        this.getTokenCatchError(token => {
          this.userIdentityService.Hydrate(token);
          if (this.agentStatePersister.registeredId && this.agentStatePersister.username
            && unauthorizedVerifySessionData.data.agentId === this.agentStatePersister.registeredId) {
            // if the registeredIds don't match
            // we got a 401 on a session that is not the current session and we can drop this call
            this._callVerifySession(token, loggedIn);
          }
        });
      })
    ),
    { dispatch: false }
  );

  logOut$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.logOut),
      map(action => toPayload<LoggedOutMethod>(action)),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromChat.getChats),
        this.ngEntityStore.select(fromAgent.getSessionTimeoutInformation)
      ]),
      tap(([method, chats, sessionTimeoutInformation]) => {
        if (chats && chats.length === 0 || sessionTimeoutInformation) {
          this.ngEntityStore.dispatch(AppActions.StopGetUpdates({source: StopGetUpdatesSources.logOut}));
          // sends logout call to nuance
          this.authRestApiService.logOut()
            .then(() => {
              this.ngEntityStore.dispatch(AgentAuthActions.loggedOut(method));
            });
        }
      })
    ),
    { dispatch: false }
  );

  clearSession$ = createEffect(() =>
  this.actions.pipe(
      ofType(AgentAuthActions.clearSession),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromAgentAuth.getLogInStatus),
        this.ngEntityStore.select(fromAgent.getSessionTimeoutInformation)
      ]),
      tap(([, loggedIn, sessionTimeoutInformation]) => {
        if (loggedIn === false && !sessionTimeoutInformation){
          this.authService.logOut();
          this.agentStatePersister.clearAll();
          this.chatPersisterService.clear();
          this.chatUiPersisterService.removeState();
          sessionStorage.clear();
        }
      })
  ),
  { dispatch: false }
  );

  lockout$ = createEffect(() =>
    this.actions.pipe(
        ofType(AgentAuthActions.lockout),
        tap(() => {
            this.ngEntityStore.dispatch(AppActions.StopGetUpdates({source: StopGetUpdatesSources.lockout}));
            this.authRestApiService.lockout();
            this.router.navigate([MercuryRoutes.SessionTimeoutComponent]);
        })),
    { dispatch: false }
  );


  lockoutVerifySession$ = createEffect (() =>
    this.actions.pipe(
      ofType(
        AgentAuthActions.lockout,
        AgentAuthActions.lockoutPersistSession,
        AppActions.ServiceInitialized // for refresh
      ),
      delay(LockoutVerifySessionSettings.WaitTimeInSeconds * 1000),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromAgent.getIsLockedOut),
        this.ngEntityStore.select(fromAgent.getIsAgentUnauthorized)]),
      filter(([, isLockedOut, isAgentUnauthorized]) => isLockedOut && !isAgentUnauthorized),
      tap(() => {
        this.authService.getToken()
          .then(token => {
            const req: VerifySession = {
              accessToken: token.accessToken,
              agentId: this.agentStatePersister.registeredId,
              agentUsername: this.agentStatePersister.username
            };
           
            this.authRestApiService.verifySession(req, this.get401Handler()).then(response => {
              if (!(response?.body?.validSession)) {
                this.sendAgentUnAuthorizedAction(true);
            }});
          });
        this.ngEntityStore.dispatch(AgentAuthActions.lockoutPersistSession());
      })),
    { dispatch: false }
  );

  setNewVoiceSessionLogging$ = createEffect (() =>
    this.actions.pipe(
      ofType(AgentAuthActions.createVoiceSession),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromAgentAuth.getVoiceSessionId)
      ]),
      tap(([, sessionId]) => {
        this.loggingFactory.setSessionContext({ sessionId });
      })),
    { dispatch: false }
  );

  redirectToReactApp$ = createEffect(() =>
    this.actions.pipe(
      ofType(SettingsActions.applicationConfigurationLoaded),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromApp.getPageName),
        this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.MercuryReact))
      ]),
      filter(([, pageName, shouldUseReactApp]) => Boolean(
        pageName === PageNames.voice
        && this.router.url.toLowerCase().startsWith(`/${MercuryRoutes.Voice.toLowerCase()}`)
        && shouldUseReactApp
        && this.environmentService?.environment
      )),
      tap(() => {
        const envName = this.environmentService?.environment;
        if (envName === Environment.LocalDev) {
          window.location.href = 'https://local.voice.mercury.comcast.net:4200/';
        }
        else if (this.environmentService.isEnvironment(envName)){
          let environmentPrefix = '';
          
          switch (envName) {
            case Environment.Prod:
              //Do nothing
              break;
            case Environment.QaRelease:
              environmentPrefix = 'qarelease.';
              break;
            default:
              environmentPrefix = envName + '.';
          }
          const reactUrl = `https://${environmentPrefix}voice.mercury.comcast.net`;
          window.location.href = reactUrl;
        }
      })
    ),
    { dispatch: false }
  );

  hydrate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? AgentAuthActions.hydrateSuccess({ state })
          : AgentAuthActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(() =>
    this.actions.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for Agent Auth state change
      ofType(AgentAuthActions.hydrateSuccess, AgentAuthActions.hydrateFailure),
      switchMap(() => this.ngEntityStore.pipe(select(fromAgentAuth.getVoiceSessionId))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(voiceSessionId => {
        const saveState: fromAuth.State = {
          ...fromAuth.initialState,
          voiceSessionId,
        };
        this.persisterService.storeState(saveState);
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return AgentAuthActions.hydrate();
  }

  private _logLogin(): void {
    let message = '';
    if (this.agentStatePersister.registeredId && this.agentStatePersister.registeredId === this.userIdentityService.registeredId) {
      message = 'Refreshed';
    } else {
      message = 'New Login';
    }
    this.agentStatePersister.storeRegisteredId(this.userIdentityService.registeredId);
    this.agentStatePersister.storeUsername(this.userIdentityService.username);

    this.loggingFactory.setUserContext({
      userId: this.userIdentityService.username
    });
    this.loggingFactory.setSessionContext({
      sessionId: this.userIdentityService.registeredId
    });

    // Log login event
    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.LogIn, { method: this.agentStatePersister.authenticationProvider, message: message });
  }

  private _callVerifySession(token: AccessToken, loggedIn: boolean): Promise<HttpResponse<VerifySessionResponse>> {
    const req: VerifySession = {
      accessToken: token.accessToken,
      agentId: this.agentStatePersister.registeredId,
      agentUsername: this.agentStatePersister.username
    };
    this.ngEntityStore.dispatch(AgentAuthActions.verifyingSession({verifyingSession: true}));
    const verifySessionPromise = this.authRestApiService.verifySession(req);
    verifySessionPromise.then((response: HttpResponse<VerifySessionResponse>) => {
      if (!(response?.body?.validSession)) {
        if (loggedIn) { // if the state is already set to loggedin, the agent's connection was interrupted and we should use relogin
          this.ngEntityStore.dispatch(AgentAuthActions.reLogIn());
        }
        else {
          this.agentStatePersister.clearAgentData();
          this.ngEntityStore.dispatch(AgentAuthActions.logIn());
        }
      }
      else {
        this.userIdentityService.registeredId = this.agentStatePersister.registeredId;
        this.userIdentityService.username = this.agentStatePersister.username;
        this.ngEntityStore.dispatch(AgentAuthActions.setContinuedSession({continuedSession: true}));

        if (!loggedIn){
          this.ngEntityStore.dispatch(AgentAuthActions.loggedIn());
          this._logLogin();
        }
        else{
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionVerified);
        }
      }
    })
    .finally(() => {
      this.ngEntityStore.dispatch(AgentAuthActions.verifyingSession({verifyingSession: false}));
    });
    return verifySessionPromise;
  }

  private getTokenCatchError(action: (t) => void): void{
    this.authService.getToken()
      .then(token => {
        action(token);
      })
      .catch((error) => {
        LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.VerifySessionGetTokenError, { error });
        this.ngEntityStore.dispatch(AppActions.StopGetUpdates({source: StopGetUpdatesSources.getTokenError}));
        this.ngEntityStore.dispatch(AgentAuthActions.loggedOut(LoggedOutMethod.InvalidToken));
      });
  }

  get401Handler(){
    const unauthorizedLogOutHandler = new ResponseHandler();
    unauthorizedLogOutHandler.handle401 = (_opName, _data) => {
      this.sendAgentUnAuthorizedAction(true);
    };
    return unauthorizedLogOutHandler;
  }

  private sendAgentUnAuthorizedAction(isAgentUnauthorized: boolean){
    this.ngEntityStore.dispatch(AgentActions.updateIsAgentUnauthorized({isAgentUnauthorized}));
  }
}
