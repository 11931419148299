// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class InteractionTypes {
  static AddressUpdate: string = 'Address_Update';
  static AttachmentSent: string = 'Attachment_Sent';
  static CalendarSelection: string = 'Calendar_Selection';
  static Datapass: string = 'Datapass';
  static InformationMessage: string = 'Information_Message';
  static MediaCard: string = 'Media_Card';
  static MessageSent: string = 'Message_Sent';
  static PageNavigated: string = 'Page_Navigated';
  static ParticipantEnters: string = 'Participant_Enters';
  static ParticipantExits: string = 'Participant_Exits';
  static TopicSelection: string = 'Topic_Selection';
  static TransferInformation: string = 'Transfer_Information';
  static XaSuggestion: string = 'Xa_Suggestion';
  static CustomerDisconnected: string = 'Customer_Disconnected';
  static CustomerReconnected: string = 'Customer_Reconnected';
}
