<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{ 
  color: color$ | async,
  selectedChat: selectedChat$ | async,
  hasDispositions: hasDispositions$ | async
} as data">
  <div *ngIf="data.color" class="cee-convo-header cee-convo-header--{{data.color.id}}">
    <div class="cee-convo-header-customer-info">
      <cee-customer-avatar
      [customerName]="(customerName$ | async)"
      [customerNumber]="data.color.id">
      </cee-customer-avatar>
      <cee-dropdown-menu [labelText]="(customerName$ | async) | titlecase" tooltipWidth="150" tooltipText="Customer details" tooltipDirection="bottom" [hideLabel]="false" (dropdownChange)="onDetailsPanelClick($event)">      
        <merc-convo-details-tab [isOpen]="isDetailsTabOpen"></merc-convo-details-tab>
      </cee-dropdown-menu>
      <h3 class="cee-convo-header-account-num" *ngIf="(isAuthenticated$ | async) || (isAccountConnected$ | async)" data-qa="account-number">
        {{ accountNumber }}
      </h3>
      <ng-container *ngIf="!(isAuthenticated$ | async) && !(isLoading$ | async)">
        <cee-button id="btn-connect-account"
          *ngIf="!(isAccountConnected$ | async)"
          (buttonClick)="onConnectAccount()">
          Connect account
        </cee-button>
        <cee-button class="cee-btn--disconnect" type="link" size="icon"
        *ngIf="(isAccountConnected$ | async)"
        (buttonClick)="onConnectAccount()"
        tooltipText="Switch Accounts" tooltipId="unlink-account" tooltipWidth="100" tooltipDirection="bottom">
          <svg class="cee-icon cee-icon--secondary">
              <use href="/assets/img/svg-sprite.svg#icon-profile-shadowed"></use>
          </svg>
        </cee-button>
      </ng-container>
      <div class="cee-convo-auth-state" *ngIf="isAuthenticated$ | async">
        <div class="cee-convo-auth-state-icon authenticated">
          <div class="cee-verified-tooltip">
            <svg class="cee-icon cee-icon--md" data-qa="fully-verified-icon"
            [ceeTooltip]="{ text: 'Customer fully verified', width: 125, direction: 'bottom' }">
              <use href="/assets/img/svg-sprite.svg#icon-check-circle"></use>
            </svg>
          </div>
        </div>
        <p class="cee-convo-auth-state-text" data-qa="fully-verified">Fully Verified</p>
      </div>
    </div>
    <div class="cee-convo-header-convo-actions">
      <div class="cee-convo-header-disposition">
        <cee-dropdown-menu id="dropdown-history" class="cee-history-dropdown" labelText="History" labelFrontIcon="history" labelFrontIconWidth="13" hasNoPadding="true" [hideLabel]="false" (dropdownChange)="onHistoryPanelClick($event)" [isOpen]="historyPanelIsOpen$ | async"
        tooltipText="View previous conversations" tooltipId="previous-conversations" tooltipDirection="bottom" tooltipWidth="120">
          <div class="merc-chat-header-item">
            <merc-history-card-container [chat]="data.selectedChat"></merc-history-card-container>
          </div>
        </cee-dropdown-menu>
        <div class="cee-convo-header-chat-options">
          <svg class="cee-icon cee-icon--secondary" data-qa="convo-header-divider">
            <use href="/assets/img/svg-sprite.svg#icon-line"></use>
          </svg>
          <ng-container
            [ngTemplateOutlet]="transfer_button"
            [ngTemplateOutletContext]="{isDropdown:false}">
          </ng-container>
          <ng-container
              [ngTemplateOutlet]="close_button"
              [ngTemplateOutletContext]="{isDropdown:false}">
          </ng-container>
        </div>
        <!-- Only displays and replaces chat options if header runs out of text space due to resolution or zoom -->
        <div class="cee-convo-header-condensed-chat-options">
          <cee-dropdown-menu labelText="Close or Disposition" class="cee-condensed-chat-options-dropdown" labelIcon="kebab" [isOpen]="isOpen" (dropdownChange)="dropdownChange($event)" [labelIconWidth]="dispositionIconWidth" [hasNoPadding]="true">
            <ng-container
              [ngTemplateOutlet]="close_button"
              [ngTemplateOutletContext]="{isDropdown:true}">
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="transfer_button"
              [ngTemplateOutletContext]="{isDropdown:true}"></ng-container>
          </cee-dropdown-menu>
        </div>
        <ng-template #close_button let-isDropdown="isDropdown">
          <cee-button id="btn-close" type="{{isDropdown ? 'dropdown-item' : 'primary'}}" role="option" (buttonClick)="onCloseConversation(data.selectedChat, data.hasDispositions)" dataQa="close-conversation"
                      [tooltipText]="isDropdown ? '' : 'End conversation'" tooltipId="end-conversation" tooltipWidth="150" tooltipDirection="bottom">
            <svg class="cee-icon cee-icon--secondary" [ngClass]="{'dropdown-item--icon': isDropdown, 'cee-convo-header-btn--icon': !isDropdown}" data-qa="close-convo-icon">
              <use href="/assets/img/svg-sprite.svg#icon-close-conversation"></use>
            </svg> Close{{isDropdown ? " Conversation" : ""}}
          </cee-button>
        </ng-template>
        <ng-template #transfer_button let-isDropdown="isDropdown">
          <cee-button id="btn-transfer" type="{{isDropdown ? 'dropdown-item' : 'primary'}}" role="option" (buttonClick)="onTransferConversation(data.selectedChat, data.hasDispositions)" *ngIf="(isTransferrable$ | async)" dataQa="transfer-conversation"
                      [tooltipText]="isDropdown ? '' : 'Transfer customer to another agent'" tooltipWidth="150" tooltipId="tranfer-chat" tooltipDirection="bottom">
            <svg class="cee-icon cee-icon--secondary" [ngClass]="{'dropdown-item--icon': isDropdown, 'cee-convo-header-btn--icon': !isDropdown}">
              <use href="/assets/img/svg-sprite.svg#icon-transfer-conversation"></use>
            </svg> Transfer
          </cee-button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>