// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NuanceCredentials } from '../credentials';

export class UpdateCustomScripts extends NuanceCredentials {
    scriptCollection: ScriptCollection;
}
export class ScriptCollection {
    name: string;
    id: string;
    scripts: ScriptModel[] = [];
    childCollections: ScriptCollection[] = [];
}

export class ScriptModel {
    id: string;
    name: string;
    value: string;
}
