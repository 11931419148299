// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  ResponseHandler,
  RestApiService,
  LoggingFactoryService
} from '@cxt-cee-chat/merc-ng-core';
import { HttpHandler, HttpResponse } from '@angular/common/http';
import { NuanceCredentialRequestService } from './nuance-credential-request-service';

@Injectable({
  providedIn: 'root'
})
export abstract class NuanceCredentialRestApiService extends RestApiService {
  constructor(
    protected nuanceCredentialRequestService: NuanceCredentialRequestService,
    loggingService: LoggingFactoryService,
    httpHandler: HttpHandler
  ) {
    super(loggingService, httpHandler);
  }

  protected async postWithCredentials<TResponse>(
    op: string,
    data: any,
    responseHandler?: ResponseHandler
  ): Promise<HttpResponse<TResponse>> {
    const request = await this.nuanceCredentialRequestService.createRequest(data);
    return this.post<TResponse>(op, request, responseHandler);
  }
}
