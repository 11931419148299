<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="latestSuggestion$ | async">
  <ng-container *ngFor="let suggestion of (latestSuggestion$ | async); trackBy: trackByQueryId">
    <cee-suggestion-panel
      [title]="getTitle(suggestion.title, suggestion.suggestionType)"
      [titleIcon]="suggestion.suggestionType === SuggestionType.Xa ? 'xa' : ''"
      [quickResponses]="suggestion.body.buttons"
      [messageText]="suggestion.body.message"
      [dataCardContent]="formatDataCard(suggestion.heading)"
      [noAnimation]="hideAnimation$ | async"
      (panelDismiss)="handlePanelDismissed($event, suggestion)"
      (panelSend)="handlePanelSend($event, suggestion)"
      (panelClosed)="handlePanelClosed($event)">
    </cee-suggestion-panel>
  </ng-container>
</ng-container>
