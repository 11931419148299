// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, TokenStoreService, AccessToken, JwtTokenFactoryService, DayTimeService, IdAccessToken } from '@cxt-cee-chat/merc-ng-core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComcastSsoAuthService extends AuthService {

  protected get tokenStoreKey(): string {
    return 'comcastSsoToken';
  }

  constructor(tokenStore: TokenStoreService,
              tokenFactory: JwtTokenFactoryService,
              private router: Router,             
              private timeService: DayTimeService,
              private msalService: MsalService,
              @Optional()
              @Inject('renewTokenBeforeInSeconds')
              renewTokenBeforeInSeconds?: number) {
    super(tokenStore, tokenFactory, renewTokenBeforeInSeconds);
  }


  public redirectToAuthenticationProvider(): void {
    this.router.navigate(['']);
  }
  public storeTokenInStore(token: AccessToken): void {
    super.removeTokenFromStore();
    super.storeTokenInStore(token);
  }
  protected handleTokenRenewal(token: AccessToken): Promise<AccessToken> {
    return this.refreshToken(token);
  }

  public refreshToken(_token: AccessToken): Promise<AccessToken> {
    return new Promise<AccessToken>(
      (resolve, reject) => {
        const account = this.msalService.instance.getAllAccounts()[0];
        if (account){
          const request = {
          account: account,
          scopes: [`${environment.auth.comcastAadSso.clientId}/.default`]
          };

          this.msalService.acquireTokenSilent(request).toPromise().then(result => {
            if (result && result.accessToken){              
              resolve(this.CreateIdAccesstoken(result));
            }
          })
          .catch(() => {
            this.msalService.acquireTokenPopup(request).toPromise().then(result => {
              if (result && result.accessToken){
                resolve(this.CreateIdAccesstoken(result));
              }
            });
          });
        }
        else{
          reject();
        }
    });
  }
  
  public StoreIdTokenInStore(response: AuthenticationResult) {    
    this.storeTokenInStore(this.CreateIdAccesstoken(response));
  }

  private CreateIdAccesstoken(response: AuthenticationResult) {
    const token = new IdAccessToken();
    token.accessToken = response.accessToken;
    token.idToken = response.idToken;
        
    const unixExpires = this.timeService.unix(+response.expiresOn);
    token.expiresOn = this.timeService.toDate(unixExpires);
    return token;
  }
}
