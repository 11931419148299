// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { FixAgentApiService } from './fix-agent-api.service';
import { AppState } from '../domain/state';
import { Store } from '@ngrx/store';
import { AgentCustomSettings } from '../domain/models/agentCustomSettings';
import { UpdateCustomSettings } from '../domain/models/requests/updateCustomSettings';
import { ProfileActions } from '../domain/actions';
import { HttpResponse } from '@angular/common/http';
import { UpdateAgentCustomSettingsResponse } from '../domain/models/responses/update-agent-custom-settings-response';
import { fromSettings } from '../domain/selectors';


@Injectable({
    providedIn: 'root'
})
export class FixAgentProfileService {
    private _customSettings: AgentCustomSettings;
    constructor(
        private _fixagentApiService: FixAgentApiService,
        private _ngEntityStore: Store<AppState>,
    ) {
        this._ngEntityStore.select(fromSettings.getCustomSettings).subscribe((settings: AgentCustomSettings) => {
            this._customSettings = settings;
        });
    }

    public getAgentCustomSettings(): AgentCustomSettings {
        return this._customSettings;
    }

    public updateCustomSettings(model: AgentCustomSettings): Promise<HttpResponse<any>> {
        return new Promise<HttpResponse<any>>((resolve, reject) => {
            const updateCustomSettings = new UpdateCustomSettings();
            updateCustomSettings.settings = model;

            this._fixagentApiService.updateCustomSettings(updateCustomSettings)
                .then(
                (response: HttpResponse<UpdateAgentCustomSettingsResponse>) => {
                    if (response.body.settings) {
                        this._ngEntityStore.dispatch(ProfileActions.Updated(response.body.settings));
                    }
                    resolve(response);
                })
                .catch((err: any) => reject(err));
        });
    }
}
