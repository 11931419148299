<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  callSummary: callSummary$ | async,
  currentSessionSummaryState: currentSessionSummaryState$ | async,
  currentCallId: currentCallId$ | async
} as data">
  <cee-modal-v2 headerText="Close this session in Mercury?"
    [active]="true"
    [controls]="getControls(data.currentCallId, data.currentSessionSummaryState, data.callSummary)"
    [customModalClass]="data.callSummary?.error ? 'call-summary-error' : ''"
    (modalClose)="closeModal()">
    <merc-session-summary
      [sessionSummaryState]="currentSessionSummaryState"
    ></merc-session-summary>
  </cee-modal-v2>
</ng-container>

