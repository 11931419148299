// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ScriptType } from 'projects/entities/src/public_api';

export class ScriptInUse {
    scriptId: string;
    chatId: string;
    scriptGroupName: string;
    scriptValue: string;
    isAutocomplete?: boolean;
    scriptType?: ScriptType;
}