// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export const HubNames = {
  FixAgentChatRequestHub: 'FixAgentChatRequestHub',
  NiagaraLogHub: 'NiagaraLogHub',
  ReportingHub: 'ReportingHub',
  VoiceHub: 'VoiceHub',
} as const;

export type HubName = typeof HubNames[keyof typeof HubNames];