<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  managedScriptGroup: (currentlyManagedScriptGroup$ | async) || {},
  scriptGroup: (selectScriptGroupToEdit$ | async),
  allScriptGroups: (selectCustomScriptGroups$ | async) || []
} as data">
  <div class= "merc-manage-scripts--editor">
    <h4>Custom header</h4>
    <div class= "merc-manage-scripts--editor-body">
      <div class="cee-form-row cee-form-row--script-group" [class.has-error]="hasError">
        <label class="cee-form-label" for="header-name">Header Name</label>
        <input type="text"
          placeholder="Customize a header"
          [ngModel]="textInput" 
          (ngModelChange)="onInputChange(null, $event)"
          class="cee-form-input"
          [class.error]="hasError"
          id="header-name"
        >
        <p *ngIf="errorMessage" class="cee-form-error-msg" id="text-error-msg">{{errorMessage}}</p>
      </div>
    </div>
    <div class="cee-modal-footer"> 
      <div class="cee-modal-footer-buttons">
        <cee-button type="secondary" (buttonClick)="backToManagePopup()" >Cancel</cee-button>
        <cee-button  [disabled]="isSaveDisabled" (buttonClick)="onSave(data.managedScriptGroup, data.allScriptGroups)">Save</cee-button>
      </div>
    </div>
  </div>
</ng-container>