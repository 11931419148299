<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
[active]="true"
[controls]="controls"
[headerText]="headerText$ | async"
(modalClose)="closeModal()">
  <p>{{ modalText$ | async }}</p>
</cee-modal>


