// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { UpdateAccountRequest } from './updateAccountRequest';

export class UpdateAccountNumberRequest extends UpdateAccountRequest {
    authenticated: boolean;
    manualConnect: boolean;
    customerGuid?: string;
}
