// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOneCti from './one-cti.reducer';
import { AppState } from '../state';

const selectAppFeature = createFeatureSelector<AppState, fromOneCti.State>(fromOneCti.featureKey);

export const isOneCtiConnected = createSelector(
  selectAppFeature,
  (state: fromOneCti.State) => state.isChatServiceConnected && state.isListenerServiceConnected
);

export const isChatServiceConnected = createSelector(
  selectAppFeature,
  (state: fromOneCti.State) => state.isChatServiceConnected
);

export const isOneCtiReconnect = createSelector(
  selectAppFeature,
  (state: fromOneCti.State) => state.isOneCtiReconnect
);