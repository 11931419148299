// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NgModule, ModuleWithProviders } from '@angular/core';
import { NG_ENTITY_MODULE_IMPORTS } from './ng-entity.module.imports';
import { NG_ENTITY_MODULE_PROVIDERS } from './ng-entity.module.providers';
import { AzureActiveDirectoryTokenProvider } from './providers';

@NgModule({
    imports: [NG_ENTITY_MODULE_IMPORTS],
    providers: [NG_ENTITY_MODULE_PROVIDERS]
})
export class NgEntityModule {
    public static provideModule(): ModuleWithProviders<NgEntityModule> {
        return {
            ngModule: NgEntityModule,
            providers: [
                NG_ENTITY_MODULE_PROVIDERS,
                AzureActiveDirectoryTokenProvider
            ]
        };
    }
}
