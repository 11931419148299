// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromScriptGroup from './script-group.reducer';

@Injectable({
  providedIn: 'root'
})
export class ScriptGroupPersisterService extends StatePersister<fromScriptGroup.State> {
  constructor() {
    super(sessionStorage, fromScriptGroup.scriptGroupsFeatureKey);
  }
}
