// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { ScriptType, AppState, ScriptActions, UiActions } from 'projects/entities/src/public_api';
import { Script } from 'projects/entities/src/lib/domain/scripts';
import { ScriptsContextMenu } from 'src/app/models/scripts-context-menu';
import { Store } from '@ngrx/store';
import { fromUi } from 'projects/entities/src/lib/domain/selectors';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContextMenuOption } from 'src/app/models/context-menu-option';

@Component({
  selector: 'merc-convo-script-context-menu',
  templateUrl: './script-context-menu.component.html',
  styleUrls: ['./script-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScriptContextMenuComponent extends SubscriberComponent implements OnInit {
  script: Script;
  editOptionId: string = 'Edit';
  deleteOptionId: string = 'Delete';

  isOpen$: Observable<boolean>;
  menuX$: Observable<number>;
  menuY$: Observable<number>;
  options$: Observable<ContextMenuOption[]>;
  disabledText$: Observable<string>;


  constructor(
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    this.isOpen$ = this.store.select(fromUi.getIsScriptsContextMenuOpen);
    const contextMenu: HTMLElement = document.querySelector('.cee-context-menu-content');

    this.menuX$ = this.store.select(fromUi.getScriptsContextMenuClientX).pipe(takeUntil(this.destroyed$),
      map((clientX: number) => {
        return this.menuXPosition(clientX, contextMenu?.offsetWidth, window.innerWidth);
      }));

    this.menuY$ = this.store.select(fromUi.getScriptsContextMenuClientY).pipe(takeUntil(this.destroyed$),
      map((clientY: number) => {
        return this.menuYPosition(clientY, contextMenu?.offsetHeight, window.innerHeight);
      }));

    const script$ = this.store.select(fromUi.getScriptsContextMenuScript);

    script$.pipe(takeUntil(this.destroyed$)).subscribe((script: Script) => {
      this.script = script;
    });

    this.options$ = script$.pipe(takeUntil(this.destroyed$),
      map((script: Script) => {
        return this.getOptions(script);
      }));

    this.disabledText$ = script$.pipe(takeUntil(this.destroyed$),
      map((script: Script) => {
        return this.getDisabledText(script);
      }));
  }

  private getOptions(script: Script): ContextMenuOption[]{
    if (script && script.type === ScriptType.Custom){
      return [
        {
          id: this.editOptionId,
          displayText:
            '<span style="padding: 0 4px"><svg class="cee-icon cee-icon--secondary" > <use xlink:href="/assets/img/svg-sprite.svg#icon-edit"></use></svg></span> Edit'
        },
        {
          id: this.deleteOptionId,
          displayText:
            '<span style="padding: 0 4px"><svg class="cee-icon cee-icon--secondary" > <use xlink:href="/assets/img/svg-sprite.svg#icon-delete"></use></svg></span> Delete'
        }
      ];
    }
    return [];
  }

  private getDisabledText(script: Script): string {
    if (script && script.type === ScriptType.System){
      return 'Editing and deleting unavailable for system responses';
    }
    return '';
  }

  menuXPosition(clientX: number, menuMaxWidth: number, windowWidth: number): number {
    menuMaxWidth = menuMaxWidth || 100;
    return clientX + menuMaxWidth > windowWidth && clientX - menuMaxWidth > 0 ? clientX - menuMaxWidth : clientX;
  }

  menuYPosition(clientY: number, menuHeight: number, windowHeight: number): number {
    menuHeight = menuHeight || 50;
    return clientY + menuHeight > windowHeight && clientY - menuHeight > 0 ? clientY - menuHeight : clientY;
  }

  onMenuChange(open){
    const eventArgs = new ScriptsContextMenu();
    eventArgs.open = open;
    this.store.dispatch(UiActions.ToggleScriptsContextMenuAction({ scriptsContextMenu: eventArgs }));
  }

  onOptClicked(optionId) {
    const { id } = this.script;
    if (optionId === this.deleteOptionId) {
      this.store.dispatch(ScriptActions.deleteScriptConfirmation({ id }));
    }
    else if (optionId === this.editOptionId) {
      this.store.dispatch(ScriptActions.editScript({ id }));
    }
  }
}
