// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatDialogAppUpdateOptions, CtiRequests } from '../constants/cti.constants';
import { CtiChatRequest } from './cti-chat-request';

export class CtiAppUpdateRequest extends CtiChatRequest{
  action: string;
  area: string;
  updateApplication: string;
  messageName: string = CtiRequests.ChatDialogAppUpdate;
  public constructor(uui: string, sessionId: string, action: string, area: string){
    super(uui, sessionId);
    this.action = action;
    this.area = area;
    this.updateApplication = ChatDialogAppUpdateOptions.Applications.Einstein360;
  }
}
