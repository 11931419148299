// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export enum ErrorCode {
    UnexpectedError,
    UnableToRefreshAccessToken
}

export enum DisplayStatus {
    ClosedWrappingUP = 'Closed - Wrapping up',
    Connected = 'Connected',
    PendingPickUp = 'Pending pick up',
    Closed = 'Closed'
}

export enum BannerTypes {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Warning = 'warning'
}

export enum AgentActionTabs {
  Logout = 'Logout',
  TakeSurvey = 'Take Survey'
}

export enum AgentSettingsTabs {
  FeatureAnnouncement = 'What\'s new'
}