// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { ChatLanguageTranslation } from './language-translation.model';
import { ChatActions, LanguageTranslationActions } from '../actions';
import { LanguageMessageTranslationData } from '../models/message-translation-data';
import { ChatMessage, ChatMessageType } from '../models';

export const featureKey = 'languageTranslation';

export interface State extends EntityState<ChatLanguageTranslation> { }

export const adapter: EntityAdapter<ChatLanguageTranslation> = createEntityAdapter<ChatLanguageTranslation>({
  selectId: (languageTranslations: ChatLanguageTranslation) => languageTranslations.chatId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({});

function getChatTranslations({ chatId }: { chatId: string; }, state: State): ChatLanguageTranslation {
  return state.entities[chatId];
}

function getChatTranslationsDataList({ chatId }: { chatId: string; }, state: State): LanguageMessageTranslationData[] {
  return getChatTranslations({chatId}, state)?.translationData;
}

function updateTranslationData({ chatId }: { chatId: string; }, changes: Partial<ChatLanguageTranslation>, state: State): State {
  const update: Update<ChatLanguageTranslation> = {
    id: chatId,
    changes
  };

  return adapter.updateOne(update, state);
}

function createMessageTranslationData(text: string, messageId: string, traceId: string): LanguageMessageTranslationData {
  const translationEntry: LanguageMessageTranslationData = {
    traceId: messageId ?? traceId,
    translatedMessage: text
  };
  return translationEntry;
}

export const reducer = createReducer(
  initialState,
  on(LanguageTranslationActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(ChatActions.Bounced, ChatActions.ChatAcceptanceFailed, ChatActions.Closed, ChatActions.Transferred,
    (state, { payload }): State => {
      return adapter.removeOne(payload.chatId, state);
    }
  ),
  on(LanguageTranslationActions.chatMessagesTranslationUpdated,
    (state, { chatId, translatedMessages, isError, isAsyncTranslated }): State => {
      if (isError || !translatedMessages?.length) {
        return state;
      }

      const translationsList = getChatTranslationsDataList({chatId}, state);
      if (translationsList) {
        const toBeUpdatedTranslationsList = [
          ...translationsList
        ];
        translatedMessages.forEach(tm => {
          const index = toBeUpdatedTranslationsList.findIndex(td => td.traceId === tm.traceId); 
          if (index !== -1) {
            toBeUpdatedTranslationsList[index] = {
              ...toBeUpdatedTranslationsList[index],
              translatedMessage: tm.text
            };
          }
          else {
            const newTranslationData: LanguageMessageTranslationData = {
              traceId: tm.traceId,
              translatedMessage: tm.text
            };
            toBeUpdatedTranslationsList.push(newTranslationData);
          }
        });

        return updateTranslationData({chatId}, {
          translationData: toBeUpdatedTranslationsList,
          isAsyncTranslated
        }, state);
      }
      else {
        //Add brand new chat translation data if it does not exist.
        const translationData: LanguageMessageTranslationData[] = translatedMessages.map(tm => ({
          traceId: tm.traceId,
          translatedMessage: tm.text
        }));
        const languageTranslation: ChatLanguageTranslation = {
          chatId,
          translationData,
          isAsyncTranslated
        };

        return adapter.addOne(languageTranslation, state);
      }
    }
  ),
  on(ChatActions.NewMessage,
    (state, action) => {
      const message = action?.payload;
      const chatMessage = message as ChatMessage;
      if (chatMessage?.type !== ChatMessageType.Message || !chatMessage.translationData) {
        return state;
      }

      const translationsList = getChatTranslationsDataList({chatId: message.chatId}, state);
      const translationEntry = createMessageTranslationData(chatMessage.translationData.translatedMessage, chatMessage.messageId, chatMessage.traceId);
      if (translationsList) {
        const toBeUpdatedTranslationsList = [
          ...translationsList
        ];
        toBeUpdatedTranslationsList.push(translationEntry);

        return updateTranslationData(chatMessage, {
          translationData: toBeUpdatedTranslationsList
        }, state);
      }
      else {
        const languageTranslation: ChatLanguageTranslation = {
          chatId: chatMessage.chatId,
          translationData: [translationEntry]
        };

        return adapter.addOne(languageTranslation, state);
      }
  }),
  on(ChatActions.TextMasked,
    (state, { maskText, messageId }): State => {
      const { chatId, translatedMaskedChunks } = maskText;
      if (!translatedMaskedChunks) {
        return state;
      }

      const translationsList = getChatTranslationsDataList({chatId}, state);
      if (!translationsList?.length) {
        return state;
      }
      
      const toBeUpdatedTranslationsList = translationsList?.map(t => t.traceId === maskText.chatMessage?.messageId
        ? {
          ...t,
          traceId: messageId
        }
        : t);
      
      return updateTranslationData(maskText, {
        translationData: toBeUpdatedTranslationsList
      }, state);
    }
  ),
);

