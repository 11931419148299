// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { TranscriptMessage } from 'projects/entities/src/lib/domain/models/voice/transcriptMessage';
import { fromApp, fromCall, fromVoiceUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { CallActions, SenderType, VoiceUiActions } from 'projects/entities/src/public_api';
import { asyncScheduler, BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { throttleTime, map, filter, distinctUntilChanged, skip, delay } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { concatLatestFrom } from '@ngrx/effects';
import { CallErrorState, CallState } from 'projects/entities/src/lib/domain/models/voice/enums';
import { VoiceHelper } from 'projects/entities/src/lib/utils/voice-helper';

@Component({
  selector: 'merc-live-transcript',
  templateUrl: './live-transcript.component.html',
  styleUrls: ['./live-transcript.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LiveTranscriptComponent extends SubscriberComponent implements OnInit, OnDestroy, AfterViewInit {
  callState$: Observable<CallState>;
  callErrorState$: Observable<CallErrorState>;
  callStartTimestamp$: Observable<number>;
  callEndTimestamp$: Observable<number>;
  customerName$: Observable<string>;
  transcriptMessages$: Observable<TranscriptMessage[]>;
  highlightedMessages$: Observable<number[]>;
  scroll$: BehaviorSubject<number>;
  showJumpToLatestButton$: Observable<boolean>;
  isAtBottomThrottled$: Observable<boolean>;
  isAtBottom$: Observable<boolean>;

  senderType = SenderType;

  overrideBottomScroll: boolean = true;
  oldScrollPosition: number = 0;

  @ViewChild('scrollWindow') private scrollWindow!: ElementRef;

  constructor(
    private store: Store<AppState>,
    private timeHelper: TimeHelperService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.callState$ = this.store.select(fromCall.getCallState);
    this.callErrorState$ = this.store.select(fromVoiceUi.getCallErrorState);
    this.callStartTimestamp$ = this.store.select(fromCall.getCallStartTimestamp);
    this.callEndTimestamp$ = this.store.select(fromCall.getCallEndTimestamp);
    this.customerName$ = this.store.select(fromCall.getCustomerName);
    this.transcriptMessages$ = this.store.select(fromCall.getFilteredTranscriptMessages);
    this.highlightedMessages$ = this.store.select(fromCall.getHighlightedMessages);
    this.scroll$ = new BehaviorSubject<number>(0);

    const messageSub = this.transcriptMessages$
      .pipe(
        throttleTime(10, asyncScheduler, { leading: false, trailing: true })
      ).subscribe(() => {
        this.changeDetector.detectChanges();
      }
    );
    this.subscriptions.push(messageSub);

    const throttledScroll = this.scroll$.pipe(throttleTime(50, asyncScheduler, { leading: true, trailing: true }));
    this.isAtBottomThrottled$ = throttledScroll
      .pipe(map((scrollPos) => {
        return this.isAtBottom(scrollPos);
      }));

    this.isAtBottom$ = combineLatest([this.transcriptMessages$, this.scroll$])
      .pipe(
        throttleTime(50, asyncScheduler, { leading: false, trailing: true }),
        map(([, scrollPos]) => {
          const isAtBottom = this.isAtBottom(scrollPos);
          if (this.overrideBottomScroll && scrollPos < this.oldScrollPosition) {
            this.overrideBottomScroll = false;
          }
          else if (!this.overrideBottomScroll && isAtBottom) {
            this.overrideBottomScroll = true;
          }
          this.oldScrollPosition = scrollPos;
          return isAtBottom;
        })
      );

    this.showJumpToLatestButton$ = combineLatest([this.isAtBottomThrottled$, this.isAtBottom$])
      .pipe(
        throttleTime(2000, asyncScheduler, { leading: false, trailing: true }),
        map(([isAtBottomThrottled, isAtBottom]) => {
          return !isAtBottomThrottled && !isAtBottom && !this.overrideBottomScroll;
        })
      );

      const pageInitSub = this.store.select(fromApp.getPageInitializationSucceeded)
      .pipe(
        skip(1),
        filter(isLoaded => isLoaded),
        distinctUntilChanged(),
        delay(200)
      )
      .subscribe(() => {
          this.jumpToLatest();
      });
      this.subscriptions.push(pageInitSub);
    }

  public ngAfterViewInit(): void {
    const msgChangeSub = this.transcriptMessages$
      .pipe(
        concatLatestFrom(_ => this.isAtBottomThrottled$),
        filter((messages) => Boolean(messages?.length)),
        throttleTime(50, asyncScheduler, { leading: false, trailing: true }),
        distinctUntilChanged())
      .subscribe(([messages, isAtBottom]) => {
        if (messages?.length && (isAtBottom || this.overrideBottomScroll)) {
          this.jumpToLatest();
        }
      });
    this.subscriptions.push(msgChangeSub);
  }

  onScroll($event) {
    const scrollValue = $event.target.scrollTop ?? 0;
    this.scroll$.next(scrollValue);
  }

  isAtBottom(scrollPosition: number): boolean {
    if (this.scrollWindow?.nativeElement?.scrollHeight && this.scrollWindow?.nativeElement?.offsetHeight) {
      return this.scrollWindow?.nativeElement?.scrollHeight - this.scrollWindow?.nativeElement?.offsetHeight - scrollPosition <= 0;
    }
    return true;
  }

  jumpToLatestClicked() {
    this.store.dispatch(VoiceUiActions.JumpToLatestClicked({ source: 'liveTranscript' }));
    this.jumpToLatest();
  }

  jumpToLatest() {
    //Covers case where there's not enough content to have scrolling
    if (this.scrollWindow?.nativeElement?.scrollHeight) {
      this.scrollWindow.nativeElement.scrollTop = this.scrollWindow.nativeElement.scrollHeight;
    }
  }

  isActiveCall(callState: CallState) {
    return !VoiceHelper.isCallInactive(callState);
  }

  hasCallError(callErrorState: CallErrorState) {
    return VoiceHelper.hasCallError(callErrorState);
  }

  getFormattedTime(callTimestamp: number, messageOffset: number = 0): string {
    //Remember to fix time zone
    return this.timeHelper.formatTimeTwoHourDigitSeconds(callTimestamp + messageOffset, null);
  }

  getCallDuration(startTime: number, endTime: number) {
    let seconds = endTime - startTime;
    const hrs = Math.floor(seconds / (3600));

    seconds %= 3600;
    const mins = Math.floor(seconds / 60);
    seconds %= 60;

    const callDuration = `${mins}m ${seconds}s`;

    return hrs > 0 ? `${hrs}h ` + callDuration : callDuration;
  }

  isMessageHighlighted(utteranceSequence: number, highlightedMessages: number[]): boolean {
    const foundIndex = highlightedMessages?.find(m => m === utteranceSequence);
    return !!foundIndex || foundIndex === 0;
  }

  toggleHighlight(shouldHighlight: boolean, utteranceSequence: number, sender: SenderType) {
    if (shouldHighlight) {
      this.store.dispatch(CallActions.highlightTranscriptMessage({ utteranceSequence, sender }));
    } else {
      this.store.dispatch(CallActions.unhighlightTranscriptMessage({ utteranceSequence, sender }));
    }
  }

  getCustomerName(customerName: string) {
    return VoiceHelper.getCustomerName(customerName);
  }

  trackByMessageSequence(_index: number, transcriptMessage: TranscriptMessage){
    return `${transcriptMessage.utteranceSequence}-${transcriptMessage.partialSequence}`;
  }
}

