<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-live-transcript-body" (scroll)="onScroll($event)" #scrollWindow *ngIf="{
        callState: (callState$ | async),
        callErrorState: (callErrorState$ | async),
        callStartTimestamp: (callStartTimestamp$ | async),
        callEndTimestamp: (callEndTimestamp$ | async),
        customerName: (customerName$ | async),
        transcriptMessages: (transcriptMessages$ | async),
        highlightedMessages: (highlightedMessages$ | async),
        showJumpToLatestButton: (showJumpToLatestButton$ | async),
        isAtBottom: (isAtBottom$ | async)
    } as data">

    <ng-container *ngIf="isActiveCall(data.callState); else no_transcripts">
        <div class="merc-live-transcript-content">
            <ng-container [ngTemplateOutlet]="callDetails" [ngTemplateOutletContext]="{isStart:true}"></ng-container>

            <ng-container *ngFor="let transcriptMessage of data.transcriptMessages | sortBy:'asc':'utteranceSequence'; trackBy: trackByMessageSequence">
                <cee-call-entry
                    [isComplete]="transcriptMessage.isComplete"
                    [isPinned]="isMessageHighlighted(transcriptMessage.utteranceSequence, data.highlightedMessages)"
                    [timestamp]="getFormattedTime(data.callStartTimestamp, transcriptMessage.offsetTime)" [customerNumber]="2"
                    [customerName]="getCustomerName(data.customerName)" [isCustomer]="transcriptMessage.sender === senderType.Requester"
                    [isHighlightEntry]="false"
                    (togglePinClicked)="toggleHighlight($event, transcriptMessage.utteranceSequence, transcriptMessage.sender)">
                    {{ transcriptMessage.message }}
                </cee-call-entry>
            </ng-container>

            <ng-container [ngTemplateOutlet]="callDetails" [ngTemplateOutletContext]="{isStart:false}"
                *ngIf="data.callEndTimestamp">
            </ng-container>

            <cee-button-v2 customClass="merc-live-transcript-jump-to-latest"
                *ngIf="data.showJumpToLatestButton && !data.isAtBottom" (buttonClick)="jumpToLatestClicked()">
                <svg class="cee-icon">
                    <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-arrow'"></use>
                </svg>
                <span>Jump to Latest</span>
            </cee-button-v2>

            <ng-template #callDetails let-isStart="isStart">
                <div class="merc-transcript-call-info" [ngClass]="{ 'merc-transcript-call-info--ended': !isStart }">
                    <svg class="cee-icon merc-phone-icon">
                        <use
                            [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-phone-' + (isStart ? 'pick-up' : 'hang-up')">
                        </use>
                    </svg>
                    <div class="merc-transcript-call-text-wrapper">
                        <cee-timestamp class="merc-transcript-message-timestamp"
                            [dateTime]="getFormattedTime(isStart ? data.callStartTimestamp : data.callEndTimestamp)">
                        </cee-timestamp>
                        <span class="merc-transcript-call-details"> {{ isStart
                            ? "Verified call started"
                            : "Call ended " + getCallDuration(data.callStartTimestamp, data.callEndTimestamp)
                            }}
                        </span>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>

    <ng-template #no_transcripts>
        <div class="merc-empty-transcripts-notification"
            *ngIf="!hasCallError(data.callErrorState); else no_transcripts_error">
            <div class="merc-transcript-notification-icon-container">
                <svg class="cee-icon merc-empty-transcript-icon">
                    <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-conversation-bubbles'"></use>
                </svg>
            </div>
            <span class="merc-no-transcripts-message">Live Transcript will show what you and the customer are
                saying.</span>
        </div>
    </ng-template>

    <ng-template #no_transcripts_error>
        <div class="merc-empty-transcripts-notification">
            <div class="merc-transcript-notification-icon-container merc-transcript-notification-icon-container--error">
                <svg class="cee-icon merc-empty-transcript-icon">
                    <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-conversation-bubbles'"></use>
                </svg>
                <svg class="cee-icon cee-icon--lg merc-transcript-alert">
                    <use xlink:href="/assets/img/svg-sprite.svg#icon-alert-fill"></use>
                </svg>
            </div>
            <cee-banner-v2 type="error">
                Live transcript currently unavailable.
            </cee-banner-v2>
        </div>
    </ng-template>
</div>
