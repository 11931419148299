// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { Script, ScriptGroup, ScriptTree } from './models';

export const upsertScriptCollection = createAction(
  '[ScriptTree] Upsert Script Collection',
  props<{ scriptTree: ScriptTree, scriptGroups: ScriptGroup[], scripts: Script[] }>()
);

export const deleteScriptCollection = createAction(
  '[ScriptTree] Delete Script Collection',
  props<{ scriptTreeId: string }>()
);

export const loadCustomResponses = createAction(
  '[ScriptTree] Load Custom Scripts'
);
