// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { Chat, SendSuggestion } from 'projects/entities/src/lib/domain/models';
import { CloseSuggestionFeedbackMethod, DismissSuggestionReason } from 'projects/entities/src/lib/domain/models/enums';
import { fromAgent, fromChat, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AgentActions, AppState, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs/internal/Observable';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-dismiss-suggestion-feedback',
  templateUrl: './dismiss-suggestion-feedback.component.html',
  styleUrls: ['./dismiss-suggestion-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DismissSuggestionFeedbackComponent extends SubscriberComponent implements OnInit {
  @Output() ratingSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  feedbackOptions: string[] = [
    DismissSuggestionReason.NotRelevant,
    DismissSuggestionReason.NeedMoreInfo,
    DismissSuggestionReason.Incorrect,
    DismissSuggestionReason.WrongTone,
    DismissSuggestionReason.Other
  ];

  // Rate Connection takes priority over Dismiss Suggestion Feedback
  dismissedSuggestion$: Observable<SendSuggestion>;
  showRateConnectionQuality$: Observable<boolean>;
  isFeedbackSubmitted$: Observable<boolean>;
  chatColorId$: Observable<number>;
  customerName$: Observable<string>;
  chat$: Observable<Chat>;
  suggestionTitle$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService
  ) {
    super();
  }

  ngOnInit() {
    this.dismissedSuggestion$ = this.store.select(fromAgent.getLastDismissedSuggestion);
    this.suggestionTitle$ = this.store.select(fromAgent.getLastDismissedSuggestionTitle);
    this.isFeedbackSubmitted$ = this.store.select(fromUi.getIsDismissSuggestionFeedbackSubmitted);
    this.chatColorId$ = this.store.select(fromChat.getDismissSuggestionFeedbackChatColorId);
    this.customerName$ = this.store.select(fromChat.getDismissSuggestionFeedbackChatCustomerName);
    this.chat$ = this.store.select(fromChat.getDismissSuggestionFeedbackChat);
  }

  submitFeedback(feedbackOption: DismissSuggestionReason, dismissedSuggestion: SendSuggestion) {
    this.store.dispatch(AgentActions.SubmitDismissSuggestionFeedback({ reason: feedbackOption, dismissedSuggestion, timestamp: this.timeService.unix() }));
  }

  closeModal(feedbackSubmitted: boolean, chat: Chat) {
    const closeMethod = feedbackSubmitted
      ? CloseSuggestionFeedbackMethod.CloseButtonFeedbackSubmitted
      : CloseSuggestionFeedbackMethod.CloseButtonFeedbackNotSubmitted;
    this.store.dispatch(UiActions.CloseDismissSuggestionFeedback({ closeMethod, chat }));
  }
}
