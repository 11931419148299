// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MercuryRoutes } from '../../constants/constants';
import { Store } from '@ngrx/store';
import { AppState,
  AppActions,
  Constants} from 'projects/entities/src/public_api';
import { IdAccessToken, TokenStoreService } from '@cxt-cee-chat/merc-ng-core';
import { MsalService } from '@azure/msal-angular';
import { MSALInstanceFactory } from 'src/app/modules/app-module.providers';
import { AuthenticationResult } from '@azure/msal-common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'merc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  isRouted: boolean = false;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    protected ngEntityStore: Store<AppState>,
    private tokenStore: TokenStoreService,
    private authService: MsalService
  ) {}

  ngOnInit() {
    this.handleAuth();
  }

  switchToSso(){
    this.router.navigate([MercuryRoutes.SsoLogin]);
  }
  private handleAuth(): void
  {
    MSALInstanceFactory().
      handleRedirectPromise()
        .then((response: AuthenticationResult) => {
          if (response && response.idToken)
          {
            const token = new IdAccessToken();
            token.accessToken = response.idToken;
            token.idToken = response.accessToken;
            this.tokenStore.storeToken(Constants.ComcastToken, token);            
            this.ngEntityStore.dispatch(AppActions.InitializeService());
            this.isLoading$.next(false);
          }
          else
          {
            this.authService.loginRedirect();
          }
        }
      );
  }
}
