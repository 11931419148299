<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-banner [active]="errorMessage$ | async" id="login--error-banner" [type]="'error'">
  {{ errorMessage$ | async }}
</cee-banner>
<div class="cee-form-row">
  <div
    class="cee-form-row--group"
    [ngClass]="{ 'has-error': showUsernameError }"
  >
    <label class="cee-form-label" for="login-username">Username</label>
    <input
      type="text"
      class="cee-form-input"
      name="login-username"
      id="login-username"
      placeholder="Enter username"
      [(ngModel)]="username"
      (focusin)="onUsernameInputFocus()"
      (input)="toggleButton()"
      [ngClass]="{ error: showUsernameError }"
    />
    <p
      *ngIf="showUsernameError"
      id="username-error-msg"
      class="cee-form-error-msg"
    >
      Please enter your username.
    </p>
  </div>
  <div
    class="cee-form-row--group"
    [ngClass]="{ 'has-error': showPasswordError }"
  >
    <label class="cee-form-label" for="login-password">Password</label>
    <input
      type="password"
      class="cee-form-input"
      name="login-password"
      id="login-password"
      placeholder="Enter password"
      [(ngModel)]="password"
      (focusin)="onPasswordInputFocus()"
      (input)="toggleButton()"
      [ngClass]="{ error: showPasswordError }"
    />
    <p
      *ngIf="showPasswordError"
      id="password-error-msg"
      class="cee-form-error-msg"
    >
      Your password is required.
    </p>
  </div>
</div>
<div class="merc-login-form-submit" [ngClass]="{'center': !cancelText}">
    <cee-button x-test-target="cancel-button" *ngIf="cancelText" type="secondary" size="sm" (buttonClick)="onCancel()"
      >{{cancelText}}</cee-button
    >
    <cee-button x-test-target="submit-button" type="primary"  size="sm" (buttonClick)="onSubmit()"
      >{{submitText}}</cee-button
    >
  </div>
