<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-template #innerTemplate>
  <h2 class="cee-h2 merc-agent-settings-tab-header">Feedback</h2>
  <div class="merc-agent-settings-tab-body merc-agent-settings-feedback-tab">
    <h3 class="cee-h2" >Connection quality</h3>
    <h3 class="cee-h4">How is Mercury performing?</h3>
    <div *ngIf="!(isRatingSubmitted$ | async); else feedback_submitted">
      <merc-rate-connection (ratingSubmitted)="onShowFollowup($event)"></merc-rate-connection>
      <merc-rate-connection-followup *ngIf="showFollowup$ | async"></merc-rate-connection-followup>
    </div>
    <ng-template #feedback_submitted>
      <div class="merc-agent-settings-feedback-submitted-message">
        <svg class="cee-icon">
          <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-check-circle'"></use>
        </svg>
        <span>
          Your feedback helps improve Mercury!
        </span>
      </div>
    </ng-template>
  </div>
  <div class="merc-agent-settings-tab-footer" *ngIf="featureFlagMySupport$ | async">
    <p class="merc-agent-settings-mysupport"> Experiencing issues? Contact 
    <cee-button type="link" (buttonClick)="onPageInitMySupportClicked()">
      mySupport
    </cee-button>
    </p>
  </div>
</ng-template>
