// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { MercuryRoutes } from '../../../constants/constants';
import { Store } from '@ngrx/store';
import { AgentActions, AgentAuthActions, AgentStatePersisterService, AppState,
  AuthenticationProviderTypes,
  GetAccessToken } from 'projects/entities/src/public_api';
import { getIsFormAuthenticationSuccess } from 'projects/entities/src/lib/domain/auth.selectors';
import { Subject } from 'rxjs';


@Component({
  selector: 'merc-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['../login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit {
  @Input() submitText: string = 'Login';
  @Input() cancelText: string;
  buttonDisabled: boolean = true;
  username: string;
  password: string;
  errorMessage$: Subject<string> = new Subject<string>();
  showUsernameError: boolean = false;
  showPasswordError: boolean = false;
  isRouted: boolean = false;
  isLoading: boolean = true;

  constructor(
    private statePersister: AgentStatePersisterService,
    protected ngEntityStore: Store<AppState>
  ) {}

  ngOnInit() {
    this.ngEntityStore.select(getIsFormAuthenticationSuccess).
      subscribe((data: boolean) => {
        if (data === null) { return; }
        this.ngEntityStore.dispatch(AgentActions.UpdateLockout({ isLockedOut: false }));
        if (data){
          this.statePersister.storeAuthenticationProvider(AuthenticationProviderTypes.NuanceForm);
          this.statePersister.clearRegisteredId();
          this.statePersister.clearSelectedGroupIds();
          this.isRouted = true;
          this.redirectTo(MercuryRoutes.Home);
        }
        else if (!this.isRouted)
        {
          this.errorMessage$.next('Incorrect username or password. Try entering your information again.');
        }
      });
  }

  onSubmit() {
    this.showUsernameError = !this.username;
    this.showPasswordError = !this.password;
    if (this.showUsernameError || this.showPasswordError){
      this.errorMessage$.next('Login unsuccessful. Please try again.');
    }
    else {
      const getToken = new GetAccessToken();
      getToken.userName = this.username;
      getToken.password = this.password;
      this.errorMessage$.next('');
      this.ngEntityStore.dispatch(AgentAuthActions.formAuthenticate(getToken));
    }
  }

  onCancel(){
    this.statePersister.clearRegisteredId();
    this.ngEntityStore.dispatch(AgentActions.UpdateLockout({ isLockedOut: false } ));
    this.redirectTo(MercuryRoutes.FormLogin);
  }

  redirectTo(route: string){
    window.location.href = route;
  }

  toggleButton() {
    if (!this.password || !this.username) {
      this.buttonDisabled = true;
    } else if (this.password === '' || this.username === '') {
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = false;
    }
  }

  onUsernameInputFocus(){
    this.showUsernameError = false;
  }
  onPasswordInputFocus(){
    this.showPasswordError = false;
  }
}
