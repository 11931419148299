// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class UpdateAccountInformation {
    chatId: string;
    accountNumber: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    authenticated: boolean;
    isAccountConnected?: boolean;
    updateDisplayName: boolean;
}
