// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  getSelectedChatId } from '../chat.selectors';
import { AppState } from '../state';
import { CxGptResponses } from './cx-gpt-responses.model';
import * as fromCxGptResponses from './cx-gpt-responses.reducer';
import { LoadingState } from '../models/enums';

export const selectCxGptResponsesFeature = createFeatureSelector<AppState, fromCxGptResponses.State>(fromCxGptResponses.featureKey);

const {  
  selectAll: selectAllCxGptResponses
} = fromCxGptResponses.adapter.getSelectors(selectCxGptResponsesFeature);


export const getAllCxGptResponses = createSelector(
  selectAllCxGptResponses,
  (cxGptResponses: CxGptResponses[]) => cxGptResponses
);

export const getCxGptResponsesByChatId = (chatId: string) => createSelector(
  getAllCxGptResponses,
  (cxGptResponses: CxGptResponses[]) => cxGptResponses.find(s => s.chatId === chatId)
);

export const IsCxGptResponseFailed = createSelector(
  getSelectedChatId,
  getAllCxGptResponses,
  (chatId, cxGptResponses) => {
    const selectedResponses = cxGptResponses.find(response => response.chatId === chatId);
    return selectedResponses?.state === LoadingState.Failed;
  }
);

export const getLatestCxGptResponsesByChatId = (chatId: string) => createSelector(
  selectAllCxGptResponses,
  (cxGptResponses: CxGptResponses[]) => {
    const responses = cxGptResponses.find(s => s.chatId === chatId);
    return responses?.cxGptResponses?.length > 0 ? responses.cxGptResponses.find(f => f?.isLatest) : null;
  }
);

export const getLatestCxGptResponsesForSelectedChatId = createSelector(
  getSelectedChatId,
  selectAllCxGptResponses,
  (chatId: string, cxGptResponses: CxGptResponses[]) => {
    const responses = cxGptResponses.find(s => s.chatId === chatId);
    return responses?.cxGptResponses?.find(f => f?.isLatest);
  }
);
