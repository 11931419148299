// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export enum AudioNotification {
  NewChat,
  NewMessageOther,
  NewMessageCurrent,
  OutgoingMessage,
  ChatEnds,
  BouncedChat,
  UnpinnedChat,
  TestSound,
  CustomerAwaitingReply
}
