// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ButtonControl, ButtonType } from '@cxt-cee-chat/merc-pattern-lib';

@Component({
  selector: 'merc-agent-settings-close-chats-modal',
  templateUrl: './close-chats-modal.component.html',
  styleUrls: ['./close-chats-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseChatsModalComponent implements OnInit {
  @Input() active: boolean = false;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  controls: ButtonControl[] = [];

  constructor(
  ) {}

  ngOnInit(): void {
    this.controls = [
      {
        text: 'OK',
        type: ButtonType.Primary,
        disabled: false,
        controlFunction: () => this.closeModal()
      }
    ];
  }

  closeModal(): void {
    this.modalClose.emit(true);
  }
}
