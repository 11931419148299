// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService, DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Constants, NavigationSources, StopGetUpdatesSources } from '../../constants/constants';
import { AgentOperations, DebugEvents } from '../../constants/event-logs.constants';
import { FeatureFlags } from '../../constants/featureFlags.constants';
import { PageInitializationConstants } from '../../constants/page-initialization.constants';
import { AgentStatePersisterService } from '../../services/agent-state-persister.service';
import { FixAgentApiService } from '../../services/fix-agent-api.service';
import { PageInitializationHelperService } from '../../services/page-initialization-helper.service';
import { UserIdentityService } from '../../services/user-identity.service';
import { LogHelper } from '../../utils/logHelper';
import { AgentActions, AgentAuthActions, AgentAvailabilityActions, AppActions, ChatActions, LogActions, toPayload } from '../actions';
import { AvailabilityChange } from '../models/availabilityChange';
import { CloseAsyncChat } from '../models/closeAsyncChat';
import { CloseChat } from '../models/closeChat';
import { AvailabilityType, LoggedOutMethod } from '../models/enums';
import { GetAgentAnalyticsResponse } from '../models/getAgentAnalyticsResponse';
import { GetAgentMetrics } from '../models/getAgentMetrics';
import { GetAgentSalesConversionMetrics } from '../models/getAgentSalesConversionMetrics';
import { LoadAgentAnalytics } from '../models/loadAgentAnalytics';
import { LogInfo, LogType } from '../models/LogTypeInterfaces';
import { GetAgentAvailabilitySummary } from '../models/requests/getAgentAvailabilitySummary';
import { TransferChat } from '../models/requests/transferChat';
import { GetAgentAvailabilitySummaryResponse } from '../models/responses/get-agent-availability-summary-response';
import { GetAgentGroupsResponse } from '../models/responses/get-agent-groups-response';
import { GetAgentMetricsResponse } from '../models/responses/get-agent-metrics-response';
import { GetAgentSalesConversionMetricsResponse } from '../models/responses/get-agent-sales-conversion-metrics-response';
import { SessionTimeoutInformation } from '../models/sessionTimeoutInformation';
import { fromAgent, fromAgentAvailability, fromApp, fromChat, fromSettings } from '../selectors';
import { hasFeatureFlag } from '../settings/settings.selectors';
import { AppState } from '../state';
import { AgentPersisterService } from './agent-persister.service';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Agent implements OnInitEffects {
  constructor(
    @Inject(AuthService) protected authService: AuthService,
    private ngEntityStore: Store<AppState>,
		private actions: Actions,
    private pageInitHelper: PageInitializationHelperService,
    private fixAgentApiService: FixAgentApiService,
    private agentStatePersister: AgentStatePersisterService,
    private userIdentityService: UserIdentityService,
    private loggingFactory: LoggingFactoryService,
    private persisterService: AgentPersisterService,
    private timeService: DayTimeService,
    protected config: AppConfigService,
  ) {
  }
    loadAgentMetrics$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentActions.LoadAgentMetrics),
            tap(() => {
                const getCompletedChatCount = new GetAgentMetrics();
                this.authService.getToken().then(token => {
                    this.userIdentityService.Hydrate(token);
                    getCompletedChatCount.agentUsername = this.userIdentityService.username;
                    this.fixAgentApiService.getAgentMetrics(getCompletedChatCount).then(
                        (response: HttpResponse<GetAgentMetricsResponse>) => {
                            this.ngEntityStore.dispatch(AgentActions.AgentMetricsLoaded(response.body));
                        }
                    );
                });
            })
        ),
        { dispatch: false }
    );

    loadAgentAnalytics$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentActions.LoadAgentAnalytics),
            map(action => toPayload<LoadAgentAnalytics>(action)),
            tap((loadAgentAnalytics: LoadAgentAnalytics) => {
                const getAgentAnalytics = new GetAgentMetrics();
                this.authService.getToken().then(token => {
                    this.userIdentityService.Hydrate(token);
                    getAgentAnalytics.agentUsername = this.userIdentityService.username;

                    if (loadAgentAnalytics.source === 'manual') {
                        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.RefreshAnalytics);
                    }

                    this.fixAgentApiService.getAgentAnalytics(getAgentAnalytics)
                        .then(
                            (response: HttpResponse<GetAgentAnalyticsResponse>) => {
                                this.ngEntityStore.dispatch(AgentActions.UpdateAgentAnalytics(response.body));
                            }
                        ).catch(
                            () => this.ngEntityStore.dispatch(AgentActions.GetAgentAnalyticsFailed())
                        );
                });
            }),
        ),
        { dispatch: false }
    );

    loadAgentGroups$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentActions.LoadAgentGroups),
            tap(() => {
                this.pageInitHelper.updateStatePending(PageInitializationConstants.GetAgentGroups);
                const getAgentGroupsPromise = this.fixAgentApiService.getAgentGroups();
                getAgentGroupsPromise.then(
                    (response: HttpResponse<GetAgentGroupsResponse>) => {
                        this.ngEntityStore.dispatch(AgentActions.UpdateAgentGroups(response.body.agentGroups));
                    });
                this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.GetAgentGroups, getAgentGroupsPromise);
            })),
        { dispatch: false }
    );

  sessionLockoutPageLoad$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.SessionLockoutPageLoad),
      tap(() => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutPageLoad);
      })),
    { dispatch: false }
  );

  lockedOut$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.UpdateLockout),
      filter(({isLockedOut}) => isLockedOut),
      concatLatestFrom(_ => this.ngEntityStore.select(fromChat.hasChats)),
      tap(([, hasChats]) => {
        if (hasChats){
          this.ngEntityStore.dispatch(AgentActions.HandleOpenChats());
        }
        else {
          this.ngEntityStore.dispatch(AgentActions.SessionTimeout());
        }
      })),
    { dispatch: false }
  );

    handleOpenChats$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentActions.HandleOpenChats),
            concatLatestFrom(_ => [
              this.ngEntityStore.select(fromAgent.getAgentGroups),
              this.ngEntityStore.select(fromChat.getChats)
            ]),
            tap(([, agentGroups, chats]) => {
                if (chats && chats.length) {
                    chats.forEach(chat => {
                        if (!chat.isAsync) {
                            // map agent group name to id for transfer
                            let agentGroupName = chat.agentGroupId;

                            if (agentGroups && agentGroups.length) {
                                agentGroups.forEach(group => {
                                    if (group.id === chat.agentGroupId) {
                                        agentGroupName = group.displayName;
                                    }
                                });
                            }

                            if (chat.agentGroupId) {
                                const transferChatModel: TransferChat = {
                                    chatId: chat.chatId,
                                    targetQueueId: chat.agentGroupId,
                                    targetQueueName: agentGroupName,
                                    targetQueueBusinessUnitId: chat.businessUnitId,
                                    targetQueueBusinessUnitName: chat.businessUnitId,
                                    notes: 'Agent session timeout'
                                };

                                this.ngEntityStore.dispatch(ChatActions.Transfer(transferChatModel));
                                LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutTransfer, { engagementId: chat.chatId });
                            } else {
                                const closeChat: CloseChat = {
                                    chatId: chat.chatId,
                                    unresolvedContactReason: ''
                                };

                                this.ngEntityStore.dispatch(ChatActions.Close(closeChat));
                                LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutClose, { engagementId: chat.chatId });
                            }

                        } else {
                            const closeModel: CloseAsyncChat = {
                                chatId: chat.chatId,
                                resolved: false,
                                contactReason: ''
                            };

                            this.ngEntityStore.dispatch(ChatActions.CloseAsyncChat(closeModel));
                            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutClose, { engagementId: chat.chatId });
                        }
                    });
                }
                this.ngEntityStore.dispatch(AppActions.StopGetUpdates({source: StopGetUpdatesSources.lockoutHandleOpenChats}));
            })),
        { dispatch: false }
    );

    openChatsHandled$ = createEffect(() =>
      this.actions.pipe(
        ofType(ChatActions.Transferred, ChatActions.Closed),
        concatLatestFrom(_ => [
          this.ngEntityStore.select(fromChat.hasChats),
          this.ngEntityStore.select(fromAgent.getIsLockedOut)
        ]),
        filter(([, hasChats, isLockedOut]) => !hasChats && isLockedOut),
        tap(() => {
          this.ngEntityStore.dispatch(AgentActions.SessionTimeout());
        })),
      { dispatch: false }
    );

    sessionTimeout$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentActions.SessionTimeout),
            withLatestFrom(
                this.ngEntityStore.pipe(select(fromSettings.getSessionLockOutSettings))
            ),
            tap(([, lockoutSettings]) => {
                let fullName = 'Agent';
                const timestamp = this.agentStatePersister.availabilityStatus && this.agentStatePersister.availabilityStatus.timestamp ? this.agentStatePersister.availabilityStatus.timestamp : Date.now();

                if (this.agentStatePersister.availabilityStatus && this.agentStatePersister.availabilityStatus.available === AvailabilityType.Available) {
                    const availArgs: AvailabilityChange = {
                        available: AvailabilityType.Unavailable,
                        reason: 'Busy',
                        timestamp: timestamp
                    };
                    this.ngEntityStore.dispatch(AgentAvailabilityActions.updateAvailability(availArgs));
                }

                this.userIdentityService.fullName.pipe(filter((value) => value !== ''), take(1)).subscribe(name => {
                    fullName = name;
                });

                const sessionTimeoutInformation: SessionTimeoutInformation = {
                    fullName: fullName,
                    busyTimestamp: timestamp,
                    lockoutTimestamp: this.timeService.unix(),
                    settingDuration: lockoutSettings.logoffCountdownDuration
                };

                this.ngEntityStore.dispatch(AgentAuthActions.lockout({ sessionTimeoutInformation }));
            })),
        { dispatch: false }
    );

    navigateToSupportPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.NavigateToSupportPage),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromChat.selectChatIds),
        this.ngEntityStore.select(fromApp.getPageInitializationErrorList)
      ]),
      tap(([{ source }, chatIds, loggingData]) => {
        window.open(Constants.ComcastSupportUrl, '_blank');

        const logDimensions: any = {
            source: source
        };
        switch (source) {
            case (NavigationSources.actionBar):
                logDimensions.engagementIds = chatIds;
                break;
            case (NavigationSources.pageInit):
                logDimensions.errorTypes = loggingData ?? [];
                break;
            default:
                break;
        }

        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.MySupportOpened, logDimensions);
      })
    ),
    { dispatch: false }
  );

  cancelSessionTimeout$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.cancelSessionTimeout),
      tap(() => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutLogout, { isManual: true });
        this.ngEntityStore.dispatch(AgentAuthActions.logOut(LoggedOutMethod.ManualLockout));
      })),
    { dispatch: false }
  );

  sessionTimeoutExpired$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.sessionTimeoutExpired),
      tap(() => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutLogout, { isManual: false });
        this.ngEntityStore.dispatch(AgentAuthActions.logOut(LoggedOutMethod.AutomaticLockout));
      })),
    { dispatch: false }
  );

  sessionTimeoutReauthenticate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.sessionTimeoutReauthenticate),
      tap(() => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutReAuthenticate);
      })),
    { dispatch: false }
  );

  rehydrateUserIdentityService$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.rehydrateUserIdentityService),
      tap(() => {
        this.authService.getToken()
          .then(token => {
            this.userIdentityService.Hydrate(token);
            this.userIdentityService.registeredId = this.agentStatePersister.registeredId;
          });
      })),
    { dispatch: false }
  );

  hydrate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? AgentActions.hydrateSuccess({ state })
          : AgentActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(
    () =>
      this.actions.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for Agent state change
        ofType(AgentActions.hydrateSuccess, AgentActions.hydrateFailure),
        switchMap(() => this.ngEntityStore.pipe(select(fromAgent.selectAgentFeature))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(state => {
          this.persisterService.storeState(state);
        })
      ),
    { dispatch: false }
  );

  requestExtraChats$ = createEffect(() =>
  this.actions.pipe(
    ofType(AgentActions.RequestExtraChats),
    concatLatestFrom(() => this.ngEntityStore.select(fromChat.getChats)),
    tap(([data, chats]) => {
      const engagementIds = chats?.map(c => c.chatId);
      this.fixAgentApiService.requestExtraChats(data).then(() => {
        this.ngEntityStore.dispatch(AgentAvailabilityActions.requestExtraChatsUpdated({requestExtraChats: data.enabled}));
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ToggleExtraChats, {requestExtraChats: data.enabled, engagementIds});
      });
    })),
{ dispatch: false }
);

getAgentAvailabilitySummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.GetAgentAvailabilitySummary),
      concatLatestFrom(() => this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.AgentSalesConversionStats))),
      tap((hasAgentSalesConversionStats) => {
        this.authService.getToken().then(token => {
            this.userIdentityService.Hydrate(token);
            const getAgentAvailabilitySummary: GetAgentAvailabilitySummary = {
                agentId: this.userIdentityService.registeredId,
                agentUsername: this.userIdentityService.username
            };
            this.fixAgentApiService.getAgentAvailabilitySummary(getAgentAvailabilitySummary)
            .then((response: HttpResponse<GetAgentAvailabilitySummaryResponse>) => {
                if (response?.body){
                    this.ngEntityStore.dispatch(AgentActions.UpdateAgentAvailabilitySummary({agentAvailabilityResponse: response.body}));
                }
            });

            if (!hasAgentSalesConversionStats){
              return;
            }
            const getAgentSalesMetrics: GetAgentSalesConversionMetrics = {
              agentUsername: this.userIdentityService.username,
              site: this.config.siteId
            };
            this.fixAgentApiService.getAgentSalesConversionMetrics(getAgentSalesMetrics)
              .then(
                  (response: HttpResponse<GetAgentSalesConversionMetricsResponse>) => {
                      this.ngEntityStore.dispatch(AgentActions.UpdateAgentSalesConversionMetrics({agentSalesConversionMetrics: response.body}));
                  }
              );
        });
    })),
    {dispatch: false}
  );

  logOfferQualityCheck$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.UpdateOfferQualityCheck),
      concatLatestFrom(() => [
        this.ngEntityStore.pipe(select(fromAgent.getOfferFirstQualityCheck)),
        this.ngEntityStore.pipe(select(fromAgent.getLastQualityCheckDate)),
        this.ngEntityStore.pipe(select(fromSettings.getQualityCheckConfiguration)),
        this.ngEntityStore.pipe(select(fromSettings.getCustomSettings)),
        this.ngEntityStore.pipe(select(fromAgentAvailability.getChatsClosedSinceAvailable))
      ]),
      tap(([payload, offerFirstQualityCheck, lastQualityCheckDate, qualityCheckConfiguration, customSettings, chatsClosedSinceAvailable]) => {
        const { chatId, offerQualityCheck } = payload;
        if (offerQualityCheck) {
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.QualityCheckOffer, {chatId}, true);
          const currentDate = new Date();
          const logPayload: LogInfo = {
            logType: LogType.debug,
            operationName: DebugEvents.OfferQualityCheck,
            data: {
              offerFirstQualityCheck,
              lastQualityCheckDate: lastQualityCheckDate?.toJSON(),
              currentDateTime: currentDate.toJSON(),
              qualityCheckConfiguration,
              settingsLastQualityCheckDateTime: customSettings?.lastQualityCheckDateTime,
              chatsClosedSinceAvailable
            }
          };
          this.ngEntityStore.dispatch(LogActions.logDebug({logPayload}));
        }
      })
    ),
    {dispatch: false}
  );

  ngrxOnInitEffects(): Action {
    return AgentActions.hydrate();
  }
}

