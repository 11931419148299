// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { AgentActions, AgentFacingMetricsActions, ChatActions } from '../actions';
import { ChatMetricsData } from '../models/agent-metrics-data';

export const featureKey = 'agent-facing-metrics';

export interface State {
  lastStatusMetricTimestamp: number;
  lastDataRefreshTimestamp: number;
  chatsCompleted: number;
  chatsTransferred: number;
  //All durations are in seconds
  durationAvailable: number;
  durationUnavailable: number;
  chatMetrics: Record<string, ChatMetricsData>;
}

export const initialState: State = {
  lastStatusMetricTimestamp: 0,
  lastDataRefreshTimestamp: 0,
  chatsCompleted: 0,
  chatsTransferred: 0,
  durationAvailable: 0,
  durationUnavailable: 0,
  chatMetrics: {}
};

export const reducer = createReducer<State>(
  initialState,
  on(AgentFacingMetricsActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(
    AgentActions.UpdateCompletedChatCount,
    (state, action): State => ({
      ...state,
      chatsCompleted: action.payload
    })
  ),
  on(
    ChatActions.Transferred,
    (state): State => {
      const chatsCompleted: number = state.chatsCompleted + 1;
      return {
        ...state,
        chatsCompleted
        //Transferred chats metric is updated in updatePostChatMetrics
      };
    }
  ),
  on(
    AgentFacingMetricsActions.updateMetricStatusTimer,
    (state, {durationAdded, available: wasAvailable, latestTimestamp}): State => {
      const timerChanges = wasAvailable
        ? { durationAvailable: (state?.durationAvailable ?? 0) + durationAdded }
        : { durationUnavailable: (state?.durationUnavailable ?? 0) + durationAdded };
      return {
        ...state,
        ...timerChanges,
        lastStatusMetricTimestamp: latestTimestamp
      };
    }
  ),
  on(
    AgentFacingMetricsActions.resetLastMetricStatusTimestamp,
    (state, {timestamp}): State => {
      return {
        ...state,
        lastStatusMetricTimestamp: timestamp
      };
    }
  ),
  on(AgentFacingMetricsActions.updateMetricResponseTime, (state, { chatId, duration }) => {
    const chatMetrics = {...state?.chatMetrics?.[chatId] ?? new ChatMetricsData()};
    chatMetrics.firstResponseTime = duration;

    return {
      ...state,
      chatMetrics: {
        ...state.chatMetrics,
        [chatId]: chatMetrics
      }
    };
  }),
  on(AgentFacingMetricsActions.updatePostChatMetrics, (state, { chatId, duration, isTransfer }) => {
    const chatMetrics = {...state?.chatMetrics?.[chatId] ?? new ChatMetricsData()};
    chatMetrics.engagementHandleTime = duration;
    const chatsTransferred = isTransfer
      ? (state?.chatsTransferred ?? 0) + 1
      : state?.chatsTransferred ?? 0;
    
    return {
      ...state,
      chatsTransferred,
      chatMetrics: {
        ...state.chatMetrics,
        [chatId]: chatMetrics
      }
    };
  }),
);
