// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AgentChatMessage } from './agent-chat-message';
import { SendMessageType } from './enums';
import { GetEnhancedChatMessageRequest } from './requests/get-enhanced-chat-message-request';

export class SendChatMessage extends AgentChatMessage {
    scriptTreeId: string;
    sendMessageType: SendMessageType;
    translateTo?: string;
    autoEnhanceData?: GetEnhancedChatMessageRequest;
    shouldAutoEnhance?: boolean;
    isUsingCustomScript?: boolean;
}
