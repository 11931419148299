// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NgEntityEffects } from './effects';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AgentActions, AppActions, HubsActions, LogActions } from './actions';
import { filter, tap } from 'rxjs/operators';
import { LogHelper } from '../utils/logHelper';
import { AgentOperations, DebugEvents, ErrorEvents } from '../constants/event-logs.constants';
import { Injectable } from '@angular/core';
import { PageInitializationState } from './models/enums';
import { fromApp } from './selectors';
import { LogInfo, LogType } from './models/LogTypeInterfaces';
import { PageInitializationHelper } from '../utils/page-initialization-helper';


@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_App extends NgEntityEffects {
  checkPageInitSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.SetCurrentPage, AppActions.UpdatePageInitialization),
      concatLatestFrom(_ => [
        this.ngEntityStore.select(fromApp.getIsPageInitializationPending),
        this.ngEntityStore.select(fromApp.getHasCriticalPageInitializationError),
        this.ngEntityStore.select(fromApp.getPageInitializationSucceeded),
      ]),
      tap(([, pageInitPending, pageInitCriticalError, pageInitSucceeded]) => {
        if (!pageInitPending && !pageInitCriticalError && !pageInitSucceeded){
          // page init succeeded is used as a flag to check if we have already dispatched this
          // if we have, we dont need to again, ie if a non critical event updates after succeeding
          this.ngEntityStore.dispatch(AppActions.PageInitializationSuccess());
        }
      })
    ),
    { dispatch: false }
  );

  pageInitSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.PageInitializationSuccess),
      concatLatestFrom(_ => this.ngEntityStore.select(fromApp.getPageName)),
      tap(([, pageName]) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.PageLoadComplete, {pageName}, true);
      })
    ),
    { dispatch: false }
  );

  pageInitError$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.UpdatePageInitialization),
      filter(({ data }) => data.state === PageInitializationState.Error),
      concatLatestFrom(_ => this.ngEntityStore.select(fromApp.getPageName)),
      tap(([{ data }, pageName]) => {
        const { error, eventName } = data;
        const isCritical = PageInitializationHelper.isCritical(pageName, eventName);
        LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.PageLoadError, { isCritical, PageLoadEvent: eventName, error });
      })
    ),
    { dispatch: false }
  );

  initializeSessionTimeoutPageServices$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.initializeSessionTimeoutPageServices),
      tap(() => {
        this.ngEntityStore.dispatch(AppActions.InitializeService());
        this.ngEntityStore.dispatch(HubsActions.initializeHubs());
        this.ngEntityStore.dispatch(AgentActions.rehydrateUserIdentityService());

        if (this.environmentService.logging.niagaraLog.enabled) {
          this.ngEntityStore.dispatch(HubsActions.connectNiagaraLogHub());
        }
      })
    ),
    { dispatch: false }
  );

  logDebugGetUpdatesActions$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.StartGetUpdates, AppActions.StopGetUpdates),
      tap(({type, source}) => {
        const operationName = type === AppActions.StartGetUpdates.type
                                ? DebugEvents.StartGetUpdates
                                : DebugEvents.StopGetUpdates;

        const logPayload: LogInfo = {
          logType: LogType.debug,
          operationName,
          data: { source }
        };
        this.ngEntityStore.dispatch(LogActions.logDebug({logPayload}));
      })
    ),
    { dispatch: false }
  );

}
