// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { LanguageTranslationActions } from '../actions';
import { fromLanguageTranslation } from '../selectors';
import { AppState } from '../state';
import { LanguageTranslationPersisterService } from './language-translation-persister.service';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class LanguageTranslationsEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: LanguageTranslationPersisterService,
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(LanguageTranslationActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? LanguageTranslationActions.hydrateSuccess({ state })
          : LanguageTranslationActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(() =>
    this.action.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
      ofType(LanguageTranslationActions.hydrateSuccess, LanguageTranslationActions.hydrateFailure),
      switchMap(() => this.store.pipe(select(fromLanguageTranslation.selectLanguageTranslationFeature))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(state => {
        this.persisterService.storeState(state);
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return LanguageTranslationActions.hydrate();
  }
}
