// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ObjectDifference {
  name: string;
  prevValue: any;
  newValue: any;
}
export class ObjectHelper {
  public static shallowEqual(object1, object2): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  public static merge<T>(object: T, override: T): T {
    if (this.isNullOrUndefined(override)) { return object; }
    if (this.isNullOrUndefined(object)) { return override; }

    const _object = { ...object };
    const _override = { ...override };

    //remove any null or undefined keys from the overriding object
    const overrideKeys = Object.keys(_override);
    for (const key of overrideKeys) {
      if (this.isNullOrUndefined(_override[key])) {
        delete _override[key];
      }
    }

    // merge inner elements
    const objectKeys = Object.keys(_object);
    for (const key of objectKeys) {
      if (this.isObject(_object[key])) {
        _override[key] = this.merge(_object[key], _override[key]);
      }
    }

    return {
      ..._object,
      ..._override
    };
  }

  public static isNullOrUndefined(object: any) {
    return object === null || object === undefined;
  }

  public static isObject(object: any) {
    return typeof (object) === 'object' && !Array.isArray(object) && !this.isNullOrUndefined(object);
  }

  public static getDiffKeys<T>(object1: T, object2: T): string[] {
    const diffKeys = [];

    if (!object1 && !object2){
      return diffKeys;
    }

    if (!object1){
      return Object.keys(object2);
    }

    if (!object2){
      return Object.keys(object1);
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    function addKey(key: string) {
      if (!diffKeys.find(k => k === key)){
        diffKeys.push(key);
      }
    }

    keys1.forEach(key => {
      if (object1[key] !== object2[key]) {
        addKey(key);
      }
    });

    keys2.forEach(key => {
      if (object1[key] !== object2[key]) {
        addKey(key);
      }
    });

    return diffKeys;
  }

  public static getDiff<T>(object1: T, object2: T): ObjectDifference[] {
    const diffKeys = this.getDiffKeys(object1, object2);
    return diffKeys.map(key => {
      const objDiff: ObjectDifference = {
        name: key,
        prevValue: object1 ? object1[key] : undefined,
        newValue: object2 ? object2[key] : undefined
      };
      return objDiff;
    });
  }
}
