// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChannelType } from './enums';
import { ChatMember } from './chatMember';
import { ChatQueue } from './chatQueue';
import { DispositionSelection } from './dispositionSelection';
export abstract class PriorEngagement {
    parentId: string;
    isAsync: boolean;
    createDate: number;
    endDate: number;
    participants: PriorEngagementParticipant[];
    queue: ChatQueue;
    channel: ChannelType;
    timesTransferred: number;
    notes: string;
    disposition: DispositionSelection;
    expanded: boolean;
}

export class PriorEngagementParticipant extends ChatMember {
    id: string;
    displayName: string;
    enterTimestamp: number;
    exitTimestamp: number;
}
