// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ChatOperations } from '../../constants/event-logs.constants';
import { LogHelper } from '../../utils/logHelper';
import { ChatActions, ChatUiActions, PlaceholdersActions, UiActions } from '../actions';
import { PlaceholderEditorState, PlaceholderEditorType } from '../models/enums';
import { SavePlaceholderLog } from '../models/logDimensions/save-placeholder-log';
import { ShowPlaceholderFormLog } from '../models/logDimensions/show-placeholder-form-log';
import { fromChat, fromPlaceholders } from '../selectors';
import { AppState } from '../state';
import { PlaceholdersPersisterService } from './placeholders-persister.service';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class PlaceholdersEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: PlaceholdersPersisterService,
    private loggingFactory: LoggingFactoryService,
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(PlaceholdersActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? PlaceholdersActions.hydrateSuccess({ state })
          : PlaceholdersActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(() =>
    this.action.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
      ofType(PlaceholdersActions.hydrateSuccess, PlaceholdersActions.hydrateFailure),
      switchMap(() => this.store.pipe(select(fromPlaceholders.selectPlaceholdersFeature))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(state => {
        this.persisterService.storeState(state);
      })
    ),
    { dispatch: false }
  );

  updateActiveEngagements$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.updateActiveEngagements),
      concatLatestFrom(() =>
        this.store.select(fromChat.selectOrphanedChatUiChatIds)
      ),
      filter(([, chatIds]) => chatIds.length > 0),
      map(([, chatIds]) => {
        return PlaceholdersActions.chatsRemoved({ chatIds });
      })
    )
  );

  smartResponseClicked$ = createEffect(() =>
    this.action.pipe(
      ofType(UiActions.SmartResponseClicked),
      filter(({smartResponseInUse}) => smartResponseInUse?.isInEditPlaceholder),
      map(({ smartResponseInUse, placeholderKeys }) => {
        const { chatId, quickSend } = smartResponseInUse;
        const editorState = quickSend ? PlaceholderEditorState.QuickSend : PlaceholderEditorState.MessageEditor;
        return PlaceholdersActions.openEditPlaceholderPanel({
          chatId,
          editorState,
          placeholderKeys,
          editorType: PlaceholderEditorType.SmartResponse
        });
      })
    )
  );

  chatSelected$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatUiActions.selectChat, ChatUiActions.agentSelectedChat),
      concatLatestFrom(() => this.store.select(fromPlaceholders.getEditPlaceholderPanelChatId)),
      filter(([{chatId}, editPlaceholderPanelChatId]) => chatId !== editPlaceholderPanelChatId),
      map(() => PlaceholdersActions.closeEditPlaceholderPanel())
    )
  );

  openEditPlaceholderPanel$ = createEffect(() =>
    this.action.pipe(
      ofType(PlaceholdersActions.openEditPlaceholderPanel),
      concatLatestFrom(({chatId}) => [
        this.store.select(fromChat.getChat(chatId)),
        this.store.select(fromPlaceholders.getAllPlaceholdersForChatId(chatId))
      ]),
      tap(([{editorState: action, editorType: messageType, placeholderKeys}, chat, placeholders]) => {
        const placeholderNames = placeholderKeys?.map(pk => {
          const placeholder = placeholders.find(p => p.key === pk);
          return placeholder?.label ? placeholder.label : pk;
        });
        const logDimensions: ShowPlaceholderFormLog = {
          messageType,
          action,
          placeholderNames
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ShowPlaceholderForm, chat, logDimensions);
      })
    ),
    {dispatch: false}
  );

  submitEditPlaceholderPanel$ = createEffect(() =>
    this.action.pipe(
      ofType(PlaceholdersActions.submitEditPlaceholderPanel),
      concatLatestFrom(() => [
        this.store.select(fromPlaceholders.getEditPlaceholderPanelChatId),
        this.store.select(fromChat.getChats),
        this.store.select(fromPlaceholders.getEditPlaceholderPanelPlaceholderVms),
        this.store.select(fromPlaceholders.getEditPlaceholderPanelState)
      ]),
      tap(([{formValues}, chatId, chats, placeholderVms, editorState]) => {
        const chat = chats.find(c => c.chatId === chatId);

        const placeholders = placeholderVms?.map((p, index) => {
          return {
            name: p.label ? p.label : p.key,
            value: formValues[index],
            options: p.options?.map(o => o.value),
            previousValue: p.selectedValue
          };
        });
        const logDimensions: SavePlaceholderLog = {
          placeholders,
          action: editorState
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SavePlaceholder, chat, logDimensions);
      })
    ),
    {dispatch: false}
  );

  ngrxOnInitEffects(): Action {
    return PlaceholdersActions.hydrate();
  }
}
