// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { TypingStatus } from './enums';
import { ChatInteractionRequest } from './requests/chat-interact-request';

export class SendAgentTypingStatus extends ChatInteractionRequest {
  state: TypingStatus;
}
