// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Chunk, findAll } from 'highlight-words-core';
import { MaskText } from 'projects/entities/src/lib/domain/models/mask-text';
import { HighlightWordsUtils } from 'projects/entities/src/lib/utils/highlight-words-utils';
import { ChatMessage } from 'projects/entities/src/public_api';

@Injectable({
  providedIn: 'root'
})

export class ConvoMessageContextMenuService {
  constructor() {}

  public createMaskString(chunks: Chunk[], message: string) {
    return HighlightWordsUtils.maskHighlights(message, chunks);
  }

  public getMessageElementSelection(messageElement: Element): Selection {
    const selection = window.getSelection();

    this.ensureSelectionIsInsideElement(messageElement, selection);

    return selection;
  }

  private isValidSelection(selection: Selection): boolean {
    return !!selection?.rangeCount;
  }

  private ensureSelectionIsInsideElement(element: Element, selection: Selection): void {
    if (!this.isValidSelection(selection)) {
      return;
    }

    const range = selection.getRangeAt(0);

    if (this.isNodeOutsideElement(element, range.startContainer)) {
      range.setStart(element, 0);
    }

    if (this.isNodeOutsideElement(element, range.endContainer)) {
      range.setEndAfter(element);
    }
  }

  private isNodeOutsideElement(element: Element, node: Node): boolean {
    return element && !element.isSameNode(node) && !element.contains(node);
  }

  public getMaskTextActionPayload(chatMessage: ChatMessage, messageElement: Element, selection: Selection, translatedMessage?: string): MaskText {
    const selectedChunk = this.getSelectedChunk(messageElement, selection, chatMessage, translatedMessage);
    return this.createMaskTextPayload(chatMessage, messageElement, selectedChunk, translatedMessage);
  }

  private getSelectedChunk(messageElement: Element, selection: Selection, chatMessage: ChatMessage, translatedMessage?: string): Chunk {
    if (!this.isValidSelection(selection)) {
      return;
    }

    if (translatedMessage) {
      return {
        start: 0,
        end: chatMessage.message?.length,
        highlight: true
      };
    }

    const selectedText = selection.toString();
    const range = selection.getRangeAt(0);
    const rangeBeforeSelection = range.cloneRange();

    // get all text in the message BEFORE the selected text
    rangeBeforeSelection.selectNodeContents(messageElement);
    rangeBeforeSelection.setEnd(range.startContainer, range.startOffset);

    const start = rangeBeforeSelection.toString().length;
    const end = start + selectedText.length;
    return {
      start,
      end,
      highlight: true
    };
  }

  private createMaskTextPayload(chatMessage: ChatMessage, messageElement: Element, selectedChunk: Chunk, translatedMessage: string): MaskText {
    const { chatId, maskedChunks } = chatMessage;

    // messageElement.textContent should hold the same text as chatMessage.message
    // using messageElement.textContent since that is where the selected chunk comes from
    const messageText = messageElement.textContent;

    // findAll will consolidate any overlapping masks and fill in the unmasked chunks
    const newMaskedChunks = translatedMessage
    ? [{
      start: 0,
      end: chatMessage.message?.length,
      highlight: true
    }]
    : findAll({
      // override findChunks to return existing and new mask chunks
      findChunks: () => this.getHighlightedChunks(maskedChunks, selectedChunk),
      searchWords: [],
      textToHighlight: messageText
    });

    const translatedMaskedChunk: Chunk = translatedMessage
    ? {
      start: 0,
      end: translatedMessage.length,
      highlight: true
    }
    : null;

    return {
      maskedChatLine: this.createMaskString(newMaskedChunks, messageText),
      maskedChunks: newMaskedChunks,
      chatId,
      chatMessage,
      traceId: Guid.raw(),
      translatedMaskedChatLine: translatedMessage ? this.createMaskString([translatedMaskedChunk], translatedMessage) : null,
      translatedMaskedChunks: translatedMessage ? [translatedMaskedChunk] : null
    };
  }

  private getHighlightedChunks(maskedChunks: Chunk[], selectedChunk: Chunk) {
    const chunks = (maskedChunks ?? []).filter(chunk => chunk.highlight);
    if (selectedChunk) {
      chunks.push(selectedChunk);
    }
    return chunks;
  }
}
