// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, forwardRef, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CeeTabPanelItemComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { UserIdentityService } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-agent-settings-profile-tab',
  templateUrl: './profile-tab.component.html',
  styleUrls: ['./profile-tab.component.scss'],
  providers: [
    {
      provide: CeeTabPanelItemComponent,
      useExisting: forwardRef(() => ProfileTabComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTabComponent extends CeeTabPanelItemComponent implements OnInit {
  title = 'Profile';
  username:  Observable<string>;
  constructor(private identityService: UserIdentityService) {
    super();
   }

  ngOnInit() {
    this.username = this.identityService.fullName;
  }
}
