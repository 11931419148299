// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AgentFeatureFlags } from './agentFeatureFlags';
import { ChatMessageForSummary } from './chatMessageForSummary';
import { CxGptResponseVm } from './cx-gpt-response';
import { ChatMessageType, SmartResponseType, SuggestionType } from './enums';
import { SecureDataRequest } from './secureDataRequest';
import { SmartResponseVm } from './smart-response-vm';

export class NlpTranscript{
    engagementId: string;
    conversationId: string;
    sessionId: string;
    subject: string;
    asyncDialog: ChatMessageForSummary[];
    dialog: ChatDialog[];
    secureDataRequests: SecureDataRequest[];
    featureFlags: AgentFeatureFlags[];
    events: TranscriptEvent[];
    xaTranscriptEndTimestamp: number;
    customerLanguage: string;
}
export class ChatDialog {
    speaker: string;
    text: string;
    translatedText: string;
    timestamp: number;
    suggestions: Suggestion[];
    originalResponses: OriginalResponse[];
    suggestion: SentSuggestion;
    smartResponses: OriginalSmartResponse[];
    sentSmartResponses: SentSmartResponse[];
    cxGptResponse: OriginalCxGptResponse;
    sentCxGptResponse: SentCxGptResponse;
    isHistoric: boolean;
    messageType: ChatMessageType;
    messageId: string;
}
export class OriginalResponse{
    category: string;
    response: string;
}
export class Suggestion{
    queryId: string;
    intent: string;
    originalText: string;
    suggestionType?: SuggestionType;
    suppressed?: boolean;
}

export class SentSuggestion {
  queryId: string;
  modified: boolean;
  autoPilot: boolean;
}

export class SentSmartResponse {
    id: string;
    modified: boolean;
    originalText: string;
    type: SmartResponseType;
    parentMessageId: string;
    smartResponsesDisplayed: string;
}

export class SentCxGptResponse {
    modified: boolean;
    originalText: string;
}

export class TranscriptEvent {
    eventName: string;
    timestamp: number;
    data?: object;
}

export class OriginalSmartResponse extends SmartResponseVm {
    messageId: string;
}

export class OriginalCxGptResponse extends CxGptResponseVm {
    messageId: string;
}
