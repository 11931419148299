// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../../services/state-persister';
import * as fromCall from './call.reducer';

@Injectable({
  providedIn: 'root'
})
export class CallPersisterService extends StatePersister<fromCall.State> {
  constructor() {
    super(localStorage, fromCall.featureKey);
  }
}
