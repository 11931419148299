// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatMember } from './chatMember';
import { SenderType } from './enums';

export class Customer extends ChatMember {
    firstName?: string;
    lastName?: string;
    displayName?: string;
    type: SenderType = SenderType.Requester;
    name?: string;
}
