// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Guid } from 'guid-typescript';
import { ScriptType } from '../domain/models/enums';
import { ScriptCollection, ScriptModel } from '../domain/models/requests/updateCustomScripts';
import { Script, ScriptGroup, ScriptTree } from '../domain/scripts';
import { defaultCustomScriptTree } from '../domain/scripts/custom-responses.constants';

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {
  constructor(
  ) { }

  /**
   * transforms normalized script data to the hierarchical structure expected by the API
   * @param scriptGroup the current top level script group
   * @param allScriptGroups all the script groups
   * @param allScripts all the scripts
   * @returns hierarchical script collection with the given group and all it's child scripts/groups
   */
  public createScriptCollection(
    scriptGroup: ScriptGroup,
    allScriptGroups: Dictionary<ScriptGroup>,
    allScripts: Dictionary<Script>,
    level: number = 0): [ScriptCollection, ScriptGroup[]] {

    const { name: scriptGroupName, scriptCollectionId, scriptGroups, scripts } = scriptGroup;


    const scriptCollection: ScriptCollection = {
      name: scriptGroupName,
      id: scriptCollectionId,
      scripts: [],
      childCollections: [],
    };

    const excessScriptGroups: ScriptGroup[] = [];

    scripts?.forEach(scriptId => {
      const script: Script = allScripts[scriptId];
      if (script) {
        const { id, name, value } = script;
        const scriptModel: ScriptModel = {
          id,
          name,
          value,
        };
        scriptCollection.scripts.push(scriptModel);
      }
    });

    if (level < 3) {
      scriptGroups?.forEach(scriptGroupId => {
        const childScriptGroup = allScriptGroups[scriptGroupId];
        if (childScriptGroup) {
          const [childScriptCollection, childExcessScriptGroups] = this.createScriptCollection(childScriptGroup, allScriptGroups, allScripts, level + 1);
          scriptCollection.childCollections.push(childScriptCollection);
          childExcessScriptGroups?.forEach(excessScriptGroup => excessScriptGroups.push(excessScriptGroup));
        }
      });
    }
    else {
      excessScriptGroups.push(scriptGroup);
    }

    return [scriptCollection, excessScriptGroups];
  }

  /**
   * normalizes the hierarchical tree structure returned by the API into two collections: Script[] and ScriptGroup[]
   * @param scriptCollection hierarchical script tree structure
   * @param scriptTreeId
   * @param type the type (all scripts will be set to this type)
   * @param allScriptGroups all the scripts groups in the given ScriptCollection will be added to this array
   * @param allScripts  all the scripts in the given ScriptCollection will be added to this array
   * @param parent
   * @returns the current parent script group
   */
  private normalizeScriptCollection(scriptCollection: ScriptCollection, scriptTreeId: string, type: ScriptType, allScriptGroups: ScriptGroup[], allScripts: Script[], parent: ScriptGroup): ScriptGroup {
    const parentId = parent?.id || scriptTreeId;
    const scriptGroupId = scriptCollection.id || this.generateCustomScriptGroupId(parentId); // script collection "folders" do not have ids, so create something unique for the entity id
    const scriptGroup: ScriptGroup = {
      scriptTreeId,
      id: scriptGroupId,
      name: scriptCollection.name,
      scriptCollectionId: scriptCollection.id,
      scriptGroups: [],
      scripts: [],
    };

    scriptCollection.scripts?.forEach(scriptModel => {
      const { id, name, value } = scriptModel;
      const script: Script = {
        id,
        name,
        value,
        type,
        scriptGroupId,
        scriptTreeId,
      };
      allScripts.push(script);
      scriptGroup.scripts.push(script.id);
    });

    scriptCollection.childCollections?.forEach(child => {
      const childScriptGroup = this.normalizeScriptCollection(child, scriptTreeId, type, allScriptGroups, allScripts, scriptGroup);
      scriptGroup.scriptGroups.push(childScriptGroup.id);
    });

    allScriptGroups.push(scriptGroup);
    return scriptGroup;
  }

  public createScriptTree(scriptTreeId: string, scriptTreeName: string, type: ScriptType, childCollections: ScriptCollection[]): readonly [ScriptTree, ScriptGroup[], Script[]] {
    const childScriptGroups: ScriptGroup[] = [];
    const allScriptGroups: ScriptGroup[] = [];
    const allScripts: Script[] = [];

    childCollections?.forEach(collection => {
      const scriptGroup = this.normalizeScriptCollection(collection, scriptTreeId, type, allScriptGroups, allScripts, null);
      childScriptGroups.push(scriptGroup);
    });

    const scriptTree: ScriptTree = {
      id: scriptTreeId,
      name: scriptTreeName,
      type,
      scriptGroups: childScriptGroups.map(sg => sg.id),
    };

    return [scriptTree, allScriptGroups, allScripts] as const;
  }

  public createCustomScriptTree(scriptCollection: ScriptCollection, customScriptGroup: ScriptGroup): readonly [ScriptTree, ScriptGroup[], Script[]] {
    const { id, name } = scriptCollection;
    const scriptTreeId = id || customScriptGroup?.scriptTreeId || defaultCustomScriptTree.id;
    return this.createScriptTree(scriptTreeId, name, ScriptType.Custom, [scriptCollection]);
  }

  public generateCustomScriptGroupId(parentId: string): string{
    return `${parentId}:${Guid.raw()}`;
  }
}
