<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  chatSummary: chatSummary$ | async,
  hasDispositions: hasDispositions$ | async,
  hasUnifiedNotesFeature: hasUnifiedNotesFeature$ | async,
  chatSummaryPayload: chatSummaryPayload$ | async
} as data">
  <cee-confirmation-modal [headerText]="!isAsync && data.hasDispositions ? 'Disposition' : 'End Session'" [active]="active"
    [disabledConfirmButton]="(asyncResolution == '' && isAsync) || (data.hasDispositions && disableConfirmation)"
    (modalClose)="closeModal($event)" (confirmClicked)="closeChat(data.chatSummaryPayload)" (cancelClicked)="closeModal($event)"
    cancelText="Cancel" confirmText="End session">


    <div class="cee-form-row">
      <ng-container *ngIf="data.hasUnifiedNotesFeature; else defaultDescription">
        <p x-test-target="notes-saved-description" *ngIf="data.chatSummary?.autoSaveState === loadingState.Success">
          Summary is automatically saved to Einstein.
        </p>
      </ng-container>
      <ng-template #defaultDescription>
        <p x-test-target="default-description">
          Review and copy this summary into Einstein. Ending this session will remove it from your desktop.
        </p>
      </ng-template>
      
      <merc-customer-info-heading></merc-customer-info-heading>

      <div class="merc-end-session-error cee-form-row" *ngIf="data.chatSummary?.hasError || data.chatSummary?.autoSaveState === loadingState.Failed">
        <ng-container *ngIf="data.hasUnifiedNotesFeature; else defaultError">
          <cee-banner
            id="summary-modal-unified-notes-error-banner"
            [type]="shouldShowRetry(data.chatSummary) ? 'warning' : 'error'"
            [showActionButton]="shouldShowRetry(data.chatSummary)"
            actionButtonText="Retry"
            [dismissable]="true"
            (actionButtonClicked)="retryClicked(data.chatSummary)"
            dataQa="chat-summary-error"
          >
            <p>
              {{
                data.chatSummary?.hasError 
                ? 'Sorry, we encountered a problem with the automatic summary.' 
                : 'Sorry, we were unable to automatically save to the customer account. Please copy the summary manually.'
              }}
            </p>
          </cee-banner>
        </ng-container>
        <ng-template #defaultError>
          <cee-banner
            id="summary-modal-error-banner"
            [type]="'error'"
            [dismissable]="true"
            dataQa="connect-account-number-error"
          >
            Could not load automated summary.
          </cee-banner>
        </ng-template>
      </div>

      <div class="merc-end-session-warning cee-form-row" *ngIf="showDelayMessage && data.chatSummary?.isLoading">
        <cee-banner
         id="sumamry-modal-warning-banner"
         [type]="'warning'"
         [dismissable]="true"
         [dismissableText]="'Cancel'"
         (bannerDismiss)="onCancelChatSummaryDataClicked()"
        >
         Summary is taking longer than usual...
        </cee-banner>
      </div>
      <ng-container *ngIf="isAsync; else notAsync" >
        <div class="end-session-summary--detail cee-form-row">
          <cee-select
            name="async-options"
            [options]="options"
            label="Async Options"
            [hideLabel]="true"
            (handleChange)="asyncOptionChange($event)"
          >
          </cee-select>
        </div>

        <ng-container *ngIf="asyncResolution === 'resolved' || asyncResolution === 'unresolved'">
          <ng-container *ngTemplateOutlet="notesForm"></ng-container>
        </ng-container>
      </ng-container>

      <ng-template #notAsync>
        <ng-container *ngIf="data.hasDispositions; else notesForm">
          <form class="merc-dispositions-form">
            <merc-dispositions [parentContainer]="'close'" [chatId]="chatId" [isV2]="true"></merc-dispositions>
            <ng-container *ngTemplateOutlet="notesForm"></ng-container>
          </form>
        </ng-container>
      </ng-template>

      <ng-template #notesForm>
        <div class="end-session-summary--detail cee-form-row">
          <ng-container *ngIf="data.hasUnifiedNotesFeature; else notesTextArea">
            <div *ngIf="!data.chatSummary?.hasError" class="merc-end-session-summary--detail-container"
            [ngClass]="{'cee-textarea--loading': data.chatSummary?.isLoading}">
              <h3 class="cee-form-label">NOTES</h3>
              <p *ngIf="data.chatSummary?.isLoading; else notesText">
                Loading...
              </p>
              <ng-template #notesText>
                <p>
                  {{data.chatSummaryPayload}}
                </p>
                <cee-button
                  class="merc-end-session-summary--detail-copy" 
                  type="link" 
                  [tooltipText]="copySummaryTooltipText" 
                  tooltipId="copy-summary"
                  tooltipDirection="bottom" 
                  tooltipAppearanceDelayMs="0" 
                  tooltipPersistDirective="true"
                  (buttonClick)="copyToClipboard(isAsync, data.hasDispositions, data.chatSummary?.summary, data.chatSummaryPayload, data.hasUnifiedNotesFeature)"
                  (mouseout)="resetCopyText()">
                  <svg class="cee-icon cee-icon--md">
                    <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy-v2'"></use>
                  </svg> <span>Copy to Clipboard</span>
                </cee-button>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #notesTextArea>
            <cee-textarea [name]="notes" label="NOTES" [placeholder]="data.chatSummary?.isLoading ? 'Loading...' : 'Notes'" [loading]="data.chatSummary?.isLoading" (textChange)="onTextFieldChange('notes', $event)" [textInput]="data.chatSummaryPayload">
            </cee-textarea>
          </ng-template>
        </div>
      </ng-template>

      <div class="merc-end-session-summary--footer"
        *ngIf="data.chatSummary && !data.chatSummary.isLoading && !data.chatSummary.hasError && (!isAsync || asyncResolution)">
        <div class="merc-end-session-summary--footer-rating" [ngClass]="{'merc-end-session-summary--footer-rating-bottom': data.hasUnifiedNotesFeature}">
          <div class="merc-end-session-summary--footer-rating-feedback" *ngIf="data.chatSummary.rated; else rateSummary">
            <span *ngIf="data.chatSummary.isPositiveRating">Thanks for your feedback!</span>
            <span *ngIf="!data.chatSummary.isPositiveRating">Your feedback helps up improve. Please edit the summary before saving in Einstein.</span>
          </div>
          <ng-template #rateSummary>
            <cee-thumb-rating ratingLabel="Is this summary accurate?"
              (positiveRating)="positiveRating()"
              (negativeRating)="negativeRating()"
            >
            </cee-thumb-rating>
          </ng-template>
        </div>        
        <div class="merc-end-session--item" *ngIf="!data.hasUnifiedNotesFeature">
          <cee-button-v2 class="merc-tool-tip" type="link" [tooltipText]="copySummaryTooltipText" tooltipId="copy-summary"
            tooltipDirection="bottom" tooltipAppearanceDelayMs="0" tooltipPersistDirective="true"
            (buttonClick)="copyToClipboard(isAsync, data.hasDispositions, data.chatSummary?.summary, data.chatSummaryPayload, data.hasUnifiedNotesFeature)"
            (mouseout)="resetCopyText()">
            <svg class="cee-icon cee-icon--md">
              <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy'"></use>
            </svg> <span>Copy to Clipboard</span>
          </cee-button-v2>
        </div>
      </div>
    </div>

  </cee-confirmation-modal>
</ng-container>
