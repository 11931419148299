// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ScriptType } from '../models/enums';
import { ScriptGroup, ScriptTree } from './models';

export const defaultCustomScriptTreeId = 'Custom Responses';

export const defaultCustomScriptGroup: ScriptGroup = {
    id: defaultCustomScriptTreeId,
    name: defaultCustomScriptTreeId,
    scriptTreeId: defaultCustomScriptTreeId,
    scriptGroups: [],
    scripts: [],
    scriptCollectionId: defaultCustomScriptTreeId
};

export const defaultCustomScriptTree: ScriptTree = {
    id: defaultCustomScriptTreeId,
    name: defaultCustomScriptTreeId,
    scriptGroups: [defaultCustomScriptTreeId],
    type: ScriptType.Custom
};
