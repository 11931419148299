// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AgentGroupsResponseBase } from './agent-groups-response-base';

export class GetSettingsResponse extends AgentGroupsResponseBase {
    public busyStatuses: string[];
    public enableCustomScript: boolean;
    public maxChats: number;
    public maxExtraChats: number;
    public agentNetworkId: string;
    public pernrId: string;
    public comcastGuid: string;

    constructor(data?: any) {
          super(data);
          
        if (data) {
            this.busyStatuses = data.busyStatuses ?? [];
            this.enableCustomScript = data.enableCustomScript;
            this.agentNetworkId = data.agentNetworkId;
            this.pernrId = data.pernrId;
            this.comcastGuid = data.comcastGuid;
        }
    }
}
