<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div *ngIf="listTitle" class="cee-convo-card-list--title" [attr.data-qa]="dataQaTitle">{{listTitle}}</div>
<ul class="cee-convo-card-list">
    <li>
        <ng-content></ng-content>
    </li>
</ul>
