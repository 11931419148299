// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getAgentGroups, getAgentNetworkId, getBusinessUnits } from '../agent.selectors';
import { AgentCustomSettings } from '../models/agentCustomSettings';
import { NpsSurveyData } from '../models/nps-survey-data';
import * as fromSettings from './settings.reducer';
import { AppState } from '../state';
import { ThemeConstants } from '../../constants/constants';
import { FeatureFlags } from '../../constants/featureFlags.constants';
import { ChatStartGreetings } from '../models/chat-start-greetings';
import { DetailsSelectedTab } from '../models/enums';
import { LanguageTranslationSettings } from '../models/languageTranslationSettings';

export const selectSettingsFeature = createFeatureSelector<
  AppState,
  fromSettings.State
>(fromSettings.featureKey);

export const getCustomSettings = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.agentCustomSettings
);

export const hideAnimations = createSelector(
    getCustomSettings,
    (settings: AgentCustomSettings) => settings?.animationsOff ?? false
);

export const showCustomerActions = createSelector(
    getCustomSettings,
    (settings: AgentCustomSettings) => !(settings?.hideCustomerActions ?? false)
);

export const showPageNavigation = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => settings?.showPageNavigation ?? true
);

export const getIsDarkTheme = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => !Boolean(settings?.theme) || settings.theme === ThemeConstants.dark
);

export const getFeatureFlags = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.featureFlags
);

export const hasFeatureFlag = (flag: string) => createSelector(
    getFeatureFlags,
    (flags: string[]) => flags?.includes(flag) ?? false
);

export const getSessionLockOutSettings = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.sessionLockOutSettings
);

export const selectXaResponsesTitleMapping = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.xaResponsesTitleMapping
);

export const getSurveyConfiguration = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.surveyConfiguration
);

export const getLastSurveyCompleteDate = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => settings?.lastSurveyCompleteDate
);

export const getNpsSurveyData = createSelector(
  [getSurveyConfiguration, getLastSurveyCompleteDate, getFeatureFlags, getAgentGroups, getBusinessUnits, getAgentNetworkId],
  (surveyConfiguration, lastSurveyCompleteDate, featureFlags, agentGroups, businessUnits, agentNetworkId) => {
    return {
      surveyConfiguration,
      lastSurveyCompleteDate,
      featureFlags,
      agentGroups,
      businessUnits,
      agentNetworkId
    } as NpsSurveyData;
  }
);

export const getEngagementInactiveWarningCountdownDuration = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.engagementInactiveWarningCountdownDuration
);

export const getPriorEngagementsPageSize = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.priorEngagementsPageSize
);

export const getVolume = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => settings?.soundVolume ?? false
);

export const getIsMuted = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => settings?.soundMuteAll ?? false
);

export const getShowAutocomplete = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => settings?.scriptAutocomplete ?? false
);

export const getTranslateMessagesDefault = createSelector(
  getCustomSettings,
  (settings: AgentCustomSettings) => settings?.translateMessages ?? false
);

export const getIsSettingsNotificationVisible = createSelector(
  selectSettingsFeature,
  hasFeatureFlag(FeatureFlags.HasNewSettings),
  (state: fromSettings.State, featureFlag: boolean) => !state.settingsNotificationDismissed && featureFlag
);
export const getAllCustomerHeartbeatThresholds = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.customerHeartbeatThreshold
);

export const getAllChatAutomationSettings = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.chatAutomationSettings
);

export const getSecureDataCollectionLinkReceivedMessage = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.secureDataCollectionLinkReceivedMessage
);

export const getSecureDataCollectionCompletedMessage = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.secureDataCollectionCompletedMessage
);

export const getDisconnectTimeout = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.disconnectTimeout
);

export const getQualityCheckConfiguration = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.qualityCheckSettings
);

const getChatStartGreetings = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.chatStartGreetings
);

export const getChatStartGreetingNewAsync = createSelector(
  getChatStartGreetings,
  (greetings: ChatStartGreetings) => greetings?.chatStartGreetingNewAsync
);

export const getNewFeatureBannerAckTimestamp = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.agentCustomSettings?.newFeatureBannerAckTimestamp ?? null
);

export const getChatStartGreetingReturningDisconnect = createSelector(
  getChatStartGreetings,
  (greetings: ChatStartGreetings) => greetings?.chatStartGreetingReturningDisconnect
);

export const getSmartResponseSourceResultCount = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.smartResponseSourceResultCount
);

export const getSmartResponseDisplayCount = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.smartResponseDisplayCount
);

export const showSmartResponses = createSelector(
  hasFeatureFlag(FeatureFlags.SmartResponses),
  hasFeatureFlag(FeatureFlags.HideSmartResponses),
  (hasSmartResponsesFeature: boolean, hasHideSmartResponsesFeature: boolean) =>
    hasSmartResponsesFeature && !hasHideSmartResponsesFeature
);

export const showRecommendedTab = createSelector(
  showSmartResponses,
  hasFeatureFlag(FeatureFlags.XaResponse),
  (hasSmartResponsesFeature: boolean, hasXaResponseFeature: boolean) =>
    hasSmartResponsesFeature || hasXaResponseFeature
);

export const getDetailsPanelDefaultTab = createSelector(
  showRecommendedTab,
  (showRecommended: boolean) => showRecommended ? DetailsSelectedTab.Recommended : DetailsSelectedTab.Responses
);

export const getSchedulerIntentConfiguration = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.schedulerIntent
);

export const getItgList = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state.itgList
);

export const getShowConversationSummary = createSelector(
  hasFeatureFlag(FeatureFlags.ChatConversationSummary),
  hasFeatureFlag(FeatureFlags.HideSummary),
  (hasChatConversationSummary: boolean, hasHideSummary: boolean) => hasChatConversationSummary && !hasHideSummary
);

export const getLanguageTranslationSettings = createSelector(
	selectSettingsFeature,
	(state: fromSettings.State) => state.languageTranslationSettings
);

export const getAgentGroupIdLanguageLists = createSelector(
	getLanguageTranslationSettings,
	(languageTranslationSettings: LanguageTranslationSettings) => languageTranslationSettings?.agentGroupIdLists
);

export const selectAskMeAnythingPromptId = createSelector(
  selectSettingsFeature,
  (state: fromSettings.State) => state?.askMeAnythingPromptId
);

export const selectEnhanceMessagePromptId = createSelector(
	selectSettingsFeature,
	(state: fromSettings.State) => state?.enhancePromptId
  );
