// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { Chunk } from 'highlight-words-core';
import {
  HighlightWords,
  ReplacementChunk
} from 'projects/entities/src/lib/utils/highlight-words';
import { HighlightOptions } from 'src/app/models/highlight-options';

@Component({
  selector: 'merc-highlighter',
  templateUrl: './highlighter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlighterComponent {
  @Input() textToHighlight: string;
  @Input() searchText: string[] = [];
  @Input() highlightCustomClass: string;
  @Input() replacements: HighlightOptions[] = [];

  private replacementChunks: ReplacementChunk[];
  private replacedTextToHighlight: string;

  getFilledInChunks(): Chunk[] {
    const {
      filledInChunks,
      replacedTextToHighlight,
      replacementChunks
    } = HighlightWords.findAll(
      this.textToHighlight,
      this.searchText,
      this.replacements
    );

    this.replacementChunks = replacementChunks;
    this.replacedTextToHighlight = replacedTextToHighlight;

    return filledInChunks;
  }

  getText(chunk: Chunk): string {
    const { end, start } = chunk;
    return this.replacedTextToHighlight.substring(start, end);
  }

  getClassName(chunk: Chunk): string {
    return HighlightWords.getClassName(
      chunk,
      this.replacementChunks,
      this.highlightCustomClass
    );
  }

  getInnerHtml(chunks: Chunk[]): string {
    return chunks.reduce((text, c) => {
      const className = this.getClassName(c);
      const chunkText = this.getText(c);
      if (className){
        return `${text}<span class="${className}">${chunkText}</span>`;
      }
      return `${text}${chunkText}`;
    }, '');
  }
}
