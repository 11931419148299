// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, ViewEncapsulation, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LoginBannerHelper } from 'projects/entities/src/lib/utils/loginBannerHelper';
import { BannerContent } from 'projects/entities/src/lib/domain/models/bannerContent';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['../../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBannerComponent implements OnInit {
  bannerContent$: Observable<BannerContent>;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // get query string for any banner content
    this.bannerContent$ = this.route.queryParams.pipe(map(params => {
      if (params && params.bannerReason) {
        return LoginBannerHelper.getBannerContentByQueryString(params);
      }
    }));
  }
}
