// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable, NgZone } from '@angular/core';
import { DayTimeService, IncrementingTimerService, TimerFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Call } from 'projects/entities/src/lib/domain/voice/call/call.model';
import { SubscriberService } from 'src/app/subscribed-container';

@Injectable({
  providedIn: 'root'
})
export class CallHeaderService extends SubscriberService {
  call: Call;
  callDurationTimer: IncrementingTimerService;

  constructor(
    private ngZone: NgZone,
    private timerFactory: TimerFactoryService,
    private timeService: DayTimeService
  )
  {
    super();
    if (!this.callDurationTimer) {
      this.callDurationTimer = this.timerFactory.createIncrementingTimer(this.ngZone);
    }
  }

  public getCallTimer(): IncrementingTimerService {
    return this.callDurationTimer;
  }

  public resetCallTimer() {
    this.callDurationTimer.stopAndReset();
  }

  public startCallTimer(call: Call) {
    this.callDurationTimer.start(call?.startTimestamp ? this.timeService.unix() - call.startTimestamp : 0);
  }

  public stopCallTimer() {
    this.callDurationTimer.stop();
  }
}
