// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiFocusApplicationType } from 'projects/entities/src/lib/domain/models/enums';
import { CtiRequests } from '../constants/cti.constants';
import { CtiChatRequest } from './cti-chat-request';

export class CtiResumeChatRequest extends CtiChatRequest{
    messageName: string = CtiRequests.ChatDialogResume;
    focusApplication?: CtiFocusApplicationType;
    public constructor (uui: string, sessionId: string, focusApplication?: CtiFocusApplicationType) {
        super(uui, sessionId);
        this.focusApplication = focusApplication;

   }
}
