// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RatingOption } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { QualityCheckResponseSource } from 'projects/entities/src/lib/domain/models/enums';
import { QualityCheckResponse } from 'projects/entities/src/lib/domain/models/qualityCheckResponse';
import { fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState, SettingsActions } from 'projects/entities/src/public_api';
import { takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-rate-connection',
  templateUrl: './rate-connection.component.html',
  styleUrls: ['./rate-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RateConnectionComponent extends SubscriberComponent implements OnInit {
  @Input() chatId: string;
  @Input() inlineModal: boolean = false;
  @Output() ratingSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  ratingLabel: string;
  ratingOptions: RatingOption[] = [
    {
      id: 'star-5',
      value: 5, 
      description: 'The performance is excellent' 
    },
    {
      id: 'star-4',
      value: 4, 
      description: 'The performance is good' 
    },
    {
      id: 'star-3',
      value: 3, 
      description: 'The performance is fair' 
    },
    {
      id: 'star-2',
      value: 2, 
      description: 'The performance is poor' 
    },
    { 
      id: 'star-1',
      value: 1, 
      description: 'The performance is bad' 
    }
  ];

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.store.select(fromUi.getShowRateConnectionQuality)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isModal => this.ratingLabel = isModal ? 'How is Mercury performing?' : '');
  }

  updateRating(rating) {
    const source = this.inlineModal ? QualityCheckResponseSource.Inline : QualityCheckResponseSource.Settings;
    const qualityCheckResponse: QualityCheckResponse = {
      rating: rating,
      chatId: this.chatId,
      source: source
    };

    this.ratingSubmitted.emit(rating);
    this.store.dispatch(SettingsActions.sendQualityCheckResponse({qualityCheckResponse, qualityCheckDateTime: new Date()}));
  }
}
