<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container class="merc-sound-bar">
  <label for="volume">Volume</label>
  <div class="merc-sound-bar-controls">
    <div class="merc-volume-slider">
      <cee-button type="link" (click)="toggleMute(isMuted)">  
        <ng-container *ngIf="isMuted; else notMuted">           
            <span class="cee-accessibly-hidden">Unmute volume</span>
            <svg class="cee-icon cee-icon--lg"
            [ceeTooltip]="{ text: 'Unmute' }">
              <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-volume-mute'"></use>
            </svg>           
        </ng-container>
        <ng-template #notMuted>           
          <span class="cee-accessibly-hidden">Mute volume</span>
          <svg class="cee-icon cee-icon--lg"
          [ceeTooltip]="{ text: 'Mute' }">
            <use *ngIf="volume >= 50" [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-volume-high'"></use>
            <use *ngIf="volume < 50" [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-volume-low'"></use>
          </svg>  
        </ng-template>
    </cee-button>
      <input
        #volumeSlider
        class="slider"
        type="range" 
        id="volume" 
        name="volume"
        min="0" 
        max="100"
        [ngModel]="isMuted ? 0 : volume"
        (change)="handleChange($event)">
    </div> 
    <cee-button [disabled]="isMuted" *ngIf="testButton" type="secondary" (click)="playTestSound()">Test sound</cee-button>
  </div>
</ng-container>