// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromAuth from '../auth.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuthPersisterService extends StatePersister<fromAuth.State> {
  constructor() {
    super(sessionStorage, fromAuth.featureKey);
  }
}
