// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class AgentMetricsData {
  lastStatusMetricTimestamp: number;
  lastDataRefreshTimestamp: number;
  chatsCompleted: number = 0;
  chatsTransferred: number = 0;
  //All durations are in seconds
  durationAvailable: number;
  durationUnavailable: number;
  chatMetrics: Record<string, ChatMetricsData>;
}

export class ChatMetricsData {
  //Duration it took in seconds for agent to send a first response
  firstResponseTime?: number;
  engagementHandleTime?: number;
}