// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class AuthenticationProviderTypes {
	static NuanceForm = 'nuanceForm';
	static NuanceSso = 'nuanceSso';
	static ComcastNuanceSso = 'ComcastNuanceSso';
}

