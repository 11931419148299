// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { EntityAdapter } from '@ngrx/entity';

export abstract class EntityStatePersister<T> {
  constructor(private readonly localForage: LocalForage, private readonly adapter: EntityAdapter<T>) { }

  storeEntity(entity: T): Promise<T> {
    const id = this.adapter.selectId(entity);
    return this.setItem(id, entity);
  }

  removeEntity(entity: T): Promise<void> {
    const id = this.adapter.selectId(entity);
    return this.removeItem(id);
  }

  clear(): Promise<void> {
    return this.localForage.clear();
  }

  getEntity(id: string | number): Promise<T> {
    return this.getItem(id);
  }

  async getEntities(): Promise<T[]> {
    const keys = await this.localForage.keys();

    return Promise.all(keys.map(id => {
      return this.localForage.getItem<T>(id);
    }));
  }

  async getIds(): Promise<string[]> {
    const keys = await this.localForage.keys();
    return keys ?? [];
  }

  async storeEntities(entities: T[]): Promise<T[]> {
    if (!entities?.length) {
      return Promise.resolve(entities);
    }

    return Promise.all(entities.map((entity) => {
      const id = this.adapter.selectId(entity);
      return this.setItem(id, entity);
    }));
  }

  /**
   * Override to modify the entity before it is persisted in the store/db
   * @param entity the entity being stored
   * @returns the entity to store
   */
  beforeStore(entity: T): T {
    return entity;
  }

  private setItem(id: string | number, entity: T): Promise<T> {
    const item: T = this.beforeStore ? this.beforeStore(entity) : entity;
    return this.localForage.setItem<T>(id.toString(), item);
  }

  private getItem(id: string | number): Promise<T> {
    return this.localForage.getItem<T>(id.toString());
  }

  private removeItem(id: string | number): Promise<void> {
    return this.localForage.removeItem(id.toString());
  }
}
