// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { AppState, AskMeAnythingActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { CheckboxDetails } from 'projects/entities/src/lib/domain/models/checkboxDetails';
import { FeedbackOptions } from './ask-me-anything-constants';
import { fromAskMeAnything } from 'projects/entities/src/lib/domain/selectors';

@Component({
  selector: 'merc-ask-me-anything-feedback-modal',
  templateUrl: './ask-me-anything-feedback-modal.component.html',
  styleUrls: ['./ask-me-anything-feedback-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskMeAnythingFeedbackModalComponent implements OnInit {
  showAskMeAnythingFeedbackModal$: Observable<boolean>;
  chatId$: Observable<string>;
  searchId$: Observable<string>;

  options: CheckboxDetails[] = [];
  optionalFeedback: string = '';
  checkedFeedback: { [value: string]: boolean } = {};

  constructor(
    private store: Store<AppState>
  ) {

  }

  ngOnInit() {
    this.showAskMeAnythingFeedbackModal$ = this.store.select(fromAskMeAnything.getIsFeedbackModalOpen);
    this.chatId$ = this.store.select(fromAskMeAnything.getSelectedAskMeAnythingId);
    this.searchId$ = this.store.select(fromAskMeAnything.getFeedbackSearchId);
    this.options = this.formatOptions(FeedbackOptions);
  }

  closeModal() {
    this.store.dispatch(AskMeAnythingActions.closeFeedbackModal());
    this.checkedFeedback = {};
    this.optionalFeedback = '';
    this.options = this.formatOptions(FeedbackOptions);
  }

  formatOptions(options: string[]): CheckboxDetails[] {
    return options.map((option) => ({
      value: option,
      label: option,
      checked: false,
    }));
  }

  submitFeedback(chatId: string, searchId: string, questionId: string) {
    const selectedFeedback = Object.keys(this.checkedFeedback).filter(key => this.checkedFeedback[key]);

    this.store.dispatch(AskMeAnythingActions.submitFeedback({ chatId, searchId, questionId, selectedFeedback, optionalFeedback: this.optionalFeedback}));
    this.closeModal();
  }

  updateFeedback(event, option) {
    this.checkedFeedback[option.value] = event.target.checked;
  }

  onFeedbackChange(event) {
    this.optionalFeedback = event;
  }
}
