// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { PlayAudioOptions } from '@cxt-cee-chat/merc-ng-core';
import { createAction, props } from '@ngrx/store';
import { createPayloadAction } from './action-adapters';
import { AgentCustomSettings } from './models/agentCustomSettings';

export const Load = createAction('[Custom Settings] Load');

export const Loaded = createPayloadAction<AgentCustomSettings>(
  '[Custom Settings] Loaded'
);

export const Update = createPayloadAction<AgentCustomSettings>(
  '[Custom Settings] Update'
);

export const saveVolume = createAction(
  '[Custom Settings] Save Volume',
  props<{volume: number, isMuted: boolean, source: string}>()
);

export const muteVolume = createAction(
  '[Custom Settings] Mute Volume',
  props<{isMuted: boolean}>()
);

export const Updated = createPayloadAction<AgentCustomSettings>(
  '[Custom Settings] Updated'
);

export const testVolume = createAction(
  '[Custom Settings] Test Volume',
  props<{audioOptions: PlayAudioOptions}>()
);

