<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<span *ngIf="(scriptSentCount$ | async) as timesSent" class="cee-tag cee-tag--tertiary"> {{timesSent}} </span>
<ng-container *ngIf="{ isScriptInUse: isScriptInUse$ | async, searchTerm: searchTerm$ | async} as data">
  <button *ngIf="(script$ | async) as script" class="cee-btn cee-btn--script script-highlight-style" [ngClass]="{'script-disabled': data.isScriptInUse}"
    (click)="handleScriptClicked(script, data.isScriptInUse, $event)"
    (dblclick)="scriptDoubleClicked(script, data.isScriptInUse, $event)" [innerHTML]="getScriptValue(script, data.searchTerm) | safeHtml"
    (contextmenu)="showContextMenu(script, $event)"
    data-qa="script">
  </button>
</ng-container>
