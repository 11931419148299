// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { CeeAccordionComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { ChatTranscriptInteraction, EngagementTranscript } from 'projects/entities/src/lib/domain/models/chatTranscript';
import { ChannelType, Chat } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-convo-async-engagement',
  templateUrl: './convoAsyncEngagement.component.html',
  styleUrls: ['./convoAsyncEngagement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoAsyncEngagementComponent implements OnInit {
  @Input() engagement: EngagementTranscript;
  @Input() selectedActiveChat: Chat;
  @Input() isResolved: boolean = false;
  title: string = 'No Topic';
  channels = [];
  timestampStart: string;
  timestampEnd: string;
  @ViewChild('engagementContainer') transcriptContainer: ElementRef;
  @ViewChild('engagementAccordion') transcriptAccordion: CeeAccordionComponent;

  constructor(
    private timeHelper: TimeHelperService) { }

  ngOnInit() {
    this.channels = this.getChannels(this.engagement.channel);
    this.timestampStart = this.getFormattedTime(this.engagement.createDate);
    this.timestampEnd = this.getFormattedTime(this.engagement.endDate);
  }

  getOffsetTop() {
    return this.transcriptContainer.nativeElement.offsetTop;
  }

  private getChannels(channelType: ChannelType) {
    const channels = [];
    if (channelType === ChannelType.WebChat){
      channels.push('web');
    }
    else if (channelType === ChannelType.Sms){
      channels.push('sms');
    }
    return channels;
  }

  private getFormattedTime(unix: number): string {
    return this.timeHelper.formatDateTimeShortMonth(unix, this.selectedActiveChat.timeZone?.name);
  }

  trackByInteraction(_index: number, interaction: ChatTranscriptInteraction){
    return interaction.timestamp;
  }

}
