<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  hideAnimation: hideAnimation$ | async,
  hasAskMeAnythingRewriteFeatureFlag: hasAskMeAnythingRewriteFeatureFlag$ | async
} as data">
  <div class="merc-ask-me-anything-content" *ngIf="search">
    <div class="merc-ask-me-anything-content-question">
      <div [ngClass]="[
        'merc-ask-me-anything-content-question-bubble', 
        search.isCustomerQuestion 
          ? 'merc-ask-me-anything-content-question-bubble--customer-' + color?.id 
          : 'merc-ask-me-anything-content-question-bubble--agent'
      ]"></div>
      <div class="merc-ask-me-anything-content-question-text">{{ search.question }}</div>
    </div>

    <div class="merc-ask-me-anything-content-answer">
      <div [ngSwitch]="search.loadingState">
        <ng-container *ngSwitchCase="loadingState.Success">
          <div class="merc-ask-me-anything-content-answer--text">
            <span *ngIf="search.answer; else noContent" x-test-target="search-answer">{{search.answer}}</span>
            <ng-template #noContent>
              <span x-test-target="search-no-content">Sorry, I couldn't find an answer to your question. Please provide more details or rephrase it, keeping in mind that I can't read previous questions.</span>
            </ng-template>
          </div>
          <div class="merc-ask-me-anything-content-answer--sources" *ngIf="search.citations?.length > 0">
            <span class="merc-ask-me-anything-content-answer--sources-title">Sources: </span>
            <div class="merc-ask-me-anything-content-answer--sources-container">
              <button *ngFor="let citation of search.citations"
                class="merc-ask-me-anything-content-answer--sources-link"
                (click)="onSourceClick(chatId, citation.documentUrl, citation.url, search.searchId, search.questionId)" 
                [ceeTooltip]="{id: 'ask-me-anything-url-' + citation.url, text: '', supplementaryText: citation.title, width: 200, direction: 'bottom'}">
                  {{ citation.url }}
                  <svg class="cee-icon">
                    <use xlink:href="/assets/img/svg-sprite.svg#icon-link-out"></use>
                  </svg>
              </button>
            </div>
          </div>
          <div class="merc-ask-me-anything-content-answer--footer" *ngIf="search.answer">
            <div class="merc-ask-me-anything-content-answer--rating">
              <span>{{ search.rating === ratingState.None ? '' : 'Thank you for your feedback!' }}</span>
              <cee-thumb-rating *ngIf="search.rating === ratingState.None"
                (positiveRating)="positiveRating(chatId, search.searchId)"
                (negativeRating)="negativeRating(chatId, search.searchId)"
              >
              </cee-thumb-rating>
            </div>
            <cee-button 
              customClass="merc-ask-me-anything-content-answer--customer-version" 
              *ngIf="chatId && data.hasAskMeAnythingRewriteFeatureFlag" 
              (buttonClick)="onCustomerVersionClick(chatId, search)"
              [type]="search.customerVersionClicked ? 'link' : 'primary'"
            >
              Customer version
              <svg class="cee-icon">
                <use xlink:href="/assets/img/svg-sprite.svg#icon-enhance"></use>
              </svg>
            </cee-button>
          </div>
          
        </ng-container>

        <ng-container *ngSwitchCase="loadingState.Failed">
          <span class="merc-ask-me-anything-content-answer--error-text">Sorry, there was an issue generating the result. Please try again.</span>
          <div class="merc-ask-me-anything-content-answer--error-button-wrapper">
            <cee-button type="link" customClass="merc-ask-me-anything-content-answer--error-button" (buttonClick)="onRetryClick(chatId, search.searchId)">Retry 
              <svg class="cee-icon">
                <use xlink:href="/assets/img/svg-sprite.svg#icon-refresh"></use>
              </svg>
            </cee-button>
          </div>
        </ng-container>
        
        <ng-container *ngSwitchDefault>
          <cee-loading-skeleton [hideAnimation]="data.hideAnimation" [isParagraphBlock]="false" [iterations]="2"></cee-loading-skeleton>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
