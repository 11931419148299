// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, ChatUiActions } from 'projects/entities/src/public_api';
import { ScriptGroup } from 'projects/entities/src/lib/domain/scripts/';
import * as fromSelectedChatScripts from 'projects/entities/src/lib/domain/scripts/selected-chat-selectors';
import * as fromScriptsSearch from 'projects/entities/src/lib/domain/scripts/scripts-search-selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-convo-script-group',
  templateUrl: './script-group.component.html',
  styleUrls: ['./script-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptGroupComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @Input() chatId: string;
  @Input() chatScriptTreeId: string; // required because toggling is tracked per scriptTreeId because idk
  @Input() scriptTreeId: string;
  @Input() scriptGroupId: string;
  @Input() level: number = 0;

  public scriptGroup$: Observable<ScriptGroup>;
  public isScriptGroupActive$: Observable<boolean>;
  public isSelectedChatSearchingScripts$: Observable<boolean>;
  private _isSearching: boolean = false;
  
  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    const { scriptGroupId } = this;

    this.scriptGroup$ = this.store.select(fromSelectedChatScripts.getFilteredScriptGroup(scriptGroupId));
    this.isScriptGroupActive$ = this.store.select(fromSelectedChatScripts.isScriptGroupActive({ scriptGroupId }));

    this.isSelectedChatSearchingScripts$ = this.store.select(fromScriptsSearch.isSelectedChatSearchingScripts);

    this.subscriptions.push(this.store.select(fromScriptsSearch.isSelectedChatSearchingScripts).subscribe((isSearching: boolean) => {
      this._isSearching = isSearching;
    }));
    
  }

  onAccordionChange(scriptGroup: ScriptGroup) {
    if (this._isSearching) {
      // TODO: should we track searching expand state?
      return;
    }

    const { id: scriptGroupId } = scriptGroup;

    this.store.dispatch(ChatUiActions.toggleScriptGroup({ chatId: this.chatId, scriptGroupId }));
  }


  hasChildren(scriptGroup: ScriptGroup): boolean {
    const { scripts, scriptGroups } = scriptGroup;
    return scripts?.length > 0 || scriptGroups?.length > 0;
  }
}
