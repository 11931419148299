// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SmartResponseVm } from '../smart-response-vm';

export class SendSmartResponse { 
  chatId: string;
  messageId?: string;  
  isModified?: boolean;  
  traceId?: string; 
  timestamp?: number;
  message: string;
  smartResponse: SmartResponseVm[];
}
