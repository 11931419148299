// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { SmartResponses } from './smart-responses.model';
import { ChatActions, SmartResponsesActions } from '../actions';
import { SmartResponsesHelper } from '../../utils/smart-responses-helper';
import { LoadingState } from '../models/enums';

export const featureKey = 'smartResponses';

export interface State extends EntityState<SmartResponses> { }

export const adapter: EntityAdapter<SmartResponses> = createEntityAdapter<SmartResponses>({
  selectId: (smartResponses: SmartResponses) => SmartResponsesHelper.getKey(smartResponses),
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(SmartResponsesActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(SmartResponsesActions.chatsRemoved, (state, { chatIds }) => {
    return adapter.removeMany((smartResponses: SmartResponses) =>
      Boolean(chatIds.find(cId => cId === smartResponses.chatId)), state);
  }),
  on(ChatActions.Closed,
    ChatActions.Bounced,
    ChatActions.Transferred, (state, { payload }) => {
      const {chatId} = payload;
      return adapter.removeMany((smartResponses: SmartResponses) => chatId === smartResponses.chatId, state);
  }),
  on(SmartResponsesActions.getSmartResponses, (state, { chatId, messageId }) => {
    const update: SmartResponses = {
      chatId,
      messageId,
      state: LoadingState.Pending,
      smartResponses: []
    };
    return adapter.upsertOne(update, state);
  }),
  on(SmartResponsesActions.getSmartResponsesFailed, (state, { chatId, messageId }) => {
    const key = SmartResponsesHelper.getKey({chatId, messageId});
    const update: Update<SmartResponses> = {
      id: key,
      changes: {
        state: LoadingState.Failed,
        smartResponses: []
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(SmartResponsesActions.updateSmartResponses, (state, { chatId, messageId, response }) => {
    const smartResponses = SmartResponsesHelper.translateSmartResponsesToViewModel(response);
    const key = SmartResponsesHelper.getKey({chatId, messageId});
    const update: Update<SmartResponses> = {
      id: key,
      changes: {
        state: LoadingState.Success,
        smartResponses
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(ChatActions.AcceptNewChat, (state, {chat, smartResponses }) => {

    if (!smartResponses) { return state; }
    const updatedSmartResponses = SmartResponsesHelper.updateChatIdInSmartResponses(chat.chatId, smartResponses);
    return adapter.upsertMany(updatedSmartResponses, state);
  })
);


