<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="(scriptGroup$ | async) as scriptGroup">
  <cee-accordion *ngIf="hasChildren(scriptGroup)"
    [titleText]="scriptGroup.name"
    [customClass]="'cee-capitalize'"
    [active]="(isScriptGroupActive$ | async)"
    [type]="level > 0 ? 'secondary' : 'primary'"
    (accordionChange)="onAccordionChange(scriptGroup)">
    <ng-container *ngIf="scriptGroup.scripts">
      <ul class="cee-list-block">
      <li *ngFor="let scriptId of scriptGroup.scripts" data-qa="script">
        <merc-convo-script-button
          [chatId]="chatId"
          [scriptGroup]="scriptGroup"
          [scriptId]="scriptId">
        </merc-convo-script-button>
      </li>
      </ul>
    </ng-container>

    <ng-container *ngFor="let id of scriptGroup.scriptGroups">
      <merc-convo-script-group *ngIf="level === 0"
        [level]="1"
        [chatId]="chatId"
        [chatScriptTreeId]="chatScriptTreeId"
        [scriptTreeId]="scriptTreeId"
        [scriptGroupId]="id">
      </merc-convo-script-group>
    </ng-container>
  </cee-accordion>
</ng-container>
