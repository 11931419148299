// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export const RecommendedActionControlTexts = {
  genericItg: 'Launch ITG'
};

export const RecommendedActionErrorMessages = {
  genericItg: 'Unable to launch ITG. To use the ITG, please launch through Einstein360.'
};
