// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { Customer,
  AppState, Color, ConnectAccountActions, UiActions, ChatOperations, Chat } from 'projects/entities/src/public_api';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Observable } from 'rxjs';
import { fromChat, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { logSelectedChatAction } from 'projects/entities/src/lib/domain/log-actions.actions';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { ConfirmAction } from 'projects/entities/src/lib/domain/models/enums';
import { SuggestionService } from 'src/app/services/suggestion.service';

@Component({
  selector: 'merc-convo-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './convo-header.component.html',
  styleUrls: ['./convo-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoHeaderComponent extends SubscriberComponent implements OnInit {
  private apps: string[][];
  dispositionIconWidth = 5;
  isOpen: boolean = false;
  accountNumber: string;
  applications: Map<string, string> = new Map<string, string>();
  color$: Observable<Color>;
  customer$: Observable<Customer>;
  isAuthenticated$: Observable<boolean>;
  isAccountConnected$: Observable<boolean>;
  isTransferrable$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  selectedChat$: Observable<Chat>;
  historyPanelIsOpen$: Observable<boolean>;
  hasDispositions$: Observable<boolean>;
  isItgRunning: boolean = false;
  isDetailsTabOpen: boolean = false;
  public customerName$: Observable<string>;

  constructor(
    private ngEntityStore: Store<AppState>,
    private config: AppConfigService,
    private suggestionService: SuggestionService)
  {
    super();
  }

  ngOnInit() {
    this.apps = this.config.appLauncher;
    this.color$ = this.ngEntityStore.select(fromChat.getSelectedChatColor).pipe(distinctUntilChanged());
    this.customer$ = this.ngEntityStore.select(fromChat.getSelectedChatCustomer).pipe(distinctUntilChanged());
    this.isAuthenticated$ = this.ngEntityStore.select(fromChat.getSelectedChatAuthenticated).pipe(distinctUntilChanged());
    this.isAccountConnected$ = this.ngEntityStore.select(fromChat.getSelectedChatIsAccountConnected).pipe(distinctUntilChanged());
    this.isTransferrable$ = this.ngEntityStore.select(fromChat.getSelectedChatIsTransferrable).pipe(distinctUntilChanged());
    this.isLoading$ = this.ngEntityStore.select(fromChat.getSelectedChatIsLoading).pipe(distinctUntilChanged());
    this.selectedChat$ = this.ngEntityStore.select(fromChat.getSelectedChat).pipe(distinctUntilChanged());
    this.historyPanelIsOpen$ =  this.ngEntityStore.select(fromUi.getHistoryPanelIsOpen).pipe(distinctUntilChanged());
    this.hasDispositions$ = this.ngEntityStore.select(fromChat.hasDispositions);
    this.customerName$ = this.ngEntityStore.select(fromChat.getSelectedChatCustomerName);

    this.ngEntityStore.select(fromChat.getSelectedChatAccountNumber)
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((accountNumber: string) => {
        this.accountNumber = accountNumber;
        this.applications = this.getApplications(accountNumber);
      });

    this.ngEntityStore.select(fromChat.getIsSelectedChatItgRunning)
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((itgRunning: boolean) => {
        this.isItgRunning = itgRunning;
      });
  }

  onCloseConversation(chat: Chat, hasDispositions: boolean) {
   this.handleConversation(ConfirmAction.Close, chat, hasDispositions);
  }

  onTransferConversation(chat: Chat, hasDispositions: boolean) {
    this.handleConversation(ConfirmAction.Transfer, chat, hasDispositions);
  }

  handleConversation(action: ConfirmAction, chat: Chat, hasDispositions: boolean){
    this.dropdownChange(false);
    if (this.isItgRunning){
      this.ngEntityStore.dispatch(UiActions.ToggleCloseTransferModal({toggleCloseTransferModal: true, action}));
    }
    else{
      this.suggestionService.handleConfirmAction(action, chat.state, chat.isAsync, hasDispositions, chat.chatId);
    }
  }
  
  onDetailsPanelClick(isOpen) {
    this.isDetailsTabOpen = isOpen;
    if (isOpen) {
      const logPayload = {
        logType: LogType.chat,
        operationName: ChatOperations.ViewDetailsPanel,
        data: null
      };
      this.ngEntityStore.dispatch(logSelectedChatAction({ logPayload }));
    }
  }

  onHistoryPanelClick(historyPanelIsOpen: boolean) {  
      this.ngEntityStore.dispatch(UiActions.UpdateHistoryPanel({ historyPanelIsOpen}));     
  }

  onConnectAccount() {
    this.ngEntityStore.dispatch(ConnectAccountActions.ToggleModalOpen(true));
  }

  dropdownChange(dropdownState) {
    this.isOpen = dropdownState;
  }

  private getApplications(accountNumber: string): Map<string, string> {
    const apps = new Map<string, string>();

    for (const app of this.apps) { //each endpoint has the account number as the last portion of the URL (need more sophisticated mechanism)
      apps.set(app[0], app[1] + accountNumber);
    }

    return apps;
  }
}
