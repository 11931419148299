// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { createPayloadAction } from '../action-adapters';
import { ItgDetails } from '../models/itg-details';
import { QualityCheckFollowupResponse } from '../models/qualityCheckFollowupResponse';
import { QualityCheckResponse } from '../models/qualityCheckResponse';
import { ApplicationConfigurationResponse } from '../models/responses/application-configuration-response';
import { State } from './settings.reducer';

export const updateLastSurveyCompleteDate = createAction(
  '[Custom Settings] Update Last Survey Complete Date',
  props<{ surveyTimestamp: number }>()
);

export const sendQualityCheckResponse = createAction(
  '[Custom Settings] Send Quality Check Response',
  props<{ qualityCheckResponse: QualityCheckResponse, qualityCheckDateTime: Date }>()
);

export const sendQualityCheckFollowupResponse = createAction(
  '[Custom Settings] Send Quality Check Followup Response',
  props<{ qualityCheckFollowupResponse: QualityCheckFollowupResponse , qualityCheckDateTime: Date}>()
);

export const sendNewFeatureBannerAcknowledgement = createAction(
  '[Custom Settings] Send New Feature Banner Acknowledgement',
  props<{ featureBannerAcknowledgementTimeStamp: Date, isCloseAction: boolean }>()
);

export const applicationConfigurationLoaded = createPayloadAction<
  ApplicationConfigurationResponse
>('[Application Configurations] Loaded');

export const hydrate = createAction('[Settings] Hydrate');

export const hydrateSuccess = createAction(
  '[Settings] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[Settings] Hydrate Failure');

export const getItgList = createAction(
  '[Application Configurations] Get Itg List'
);

export const ItgListLoaded = createAction(
  '[Application Configurations] Itg List Loaded',
  props<{ list: ItgDetails[] }>()
);
