// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { LoggingFactoryService, TokenStoreService, AuthService } from '@cxt-cee-chat/merc-ng-core';
import { Router } from '@angular/router';
import { MercuryRoutes } from '../constants/constants';
import { AppState, UserIdentityService,
    AgentStatePersisterService, AuthenticationProviderTypes, AgentOperations, LogHelper, ChatRequestApiService, ScriptTreeActions, HubsActions, AgentAuthActions, AgentAvailabilityActions, AgentActions, ProfanityActions, ProfileActions, toPayload, SettingsActions } from 'projects/entities/src/public_api';
import { LoggedOutMethod, LoginBanners } from 'projects/entities/src/lib/domain/models/enums';
import { fromApp } from 'projects/entities/src/lib/domain/selectors';
import { PageNames } from 'projects/entities/src/lib/constants/constants';

@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_Auth {
    constructor(
		protected ngEntityStore: Store<AppState>,
		protected actions: Actions,
    protected userIdentityService: UserIdentityService,
        protected tokenStoreService: TokenStoreService,
        protected chatRequestApiService: ChatRequestApiService,
        private router: Router,
        private statePersister: AgentStatePersisterService,
        private loggingFactory: LoggingFactoryService,
        @Inject(AuthService) protected authService: AuthService
	) {
  }

    loggedIn$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentAuthActions.loggedIn),
            concatLatestFrom(() => this.ngEntityStore.select(fromApp.getPageName)),
            tap(([, pageName]) => {
              if (pageName === PageNames.voice && this.router.url.toLowerCase().startsWith(`/${MercuryRoutes.Voice.toLowerCase()}`)){
                this.ngEntityStore.dispatch(AgentActions.LoadAgentMetrics());
                this.ngEntityStore.dispatch(AgentAvailabilityActions.loadSettings());
                this.ngEntityStore.dispatch(SettingsActions.getItgList());
              }
              else{
                this.ngEntityStore.dispatch(AgentAvailabilityActions.loadSettings());
                this.ngEntityStore.dispatch(AgentActions.LoadAgentMetrics());
                this.ngEntityStore.dispatch(ScriptTreeActions.loadCustomResponses());
                this.ngEntityStore.dispatch(ProfileActions.Load());
                this.ngEntityStore.dispatch(ProfanityActions.Load());
              }
            })
        ),
        { dispatch: false }
    );

    loggedOut$ = createEffect(() =>
        this.actions.pipe(
            ofType(AgentAuthActions.loggedOut),
            map((action) => toPayload<LoggedOutMethod>(action)),
            tap((method: LoggedOutMethod) => {
              if (method === LoggedOutMethod.UnauthorizedCall){
                LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.UnauthorizedLogOut, { message: 'Unauthorized on login, invalid token, logging agent out' });
              }
              this.ngEntityStore.dispatch(HubsActions.disconnectChatRequestHub());
              LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.LogOut, { method, authenticationProvider: this.statePersister.authenticationProvider });
              const route = this.statePersister.authenticationProvider === AuthenticationProviderTypes.NuanceSso || this.statePersister.authenticationProvider === AuthenticationProviderTypes.ComcastNuanceSso ? MercuryRoutes.SsoLogin : MercuryRoutes.FormLogin;
              if (method === LoggedOutMethod.Disconnect){
                this.router.navigate([route], { queryParams: { bannerReason: LoginBanners.Disconnected } });
              }
              else{
                this.router.navigate([route]);
              }

              this.statePersister.clearUnavailableTimestamp();
            })
        ),
        { dispatch: false }
    );
}
