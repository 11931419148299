<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
    oneCtiConnected: oneCtiConnected$ | async,
    callState: callState$ | async,
    feedContent: feedContent$ | async,
    hasRecommendationError: hasRecommendationError$ | async,
    customerName: customerName$ | async,
    showJumpToLatestButton: showJumpToLatestButton$ | async,
    isAtBottom: isAtBottom$ | async
  } as data">
  <ng-container *ngIf="data.oneCtiConnected; else oneCtiError">
    <ng-container *ngIf="isActiveCall(data.callState); else inactiveCall">

      <section class="merc-highlight-feed-container" (scroll)="onScroll($event)" #scrollWindow
        [class.merc-highlight-has-recommendation-error]="data.hasRecommendationError">
        <div *ngIf="data.feedContent?.length; else emptyHighlightFeed" class="merc-highlight-feed">
          <ng-container *ngFor="let content of data.feedContent; trackBy: trackById">
            <div [ngSwitch]="content.type" class="merc-highlight-feed-entry">
              <ng-container *ngSwitchCase="feedContentType.ItgRecommendation">
                <merc-voice-call-feed-reference [messageText]="content.message"></merc-voice-call-feed-reference>
                <merc-voice-recommended-action [recommendedAction]="content"></merc-voice-recommended-action>
              </ng-container>
              <ng-container *ngSwitchCase="feedContentType.PinnedMessage">
                <cee-call-entry [isHighlightEntry]="true"
                  [customerName]="data.customerName"
                  [isCustomer]="isCustomer(content.sender)"
                  (togglePinClicked)="unpinMessage(content.utteranceSequence, content.sender)"
                >{{ content.message }}</cee-call-entry>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <ng-template #emptyHighlightFeed>
          <div class="merc-highlight-feed-container--empty">
            <svg class="cee-icon">
              <use xlink:href="/assets/img/svg-sprite.svg#icon-lightning"></use>
            </svg>
            <span>Recommendations will appear here based on your conversation.</span>
          </div>
        </ng-template>

      </section>
      <div class="merc-highlight-feed-banner-error"
        *ngIf="data.hasRecommendationError">
        <cee-banner-v2 type="error">
          Unable to load new recommendations.
        </cee-banner-v2>
      </div>
      <cee-button-v2 customClass="merc-highlight-feed-jump-to-latest"
        *ngIf="data.showJumpToLatestButton && !data.isAtBottom" (buttonClick)="jumpToLatestClicked()">
        <svg class="cee-icon">
          <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-arrow'"></use>
        </svg>
        <span>Jump to Latest</span>
      </cee-button-v2>
    </ng-container>

    <ng-template #inactiveCall>
      <section class="merc-highlight-feed-container">
        <div class="merc-highlight-feed-container--empty">
          <svg class="cee-icon">
            <use xlink:href="/assets/img/svg-sprite.svg#icon-phone-connection"></use>
          </svg>
          <span>Information will be loaded when your next call begins.</span>
        </div>
      </section>
    </ng-template>

  </ng-container>
</ng-container>

<ng-template #oneCtiError>
  <section class="merc-highlight-feed-container">
    <div class="merc-highlight-feed-container--error">
      <div class="merc-highlight-feed-container--error-icon">
        <svg class="cee-icon merc-phone-connection-icon">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-phone-connection"></use>
        </svg>
        <svg class="cee-icon cee-icon--lg merc-phone-alert">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-alert-fill"></use>
        </svg>
      </div>
      <cee-banner-v2 type="error">
        OneCTI is not connected. New calls will not load.
      </cee-banner-v2>
    </div>
  </section>
</ng-template>
