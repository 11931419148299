// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class StringUtils {
    public static truncate(str: string, maxLength: number, addEllipsis: boolean = true): string {
        if (!str || str.length <= maxLength) { return str; }
        str = str.trim();
        return addEllipsis ? `${str.substring(0, maxLength - 3).trimEnd()}...` : str.substring(0, maxLength).trimEnd();
    }

    public static toTitleCase (str: string) {
        return str?.toLowerCase().split(' ').map(function(word) {
            return word.split('-').map(function(text){
                return text.replace(text[0], text[0].toUpperCase());
            }).join('-');
        }).join(' ');
    }
}