// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { BusinessUnitTransferOption } from './businessUnitTransferOption';

export class UpdateTransferOptions{
    chatId: string;
    businessUnits: BusinessUnitTransferOption[];
}
