// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromScriptTree from './script-tree.reducer';

@Injectable({
  providedIn: 'root'
})
export class ScriptTreePersisterService extends StatePersister<fromScriptTree.State> {
  constructor() {
    super(sessionStorage, fromScriptTree.scriptTreesFeatureKey);
  }
}
