// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { IncrementingTimerService, TimerFactoryService } from '@cxt-cee-chat/merc-ng-core';
import {
  Store
} from '@ngrx/store';
import { isOneCtiConnected } from 'projects/entities/src/lib/domain/one-cti/one-cti.selectors';
import { fromAgentAvailability, fromAgentFacingMetrics, fromChat, fromHubs, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { TimestampHelper } from 'projects/entities/src/lib/utils/timestamp-helper';
import {
  AgentStatePersisterService,
  AppState,
  AvailabilityChange,
  AvailabilityType
} from 'projects/entities/src/public_api';
import { Observable, fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  takeUntil, tap
} from 'rxjs/operators';
import { AvailabilityOption } from 'src/app/models/availability-options';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-header-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavComponent extends SubscriberComponent implements OnInit, OnDestroy {
  public showErrorModal: boolean = false;
  public availabilityError: boolean = false;
  public availabilityErrorReason: string;
  public options: AvailabilityOption[] = [];
  public selectedValue: string;
  public completedChatCount$: Observable<number>;
  public availabilityTimer: IncrementingTimerService;
  public restartTimerOnChatsClose: boolean = false;
  public hasChats$: Observable<boolean>;
  public isOneCtiConnected$: Observable<boolean>;
  public isChatHubConnected$: Observable<boolean>;
  public isChatConnectionPoor$: Observable<boolean>;
  public refreshStateError$: Observable<boolean>;
  public getScreenHeader$: Observable<string>;
  public agentAvailableTime$: Observable<number>;
  public agentUnavailableTime$: Observable<number>;
  public agentAvgResponseTime$: Observable<number>;
  public agentAvgHandleTime$: Observable<number>;
  public transferredChatCount$: Observable<number>;
  public refreshStateError: boolean;
  public refreshButtonClass = '';
  analyticsErrorTooltip = `<svg class="cee-icon" viewBox="0 0 16 16" id="err-icon">
          <use
            href="/assets/img/svg-sprite.svg#icon-exclamation"
          ></use>
        </svg> Unable to refresh data.`;
  ctiNotConnectedTooltipText = `Some Mercury functionality will not work until OneCTI is successfully connected.
  <br/ >If problem persists, contact leadership for support.`;
  public wifiErrorTooltipText = `It looks like you're having internet trouble. Attempting to reconnect.`;
  public wifiWarningTooltipText = 'Connection is poor and may affect performance.';

  constructor(
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private timerService: TimerFactoryService,
    private agentStatePersisterService: AgentStatePersisterService
  ) {
    super();

    const beforeunload$ = fromEvent(window, 'beforeunload');
    beforeunload$.pipe(
      tap(() => {
        this.onBeforeUnload();
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  ngOnInit() {
    this.isOneCtiConnected$ = this.store.select(isOneCtiConnected);
    this.isChatHubConnected$ = this.store.select(fromHubs.getIsChatRequestHubConnected);
    this.isChatConnectionPoor$ = this.store.select(fromHubs.selectIsChatConnectionPoor);

    const availSub$ = this.store
      .select(fromAgentAvailability.getAvailability)
      .subscribe((value: AvailabilityChange) => {
        if ((value.available === AvailabilityType.Error) !== this.availabilityError) {
          this.availabilityError = value.available === AvailabilityType.Error;
          this.availabilityErrorReason = value.reason;
          this.cdr.detectChanges();
        } else if (this.availabilityTimer) {
          if (value.available === AvailabilityType.Unavailable) {
            if (!this.availabilityTimer.isRunning) {
              this.agentStatePersisterService.storeUnavailableTimestamp(value.timestamp);
              this.onAvailabilityTimerStart(this.availabilityTimer, value.timestamp);
            }
          } else {
            this.onAvailabilityTimerStop(this.availabilityTimer);
          }
        }
      });
    this.subscriptions.push(availSub$);

    this.hasChats$ = this.store.select(fromChat.hasChats);
    const chatSub$ = this.hasChats$
      .pipe(distinctUntilChanged())
      .subscribe((hasChats: boolean) => {
        if (hasChats) {
          this.restartTimerOnChatsClose = true;
        } else if (this.availabilityTimer?.isRunning && this.restartTimerOnChatsClose) {
            this.restartTimerOnChatsClose = false;
            this.onAvailabilityTimerStop(this.availabilityTimer);
            this.availabilityTimer.start(0);
            this.agentStatePersisterService.storeUnavailableTimestamp(Date.now());
        }
      });
    this.subscriptions.push(chatSub$);

    this.availabilityTimer = this.timerService.createIncrementingTimer(this.ngZone);

    this.agentAvailableTime$ = this.store.select(fromAgentFacingMetrics.getMetricsDurationAvailable);
    this.agentUnavailableTime$ = this.store.select(fromAgentFacingMetrics.getMetricsDurationUnavailable);
    this.agentAvgResponseTime$ = this.store.select(fromAgentFacingMetrics.getMetricsAvgResponseTime);
    this.agentAvgHandleTime$ = this.store.select(fromAgentFacingMetrics.getMetricsAvgHandleTime);
    this.transferredChatCount$ = this.store.select(fromAgentFacingMetrics.getMetricsChatsTransferred);
    this.completedChatCount$ = this.store.select(fromAgentFacingMetrics.getChatsCompleted);

    this.updateAgentState();

    this.getScreenHeader$ = this.store.select(fromUi.getScreenHeader);
  }

  updateAgentState() {
    if (this.agentStatePersisterService.availabilityStatus?.available === AvailabilityType.Unavailable
      && this.agentStatePersisterService.unavailableTimestamp) {

      this.onAvailabilityTimerStart(this.availabilityTimer, this.agentStatePersisterService.unavailableTimestamp);
    }
  }

  onBeforeUnload() {
    if (this.availabilityTimer.isRunning) {
      this.availabilityTimer.dispose();
    }
    this.agentStatePersisterService.clearUnavailableTimestamp();
  }

  onAvailabilityTimerStart(timer: IncrementingTimerService, timestamp: number): void {
    timer.start(TimestampHelper.getSecondsSinceTimestampInMilliseconds(timestamp));
  }

  onAvailabilityTimerStop(timer: IncrementingTimerService): void {
    timer.stop();
  }

  onViewDetails() {
    this.showErrorModal = true;
  }

  onRefresh() {
    window.location.reload();
  }

  onCloseModal() {
    this.showErrorModal = false;
  }

  ngOnDestroy() {
    this.availabilityTimer.dispose();
    this.agentStatePersisterService.clearUnavailableTimestamp();
    super.ngOnDestroy();
  }

  getAgentMetricHeaders() {
    return 'Total chats<br />Transferred out<br />Time available<br />Time unavailable<br />Avg. response time<br />Avg. handle time';
  }

  getAgentMetricData(completedChatCount: number, transferredChatCount: number, availableTimestamp: number, unavailableTimestamp: number, agentAvgResponseTime: number, agentAvgHandleTime: number) {
    //Format all timestamps from ms to string
    const available = TimestampHelper.formatTimestampToHHMMSS(availableTimestamp);
    const unavailable = TimestampHelper.formatTimestampToHHMMSS(unavailableTimestamp);
    const avgResponseTime = TimestampHelper.formatTimestampToHHMMSS(agentAvgResponseTime);
    const avgHandleTime = TimestampHelper.formatTimestampToHHMMSS(agentAvgHandleTime);
    return `${completedChatCount}<br />${transferredChatCount}<br />${available}<br />${unavailable}<br />${avgResponseTime}<br />${avgHandleTime}`;
  }

  formatMetricTimestamp(timestamp: number) {
    return TimestampHelper.getRoundedTimeAgo(timestamp);
  }
}
