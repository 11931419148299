// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CustomEventDataLog } from '../../events/custom-event-data-log';

export class CallEvent extends CustomEventDataLog {
  callId: string;

  constructor(name: string, dimensions: {}) {
    super(name, dimensions);
    Object.assign(this, dimensions);
    this.name = name;
  }
}