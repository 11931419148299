// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, ExitAction, UiActions } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';

@Component({
  selector: 'merc-settings-confirm-exit-modal',
  templateUrl: './settings-confirm-exit-modal.component.html',
  styleUrls: ['./settings-confirm-exit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsConfirmExitModalComponent extends SubscriberComponent implements OnInit {
  @Output() modalClose: EventEmitter<ExitAction> = new EventEmitter<ExitAction>();
  active: boolean = false;

  constructor(
    private ngEntityStore: Store<AppState>,
  ) {
    super();
  }
  ngOnInit(): void {
    this.active = true;
  }

  closeModal(): void {
    this.sendExitAction(ExitAction.Closed);
  }

  cancelSettings(): void {
    this.sendExitAction(ExitAction.Canceled);
  }

  saveSettings(): void {
    this.sendExitAction(ExitAction.Saved);
  }

  private sendExitAction(exitAction: ExitAction): void {
    this.modalClose.emit(exitAction);
    this.ngEntityStore.dispatch(UiActions.closeCustomSettingsExitModal());
  }
}
