// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'merc-voice-call-feed-reference',
  templateUrl: './call-feed-reference.component.html',
  styleUrls: ['./call-feed-reference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceCallFeedReferenceComponent implements OnInit {
  @Input() messageText: string;

  constructor() { }

  ngOnInit(): void {
  }
}
