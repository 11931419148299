// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CallErrorState, CallState } from 'projects/entities/src/lib/domain/models/voice/enums';
import { fromAgentAuth, fromCall, fromVoiceUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { SenderType, VoiceUiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-live-transcript-header',
  templateUrl: './live-transcript-header.component.html',
  styleUrls: ['./live-transcript-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LiveTranscriptHeaderComponent extends SubscriberComponent implements OnInit, OnDestroy {
  callState$: Observable<CallState>;
  callErrorState$: Observable<CallErrorState>;
  isTranscriptFeedCollapsed$: Observable<boolean>;
  isShowAgentTranscriptEnabled$: Observable<boolean>;
  voiceSessionId$: Observable<string>;

  senderType: SenderType;

  @Input() shouldShowExpandCollapse: boolean;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.callState$ = this.store.select(fromCall.getCallState);
    this.callErrorState$ = this.store.select(fromVoiceUi.getCallErrorState);
    this.isTranscriptFeedCollapsed$ = this.store.select(fromVoiceUi.getIsTranscriptFeedCollapsed);
    this.isShowAgentTranscriptEnabled$ = this.store.select(fromVoiceUi.getIsShowAgentTranscriptEnabled);
    this.voiceSessionId$ = this.store.select(fromAgentAuth.getVoiceSessionId);
  }

  public toggleTransferFeedCollapse(isCurrentlyCollapsed: boolean) {
    this.store.dispatch(VoiceUiActions.ToggleLiveTranscriptCollapse({ isCollapsed: !isCurrentlyCollapsed }));
  }
  
  public toggleShowAgentTranscript(isCurrentlyEnabled: boolean) {
    this.store.dispatch(VoiceUiActions.ToggleShowAgentTranscript({ isEnabled: !isCurrentlyEnabled }));
  }

  isActiveCallErrored(callState: CallState, callErrorState: CallErrorState) {
    return callState !== CallState.Inactive && callErrorState !== CallErrorState.None;
  }
}

