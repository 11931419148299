// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChannelType } from './enums';
import { ChannelDevice } from './channelDevice';

export class Channel {
    type: ChannelType;
    device: ChannelDevice;    
}





