// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Disposition } from './disposition';

export class DispositionSelection {
    chatId: string;
    collectionId: string;
    collectionDisplayText: string;
    disposition: Disposition;
    notes: string;
}

export class DispositionRadioSelection {
  chatId: string;
  collectionId: string;
  dispositionId: string;
  notes: string;
  parentContainer: string;
}

export class DispositionLogInfo {
  category: DispositionLogInfoGroup;
  reason: DispositionLogInfoGroup;
  notes: string;
}

export class DispositionLogInfoGroup {
  id: string;
  name: string;
}