// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { MaskingService } from '@cxt-cee-chat/merc-ng-core';
import { SmartResponsesConstants } from '../constants/smart-responses.constants';
import { Chat, ChatInteraction, ChatMessage, ChatMessageType, SenderType } from '../domain/models';
import { LoadingState, SmartResponseComponentDisplayType, SmartResponseType } from '../domain/models/enums';
import { GetSmartResponsesResponse, SmartResponse, SmartResponseEntityResponse } from '../domain/models/responses/get-smart-responses-responses';
import { SelectedMessage } from '../domain/models/selectedMessage';
import { NumberedSmartResponseDialog, SmartResponseDialog } from '../domain/models/smart-response-dialog';
import { SmartResponseFeedbackVm } from '../domain/models/smart-response-feedback-vm';
import { SmartResponseToSendVm } from '../domain/models/smart-response-to-send-vm';
import { SmartResponseVm } from '../domain/models/smart-response-vm';
import { SmartResponsesSearchVm } from '../domain/models/smart-responses-search-vm';
import { Suggestion } from '../domain/models/suggestion';
import { Placeholder } from '../domain/placeholders/placeholder.model';
import { SmartResponses } from '../domain/smart-responses/smart-responses.model';
import { ChatHelper } from './chatHelper';
import { PlaceholderHelper } from './placeholder-helper';
import { StringHelper } from './string-helper';

export class SmartResponsesHelper {
  public static getKey({chatId, messageId}: {chatId: string, messageId: string}): string{
    return `${chatId}_${messageId}`;
  }

  public static getSmartResponseDialogData(chat: Chat, seedMessageId: string, maskingService: MaskingService, hasLanguageTranslatorFeatureFlag: boolean): NumberedSmartResponseDialog[]{
    const smartResponseDialogEndingWithSeedMessage = this.getSmartResponseDialogStartingWithSeedMessage(chat, seedMessageId, hasLanguageTranslatorFeatureFlag).reverse();
    const numberedSmartResponseDialog = smartResponseDialogEndingWithSeedMessage.map((d, index) => {
      return {
        ...d,
        utterance: maskingService.maskPersonalInfo(d.utterance),
        sequence: index + 1
      } as NumberedSmartResponseDialog;
    });
    return numberedSmartResponseDialog;
  }

  public static getSmartResponseDialogStartingWithSeedMessage(chat: Chat, seedMessageId: string, hasLanguageTranslatorFeatureFlag: boolean): SmartResponseDialog[]{
    const dialogs = [];
    let wordCount = 0;
    const xaTranscriptEndTimestamp = chat.xaTranscriptEndTimestamp;
    const asyncMessages = ChatHelper.getAsyncTranscriptMessages(chat.asyncEngagements);
    const chatMessages = ChatHelper.getXaTranscriptRemovedInteractions(chat.messages, xaTranscriptEndTimestamp);
    const messages = [...chatMessages, ...asyncMessages];
    ChatHelper.sortMessagesByTimestamp(messages);

    const seedMessageIndex = messages.findIndex((m) => m.messageId === seedMessageId);
    if (seedMessageIndex >= 0){
      for (let i = seedMessageIndex; i >= 0; i--){
        const msg = messages[i];
        wordCount += this.addDialogAndGetCount(msg, dialogs, hasLanguageTranslatorFeatureFlag);

        if (wordCount >= SmartResponsesConstants.minWordCount){
          return dialogs;
        }
      }
    }

    return dialogs;
  }


  public static addDialogAndGetCount(msg: ChatInteraction, dialogs: SmartResponseDialog[], hasLanguageTranslatorFeatureFlag: boolean): number{
    if (this.shouldAddDialog(msg)){
      const chatMessage = <ChatMessage>msg;
      const wordsInMessage = StringHelper.countWords(this.getSmartResponseMessage(chatMessage, hasLanguageTranslatorFeatureFlag));
      const dialog = this.createDialog(chatMessage, hasLanguageTranslatorFeatureFlag);
      dialogs.push(dialog);
      return wordsInMessage;
    }
    return 0;
  }

  public static shouldAddDialog(msg: ChatInteraction): boolean{
    if (msg.sender === SenderType.System || msg.sender === SenderType.XA) { return false; }
    switch (msg.type){
      case ChatMessageType.Message:
      case ChatMessageType.XaSuggestion:
        return true;
      default:
        return false;
    }
  }

  public static createDialog(msg: ChatMessage, hasLanguageTranslatorFeatureFlag: boolean): SmartResponseDialog{
    const isCustomer = msg.sender === SenderType.Requester;
    const speakerId = isCustomer ? SmartResponsesConstants.speakerId.customer : SmartResponsesConstants.speakerId.agent;
    const type = isCustomer ? SmartResponsesConstants.type.customerSent : SmartResponsesConstants.type.agentSent;
    return {
      speakerId,
      utterance: this.getSmartResponseMessage(msg, hasLanguageTranslatorFeatureFlag),
      type
    };
  }

  public static translateSmartResponsesToViewModel(response: GetSmartResponsesResponse): SmartResponseVm[] {
    const result = [];

    if (response?.smartResponses){
      response.smartResponses.appliedAi?.responses?.forEach(sr => {
        const appliedAiSmartResponseVm = this.translateSmartResponse(sr, SmartResponseType.AppliedAi, response.smartResponses.appliedAi.modelId);
        result.push(appliedAiSmartResponseVm);
      });

      response.smartResponses.nuance?.responses?.forEach(sr => {
        const nuanceSmartResponseVm = this.translateSmartResponse(sr, SmartResponseType.Nuance, response.smartResponses.nuance.modelId);
        result.push(nuanceSmartResponseVm);
      });
    }

    return result;
  }

  public static updateChatIdInSmartResponses(chatId: string, responses: SmartResponses[]): SmartResponses[] {
    return responses.map(r =>
      {
        return {
        ...r,
        chatId,
        state: LoadingState.Success
      };
    });
  }

  public static translateSmartResponse(smartResponse: SmartResponse, type: SmartResponseType, modelId: string): SmartResponseVm {
    const {id, category, confidence} = smartResponse;
    const {value, type: answerType} = smartResponse.answer;
    const vm: SmartResponseVm = {
      id: id ?? this.generateSmartResponseId(),
      category,
      confidence,
      value,
      answerType,
      type,
      entities: this.translateEntities(smartResponse.entities),
      modelId
    };
    return vm;
  }

  public static translateEntities(entities: SmartResponseEntityResponse[]): string[]{
    return entities?.map(e => {
      return e.key;
    });
  }

  public static getSmartResponseComponentDisplayType(selectedMessage: SelectedMessage, smartResponsesObject: SmartResponses, suggestions: Suggestion[]): SmartResponseComponentDisplayType {
    if (smartResponsesObject?.state == null || !smartResponsesObject?.smartResponses) {
      return SmartResponseComponentDisplayType.Notification;
    }

    const { state, smartResponses } = smartResponsesObject;
    const { isHistoric, canSearchSmartResponses } = selectedMessage;

    if (state === LoadingState.Failed) {
      return SmartResponseComponentDisplayType.Error;
    }

    if (isHistoric || !canSearchSmartResponses
      || (smartResponses?.length === 0 && state === LoadingState.Success && suggestions.length === 0)) {
      return SmartResponseComponentDisplayType.Notification;
    }

    if (state === LoadingState.Success) {
      return SmartResponseComponentDisplayType.SmartResponseList;
    }

    return SmartResponseComponentDisplayType.Skeleton;
  }

  public static getSmartResponsesFeedback(chat: Chat, agentId: string, agentGroupName: string, businessUnitName: string, agentMessage: string, sendSmartResponse: SmartResponseToSendVm, smartResponses: SmartResponsesSearchVm, agentInputText: string, smartResponseDisplayCount: number, displayedRecommendationIds: string[], displayedFilteredRecommendationIds: string[], filteredSmartResponse: SmartResponseVm[], maskingService: MaskingService, hasLanguageTranslatorFeatureFlag: boolean): SmartResponseFeedbackVm{
    const smartResponsesByMessageId = smartResponses ? [...smartResponses.smartResponses?.filter(f => f.id != null)] : null;
    const returnedSmartResponses = smartResponsesByMessageId?.slice(0, smartResponseDisplayCount);

    displayedFilteredRecommendationIds = agentInputText ? filteredSmartResponse?.map(d => d.id)?.filter(f => displayedFilteredRecommendationIds?.includes(f)) : displayedRecommendationIds;

    const selectedMessage = chat?.messages?.find(f => f.messageId === sendSmartResponse?.parentMessageId);
    const sendSmartResponseText = smartResponsesByMessageId?.find(f => f.id === sendSmartResponse.id)?.searchText ?? '';

    const feedback: SmartResponseFeedbackVm = {
      agentGroupName: agentGroupName,
      agentId: agentId,
      businessUnitName: businessUnitName,
      customerId: chat.nuanceCustomerId,
      engagementId: chat.chatId,
      recommendationFilteringEnabled: true,
      agentMessage: maskingService.maskPersonalInfo(agentMessage),
      didEditRecommendations: ChatHelper.isTextModified(agentMessage, sendSmartResponseText),
      returnedRecommendationIds: returnedSmartResponses?.map(s => s.id)?.join() ?? '',
      agentLastInputText: maskingService.maskPersonalInfo(agentInputText),
      displayedRecommendationIds: displayedRecommendationIds?.join() ?? '',
      displayedFilteredRecommendationIds: displayedFilteredRecommendationIds?.join() ?? '',
      modelId: sendSmartResponse.modelId,
      selectedFilteredRecommendationRanks: (displayedFilteredRecommendationIds && sendSmartResponse?.id ? displayedFilteredRecommendationIds.indexOf(sendSmartResponse?.id) + 1 : '').toString(),
      selectedRecommendationRanks: (smartResponsesByMessageId && sendSmartResponse?.id ? smartResponsesByMessageId.map(d => d.id).indexOf(sendSmartResponse?.id) + 1 : '').toString(),
      selectedRecommendationIds: sendSmartResponse?.id ?? '',
      selectedSpeaker: selectedMessage?.sender === SenderType.Requester ? SmartResponsesConstants.speakerId.customer.toLocaleLowerCase() : SmartResponsesConstants.speakerId.agent.toLocaleLowerCase(),
      selectedSpeakerMessage: maskingService.maskPersonalInfo(this.getSmartResponseMessage(selectedMessage as ChatMessage, hasLanguageTranslatorFeatureFlag))
    };
    return feedback;
  }

  public static getSearchSmartResponses(smartResponses: SmartResponses, placeholders: Placeholder[] ){
    if (smartResponses?.smartResponses){
      const result: SmartResponsesSearchVm = {
        ...smartResponses,
        smartResponses: smartResponses.smartResponses.map(sr => {
          return {
            ...sr,
            searchText: PlaceholderHelper.updateWithPlaceholders(sr?.value, sr?.entities, placeholders)
          };
        })
      };
      return result;
    }
    return smartResponses ? {
      ...smartResponses,
      smartResponses: null
    } : undefined;
  }

  public static getFilteredSmartResponses(smartResponses: SmartResponses, filterTexts: string[], filterResponseType?: boolean, filterType?: SmartResponseType){
    if (smartResponses?.smartResponses){
      const result = {...smartResponses};

      if (filterTexts.length > 0 || filterResponseType){
        result.smartResponses = result.smartResponses.filter(sr => {
          const textToSearch = sr.searchText?.toLowerCase();

          // when there is nothing to filter on (filterTexts.length === 0), it should be included
          const hasFilteredTexts = filterTexts.length === 0 || filterTexts.every(ft => textToSearch?.includes(ft.toLowerCase()));

          if (filterResponseType){
            return hasFilteredTexts && filterType === sr.type;
          }

          return hasFilteredTexts;
        });
      }

      result.smartResponses = result.smartResponses.map(smartResponse => {
        const sr = {...smartResponse};
        delete sr.searchText;
        return sr;
      });

      return result;
    }

    return smartResponses;
  }

  public static getSmartResponseMessage(msg: ChatMessage, hasLanguageTranslatorFeatureFlag: boolean){
    const translatedText = msg?.translationData?.translatedMessage;
    const useTranslatedText = ChatHelper.shouldUseTranslatedText(hasLanguageTranslatorFeatureFlag, msg?.sender, translatedText, msg?.translationData?.hasError);
    return useTranslatedText ? translatedText : msg?.message;
  }
  public static generateSmartResponseId(){    
    return 'agent-' + Math.floor(100000 + Math.random() * 900000);
  }
}
