<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{ scriptGroup: (scriptGroup$ | async),
  parentScriptGroup: (allScriptGroups$ | async)
 } as data;" >
 
  <div class="merc-script-group-manage-item">
  <ng-container *ngIf="level === 0; else accordionContainer">
    <div>
    <ul class="cee-list-block" 
      cdkDropList 
      [cdkDropListData]="data.scriptGroup?.scripts"
      (cdkDropListDropped)="onScriptDrop($event, data.scriptGroup)"
      id="{{data.scriptGroup?.id}}"
      [cdkDropListConnectedTo]="getConnectedScriptGroups(data.parentScriptGroup)"
    >
      <!--- Groupless Scripts --->
      <li *ngFor="let scriptId of data.scriptGroup?.scripts" data-qa="management-groupless-script">
        <div cdkDrag [cdkDragData]="scriptId">
          <span class="merc-script-button-drag">
            <cee-button type="link" size="icon">
              <span class="cee-accessibly-hidden">Drag this script group to move it</span>
              <svg class="cee-icon cee-icon--secondary" width="16" height="16" viewBox="0 0 16 16" >
                  <use xlink:href="/assets/img/svg-sprite.svg#icon-drag"></use>
              </svg>
            </cee-button>
          </span>
          <merc-convo-script-panel-button
              [script]="scriptId"
              (manageEditClick)="emitButtonClick()">
          </merc-convo-script-panel-button>
        </div>
      </li>
    </ul>
    </div>

    <div cdkDropList
    [cdkDropListData]="data.scriptGroup"
    (cdkDropListDropped)="onScriptGroupDrop($event, data.scriptGroup?.scriptGroups, data.scriptGroup?.id)">
    <!--- Script Groups --->
    <ng-container *ngFor="let id of data.scriptGroup?.scriptGroups">
      <div cdkDrag [cdkDragData]="id" class="merc-script-group-drag">
        <span class="merc-script-button-drag merc-script-button-drag--group">
          <cee-button type="link" size="icon">
            <span class="cee-accessibly-hidden">Drag this script group to move it</span>
            <svg class="cee-icon cee-icon--secondary" width="16" height="16" viewBox="0 0 16 16" >
                <use xlink:href="/assets/img/svg-sprite.svg#icon-drag"></use>
            </svg>
          </cee-button>
        </span>
        <merc-script-group-manage
          [scriptGroupId]="id"
          [level]="1"
          (manageEditClick)="emitButtonClick()"
        ></merc-script-group-manage>
      </div>
    </ng-container>
    </div>
  </ng-container>

  <ng-template #accordionContainer>
    <!--- Group Header --->
    <cee-accordion
    [titleText]="data.scriptGroup?.name"
    [customClass]="'cee-capitalize'"
    [active]="true"
    [type]="'primary'"
    >
    <div cdkDropList 
    (cdkDropListDropped)="onScriptDrop($event, data.scriptGroup)"
    [cdkDropListData]="data.scriptGroup?.scripts"
    id="{{data.scriptGroup?.id}}"
    [cdkDropListConnectedTo]="getConnectedScriptGroups(data.parentScriptGroup)">
      <ng-container *ngIf="data.scriptGroup?.scripts?.length > 0; else emptyMessage">
          <ul class="cee-list-block">
            <!--- Scripts --->
            <li *ngFor="let scriptId of data.scriptGroup?.scripts" data-qa="management-script">
              <div cdkDrag
              [cdkDragData]="scriptId">
                <span class="merc-script-button-drag">
                  <cee-button type="link" size="icon">
                    <span class="cee-accessibly-hidden">Drag this script group to move it</span>
                    <svg class="cee-icon cee-icon--secondary" width="16" height="16" viewBox="0 0 16 16" >
                        <use xlink:href="/assets/img/svg-sprite.svg#icon-drag"></use>
                    </svg>
                  </cee-button>
                </span>
                <merc-convo-script-panel-button
                    [script]="scriptId"
                    (manageEditClick)="emitButtonClick()">
                </merc-convo-script-panel-button>
              </div>
            </li>
          </ul>
      </ng-container>
      <ng-template #emptyMessage>
        <p class="cee-scripts-message">No custom responses have been added to this header yet</p>
      </ng-template>
    </div>
    </cee-accordion>

    <span class="merc-script-panel-button-option merc-script-group-manage-item-buttons">
      <cee-button type="icon" (click)="scriptGroupEditClicked()">
        <svg class="cee-icon cee-icon--secondary">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-edit"></use>
        </svg>
      </cee-button>
      <cee-button type="icon" (click)="scriptGroupDeleteClicked()">
        <svg class="cee-icon cee-icon--secondary">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-delete"></use>
        </svg>
      </cee-button>
    </span>
  </ng-template>
  </div>
</ng-container>
