// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { Models } from 'projects/entities/src/public_api';
import { createPayloadAction } from '../action-adapters';
import { Chat, ChatInteraction, ChatMessage, SendChatMessage } from '../models/';
import { DataCollectionState, TranslatedMessageType } from '../models/enums';
import { GetPriorEngagementsActionData } from '../models/getPriorEngagementsActionData';
import { GetXaSuggestionsMessage } from '../models/getXaSuggestionsMessage';
import { GroupAutomationSettings } from '../models/group-automation-settings';
import { ChatTranscriptResponse } from '../models/responses/chat-transcript-response';
import { CustomerStateChangeResponse } from '../models/responses/customerStateChangeResponse';
import { RecommendationsResponse } from '../models/responses/recommendationsResponse';
import { SelectedSchedule } from '../models/selectedSchedule';
import { ScriptInUse } from '../models/setScriptInUse';
import { SmartResponseToSendVm } from '../models/smart-response-to-send-vm';
import { Suggestion } from '../models/suggestion';
import { XaSuggestionChatMessage } from '../models/xa-suggestion-chat-message';
import { SmartResponses } from '../smart-responses/smart-responses.model';
import { Placeholder } from '../placeholders/placeholder.model';
import { SmartResponseFeedbackVm } from '../models/smart-response-feedback-vm';
import { ItgMetadata } from '../models/itgMetadata';
import { SendAutoGreetingMessage } from '../models/sendAutoGreetingMessage';
import { ChatSummaryData } from '../models/chatSummaryData';
import { CxGptResponseVm } from '../models/cx-gpt-response';
import { TranslationResponse } from '../models/responses/translation-response';

export const AcceptNewChat = createAction('[Chat] Accept New Chat', props<{ chat: Chat, recommendation?: RecommendationsResponse, smartResponses?: SmartResponses[], placeHolders?: Placeholder[], itgsInProgress?: ItgMetadata[] }>());

export const RefuseChat = createAction('[Chat] Refuse Chat', props<{ chat: Chat }>());

export const ChatAccepted = createAction('[Chat] Chat Accepted', props<{ chat: Chat, chatAutomationSettings: GroupAutomationSettings[] }>());

export const AcceptChatResponse = createAction('[Chat] Accept Chat Response', props<{ success: boolean, chatId: string }>());

export const ChatAcceptanceFailed = createPayloadAction<Chat>(
  '[Chat] Chat Acceptance Failed'
);

export const ChatAcceptanceUnknown = createPayloadAction<Chat>(
  '[Chat] Chat Acceptance Unknown'
);

export const NewMessage = createPayloadAction<Models.ChatInteraction>(
  '[Chat] New Message'
);

export const SendMessage = createAction(
  '[Chat] Send Message',
  props<{ chatMessage: Models.SendChatMessage }>()
);

export const MaskText = createAction(
  '[Chat] Mask Text',
  props<{ maskText: Models.MaskText }>()
);

export const TextMasked = createAction(
  '[Chat] Text Masked',
  props<{ maskText: Models.MaskText, messageId: string }>()
);

export const MaskTextFailed = createAction(
  '[Chat] Mask Text Failed',
  props<{ maskText: Models.MaskText }>()
);

export const CopyText = createPayloadAction<string>('[Chat] Copy Text');

export const Close = createPayloadAction<Models.CloseChat>('[Chat] Close');

export const AutoClose = createAction('[Chat] Auto Close', props<{ chatId: string }>());

export const PromptCloseEngagement = createPayloadAction<Chat>(
  '[Chat] Prompt Close Engagement'
);

export const CloseAsyncChat = createPayloadAction<Models.CloseAsyncChat>(
  '[Chat] Close Async Chat'
);

export const Closed = createPayloadAction<Chat>('[Chat] Closed');

export const CheckCustomerHeartbeat = createAction(
  '[Chat] Check Customer Heartbeat',
  props<{ chatId: string, timeFromLastHeartbeat: number }>()
);

export const CustomerHeartbeatChecked = createAction(
  '[Chat] Customer Heartbeat Checked',
  props<{ chatId: string }>()
);

export const CustomerHeartbeatExpired = createAction(
  '[Chat] Customer Heartbeat Expired',
  props<{ stateChangeResponse: CustomerStateChangeResponse }>()
);

export const CustomerExited = createAction(
  '[Chat] Customer Exited',
  props<{ stateChangeResponse: CustomerStateChangeResponse }>()
);

export const CloseEngagementInstruction = createAction(
  '[Chat] Close Engagement Instruction',
  props<{ stateChangeResponse: CustomerStateChangeResponse, source: string }>()
);

export const CustomerDisconnected = createPayloadAction<string>(
  '[Chat] Customer Disconnected'
);

export const CustomerReconnected = createPayloadAction<string>(
  '[Chat] Customer Reconnected'
);

export const DispositionsReceived = createPayloadAction<
  Models.DispositionResponse
>('[Chat] Dispositions Received');

export const Transfer = createPayloadAction<Models.TransferChat>(
  '[Chat] Transfer'
);

export const TransferFailed = createPayloadAction<Chat>(
  '[Chat] Transfer Failed'
);

export const Transferred = createPayloadAction<Chat>('[Chat] Transferred');

export const UpdateTransferOptions = createPayloadAction<
  Models.UpdateTransferOptions
>('[Chat] UpdateTransferOptions');

export const Bounce = createPayloadAction<string>('[Chat] Bounce');

export const Bounced = createPayloadAction<Chat>('[Chat] Bounced');

export const GetPriorEngagements = createPayloadAction<GetPriorEngagementsActionData>(
  '[Chat] Get Prior Engagements'
);

export const SetPriorEngagements = createPayloadAction<
  Models.SetPriorEngagements
>('[Chat] Set Prior Engagements');

export const ChannelInformation = createPayloadAction<
  Models.ChannelInformation
>('[Chat] Channel Information');

export const History_EngagementSelected = createPayloadAction<
  Models.SelectedHistoryEngagement
>('[Chat] History Engagement Selected');

export const PriorEngagementAccordionChange = createPayloadAction<
  Models.PriorEngagementAccordionChange
>('[Chat] Prior Engagement Accordion Change');

export const GetChatAccountLocality = createPayloadAction<
  Models.GetChatAccountLocality
>('[Chat] Get Chat Account Locality');

export const SetChatAccountLocality = createPayloadAction<
  Models.ChatAccountLocality
>('[Chat] Set ChatAccount Locality');

export const ReportImage = createPayloadAction<Models.ChatImageMessage>(
  '[Chat] Report Image'
);

export const UpdateAccountNumber = createPayloadAction<
  Models.UpdateAccountNumber
>('[Chat] Update Account Number');

export const UpdateAccountInformation = createPayloadAction<
  Models.UpdateAccountInformation
>('[Chat] Update Account Information');

export const LoadContext = createPayloadAction<Models.LoadContext>(
  '[Chat] Load Context'
);

export const UpdateUui = createPayloadAction<Models.UpdateUui>(
  '[Chat] Update Uui'
);

export const ChatFirstLoadSuccess = createPayloadAction<string>(
  '[Chat] Chat First Load Success'
);

export const ChatLoadComplete = createPayloadAction<string>(
  '[Chat] Chat Load Complete'
);

export const GetCustomerInformation = createPayloadAction<string>(
  '[Chat] Get Customer Information'
);

export const GetSystemScripts = createPayloadAction<Chat>(
  '[Chat] Get System Scripts'
);

export const SystemScriptsLoaded = createPayloadAction<
  Models.SystemScriptsLoaded
>('[Chat] System Scripts Loaded');

export const SetScriptInUse = createAction(
  '[Chat] Set Script In Use',
  props<{ scriptInUse: ScriptInUse }>()
);

export const ClearScriptsInUse = createAction(
  '[Chat] Clear Scripts In Use',
  props<{ chatId: string }>()
);

export const getActiveEngagementsResponse = createAction(
  '[Chat] Get Active Engagements Response',
  props<{ engagements: ChatTranscriptResponse[] }>()
);

export const updateActiveEngagements = createAction(
  '[Chat] Update Active Engagements',
  props<{ engagements: ChatTranscriptResponse[], storageChats: Chat[], chats: Chat[], serverSyncTimestamp: number }>()
);

export const GetAsyncEngagements = createPayloadAction<
  Models.GetAsyncEngagements
>('[Chat] Get Async Engagements');

export const UpdateAsyncEngagements = createAction(
  '[Chat] Update Async Engagements',
  props<{ updateAsyncEngagements: Models.UpdateAsyncEngagements }>()
);

export const GetActiveChatTranscript = createPayloadAction<string>(
  '[Chat] Get Active Chat Transcript'
);

export const GetActiveChatTranscriptResponse = createAction(
  '[Chat] Get Active Chat Transcript Response',
  props<{ chatId: string, transcript: ChatTranscriptResponse }>()
);

export const getActiveEngagements = createAction(
  '[Chat] Get Active Engagements'
);

export const UpdateMessages = createPayloadAction<Models.UpdateMessages>(
  '[Chat] Update Messages'
);

export const JoinChats = createAction(
  '[Chat] Join Chats',
  props<{ chatIds: string[] }>()
);

export const JoinChatsResponse = createAction(
  '[Chat] Join Chats Response',
  props<{ successChatIds: string[], failedChatIds: string[] }>()
);


export const JoinChatFailed = createAction(
  '[Chat] Join Chat Failed',
  props<{ chatId: string }>()
);

export const AddXaSuggestions = createPayloadAction<Models.AddXaSuggestions>(
  '[Chat] Add Xa Suggestions'
);

export const AddRecommendation = createAction(
  '[Chat] Add Xa Recommendation',
  props<{addRecommendation: Models.AddRecommendation}>()
);

export const ClearLatestItgSuggestions = createAction(
  '[Chat] Clear Latest Itg Suggestions',
  props<{chatId: string}>()
);

export const DismissLatestSuggestions = createAction(
  '[Chat] Dismiss Latest Suggestions',
  props<{sendSuggestion: Models.SendSuggestion, isIgnored: boolean, timestamp: number}>()
);

export const ClearLatestSuggestions = createPayloadAction<string>(
  '[Chat] Clear Latest Suggestions',
);

export const ClickSuggestion = createPayloadAction<Models.SendSuggestion>(
  '[Chat] Click Suggestion'
);

export const SendSuggestion = createPayloadAction<Models.SendSuggestion>(
  '[Chat] Send Suggestion'
);

export const ClearClickedSuggestion = createPayloadAction<string>(
  '[Chat] Clear Clicked Suggestion'
);

export const SendSmartResponse = createAction(
  '[Chat] Send Smart Response',
  props<{chatMessage: Models.SendChatMessage, smartResponseToSend: SmartResponseToSendVm}>()
);

export const SendCxGptResponse = createAction(
  '[Chat] Send CxGpt Response',
  props<{chatMessage: Models.SendChatMessage, cxGptResponse: CxGptResponseVm}>()
);

export const ClearClickedSmartResponse = createAction(
  '[Chat] Clear Clicked SmartResponse',
  props<{chatId: string}>()
);

export const AsyncEngagementSelected = createPayloadAction<
  Models.SelectedHistoryEngagement
>('[Chat] Async Engagement Selected');

export const MarkAsMercuryChat = createPayloadAction<
  Models.MarkAsMercuryChatRequest
>('[Chat] Mark As Mercury Chat');

export const messageSent = createAction(
  '[Chat] Message Sent',
  props<{ chatId: string, traceId: string, messageId: string, chatMessage: Models.ChatInteraction, timestamp: number, numAutoRetries: number, translatedText?: string, enhancedText?: string }>()
);

export const updateMessageId = createAction(
  '[Chat] Update Message Id',
  props<{ chatId: string, traceId: string, messageId: string }>()
);

export const GetEngagementById = createPayloadAction<Models.GetEngagementById>(
  '[Chat] Get Engagement By Id'
);

export const EinsteinChatButtonClicked = createAction(
  '[Chat] Einstein ChatButton Clicked',
);

export const ECMButtonClicked = createAction(
  '[Chat] ECM ChatButton Clicked'
);

export const CtiApplicationFocus = createPayloadAction<
  Models.CtiFocusApplication
>('[Chat] Cti Application Focus');

export const GetDispositions = createPayloadAction<string>(
  '[Chat] Get Dispositions'
);

export const SelectedDispositions = createPayloadAction<
  Models.DispositionRadioSelection
>('[Chat] Selected Dispositions');

export const UpdateSendCustomerQuickResponses = createPayloadAction<
  Models.CustomerResponseButtonChange
>('[Chat] Update Send Customer Quick Responses');

export const GetXaSuggestions = createAction(
  '[Chat] GetXaSuggestions',
  props<{ chatId: string, message: GetXaSuggestionsMessage }>());

export const UpdateRecommendation = createAction(
  '[Chat] UpdateRecommendation',
  props<{ recommendation: RecommendationsResponse }>());

export const UpdateSelectedSchedule = createAction(
  '[Chat] Selected Schedule',
  props<{ chatId: string, selectedSchedule: SelectedSchedule }>()
);

export const StartDataCollectionRequest = createAction(
  '[Chat] Start Data Collection Request',
  props<{ chatId: string, startTime: number }>()
);

export const CancelDataCollectionRequest = createAction(
  '[Chat] Cancel Data Collection Request',
  props<{ chatId: string }>()
);

export const sendMessageFailed = createAction(
  '[Chat] Send Message Failed',
  props<{ chatId: string, traceId: string, numAutoRetries: number, isTranslationError?: boolean }>()
);

export const sendSuggestionFailed = createAction(
  '[Chat] Send Suggestion Failed',
  props<{ chatId: string, traceId: string, numAutoRetries: number, isTranslationError?: boolean }>()
);

export const retrySendMessage = createAction(
  '[Chat] Retry Send Message',
  props<{ chatMessage: SendChatMessage, newTraceId: string }>()
);

export const retryTranslateMessage = createAction(
  '[Chat] Retry Translate Message',
  props<{ chatMessage: ChatMessage }>()
);

export const retryTranslateMessageOnReload = createAction(
  '[Chat] Retry Translate Message On Reload',
  props<{ chat: Chat }>()
);

export const retryTranslateMessageUpdated = createAction(
  '[Chat] Retry Translate Message Updated',
  props<{ chatMessage: ChatMessage, isError: boolean, translationResponse: TranslationResponse }>()
);

export const retrySendXaSuggestion = createAction(
  '[Chat] Retry Send Xa Suggestion',
  props<{ chatMessage: XaSuggestionChatMessage, newTraceId: string }>()
);

export const deleteChatMessage = createAction(
  '[Chat] Delete Chat Message',
  props<{ chatMessage: ChatMessage }>()
);

export const AddGreetingSuggestion = createAction(
  '[Chat] Add Greeting Suggestion',
  props<{ chatId: string, suggestion: Models.GreetingSuggestion }>()
);

export const NewExternalSuggestion = createAction(
  '[Chat] New External Suggestion',
  props<{ chatId: string, suggestion: Models.ExternalSuggestion }>()
);

export const AddExternalSuggestions = createAction(
  '[Chat] Add External Suggestions',
  props<{ chatId: string, suggestion: Models.ExternalSuggestion }>()
);

export const InitiateSecureDataCollection = createAction(
  '[Chat] Initiate Secure Data Collection',
  props<{ requestData: Models.SecureDataCollectionInfo }>()
);

export const UpdateSecureDataCollectionState = createAction(
  '[Chat] Update Secure Data Collection Request State',
  props<{ chatId: string, requestState: DataCollectionState, timestamp: number }>()
);

export const StoredTextToSend = createAction(
  '[UI] StoredTextToSend',
  props<{ chatId: string, storedTextToSend: Models.StoredTextToSend }>()
);

export const oneCtiStartVerified = createAction(
  '[Chat] One Cti Start Verified',
  props<{ chatId: string }>()
);

export const hideSuggestion = createAction(
  '[Chat] Hide Suggestion',
  props<{ chatId: string, suggestion: Suggestion }>()
);

export const persistChats = createAction('[Chat] Persist Chats');

export const AddXaTranscript = createAction(
  '[Chat] Add Xa Transcript',
  props<{chatId: string, messages: ChatInteraction[], xaTranscriptEndTimestamp: number}>()
);

export const UpdateSmartResponseFeedBack = createAction(
  '[Chat] Update smart Response Feedback',
  props<{ chatId: string, feedback: SmartResponseFeedbackVm }>()
);

export const SendChatStartMessages = createAction(
  '[Chat] Send Chart Start messages',
  props<{ chatId: string, autoGreetingMessage: SendAutoGreetingMessage }>()
);

export const AddCustomerAwaitingReplySuggestion = createAction(
  '[Chat] Add Customer Awaiting Reply Suggestion',
  props<{chatId: string}>()
);

export const TriggerCustomerAwaitingReplyNotification = createAction(
  '[Chat] Trigger Customer Awaiting Reply Notification',
  props<{chatId: string, windowHasFocus: boolean, value: number}>()
);

export const CustomerAwaitingReplyNotificationClicked = createAction(
  '[Chat] Customer Awaiting Reply Notification clicked',
  props<{chatId: string}>()
);

export const UpdateChatSummary = createAction(
  '[Chat] Update Chat Summary',
  props<{ chatId: string, chatSummaryData: ChatSummaryData, error?: boolean }>()
);

export const UpdateBotConversationSummary = createAction(
  '[Chat] Update Bot Summary',
  props<{ chatId: string, botConversationSummary: string }>()
);

export const rateSessionSummary = createAction(
  '[Chat] Rate Session Summary',
  props<{ chatId: string, isPositiveRating: boolean, useNotes: boolean }>()
);

export const resetSessionSummaryRating = createAction(
  '[Chat] Reset Session Summary Rating',
  props<{ chatId: string }>()
);

export const EnhanceChatMessage = createAction(
  '[Chat] Enhance Chat Message',
  props<{ chatId: string, message: string }>()
);

export const UpdateEnhanceChatMessage = createAction(
  '[Chat] Update Enhance Chat Message',
  props<{ chatId: string, message: string, error?: boolean, errorMessage?: string }>()
);

export const rateAsyncEngagementsSummary = createAction(
  '[Chat] Rate Async Engagements Summary',
  props<{ chatId: string, isPositiveRating: boolean }>()
);

export const LogTranslationRequest = createAction(
  '[Chat] Log Translation Request',
  props<{ chatId: string, messageType: TranslatedMessageType, isRetry: boolean }>()
);

export const TranslationToggled = createAction(
  '[Chat] Translation Toggled',
  props<{ chatId: string, isEnabled: boolean }>()
);
