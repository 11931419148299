// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatAttachment } from './chatAttachment';
import { ChatMessageType } from './enums';

export class ChatImageMessage extends ChatAttachment {
    type: ChatMessageType = ChatMessageType.Image;
    reported: boolean;
}