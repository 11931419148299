// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SuggestionBase } from '../suggestion';

export class GetXaSuggestionsResponse {
  chatId: string;
  suggestions: SuggestionBase[];
  message: string;
  messageId: string;
  queryId: string;
  sessionId: string;
  json: string;
  errorMessages?: ErrorMessage[];
  isTimeSlotTakenError?: boolean;
  isUnSupportedContentError?: boolean;
  intentValue: string;
  intentResult: string;
}

export class ErrorMessage{
  message: string;
  errorType: string;
  errorCode: string;
}
