// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AgentActions, BounceTimerType } from 'projects/entities/src/public_api';
import { Subscription, timer } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AgentOperations } from '../../constants/event-logs.constants';
import { LogHelper } from '../../utils/logHelper';
import { ChatActions, ChatUiActions, UiActions } from '../actions';
import { CloseSuggestionFeedbackMethod } from '../models/enums';
import { fromAgent, fromChat, fromUi } from '../selectors';
import { AppState } from '../state';

@Injectable()
export class UiEffects {
  retrieveAgentAvailabilitySummary: Subscription;

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private loggingFactory: LoggingFactoryService,
    private timeService: DayTimeService
  ) { }

  sessionLockoutOpen$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.openSessionLockoutWarningModal),
      tap(({elapsedTime}) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutWarning, { elapsedTime });
      })),
    { dispatch: false }
  );

  sessionLockoutClose$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.closeSessionLockoutWarningModal),
      tap(({elapsedTime, warningElapsedTime}) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutWarningCancel, { elapsedTime, warningElapsedTime });
      })),
    { dispatch: false }
  );

  dismissNotificationBanner$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.dismissNotificationBanner),
      concatLatestFrom(_ => [this.store.select(fromUi.getNotificationBounceType),
        this.store.select(fromUi.getNotificationBannerDetails)]),
      tap(([, type, bannerDetails]) => {
        if (type === BounceTimerType.Bounced) {
          this.store.dispatch(UiActions.dismissBouncedNotificationBanner());
        }
        else if (bannerDetails && bannerDetails.length > 0){
          const { chatId } = bannerDetails[0];
          this.store.dispatch(UiActions.NotificationBannerClosed({ chatId }));
        }
      })
    ),
    { dispatch: false }
  );

  notificationBannerClosed$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.NotificationBannerClosed),
      tap(({chatId}) => {
        const lastViewedTimestamp = this.timeService.unix();
        this.store.dispatch(ChatUiActions.agentSelectedChat({ chatId, lastViewedTimestamp }));
      })
    ),
    { dispatch: false }
  );

  agentSummaryPanelToggled$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.ToggleAgentSummaryPanel),
      tap(({toggleAgentSummaryPanel}) => {
       if (toggleAgentSummaryPanel) {
         if (!this.retrieveAgentAvailabilitySummary || this.retrieveAgentAvailabilitySummary.closed) {
          this.retrieveAgentAvailabilitySummary = timer(0, 10000).subscribe(() => {
            this.store.dispatch(AgentActions.GetAgentAvailabilitySummary());
          }); 
         }

         LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChangeScreen, { screen: 'availabilitySummary' });
         
       } else {
         if (this.retrieveAgentAvailabilitySummary) {
          this.retrieveAgentAvailabilitySummary.unsubscribe();
         }

         LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChangeScreen, { screen: 'chat' });
       }
      })
    ),
    { dispatch: false }
  );

  closeDismissSuggestionFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Closed, ChatActions.Transferred),
      concatLatestFrom(_ => [
        this.store.select(fromUi.getShowDismissSuggestionFeedbackModal),
        this.store.select(fromAgent.getLastDismissedSuggestion)
      ]),
      filter(([action, isModalActive, lastDismissedSuggestion]) => 
        isModalActive
        && action.payload?.chatId === lastDismissedSuggestion?.chatId
      ),
      map(([action]) => {
        const closeMethod = action.type === ChatActions.Closed.type
            ? CloseSuggestionFeedbackMethod.CloseChat
            : CloseSuggestionFeedbackMethod.TransferChat;

        return UiActions.CloseDismissSuggestionFeedback({ closeMethod, chat: action.payload });
      })
    )
  );

  replaceDismissSuggestionFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.UpdateOfferQualityCheck),
      concatLatestFrom(_ => [
        this.store.select(fromUi.getShowDismissSuggestionFeedbackModal),
        this.store.select(fromChat.getDismissSuggestionFeedbackChat)        
      ]),
      filter(([{offerQualityCheck}, isModalActive]) => 
        isModalActive && offerQualityCheck
      ),
      map(([, , chat]) => {
        return UiActions.CloseDismissSuggestionFeedback({ closeMethod: CloseSuggestionFeedbackMethod.QualityCheckReplacement, chat });
      })
    )
  );
}
