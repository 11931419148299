// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class StringHelper {
  /**
   * escape string to make it safe for use in a RegEx
   * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
   * @param str
   * @returns escaped string
   */
  private static escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public static replaceAll(
    str: string,
    search: string,
    replacement: string,
    replacementIncludesSubstitutions: boolean = false
  ) {
    if (!str) {
      return str;
    }
    const escapedSearch = this.escapeRegExp(search);
    const pattern = replacementIncludesSubstitutions
      ? '(' + escapedSearch + ')'
      : escapedSearch;
    const regEx = new RegExp(pattern, 'gi');

    return str.replace(regEx, replacement);
  }

  public static countWords(str: string): number{
    if (!str) { return 0; }
    const wordPattern = /(\w+)/g;
    const matches = str.match(wordPattern);
    return matches ? matches.length : 0;
  }
}
