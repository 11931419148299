// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiRequests } from '../constants/cti.constants';
import { CtiChatRequest } from './cti-chat-request';

export class CtiEndChatRequest extends CtiChatRequest{
    messageName: string = CtiRequests.ChatDialogStop;
}
