<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-confirmation-modal 
    [headerText]="'Report Image'" 
    [active]="active" 
    [confirmText]="'Report'" 
    (confirmClicked)="onConfirmClicked()"
    (cancelClicked)="onCancelClicked()"
    (modalClose)="onModalClose()">
    <p>
        Do you want to report this image for innapropriate content? 
        This will remove the image from the conversation transcript and send it to administration for review.
    </p>
</cee-confirmation-modal>