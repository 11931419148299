<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-tab-panel
  #tabPanel
  [headerText]="'Settings'"
  [active]="active$ | async"
  [activeTabTitle]="activeTabTitle$ | async"
  [secondaryHeader]="secondaryHeader"
  [customModalClass]="'merc-settings-tab-panel'"
  [controls]="controls"
  (modalClose)="onModalClose()"
  (tabClicked)="onTabClicked($event)">
  <!-- <merc-agent-settings-profile-tab></merc-agent-settings-profile-tab> -->
  <merc-agent-settings-display-tab
    [settings]="agentCustomSettings"
    (settingsModelChange)="onSettingsModelChanged($event)">
  </merc-agent-settings-display-tab>
  <merc-agent-settings-sound-notification-tab
    [settings]="agentCustomSettings"
    (settingsModelChange)="onSettingsModelChanged($event)">
  </merc-agent-settings-sound-notification-tab>
  <merc-agent-settings-advanced-features-tab
    [settings]="agentCustomSettings"
    (settingsModelChange)="onSettingsModelChanged($event)">
  </merc-agent-settings-advanced-features-tab>
  <merc-agent-settings-feedback-tab
    *ngIf="qualityCheckFeatureFlag$ | async"
  ></merc-agent-settings-feedback-tab>
  <merc-agent-settings-feature-announcement-tab
    *ngIf="featureAnnouncementFeatureFlag$ | async">
  </merc-agent-settings-feature-announcement-tab>
  <cee-tab-panel-item [title]="logoutTab" [displayBody]="false"></cee-tab-panel-item>
  <cee-tab-panel-item *ngIf="surveyUrl && shouldShowSurvey(npsSurveyData$ | async)" [icon]="true" [iconType]="'link-out'" [link]="surveyUrl" [alignBottom]="true" [title]="surveyTab" [displayBody]="false"></cee-tab-panel-item>
</cee-tab-panel>
<merc-settings-confirm-exit-modal *ngIf="isExitCustomSettingsModalOpen$ | async" (modalClose)="onCloseExitModal($event)"></merc-settings-confirm-exit-modal>
<merc-agent-settings-close-chats-modal [active]="closeChatsModalActive" (modalClose)="onCloseChatsModalClose()"></merc-agent-settings-close-chats-modal>
<merc-agent-settings-confirm-logout-modal [active]="confirmLogoutModalActive" (modalClose)="onCancelLogout()" (modalConfirm)="onConfirmLogout()"></merc-agent-settings-confirm-logout-modal>
