// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  AfterViewInit
} from '@angular/core';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
import { AskMeAnythingSearchVm } from 'projects/entities/src/lib/domain/ask-me-anything/ask-me-anything.model';
import { fromApp, fromAskMeAnything, fromChat } from 'projects/entities/src/lib/domain/selectors';
import { AppState, AskMeAnythingActions, Chat, ChatUiActions, Color } from 'projects/entities/src/public_api';
import { DetailsSelectedTab } from 'projects/entities/src/lib/domain/models/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { debounceTime, delay, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/effects';
import { MaskingService } from '@cxt-cee-chat/merc-ng-core';

@Component({
  selector: 'merc-ask-me-anything-base',
  templateUrl: './ask-me-anything-base.component.html',
  styleUrls: ['./ask-me-anything-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AskMeAnythingBaseComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild('amaTextarea') amaTextarea: ElementRef;
  @ViewChild('scrollWindow') private searchesScrollSection!: ElementRef;
  @ViewChildren('searches') private searchesElem!: QueryList<ElementRef>;

  selectedAskMeAnythingSearches$: Observable<AskMeAnythingSearchVm[]>;
  selectedAskMeAnythingId$: Observable<string>;
  selectedChatColor$: Observable<Color>;
  selectedTab$: Observable<DetailsSelectedTab>;
  scroll$: BehaviorSubject<number>;
  selectedActiveChat$: Observable<Chat>;

  amaTextareaValue: string = '';
  isDisabled: boolean = true;

  constructor(
    private store: Store<AppState>, 
    private changeDetection: ChangeDetectorRef,
    private maskingService: MaskingService
  ) {
    super();
  }

  ngOnInit() {
    this.scroll$ = new BehaviorSubject<number>(0);
    this.selectedAskMeAnythingSearches$ = this.store.select(fromAskMeAnything.getAskMeAnythingForSelectedChat);
    this.selectedAskMeAnythingId$ = this.store.select(fromAskMeAnything.getSelectedAskMeAnythingId);
    this.selectedChatColor$ = this.store.select(fromChat.getSelectedChatColor);
    this.selectedTab$ = this.store.select(fromChat.getDetailsPanelTabSelected);
    this.selectedActiveChat$ = this.store.select(fromChat.getSelectedChat);
  }

  ngAfterViewInit() {
    // when searches array is updated, scroll to the top of the latest search
    const searchesSub = this.selectedAskMeAnythingSearches$
      .pipe(
        delay(50))
      .subscribe(async (searches) => {
        if (searches?.length > 0){
          this.scrollToSearch(searches[searches.length - 1]?.searchId);
        }
      });
    this.subscriptions.push(searchesSub);

    // when the selected tab changes, scroll to the last scroll position
    const selectedTabSub = this.selectedTab$
      .pipe(
        concatLatestFrom(() => this.store.select(fromChat.getAskMeAnythingPanelScrollPosition)),
        delay(50)
      ).subscribe(async ([selectedTab, scrollPosition]) => {
        if (selectedTab === DetailsSelectedTab.Knowledge){
          this.scrollTo(scrollPosition);
        }
      });
    this.subscriptions.push(selectedTabSub);

    // when the selected active chat changes, scroll to the last scroll position
    this.selectedActiveChat$
      .pipe(
        debounceTime(50),
        filter((chat) => Boolean(chat)),
        map((chat) => chat.chatId),
        distinctUntilChanged(),
        concatLatestFrom(() => [
          this.store.select(fromChat.getAskMeAnythingPanelScrollPosition),
          this.selectedTab$
        ]),
        takeUntil(this.destroyed$)
      )
      .subscribe(([_, scrollPosition, selectedTab]) => {
        if (selectedTab === DetailsSelectedTab.Knowledge){
          this.scrollTo(scrollPosition);
        }
      });

    this.scroll$
      .pipe(
        debounceTime(50),
        concatLatestFrom(() => [
          this.selectedAskMeAnythingId$,
          this.store.select(fromChat.getDetailsPanelTabSelected),
          this.store.select(fromApp.getPageInitializationSucceeded)
        ]),
        takeUntil(this.destroyed$))
      .subscribe( ([scrollPosition, chatId, selectedTab, pageInitSucceeded]) => {
        if (pageInitSucceeded && selectedTab === DetailsSelectedTab.Knowledge) {
          this.store.dispatch(ChatUiActions.updateAmaPanelScrollPos({ chatId, scrollPosition }));
        }
    });

  }

  onEnterKey(event: KeyboardEvent, chatId: string) {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      if (this.amaTextareaValue.trim().length > 0) {
        this.amaSend(chatId);
      }
    } 
  }

  amaSend(chatId: string) {
    this.store.dispatch(
      AskMeAnythingActions.search({
        chatId,
        searchId: Guid.raw(),
        question: this.maskingService.maskPersonalInfo(this.amaTextareaValue),
        isCustomerQuestion: false,
        knowledgeFocused: false
      })
    );
    this.amaTextareaValue = '';
    this.amaCharCount();
    this.changeDetection.detectChanges();
  }

  amaCharCount() {
    const textarea = document.getElementById(
      'amaTextarea'
    ) as HTMLTextAreaElement;
    const charCount = document.getElementById('mercCharCount');
    if (textarea && charCount) {
      const text = this.amaTextareaValue;
      charCount.textContent = text.length.toString();
      if (text.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    }
  }

  scrollToSearch(searchId: string){
    const c = this.searchesElem?.find((x: ElementRef) => x?.nativeElement?.getAttribute('id') === searchId);
    if (c?.nativeElement && this.searchesScrollSection?.nativeElement) {
      const scrollPosY = this.searchesScrollSection.nativeElement.scrollHeight - c.nativeElement.offsetHeight;
      this.searchesScrollSection.nativeElement.scrollTo(0, scrollPosY);
    }
  }

  scrollTo(scrollPosition: number){
    if (this.searchesScrollSection?.nativeElement){
      this.searchesScrollSection.nativeElement.scrollTop = scrollPosition ?? this.searchesScrollSection.nativeElement.scrollHeight;
    }
  }

  onScroll($event) {
    const scrollValue = $event.target.scrollTop ?? 0;
    this.scroll$.next(scrollValue);
  }

  public trackBySearchId(_index: number, amaSearch: AskMeAnythingSearchVm) {
    return amaSearch.searchId;
  }
}
