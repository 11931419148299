// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { IXmlToJsonParserConfigModel } from '../models/xml-to-json-parser-config.model';
import { ParserConstants } from './parser.constants';
import { ObjectUtil } from '../utils/object.util';
import { AppUtil } from '../utils/app.util';
/**
 * Converts a xml string into json object.
 * Supports for nth level of properties.
 * e.g.
 *       <CTIData>
 *         <Account>1234</Account>
 *         <UUI>1,2</UUI>
 *         <UCID>1</UCID>
 *         <CTIBeginCall>true</CTIBeginCall>
 *         <SequenceNumber>1</SequenceNumber>
 *       </CTIData>
 * Not suitable for namespaced XML.
 */
export class XmlToJsonParser {
	constructor() {}

	/**
	 *
	 * Converts a xml string into json object.
	 */
	public parse(xmlString: string, config?: IXmlToJsonParserConfigModel): any {
		let json: any = null;
		try {
			if (xmlString === null) {
				return ObjectUtil.transformJsonObjectKeysInToCamelCase(json);
			}
			const domElement: Document = this.xmlStringToXmlDocument(xmlString);
			AppUtil.log('Core ==> domElement %o', domElement);
			if (typeof domElement !== 'undefined' && domElement !== null) {
				json = this.xmlToJson(domElement);
				AppUtil.log('Core ==> json %o', json);
				if (!config) {
					return json;
				} else if (
					typeof config !== 'undefined' &&
					config !== null &&
					typeof json !== 'undefined' &&
					json !== null
				) {
					if (config.removeLineBreaks) {
						this.removeLineBreaks(json);
					}
					if (config.removeComments) {
						this.removeCommentProperties(json);
					}
					if (config.transformTextOnly) {
						this.transformTextOnly(json);
					}
				}
			}
			return typeof json !== 'undefined' && json !== null
				? ObjectUtil.transformJsonObjectKeysInToCamelCase(json)
				: json;
		} catch (error) {
			return ObjectUtil.transformJsonObjectKeysInToCamelCase(json);
		}
	}

	/**
	 * Parses an xml string into a xml dom element.
	 */
	private xmlStringToXmlDocument(xmlString: string): Document {
		const parser = new DOMParser();
		let xmlDoc: Document = null;

		try {
			xmlDoc = parser.parseFromString(
				<any>xmlString,
				<any>ParserConstants.TEXT_XML
			);
		} catch (e) {
			AppUtil.log(
				'XmlToJsonParser : xmlStringToXmlDocument() : Unable to parse xml string into xml document ===> %o',
				e
			);
			return xmlDoc;
		}
		return xmlDoc;
	}

	/**
	 * This method cleans up the JSON object which was created from the xmlToJson method.
	 * If the XML was multilined the JSON object gets on parsing a property which is
	 * called '#text' and an array with line breaks. These linebreaks will be removed in this method.
	 */
	private removeLineBreaks(json: any): any {
		Object.keys(json).forEach((key: string ) => {
			if (this.isValidProperty(key, json)) {
				if (key === ParserConstants.XML_TEXT_NODE && Array.isArray(json[key])) {
					delete json[key];
				}
				if (typeof json[key] === ParserConstants.OBJECT_TYPE) {
					this.removeLineBreaks(json[key]);
				}
			}
		});
	}

	/**
	 * if Xml contains comments, this method removes all comments of the JSON object.
	 */
	private removeCommentProperties(json: any): any {
		Object.keys(json).forEach((key: string) => {
			if (this.isValidProperty(key, json)) {
				if (key === ParserConstants.XML_COMMENT_NODE) {
					delete json[key];
				}
				if (typeof json[key] === ParserConstants.OBJECT_TYPE) {
					this.removeCommentProperties(json[key]);
				}
			}
		});
	}

	/**
	 * if the object has only one property with name '#text'.
	 * it would be transformed to property == '#text' value.
	 */
	private transformTextOnly(json: any): any {
		Object.keys(json).forEach((key: string) => {
			if (this.isValidProperty(key, json)) {
				const hasMoreProps: boolean = Object.keys(json[key]).length > 1;
				const firstProperty: string = Object.keys(json[key])[0];

				if (
					hasMoreProps ||
					typeof json[key][firstProperty] === ParserConstants.OBJECT_TYPE
				) {
					this.transformTextOnly(json[key]);
					return;
				}

				if (
					typeof json[key] === ParserConstants.OBJECT_TYPE &&
					json[key][ParserConstants.XML_TEXT_NODE]
				) {
					json[key] = json[key][ParserConstants.XML_TEXT_NODE];
				}
			}
		});
	}

	private isValidProperty(key: string, json: any) {
		return typeof key !== 'undefined' && key !== null && json[key] !== null;
	}

	/**
	 * This method parses an XML DOM element into a JSON object.
	 */
	private xmlToJson(xml: Document | any): any {
		let jsonObject = {};
		AppUtil.log('Core ==> xmlToJson %o', xml);
		if (
			typeof xml !== 'undefined' &&
			xml !== null &&
			typeof xml.nodeType !== 'undefined' &&
			xml.nodeType !== null
		) {
			AppUtil.log(
				'Core ==> xmlToJson ===> xml not null ===> note type %o',
				parseInt(xml.nodeType)
			);
			AppUtil.log('Core ==> xmlToJson ===> xml attributes %o', xml.attributes);
			if (parseInt(xml.nodeType) === 1) {
				if (
					typeof xml.attributes !== 'undefined' &&
					xml.attributes !== null &&
					xml.attributes.length > 0
				) {
					AppUtil.log(
						'Core ==> xmlToJson ===> xml attributes not null ===> xml.attributes.length %o',
						xml.attributes.length
					);
					jsonObject[ParserConstants.XML_ATTRIBUTE_NODE] = {};

					for (let i = 0; i < xml.attributes.length; i++) {
						const elementNode: any = xml.attributes[i];
						AppUtil.log(
							'Core ==> xmlToJson ===> elementNode ===> %o',
							elementNode
						);
						if (
							typeof elementNode !== 'undefined' &&
							elementNode !== null &&
							typeof elementNode.nodeName !== 'undefined' &&
							elementNode.nodeName !== null &&
							typeof elementNode.nodeValue !== 'undefined' &&
							elementNode.nodeValue !== null
						) {
							AppUtil.log(
								'Core ==> xmlToJson ===> elementNode not null ===> elementNode.nodeValue %o',
								elementNode.nodeValue
							);
							jsonObject[ParserConstants.XML_ATTRIBUTE_NODE][
								elementNode.nodeName
							] = elementNode.nodeValue;
						}
					}
				}
			} else if (parseInt(xml.nodeType) === 3) {
				if (typeof xml.nodeValue !== 'undefined' && xml.nodeValue !== null) {
					jsonObject = xml.nodeValue;
				}
			}
			AppUtil.log(
				'Core ==> xmlToJson ===> check xml child nodes %o',
				xml.hasChildNodes()
			);
			AppUtil.log(
				'Core ==> xmlToJson ===> check child nodes length %o',
				xml.childNodes.length,
				typeof xml.childNodes,
				xml.childNodes
			);
			if (
				xml.hasChildNodes() &&
				typeof xml.childNodes !== 'undefined' &&
				xml.childNodes !== null
			) {
				AppUtil.log('Core ==> xmlToJson ===> inside child nodes ');
				for (let i = 0; i < xml.childNodes.length; i++) {
					AppUtil.log(
						'Core ==> xmlToJson ===> inside child loop ===> %o',
						xml.childNodes[i]
					);
					const elementNode: any = xml.childNodes[i];
					AppUtil.log(
						'Core ==> xmlToJson ===> child element node %o',
						elementNode
					);
					if (
						typeof elementNode !== 'undefined' &&
						elementNode !== null &&
						typeof elementNode.nodeName !== 'undefined' &&
						elementNode.nodeName !== null
					) {
						AppUtil.log(
							'Core ==> xmlToJson ===> clienl element node not null ===> node name ===> %o ',
							elementNode.nodeName
						);
						if (
							typeof jsonObject[elementNode.nodeName] ===
							ParserConstants.UNDEFINED_TYPE
						) {
							const elementObject = this.xmlToJson(elementNode);
							jsonObject[elementNode.nodeName] = this.replaceEmptyObjectWithNull(elementObject);
						} else {
							if (
								typeof jsonObject[elementNode.nodeName].push ===
								ParserConstants.UNDEFINED_TYPE
							) {
								const previousNode = jsonObject[elementNode.nodeName];

								jsonObject[elementNode.nodeName] = [];
								jsonObject[elementNode.nodeName].push(previousNode);
							}
							jsonObject[elementNode.nodeName].push(
								this.xmlToJson(elementNode)
							);
						}
					}
				}
			}
		}
		return jsonObject;
	}

	private replaceEmptyObjectWithNull(elementObject: any): any {
		return AppUtil.isEmptyObject(elementObject) ? null : elementObject;
	}
}
