<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="chat">
  <cee-history-card [active]="!chat.selectedHistoryEngagement || !chat.selectedHistoryEngagement.priorEngagementId"
    [colorId]="chat.color.id" (click)="currentEngagementClicked()" [channels]="chat.channel.type | formatChannels"
    [topic]="chat.queueName">
  </cee-history-card>
  <ng-container *ngIf="chat.engagementHistory && chat.engagementHistory.length > 0">
    <div
      *ngFor="let engagement of (chat.engagementHistory | sortBy:'desc':'createDate'); ; trackBy: trackByEngagementChat">
      <cee-history-card [active]="engagement.id === chat.selectedHistoryEngagement?.priorEngagementId || engagement.id === chat.chatWindowScrollEngagementIntersection?.priorEngagementId"
        [colorId]="chat.color.id" (click)="priorEngagementClicked(engagement)" [topic]="engagement.queue.name"
        [channels]="engagement.channel | formatChannels"
        [conversationDateTime]="getFormattedTime(engagement.createDate)">
      </cee-history-card>
    </div>
  </ng-container>
</ng-container>