// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiRequest } from './cti-request';

export class CtiChatRequest extends CtiRequest{
    uui: string;   
        
    public constructor (uui: string, sessionId: string) {
        super(sessionId);
        this.uui = uui;       
   }
}