// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUi from './ui.reducer';
import { AppState } from '../state';
import { ITransferChatModalEntityState } from './ui.reducer';
import { ScriptsContextMenu } from 'src/app/models/scripts-context-menu';
import { BannerTypes, BounceTimerDetail, BounceTimerType, ConvoMessageContextMenu } from 'projects/entities/src/public_api';
import { AgentSettingsBannerText, BounceBannerText } from '../../constants/constants';
import { AgentSettingsBanner, Chat, ChatMessage, ExitAction } from '../models';
import { ItgActionType, NavigationButtons } from '../models/enums';
import { getChats, getIsSelectedChatItgInProgress } from '../chat.selectors';
import { filterValidSearchTerm, getSmartResponsesForSelectedChatMessage, getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage, splitSearchText } from '../smart-responses/smart-responses.selectors';
import { SmartResponses } from '../smart-responses/smart-responses.model';
import { RecommendedTabHelper } from '../../utils/recommended-tab-helper';
import { SmartResponsesHelper } from '../../utils/smart-responses-helper';
import { SmartResponseVm } from '../models/smart-response-vm';
import { fromSettings } from '../selectors';
import { FeatureFlags } from '../../constants/featureFlags.constants';

const selectUiFeature = createFeatureSelector<AppState, fromUi.State>(
  fromUi.featureKey
);

export const getClickedScript = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.scriptClickEventArgs
);

export const getClickedSmartResponse = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.smartResponseInUse
);

export const getClickedCxGptResponse = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.cxGptResponseInUse
);

export const getAgentSettingsBannerFeature = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.agentSettingsBanner
);

export const getAgentSettingsBannerActive = createSelector(
  getAgentSettingsBannerFeature,
  (settingsBanner: AgentSettingsBanner) => settingsBanner.active
);

export const getAgentSettingsBannerExitAction = createSelector(
  getAgentSettingsBannerFeature,
  (settingsBanner: AgentSettingsBanner) => settingsBanner.exitAction
);

export const getAgentSettingsBannerExitType = createSelector(
  getAgentSettingsBannerExitAction,
  (exitAction: ExitAction) => exitAction === ExitAction.Canceled ? BannerTypes.Info : BannerTypes.Success
);

export const getAgentSettingsBannerText = createSelector(
  getAgentSettingsBannerExitAction,
  (exitAction: ExitAction) => exitAction === ExitAction.Canceled ? AgentSettingsBannerText.cancelled : AgentSettingsBannerText.saved
);
export const getIsCloseChatModalOpen = createSelector(
  selectUiFeature,
  fromSettings.getShowConversationSummary,
  (state: fromUi.State, showConversationSummary: boolean) => state.isCloseChatModalOpen && !showConversationSummary
);

export const getIsConversationSummaryModalOpen = createSelector(
  selectUiFeature,
  fromSettings.getShowConversationSummary,
  (state: fromUi.State, showConversationSummary: boolean) => state.isCloseChatModalOpen && showConversationSummary
);

const selectTransferChatModalFeature = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.transferChatModal
);

export const transferChatModal = {
  getIsModalOpen: createSelector(
    selectTransferChatModalFeature,
    (state: ITransferChatModalEntityState) => state.isOpen
  ),
  getTransferFailed: createSelector(
    selectTransferChatModalFeature,
    (state: ITransferChatModalEntityState) => state.transferFailed
  )
};

export const getImageModalValue = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.imageModalValue
);

export const selectConvoMessageContextMenu = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.convoMessageContextMenu
);

export const isMaskingMessage = createSelector(
  selectConvoMessageContextMenu,
  getChats,
  (contextMenu: ConvoMessageContextMenu, chats: Chat[]) => {
    if (!contextMenu?.maskText || !contextMenu?.chatMessage?.messageId) { return false; }
    const chat = chats.find(c => c.chatId === contextMenu.maskText.chatId);
    if (!chat) { return false; }
    const message = chat.messages.find(m => m.messageId === contextMenu.chatMessage.messageId);
    return Boolean((<ChatMessage>message)?.isMasking);
  }
);

const getScriptsContextMenuFeature = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.scriptsContextMenu
);

export const getScriptsContextMenuClientX = createSelector(
  getScriptsContextMenuFeature,
  (scriptsContextMenu: ScriptsContextMenu) => scriptsContextMenu?.clientX || 0
);

export const getScriptsContextMenuClientY = createSelector(
  getScriptsContextMenuFeature,
  (scriptsContextMenu: ScriptsContextMenu) => scriptsContextMenu?.clientY || 0
);

export const getScriptsContextMenuScript = createSelector(
  getScriptsContextMenuFeature,
  (scriptsContextMenu: ScriptsContextMenu) => scriptsContextMenu?.script
);

export const getIsScriptsContextMenuOpen = createSelector(
  getScriptsContextMenuFeature,
  (scriptsContextMenu: ScriptsContextMenu) => Boolean(scriptsContextMenu?.open)
);

export const getAgentSettingsPanelFeature = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.agentSettingsPanel
);

export const getIsAgentSettingsPanelOpen = createSelector(
  getAgentSettingsPanelFeature,
  (agentSettingsPanel: fromUi.IAgentSettingsPanelEntityState) => agentSettingsPanel.isOpen
);

export const getBottomButtonsData = createSelector(
  getIsAgentSettingsPanelOpen,
  fromSettings.getIsSettingsNotificationVisible,
  fromSettings.getNewFeatureBannerAckTimestamp,
  fromSettings.hasFeatureFlag(FeatureFlags.FeatureBanner),
  (isOpen: boolean, isSettingsNotificationVisible: boolean, agentFeatureBannerAckTimestamp: string, isBannerFlagOn: boolean) => {
    return {
      settingsActive: isOpen,
      showSettingsNotification: isSettingsNotificationVisible,
      agentFeatureBannerAckTimestamp,
      isBannerFlagOn
    };
  }
);

export const getAgentSettingsPanelActiveTabTitle = createSelector(
  getAgentSettingsPanelFeature,
  (agentSettingsPanel: fromUi.IAgentSettingsPanelEntityState) => agentSettingsPanel.activeTabTitle
);

export const getIsExitCustomSettingsModalOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.isExitCustomSettingsModalOpen
);

export const getNotificationBannerDetails = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.notificationBannerDetails
);

export const getShowNotificationBanner = createSelector(
  getNotificationBannerDetails,
  (details: BounceTimerDetail[]) => Boolean(details && details.length > 0)
);

export const getNotificationBounceType = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.notificationBounceType
);

export const getNotificationBounceText = createSelector(
  getNotificationBounceType,
  (type: BounceTimerType) => type === BounceTimerType.Bounced ? BounceBannerText.bounced : BounceBannerText.warning
);

export const getNotificationBannerClosed = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.notificationBannerClosedChatId
);

export const getIsSessionLockoutWarningModalOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.isSessionLockoutWarningModalOpen
);

export const getAgentSummaryOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.agentSummaryOpen
);

export const getLeftNavigationActiveLinkId = createSelector(
  getAgentSummaryOpen,
  (isOpen: boolean) => isOpen ? NavigationButtons.Summary : NavigationButtons.Conversations
);

export const getHasChatNotification = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.hasChatNotification
);

export const getTopButtonsData = createSelector(
  [getHasChatNotification, getAgentSummaryOpen, fromSettings.hasFeatureFlag(FeatureFlags.HasAgentSummary)],
  (hasChatNotification, agentSummaryOpen, hasAgentSummary) => {
    return {
      hasChatNotification,
      agentSummaryOpen,
      hasAgentSummary
    };
  }
);

export const getScreenHeader = createSelector(
  getAgentSummaryOpen,
  (open: boolean) => open ? 'Agent Summary' : 'Conversations'
);

export const getItgModalOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.itgModalOpen
);

export const getCloseTransferModalOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.closeTransferModalOpen
);

export const getConfirmCloseTransferAction = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.confirmCloseTransferAction
);

export const getItgModalAction = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.itgModalAction
);

export const getItgHeaderText = createSelector(
  getItgModalAction,
  (action: ItgActionType) => {
    switch (action) {
      case ItgActionType.Cancel :
        return 'Are you sure you want to cancel this ITG?';
      case ItgActionType.Pause :
        return 'You have an ITG in progress';
      case ItgActionType.Start :
        return 'Are you sure you want to close this ITG to start a new one?';
    }
  }
);

export const getItgModalText = createSelector(
  getItgModalAction,
  (action: ItgActionType) => {
    switch (action) {
      case ItgActionType.Cancel :
        return 'This ITG is still actively responding to the customer. We recommend you check to see if the customer is ready to resume troubleshooting before cancelling the ITG.';
      case ItgActionType.Pause :
        return 'Sending a message while an activity is in-progress will pause the activity, and you will have to resume it manually. Are you sure you want to interrupt the activity?';
      case ItgActionType.Start :
        return 'This ITG is still actively responding to the customer. Starting a new ITG will cancel this ITG.';
    }
  }
);

export const getItgModalSuggestedItg = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.itgModalSuggestedItg
);

export const getToastDetails = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.updateInlineToast
);

export const getShowRateConnectionQuality = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.showRateConnectionQuality
);

export const getShowRateConnectionModals = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.showRateConnectionQuality || state.toggleShowRateConnectionFollowUpModal
);

export const getShowRateConnectionFollowUpModal = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.toggleShowRateConnectionFollowUpModal
);

export const getShowRateConnectionFollowUp = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.toggleShowRateConnectionFollowUp
);

export const getEnableRateConnectionAdditionalFeedback = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.enableRateConnectionAdditionalFeedback
);

export const getSettingsConnectionRatingSubmitted = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.settingsConnectionRatingSubmitted
);

export const getShowDismissSuggestionFeedbackModal = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.showDismissSuggestionFeedback
);

export const getIsDismissSuggestionFeedbackSubmitted = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.dismissSuggestionFeedbackSubmitted
);

export const getHistoryPanelIsOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.historyPanelIsOpen
);

export const getSmartResponseFilterText = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.smartResponseFilterText
);

export const selectSmartResponseFilterText = createSelector(
  getSmartResponseFilterText,
  (filterText: string): string => {
    return filterValidSearchTerm(filterText);
  }
);

export const selectSmartResponseSplitFilterText = createSelector(
  selectSmartResponseFilterText,
  (filterText: string): string[] => splitSearchText(filterText)
);

export const getFilteredSmartResponsesByFilteredText = createSelector(
  getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage,
  selectSmartResponseSplitFilterText,
  (smartResponses: SmartResponses, filterTexts: string[]): SmartResponseVm[] => SmartResponsesHelper.getFilteredSmartResponses(smartResponses, filterTexts)?.smartResponses
);

export const getRecommendedPanelBannerVm = createSelector(
  getIsSelectedChatItgInProgress,
  getSmartResponsesForSelectedChatMessage,
  (isItgInProgress: boolean, smartResponses: SmartResponses) => RecommendedTabHelper.getBannerVm(isItgInProgress, smartResponses)
);

export const getIsLoadingTransferOptions = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.isLoadingTransferOptions ?? false
);

export const isPreviousChatSummaryCardOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.previousChatSummaryCardOpen ?? false
);

export const isPreviousChatSummaryModalOpen = createSelector(
  selectUiFeature,
  (state: fromUi.State) => state.previousChatSummaryModalOpen ?? false
);


