// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatInteractionRequest } from './chat-interact-request';

export class UpdateAccountRequest extends ChatInteractionRequest {
  accountNumber: string;
  displayName: string;
  nuanceCustomerId: string;
  siteId: string;
}
