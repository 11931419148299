// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpErrorResponse, HttpEvent, HttpHandler } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AccessToken, AuthService, BearerTokenHttpHandler } from '@cxt-cee-chat/merc-ng-core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComcastSsoAuthService } from './comcast-auth-sso-service';

@Injectable()
export class NuanceBearerTokenHttpHandler extends BearerTokenHttpHandler
{
    constructor
    (
        @Inject(AuthService) private authService: AuthService,
        private readonly comcastSsoService: ComcastSsoAuthService, 
        next: HttpHandler
    )
    {
        super(next);
    }

    getAccessToken(): Promise<AccessToken> {
        if (environment.auth.useComcastSso){
            return this.comcastSsoService.getToken();
        }
        return this.authService.getToken();
    }

    handleResponseError(error: HttpErrorResponse): Observable<HttpEvent<any>>{
        return throwError(error);
    }
}

export const NUANCE_BEARER_TOKEN_HANDLER = new InjectionToken<HttpHandler>('nuanceBearerTokenHandler');
