// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { RatingState, RecommendedActionState } from 'projects/entities/src/lib/domain/models/voice/enums';
import { RecommendedAction } from 'projects/entities/src/lib/domain/models/voice/recommended-action';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { CallActions } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-voice-recommended-action',
  templateUrl: './recommended-action.component.html',
  styleUrls: ['./recommended-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VoiceRecommendedActionComponent implements OnInit {
  @Input() recommendedAction: RecommendedAction;

  recommendedActionState = RecommendedActionState;
  ratingState = RatingState;

  constructor(private store: Store<AppState>)
  { }

  ngOnInit() {
  }

  showAction() {
    return this.recommendedAction.actionState === RecommendedActionState.Recommendation && this.recommendedAction.ratingState !== RatingState.Negative;
  }

  controlClicked() {
    this.store.dispatch(CallActions.recommendedActionControlClicked({recommendedAction: this.recommendedAction, requestId: Guid.raw()}));
  }

  positiveRating(){
    this.store.dispatch(CallActions.updateRecommendedActionRating({recommendedAction: this.recommendedAction, rating: RatingState.Positive}));
  }

  negativeRating(){
    this.store.dispatch(CallActions.updateRecommendedActionRating({recommendedAction: this.recommendedAction, rating: RatingState.Negative}));
  }
}
