// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { Chat } from './models/chat';
import { ChatInteraction } from './models/chatInteraction';
import { ChatMessage } from './models/chatMessage';
import { ChannelType, ChatMessageType, ChatStartGreetingType, ChatState, CustomerActivityStatus, DataCollectionState, DetailsSelectedTab, ItgState, Language, SenderType, SystemMessageType } from './models/enums';
import {AppState} from './state';
import * as fromChat from './chat/chat.reducer';
import * as fromChatUi from './chat-ui/chat-ui.reducer';
import { ChatUi } from './chat-ui/chat-ui.model';
import { ChatHelper } from '../utils/chatHelper';
import { ChatAgentTypingStatus } from './models/chat-agent-typing-status';
import { Suggestion } from './models/suggestion';
import { Customer } from './models/customer';
import { RadioGroup, RadioGroupInput } from '@cxt-cee-chat/merc-pattern-lib/models/radio-group';
import { ChatAutomationSettings, DispositionCategory, SendChatMessage } from './models';
import { AgentGroup } from './models/agentGroup';
import * as fromAgent from './agent.selectors';
import { EngagementPaginationData } from './models/engagementPaginationData';
import { getChatStartGreetingNewAsync, getChatStartGreetingReturningDisconnect, getDetailsPanelDefaultTab, getPriorEngagementsPageSize, showRecommendedTab } from './settings/settings.selectors';
import { ItgSuggestion } from './models/itgSuggestion';
import { SchedulerSuggestion } from './models/schedulerSuggestion';
import { ItgMetadata } from './models/itgMetadata';
import { AbandonedChatAutomationDetails } from './models/abandoned-chat-automation-details';
import { CustomerActivityTimerDetails } from './models/customer-activity-timer-details';
import { createArrayComparerSelector } from '../ngrx';
import { fromSettings } from './selectors';
import { SecureDataRequest } from './models/secureDataRequest';
import { DataCollectionButtonText, DataCollectionSuggestionTitles, MessageEditorPlaceholderText } from '../constants/constants';
import { SecureDataCollectionHelper } from '../utils/secure-data-collection-helper';
import { AsyncEngagementTranscript, ChatTranscriptMessage } from './models/chatTranscript';
import { ChatStartGreetingHelper } from '../utils/chat-start-greeting-helper';
import { SelectedMessage } from './models/selectedMessage';
import { RecommendedTabHelper } from '../utils/recommended-tab-helper';
import { ItgHelper } from '../utils/itg-helper';
import { AbandonedChatAutomationHelper } from '../utils/abandoned-chat-automation-helper';
import { ChatSummaryData } from './models/chatSummaryData';
import { ChatMessageTranslationData } from './models/chatMessageTranslationData';
import { fromLanguageTranslation } from './selectors';
import { LanguageMessageTranslationData } from './models/message-translation-data';
import { LanguageTranslationHelper } from '../utils/language-translations-helper';
import { FeatureFlags } from '../constants/featureFlags.constants';
import { StringUtils } from 'src/app/utils/string-helper';

export const selectChatFeature = createFeatureSelector<AppState, fromChat.State>(fromChat.featureKey);

const {
    selectEntities: selectChatEntities,
    selectAll: selectAllChats
} = fromChat.adapter.getSelectors(selectChatFeature);

export const selectChatUiState = createFeatureSelector<AppState, fromChatUi.State>(fromChatUi.chatUiFeatureKey);

const {
    selectEntities: selectChatUiEntities,
    selectAll: selectAllChatUi
} = fromChatUi.adapter.getSelectors(selectChatUiState);

export const getChats = createSelector(
    selectAllChats,
    (chats: Chat[]) => chats
);

export const hasChats = createSelector(
  getChats,
  (chats: Chat[]): boolean => Boolean(chats.length)
);

export const selectChatIds = createSelector(
    getChats,
    (chats: Chat[]) => chats?.map(c => c.chatId) ?? []
);

export const selectChatUiChatIds = createSelector(
    selectAllChatUi,
    (chatUis: ChatUi[]) => chatUis?.map(c => c.chatId) ?? []
);

export const selectOrphanedChatUiChatIds = createSelector(
    selectChatIds,
    selectChatUiChatIds,
    (chatIds, chatUiIds) => chatUiIds.filter(e => !chatIds.includes(e))
);

export const getChat = (chatId: string) => createSelector(
    getChats,
    (chats: Chat[]) => chats?.find(c => c.chatId === chatId)
);

export const getDismissSuggestionFeedbackChat = createSelector(
  fromAgent.getLastDismissedSuggestionChatId,
  selectChatEntities,
  (chatId: string, chats: Dictionary<Chat>) => chats[chatId]
);

export const getDismissSuggestionFeedbackChatColorId = createSelector(
  fromAgent.getLastDismissedSuggestionChatId,
  selectChatEntities,
  (chatId: string, chats: Dictionary<Chat>) => chats[chatId]?.color?.id
);

export const getDismissSuggestionFeedbackChatCustomerName = createSelector(
  fromAgent.getLastDismissedSuggestionChatId,
  selectChatEntities,
  (chatId: string, chats: Dictionary<Chat>) => chats[chatId] ? ChatHelper.getCustomerUser(chats[chatId])?.name : null
);

export const getNewMessageForChat = (chatId: string) => createSelector(
    getChatMessages(chatId),
    (messages: ChatInteraction[]) => messages?.length ? messages[messages.length - 1] : null
);

export const getSelectedChatId = createSelector(
    selectChatUiState,
    fromChatUi.getSelectedChatId
);

export const getSelectedChat = createSelector(
    getSelectedChatId,
    selectChatEntities,
    (selectedChatId: string, chats: Dictionary<Chat>) => chats[selectedChatId]
);

export const getSelectedAgentGroup = createSelector(
  getSelectedChat,
  fromAgent.getAgentGroups,
  (selectedChat: Chat, agentGroups: AgentGroup[]) => {
    if (selectedChat && agentGroups) {
      return agentGroups.find((agentGroup: AgentGroup) => agentGroup.id === selectedChat.agentGroupId);
    }
  }
);

export const getAgentGroup = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): string => chat?.agentGroupId
);

export const getSelectedChatUi = createSelector(
    getSelectedChatId,
    selectChatUiEntities,
    (selectedChatId: string, chatUis: Dictionary<ChatUi>) => chatUis[selectedChatId]
);

const getChatUi = (chatId: string) => createSelector(
    selectChatUiEntities,
    (chatUis: Dictionary<ChatUi>) => chatUis[chatId] || {}
);

export const isCustomerTyping = (chatId: string) => createSelector(
    getChatUi(chatId),
    (chatUi: ChatUi) => {
      return chatUi.isCustomerTyping;
    }
);

export const isMessagingEnabled = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.messagingEnabled
);

export const getSelectedHistoryEngagement = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => chat?.selectedHistoryEngagement
);

export const isSelectedChat = (chatId: string) => createSelector(
    getSelectedChat,
    (chat: Chat) => chat?.chatId === chatId
);

export const getNonPendingChats = createArrayComparerSelector(
    getChats,
    (chats: Chat[]) => chats.filter((x: Chat) => x.state !== ChatState.PendingAcceptance && x.state !== ChatState.AcceptanceUnknown)
);

export const getPendingChats = createSelector(
    getChats,
    (chats: Chat[]) => chats.filter((x: Chat) => x.state === ChatState.PendingAcceptance || x.state === ChatState.AcceptanceUnknown)
);

export const hasPendingChats = createSelector(
  getPendingChats,
  (chats: Chat[]): boolean => Boolean(chats.length)
);

export const selectAgentUnresponsiveChats = createSelector(
    getChats,
    selectChatUiEntities,
    (chats: Chat[], chatUis: Dictionary<ChatUi>) =>
        chats.filter(({ chatId }) => !(chatUis[chatId]?.hasAgentResponded))
);

const selectActiveChatIds = createArrayComparerSelector(
  selectAllChatUi,
  (chatUis: ChatUi[]): string[] =>
    {
      const chatIds = [];
      chatUis.forEach((chatUi) => {
        if (ChatHelper.isChatActive(chatUi?.customerActivityStatus)) {
          chatIds.push(chatUi.chatId);
        }
      });
      return chatIds;
    }
);

export const getContinuedChats = createArrayComparerSelector(
  getNonPendingChats,
  selectActiveChatIds,
  (chats: Chat[], chatIds: string[]) =>
    chats.filter(({ chatId, isReconnectEngagement, isAsync, asyncEngagements }) => {
      return chatIds.some(id => id === chatId && ChatHelper.isContinuedConversation(isReconnectEngagement, isAsync, asyncEngagements));
    }
  )
);

export const hasAnyContinuedChats = createArrayComparerSelector(
  getNonPendingChats,
  (chats: Chat[]) => {
    const filteredChats = chats.filter(({ isReconnectEngagement, isAsync, asyncEngagements }) => {
      return ChatHelper.isContinuedConversation(isReconnectEngagement, isAsync, asyncEngagements);
    });
    return filteredChats.length > 0;
  }
);

export const getNewChats = createArrayComparerSelector(
  getNonPendingChats,
  selectActiveChatIds,
  (chats: Chat[], chatIds: string[]) =>
    chats.filter(({ chatId, isReconnectEngagement, isAsync, asyncEngagements }) => {
      return chatIds.some(id => id === chatId && !ChatHelper.isContinuedConversation(isReconnectEngagement, isAsync, asyncEngagements));
    })
);

export const getInactiveChats = createArrayComparerSelector(
  getNonPendingChats,
  selectActiveChatIds,
  (chats: Chat[], chatIds: string[]) =>
    chats.filter(({ chatId }) => {
    return !chatIds.some(id => id === chatId);
  })
);

export const getAgentRespondedStatus = (chatId: string) => createSelector(
    selectChatUiEntities,
    (chatUis: Dictionary<ChatUi>) => Boolean(chatUis[chatId]?.hasAgentResponded)
);

export const getHasMessageSent = (chatId: string) => createSelector(
  selectChatUiEntities,
  (chatUis: Dictionary<ChatUi>) => Boolean(chatUis[chatId]?.hasMessageSent)
);

export const getHasCustomerRespondedToAgentInitialMessage = (chatId: string) => createSelector(
  selectChatUiEntities,
  (chatUis: Dictionary<ChatUi>) => Boolean(chatUis[chatId]?.hasCustomerRespondedToAgentInitialMessage)
);

export const getHasAgentRespondedToCustomer = (chatId: string) => createSelector(
  selectChatUiEntities,
  (chatUis: Dictionary<ChatUi>) => Boolean(chatUis[chatId]?.hasAgentRespondedToCustomer)
);

export const getTransferOptions = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.transferOptions
);

export const getDispositions = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.dispositions
);

export const hasDispositions = createSelector(
  getDispositions,
  (dispositions: DispositionCategory[]) => Boolean(dispositions?.length)
);

export const getFormattedDispositions = createSelector(
  getDispositions,
  (dispositions: DispositionCategory[]) =>
  dispositions?.map(dg => {
    const formattedDispositions: RadioGroupInput[] = [];

    dg.dispositions.map((d) => {
      formattedDispositions.push({
        label: d.displayText,
        value: d.id
      });
    });

    const currGroup: RadioGroup = {
      label: dg.displayText,
      value: dg.id,
      options: formattedDispositions
    };

    return currGroup;
  }) ?? []
);

export const getSelectedDisposition = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.selectedDispositions
);

export const getVisibleSuggestions = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.suggestions?.filter(s => s.visible) || []
);

export const getLatestSuggestions = createSelector(
  getSelectedChat,
  (chat: Chat) =>
    chat?.suggestions.filter((s: Suggestion) => s.isLatest) || []
);

export const getIsLatestSuggestionTitleDataCollection = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => {
    const latestSuggestion = chat?.suggestions.find(s => s.isLatest) || null;

    return Boolean(latestSuggestion?.title) && (latestSuggestion.title === DataCollectionSuggestionTitles.linkAvailable
                                                || latestSuggestion.title === DataCollectionSuggestionTitles.customerApplied);
  }
);

export const getLatestItgSuggestionsByChatId = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) =>
    chat?.itgSuggestions.filter((s: ItgSuggestion) => s.isLatest) || []
);

export const getLatestSchedulerSuggestionsByChatId = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) =>
    chat?.schedulerSuggestions.filter((s: SchedulerSuggestion) => s.isLatest) || []
);

export const getAllItgSuggestionsByChatId = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) =>
    chat?.itgSuggestions || []
);

export const getChatDefaultGroupTranslationLanguage = (chatId: string) => createSelector(
	getChat(chatId),
	(chat: Chat) => chat?.defaultGroupTranslationLanguage
);

export const getSelectedChatAllItgSuggestions = createSelector(
  getSelectedChat,
  (chat: Chat) =>
    chat?.itgSuggestions || []
);

export const getSuggestedActivityByChatId = (chatId: string) => createSelector(
  getLatestItgSuggestionsByChatId(chatId),
  (itgSuggestions: ItgSuggestion[]) => {
    const suggestionLength = itgSuggestions.length;
    return suggestionLength && itgSuggestions[suggestionLength - 1].isItgStart ? itgSuggestions[suggestionLength - 1] : null;
  }
);

export const getSelectedChatCustomer = createSelector(
  getSelectedChat,
  (chat: Chat) =>
    chat?.users.find((u) => u.type === SenderType.Requester) as Customer
);

export const getSelectedChatAccountNumber = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.accountNumber
);

export const getChatColorId = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.color?.id
);

export const getSelectedChatColor = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.color
);

export const getSelectedChatAuthenticated = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.authenticated
);

export const getSelectedChatIsAccountConnected = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.isAccountConnected
);

export const getSelectedChatIsLoading = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.isLoading
);

export const getSelectedChatIsTransferrable = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.isTransferrable
);

export const getSelectedChatIsAsync = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.isAsync
);

export const getContactReason = (chat: Chat) => createSelector(
  getChat(chat?.chatId),
  (stateChat: Chat) => stateChat?.contactReason
);

export const getAsyncEngagements = (chat: Chat) => createSelector(
  getChat(chat?.chatId),
  (stateChat: Chat) => stateChat?.asyncEngagements
);

export const getSelectedChatAsyncEngagments = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.asyncEngagements
);

export const getPriorEngagements = (chat: Chat) => createSelector(
  getChat(chat?.chatId),
  (stateChat: Chat) => stateChat?.engagementHistory
);

export const getChatMessages = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => chat?.messages
);

export const getBotConversationSummary = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.botConversationSummary
);

export const getChatMessage = (chatId: string, traceId: string, messageId?: string) => createSelector(
    getChatMessages(chatId),
    (chatMessages: ChatInteraction[]) => {
      return chatMessages?.find(cm => ChatHelper.doesTraceOrMessageIdMatch(traceId, messageId, cm.traceId, cm.messageId));
    }
);

export const getChatMessageTranslationData = (chatId: string, traceId: string, messageId?: string) => createSelector(
  getChatMessage(chatId, traceId, messageId),
  (chatMessage: ChatMessage) => chatMessage?.translationData
);

export const getTranslatedMessageHasError = (chatId: string, traceId: string, messageId?: string) => createSelector(
  getChatMessageTranslationData(chatId, traceId, messageId),
  (translationData: ChatMessageTranslationData) => translationData?.hasError
);

export const getTranslatedMessageIsLoading = (chatId: string, traceId: string) => createSelector(
  getChatMessageTranslationData(chatId, traceId),
  (translationData: ChatMessageTranslationData) => translationData?.isLoading
);

export const getTranslatedMessageText = (chatId: string, traceId: string, messageId: string) => createSelector(
  getChatMessageTranslationData(chatId, traceId, messageId),
  fromLanguageTranslation.getTranslationText(chatId, traceId, messageId),
  (translationData: ChatMessageTranslationData, newStateTranslatedText: string): string => translationData?.translatedMessage ?? newStateTranslatedText
);

export const getTranslateTooltipChainText = (chatId: string, traceId: string, messageId: string, isStacked: boolean, timestamp: number) => createSelector(
  getChatMessages(chatId),
  fromLanguageTranslation.getLanguageTranslationDataByChatId(chatId),
  isMessageFromPreviousEngagement(chatId, timestamp),
  (chatMessages: ChatInteraction[], translationList: LanguageMessageTranslationData[], isFromPreviousEngagement: boolean) => {
    //To avoid abysmal search runtime, translated async message tooltips will not be stacked
    if (isStacked || !chatMessages || isFromPreviousEngagement) {
      return null;
    }

    const msgIndex = chatMessages?.findIndex(cm => ChatHelper.doesTraceOrMessageIdMatch(traceId, messageId, cm.traceId, cm.messageId));
    if (msgIndex < 0) {
      return null;
    }

    const sender = chatMessages[msgIndex].sender;
    let prevTimestamp = chatMessages[msgIndex].timestamp;
    let tooltipMessage = '';
    //loop checks to make sure that the following messages after the first is the same sender and is within the timestamp constant threshold of connecting messages
    for (let i = msgIndex; i < chatMessages.length && ChatHelper.isTimestampStackedMessage(chatMessages[i], sender, prevTimestamp); prevTimestamp = chatMessages[i].timestamp, i++) {
      const chatMessage = chatMessages[i] as ChatMessage;
      const originalMessage = chatMessage.message;
      const translatedMessage = chatMessage.translationData?.translatedMessage;
      const backupTranslatedMessage = translationList.find(t => LanguageTranslationHelper.doesTranslationIdMatch(traceId, messageId, t.traceId));
      //message should be falsy if translated/backup message is falsy
      const message = sender === SenderType.Requester && (translatedMessage || backupTranslatedMessage)
        ? originalMessage
        : translatedMessage;

      if (message) {
        tooltipMessage = tooltipMessage
          ? `${tooltipMessage} ${message}`
          : message;
      }
    }

    return tooltipMessage;
  }
);

export const getHistoricalEngagements = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => chat.engagementHistory
);

const getLastViewedTimestamp = (chatId: string) => createSelector(
    getChatUi(chatId),
    (chatUi: ChatUi) => chatUi.lastViewedTimestamp ?? 0
);

const getUnreadChatMessages = (chatId: string) => createSelector(
    getLastViewedTimestamp(chatId),
    getChatMessages(chatId),
    getSelectedChatId,
    (lastViewedTimestamp: number, msgs: ChatInteraction[], selectedChatId: string) =>
        selectedChatId === chatId
            ? 0
            : msgs?.filter(m => m.timestamp > lastViewedTimestamp && m.sender !== SenderType.Mercury && m.systemType !== SystemMessageType.Datapass)
);

export const getPinnedMessageIds = (chatId: string) => createArrayComparerSelector(
  getChatUi(chatId),
  ({ pinnedMessages }: ChatUi) => pinnedMessages || []
);

export const getPinnedMessages = (chatId: string) => createSelector(
  getPinnedMessageIds(chatId),
  getChatMessages(chatId),
  fromSettings.showPageNavigation,
  (pinnedMessages: string[], msgs: ChatInteraction[], showPageNavigation: boolean) =>
    showPageNavigation
      ? msgs?.filter(m => pinnedMessages.includes(m.messageId))
      : msgs?.filter(m => pinnedMessages.includes(m.messageId) && !ChatHelper.isPageNavigatedMessage(m))
);

export const isMessagePinned = (chatId: string, messageId: string) => createSelector(
  getChatUi(chatId),
  ({ pinnedMessages }: ChatUi) => ChatHelper.isMessagePinned(pinnedMessages, messageId)
);

export const getChatNotificationCount = (chatId: string) => createSelector(
    getUnreadChatMessages(chatId),
    getSelectedChatId,
    getIsLatestSuggestionTitleDataCollection(chatId),
    (msgs: ChatInteraction[], selectedChatId: string, isDataCollection: boolean) => {
      if (selectedChatId === chatId) {
        return 0;
      }
      if (isDataCollection) {
        return msgs?.length + 1;
      }
      return msgs?.length;
    }
);

export const getSelectedChatCustomerName = createSelector(
  getSelectedChat,
  (chat: Chat) => ChatHelper.getSelectedChatCustomerName(chat?.users, chat?.customerDetails)
);

export const getChatCustomerName = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => ChatHelper.getSelectedChatCustomerName(chat?.users, chat?.customerDetails)
);


//only to be used in effects to get information
export const getChatAbandonedChatAutomationDetails = (chatId: string) => createSelector([
    getChatAutomationSettings(chatId),
    getChatCustomerActivityStatus(chatId),
    getChatAutomationWarningIndex(chatId),
    getChatLastPinClickTimeStamp(chatId),
    getChatLastUnPinClickTimeStamp(chatId),
    getChatPinCount(chatId)
  ],
  (automationSettings, customerActivityStatus, warningIndex, lastPinClickTimestamp, lastUnPinClickTimestamp, pinCount): AbandonedChatAutomationDetails => {
    const details: AbandonedChatAutomationDetails = {
      automationSettings,
      customerActivityStatus,
      warningIndex,
      lastPinClickTimestamp,
      lastUnPinClickTimestamp,
      pinCount
    };
    return details;
  }
);

const getChatLastPinClickTimeStamp = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.lastPinClickTimestamp
);

const getChatLastUnPinClickTimeStamp = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.lastUnPinClickTimestamp
);

const getChatPinCount = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.pinCount
);

export const getChatCustomerActivityStatus = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.customerActivityStatus ?? CustomerActivityStatus.Active
);

export const getChatAutomationWarningIndex = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.warningIndex ?? 0
);

const getChatAutomationTimerDetails = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.abandonedChatAutomationTimerDetails
);

export const getChatCustomerActivityTimerDetails = (chatId: string) => createSelector(
  [getChatAutomationTimerDetails(chatId), getChatCustomerActivityStatus(chatId)],
  (abandonedChatAutomationTimerDetails, customerActivityStatus) => {
    const details: CustomerActivityTimerDetails = {
      ...abandonedChatAutomationTimerDetails,
      customerActivityStatus
    };
    return details;
  }
);

export const isChatPinned = (chatId: string) => createSelector(
  getChatCustomerActivityStatus(chatId),
  (status: CustomerActivityStatus) => status === CustomerActivityStatus.Pinned ? true : false
);

export const getChatAutomationSettings = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chat: ChatUi) => chat?.automationSettings
);

export const getIsAutomationPinningEnabled = (chatId: string) => createSelector(
  getChatAutomationSettings(chatId),
  (automationSettings: ChatAutomationSettings) => Boolean(automationSettings?.pinningEnabled)
);

export const getChatShowInactiveWarningPinTimer = (chatId: string) => createSelector(
  getChatUi(chatId),
  isAbandonedChatAutomationDisabled(chatId),
  (chat: ChatUi, isAcaDisabled: boolean) => chat?.showInactiveWarningPinTimer && !isAcaDisabled
);

export const getLastChatMessage = (chatId: string, predicate?: (msg: ChatInteraction) => boolean) => createSelector(
    getChatMessages(chatId),
    (msgs: ChatInteraction[]) => msgs?.filter(x => !predicate || predicate(x))
        .reverse()[0] as ChatMessage
);

export const getAgentTypingStatus = (chatId: string) => createSelector(
    selectChatUiEntities,
    (chatUis: Dictionary<ChatUi>): ChatAgentTypingStatus => (
        {
            isAgentTyping: Boolean(chatUis[chatId]?.isAgentTyping)
        })
);

export const getChatChannel = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => chat?.channel
);

export const getChatDeviceType = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => chat?.deviceType
);

export const getChatState = (chatId: string) => createSelector(
    getChat(chatId),
    (chat: Chat) => chat?.state
);

export const getSelectedChatState = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.state
);

export const getClickedSuggestion = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.clickedSuggestion
);

export const isClickedSuggestion = (intent: string) => createSelector(
    getSelectedChat,
    (chat: Chat) => (chat?.clickedSuggestion && chat.clickedSuggestion.intent === intent)
  );

export const getSendQuickResponses = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.sendQuickResponses
);

export const getStoredTextToSend = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.storedTextToSend
);

export const getPaginationData = createSelector(
  [getSelectedChatUi, getPriorEngagementsPageSize],
  (chatUi: ChatUi, pageSize): EngagementPaginationData => ({
      pageNumber: chatUi?.priorEngagementsLoadingData?.pageNumber ?? 1,
      pageSize: pageSize,
      totalEngagements: chatUi?.totalEngagements
  })
);

export const getSelectedChatUIIsLoadingPriorEngagements = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.isLoadingPriorEngagements
);

export const getPriorEngagementsLoadingData = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.priorEngagementsLoadingData
);

export const allPriorEngagementsLoaded = createSelector(
  [getSelectedChatUi, getPriorEngagementsPageSize],
  (chatUi: ChatUi, pageSize): boolean =>
    (chatUi?.priorEngagementsLoadingData?.pageNumber ?? 1) * pageSize >= chatUi?.totalEngagements
);

export const getIsSelectedChatItgRunning = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi): boolean => isItgRunning(chatUi)
);

export const getIsSelectedChatItgInProgress = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi): boolean => ItgHelper.isItgInProgress(chatUi)
);

export const getIsItgRunning = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): boolean => isItgRunning(chatUi)
);

export const getRunningItg = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): ItgMetadata => chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Running) || null
);

export const getIsItgPaused = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): boolean => Boolean(chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Paused))
);

export const getSelectedChatItgsInProgress = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi): ItgMetadata[] => chatUi?.itgsInProgress || []
);

export const getSelectedChatItgRunningOrPaused = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi): ItgMetadata => chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Paused || itg.state === ItgState.Running)
);

export const getSelectedChatItgsNotInProgress = createSelector(
  getSelectedChatItgsInProgress,
  getSelectedChatAllItgSuggestions,
  getSelectedChat,
  (itgsInProgress: ItgMetadata[], itgSuggestions: ItgSuggestion[], chat: Chat): ItgSuggestion[] => {
    if (!chat?.authenticated || chat?.channel.type === ChannelType.Sms){
      return [];
    }
    const itgSuggestionsWithIntents = itgSuggestions.filter(itg => itg.itgIntent && !itg.suppressed);

    const itgsNotInProgress = [];
    itgSuggestionsWithIntents.reverse().forEach(itg => {
      const isItgInProgress = itgsInProgress.some(inProgress => {
        return inProgress?.title === itg.itgIntent?.title;
      });
      const isAdded = itgsNotInProgress.find(i => itg.itgIntent?.title === i.itgIntent?.title);
      if (!isItgInProgress && !isAdded){
        itgsNotInProgress.unshift(itg);
      }
    });
    return itgsNotInProgress;
  }
);

export const getScheduleSuggestions = createSelector(
  [getSelectedChat],
  (chat: Chat): SchedulerSuggestion[] => chat?.schedulerSuggestions
);

export const getItgsInProgressForActivityBar = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): ItgMetadata[] => ItgHelper.getItgsInProgressForActivityBar(chatUi?.itgsInProgress)
);

export const getCurrentItg = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): ItgMetadata => chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Running || itg.state === ItgState.Paused)
);

export const getActiveItgLatestMessageId = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): string => chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Running)?.lastCustomerMessageDuringAutoPilot?.messageId
);

export const getItgMetadata = (chatId: string, title: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi): ItgMetadata => chatUi?.itgsInProgress?.find(itg => itg.title === title)
);

export const getIsSuggestionsExpanded = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi): boolean => Boolean(chatUi?.isSuggestionsExpanded)
);

export const getUnFocusedChat = createSelector(
  selectChatUiState,
  fromChatUi.getUnFocusedChat
);

export const getShowDispositions = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => Boolean(chatUi?.showDispositions)
);

export const getDetailsPanelScrollPosition = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.detailsPanelScrollPosition
);

export const getAskMeAnythingPanelScrollPosition = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.askMeAnythingPanelScrollPosition
);

export const getDetailsPanelTabSelected = createSelector(
  getSelectedChatUi,
  getDetailsPanelDefaultTab,
  (chatUi: ChatUi, defaultTab: DetailsSelectedTab) => chatUi?.detailsPanelTabSelected ?? defaultTab
);

export const getSecureDataCollectionUrl = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): string => chat?.secureDataCollection?.dataCollectionUrl ?? null
);

export const getSecureDataCollectionState = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): DataCollectionState => chat?.secureDataCollection?.dataCollectionState ?? DataCollectionState.Inactive
);

export const getSecureDataRequests = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): SecureDataRequest[] => chat?.secureDataRequests
);

export const getIsOneCtiConnected = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.oneCtiConnected ?? false
);

export const getSecureDataCollectionButtonEnabled = (chatId: string) => createSelector(
  getIsOneCtiConnected(chatId),
  getSecureDataCollectionState(chatId),
  (isOneCtiConnected: boolean, secureDataCollectionState: DataCollectionState) => isOneCtiConnected && SecureDataCollectionHelper.isRequestEnabledDataCollectionState(secureDataCollectionState)
);

export const getSecureDataCollectionButtonText = (chatId: string) => createSelector(
  getSecureDataCollectionState(chatId),
  (secureDataCollectionState: DataCollectionState) =>
  SecureDataCollectionHelper.isRequestEnabledDataCollectionState(secureDataCollectionState)
      ? DataCollectionButtonText.requestAvailable
      : DataCollectionButtonText.linkRequested
);

export const getConvoWindowBannerDetails = (chatId: string) => createSelector(
  getSecureDataCollectionState(chatId),
  (dataCollectionState: DataCollectionState) => SecureDataCollectionHelper.getBannerDetails(dataCollectionState)
);

export const getAsyncEngagementsByChatId = (chatId: string) => createArrayComparerSelector(
  getChat(chatId),
  (chat: Chat) => chat?.asyncEngagements
);

export const getPreviousAsyncEngagement = (chatId: string) => createSelector(
  getAsyncEngagementsByChatId(chatId),
  (asyncEngagements: AsyncEngagementTranscript[]) => ChatHelper.getPreviousAsyncEngagement(asyncEngagements)
);

export const getLastAsyncChatMessage = (chatId: string) => createSelector(
  getPreviousAsyncEngagement(chatId),
  (asyncEngagement: AsyncEngagementTranscript) => ChatHelper.getLastMessageInAsyncEngagement(asyncEngagement, ChatHelper.isCustomerOrAgentSender)
);

export const getLastAsyncChatMessageText = (chatId: string) => createSelector(
  getLastAsyncChatMessage(chatId),
  (chatMessage: ChatTranscriptMessage) => chatMessage?.message
);

export const getLastAgentAsyncChatMessageSenderId = (chatId: string) => createSelector(
  getPreviousAsyncEngagement(chatId),
  (asyncEngagement: AsyncEngagementTranscript) => ChatHelper.getLastMessageInAsyncEngagement(asyncEngagement, (sender: SenderType) => sender === SenderType.FixAgent)?.senderId
);

export const getLastAsyncChatMessageTimestamp = (chatId: string) => createSelector(
  getLastAsyncChatMessage(chatId),
  (chatMessage: ChatTranscriptMessage) => chatMessage?.timestamp
);

export const isMessageFromPreviousEngagement = (chatId: string, timestamp: number) => createSelector(
  getLastAsyncChatMessageTimestamp(chatId),
  (lastAsyncMessageTimestamp: number) => timestamp && lastAsyncMessageTimestamp && timestamp <= lastAsyncMessageTimestamp
);

export const getAsyncTranscriptName = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => {
    for (let i = 0; i < chat?.asyncEngagements?.length; i++) {
      const firstNameDataPass = chat?.asyncEngagements[i]?.interactions?.find(m => (m as ChatMessage).title === 'firstName');
      if (firstNameDataPass) {
        return (firstNameDataPass as ChatMessage).message;
      }
    }
    return undefined;
  }
);

export const getChatStartGreetingType = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => ChatStartGreetingHelper.GetChatStartGreetingType(chat)
);

export const getChatStartGreeting = (chatId: string) => createSelector(
  getChat(chatId),
  getChatStartGreetingType(chatId),
  getAsyncTranscriptName(chatId),
  getChatStartGreetingNewAsync,
  getChatStartGreetingReturningDisconnect,
  (chat: Chat, chatStartGreetingType: ChatStartGreetingType, transcriptCustName: string, newAsyncGreeting: string, returningDisconnectGreeting: string) => {
    const custNameData = transcriptCustName ? transcriptCustName : chat?.customerDetails?.firstName;
    const custName = StringUtils.toTitleCase(custNameData);
    return ChatStartGreetingHelper.GetChatStartGreeting(chatStartGreetingType, newAsyncGreeting, returningDisconnectGreeting, chat.agentAlias, custName);
  }
);

export const getSelectedMessage = (chatId: string) => createSelector(
  getChatUi(chatId),
  showRecommendedTab,
  (chatUi: ChatUi, showRecommended: boolean) => getChatUiSelectedMessage(chatUi, showRecommended)
);

export const getSelectedMessageId = (chatId: string) => createSelector(
  getSelectedMessage(chatId),
  (selectedMessage: SelectedMessage) => selectedMessage?.chatMessage?.messageId
);

export const isSelectedChatSelectedTabOnRecommended = createSelector(
  getSelectedChatUi,
  getDetailsPanelDefaultTab,
  (chatUi: ChatUi, defaultTab: string) => RecommendedTabHelper.isSelectedTabOnRecommended(chatUi?.detailsPanelTabSelected, defaultTab)
);

export const isSelectedMessageId = (chatId: string, messageId: string) => createSelector(
  getSelectedMessage(chatId),
  isSelectedChatSelectedTabOnRecommended,
  (selectedMessage: SelectedMessage, isTabOnRecommend: boolean) => Boolean(selectedMessage?.chatMessage && selectedMessage.chatMessage.messageId === messageId) && isTabOnRecommend
);

export const canSelectChatMessage = createSelector(
  showRecommendedTab,
  isSelectedChatSelectedTabOnRecommended,
  getIsSelectedChatItgInProgress,
  (showRecommended: boolean, isTabOnRecommend: boolean, isSelectedChatItgInProgress: boolean) => showRecommended && isTabOnRecommend && !isSelectedChatItgInProgress
);

export const getSelectedChatMessages = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.messages
);

export const getSelectedChatSelectedMessage = createSelector(
  getSelectedChatUi,
  showRecommendedTab,
  (chatUi: ChatUi, showRecommended) => getChatUiSelectedMessage(chatUi, showRecommended)
);

export const getSelectedChatSelectedMessageId = createSelector(
  getSelectedChatSelectedMessage,
  (selectedMessage: SelectedMessage) => selectedMessage?.chatMessage?.messageId
);

export const getSelectedChatSelectedPreviousMessage = createSelector(
  getSelectedChatSelectedMessageId,
  getSelectedChatMessages,
  getSelectedChatAsyncEngagments,
  (currentSelectedMessageId: string, chatMessages: ChatInteraction[], asyncEngagements: AsyncEngagementTranscript[]) => {
    const selectableMessages = ChatHelper.getSelectableMessages(chatMessages, asyncEngagements);
    const selectedMessageIndex = selectableMessages.findIndex(message => message.messageId === currentSelectedMessageId);
    const hasPreviousMessage = selectedMessageIndex !== -1 && selectedMessageIndex !== 0;

    return hasPreviousMessage ? selectableMessages[selectedMessageIndex - 1] : null;
  }
);

export const getSelectedChatSelectedNextMessage = createSelector(
  getSelectedChatSelectedMessageId,
  getSelectedChatMessages,
  getSelectedChatAsyncEngagments,
  (currentSelectedMessageId: string, chatMessages: ChatInteraction[], asyncEngagements: AsyncEngagementTranscript[]) => {
    const selectableMessages = ChatHelper.getSelectableMessages(chatMessages, asyncEngagements);
    const selectedMessageIndex = selectableMessages.findIndex(message => message.messageId === currentSelectedMessageId);
    const hasNextMessage = selectedMessageIndex !== -1 && selectedMessageIndex !== selectableMessages.length - 1;

    return hasNextMessage ? selectableMessages[selectedMessageIndex + 1] : null;
  }
);

export const getSelectedChatSelectedChatMessage = createSelector(
  getSelectedChatSelectedMessage,
  (selectedMessage: SelectedMessage) => selectedMessage?.chatMessage
);

export const getSelectedChatSelectedMessageCanSearchSmartResponses = createSelector(
  getSelectedChatSelectedMessage,
  (selectedMessage: SelectedMessage) => selectedMessage?.canSearchSmartResponses
);

export const getIsSelectedChatSelectedMessageHistoric = createSelector(
  getSelectedChatSelectedMessage,
  (selectedMessage: SelectedMessage) => selectedMessage?.isHistoric
);

export const getIsGreetingSuggestionPreviouslyOffered = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.isGreetingSuggestionPreviouslyOffered ?? false
);

export const getSelectedChatTextEditorContent = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.textEditorContent || ''
);

export const getHasSmartResponseNotification = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.hasSmartResponseNotification ?? false
);

export const getHasActivitiesNotification = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.hasActivitiesNotification ?? false
);

export const getSelectedMessageIdSuggestions = createSelector(
  getSelectedChat,
  getSelectedChatSelectedMessageId,
  (chat: Chat, messageId: string) => {
    return chat?.suggestions?.filter(s => s.messageId === messageId && s.visible) || [];
  }
);

export const getSmartResponseFeedback =  (chatId: string) => createSelector(
  getChat(chatId),
  (chat) => {
    return chat?.smartResponseFeedback;
  }
);

export const getEnhancedChatMessage = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.enhancedChatMessageData?.message || ''
);

export const getIsLoadingEnhancedChatMessage = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.enhancedChatMessageData?.isLoading ?? false
);

export const isAutoEnhanceMessagesEnabled = () => createSelector(
  fromSettings.hasFeatureFlag(FeatureFlags.EnhanceMessages),
  fromSettings.hasFeatureFlag(FeatureFlags.AutoEnhanceMessages),
  (enhanceFlag: boolean, autoEnhanceFlag: boolean) => enhanceFlag && autoEnhanceFlag
);

function getChatUiSelectedMessage(chatUi: ChatUi, showRecommended: boolean){
  return showRecommended ? chatUi?.selectedMessage : null;
}

function isItgRunning(chatUi: ChatUi): boolean {
  return Boolean(chatUi?.itgsInProgress?.find(itg => itg.state === ItgState.Running));
}

export const getDisplayedSmartResponses = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.displayedSmartResponses
);

export const getDisplayedFilteredSmartResponses = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.displayedFilteredSmartResponses
);

export const getItgsInProgress = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.itgsInProgress
);

export const getSelectedChatTimeZone = createSelector(
  getSelectedChat,
  (chat: Chat) => chat?.timeZone
);

export const getChatDataCollectionState = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): DataCollectionState => chat?.secureDataCollection?.dataCollectionState
);

export const isAbandonedChatAutomationDisabled = (chatId: string) => createSelector(
  getChatCustomerActivityStatus(chatId),
  getIsItgRunning(chatId),
  getIsItgPaused(chatId),
  getChatDataCollectionState(chatId),
  (customerActivityStatus: CustomerActivityStatus,
    isChatItgRunning: boolean,
    isChatItgPaused: boolean,
    dataCollectionState: DataCollectionState
  ): Boolean => AbandonedChatAutomationHelper.isDisabled(customerActivityStatus, isChatItgRunning, isChatItgPaused, dataCollectionState)
);

export const getChatMessageEditorPlaceholder = (chatId: string) => createSelector(
  getIsItgRunning(chatId),
  (itgIsRunning: boolean) => itgIsRunning ? MessageEditorPlaceholderText.itgRunning : ''
);

export const getChatMessageInputErrorMessage = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.messageInputErrorMessage
);

export const getItgRunningChatIds = createSelector(
  selectAllChatUi,
  (chatUis: ChatUi[]) => chatUis?.filter(chatUi => isItgRunning(chatUi))?.map(c => c.chatId) ?? []
);

export const getCustomerFirstName = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): string => chat?.customerDetails?.firstName
);

export const isAutoGreetingAllowedContext = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): boolean => ChatHelper.isAutoGreetingAllowedContext(chat?.uui)
);

export const getChatSummaryData = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): ChatSummaryData => chat?.summaryData
);

export const getAsyncEngagementsSummaryData = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): ChatSummaryData => chat?.asyncEngagementsSummaryData
);

export const getCustomerLanguage = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat): Language => chat?.customerLanguage
);

export const getUntranslatedChatMessages = (chatId: string) => createSelector(
  getChatMessages(chatId),
  (chatMessages: ChatMessage[]): ChatMessage[] => chatMessages.filter(m => !m.translationData?.translatedMessage && m.type === ChatMessageType.Message && ChatHelper.isCustomerOrAgentSender(m.sender))
);

export const getSummaryPayload = (chatId: string) => createSelector(
  getChatSummaryData(chatId),
  fromSettings.hasFeatureFlag(FeatureFlags.UseRecap),
  (chatSummaryData: ChatSummaryData, hasUseRecapFeatureFlag: boolean) => ChatHelper.getSummaryText(chatSummaryData, hasUseRecapFeatureFlag)
);

export const getAsyncEngagementsSummaryPayload = (chatId: string) => createSelector(
  getAsyncEngagementsSummaryData(chatId),
  fromSettings.hasFeatureFlag(FeatureFlags.UseRecap),
  (chatSummaryData: ChatSummaryData, hasUseRecapFeatureFlag: boolean) => ChatHelper.getSummaryText(chatSummaryData, hasUseRecapFeatureFlag)
);

export const getTranslationLanguage = (chatId: string) => createSelector(
  getCustomerLanguage(chatId),
  getChatDefaultGroupTranslationLanguage(chatId),
  (customerLanguage, defaultGroupTranslationLanguage) => ChatHelper.getCustomerQueueLanguage(customerLanguage, defaultGroupTranslationLanguage)
);

export const isChatTranslationConfigEnabled = (chatId: string) => createSelector(
  getChat(chatId),
  (chat) => chat?.isTranslationConfigEnabled
);

//isTranslatedChat is responsible for displaying text already translated regardless of agent setting
export const isTranslatedChat = (chatId: string) => createSelector(
  getTranslationLanguage(chatId),
  fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator),
  (translationLanguage, isTranslationFlagOn: boolean) => ChatHelper.isTranslatedChat(translationLanguage, isTranslationFlagOn)
);

export const shouldTranslateMessages = (chatId: string) => createSelector(
  getTranslationLanguage(chatId),
  fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator),
  isChatTranslationConfigEnabled(chatId),
  (translationLanguage, isTranslationFlagOn: boolean, isTranslationSettingEnabled: boolean) => ChatHelper.shouldTranslateMessages(translationLanguage, isTranslationFlagOn, isTranslationSettingEnabled)
);

export const getChatMessageInputInfoMessage = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.messageInputInfoMessage
);

export const getIsChatMessageInputLoading = (chatId: string) => createSelector(
	getChat(chatId),
	getChatUi(chatId),
	(chat: Chat, chatUi: ChatUi) => chat?.enhancedChatMessageData?.isLoading || chatUi?.isAskMeAnythingRewriteLoading
);

export const getAskMeAnythingRewriteContent = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.askMeAnythingRewriteContent
);

export const showMessageInputUndoButton = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.showMessageInputUndoButton
);

export const getUndoButtonPreviousContent = (chatId: string) => createSelector(
  getChatUi(chatId),
  (chatUi: ChatUi) => chatUi?.undoButtonPreviousContent
);

export const getScriptsInUseByChatId = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.scriptsInUse || []
);

export const getStartTimestamp = (chatId: string) => createSelector(
  getChat(chatId),
  (chat: Chat) => chat?.startTimestamp ?? 0
);

export const getIsMessageUsingCustomScript = (chatId: string, traceId: string) => createSelector(
  getChatMessage(chatId, traceId),
  (chatMessage: SendChatMessage) => chatMessage?.isUsingCustomScript ?? false
);

