// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AgentActions, AgentAuthActions, AgentAvailabilityActions, ChatActions, HubsActions, SettingsActions, toPayload } from './actions';
import { AvailabilityType } from './models/enums';
import { AvailabilityChange } from './models/availabilityChange';
import { HttpResponse } from '@angular/common/http';
import { AgentGroup } from './models/agentGroup';
import { PageInitializationConstants } from '../constants/page-initialization.constants';
import { LogHelper } from '../utils/logHelper';
import { AgentOperations, ErrorEvents } from '../constants/event-logs.constants';
import { GetSettingsResponse } from './models/responses/get-settings-response';
import { AvailabilityColors, AvailabilityOption } from 'src/app/models/availability-options';
import { AgentDefaultAvailabilityReasons, Constants, Location } from '../constants/constants';
import { SetAgentAvailabilityResponse } from './models/responses/set-agant-availability-response';
import { Injectable } from '@angular/core';
import { GetApplicationConfigurationByUser } from './models/requests/getApplicationConfigurationByUser';
import { ApplicationConfigurationResponse } from './models/responses/application-configuration-response';
import { fromAgent, fromAgentAvailability, fromApp, fromChat } from './selectors';
import { AvailabilityPersisterService } from './availability-persister.service';
import { AppState } from './state';
import { Action, select, Store } from '@ngrx/store';
import { PageInitializationHelperService } from '../services/page-initialization-helper.service';
import { FixAgentApiService } from '../services/fix-agent-api.service';
import { AgentStatePersisterService } from '../services/agent-state-persister.service';
import { UserIdentityService } from '../services/user-identity.service';
import { AppConfigApiService } from '../services/app-config-api.service';
import { DayTimeService, EnvironmentService, IdAccessToken, LoggingFactoryService, TokenStoreService } from '@cxt-cee-chat/merc-ng-core';
import { SetAgentAvailabilityRequest } from './models/requests/set-agent-availability-request';
import { VoiceHelper } from '../utils/voice-helper';
import { UnusedSlotTimes } from './models/unusedSlotTimes';
import { AgentHelper } from '../utils/agentHelper';
import { GetAgentUserAttributes } from './models/getAgentUserAttributes';
import { GetAgentUserAttributesResponse } from './models/getAgentUserAttributesResponse';
import { UserAttributes } from './models/userAttributes';

/*
  hydrating the state with refresh
  https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Availability implements OnInitEffects {
  constructor(
    private persisterService: AvailabilityPersisterService,
    private ngEntityStore: Store<AppState>,
		private actions: Actions,
    private pageInitHelper: PageInitializationHelperService,
    private fixAgentApiService: FixAgentApiService,
    private agentStatePersister: AgentStatePersisterService,
    private userIdentityService: UserIdentityService,
    private appConfigService: AppConfigApiService,
    private environmentService: EnvironmentService,
    private loggingFactory: LoggingFactoryService,
	private tokenStore: TokenStoreService,
	private timeService: DayTimeService,
  ) {
  }

  hydrate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAvailabilityActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? AgentAvailabilityActions.hydrateSuccess({ state })
          : AgentAvailabilityActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(
    () =>
      this.actions.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
        ofType(AgentAvailabilityActions.hydrateSuccess, AgentAvailabilityActions.hydrateFailure),
        switchMap(() => this.ngEntityStore.pipe(select(fromAgentAvailability.selectAgentAvailability))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(state => {
          this.persisterService.storeState(state);
        })
      ),
    { dispatch: false }
  );

  // on refresh, if the last status was error, use the last status from the agentStatePersister
  syncAvailabilityStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAuthActions.loggedIn),
      take(1), // will only be done on initial page loggedIn
      withLatestFrom(
        this.ngEntityStore.select(fromAgentAvailability.getAvailability)
      ),
      tap(([_, availabilityChange]) => {
        this.handleAvailabilityChange(availabilityChange);
      })
    ),
    { dispatch: false }
  );
  
  syncStatusOnRefuseChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.RefuseChat),     
      withLatestFrom(
        this.ngEntityStore.select(fromAgentAvailability.getAvailability)
      ),
      tap(([_, availabilityChange]) => {
        this.handleAvailabilityChange(availabilityChange);
      })
    ),
    { dispatch: false }
  );

  // TODO: the agent availability state has been added to a slice that gets reloaded on refresh
  // this includes busy statuses(settingsLoaded dispatch below is not necessary)
  // but looks like this call is also need to get agent groups so leaving it as is for now
	loadAgentSettings$ = createEffect(() =>
		this.actions.pipe(
			ofType(AgentAvailabilityActions.loadSettings),
			take(1),
			withLatestFrom(
				this.ngEntityStore.select(fromAgent.getSelectedAgentGroups),
				this.ngEntityStore.select(fromApp.getPageName),
				this.ngEntityStore.select(fromAgentAvailability.getAvailability),
				this.ngEntityStore.select(fromChat.selectChatIds),
				this.ngEntityStore.select(fromAgent.getunusedSlotTimes)
			),
			tap(([_action, selectedGroups, pageName, previousAvailability, chatIds, unusedSlotTimes]) => {
				this.pageInitHelper.updateStatePending(PageInitializationConstants.AgentSettings);
				const token = this.tokenStore.getToken(Constants.ComcastToken) as IdAccessToken;
				const model = {
					idToken: token?.idToken ?? ''
				};
				const getAgentSettingsPromise = this.fixAgentApiService.getAgentSettings(model);
				getAgentSettingsPromise.then(
					(response: HttpResponse<GetSettingsResponse>) => {
						const responseModel: GetSettingsResponse = new GetSettingsResponse(response.body);

						this.ngEntityStore.dispatch(AgentAvailabilityActions.settingsLoaded(responseModel.busyStatuses));

						responseModel.busyStatuses = responseModel.busyStatuses ?? [];
						const availOptions: AvailabilityOption[] = this.CreateAvailOptions(responseModel.busyStatuses);

						const agentStateObj = {
							maxChats: responseModel.maxChats,
							maxExtraChats: responseModel.maxExtraChats,
							enableCustomScripts: responseModel.enableCustomScript,
							availabilityOptions: availOptions,
							agentNetworkId: responseModel.agentNetworkId,
							pernrId: responseModel.pernrId,
							userGuid: responseModel.comcastGuid
						};

						this.ngEntityStore.dispatch(AgentActions.UpdateAgentStateSettings(agentStateObj));
						this.loggingFactory.addLoggingProperty('agentNetworkId', responseModel.agentNetworkId);
						this.loggingFactory.addLoggingProperty('pernrId', responseModel.pernrId);
						this.logUnusedSlotTimes(chatIds, agentStateObj.maxChats, previousAvailability.available === AvailabilityType.Available, unusedSlotTimes, previousAvailability.reason);

						if (selectedGroups.length === 0) {
							// selected groups will be 0 when not an advanced agent
							// or bypassed the advanced agent selection page due to an error getting agent groups
							// so we want to have all agent groups that come back from the settings
							selectedGroups = response.body.agentGroups;
							this.ngEntityStore.dispatch(AgentActions.UpdateSelectedAgentGroups(responseModel.agentGroups));
						}

						this.ngEntityStore.dispatch(AgentActions.UpdateAgentGroups(responseModel.agentGroups));

						this.callGetApplicationConfigurations(selectedGroups, VoiceHelper.isVoicePage(pageName));
					});
					this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.AgentSettings, getAgentSettingsPromise);
				})
		),
		{ dispatch: false }
	);

	getUserAttributes$ = createEffect(() =>
		this.actions.pipe(
			ofType(AgentActions.UpdateAgentStateSettings),			
			tap((payload) => {
				const model: GetAgentUserAttributes = {
					userGuid: payload.userGuid
				};

				this.fixAgentApiService.getAgentUserAttributes(model).then(
					(response: HttpResponse<GetAgentUserAttributesResponse>) => {
						const agentAttributes: UserAttributes = {
							locationId : response?.body?.attributes?.region ?? Location.National,
							role: response?.body?.attributes?.role,
							userId: payload?.agentNetworkId
						};
						this.ngEntityStore.dispatch(AgentActions.UpdateAgentUserAttributes({agentAttributes}));
					});
				})
		),
		{ dispatch: false }
	);

	changeAvailability$ = createEffect(() =>
		this.actions.pipe(
			ofType(AgentAvailabilityActions.updateAvailability),
			map(action => toPayload<AvailabilityChange>(action)),
			withLatestFrom(
				this.ngEntityStore.select(fromAgentAvailability.getAvailability),
				this.ngEntityStore.select(fromChat.selectChatIds),
        		this.ngEntityStore.select(fromAgent.getMaxChats),
				this.ngEntityStore.select(fromAgent.getunusedSlotTimes)
			),
			tap(([availArgs, previousAvailability, chatIds, maxConcurrency, unusedSlotTimes]) => {
				const availabilityModel: SetAgentAvailabilityRequest = {
					available: availArgs.available === AvailabilityType.Available,
					reason: availArgs.reason,
					currentReason: previousAvailability.reason
				};
				this.fixAgentApiService.setAvailability(availabilityModel)
					.then((availabilityResponse: HttpResponse<any>) => {
						const responseModel: SetAgentAvailabilityResponse = new SetAgentAvailabilityResponse(availabilityResponse.body);

						if (responseModel.success) {
							const model = this._dispatchAvailabilityChanged(this.getAvailabilityType(responseModel.available), responseModel.reason, Date.now());
							this.agentStatePersister.storeAvailability(model);

							if (model.reason === previousAvailability.reason || previousAvailability.available === AvailabilityType.Error) {
								LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.StatusSync, { status: model.reason });
							}
							else {
								LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChangeStatus, { status: model.reason, previousStatus: previousAvailability.reason, engagementIds: chatIds, maxConcurrency });
							}
							this.logUnusedSlotTimes(chatIds, maxConcurrency, responseModel.available, unusedSlotTimes, previousAvailability.reason);
						}
					})
					.catch((error) => {
						this._dispatchAvailabilityChanged(AvailabilityType.Error, AgentDefaultAvailabilityReasons.Error, Date.now());
						LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.ChangeStatusError, {error});
					});
			})
		),
		{ dispatch: false }
	);

	private handleAvailabilityChange(availabilityChange: AvailabilityChange) {
		let updateAvailability = availabilityChange;
		if (availabilityChange.available === AvailabilityType.Error) {
			updateAvailability = this.agentStatePersister.availabilityStatus;
		}
		this.ngEntityStore.dispatch(AgentAvailabilityActions.updateAvailability(updateAvailability));
	}

	public CreateAvailOptions(busyStatuses: string[]): AvailabilityOption[] {
		const availOptions: AvailabilityOption[] = [];

		function createAvailOption(display: string, avail: boolean, hidden: boolean = false): AvailabilityOption {
			const opt = new AvailabilityOption();
			opt.id = display.toLowerCase();
			opt.label = display;
			opt.color = avail ? AvailabilityColors.green : AvailabilityColors.red;
			opt.available = avail;
			opt.hidden = hidden;
			return opt;
		}

		availOptions.push(createAvailOption(AgentDefaultAvailabilityReasons.Available, true));
		availOptions.push(createAvailOption(AgentDefaultAvailabilityReasons.Busy, false, true));
		availOptions.push(createAvailOption(AgentDefaultAvailabilityReasons.IncomingChat, true, true));

		busyStatuses.forEach((s: string) => {
			availOptions.push(createAvailOption(s, false));
		});

		return availOptions;
	}

	private _dispatchAvailabilityChanged(avail: AvailabilityType, reason: string, timestamp: number): AvailabilityChange {
		const data: AvailabilityChange = {
			available: avail,
			reason,
			timestamp
		};
		this.ngEntityStore.dispatch(AgentAvailabilityActions.availabilityUpdated(data));
		return data;
	}

	private getAvailabilityType(available: boolean): AvailabilityType {
		return available ? AvailabilityType.Available : AvailabilityType.Unavailable;
	}

	private callGetApplicationConfigurations(agentGroups: AgentGroup[], isVoicePage: boolean) {
		const groupIds = agentGroups.map(m => m.id);
		const model: GetApplicationConfigurationByUser = {
			userId: this.userIdentityService.username,
			groupIds: groupIds
		};
		this.pageInitHelper.updateStatePending(PageInitializationConstants.ApplicationConfiguration);
		const getApplicationConfigurationPromise = this.appConfigService.getApplicationConfigurationByUser(model);
		getApplicationConfigurationPromise.then(
			(response: HttpResponse<ApplicationConfigurationResponse>) => {
				if (response?.body) {
					this.ngEntityStore.dispatch(SettingsActions.applicationConfigurationLoaded(response.body));

          this.ngEntityStore.dispatch(HubsActions.initializeHubs());
          this.ngEntityStore.dispatch(HubsActions.connectChatRequestHub());

          if (this.environmentService.logging.niagaraLog.enabled) {
            this.ngEntityStore.dispatch(HubsActions.connectNiagaraLogHub());
          }

          if (isVoicePage){
            this.ngEntityStore.dispatch(HubsActions.connectVoiceHub());
          }
				}
			}
		);
		this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.ApplicationConfiguration, getApplicationConfigurationPromise);
	}
	
	private logUnusedSlotTimes(chatIds: string[], maxChats: number, isAvailable: boolean, agentUnusedSlotTimes: UnusedSlotTimes, previousStatus: string){
		if (isAvailable && agentUnusedSlotTimes?.unAvailableStartTime){			
			const slotsUnusedElapsedSeconds = AgentHelper.getSlotsUnusedElapsedSeconds(this.timeService.unix(), agentUnusedSlotTimes).filter(f => f > 0);
			const logDimensionsTotal = {
				totalUnusedElapsedSeconds: slotsUnusedElapsedSeconds.reduce((sum, current) => sum + current, 0),
				slotsUnusedElapsedSeconds,
				maxSlots: maxChats,
				previousStatus     
				};
			LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.UnusedSlotTimes, logDimensionsTotal);
			this.ngEntityStore.dispatch(AgentActions.UpdateUnusedSlotTimes({ unusedSlotTimes: null }));
		}
		const availableSlots = maxChats - chatIds?.length;
		if (!isAvailable && !agentUnusedSlotTimes?.unAvailableStartTime && availableSlots > 0){
			const unusedSlotTimes: UnusedSlotTimes = {
				currentSlotUnavailableStartTime: this.timeService.unix(),
				unAvailableStartTime: this.timeService.unix(),
				slotsUnusedElapsedSeconds: Array(maxChats).fill(0),
				availableSlots
			};
			this.ngEntityStore.dispatch(AgentActions.UpdateUnusedSlotTimes({unusedSlotTimes}));
		}
	}

  ngrxOnInitEffects(): Action {
    return AgentAvailabilityActions.hydrate();
  }
}
