<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  managedScript: (managedScript$ | async) || {},
  script: (scriptToEdit$ | async),
  scriptGroup: (scriptGroup$ | async),
  managedScriptGroup: (managedScriptGroup$ | async) || {},
  hideAnimations: (hideAnimations$ | async)
} as data">
<cee-modal [headerText]="getModalHeader(data.managedScript, data.managedScriptGroup)"
  [active]="data.managedScript?.status || data.managedScriptGroup?.status"
  [clickOutsideToClose]="false"
  [customModalClass]="data.hideAnimations ? 'merc-hide-animation merc-manage-script-modal' : 'merc-manage-script-modal'"
  customBackdropClass="merc-manage-script-backdrop"
  (modalClose)="onModalClose()">

  <span class="merc-add-script" *ngIf="hideEditor(data.managedScript) && hideEditor(data.managedScriptGroup)">
    <cee-button (buttonClick)="onAddHeader()" dataQa="new-custom-header">
      <span style="padding: 0 4px">
        <svg class="cee-icon cee-icon--secondary"><use xlink:href="/assets/img/svg-sprite.svg#icon-plus"></use></svg>
      </span> New custom header
    </cee-button>
    <cee-button (buttonClick)="onAddScript()" dataQa="new-custom-response">
      <span style="padding: 0 4px">
        <svg class="cee-icon cee-icon--secondary"><use xlink:href="/assets/img/svg-sprite.svg#icon-plus"></use></svg>
      </span> New custom response
    </cee-button>
  </span>

  <cee-script-search *ngIf="hideEditor(data.managedScript) && hideEditor(data.managedScriptGroup)"
    #scriptSearch
    hideScriptOptions='true'
    [scriptSearchId]="scriptSearchId"
    (scriptSearchChange)="onSearchChange($event)">
  </cee-script-search>

  <div [ngClass]="{'merc-manage-scripts-content-container' : true, 'merc-manage-scripts-content-container-absolute' : (hideEditor(data.managedScript) && hideEditor(data.managedScriptGroup))}">
    <div>
      <cee-banner *ngIf="(bannerText$ | async) as bannerText" [type]="'success'" [active]="!!bannerText" dataQa="manage-scripts-banner-text">
        {{bannerText}}
      </cee-banner>
      <div *ngIf="(isSearching$ | async)">
        <merc-convo-script-search-results-banner
          [searchSystemScriptsCount]="(scriptFilterCount$ | async)"
          (clearSearchClicked)="clearSearch()">
        </merc-convo-script-search-results-banner>
      </div>
      <div *ngIf="(hideEditor(data.managedScript) && hideEditor(data.managedScriptGroup)) && (scripts$ | async) as scripts">
        <merc-script-group-manage
          [scriptGroupId]="data.scriptGroup?.id"
          (manageEditClick)="notifyPanelClick(true)">
        </merc-script-group-manage>
      </div>
    </div>

    <div [ngClass]="{'hide': hideEditor(data.managedScript)}">
      <div class= "merc-manage-scripts--editor" data-qa="script-editor">
        <h4 data-qa="upsert-script-title">{{ data.script ? 'Edit' : 'New'}} response</h4>
        <div class= "merc-manage-scripts--editor-body">
          <div class="cee-form-row cee-form-row--script-group">
            <label class="cee-form-label" for="text-optional">Header Name <span class="cee-form-label-optional">(Optional)</span></label>
            <cee-typeahead-search
              *ngIf="!hideEditor(data.managedScript)"
              #typeaheadSearch
              [initialValueId]="data.script?.scriptGroupId"
              [options]="customScriptGroups$ | async"
              placeholder="Select or customize a header"
              minSearchableLength="3"
              (inputChange)="onInputChangeScriptGroup(data.script, data.managedScript, $event)"
            >
            </cee-typeahead-search>
          </div>
          <div class="cee-form-row">
            <label class="cee-form-label">Response body</label>
            <cee-text-editor
              [editorText]="data.script?.value || editorText"
              [placeholder]="textPlaceholder"
              (messageEditorChange)="onEditorChange(data.script, data.managedScript, newScriptGroup, $event)"
              [isTargetWindowHyperlinkEnabled]="(linkEditorTargetFeatureFlag$ | async)">
            </cee-text-editor>
            <p *ngIf="errorMessage" class="cee-form-error-msg" id="text-error-msg">{{errorMessage}}</p>
          </div>
        </div>
        <div class="cee-modal-footer">
          <div class="cee-modal-footer-buttons">
            <cee-button type="secondary" (buttonClick)="backToManagePopup()" dataQa="cancel-custom-response">Cancel</cee-button>
            <cee-button (buttonClick)="onSave(data.managedScript, data.script, newScriptGroup)" [disabled]="isSaveDisabled" dataQa="save-custom-response">Save</cee-button>
          </div>
        </div>
      </div>
    </div>

    <div [ngClass]="{'hide': hideEditor(data.managedScriptGroup)}">
      <merc-script-header-editor (cancelEdit)="backToManagePopup()"></merc-script-header-editor>
    </div>
  </div>
</cee-modal>
</ng-container>
<merc-convo-script-confirm-delete-modal></merc-convo-script-confirm-delete-modal>
<merc-convo-script-group-confirm-delete-modal></merc-convo-script-group-confirm-delete-modal>

