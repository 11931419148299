<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="cee-btn cee-btn--script script-highlight-style cee-btn--script-suggestion"
  [tabindex]="disabled ? -1 : 0"
  role="button"
  (click)="suggestionClicked($event, suggestion)"
  (keydown.enter)="suggestionClicked($event, suggestion)"
  (keydown.space)="suggestionClicked($event, suggestion)"
  [attr.aria-disabled]="disabled">
  <button
    (click)="hideSuggestion(suggestion)"
    [disabled]="disabled"
    class="cee-btn merc-xa-hide-suggestion merc-hide-suggestion">
    <span class="cee-accessibly-hidden">Hide</span>
    <svg class="cee-icon merc-hide-suggestion" >
        <use class="merc-hide-suggestion" xlink:href="/assets/img/svg-sprite.svg#icon-close"></use>
    </svg>
  </button>
  <svg *ngIf="suggestion.suggestionType === SuggestionType.Xa" class="cee-icon merc-xa-suggestion-icon" >
    <use class="merc-hide-suggestion" xlink:href="/assets/img/svg-sprite.svg#icon-xa"></use>
  </svg><p><strong>{{getTitle(suggestion.title, suggestion.suggestionType)}}</strong></p>
  <p class="merc-xa-suggestion-container" [class.show]="show" [innerHtml]="suggestion.body.message">
    <cee-quick-response *ngIf="suggestion.body.buttons" [responses]="suggestion.body.buttons">
    </cee-quick-response>
  </p>
  <button class="cee-btn merc-xa-show-more" (click)="show = !show" [disabled]="disabled">{{ show ? 'Hide': 'Show More' }}</button>
</div>
