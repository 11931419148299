// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CallState, FeedContentType } from 'projects/entities/src/lib/domain/models/voice/enums';
import { FeedContent } from 'projects/entities/src/lib/domain/models/voice/feed-content';
import { fromApp, fromCall, fromOneCti, fromVoiceUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { asyncScheduler, BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { throttleTime, map, filter, distinctUntilChanged, skip, delay } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { concatLatestFrom } from '@ngrx/effects';
import { VoiceHelper } from 'projects/entities/src/lib/utils/voice-helper';
import { SenderType } from 'projects/entities/src/lib/domain/models/enums';
import { CallActions, VoiceUiActions } from 'projects/entities/src/lib/domain/actions';

@Component({
  selector: 'merc-highlight-feed',
  templateUrl: './highlight-feed.component.html',
  styleUrls: ['./highlight-feed.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightFeedComponent extends SubscriberComponent implements OnInit, OnDestroy, AfterViewInit {
  scroll$: BehaviorSubject<number>;
  showJumpToLatestButton$: Observable<boolean>;
  isAtBottomThrottled$: Observable<boolean>;
  isAtBottom$: Observable<boolean>;
  feedContent$: Observable<FeedContent[]>;
  oneCtiConnected$: Observable<boolean>;
  hasRecommendationError$: Observable<boolean>;
  callState$: Observable<CallState>;
  customerName$: Observable<String>;

  feedContentType = FeedContentType;
  callState = CallState;

  overrideBottomScroll: boolean = true;
  oldScrollPosition: number = 0;

  @ViewChild('scrollWindow') private scrollWindow!: ElementRef;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.feedContent$ = this.store.select(fromCall.getFeedContent);
    this.oneCtiConnected$ = this.store.select(fromOneCti.isOneCtiConnected);
    this.hasRecommendationError$ = this.store.select(fromVoiceUi.getHasRecommendationsError);
    this.callState$ = this.store.select(fromCall.getCallState);
    this.customerName$ = this.store.select(fromCall.getCustomerName);

    this.scroll$ = new BehaviorSubject<number>(0);
    const throttledScroll = this.scroll$.pipe(throttleTime(50, asyncScheduler, {leading: true, trailing: true}));
    this.isAtBottomThrottled$ = throttledScroll.pipe(map(scrollPos => {
      return this.isAtBottom(scrollPos);
    }));

    this.isAtBottom$ = combineLatest([this.feedContent$, this.scroll$])
      .pipe(
        throttleTime(50, asyncScheduler, { leading: false, trailing: true }),
        map(([, scrollPos]) => {
          const isAtBottom = this.isAtBottom(scrollPos);
          if (this.overrideBottomScroll && scrollPos < this.oldScrollPosition) {
            this.overrideBottomScroll = false;
          }
          else if (!this.overrideBottomScroll && isAtBottom) {
            this.overrideBottomScroll = true;
          }
          this.oldScrollPosition = scrollPos;
          return isAtBottom;
        })
      );

    this.showJumpToLatestButton$ = combineLatest([this.isAtBottomThrottled$, this.isAtBottom$])
    .pipe(
      throttleTime(2000, asyncScheduler, {leading: false, trailing: true}),
      map(([isAtBottomThrottled, isAtBottom]) => {
        return !isAtBottomThrottled && !isAtBottom && !this.overrideBottomScroll;
      })
    );

    const pageInitSub = this.store.select(fromApp.getPageInitializationSucceeded)
      .pipe(
        skip(1),
        filter(isLoaded => isLoaded),
        distinctUntilChanged(),
        delay(200)
      )
      .subscribe(() => {
          this.jumpToLatest();
      });
      this.subscriptions.push(pageInitSub);
  }

  public ngAfterViewInit(): void {
    const msgChangeSub = this.feedContent$
      .pipe(
        concatLatestFrom(_ => this.isAtBottomThrottled$),
        filter((messages) => Boolean(messages?.length)),
        throttleTime(50, asyncScheduler, {leading: false, trailing: true}),
        distinctUntilChanged())
      .subscribe(([messages, isAtBottom]) => {
        if (messages?.length && (isAtBottom || this.overrideBottomScroll)) {
          this.jumpToLatest();
        }
      });
    this.subscriptions.push(msgChangeSub);
  }

  onScroll($event) {
    const scrollValue = $event.target.scrollTop ?? 0;
    this.scroll$.next(scrollValue);
  }

  isAtBottom(scrollPosition: number): boolean{
    if (this.scrollWindow?.nativeElement?.scrollHeight && this.scrollWindow?.nativeElement?.offsetHeight){
      return this.scrollWindow?.nativeElement?.scrollHeight - this.scrollWindow?.nativeElement?.offsetHeight - scrollPosition <= 1;
    }
    return true;
  }

  jumpToLatestClicked() {
    this.store.dispatch(VoiceUiActions.JumpToLatestClicked({source: 'callFeed'}));
    this.jumpToLatest();
  }

  jumpToLatest() {
    //Covers case where there's not enough content to have scrolling
    if (this.scrollWindow?.nativeElement?.scrollHeight) {
      this.scrollWindow.nativeElement.scrollTop = this.scrollWindow.nativeElement.scrollHeight;
    }
  }

  isCustomer(sender: SenderType) {
    return VoiceHelper.isCustomer(sender);
  }

  unpinMessage(utteranceSequence: number, sender: SenderType) {
    this.store.dispatch(CallActions.unhighlightTranscriptMessageFromHighlightFeed({ utteranceSequence, sender }));
  }

  isActiveCall(callState: CallState){
    return !VoiceHelper.isCallInactive(callState);
  }

  trackById(_index: number, feedContent: FeedContent){
    return feedContent.id;
  }
}

