<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="cee-agent-state" 
  [ngClass]="cssClass | async" 
  [class.disabled]="hasPendingChats$ | async"
  [class.has-chats]="hasChats">
        <cee-select
        *ngIf="hasChats; else selectOptions"
        [name]="name"
        [options]="options"
        [label]="accessibilityLabel"
        [hideLabel]="true"
        [shouldIgnoreDefaultReset]="true"
        [selectedOption]="(hasPendingChats$ | async) ? incomingChatOptionId : selected"
        [disabled]="hasPendingChats$ | async"
        (handleChange)="selectChanged($event)"
        [ceeTooltip]="{ id:'availability-selector', text: 'Conversation still in progress', width: 300, direction: 'bottom', minWidth: 200 }"
        ></cee-select>
</div>

<ng-template #selectOptions>
  <cee-select
  [name]="name"
  [options]="options"
  [label]="accessibilityLabel"
  [hideLabel]="true"
  [shouldIgnoreDefaultReset]="true"
  [selectedOption]="(hasPendingChats$ | async) ? incomingChatOptionId : selected"
  [disabled]="hasPendingChats$ | async"
  (handleChange)="selectChanged($event)"
  ></cee-select>
</ng-template>
