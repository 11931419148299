// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class MiddlewareResponse {
    public success: boolean;
    public traceId: string;
    public agentId?: string;

    constructor(data?: any) {
        this.Hydrate(data);
    }

    public Hydrate(data: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
