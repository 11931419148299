// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  LoggingFactoryService,
  HubResponse,
  ResponseHandler
} from '@cxt-cee-chat/merc-ng-core';
import { UnauthorizedVerifySession } from '../domain/models/unauthorizedVerifySession';
import { NuanceCredentialRequestService } from './nuance-credential/nuance-credential-request-service';
import { NuanceCredentialHubService } from './nuance-credential/nuance-credential-hub-service';
import { AgentAuthActions } from '../domain/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../domain/state';

@Injectable({
  providedIn: 'root'
})
export class VoiceHubService extends NuanceCredentialHubService {
  private _unauthorizedVerifySessionHandler: ResponseHandler;
  constructor(
    protected nuanceCredentialRequestService: NuanceCredentialRequestService,
    loggingService: LoggingFactoryService,
    protected ngEntityStore: Store<AppState>) {
    super(nuanceCredentialRequestService, loggingService);
    this._unauthorizedVerifySessionHandler = new ResponseHandler();
    this._unauthorizedVerifySessionHandler.handle401 = (opName, data) => {
      const operationData: UnauthorizedVerifySession = {
        operationName: opName,
        data: data
      };
      this.ngEntityStore.dispatch(AgentAuthActions.unauthorizedVerifySession(operationData));
    };
  }

  public createSession({ phoneExtension }: { phoneExtension: string }): Promise<HubResponse> {
    const request = {
      phoneExtension
    };
    return this.callServerWithCredentials('CreateSession', request, this._unauthorizedVerifySessionHandler);
  }

  public deleteSession({ phoneExtension, sessionId }: { phoneExtension: string, sessionId: string }): Promise<HubResponse> {
    const request = {
      phoneExtension,
      sessionId
    };
    return this.callServerWithCredentials('DeleteSession', request, this._unauthorizedVerifySessionHandler);
  }

  public getSessionStatus({ phoneExtension, sessionId }: { phoneExtension: string, sessionId: string }): Promise<HubResponse> {
    const request = {
      phoneExtension,
      sessionId
    };
    return this.callServerWithCredentials('SessionStatus', request, this._unauthorizedVerifySessionHandler);
  }

  public getCallSummary({ phoneExtension, sessionId, callId }: { phoneExtension: string, sessionId: string, callId: string }): Promise<HubResponse> {
    const request = {
      phoneExtension,
      sessionId,
      callId
    };
    return this.callServerWithCredentials('CallSummary', request, this._unauthorizedVerifySessionHandler);
  }
}
