// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

/**
 * removeLineBreaks  => Removes all properties which has the name '#text' and type array.
 * removeComments    => Removes all properties with name '#comment'.
 * transformTextOnly => Transforms a property with #text == string only into property == string.
 */
export interface IXmlToJsonParserConfigModel {
	removeLineBreaks: boolean;
	removeComments: boolean;
	transformTextOnly: boolean;
}

export const initialXmlToJsonParserConfig: IXmlToJsonParserConfigModel = {
	removeLineBreaks: true,
	removeComments: true,
	transformTextOnly: true
};
