// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatMessage } from './chatMessage';
import { ChatMessageStatus } from './enums';

export class AgentChatMessage extends ChatMessage{
  status: ChatMessageStatus;
  retryAttempts?: number;
}
