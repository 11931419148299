// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ChatActions, ChatImageMessage } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-convo-report-image-modal',
  templateUrl: './convoReportImageModal.component.html',
  styleUrls: ['./convoReportImageModal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConvoReportImageModalComponent {
  @Input() active: boolean;
  @Input() imageMessage: ChatImageMessage;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private ngEntityStore: Store<AppState>){

  }
  
  onModalClose(){
    this.modalClose.emit(true);
    this.active = false;
  }

  onCancelClicked(){
    this.modalClose.emit(true);
    this.active = false;
  }

  onConfirmClicked(){
    this.ngEntityStore.dispatch(ChatActions.ReportImage(this.imageMessage));
    this.modalClose.emit(true);
    this.active = false;
  }
}