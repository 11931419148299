// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CeeAccordionComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import { AppState, Chat, ChatHelper, ChatInteraction, ChatMessageType, ChatOperations, ChatUiActions, LogActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-convo-pinned-messages',
  templateUrl: './convo-pinned-messages.component.html',
  styleUrls: ['./convo-pinned-messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoPinnedMessagesComponent implements OnInit {
  @Input() chatId: string;
  pinnedMessages$: Observable<ChatInteraction[]>;
  chat$: Observable<Chat>;

  @ViewChild('pinAccordion', { read: CeeAccordionComponent}) pinAccordion!: CeeAccordionComponent;
  @Output() jumpToMessage: EventEmitter<string> = new EventEmitter<string>();

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.pinnedMessages$ = this.store.select(fromChat.getPinnedMessages(this.chatId));
    this.chat$ = this.store.select(fromChat.getChat(this.chatId));
  }

  removePin(messageId: string) {
    this.store.dispatch(ChatUiActions.unpinMessage({messageId, chatId: this.chatId, source: 'pinned-data'}));
  }

  onJumpToMessage(messageId: string, chat: Chat) {
    const logPayload = {
      logType: LogType.chat,
      operationName: ChatOperations.JumpToMessage,
      data: null,
      chat: chat
    };

    this.store.dispatch(LogActions.logChatAction({logPayload}));
    this.jumpToMessage.emit(messageId);
    this.pinAccordion.toggleAccordion(false);
  }

  onAccordionChange(_event, totalPinned: number, chat: Chat) {
    const isActive = this.pinAccordion.active;

    if (isActive) {
      const logPayload = {
        logType: LogType.chat,
        operationName: ChatOperations.ExpandMessagePins,
        data: {
          count: totalPinned
        },
        chat: chat
      };

      this.store.dispatch(LogActions.logChatAction({logPayload}));
    }
  }

  clearPins() {
    this.store.dispatch(ChatUiActions.clearPinnedMessages({chatId: this.chatId}));
  }

  isSystemMessage(chatMessage: ChatInteraction): boolean {
    return ChatHelper.isSystemMessage(chatMessage);
  }

  getAccordionTitle(pinnedMessages: ChatInteraction[]): string {
    const countText = `(${pinnedMessages.length})`;

    return `Pinned Messages ${countText}`;
  }

  isXaResponse(type: ChatMessageType): boolean {
    return type === ChatMessageType.XaSuggestion;
  }

}
