<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="!(chats$ | async).length; else chats">
  <div class="background-no-convos">
    <svg class="no-convos-light">
      <use [attr.href]="'/assets/img/mercury-sprite.svg#no-convos-light'"></use>
    </svg>
    <svg class="no-convos-dark">
      <use [attr.href]="'/assets/img/mercury-sprite.svg#no-convos-dark'"></use>
    </svg>
    <div class="cee-h2" data-qa="no-conversations">You have no conversations at the moment.</div>
  </div>
</ng-container>
<ng-template #chats>
  <ng-container *ngFor="let chat of (chats$ | async); trackBy:trackByChatId">
    <merc-chat [hidden]="(selectedChatId$ | async) !== chat.chatId" [chat]="chat"></merc-chat>  
  </ng-container>
</ng-template>