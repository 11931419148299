// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class SetSentScript {
    chatId: string;
    scriptGroupName: string;
    scriptId: string;
    scriptValue: string;
    message: string;
}