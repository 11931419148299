// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnDestroy } from '@angular/core';
import { IDisposable } from '@cxt-cee-chat/merc-ng-core';
import { Subject, Subscription } from 'rxjs';

export abstract class SubscriberService implements IDisposable {
  protected subscriptions: Subscription[] = [];

  public dispose(): void {
    if (this.subscriptions?.length) {
      this.subscriptions.forEach(s => {
        if (!s.closed) { s.unsubscribe(); }
      });
    }
  }
}

@Component({
  template: ''
})
export abstract class SubscriberComponent extends SubscriberService implements OnDestroy  {
  protected destroyed$: Subject<{}> = new Subject();
  
  /**
   * DO NOT use this.destroyed$.complete();
   * It is not necessary:
   * https://stackoverflow.com/questions/44289859/do-i-need-to-complete-a-subject-for-it-to-be-garbage-collected
   */
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.dispose();
  }
}