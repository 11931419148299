// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { PlaceholderVm } from 'projects/entities/src/lib/domain/models/placeholder-vm';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, PlaceholdersActions, ProfanityService } from 'projects/entities/src/public_api';
import { fromPlaceholders } from 'projects/entities/src/lib/domain/selectors';
import { concatLatestFrom } from '@ngrx/effects';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'merc-edit-placeholder',
  templateUrl: './edit-placeholder.component.html',
  styleUrls: ['./edit-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class EditPlaceholderComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  active: boolean = false;
  title$: Observable<string>;
  description$: Observable<string>;
  placeholders$: Observable<PlaceholderVm[]>;
  errorMessage$: Observable<string>;
  profanityErrorMessage$: Observable<string>;
  showSendIcon$: Observable<boolean>;
  submitText$: Observable<string>;
  form: FormGroup;

  @ViewChildren('editPlaceholderInput') inputs: QueryList<ElementRef>;

  constructor(
    private store: Store<AppState>,
    private profanityService: ProfanityService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.active = true;
    this.title$ = this.store.select(fromPlaceholders.getEditPlaceholderPanelTitle);
    this.description$ = this.store.select(fromPlaceholders.getEditPlaceholderPanelDescription);
    this.errorMessage$ = this.store.select(fromPlaceholders.getEditPlaceholderErrorMessage);
    this.profanityErrorMessage$ = this.store.select(fromPlaceholders.getEditPlaceholderProfanityErrorMessage);    
    this.submitText$ = this.store.select(fromPlaceholders.getEditPlaceholderSubmitButtonText);
    this.showSendIcon$ = this.store.select(fromPlaceholders.getEditPlaceholderShowSendIcon);
    this.placeholders$ = this.store.select(fromPlaceholders.getEditPlaceholderPanelPlaceholderVms);

    this.form = this.formBuilder.group({
      placeholders: this.formBuilder.array([])
    });
    this.placeholders$.pipe(
      concatLatestFrom(() => this.store.select(fromPlaceholders.getEditPlaceholderValidationRequired))
    ).subscribe(([placeholderVms, shouldValidate]) => {
      placeholderVms.forEach(p => {
        this.addPlaceholderForm(p, shouldValidate);
      });
    });
  }

  ngAfterViewInit() {
		this.inputs.first?.nativeElement.focus();
	}

  closeForm(){
    this.store.dispatch(PlaceholdersActions.closeEditPlaceholderPanel());
  }

  updateSelectedValue(selectedValue: string, index: number){
    this.placeholderForms.controls[index].setValue({ selectedValue });
  }

  onSubmit(){
    this.form.markAllAsTouched();
    const controls = this.placeholderForms.controls;
    const isValid = controls.every(control => control.valid);
    if (isValid){
      const formValues = controls.map(control => control.value.selectedValue);
      this.store.dispatch(PlaceholdersActions.submitEditPlaceholderPanel({formValues}));
    }
  }

  get placeholderForms() {
    return this.form.controls['placeholders'] as FormArray;
  }

  addPlaceholderForm(placeholderVm: PlaceholderVm, shouldValidate: boolean){
    const control = shouldValidate
      ? [placeholderVm.selectedValue, Validators.required, this.profanityValidator()]
      : [placeholderVm.selectedValue, undefined, this.profanityValidator()];

    const placeholder = this.formBuilder.group({
      selectedValue: control
    });

    this.placeholderForms.push(placeholder);
  }

  hasError(formGroup: FormGroup){
    const control = this.getSelectedValueControl(formGroup);
    return control.touched && control.invalid;
  }

  hasProfanity(formGroup: FormGroup): boolean {
    const control = this.getSelectedValueControl(formGroup);
    return control.errors?.hasProfanity;
  }

  private getSelectedValueControl(formGroup: FormGroup): AbstractControl {
    return formGroup.controls['selectedValue'];
  }

  trackByIndex(index: number) {
    return index;
  }

  // profanityValidator is async so it won't run unless sync validators pass (currently Validators.required)
  private profanityValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      const illegalWords = this.profanityService.test(control.value);
      const hasProfanity = (illegalWords?.length > 0);
  
      return Promise.resolve(hasProfanity ? { hasProfanity } : null);
    };
  }  
}
