// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgentDefaultAvailabilityReasons } from 'projects/entities/src/lib/constants/constants';
import { fromAgent, fromAgentAvailability, fromChat } from 'projects/entities/src/lib/domain/selectors';
import { AgentAvailabilityActions, AppState, AvailabilityChange, AvailabilityType } from 'projects/entities/src/public_api';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { AvailabilityOption } from 'src/app/models/availability-options';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-agent-availability-selector',
  templateUrl: './availability-selector.component.html',
  styleUrls: ['./availability-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailabilitySelectorComponent extends SubscriberComponent implements OnInit {
  @Input() hasChats: boolean = false;
  public options: AvailabilityOption[] = [];
  public selected: string = null;

  public cssClass: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public name: string = 'cee-example-select';
  public accessibilityLabel: string = 'Select an agent state';
  public hasPendingChats$;
  public incomingChatOptionId = AgentDefaultAvailabilityReasons.IncomingChat.toLowerCase();

  constructor(
    private appState: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    this.hasPendingChats$ = this.appState.select(fromChat.hasPendingChats);

    this.appState.select(fromAgent.getAvailabilityOptions)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((availabilityOptions: AvailabilityOption[]) => {
        this.options = availabilityOptions;
      });

    const availSub$ = this.appState
      .select(fromAgentAvailability.getAvailabilityData)
      .pipe(
        distinctUntilChanged(),
        filter(({availability, availabilityOptions}) => availability.available !== AvailabilityType.Error
          && availabilityOptions.length > 0),
        map(({availability, availabilityOptions}) => availabilityOptions.find(x => x.label === availability.reason)),
        filter((opt: AvailabilityOption) => opt !== null && opt !== undefined)
      )
      .subscribe((value: AvailabilityOption) => {
        this.selected = value.id;
        const opt: AvailabilityOption = this._findOption(value.id);
        this._update(opt);
      });
    this.subscriptions.push(availSub$);
  }

  selectChanged(selectedOptId: string) {
    const selectedOption = this._findOption(selectedOptId);

    if (selectedOption) {
      this._update(selectedOption);

      const availArgs: AvailabilityChange = {
        available: selectedOption.available ? AvailabilityType.Available : AvailabilityType.Unavailable,
        reason: selectedOption.label,
        timestamp: Date.now()
      };
      this.appState.dispatch(AgentAvailabilityActions.updateAvailability(availArgs));
    }
  }

  private _update(selectedOption: AvailabilityOption) {
    if (selectedOption) {
      this.cssClass.next(selectedOption.color);
    }
  }

  private _findOption(optionId: string) {
    return this.options.find(opt => optionId === opt.id);
  }
}
