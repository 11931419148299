// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import {
  RemoteDependencyData,
  IDependencyTelemetry
} from '@microsoft/applicationinsights-common';

@Injectable({
  providedIn: 'root'
})
export class DependencyFailureValidator {
  private static dependencyNamePatterns: RegExp[] = [
    /\b(?:AcceptChat|CloseChatRequest|CloseConversation|GetUpdates|SendTextMessage|TransferChat)\b/i,
    /fixagent\/(?:getscripts)/i
  ];

  public isValid(telemetryItem: ITelemetryItem): boolean {
    if (!this.isRemoteDependency(telemetryItem)) {
      return false;
    }

    const remoteData = telemetryItem.baseData as IDependencyTelemetry;

    return Boolean(
      this.isFailure(remoteData) && this.isVitalDependency(remoteData)
    );
  }

  private isRemoteDependency(telemetryItem: ITelemetryItem): boolean {
    return (
      telemetryItem &&
      telemetryItem.name === RemoteDependencyData.envelopeType &&
      telemetryItem.baseType === RemoteDependencyData.dataType
    );
  }

  private isFailure(remoteData: IDependencyTelemetry) {
    return remoteData && remoteData.success === false;
  }

  private isVitalDependency(remoteData: IDependencyTelemetry): boolean {
    const dependencyName = remoteData.name;
    const patterns = DependencyFailureValidator.dependencyNamePatterns;

    return (
      dependencyName && patterns.some(pattern => pattern.test(dependencyName))
    );
  }
}
