<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  [active]="active"
  [customModalClass]="'merc-rate-connection-modal'"
  [customBackdropClass]="'cee-modal-backdrop--hidden merc-rate-connection-backdrop'"
  (modalClose)="closeModal(true)"
  [clickOutsideToClose]="false"
>
  <merc-rate-connection [chatId]="chatId" (ratingSubmitted)="closeModal(false)" [inlineModal]="true"></merc-rate-connection>
</cee-modal>
