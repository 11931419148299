// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { TimeHelperService, TimerService } from '@cxt-cee-chat/merc-ng-core';
import { Observable, Subscription } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent extends SubscriberComponent {
  readonly defaultLabel = '0:00';

  public timeLabel: string = this.defaultLabel;

  private _timerServiceValue$: Subscription;
  private _timerService: TimerService;

  @Input()
  public set timerRef(timerService: TimerService) {
    this._timerService = timerService;
    this.setupLabelSubscription(timerService);
  }

  public get timerRef(): TimerService {
    return this._timerService;
  }

  @Input()
  public styleClass: Observable<string>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private timeHelper: TimeHelperService
  ) {
    super();
  }

  private setupLabelSubscription(timerService: TimerService) {
    this.unsubscribe(this._timerServiceValue$);

    if (!timerService) {
      this.timeLabel = this.defaultLabel;
      return;
    }

    this.initializeTimeLabelSubscription(timerService);
  }

  private initializeTimeLabelSubscription(timerService: TimerService) {
    this._timerServiceValue$ = timerService.value.subscribe(
      (seconds: number) => {
        this.timeLabel = this.timeHelper.formatTime(seconds, this.defaultLabel);
        // TimerService's timer emits from runOutsideAngular so force change detection to update the label (and maybe the button style)
        this.changeDetector.detectChanges();
      }
    );

    this.subscriptions.push(this._timerServiceValue$);
  }

  private unsubscribe(subscription: Subscription) {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }
}
