// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateUnitType, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FeatureFlags } from '../constants/featureFlags.constants';
import { NpsSurvey } from '../constants/nps-survey.constants';
import { AgentGroup } from '../domain/models/agentGroup';
import { BusinessUnit } from '../domain/models/business-unit';
import { SurveyConfiguration } from '../domain/models/survey-configuration';
import { UserIdentityService } from './user-identity.service';


@Injectable({
	providedIn: 'root'
})
export class NpsSurveyService {
  constructor(
    protected userIdentityService: UserIdentityService,
    protected appConfigService: AppConfigService,
    private timeService: DayTimeService)
    {}

  public showNpsSurvey(surveyConfiguration: SurveyConfiguration, lastSurveyCompleteDate: number, featureFlags: string[], agentGroups: AgentGroup[], businessUnits: BusinessUnit[], isModal: boolean): boolean{
    if (!agentGroups?.length || !businessUnits?.length) { return false; }
    const startDate = surveyConfiguration?.startDate;
    const endDate = surveyConfiguration?.endDate;
    // if the user does not have the survey feature flag, NEVER present the survey
    if (featureFlags?.includes(FeatureFlags.NpsSurvey) && startDate && endDate) {
      const _startDate: Date = this.timeService.toDate(startDate);
      const startDateMidnight = this.timeService.unixStartOf(_startDate, DateUnitType.Day);
      const now = this.timeService.unix();

      // if the current date is not between the start/end dates, NEVER present the survey
      if (now < startDateMidnight || now > endDate) { return false; }

      if (!isModal) { return true; }

      // if agent last survey date is not set/null or before the start date (rounded back to midnight), present the survey
      if (!lastSurveyCompleteDate || lastSurveyCompleteDate < startDateMidnight) { return true; }
    }
    return false;
  }

  public generateUrl(agentGroups: AgentGroup[], businessUnits: BusinessUnit[], featureFlags: string[], agentNetworkId: string): string{
    let params = new HttpParams()
      .set(NpsSurvey.QueryStringKeys.UserName, agentNetworkId?.toLowerCase())
      .set(NpsSurvey.QueryStringKeys.AgentGroups, this.getAgentGroupQueryValue(agentGroups))
      .set(NpsSurvey.QueryStringKeys.BusinessUnits, this.getBusinessUnitQueryValue(businessUnits))
      .set(NpsSurvey.QueryStringKeys.AgentId, this.userIdentityService.username)
      .set(NpsSurvey.QueryStringKeys.TemplateId, 109)
      .set(NpsSurvey.QueryStringKeys.Tool, 5);

    params = this.setFeatureFlagQueries(featureFlags, params);

    return `${this.appConfigService.npsSurveyUrlBase}${params.toString()}`;
  }

  private getAgentGroupQueryValue(agentGroups: AgentGroup[]): string{
    return agentGroups.map(ag => ag.displayName).join(',');
  }

  private getBusinessUnitQueryValue(businessUnits: BusinessUnit[]): string{
    return businessUnits.map(bu => bu.name).join(',');
  }

  private setFeatureFlagQueries(featureFlags: string[], params: HttpParams): HttpParams{
    for (const [, value] of Object.entries(NpsSurvey.QueryStringKeys.FeatureFlags)){
      params = params.set(value, this.hasFeatureFlagQueryValue(featureFlags, value));
    }
    return params;
  }

  private hasFeatureFlagQueryValue(featureFlags: string[], featureFlagQueryKey: string): string{
    const featureFlagKey = this.getFeatureFlagKey(featureFlagQueryKey);
    const hasFeatureFlag = featureFlagKey ? featureFlags?.includes(featureFlagKey) : false;
    return hasFeatureFlag ? NpsSurvey.QueryStringValues.Yes : NpsSurvey.QueryStringValues.No;
  }

  private getFeatureFlagKey(featureFlagQueryKey: string): string{
    switch (featureFlagQueryKey){
      case NpsSurvey.QueryStringKeys.FeatureFlags.AbandonedChatAutomation:
        return FeatureFlags.AbandonedChatAutomation;
      case NpsSurvey.QueryStringKeys.FeatureFlags.XaResponses:
        return FeatureFlags.XaResponse;
      case NpsSurvey.QueryStringKeys.FeatureFlags.XaSolves:
        return FeatureFlags.XaSolves;
      default:
        return '';
    }
  }
}

