// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'merc-agent-settings-confirm-logout-modal',
  templateUrl: './confirm-logout-modal.component.html',
  styleUrls: ['./confirm-logout-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmLogoutModalComponent implements OnInit {
  @Input() active: boolean = false;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modalConfirm = new EventEmitter();

  constructor(
  ) {}

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalClose.emit(true);
  }

  public logout(): void {
    this.modalConfirm.emit();
  }
}
