// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { ProfanityList } from './models/profanityList';

export const Load = createAction('[Profanity] Load');

export const Loaded = createAction(
  '[Profanity] Loaded',
  props<{ profanityList: ProfanityList }>()
);

export const reportIllegalWords = createAction(
  '[Profanity] Report Illegal Words',
  props<{ chatId: string, message: string, illegalWords: string[], errorMessage: string }>()
);
