// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiRequest } from './cti-request';

export class CtiResponse extends CtiRequest{ 
    requestId: string;
    
    public constructor (requestId: string, sessionId: string) {
        super(sessionId);
        this.requestId = requestId;
   }
}