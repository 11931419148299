// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ItgConfiguration {
  /**
   * How long to wait in seconds before sending the "yes" intent to XA
   */
  static SendStartWaitTimeInSeconds: number = 1;
}

export class ItgActionText {
  public static readonly Started: string = 'Started';
  public static readonly Completed: string = 'Completed';
  public static readonly Resumed: string = 'Resumed';
}

export class ItgIntents {
  public static readonly Content: string = 'itg-content';
}

export class SchedulerIntents {
  public static readonly Failure: string = 'failure';
  public static readonly Appointment: string = 'inva-appointment';
}
