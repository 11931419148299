<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-call-feed-reference" *ngIf="messageText">
  <svg class="cee-icon cee-icon--lg">
    <use xlink:href="/assets/img/svg-sprite.svg#icon-message-fill"></use>
  </svg>
  <div class="merc-call-feed-reference--message">
    <span class="merc-call-feed-reference--message-prefix">They said, </span>
    <span>"{{ messageText }}"</span>
  </div>
</div>
