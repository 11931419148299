// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { State } from './ask-me-anything.reducer';
import { RatingState } from '../models/voice/enums';
import { AskMeAnythingCitation, AskMeAnythingRewriteVm, AskMeAnythingSearchVm } from './ask-me-anything.model';

export const hydrate = createAction('[AskMeAnything] Hydrate');

export const hydrateSuccess = createAction(
  '[AskMeAnything] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[AskMeAnything] Hydrate Failure');

export const chatsRemoved = createAction(
  '[AskMeAnything] Chats Removed',
  props<{ chatIds: string[] }>()
);

export const search = createAction(
  '[AskMeAnything] Search', 
  props<{ chatId: string, searchId: string, question: string, isCustomerQuestion: boolean, knowledgeFocused: boolean }>()
);

export const searchFailed = createAction(
  '[AskMeAnything] Search Failed',
  props<{ chatId: string, searchId: string }>()
);

export const updateAnswer = createAction(
  '[AskMeAnything] Update Answer',
  props<{ chatId: string, searchId: string, questionId: string, answer: string, citations: AskMeAnythingCitation[] }>()
);

export const rateAnswer = createAction(
  '[AskMeAnything] Rate Response',
  props<{ chatId: string, searchId: string, rating: RatingState }>()
);

export const retrySearch = createAction(
  '[AskMeAnything] Retry Search', 
  props<{ chatId: string, searchId: string }>()
);

export const closeFeedbackModal = createAction('[AskMeAnything] Close Feedback Modal');

export const submitFeedback = createAction(
  '[AskMeAnything] Submit Feedback',
  props<{ chatId: string, searchId: string, questionId: string, selectedFeedback: string[], optionalFeedback: string }>()
);

export const logSourceClicked = createAction(
  '[AskMeAnything] Log Source Clicked',
  props<{chatId: string, documentId: string, searchId: string, questionId: string}>()
);

export const rewriteAnswer = createAction(
  '[AskMeAnything] Rewrite Answer',
  props<{chatId: string, search: AskMeAnythingSearchVm }>()
);

export const addRewrite = createAction(
  '[AskMeAnything] Add Rewrite',
  props<{chatId: string, rewrite: AskMeAnythingRewriteVm }>()
);

export const rewriteError = createAction(
  '[AskMeAnything] Rewrite Error',
  props<{chatId: string, questionId: string }>()
);
