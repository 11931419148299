// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ChatRequestApiConstants {
         public static readonly Callbacks = {
           NewChat: 'NewChatCallback',
           AcceptChat: 'AcceptChatCallback',
           RefuseChat: 'RefuseChatCallback',
           NewChatMessage: 'NewChatMessageCallback',
           NewChatAttachment: 'NewChatAttachmentCallback',
           CustomerClosedChat: 'CustomerClosedChatCallback',
           ChatClosed: 'ChatClosedCallback',
           SendMessage: 'SendMessageCallback',
           TransferChat: 'TransferChatCallback',
           GetTransferOptions: 'GetTransferOptionsCallback',
           NewTransferChat: 'NewTransferChatCallback',
           BounceChat: 'BounceChatCallback',
           UpdatesComplete: 'UpdatesCompleteCallback',
           CustomerDatapass: 'CustomerDatapassCallback',
           CustomerUui: 'CustomerUuiCallback',
           ChannelInformation: 'ChannelInformationCallback',
           CustomerConnectionLost: 'CustomerConnectionLostCallback',
           CustomerReconnected: 'CustomerReconnectedCallback',
           CustomerNavigatedPage: 'CustomerNavigatedPageCallback',
           CustomerMinimizedChatWindow: 'CustomerMinimizedChatWindowCallback',
           CustomerRestoredChatWindow: 'CustomerRestoredChatWindowCallback',
           ReportImage: 'ReportImageCallback',
           UpdateAccountNumber: 'UpdateAccountNumberCallback',
           TypingStateChanged: 'TypingStateChangedCallback',
           SendAgentTypingStatus: 'SendAgentTypingStatusCallback',
           AgentSessionLost: 'AgentSessionLostCallback',
           GetPriorEngagements: 'GetPriorEngagementsCallback',
           GetCustomerInformation: 'GetCustomerInformationCallBack',
           GetChatSettings: 'GetChatSettingsCallback',
           GetAsyncEngagements: 'GetAsyncEngagementsCallback',
           CloseConversation: 'CloseConversationCallback',
           GetActiveEngagements: 'GetActiveEngagementsCallback',
           GetActiveChatTranscript: 'GetActiveChatTranscriptCallback',
           JoinChat: 'JoinChatCallback',
           AgentLostConnection: 'AgentLostConnectionCallback',
           XaTranscript: 'XaTranscriptCallback',
           MaskText: 'MaskTextCallback',
           UnlinkAccount: 'UnlinkAccountCallback',
           InformationMessage: 'InformationMessageCallback',
           GetXaSuggestions: 'GetXaSuggestionsCallback',
           SendXaSuggestion: 'SendXaSuggestionCallback',
           GetEngagementById: 'GetEngagementByIdCallback',
           GetDispositions: 'GetDispositionsCallback',
           TopicSelection: 'TopicSelectionCallback',
           PageMarker: 'PageMarkerCallback',
           Recommendation: 'RecommendationCallback',
           ChatInboundEvent: 'ChatInboundEventCallback',
           CustomerDetails: 'CustomerDetailsCallback',
           GetConversationSummary: 'ConversationSummaryCallback',
           SaveUnifiedNotes: 'SaveUnifiedNotesCallback',
           LogTranslationRequest: 'LogTranslationRequestCallback',
           BotConversationSummaryCallback: 'BotConversationSummaryCallback'
         };
       }
