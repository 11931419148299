// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ButtonControl, ButtonType } from '@cxt-cee-chat/merc-pattern-lib';
import { DecrementingTimerService } from '@cxt-cee-chat/merc-ng-core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'merc-lockout-warning-modal',
  templateUrl: './lockout-warning.component.html',
  styleUrls: ['./lockout-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockoutWarningComponent extends SubscriberComponent implements OnInit {
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modalConfirm = new EventEmitter();
  @Input() timer: DecrementingTimerService;
  controls: ButtonControl[] = [];
  active: boolean = false;

  constructor(
    private changeDetectionRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.controls = [
      {
        text: 'I\'m still here',
        type: ButtonType.Primary,
        disabled: false,
        controlFunction: () => this.closeModal()
      }
    ];
    this.active = true;
    if (this.timer){
      this.timer.timeRemaining.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        // force change detection since timers run outside of ngZone
        this.changeDetectionRef.detectChanges();
      });
    }
  }

  closeModal(): void {
    this.modalClose.emit(true);
  }

}
