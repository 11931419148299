// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ScriptActions, ScriptGroupActions, ScriptTreeActions } from './actions';
import { defaultCustomScriptGroup } from './custom-responses.constants';
import { ManagedScript as ManagedScript, Script } from './models';

export const scriptsFeatureKey = 'scripts';

export interface State extends EntityState<Script> {
  managedScript: ManagedScript | null;
  manageScriptQuery: string | null;
}

export const adapter: EntityAdapter<Script> = createEntityAdapter<Script>();

export const initialState: State = adapter.getInitialState({
  managedScript: null,
  manageScriptQuery: null,
});


export const reducer = createReducer(
  initialState,
  on(ScriptTreeActions.upsertScriptCollection,
    (state, action) => adapter.upsertMany(action.scripts, state)
  ),
  on(ScriptTreeActions.deleteScriptCollection,
    (state, { scriptTreeId }) => {
      const ids = Object.entries(state.entities)
        .filter(([_, entity]) => entity.scriptTreeId === scriptTreeId)
        .map(([key, _]) => key);
      return adapter.removeMany(ids, state);
    }
  ),
  on(ScriptActions.searchManageScripts,
    (state, { query: manageScriptQuery }) => ({
      ...state,
      manageScriptQuery
    })
  ),
  on(ScriptActions.addScript,
    (state, { script }) => ({
      ...adapter.addOne(script, state),
      managedScript: { id: script.id, status: 'NEW' },
    })
  ),
  on(ScriptActions.updateScriptValue,
    (state, { id, scriptGroupId, newValue, newScriptGroup: { id: newScriptGroupId }}) => {
      const script: Update<Script> = {
        id,
        changes: {
          value: newValue,
          scriptGroupId: newScriptGroupId ?? scriptGroupId
        }
      };

      return {
        ...adapter.updateOne(script, state),
        managedScript: { id, status: 'UPDATED' },
      };
    }
  ),
  on(ScriptActions.moveScript,
    (state, { id, scriptGroupId, newScriptGroupId }) => {
      if (!newScriptGroupId || scriptGroupId === newScriptGroupId) {
        return {...state,
          managedScript: null,
        };
      }

      const update: Update<Script> = {
        id,
        changes: {
          scriptGroupId: newScriptGroupId
        }
      };

      return {
        ...adapter.updateOne(update, state),
        managedScript: { id, status: 'MOVED' },
      };
    }
  ),
  on(ScriptActions.deleteScript,
    (state, { id }) => ({
      ...adapter.removeOne(id, state),
      managedScript: { id, status: 'DELETED' },
    })
  ),
  on(ScriptActions.deleteScriptConfirmation,
    (state, { id }) => ({
      ...state,
      managedScript: { id, status: 'DELETE' },
    })
  ),
  on(
    ScriptActions.deleteScriptConfirmationDismiss,
    ScriptGroupActions.deleteScriptGroupConfirmation,
    (state) => ({
      ...state,
      managedScript: { id: null, status: 'MANAGE' },
    })
  ),
  on(ScriptActions.manageScriptsClosed,
    ScriptGroupActions.createScriptGroup,
    ScriptGroupActions.editScriptGroup,
    (state) => ({
      ...state,
      managedScript: null,
     })
  ),
  on(ScriptActions.editScript,
    (state, { id }) => ({
      ...state,
      managedScript: { id, status: 'EDIT' },
    })
  ),
  on(ScriptActions.createScript,
    (state) => ({
      ...state,
      managedScript: { id: null, status: 'CREATE' },
    })
  ),
  on(ScriptActions.manageScripts,
    (state) => ({
      ...state,
      managedScript: { id: null, status: 'MANAGE' },
    })
  ),
  on(ScriptGroupActions.addScriptGroup,
    (state) => ({
      ...state,
      managedScript: { id: null, status: 'NEW HEADER' },
    })
  ),
  on(ScriptGroupActions.deleteScriptGroup,
    (state, { id }) => {
      const updates: Update<Script>[] = Object.entries(state.entities)
      .filter(([_, entity]) =>  entity.scriptGroupId === id)
      .map(([key, _]) => {
        return {
          id: key,
          changes: {
            scriptGroupId: defaultCustomScriptGroup.id
          }
      };
      });

      return {
        ...adapter.updateMany(updates, state),
        managedScript: {id: null, status: 'DELETE HEADER'}
      };
    }
  ),
  on(ScriptGroupActions.updateScriptGroupName,
    (state) => {
      return {
        ...state,
        managedScript: { id: null, status: 'EDIT HEADER' }
      };
    }
  ),
  on(ScriptActions.hydrateSuccess, (state, action) => {
    return action.scriptState ? action.scriptState : state;
  })
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getManagedScript = (state: State) => state.managedScript;

export const getManageScriptQuery = (state: State) => state.manageScriptQuery ?? '';
