<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
[active]="active"
[headerText]="title$ | async"
(modalClose)="closeForm()"
[customBackdropClass]="'cee-modal-backdrop--hidden merc-edit-placeholder-backdrop'"
>
  <p class="cee-body-detail">{{ description$ | async }}</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="placeholders$ | async as placeholderVms">
    <div class="merc-placeholder-editor-wrapper"
      *ngFor="let placeholder of placeholderVms; let index=index; trackBy: trackByIndex"
      [formGroup]="placeholderForms.controls[index]"
      >
      <div
        class="cee-form-row--group"
        [ngClass]="{ 'has-error': hasError(placeholderForms.controls[index]) }"
      >
        <label class="cee-form-label" for="placeholder-{{index}}">{{ placeholder.label }}</label>
        <input
          #editPlaceholderInput
          type="text"
          class="cee-form-input"
          name="placeholder-{{index}}"
          id="placeholder-{{index}}"
          placeholder="Enter value"
          formControlName='selectedValue'
          [ngClass]="{ error: hasError(placeholderForms.controls[index]) }"
        />
        <p
          *ngIf="hasError(placeholderForms.controls[index])"
          class="cee-form-error-msg"
        >
          <ng-container *ngIf="hasProfanity(placeholderForms.controls[index]); else otherError">
            {{ profanityErrorMessage$ | async}}
          </ng-container>
          <ng-template #otherError>{{ errorMessage$ | async}}</ng-template>
        </p>
        <cee-button *ngFor="let option of (placeholder.options | sortBy:'desc':'confidence')"
          type="option"
          typeAttribute="button"
          customClass="merc-placeholder-option-btn"
          (buttonClick)="updateSelectedValue(option.value, index)">
          {{ option.value }}
        </cee-button>
      </div>
    </div>

    <div class="merc-placeholder-form-submit">
      <cee-button type="link" typeAttribute="button" (buttonClick)="closeForm()">Close</cee-button>
      <cee-button type="primary">
        <svg
          *ngIf="showSendIcon$ | async"
          class="cee-icon cee-icon--md"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          x-test-target="send-icon"
        >
          <use xlink:href="/assets/img/svg-sprite.svg#icon-paper-airplane"></use>
        </svg>
        {{ submitText$ | async }}
      </cee-button>
    </div>

  </form>
</cee-modal>

