// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AsyncEngagementTranscript } from './chatTranscript';

export class UpdateAsyncEngagements {
  chatId: string;
  engagements: AsyncEngagementTranscript[];
  unresolvedContactReason: string;
}
