// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, EventEmitter, Output, forwardRef, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { CeeTabPanelItemComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { AgentCustomSettings } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-agent-settings-sound-notification-tab',
  templateUrl: './sound-notification-tab.component.html',
  styleUrls: ['./sound-notification-tab.component.scss'],
  providers: [
    {
      provide: CeeTabPanelItemComponent,
      useExisting: forwardRef(() => SoundNotificationTabComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoundNotificationTabComponent extends CeeTabPanelItemComponent implements OnInit {
  title = 'Sound and notifications';
  showControls = true;

  @Input() settings: AgentCustomSettings;
  @Output() settingsModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
    super();
   }

  ngOnInit() {
    if (this.settings.soundVolume === 0) {
      this.settings.soundMuteAll = true;
      this.onSettingsModelChange();
    }
  }

  onSettingsModelChange() {
    this.settingsModelChange.emit(this.settings);
  }

  onVolumeChange(soundbarVolume) {
    this.settings.soundVolume = soundbarVolume;
    this.settings.soundMuteAll = soundbarVolume === 0;

    this.onSettingsModelChange();
  }

  onMuted(isMuted) {
    this.settings.soundMuteAll = isMuted;
    this.onSettingsModelChange();
  }
}
