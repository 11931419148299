// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { NumberHelper } from '../utils/number-helper';

@Injectable({
	providedIn: 'root'
})
export class RateConnectionQualityHelper {

  public static offerQualityCheck(
    lastQualityCheckDate: Date,
    offerFirstQualityCheck: boolean,
    qualityCheckFrequency: number,
    qualityCheckFirstOfferChance: number,
    qualityCheckOfferChance: number,
    isFirstChatClosedSinceAvailable: boolean,
    chatId: string): {offerQualityCheck: boolean, offerFirstQualityCheck: boolean, chatId: string}
  {
    if (isFirstChatClosedSinceAvailable) { return {offerQualityCheck: false, offerFirstQualityCheck, chatId }; }

    let runOfferChance = false;
    if (!lastQualityCheckDate){
      // quality check has not been offered to the agent before
      // when null, determine if it should be offered during this session
      offerFirstQualityCheck = offerFirstQualityCheck ?? NumberHelper.probability(qualityCheckFirstOfferChance);
      runOfferChance = offerFirstQualityCheck;
    }
    // check if the quality check has been offered in the last qualityCheckFrequency
    else if (this.offerableFrequencyCheck(lastQualityCheckDate, qualityCheckFrequency)) {
      runOfferChance = true;
    }

    if (runOfferChance){
      return { offerQualityCheck: NumberHelper.probability(qualityCheckOfferChance), offerFirstQualityCheck, chatId };
    }

    return { offerQualityCheck: false, offerFirstQualityCheck, chatId };
  }

  private static offerableFrequencyCheck(lastQualityCheckDate: Date, qualityCheckFrequency: number): boolean{
    const daysAgoDate = new Date();
    daysAgoDate.setDate(daysAgoDate.getDate() - qualityCheckFrequency);

    return lastQualityCheckDate < daysAgoDate;
  }
}
