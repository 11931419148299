// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy, ElementRef, NgZone } from '@angular/core';
import { CeeScriptSearchComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, ChatUiActions, hasFeatureFlag, FeatureFlags, Chat, UiActions } from 'projects/entities/src/public_api';
import { ScriptGroup } from 'projects/entities/src/lib/domain/scripts/';
import * as fromSelectedChatScripts from 'projects/entities/src/lib/domain/scripts/selected-chat-selectors';
import { fromCustomResponses, fromScriptsSearch } from 'projects/entities/src/lib/domain/scripts';
import { fromAgent, fromChat, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { concatLatestFrom } from '@ngrx/effects';
import { ScriptsContextMenu } from 'src/app/models/scripts-context-menu';
import { DetailsSelectedTab } from 'projects/entities/src/lib/domain/models/enums';

@Component({
  selector: 'merc-convo-scripts',
  templateUrl: './scripts.component.html',
  styleUrls: ['./scripts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptsComponent extends SubscriberComponent implements OnInit {
  @Input() chatId: string;
  @Input() scriptTreeId: string;

  public scriptSearchId = 'scripts';

  public customScriptGroups$: Observable<ScriptGroup[]>;
  public systemScriptGroups$: Observable<ScriptGroup[]>;
  public scriptFilterCount$: Observable<number>;
  public isSearching$: Observable<boolean>;
  public searchTerm$: Observable<string>;
  public enableCustomScripts$: Observable<boolean>;
  public isExpandAllScripts$: Observable<boolean>;
  public showSystemScripts$: Observable<boolean>;
  scroll$: BehaviorSubject<number>;
  selectedActiveChat$: Observable<Chat>;

  hasXaResponse$: Observable<boolean>;

  private _searchSubject = new Subject();

  @ViewChild('scriptSearch')
  scriptSearchComponent: CeeScriptSearchComponent;

  @ViewChild('scrollWindow') private scrollWindow!: ElementRef;

  constructor(
    private store: Store<AppState>,    
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit() {   
    this.customScriptGroups$ = this.store.select(fromCustomResponses.selectFilteredCustomScriptGroups);
    this.systemScriptGroups$ = this.store.select(fromSelectedChatScripts.selectFilteredScriptGroups);
    this.scriptFilterCount$ = this.store.select(fromSelectedChatScripts.selectFilteredScriptCount);
    this.isSearching$ = this.store.select(fromScriptsSearch.isSelectedChatSearchingScripts);
    this.searchTerm$ = this.store.select(fromScriptsSearch.getSearchTerm);
    this.enableCustomScripts$ = this.store.select(fromAgent.getEnableCustomScripts);
    this.isExpandAllScripts$ = this.store.select(fromSelectedChatScripts.selectExpandAllScripts);
    this.showSystemScripts$ = this.store.select(fromSelectedChatScripts.showSystemScripts);
    this.selectedActiveChat$ =  this.store.select(fromChat.getSelectedChat);
    this.scroll$ = new BehaviorSubject<number>(0);

    this.subscriptions.push(this._searchSubject
      .pipe(debounceTime(250))
      .subscribe((query: string) => {
        const { chatId } = this;
        this.store.dispatch(ChatUiActions.searchScripts({ chatId, query }));
      })
    );

    this.hasXaResponse$ =  this.store.select(hasFeatureFlag(FeatureFlags.XaResponse));

    this.selectedActiveChat$
    .pipe(
      debounceTime(50),
      filter((chat) => Boolean(chat)),
      map((chat) => chat.chatId),
      distinctUntilChanged(),
      concatLatestFrom(() =>
        this.store.select(fromChat.getDetailsPanelScrollPosition),
      ),
      takeUntil(this.destroyed$)
    )
    .subscribe(([_, scrollPosition]) => {
        if (this.scrollWindow) {
          this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
              this.scrollWindow.nativeElement.scrollTop = scrollPosition ?? 0;
            });
          });
        }
    });

  this.scroll$
    .pipe(
      debounceTime(50),
      concatLatestFrom(() => [
        this.selectedActiveChat$,
        this.store.select(fromUi.getIsScriptsContextMenuOpen),
        this.store.select(fromChat.getDetailsPanelTabSelected)
      ]),
      takeUntil(this.destroyed$))
    .subscribe( ([scrollPosition, chat, isScriptsContextMenuOpen, selectedTab]) => {
      if (isScriptsContextMenuOpen){
        this.closeContextMenu();
      }

      if (chat && selectedTab === DetailsSelectedTab.Responses) {
        this.store.dispatch(ChatUiActions.updateDetailsPanelScrollPos({chatId: chat.chatId, detailsPanelScrollPosition: scrollPosition}));
      }
  });
}

onScroll($event) {
  const scrollValue = $event.target.scrollTop ?? 0;
  this.scroll$.next(scrollValue);
}
closeContextMenu(){
  const scriptsContextMenu = new ScriptsContextMenu();
  scriptsContextMenu.open = false;
  this.store.dispatch(UiActions.ToggleScriptsContextMenuAction({ scriptsContextMenu }));
}

  onSearchChange(searchInput: string) {
    const searchTerm = searchInput || '';
    this._searchSubject.next(searchTerm);
  }


  clearSearch() {
    if (this.scriptSearchComponent){
      this.scriptSearchComponent.cancelSearch();
    }
  }

  onExpandedChange(isSearching: boolean, expanded: boolean){
    if (isSearching) {
      // TODO: should we track searching expand state?
      // short circuiting for now since it is possible for the expanded/collapsed UI state to get out of sync
      return;
    }

    if (expanded) {
      this.store.dispatch(ChatUiActions.expandAllScriptGroups({ chatId: this.chatId }));
    }
    else {
      this.store.dispatch(ChatUiActions.collapseAllScriptGroups({ chatId: this.chatId }));
    }
  }

  onSystemScriptChange() {
    this.store.dispatch(ChatUiActions.toggleViewSystemScripts({ chatId: this.chatId }));
  }
}
