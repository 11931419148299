// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAskMeAnything from './ask-me-anything.reducer';
import { AppState } from '../state';
import { AskMeAnything } from './ask-me-anything.model';
import { fromChat } from '../selectors';
import { Dictionary } from '@ngrx/entity';


export const selectAskMeAnythingFeature = createFeatureSelector<AppState, fromAskMeAnything.State>(fromAskMeAnything.featureKey);

const {
    selectEntities: selectAskMeAnythingEntities,
} = fromAskMeAnything.adapter.getSelectors(selectAskMeAnythingFeature);

export const getSelectedAskMeAnythingId = createSelector(
    fromChat.getSelectedChatId,
    (selectedChatId: string) => selectedChatId
);

export const getAskMeAnythingByChatId = (chatId: string) => createSelector(
    selectAskMeAnythingEntities,
    (entities: Dictionary<AskMeAnything>) => entities[chatId]?.searches ?? []
);

export const getAskMeAnythingSearchBySearchId = (chatId: string, searchId: string) => createSelector(
    selectAskMeAnythingEntities,
    (entities: Dictionary<AskMeAnything>) => entities[chatId]?.searches?.find(search => search.searchId === searchId)
);

export const getAskMeAnythingForSelectedChat = createSelector(
    getSelectedAskMeAnythingId,
    selectAskMeAnythingEntities,
    (id: string, entities: Dictionary<AskMeAnything>) => entities[id]?.searches ?? []
);

export const getFeedbackSearchId = createSelector(
    selectAskMeAnythingFeature,
    (state: fromAskMeAnything.State) => state.feedbackSearchId
);

export const getIsFeedbackModalOpen = createSelector(
    selectAskMeAnythingFeature,
    (state: fromAskMeAnything.State) => state.isFeedbackModalActive
);
