// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { SendSuggestion } from 'projects/entities/src/lib/domain/models';
import { ChatState, ConfirmAction, SendSuggestionSource } from 'projects/entities/src/lib/domain/models/enums';
import { Suggestion } from 'projects/entities/src/lib/domain/models/suggestion';
import { AppState, ChatActions, UiActions } from 'projects/entities/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService) {
  }

  handleSendSuggestion(_event: any, suggestion: Suggestion, chatId: string) {
    const useSuggestionArgs: SendSuggestion = {
      ...suggestion,
      chatId,
      source: SendSuggestionSource.Window,
      traceId: Guid.raw()
    };

    if (_event.quickSend) {
      useSuggestionArgs.isModified = false;
      useSuggestionArgs.timestamp = this.timeService.unix();
      this.store.dispatch(ChatActions.SendSuggestion(useSuggestionArgs));
    } else {
      this.store.dispatch(ChatActions.ClickSuggestion(useSuggestionArgs));
    }
  }

  handleConfirmAction(action: ConfirmAction, chatState: ChatState, isAsync: boolean, hasDispositions: boolean, chatId: string){
    switch (action) {
      case ConfirmAction.Close: {
        if (!isAsync && !hasDispositions && chatState === ChatState.Closed){
          this.store.dispatch(ChatActions.Close({chatId}));
        }
        else{        
          this.store.dispatch(UiActions.WrapChat());
        }
        break;
      }
      case ConfirmAction.Transfer: {
        this.store.dispatch(UiActions.GetTransferOptions());
        break;
      }        
    }
  }
}
