// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChannelDevice } from './channelDevice';

export class BrowserDeviceInformation extends ChannelDevice{
    name: string;
    version: string;
    operatingSystem: string;
}