<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="type === 'simple'; else defaultMessage">
  <cee-chat-message
  [messageSenderType]="senderType"
  [messageDateTime]="this.getFormattedTime(chatMessage.timestamp)"
  [messageCustomerNumber]="colorId"
  [senderDisplayName]="displayName"
  [messageText]="messageText"
  [isStacked]="false"
  [showPin]="false"
  [isSelected]="isMessageSelected"
  [dataQa]="dataQa"
>
  <cee-thumbnail *ngIf="isImage"
    [imageUrl]="imageUrl"
    (imageClicked)="onImageClicked()"
    (reportClicked)="onReportClicked()"
    [reported]="isReported"
    [blur]="!isXA"
    [showReportFlag]="!isXA"
  >
  </cee-thumbnail>
</cee-chat-message>
</ng-container>
<ng-template #defaultMessage>
<cee-chat-message *ngIf="{
  tabSelected: tabSelected$ | async,
  translatedMessageText: translatedMessageText$ | async,
  isTranslatedChat: isTranslatedChat$ | async,
  hasLanguageTranslationError: hasLanguageTranslationError$ | async,
  languageTooltipText: languageTooltipText$ | async,
  isMessageFromPreviousEngagement: isMessageFromPreviousEngagement$ | async
} as data"
  [messageSenderType]="senderType"
  [messageDateTime]="this.getFormattedTime(chatMessage.timestamp)"
  [messageChannel]="channel$ | async"
  [messageCustomerNumber]="colorId"
  [senderDisplayName]="displayName"
  [messageText]="getMessageDisplayText(messageText, data.translatedMessageText, data.isTranslatedChat, data.hasLanguageTranslationError)"
  [languageTooltipText]="getLanguageTooltipText(data.languageTooltipText, data.translatedMessageText, data.isTranslatedChat, data.isMessageFromPreviousEngagement)"
  [languageTooltipDirection]="senderType === 'customer' ? 'right' : 'left'"
  [isStacked]="isSamePrevSender && isNotPreviousAsyncTranslatedMessage(data.isTranslatedChat, data.isMessageFromPreviousEngagement, data.translatedMessageText)"
  [isSelected]="isMessageSelected"
  (contextmenu)="onRightClick($event, data.isTranslatedChat, data.translatedMessageText)"
  [amaEnabled]="(hasAmaFeatureFlag$ | async)"
  (sendAma)="sendAma(data.tabSelected)"
  [showPin]="showPin && (hasPinFeatureFlag$ | async)"
  [isPinned]="isPinned$ | async"
  (togglePin)="togglePin($event)"
  [messageState]="chatMessageStatus | toMessageState"
  [failedStateMessage]="getFailureMessage(data.hasLanguageTranslationError)"
  [errorText]="getChatMessageErrorText(data.hasLanguageTranslationError)"
  [retryText]="getChatMessageRetryText(data.hasLanguageTranslationError)"
  (messageClick)="selectMessage()"
  (retryClick)="retryButton(data.hasLanguageTranslationError)"
  (deleteClick)="deleteMessage()"
  [dataQa]="dataQa"
  [hideAnimation]="hideAnimations$ | async"
>
  <cee-thumbnail *ngIf="isImage"
    [imageUrl]="imageUrl"
    (imageClicked)="onImageClicked()"
    (reportClicked)="onReportClicked()"
    [reported]="isReported"
    [blur]="!isXA"
    [showReportFlag]="!isXA"
  >
  </cee-thumbnail>
</cee-chat-message>
</ng-template>
