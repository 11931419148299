// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable, Inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../domain/state';
import { FixAgentAuthApiService } from '../services/fix-agent-auth-api.service';
import { ChatRequestApiService } from '../services/chat-request-api.service';
import { UserIdentityService } from '../services/user-identity.service';
import { FixAgentApiService } from '../services/fix-agent-api.service';
import { CustomerInfoApiService } from '../services/customer-info-api.service';
import { JwtTokenFactoryService, MaskingService, AuthService, TokenStoreService, LoggingFactoryService, NiagaraLogAnalyticsApiService, EnvironmentService, QueueService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { FixAgentProfileService } from '../services/fix-agent-profile.service';
import { AgentStatePersisterService } from '../services/agent-state-persister.service';
import { AppConfigApiService } from '../services/app-config-api.service';
import { FixAgentFormAuthApiService } from '../services/fix-agent-form-auth-api.service';
import { NuanceFormAuthService } from '../services/nuance-form-auth.service';
import { ScriptsService } from '../services/scripts.service';
import { ColorEnumeratorService } from '../services/color-enumerator.service';
import { PageInitializationHelperService } from '../services/page-initialization-helper.service';
import { ConvoMessageContextMenuService } from 'src/app/components/convo-window/convoMessageContextMenu/convoMessageContextMenu.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { NlpTranscriptService } from '../services/nlp-transcript.service';

@Injectable()
export class NgEntityEffects {
	constructor(
		protected ngEntityStore: Store<AppState>,
		protected actions: Actions,
		protected authRestApiService: FixAgentAuthApiService,
		protected fixagentFormAuthRestApiService: FixAgentFormAuthApiService,
		protected chatRequestApiService: ChatRequestApiService,
		protected userIdentityService: UserIdentityService,
		protected fixAgentApiService: FixAgentApiService,
		protected colorService: ColorEnumeratorService,
		protected customerInfoService: CustomerInfoApiService,
		protected appConfigService: AppConfigApiService,
		protected formAuthService: NuanceFormAuthService,
		protected tokenFactoryService: JwtTokenFactoryService,
		protected maskingService: MaskingService,
		protected fixagentProfileService: FixAgentProfileService,
		protected agentStatePersister: AgentStatePersisterService,
		protected scriptsService: ScriptsService,
		protected loggingFactory: LoggingFactoryService,
		protected analyticsApiService: NiagaraLogAnalyticsApiService,
		protected environmentService: EnvironmentService,
		protected queueService: QueueService,
		@Inject(AuthService) protected authService: AuthService,
		protected tokenStore: TokenStoreService,
		protected config: AppConfigService,
		protected maskService: ConvoMessageContextMenuService,
		protected pageInitHelper: PageInitializationHelperService,
    protected nlpTranscriptService: NlpTranscriptService,
    protected timeService: DayTimeService
	) { }
}
