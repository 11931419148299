// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { RatingState, RecommendedActionState } from '../../models/voice/enums';
import { FeedContent } from '../../models/voice/feed-content';
import { RecommendedAction } from '../../models/voice/recommended-action';
import { TranscriptMessageResponse } from '../../models/voice/responses/transcript-message-response';
import { CallSummaryDisplay } from '../../models/voice/enums';
import { Call } from './call.model';
import { State } from './call.reducer';
import { SenderType } from '../../models';

export const hydrate = createAction('[Call] Hydrate');

export const hydrateSuccess = createAction(
  '[Call] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[Call] Hydrate Failure');

export const newCallReceived = createAction(
  '[Call] New Call Received',
  props<{ call: Call, startTimestamp: number }>()
);

export const highlightTranscriptMessage = createAction(
  '[Call] Highlight Transcript Message',
  props<{ utteranceSequence: number, sender: SenderType }>()
);

export const unhighlightTranscriptMessage = createAction(
  '[Call] Unhighlight Transcript Message',
  props<{ utteranceSequence: number, sender: SenderType }>()
);

export const unhighlightTranscriptMessageFromHighlightFeed = createAction(
  '[Call] Unhighlight Transcript Message From Highlight Feed',
  props<{ utteranceSequence: number, sender: SenderType }>()
);

export const recommendedActionControlClicked = createAction(
  '[Call] Recommended Action Control Clicked',
  props<{ recommendedAction: RecommendedAction, requestId: string }>()
);

export const updateRecommendedActionRating = createAction(
  '[Call] Update Recommended Action Rating',
  props<{ recommendedAction: RecommendedAction, rating: RatingState }>()
);

export const updateRecommendedActionState = createAction(
  '[Call] Update Recommended Action State',
  props<{ requestId: string, actionState: RecommendedActionState }>()
);

export const addHighlightFeedContent = createAction(
  '[Call] Add Highlight Feed Content',
  props<{ feedContent: FeedContent }>()
);

export const transcriptMessageReceived = createAction(
  '[Call] Transcript Message Received',
  props<{ transcriptMessageResponse: TranscriptMessageResponse }>()
);

export const logFirstTranscriptMessage = createAction(
  '[Call] Log First Transcript Message',
  props<{ callId: string }>()
);

export const transcriptRecommendedActionReceived = createAction(
  '[Call] Transcript Recommended Action Received',
  props<{ transcriptRecommendedAction: RecommendedAction, callId: string }>()
);

export const recommendedActionDesktopNotificationClicked = createAction(
  '[Call] Recommended Action Desktop Notification Clicked',
  props<{ recommendedAction: RecommendedAction, callId: string }>()
);

export const toggleSessionSummaryCollapse = createAction(
  '[Call] Session Summary Collapse Toggle',
  props<{ isCollapsed: boolean }>()
);

export const updateShowSessionSummaryModal = createAction(
  '[Call] Update Show Session Summary Modal',
  props<{ show: boolean, source: string }>()
);

export const getCallSummary = createAction(
  '[Call] Get Call Summary',
  props<{ callId: string, display: CallSummaryDisplay, traceId: string, isRetry?: boolean }>()
);

export const updateCallSummary = createAction(
  '[Call] Update Call Summary',
  props<{ callId: string, issue: string, resolution: string, error: boolean }>()
);

export const callSummaryError = createAction(
  '[Call] Call Summary Error',
  props<{ callId: string }>()
);

export const callEnded = createAction(
  '[Call] Call Ended',
  props<{ callId: string, isManual: boolean, source?: string }>()
);

export const callLaunchItgError = createAction(
  '[Call] Launch ITG Error',
  props<{ requestId: string }>()
);

export const rateSessionSummary = createAction(
  '[Call] Rate Session Summary',
  props<{ isPositiveRating: boolean }>()
);
