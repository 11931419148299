// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { AskMeAnything } from './ask-me-anything.model';
import { createReducer, on } from '@ngrx/store';
import { AskMeAnythingActions, ChatActions } from '../actions';
import { LoadingState } from '../models/enums';
import { RatingState } from '../models/voice/enums';

export const featureKey = 'askMeAnything';

export interface State extends EntityState<AskMeAnything> { 
    isFeedbackModalActive: boolean;
    feedbackSearchId: string;
}

export const adapter: EntityAdapter<AskMeAnything> = createEntityAdapter<AskMeAnything>({
    selectId: (askMeAnything: AskMeAnything) => askMeAnything.chatId,
    sortComparer: false
});

export const initialState: State = adapter.getInitialState({
    isFeedbackModalActive: false,
    feedbackSearchId: ''
});

export const reducer = createReducer(
    initialState,
    on(AskMeAnythingActions.hydrateSuccess, (state, action) => {
        return { ...state, ...action.state };
    }),
    on(AskMeAnythingActions.chatsRemoved, (state, { chatIds }) => {
        return adapter.removeMany(chatIds, state);
    }),
    on(ChatActions.Closed,
        ChatActions.Bounced,
        ChatActions.Transferred, (state, { payload }) => {
            const { chatId } = payload;
            return adapter.removeOne(chatId, state);
    }),
    on(AskMeAnythingActions.search, (state, {chatId, searchId, question, isCustomerQuestion}) => {
        const chatSearches = state.entities[chatId]?.searches ?? [];
        
        const searches = [
            ...chatSearches,
            {
                searchId,
                question,
                loadingState: LoadingState.Pending,
                rating: RatingState.None,
                isCustomerQuestion
            }
        ];
        
        const update = {
            chatId,
            searches
        };

        return adapter.upsertOne(update, state);
    }),
    on(AskMeAnythingActions.retrySearch, (state, {chatId, searchId}) => {
        const chatSearches = state.entities[chatId]?.searches ?? [];
        
        const searches = [...chatSearches];
        const retrySearchIndex = searches.findIndex(s => s.searchId === searchId);
        if (retrySearchIndex !== -1){
            searches[retrySearchIndex] = {
                ...searches[retrySearchIndex],
                loadingState: LoadingState.Pending
            };

            const update = {
                chatId,
                searches
            };
    
            return adapter.upsertOne(update, state);
        }

        return state;
    }),
    on(AskMeAnythingActions.searchFailed, (state, {chatId, searchId}) => {
        const chatSearches = state.entities[chatId]?.searches ?? [];
        
        const searches = [...chatSearches];
        const failedSearchIndex = searches.findIndex(s => s.searchId === searchId);
        if (failedSearchIndex !== -1){
            searches[failedSearchIndex] = {
                ...searches[failedSearchIndex],
                loadingState: LoadingState.Failed
            };

            const update = {
                chatId,
                searches
            };
    
            return adapter.upsertOne(update, state);
        }

        return state;
    }),
    on(AskMeAnythingActions.updateAnswer, (state, {chatId, searchId, questionId, answer, citations}) => {
        const chatSearches = state.entities[chatId]?.searches ?? [];
        
        const searches = [...chatSearches];
        const updateSearchIndex = searches.findIndex(s => s.searchId === searchId);
        if (updateSearchIndex !== -1){
            searches[updateSearchIndex] = {
                ...searches[updateSearchIndex],
                questionId,
                answer,
                citations,
                loadingState: LoadingState.Success
            };
            
            const update = {
                chatId,
                searches
            };
    
            return adapter.upsertOne(update, state);
        }
        return state;
    }),
    on(AskMeAnythingActions.rateAnswer, (state, {chatId, searchId, rating}) => {
        const chatSearches = state.entities[chatId]?.searches ?? [];
        
        const searches = [...chatSearches];
        const updateSearchIndex = searches.findIndex(s => s.searchId === searchId);
        if (updateSearchIndex !== -1){
            searches[updateSearchIndex] = {
                ...searches[updateSearchIndex],
                rating
            };
        
            const update = {
                chatId,
                searches,
            };

            const updatedState = adapter.upsertOne(update, state);

            if (rating === RatingState.Negative){
                return {
                    ...updatedState,
                    isFeedbackModalActive: true,
                    feedbackSearchId: searchId
                };
            }

            return updatedState;
        }
        return state;
    }),
    on(AskMeAnythingActions.closeFeedbackModal, (state) => {
        return {
            ...state,
            isFeedbackModalActive: false,
            feedbackSearchId: ''
        };
    }),
    on(AskMeAnythingActions.rewriteAnswer, (state, {chatId, search}) => {
        const chatSearches = state.entities[chatId]?.searches ?? [];
        
        const searches = [...chatSearches];
        const updateSearchIndex = searches.findIndex(s => s.searchId === search.searchId);
        if (updateSearchIndex !== -1){
            searches[updateSearchIndex] = {
                ...searches[updateSearchIndex],
                customerVersionClicked: true
            };
        
            const update = {
                chatId,
                searches,
            };

            return adapter.upsertOne(update, state);
        }
        return state;  
    }),
    on(AskMeAnythingActions.addRewrite, (state, {chatId, rewrite}) => {
        const entity: AskMeAnything = state.entities[chatId];
        const rewrites = entity?.rewrites ?? [];
    
        const update = {
            ...entity,
            rewrites: [...rewrites, rewrite]
        };

        return adapter.upsertOne(update, state);
    })
);