// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createSelector } from '@ngrx/store';
import { Script } from './models';
import * as fromScriptsSearch from './scripts-search-selectors';
import * as fromCustomResponses from './custom-responses-selectors';
import { getSelectedChatTextEditorContent as getAutocompleteTerm } from '../chat.selectors';

export const selectFirstAutocompleteScript = createSelector(
  getAutocompleteTerm,
  fromCustomResponses.selectAllScriptsCustomFirst,
  (searchTerm: string, scripts: Script[]) => autocompleteScripts(searchTerm, scripts)
);

export const autocompleteScripts = (query: string, scripts: Script[]): Script => {
  if (!fromScriptsSearch.isSearchTermValid(query)) {
    return null;
  }

  const search = query.toLowerCase();
  let matchingScript = scripts.find(s => {
    // match script to search text or search text appended with <p> (custom scripts)
    return s?.value?.toLowerCase().startsWith(search) || s?.value?.toLowerCase().startsWith(`<p>${search}`);
  });

  matchingScript = matchingScript ? {...matchingScript} : null;

  return matchingScript;
};
