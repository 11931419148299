// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from '../domain/actions';
import { PageInitializationState } from '../domain/models/enums';
import { PageInitializationItem } from '../domain/models/page-initialization-item';
import { AppState } from '../domain/state';

@Injectable({
  providedIn: 'root'
})
export class PageInitializationHelperService  {
  constructor(
    protected ngEntityStore: Store<AppState>
    ) {
    }

    public updateStatePending(pageInitItem: string){
      this._updatePageInitializationState(pageInitItem, PageInitializationState.Pending);
    }

    public updateStateSuccess(pageInitItem: string){
      this._updatePageInitializationState(pageInitItem, PageInitializationState.Success);
    }

    public updateStateError(pageInitItem: string, error: Error){
      this._updatePageInitializationState(pageInitItem, PageInitializationState.Error, error);
    }

    public updatePageInitializationStateFromPromise<T>(pageInitItem: string, apiCall: Promise<T>){
      apiCall.then(() => {
        this.updateStateSuccess(pageInitItem);
      }).catch((error) => {
        this.updateStateError(pageInitItem, error);
      });
    }

    private _updatePageInitializationState(pageInitItem: string, state: PageInitializationState, error?: Error){
      const updateInitializationState: PageInitializationItem = {
        eventName: pageInitItem,
        state,
        ...(error && {error})
      };
      this.ngEntityStore.dispatch(AppActions.UpdatePageInitialization({ data: updateInitializationState }));
    }
}
