// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ChatRequestApiService } from '../../services/chat-request-api.service';
import { ChatActions, CxGptResponsesAction, UiActions } from '../actions';
import { fromChat, fromCxGptResponses, fromSettings } from '../selectors';
import { AppState } from '../state';
import { FeatureFlags } from '../../constants/featureFlags.constants';
import { LogHelper } from '../../utils/logHelper';
import { LoggingFactoryService, toPayload } from '@cxt-cee-chat/merc-ng-core';
import { ChatOperations } from '../../constants/event-logs.constants';
import { ChatMessageType, SenderType } from '../models/enums';
import { ChatInteraction } from '../models';
import { CxGptResponsesPersisterService } from './cx-gpt-responses-persister.service';
import { CxGptHelper } from '../../utils/cx-gpt-helper';
import { ChatHelper } from '../../utils/chatHelper';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class CxGptResponsesEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: CxGptResponsesPersisterService,
    private chatRequestApiService: ChatRequestApiService,
    private loggingFactory: LoggingFactoryService
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(CxGptResponsesAction.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? CxGptResponsesAction.hydrateSuccess({ state })
          : CxGptResponsesAction.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(() =>
    this.action.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
      ofType(CxGptResponsesAction.hydrateSuccess, CxGptResponsesAction.hydrateFailure),
      switchMap(() => this.store.pipe(select(fromCxGptResponses.selectCxGptResponsesFeature))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(state => {
        this.persisterService.storeState(state);
      })
    ),
    { dispatch: false }
  );

  updateActiveEngagements$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.updateActiveEngagements),
      concatLatestFrom(() =>
        this.store.select(fromChat.selectOrphanedChatUiChatIds)
      ),
      filter(([, chatIds]) => chatIds.length > 0),
      map(([, chatIds]) => {
        return CxGptResponsesAction.chatsRemoved({ chatIds });
      })
    )
  );

  getCxGptResponses$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.NewMessage),  
      map(action => toPayload<ChatInteraction>(action)),   
      concatLatestFrom((message) => [
        this.store.select(fromChat.getChat(message.chatId)),
        this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.CxGpt))       
      ]),
      filter(([message, chat, hasCxGptFeature
      ]) => {
        return message.sender === SenderType.Requester &&
          (message.type === ChatMessageType.Message || message.type === ChatMessageType.TopicSelection)
          && !!chat && hasCxGptFeature;
      }),
      tap(([{ chatId, messageId  }, chat]) => {
        const getCxGptResponsesRequest = CxGptHelper.createRequest(chat);
        this.store.dispatch(CxGptResponsesAction.getCxGptResponses({chatId, responses: null}));
        this.chatRequestApiService.getCxGptResponses(getCxGptResponsesRequest).then((resp) => {
          if (resp.success && resp?.body?.chatMessages?.length > 0){         
            this.store.dispatch(CxGptResponsesAction.updateCxGptResponses({ chatId, messageId, response: resp?.body?.chatMessages[0] }));
            const logDimensions = {
              message: resp?.body?.chatMessages[0]?.text
            };
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.GptResponseOffered, chat, logDimensions);
          }
          else{
            this.store.dispatch(CxGptResponsesAction.getCxGptResponsesFailed({chatId, messageId}));
          }
        }).catch(() => {
          this.store.dispatch(CxGptResponsesAction.getCxGptResponsesFailed({chatId, messageId}));
        });
      })
    ),
    { dispatch: false }
  );

  cxGptResponseEditClicked$ = createEffect(() =>
    this.action.pipe(
      ofType(UiActions.CxGptResponseEditClicked),
      concatLatestFrom(({cxGptResponseInUse}) => [
        this.store.select(fromChat.getChat(cxGptResponseInUse.chatId)),
      ]),      
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.GptResponseSentToChatBox, chat);
      })
    ),
    { dispatch: false }
  );

  updateCxGptResponseFeedback$ = createEffect(() =>
    this.action.pipe(
      ofType(CxGptResponsesAction.updateCxGptResponseFeedback),
      concatLatestFrom(({chatId}) => [
        this.store.select(fromChat.getChat(chatId)),
      ]),      
      tap(([{feedbackData}, chat]) => {
        const logDimensions = {
          messages: feedbackData?.feedback?.message,
          additionalFeedBack: feedbackData?.feedback?.additionalFeedback,
          text: feedbackData?.text
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.GptResponseFeedback, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  getcxGptResponsesFailed$ = createEffect(() =>
    this.action.pipe(
      ofType(CxGptResponsesAction.getCxGptResponsesFailed),
      concatLatestFrom(({ chatId }) => this.store.select(fromChat.getChat(chatId))),
      tap(([{ }, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.GptResponseFailed, chat);
      })
    ),
    { dispatch: false }
  );

  sendCxGptResponse$ = createEffect(() =>
  this.action.pipe(
    ofType(ChatActions.SendCxGptResponse),
    concatLatestFrom(({ chatMessage }) => this.store.select(fromChat.getChat(chatMessage.chatId))),
    tap(([{chatMessage, cxGptResponse }, chat]) => {
      const logDimensions = {
        isModified:  ChatHelper.isTextModified(cxGptResponse?.text, chatMessage.message),
        editedMessage: chatMessage.message,
        originalMessage: cxGptResponse?.text
      };
      LogHelper.logChatEvent(this.loggingFactory, ChatOperations.GptResponseSent, chat, logDimensions);
    })
  ),
  { dispatch: false }
);

  ngrxOnInitEffects(): Action {
    return CxGptResponsesAction.hydrate();
  }

}
