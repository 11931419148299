// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, TokenStoreService, AccessToken, JwtTokenFactoryService, DayTimeService, DateUnitType, IdAccessToken } from '@cxt-cee-chat/merc-ng-core';
import { FixAgentFormAuthApiService } from './fix-agent-form-auth-api.service';
import { HttpResponse } from '@angular/common/http';
import { GetAccesstokenResponse } from '../domain/models/authentication/getAccesstokenResponse';
import { GetExchangeToken } from '../domain/models/requests/getExchangeToken';
import { ComcastSsoAuthService } from './comcast-auth-sso-service';

@Injectable({
  providedIn: 'root'
})
export class ComcastNuanceSsoAuthService extends AuthService {

  protected get tokenStoreKey(): string {
    return 'comcastNuanceToken';
  }

  constructor(tokenStore: TokenStoreService,
              tokenFactory: JwtTokenFactoryService,
              private router: Router,
              private fixAgentAuthApiService: FixAgentFormAuthApiService,
              private timeService: DayTimeService,
              private comcastSsoAuthService: ComcastSsoAuthService,
              @Optional()
              @Inject('renewTokenBeforeInSeconds')
              renewTokenBeforeInSeconds?: number) {
    super(tokenStore, tokenFactory, renewTokenBeforeInSeconds);
  }


  public redirectToAuthenticationProvider(): void {
    this.router.navigate(['']);
  }
  public storeTokenInStore(token: AccessToken): void {
    super.removeTokenFromStore();
    super.storeTokenInStore(token);
  }
  protected handleTokenRenewal(token: AccessToken): Promise<AccessToken> {
    return this.refreshToken(token);
  }

  public refreshToken(_token: AccessToken): Promise<AccessToken> {
    return new Promise<AccessToken>(
      (resolve, reject) => {
        this.comcastSsoAuthService.getToken().then(token => {
          const idToken = token as IdAccessToken;
            const model: GetExchangeToken = {
              accessToken: idToken.accessToken
          };
            this.fixAgentAuthApiService.exchangeToken(model)
            .then(
              (response: HttpResponse<GetAccesstokenResponse>) => {
                if (response?.body?.accessToken){                 
                  resolve(this.CreateAccesstoken(response.body));
                }
                else{
                  reject('Unable to retrieve token');
                }
              },
              error => {
                reject(`Unable to retrieve token: ${error}`);
              }
            );
          })
          .catch(error => {
            reject(`Unable to retrieve token: ${error}`);
          });
        }
    );
  }

  public CreateAccesstoken(nuanceAccessToken: GetAccesstokenResponse) {
    const accessToken = new AccessToken();
    accessToken.accessToken = nuanceAccessToken.accessToken.token;
    const unixExpires = this.timeService.add(+nuanceAccessToken.accessToken.expiresIn, DateUnitType.Seconds);
    accessToken.expiresOn = this.timeService.toDate(unixExpires);
    accessToken.refreshToken = nuanceAccessToken.accessToken.refreshToken;
    accessToken.scope = nuanceAccessToken.accessToken.scope;
    accessToken.advancedAgent = nuanceAccessToken.accessToken.advancedAgent;
    return accessToken;
  }
}
