// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { DecrementingTimerService, IncrementingTimerService } from '@cxt-cee-chat/merc-ng-core';
import { Subscription } from 'rxjs/internal/Subscription';
import { SubscriberService } from 'src/app/subscribed-container';
import { Chat } from './chat';

export class ChatContext extends SubscriberService {
    chat: Chat;
    bounceTimer: IncrementingTimerService;
    chatDurationTimer: IncrementingTimerService;
    agentResponseTimer: IncrementingTimerService;
    autoCloseTimer: IncrementingTimerService;
    customerActivityTimer: DecrementingTimerService;
    engagementInactiveWarningTimer: DecrementingTimerService;
    engagementInactiveWarningCountdownSubscription: Subscription;
    subscriptions: Subscription[] = [];

    dispose(): void {
        this.bounceTimer?.dispose();
        this.chatDurationTimer?.dispose();
        this.agentResponseTimer?.dispose();
        this.autoCloseTimer?.dispose();
        this.customerActivityTimer?.dispose();
        this.engagementInactiveWarningTimer?.dispose();
        this.engagementInactiveWarningCountdownSubscription?.unsubscribe();
        super.dispose();
    }
}
