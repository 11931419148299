// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SecureModel } from './secureModel';

export class Credentials extends SecureModel{
    username: string;
}

export class NuanceCredentials extends SecureModel{
    agentId: string;
    agentUsername: string;
}

export class ScriptGroupCredentials extends NuanceCredentials {
    scriptGroupId: number;
}


