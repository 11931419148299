// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector } from '@ngrx/store';
import { ProfanityList } from './models/profanityList';
import { AppState } from './state';

export const selectProfanityList = createFeatureSelector<
  AppState,
  ProfanityList
>('profanity');
