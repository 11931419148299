// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { AppState, AskMeAnythingActions, Color, FeatureFlags } from 'projects/entities/src/public_api';
import { LoadingState } from 'projects/entities/src/lib/domain/models/enums';
import { AskMeAnythingSearchVm } from 'projects/entities/src/lib/domain/ask-me-anything/ask-me-anything.model';
import { Observable } from 'rxjs';
import { fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { RatingState } from 'projects/entities/src/lib/domain/models/voice/enums';

@Component({
  selector: 'merc-ask-me-anything-content',
  templateUrl: './ask-me-anything-content.component.html',
  styleUrls: ['./ask-me-anything-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskMeAnythingContentComponent implements OnInit {
  @Input() chatId: string;
  @Input() color: Color;
  @Input() search: AskMeAnythingSearchVm;

  public hideAnimation$: Observable<boolean>;
  hasAskMeAnythingRewriteFeatureFlag$: Observable<boolean>;

  loadingState = LoadingState;
  ratingState = RatingState;
  
  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.hideAnimation$ = this.store.select(fromSettings.hideAnimations);
    this.hasAskMeAnythingRewriteFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.AskMeAnythingRewrite));
  }

  onSourceClick(chatId: string, url: string, documentId: string, searchId: string, questionId: string){
    this.store.dispatch(AskMeAnythingActions.logSourceClicked({ chatId, documentId, searchId, questionId }));
    window.open(url);
  }

  onRetryClick(chatId: string, searchId: string) {
    this.store.dispatch(AskMeAnythingActions.retrySearch({ chatId, searchId }));
  }

  onCustomerVersionClick(chatId: string, search: AskMeAnythingSearchVm){
    this.store.dispatch(AskMeAnythingActions.rewriteAnswer({chatId, search}));
  }

  positiveRating(chatId: string, searchId: string) {
    this.store.dispatch(AskMeAnythingActions.rateAnswer({ chatId, searchId, rating: RatingState.Positive }));
  }

  negativeRating(chatId: string, searchId: string) {
    this.store.dispatch(AskMeAnythingActions.rateAnswer({ chatId, searchId, rating: RatingState.Negative }));
  }

}
