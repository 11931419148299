// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ConvoMessageContextMenu,
  AppState,
  ChatActions,
  UiActions,
} from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { ConvoMessageContextMenuService } from './convoMessageContextMenu.service';
import { fromHubs, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { Observable } from 'rxjs';
import { MaskText } from 'projects/entities/src/lib/domain/models';
import { ContextMenuOption } from 'src/app/models/context-menu-option';

@Component({
  selector: 'merc-convo-message-context-menu',
  templateUrl: './convoMessageContextMenu.component.html',
  styleUrls: ['./convoMessageContextMenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoMessageContextMenuComponent implements OnInit {
  copyOptionId: string = 'copy';
  maskOptionId: string = 'mask';

  convoMessageContextMenu$: Observable<ConvoMessageContextMenu>;
  isChatHubConnected$: Observable<boolean>;
  isMaskingMessage$: Observable<boolean>;

  constructor(
    protected ngEntityStore: Store<AppState>,
    protected convoMessageContextMenuService: ConvoMessageContextMenuService
  ) { }

  ngOnInit() {
    this.convoMessageContextMenu$ = this.ngEntityStore.select(fromUi.selectConvoMessageContextMenu);
    this.isChatHubConnected$ = this.ngEntityStore.select(fromHubs.getIsChatRequestHubConnected);
    this.isMaskingMessage$ = this.ngEntityStore.select(fromUi.isMaskingMessage);
  }

  onOptClicked(optionId: string, contextMenu: ConvoMessageContextMenu) {
    const { chatMessage, maskText, selectedText } = contextMenu;

    if (optionId === this.copyOptionId) {
      let copiedText: string;
      if (selectedText.length === 0) {
        copiedText = chatMessage.message;
        navigator.clipboard
          .writeText(copiedText)
          .then()
          .catch(e => console.error(e));
      } else {
        copiedText = selectedText;
        document.execCommand('copy');
      }
      this.ngEntityStore.dispatch(
        ChatActions.CopyText(copiedText)
      );
    }
    if (optionId === this.maskOptionId) {
      this.onMask(maskText);
    }
  }

  private onMask(maskText: MaskText) {
    this.ngEntityStore.dispatch(
      ChatActions.MaskText({maskText})
    );
  }

  onMenuChange(open: boolean) {
    const convoContextMenu = new ConvoMessageContextMenu();
    convoContextMenu.open = open;
    this.ngEntityStore.dispatch(UiActions.ToggleMessageContextMenu({convoContextMenu}));
  }

  getOptions(contextMenu: ConvoMessageContextMenu, isChatHubConnected: boolean, isMaskingMessage: boolean): ContextMenuOption[] {
    const { isSelectionFullyMasked, selectedText } = contextMenu ?? {};
    const options = [
      {
        id: this.copyOptionId,
        displayText:
          `<span style="padding: 0 4px">
            <svg class="cee-icon cee-icon--secondary" >
              <use xlink:href="/assets/img/svg-sprite.svg#icon-copy"></use>
            </svg>
          </span> Copy`
      }
    ];

    if (isSelectionFullyMasked || !selectedText || !isChatHubConnected || isMaskingMessage) {
      return options;
    }

    const isTranslatedMessage = contextMenu.maskText?.translatedMaskedChunks?.length;
    const translateMaskText = isTranslatedMessage ? 'this entire message<br>' : '';
    // 'Mask' option should only show if there is selected text &
    // the selected text is not fully contained by an existing masked section
    options.push({
      id: this.maskOptionId,
      displayText:
        `<span style="padding: 0 4px">
          <svg class="cee-icon cee-icon--secondary" >
            <use xlink:href="/assets/img/svg-sprite.svg#icon-mask"></use>
          </svg>
        </span> Mask ${translateMaskText}as personal information`
    });

    return options;
  }
}
