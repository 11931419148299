// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatInteractionRequest } from './chat-interact-request';

export class GetAccessToken extends ChatInteractionRequest {
    userName: string;
    password: string;
    accessToken: string;
}