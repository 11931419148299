// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorMessages } from 'projects/entities/src/lib/constants/error-messages';
import { ManagedScript, ScriptGroup } from 'projects/entities/src/lib/domain/scripts';
import { AppState, ProfanityService } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { fromCustomResponses, ScriptGroupActions } from 'projects/entities/src/lib/domain/scripts';
import { defaultCustomScriptTreeId } from 'projects/entities/src/lib/domain/scripts/custom-responses.constants';
import { ScriptsService } from 'projects/entities/src/lib/services/scripts.service';
import { takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-script-header-editor',
  templateUrl: './script-header-editor.component.html',
  styleUrls: ['./script-header-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScriptHeaderEditorComponent extends SubscriberComponent implements OnInit {
  currentlyManagedScriptGroup$: Observable<ManagedScript>;
  selectScriptGroupToEdit$: Observable<ScriptGroup>;
  selectCustomScriptGroups$: Observable<ScriptGroup[]>;
  textInput: string = '';
  errorMessage: string = null;
  isSaveDisabled: boolean = true;
  @Output() cancelEdit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private store: Store<AppState>,
    private profanityService: ProfanityService,
    private scriptsService: ScriptsService
  ) { 
    super();
  }

  ngOnInit() {
    this.clearEditor();
    this.selectCustomScriptGroups$ = this.store.select(fromCustomResponses.selectCustomScriptGroups);
    this.currentlyManagedScriptGroup$ = this.store.select(fromCustomResponses.selectCurrentManagedScriptGroup);
    this.selectScriptGroupToEdit$ = this.store.select(fromCustomResponses.selectScriptGroupToEdit);

    this.currentlyManagedScriptGroup$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((scriptGroup: ManagedScript) => {
        if (!scriptGroup || !(scriptGroup?.status === 'CREATE' || scriptGroup?.status === 'EDIT')) {
          this.clearEditor();
        }
      });

    this.selectScriptGroupToEdit$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((scriptGroup: ScriptGroup) => {
        this.textInput = scriptGroup?.name ?? '';
      });
  }

  onInputChange(scriptGroup: ScriptGroup, editorChangeEvent: string) {
    this.errorMessage = '';
    this.textInput = editorChangeEvent ? editorChangeEvent : null;
    this.isSaveDisabled = !this.textInput || this.textInput === scriptGroup?.name;
  }

  onSave(managedScriptGroup: ManagedScript, allScriptGroups: ScriptGroup[]) {
    const nameExists = allScriptGroups.reduce((acc, s) => {
      return s.name.toLowerCase() === this.textInput.toLowerCase() || acc;
    }, false);

    if (nameExists) {
      this.errorMessage = 'This header name already exists.';
      this.isSaveDisabled = true;
      return;
    }

    const profanity = this.profanityService.test(this.textInput);
    if (profanity.length) {
      this.errorMessage = ErrorMessages.profanityCheckFailedOnSave;
      this.isSaveDisabled = true;
      return;
    }

    switch (managedScriptGroup.status) {
      case 'CREATE':
        const id = this.scriptsService.generateCustomScriptGroupId(defaultCustomScriptTreeId);
        const newScriptGroup: ScriptGroup = {
          id: id,
          scriptCollectionId: id,
          name: this.textInput,
          scriptGroups: [],
          scripts: [],
          scriptTreeId: defaultCustomScriptTreeId,
        };
        this.store.dispatch(ScriptGroupActions.addScriptGroup({ 
            scriptGroup: newScriptGroup, 
            parentScriptGroupId: defaultCustomScriptTreeId }));
        break;
      case 'EDIT':
        this.store.dispatch(ScriptGroupActions.updateScriptGroupName({ 
          id: managedScriptGroup.id, 
          name: this.textInput }));
        break;
    }
    this.clearEditor();
  }

  backToManagePopup() {
    this.clearEditor();
    this.cancelEdit.emit();
  }

  clearEditor() {
    this.textInput = '';
    this.errorMessage = '';
    this.isSaveDisabled = true;
  }
}
