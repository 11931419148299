// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { TimeFormats, DateFormats, ScriptQuickSendType } from './enums';

export class AgentCustomSettings {
    showPageNavigation: boolean;
    animationsOff: boolean;
    hideCustomerActions: boolean;
    theme: string;
    timeFormat: TimeFormats;
    dateFormat: DateFormats;
    desktopNotifications: boolean;
    soundMuteAll: boolean;
    soundVolume: number;
    soundNewConversation: boolean;
    soundNewMessageCurrent: boolean;
    soundNewMessageOther: boolean;
    soundOutgoingMessage: boolean;
    soundConversationEnds: boolean;
    scriptQuickSend: ScriptQuickSendType;
    lastSurveyCompleteDate?: number;
    extraChats: boolean;
    maxChats: number;
    maxExtraSlots: number;
    scriptAutocomplete: boolean;
    translateMessages: boolean;
    lastQualityCheckDateTime?: string;
    newFeatureBannerAckTimestamp?: string;
    soundCustomerAwaitingReply: boolean;
}
