// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MercuryRoutes } from 'src/app/constants/constants';
import { AgentStatePersisterService, AppActions, AppState, AuthenticationProviderTypes } from '../../public_api';
import { ComcastNuanceSsoAuthService } from '../services/comcast-nuance-auth-sso-service';
import { FixAgentFormAuthApiService } from '../services/fix-agent-form-auth-api.service';
import { LoginBanners } from '../domain/models/enums';
import { AuthenticationResult } from '@azure/msal-browser';
import { GetExchangeToken } from '../domain/models/requests/getExchangeToken';
import { ComcastSsoAuthService } from '../services/comcast-auth-sso-service';
import { GetAccesstokenResponse } from '../domain/models/authentication/getAccesstokenResponse';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '@cxt-cee-chat/merc-ng-core';

@Injectable({
    providedIn: 'root'
})
export class ComcastNuanceAuthGuard implements CanActivate {

    constructor(private msalGuard: MsalGuard,
        private msalService: MsalService,
        private authApiService: FixAgentFormAuthApiService,
        private statePersister: AgentStatePersisterService,
        private comcastSsoService: ComcastNuanceSsoAuthService,
        private store: Store<AppState>,            
        private router: Router,
        private comcastSsoAuthService: ComcastSsoAuthService,
        @Inject(AuthService) protected authService: AuthService,   
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {        
        return new Promise((resolve, reject) => this.authService.isAuthenticated
        .then(async (authenticated: boolean) => {
            if (authenticated) {
                resolve(true);
            }
            else {                               
                const auth = await this.msalGuard.canActivate(next, state).toPromise();
                // Retrieve the id token
                if (auth) {
                    const token = await this.GetUserToken();
                    if (token){                        
                        resolve(this.ProcessAccesstoken(token));
                    }
                    else{
                        resolve(this.RedirectToLogin());
                    }
                }
                else{            
                    resolve(this.RedirectToLogin());
                }
        }})
        .catch(err => reject(err)));
    }

    private async ProcessAccesstoken(response: AuthenticationResult): Promise<boolean> {
        this.store.dispatch(AppActions.InitializeService());
        this.statePersister.storeAuthenticationProvider(AuthenticationProviderTypes.ComcastNuanceSso);
        this.comcastSsoAuthService.StoreIdTokenInStore(response);
        return this.ExchangeAccessToken(response.accessToken);       
    }

    private ExchangeAccessToken(token: string): Promise<boolean> {       
        const model: GetExchangeToken = {
            accessToken: token
        };
        //call auth api to get nuance token
        return this.authApiService.exchangeToken(model)
            .then(
            (reponse: HttpResponse<GetAccesstokenResponse>) => {  
                if (reponse?.body?.accessToken) {
                    const accessToken = this.comcastSsoService.CreateAccesstoken(reponse.body);
                    this.comcastSsoService.storeTokenInStore(accessToken);
                    return true;
                }                
                else {
                    return this.RedirectToLogin();                   
                }
            })
            .catch(() => {
                return this.RedirectToLogin();
            });
    }

    private async GetUserToken(): Promise<AuthenticationResult> {
        const account = this.msalService.instance.getAllAccounts()[0];
        if (account){
            const silentRequest = {
            account: account,
            scopes: [`${environment.auth.comcastAadSso.clientId}/.default`]
            };
            return await this.msalService.acquireTokenSilent(silentRequest).toPromise();
        }
        return null;
    }

    private RedirectToLogin(): boolean {        
        this.router.navigate([MercuryRoutes.SsoLogin], { queryParams: { bannerReason: LoginBanners.AuthFailure } });      
        return false;
    }
}
