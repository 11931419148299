// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiResponses } from '../constants/cti.constants';
import { CtiChatDialogWsResponseData } from './cti-chat-dialog-ws-response-data';
import { CtiRequest } from './cti-request';

export class CtiChatDialogWsResponse extends CtiRequest {
  messageName: string = CtiResponses.ChatDialogWSResponse;
  action: string;
  status: string;
  requestId: string;
  uui: string;
  account: string;

  public constructor (data: CtiChatDialogWsResponseData) {
      super(data.sessionId);
      this.action = data.action;
      this.status = data.status;
      this.requestId = data.requestId;
      this.uui = data.uui;
      this.account = data.account;
 }
}
