// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState, ScriptActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { Script } from 'projects/entities/src/lib/domain/scripts/';
import * as fromScripts from 'projects/entities/src/lib/domain/scripts/scripts-selectors';

@Component({
  selector: 'merc-convo-script-confirm-delete-modal',
  templateUrl: './script-confirm-delete-modal.component.html',
  styleUrls: ['./script-confirm-delete-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptConfirmDeleteModalComponent implements OnInit {
  public script$: Observable<Script>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.script$ = this.store.select(fromScripts.selectScriptToDelete);
  }

  closeModal(): void {
    this.store.dispatch(ScriptActions.deleteScriptConfirmationDismiss());
  }

  public deleteScript(script: Script): void {
    const { id, scriptGroupId } = script;
    this.store.dispatch(ScriptActions.deleteScript({ id, scriptGroupId }));
  }
}
