// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatDialogAppUpdateOptions, CtiRequests } from 'src/app/constants/cti.constants';
import { CtiRequest } from 'src/app/models/cti-request';

export class CtiLaunchItgRequest extends CtiRequest {
    action: string;
    updateApplication: string;
    datastring: string;
    requestId: string;
    uui: string;
    account: string;
    messageName: string = CtiRequests.ChatDialogAppUpdate;
    
    public constructor (sessionId: string, datastring: string, requestId: string, uui: string, account: string) {
        super(sessionId);
        this.action = ChatDialogAppUpdateOptions.Actions.LaunchItg;
        this.updateApplication = ChatDialogAppUpdateOptions.Applications.Einstein360;
        this.datastring = datastring;
        this.requestId = requestId;
        this.uui = uui;
        this.account = account;
   }
}