// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export abstract class StatePersister<T> {
  private readonly storage: Storage;
  private readonly storageKey: string;

  constructor(storage: Storage, storageKey: string) {
    this.storage = storage;
    this.storageKey = storageKey;
  }

  /**
   * Override to modify the state after it is loaded from storage
   * @param state the stored state
   * @returns the modified state
   */
  afterGet?(state: T): T;

  storeState(state: T) {
    this.storage.setItem(this.storageKey, JSON.stringify(state));
  }

  removeState() {
    this.storage.removeItem(this.storageKey);
  }

  getState(): T {
    const state: T = this.getFromStorage();

    try {
      return this.afterGet ? this.afterGet(state) : state;
    }
    catch {
      return null;
    }
  }

  private getFromStorage(): T {
    const storageValue = this.storage.getItem(this.storageKey);
    if (storageValue) {
      try {
        const state = JSON.parse(storageValue);
        return state;
      } catch {
        this.removeState();
      }
    }
    return null;
  }
}
