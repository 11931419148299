// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlaceholderEditorState, PlaceholderEditorType } from 'projects/entities/src/lib/domain/models/enums';
import { AppState, PlaceholdersActions } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-edit-placeholder-tooltip',
  templateUrl: './edit-placeholder-tooltip.component.html',
  styleUrls: ['./edit-placeholder-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPlaceholderTooltipComponent {
  @Input() chatId: string;
  @Input() placeholderKeys: string[];
  @Input() disabled: boolean = false;

  constructor(
    private store: Store<AppState>
  ) { }

  editPlaceholder(): void{
    const { chatId, placeholderKeys } = this;
    this.store.dispatch(PlaceholdersActions.openEditPlaceholderPanel({
      editorState: PlaceholderEditorState.EditPlaceholder,
      chatId,
      placeholderKeys,
      editorType: PlaceholderEditorType.SmartResponse
    }));
  }
}
