<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
#transferModal
[active]="active"
[controls]="controls$ | async"
[headerText]="'Transfer session'"
(modalClose)="closeModal()"
class="merc-transfer-modal">
<div *ngIf="{
  transferFailed: (transferFailed$ | async),
  chatSummary: chatSummary$ | async,
  chatSummaryPayload: chatSummaryPayload$ | async
  } as data">
  <div class="cee-form-row">
    <cee-banner
    type="error"
    [dismissable]="false"
    *ngIf="data.transferFailed && !selectedBUValue; else noBusinessUnitsBanner"
    >
      Chat transfer failed. No agents available in selected queue.
    </cee-banner>
  </div>
  <ng-template #noBusinessUnitsBanner>
    <cee-banner
    class="merc-no-transfer-units-banner"
    [dismissable]="false"
    dataQa="no-business-units-banner"
    *ngIf="this.disableTransferFields && !(isRefreshingBusinessUnits$ | async)">
      Transfer options are temporarily unavailable due to no agent availability
    </cee-banner>
  </ng-template>
  <merc-customer-info-heading></merc-customer-info-heading>
  <div class="cee-form-row" *ngIf="(businessUnits$ | async) as businessUnits ; else noBusinessUnits" data-qa="business-units">
    <cee-select [ngClass]="{'merc-transfer-select-field--disabled': this.disableTransferFields}" label="BUSINESS UNITS" (change)="selectedBusinessUnit($event, data.transferFailed)" [selectedOption]="businessUnitId"
      [options]="businessUnits" [disabled]="this.disableTransferFields" dataQa="transfer-queues-dropdown">
    </cee-select>
  </div>
  <ng-template #noBusinessUnits>
    <span class="cee-form-label">No queues available for transfer</span>
  </ng-template>
  <div class="cee-form-row" *ngIf="!this.disableTransferFields && {queues: (queues$ | async)} as queueData">
    <cee-select
      [ngClass]="{'merc-transfer-select-field--disabled': (this.disableTransferFields || isDropdownFieldDisabled(queueData.queues))}"
      label="AGENT GROUPS"
      (change)="selectedQueue($event)"
      [options]="queueData.queues"
      [disabled]="isDropdownFieldDisabled(queueData.queues)"
      [selectedOption]="!queueData.queues ? '' : queueId"
    >
    </cee-select>
  </div>
  <div class="cee-form-row" *ngIf="(isTransferToAgentFlagEnabled$ | async) && !this.disableTransferFields && {availableAgents: (availableAgents$ | async)} as agentData">
    <div class="cee-form-label">
      <span>Transfer to Agent </span> <i class="merc-secondary-label-text">(Optional)</i>
    </div>
   <cee-typeahead-search 
    [ngClass]="{'merc-transfer-select-field--disabled': !agentData?.availableAgents?.length}"
    shouldClearInvalidInput="true"
    placeholder="No Agent Selected" [options]="agentData?.availableAgents" [disabled]="!agentData?.availableAgents?.length"
    (inputChange)="selectedAgent($event)"></cee-typeahead-search>
  </div>
  
  <div class="cee-form-row" *ngIf="selectedBUValue && !this.disableTransferFields && {availableAgents: (availableAgents$ | async)} as agentData">
   <cee-button type="link"  customClass="merc-clear-button" (buttonClick)="clearSearch()">Clear Selections</cee-button>
  </div>

  <!-- requirement that this text area is shown on hide summary -->
  <ng-container *ngIf="(isChatConversationSummaryFlagEnabled$ | async) || (isHideSummaryFlagEnabled$ | async); else legacyTextarea">
    <div x-test-target="conversation-summary-notes">
      <cee-textarea 
      name="transferNotesText" 
      label="NOTES" 
      [placeholder]="this.disableTransferFields ? 'Unavailable' : data.chatSummary?.isLoading ? 'Loading...' : 'Notes'" 
      [loading]="this.disableTransferFields ? false : data.chatSummary?.isLoading" 
      (textChange)="onTextFieldChange('notes', $event)" 
      [textInput]="this.disableTransferFields ? '' : data.chatSummaryPayload" 
      [disabledFull]="this.disableTransferFields"></cee-textarea>
    </div>
    <div class="merc-end-session-summary--footer"
      *ngIf="data.chatSummary && !data.chatSummary.isLoading">
      <div class="merc-end-session-summary--footer-rating">
        <div class="merc-end-session-summary--footer-rating-feedback" *ngIf="data.chatSummary.rated; else rateSummary">
          <span *ngIf="data.chatSummary.isPositiveRating">Thanks for your feedback!</span>
          <span *ngIf="!data.chatSummary.isPositiveRating">Your feedback helps up improve. Please edit the summary before saving in Einstein.</span>
        </div>
        <ng-template #rateSummary>
          <cee-thumb-rating ratingLabel="Is this summary accurate?"
            (positiveRating)="positiveRating()"
            (negativeRating)="negativeRating()"
          >
          </cee-thumb-rating>
        </ng-template>
      </div>
      <div class="merc-end-session--item">
        <cee-button-v2 class="merc-tool-tip" type="link" [tooltipText]="copySummaryTooltipText" tooltipId="copy-summary"
          tooltipDirection="bottom" tooltipAppearanceDelayMs="0" tooltipPersistDirective="true"
          (buttonClick)="copyToClipboard()"
          (mouseout)="resetCopyText()">
          <svg class="cee-icon cee-icon--md">
            <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy'"></use>
          </svg> <span>Copy to Clipboard</span>
        </cee-button-v2>
      </div>
    </div>
  </ng-container>
  <ng-template #legacyTextarea>
    <div class="cee-form-row cee-notes-wrapper">
      <div class="cee-notes" [ngClass]="{'cee-notes--disabled': this.disableTransferFields}">
        <label class="cee-form-label" for="transferNotesText">NOTES</label>
        <textarea
        id="transferNotesText"
        class="cee-form-input"
        [placeholder]="this.disableTransferFields ? 'Unavailable' : ''"
        [disabled]="this.disableTransferFields"
        [(ngModel)]="transferNotes"
        data-qa="transfer-notes"
        onInput="this.parentNode.dataset.replicatedValue = this.value"></textarea>
      </div>
    </div>
  </ng-template>
</div>
<div footerExtra *ngIf="this.disableTransferFields">
  <cee-button type="link" customClass="merc-refresh-transfer-button" (buttonClick)="refreshBusinessUnits()" *ngIf="!(isRefreshingBusinessUnits$ | async); else refreshingIndicator" dataQa="refresh-transfer-options">
    <svg class="cee-icon" viewBox="0 0 16 16">
      <use href="/assets/img/svg-sprite.svg#icon-refresh"></use>
    </svg>
    Refresh
  </cee-button>
  <ng-template #refreshingIndicator>
    <div class="merc-refreshing-transfers">
      <cee-refreshing-indicator size="20" [hideAnimation]="(hideAnimations$ | async)"></cee-refreshing-indicator>
      <span class="merc-refreshing-transfers-text" data-qa="refreshing-transfer-options">Refreshing...</span>
    </div>
  </ng-template>
</div>
</cee-modal>

