// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { UserRole } from '../enums';

export class AccountUsersResponse {
    accountUsers: AccountUser[];
    isValidAccount: boolean;
    chatId: string;
    accountNumber: string;
}

export class AccountUser {
    firstName: string;
    lastName: string;
    displayName: string;
    custGuid: string;
    userId: string;
    roles: UserRole[];
}
