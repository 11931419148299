// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ScriptType } from 'projects/entities/src/public_api';

export interface ScriptTree {
  id: string;
  name: string;
  type: ScriptType;
  scriptGroups: string[];
}

export function generateMockScriptTree(): ScriptTree {
  return {
    id: 'scriptTree1',
    name: 'scriptGroupName',
    type: ScriptType.Custom,
    scriptGroups: []
  };
}
