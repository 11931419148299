// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'merc-convo-header-base',
  templateUrl: './convo-header-base.component.html',
  styleUrls: ['./convo-header-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoHeaderBaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
