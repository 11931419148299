// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgCoreModule } from '@cxt-cee-chat/merc-ng-core';
import { MercPatternLibModule } from '@cxt-cee-chat/merc-pattern-lib';
import { RunnerAppEffects_Chat } from '../effects/chat.effects';
import { RunnerAppEffects_Availability } from '../effects/availability.effects';
import { RunnerAppEffects_Auth } from '../effects/auth.effects';
import { RunnerAppEffects_App } from '../effects/app.effects';
import { AppRoutingModule } from '../app-routing.module';
import { RunnerAppEffects_Hubs } from '../effects/hubs.effects';
import { NgEntityModule } from 'projects/entities/src/public_api';
import { MsalModule } from '@azure/msal-angular';
import { QuillModule } from 'ngx-quill';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment, initialState } from 'src/environments/environment';
import { reducer } from 'projects/entities/src/lib/domain/reducer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RunnerAppEffects_Agent } from '../effects/agent.effects';
import { RunnerAppEffects_OneCti } from '../effects/one-cti.effects';

const enableStrickRuntimeChecks = !environment.production;

export const APP_MODULE_IMPORTS = [
    NgCoreModule,
    NgEntityModule.provideModule(),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    StoreModule.forRoot(reducer, {
        initialState,
        // see https://ngrx.io/guide/store/configuration/runtime-checks
        runtimeChecks: {
          strictStateImmutability: enableStrickRuntimeChecks,
          strictActionImmutability: enableStrickRuntimeChecks,
          strictActionTypeUniqueness: enableStrickRuntimeChecks,
        },
    }),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
        RunnerAppEffects_App,
        RunnerAppEffects_Chat,
        RunnerAppEffects_Availability,
        RunnerAppEffects_Auth,
        RunnerAppEffects_Hubs,
        RunnerAppEffects_Agent,
        RunnerAppEffects_OneCti
    ]),
    MercPatternLibModule,
    AppRoutingModule,
    MsalModule,
    QuillModule.forRoot()
];
