// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ChatHelper } from 'projects/entities/src/public_api';
import { EntityStatePersister } from '../../services/entity-state-persister';
import {
  Chat,
  ChatMessageType
} from '../models';
import { AgentChatMessage } from '../models/agent-chat-message';
import * as fromChat from './chat.reducer';
import { EnhancedChatMessageData } from '../models/enhancedChatMessageData';

@Injectable({
  providedIn: 'root'
})
export class ChatPersisterService extends EntityStatePersister<Chat> {
  constructor(@Inject(CHAT_STORAGE_INJECTION_TOKEN) localForage: LocalForage) {
    super(localForage, fromChat.adapter);
  }

  static createLocalForageInstance(
    localforage: LocalForage,
    defaultOptions: LocalForageOptions
  ): LocalForage {
    return localforage.createInstance({
      storeName: fromChat.featureKey,
      ...defaultOptions
    });
  }

  beforeStore(chat: Chat): Chat {
    const newChat = Object.assign({}, chat);
    newChat.messages = newChat.messages.filter(
      message =>
        ChatHelper.isCustomerWindowActionMessage(message) ||
        message.type === ChatMessageType.SummaryCard ||
        ChatHelper.isMaskedMessage(message) ||
        message.type === ChatMessageType.ItgAction ||
        !ChatHelper.isMessageSent((<AgentChatMessage>message).status) ||
        message.type === ChatMessageType.TranslationConfig
    );
    newChat.engagementHistory = [];
    newChat.asyncEngagements = [];
    newChat.enhancedChatMessageData = new EnhancedChatMessageData();

    // oneCti will be disconnected upon refresh;
    newChat.oneCtiConnected = false;

    return newChat;
  }
}

export const CHAT_STORAGE_INJECTION_TOKEN = new InjectionToken(
  'chatStorageLocalForage'
);
