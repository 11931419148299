// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  Component,
  ChangeDetectionStrategy,  
  ViewEncapsulation,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggingFactoryService, ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AppState,
  LogHelper, UiActions, AgentOperations
} from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { fromAgent, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { PreviousChatSummary } from 'projects/entities/src/lib/domain/models/previousChatSummary';

@Component({
  selector: 'merc-previous-summary-modal',
  templateUrl: './previous-summary-modal.component.html',
  styleUrls: ['./previous-summary-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviousSummaryModalComponent extends SubscriberComponent implements OnInit {
  
  active$: Observable<boolean>;
  chatSummary$: Observable<PreviousChatSummary>;
  copySummaryTooltipText: string = '';
  copyAccountNumberTooltipText: string = '';
  copyChatIdTooltipText: string = '';

  constructor(
    private store: Store<AppState>,
    private loggingFactory: LoggingFactoryService,
    private clipboardService: ClipboardService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.active$ = this.store.select(fromUi.isPreviousChatSummaryModalOpen);
    this.chatSummary$ = this.store.select(fromAgent.getSelectedChatSummary);    
  }

  closeModal(): void {
    this.store.dispatch(UiActions.UpdatePreviousChatSummaryModal({chatId: null, isOpen: false} ));
  }

  copyToClipboard(text: string, fieldType: string, chatId: string): void{
    const copied: string = 'Copied!';
    switch (fieldType) {
      case 'chatId':
        this.copyChatIdTooltipText = copied;
        break;
      case 'accountNumber':
        this.copyAccountNumberTooltipText = copied;
        break;
      case 'notes':
        text = `Notes: ${text}`;
        this.copySummaryTooltipText = copied;        
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.PreviousChatsummaryCopied, { chatId });
        break;     
    }   
    this.clipboardService.copyTo(text); 
  }
  
  resetCopyText(): void{
    this.copySummaryTooltipText = '';
    this.copyAccountNumberTooltipText = '';
    this.copyChatIdTooltipText = '';
  }
}
