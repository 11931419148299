<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="image$ | async as image">
    <cee-modal [active]="image" class="image-modal" (modalClose)="modalClose()">
        <div class="image-modal-controls">
            <cee-button [type]="'link'">
                <a [href]="image.retrievalUrl" target="_blank">
                    <svg class="cee-icon cee-icon--secondary image-modal-link-icon">
                        <use xlink:href="/assets/img/svg-sprite.svg#icon-link-out"></use>
                    </svg>
                    Open in new tab
                </a>
            </cee-button>
        </div>
        <div class="image-modal-fill" [style.background-image]="'url(' + image.retrievalUrl + ')'" ></div>
    </cee-modal>
</ng-container>
