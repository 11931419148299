// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StringHelper } from 'projects/entities/src/public_api';
import { Script } from './script.model';
import { fromScriptsSearch } from './selectors';

@Injectable({
  providedIn: 'root'
})
export class ScriptsHelper {
  constructor() {}

  public getHighlightedScriptValue(searchTerm: string, script: Script): string {
    // if searching apply highlight
    if (fromScriptsSearch.isSearchTermValid(searchTerm)) {
      return StringHelper.replaceAll(
        script.value,
        searchTerm,
        '<span class="script-highlight">$1</span>',
        true
      );
    }
    // otherwise just return the value
    return script.value;
  }
}
