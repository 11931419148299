// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { ConvoMessageComponent } from '../convoMessage/convoMessage.component';
import { MaskingService, TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { ChannelType, ChatMessageType, SenderType,
  ChatTranscriptInteraction, PriorEngagementParticipant, ChatMessage, ChatImageMessage,
  ConvoMessageContextMenu, AppState, UiActions, ChatUiActions, FeatureFlags, ChatActions, SendChatMessage, ChatHelper, Channel, HtmlHelperService, AskMeAnythingActions } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { Guid } from 'guid-typescript';
import { ErrorMessages } from 'projects/entities/src/lib/constants/error-messages';
import { AgentChatMessage } from 'projects/entities/src/lib/domain/models/agent-chat-message';
import { ChatMessageStatus, DetailsSelectedTab } from 'projects/entities/src/lib/domain/models/enums';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ConvoMessageService } from '../convoMessage/convoMessage.service';
import { ConvoMessageContextMenuService } from '../convoMessageContextMenu/convoMessageContextMenu.service';
import { Chunk } from 'highlight-words-core';
import { HighlightWordsUtils } from 'projects/entities/src/lib/utils/highlight-words-utils';
import { CustomerDetails } from 'projects/entities/src/lib/domain/models/customerDetails';

@Component({
  selector: 'merc-convo-participant-message',
  templateUrl: './convoParticipantMessage.component.html',
  styleUrls: ['./convoParticipantMessage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class ConvoParticipantMessageComponent extends ConvoMessageComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() isMessageSelected: boolean = false;
  @Input() canSelectMessage: boolean = false;
  @Input() dataQa: string;
  @Input() customerDetails: CustomerDetails;

  displayName: string = '';
  senderType: string = '';
  messageText: string = '';
  isSamePrevSender: boolean = false;
  isImage: boolean = false;
  isReported: boolean = false;
  imageUrl: string = '';
  channel$: Observable<string>;
  isXA: boolean = false;
  showContextMenu: boolean = false;
  hasAmaFeatureFlag$: Observable<boolean>;
  isPinned$: Observable<boolean>;
  hasPinFeatureFlag$: Observable<boolean>;
  hideAnimations$: Observable<boolean>;
  showPin: boolean = false;
  failedStateMessage: string = ErrorMessages.sendMessageFailed;
  chatMessageStatus: ChatMessageStatus;
  tabSelected$: Observable<string>;
  translatedMessageText$: Observable<string>;
  isTranslatedChat$: Observable<boolean>;
  hasLanguageTranslationError$: Observable<boolean>;
  languageTooltipText$: Observable<string>;
  isMessageFromPreviousEngagement$: Observable<boolean>;

  constructor(
    timeHelper: TimeHelperService,
    store: Store<AppState>,
    private htmlHelper: HtmlHelperService,
    private maskingService: MaskingService,
    protected convoMessageContextMenuService: ConvoMessageContextMenuService,
    private convoMessageService: ConvoMessageService){
    super(timeHelper, store);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.chatMessage) {
      return;
    }

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'chatMessage': {
            this.messageText = this.getMessageText();
            this.isImage = this.isImageType(this.chatMessage.type);
            this.isReported = this.reported(this.isImage);
            this.imageUrl = this.retrievalUrl(this.isImage);
            this.chatMessageStatus = this.getMessageStatus();
            this.showPin = this.getShowPin();
            this.isXA = this.chatMessage.sender === SenderType.XA;
            this.senderType = this.getSenderType();
            break;
          }
          case 'participants': 
          case 'customerDetails': {
            this.displayName = this.getDisplayName();
            break;
          }
          case 'previousMessage': {
            this.isSamePrevSender = this.samePrevSender();
            break;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.hasPinFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.MessagePinning));
    this.hasAmaFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.AskMeAnything));
    this.hideAnimations$ = this.store.select(fromSettings.hideAnimations);
    this.tabSelected$ = this.store.select(fromChat.getDetailsPanelTabSelected);
    const { chatId } = this;
    
    this.isTranslatedChat$ = this.store.select(fromChat.isTranslatedChat(chatId));
    this.isMessageFromPreviousEngagement$ = this.store.select(fromChat.isMessageFromPreviousEngagement(chatId, this.chatMessage.timestamp));
    this.hasLanguageTranslationError$ = this.store.select(fromChat.getTranslatedMessageHasError(chatId, this.chatMessage.traceId, this.chatMessage.messageId));
    this.translatedMessageText$ = this.store.select(fromChat.getTranslatedMessageText(chatId, this.chatMessage.traceId, this.chatMessage.messageId));
    this.languageTooltipText$ = this.store.select(fromChat.getTranslateTooltipChainText(chatId, this.chatMessage.traceId, this.chatMessage.messageId, this.isStacked(), this.chatMessage.timestamp));
    this.channel$ = this.store.select(fromChat.getChatChannel(chatId))
      .pipe(
        map((channel) => {
          return this.getChannelName(channel);
        }),
        distinctUntilChanged()
    );

    this.isPinned$ = this.store.select(fromChat.getPinnedMessageIds(chatId))
      .pipe(
        map((pinnedMessageIds: string[]) => {
          return ChatHelper.isMessagePinned(pinnedMessageIds, this.chatMessage.messageId);
        }),
        distinctUntilChanged()
    );
  }

  getShowPin(): boolean {
    const status = (this.chatMessage as AgentChatMessage).status;
    return ChatHelper.showMessagePin(this.chatId, this.chatMessage.chatId, status);
  }

  private getChannelName(channel: Channel): string {
    switch (channel?.type) {
      default: return 'web';
      case ChannelType.Sms: return 'sms';
    }
  }

  private getSenderType(): string {
    switch (this.chatMessage.sender){
      case SenderType.Requester:
        return 'customer';
      case SenderType.XA:
        return 'xa';
      case SenderType.Mercury:
        return 'mercury';
      case SenderType.FixAgent:
      case SenderType.System:
      default:
        return 'agent';
    }
  }

  private getDisplayName(): string {
    const message = this.chatMessage as ChatTranscriptInteraction;
    if (message.senderId && this.chatMessage.sender !== SenderType.Requester) { // messages that have come from a transcript on the backend
      let participant: PriorEngagementParticipant;
      let participantName: string;

      participant = this.participants.find(p => ((<PriorEngagementParticipant>p).id === message.senderId)) as PriorEngagementParticipant;
      participantName = participant ? participant.displayName : '';

      return participantName;
    }
    else if (this.chatMessage.sender === SenderType.Requester){ //current customer
      const custName = ChatHelper.getSelectedChatCustomerName(this.participants, this.customerDetails);
      return custName ? custName : 'Customer';
    }
    else {
      return ''; //current agent does not need to return display name to chat messages
    }

  }

  private samePrevSender(): boolean{
    const { previousMessage  } = this;
    if (!previousMessage || previousMessage.queue) {
      return false;
    }

    return ChatHelper.isTimestampStackedMessage(this.chatMessage, this.previousMessage.sender, this.previousMessage.timestamp);
  }

  private getMaskedChunks(useTranslatedMaskedChunks: boolean): Chunk[] {
    if (this.isMessage(this.chatMessage.type) || !this.chatMessage.type){
      const message = <ChatMessage>this.chatMessage;
      return useTranslatedMaskedChunks ? message.translatedMaskedChunks : message.maskedChunks;
    }
    return [];
  }

  private getMessageText(): string {
    //default if not typed its a message
    if (this.isMessage(this.chatMessage.type) || !this.chatMessage.type) {
      const chatMessage = <ChatMessage>this.chatMessage;
      const messageText = chatMessage.maskedText || chatMessage.message;
      if (!chatMessage.maskedChunks) {
        return messageText;
      }
      // unescaped since the chunks are applied on the unescaped text when masking the text
      const message = this.htmlHelper.UnescapeHtml(messageText);
      return this.maskText(message);
    }
    return '';
  }

  private retrievalUrl(isImage: boolean): string{
    if (isImage){
      return(<ChatImageMessage>this.chatMessage).retrievalUrl;
    }
    return '';
  }

  private reported(isImage: boolean): boolean{
    if (isImage){
      return(<ChatImageMessage>this.chatMessage).reported;
    }
    return false;
  }

  private getMessageStatus(): ChatMessageStatus {
    return (<AgentChatMessage>this.chatMessage).status;
  }

  onImageClicked(){
    this.store.dispatch(UiActions.ImageClicked(<ChatImageMessage>this.chatMessage));
  }
  onReportClicked(){
  }

  maskText(messageText: string, useTranslatedMaskedChunks?: boolean) {
    const chunks: Chunk[] = this.getMaskedChunks(useTranslatedMaskedChunks);
    return HighlightWordsUtils.wrapHighlights(messageText, chunks, this.htmlHelper.EscapeHtml);
  }

  onRightClick($event: PointerEvent, isTranslatedChat: boolean, translatedMessageFromSelector: string) {
    const target = <HTMLElement>$event.target;
    if (this.senderType === 'customer' &&
      (target.classList.contains('cee-masked')
      || target.classList.contains('cee-chat-message-bubble'))) {
      $event.preventDefault();
      const { clientX, clientY } = $event;
      const chatMessage = <ChatMessage>this.chatMessage;
      const { maskedChunks, translatedMaskedChunks } = chatMessage;
      const messageElement = target.closest('.cee-chat-message-bubble');

      const translatedMessage = isTranslatedChat ? chatMessage.translationData?.translatedMessage ?? translatedMessageFromSelector : null;
      const shouldHandleTranslatedMessage = isTranslatedChat && !!translatedMessage;

      const selection = this.convoMessageContextMenuService.getMessageElementSelection(messageElement);

      const maskText = this.convoMessageContextMenuService.getMaskTextActionPayload(chatMessage, messageElement, selection, shouldHandleTranslatedMessage ? translatedMessage : null);
      
      //If message is translated mask it entirely to be safe until a more optimal solution is found
      const convoContextMenu: ConvoMessageContextMenu = {
        selectedText: shouldHandleTranslatedMessage ? chatMessage.message : selection.toString(),
        maskText,
        clientX,
        clientY,
        open: true,
        chatMessage,
        isSelectionFullyMasked: shouldHandleTranslatedMessage ? !!translatedMaskedChunks?.length : this.isSelectionFullyMasked(selection, maskedChunks)
      };
      this.store.dispatch(UiActions.ToggleMessageContextMenu({convoContextMenu}));
    }
  }

  getMessageDisplayText(originalText: string, translatedMessageText: string, isTranslatedChat: boolean, hasLanguageTranslationError: boolean) {
    //In the case of async messages
    const translatedText = translatedMessageText ?? (<ChatMessage>this.chatMessage).translationData?.translatedMessage;
    
    const useTranslatedText = ChatHelper.shouldUseTranslatedText(isTranslatedChat, this.chatMessage.sender, translatedText, hasLanguageTranslationError);
    if (!useTranslatedText) {
      return originalText;
    }
    
    if (!(<ChatMessage>this.chatMessage).maskedChunks) {
      return translatedMessageText;
    }
    // unescaped since the chunks are applied on the unescaped text when masking the text
    const translatedMessage = this.htmlHelper.UnescapeHtml(translatedMessageText);
    return this.maskText(translatedMessage, true);
  }

  //Remove once XA translates customer messages
  getLanguageTooltipText(languageTooltipChainText: string, translatedMessageText: string, isTranslatedChat: boolean, isAsyncTranslationMessage: boolean): string {
    if (!isTranslatedChat) {
      return null;
    }
    
    if (isAsyncTranslationMessage) {
      const chatMessage = <ChatMessage>this.chatMessage;
      //translationData.translation message is provided here so the selector does not have to search through async engagement interactions
      const translatedText = chatMessage?.translationData?.translatedMessage ?? translatedMessageText;
      if (!translatedText) {
        return null;
      }
      
      const isCustomerMsg = this.getSenderType() === 'customer';
      return isCustomerMsg ? chatMessage.message : translatedText;
    }
    
    return languageTooltipChainText;
  }

  private isStacked(): boolean {
    return this.type === 'simple' || this.isSamePrevSender;
  }

  private isSelectionFullyMasked(selection: Selection, chunks: Chunk[]): boolean {
    if (chunks?.length === 1 && chunks[0].highlight) {
      return true; // the entire message is already masked
    }

    if (!selection?.anchorNode?.parentElement) {
      return false;
    }

    // the selection begins and ends in the same node and it is masked
    return selection.anchorNode.parentElement.classList.contains('cee-masked') &&
      selection.anchorNode.textContent === selection.focusNode.textContent;
  }

  isNotPreviousAsyncTranslatedMessage(isTranslatedChat: boolean, isMessageFromPreviousEngagement: boolean, backupTranslatedMessage: string): boolean {
    const chatMsg = <ChatMessage>this.chatMessage;
    return Boolean(!isTranslatedChat || !isMessageFromPreviousEngagement || (!chatMsg?.translationData?.translatedMessage && !backupTranslatedMessage));
  }

  togglePin(isPinned: boolean) {
    const {chatId, messageId} = this.chatMessage;
    if (isPinned) {
      this.store.dispatch(ChatUiActions.pinMessage({chatId, messageId, source: this.getSenderType()}));
    } else {
      this.store.dispatch(ChatUiActions.unpinMessage({chatId, messageId, source: 'message'}));
    }
  }

  sendAma(tabSelected: DetailsSelectedTab) {
    const {chatId} = this.chatMessage;
    const escapedText = this.htmlHelper.UnescapeHtml(this.messageText);
    const question = this.maskingService.maskPersonalInfo(escapedText);

    this.store.dispatch(
      AskMeAnythingActions.search({
        chatId,
        searchId: Guid.raw(),
        question,
        isCustomerQuestion: true,
        knowledgeFocused: (tabSelected !== DetailsSelectedTab.Knowledge)
      })
    );
  }
  
  getFailureMessage(hasTranslationError: boolean) {
    if (hasTranslationError){
      return ErrorMessages.customerTranslationFailed;
    }
    return this.failedStateMessage;
  }


  getChatMessageErrorText(hasTranslationError: boolean) {
    if (hasTranslationError && this.chatMessage.sender === SenderType.Requester){
      return ErrorMessages.customerTranslationFailed;
    }
    else if (hasTranslationError) {
      return ErrorMessages.failedTranslateMessage;
    }
    return undefined;
  }

  getChatMessageRetryText(hasTranslationError: boolean) {
    if (hasTranslationError && this.chatMessage.sender === SenderType.Requester){
      return 'Attempting to translate message...';
    }
    return undefined;
  }

  selectMessage() {
    const isValidMessageType = !this.isXA;
    this.convoMessageService.selectMessage(this.canSelectMessage, this.isMessageSelected, this.chatId, this.chatMessage, isValidMessageType);
  }

  retryButton(hasTranslationError: boolean): void {
    if (hasTranslationError && this.chatMessage.sender === SenderType.Requester){
      this.retryTranslation();
    }
    else{
      this.retrySend();
    }
  }

  retrySend(): void {
    const chatMessage = this.chatMessage as SendChatMessage;
    this.store.dispatch(ChatActions.retrySendMessage({ chatMessage, newTraceId: Guid.raw() }));
  }

  retryTranslation(): void {
    const chatMessage = this.chatMessage as ChatMessage;
    this.store.dispatch(ChatActions.retryTranslateMessage({ chatMessage }));
  }

  deleteMessage(): void {
    const chatMessage = this.chatMessage as ChatMessage;
    this.store.dispatch(ChatActions.deleteChatMessage({ chatMessage }));
  }

  private isMessage(type: ChatMessageType): boolean{
    return type === ChatMessageType.Message;
  }

  private isImageType(type: ChatMessageType): boolean{
    switch (type){
      case ChatMessageType.Image:
        return true;
      case ChatMessageType.MediaCard:
        return true;
      default:
        return false;
    }
  }
}
