// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ChatRequestApiService } from '../../services/chat-request-api.service';
import { SmartResponsesHelper } from '../../utils/smart-responses-helper';
import { ChatActions, LogActions, SmartResponsesActions, UiActions } from '../actions';
import { GetSmartResponses } from '../models/requests/get-smart-responses';
import { fromAgent, fromChat, fromPlaceholders, fromSettings, fromSmartResponses, fromUi } from '../selectors';
import { AppState } from '../state';
import { SmartResponsesPersisterService } from './smart-responses-persister.service';
import { PlaceholderHelper } from '../../utils/placeholder-helper';
import { FeatureFlags } from '../../constants/featureFlags.constants';
import { LogHelper } from '../../utils/logHelper';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { ChatOperations, DebugEvents } from '../../constants/event-logs.constants';
import { SmartResponseDialog } from '../models/smart-response-dialog';
import { SmartResponseDialogLog } from '../models/logDimensions/smart-response-dialog-log';
import { SmartResponsesConstants } from '../../constants/smart-responses.constants';
import { SendSmartResponseLog, SmartResponseLog } from '../models/logDimensions/send-smart-response-log';
import { SmartResponseType } from '../models/enums';
import { SmartResponseVm } from '../models/smart-response-vm';
import { UserIdentityService } from '../../services/user-identity.service';
import { LogInfo, LogType } from '../models/LogTypeInterfaces';
import { SmartResponseToSendVm } from '../models/smart-response-to-send-vm';
import { Chat } from '../models';
import { AgentGroup } from '../models/agentGroup';
import { BusinessUnit } from '../models/business-unit';
import { SmartResponsesSearchVm } from '../models/smart-responses-search-vm';
import { MaskingService } from '@cxt-cee-chat/merc-ng-core';
import { SmartResponseNoResultsLog } from '../models/logDimensions/smart-response-no-results-log';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class SmartResponsesEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: SmartResponsesPersisterService,
    private chatRequestApiService: ChatRequestApiService,
    private loggingFactory: LoggingFactoryService,
    private userIdentityService: UserIdentityService,
    private maskingService: MaskingService
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(SmartResponsesActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? SmartResponsesActions.hydrateSuccess({ state })
          : SmartResponsesActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(() =>
    this.action.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
      ofType(SmartResponsesActions.hydrateSuccess, SmartResponsesActions.hydrateFailure),
      switchMap(() => this.store.pipe(select(fromSmartResponses.selectSmartResponsesFeature))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(state => {
        this.persisterService.storeState(state);
      })
    ),
    { dispatch: false }
  );

  updateActiveEngagements$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.updateActiveEngagements),
      concatLatestFrom(() =>
        this.store.select(fromChat.selectOrphanedChatUiChatIds)
      ),
      filter(([, chatIds]) => chatIds.length > 0),
      map(([, chatIds]) => {
        return SmartResponsesActions.chatsRemoved({ chatIds });
      })
    )
  );

  selectedMessageIdUpdated$ = createEffect(() =>
    this.store.select(fromChat.getSelectedChatSelectedMessageId).pipe(
      concatLatestFrom(() => [
        this.store.select(fromChat.getSelectedChatId),
        this.store.select(fromChat.getSelectedChatSelectedMessage),
        this.store.select(fromSmartResponses.getSmartResponsesForSelectedChatMessage),
        this.store.select(fromSettings.showSmartResponses),
        this.store.select(fromChat.getIsSelectedChatItgInProgress)
      ]),
      filter(([, , selectedMessage, smartResponses, hasSmartResponseFeature, isItgInProgress]) =>
        hasSmartResponseFeature
        && selectedMessage?.canSearchSmartResponses
        && !selectedMessage?.isHistoric
        // checks if the messageId does not have smartResponses yet
        && !(smartResponses?.smartResponses?.length > 0)
        // cannot run if itg is in progress
        && !isItgInProgress
      ),
      map(([messageId, chatId]) => {
        return SmartResponsesActions.getSmartResponses({messageId, chatId});
      })
    )
  );

  getSmartResponses$ = createEffect(() =>
    this.action.pipe(
      ofType(SmartResponsesActions.getSmartResponses),
      concatLatestFrom(({ chatId }) => [
        this.store.select(fromChat.getChat(chatId)),
        this.store.select(fromSettings.getSmartResponseSourceResultCount),
        this.store.select(fromPlaceholders.getAllPlaceholdersForChatId(chatId)),
        this.store.select(fromSettings.getDetailsPanelDefaultTab),
        this.store.select(fromChat.getSmartResponseFeedback(chatId)),
        this.store.select(fromAgent.getAgentGroups),
        this.store.select(fromAgent.getBusinessUnits),
        this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator))
      ]),
      filter(([, chat]) => Boolean(chat)),
      tap(([{ chatId, messageId }, chat, maxResponsesPerSource, placeholders, detailsPanelDefaultTab, feedback, agentGroups, businessUnits, hasLanguageTranslatorFeatureFlag]) => {
        const dialog = SmartResponsesHelper.getSmartResponseDialogData(chat, messageId, this.maskingService, hasLanguageTranslatorFeatureFlag);
        const namedEntities = PlaceholderHelper.getNamedEntities(placeholders);
        const getSmartResponses: GetSmartResponses = {
          subject: chat.contactReason,
          dialog,
          maxResponsesPerSource,
          customerGuid: chat.authenticated ? chat.customerGuid : null,
          namedEntities,
          agentId: this.userIdentityService.username,
          businessUnitId: businessUnits?.find(f => f.id === chat.businessUnitId)?.name,
          agentGroupId: agentGroups?.find(f => f.id === chat.agentGroupId)?.name,
          feedback
        };
        if (chat.authenticated && chat.accountNumber){
          getSmartResponses.accountNumber = chat.accountNumber;
        }
        this.chatRequestApiService.getSmartResponses(getSmartResponses).then((response) => {
          if (response.success){
            this.store.dispatch(SmartResponsesActions.updateSmartResponses({chatId, messageId, response: response.body, detailsPanelDefaultTab}));
          }
          else{
            this.store.dispatch(SmartResponsesActions.getSmartResponsesFailed({chatId, messageId}));
          }
        }).catch(() => {
          this.store.dispatch(SmartResponsesActions.getSmartResponsesFailed({chatId, messageId}));
        });
      })
    ),
    { dispatch: false }
  );

  getSmartResponsesFailed$ = createEffect(() =>
    this.action.pipe(
      ofType(SmartResponsesActions.getSmartResponsesFailed),
      concatLatestFrom(({ chatId }) => this.store.select(fromChat.getChat(chatId))),
      tap(([{ }, chat]) => {
        const logDimensions: SmartResponseNoResultsLog = {
          isError: true   
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SmartResponsesNoResults, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logSmartResponsesNoResults$ = createEffect(() =>
    this.action.pipe(
      ofType(SmartResponsesActions.updateSmartResponses),
      concatLatestFrom(({ chatId, messageId }) => [
        this.store.select(fromChat.getChat(chatId)),
        this.store.select(fromSmartResponses.getSmartResponsesWithKey(chatId, messageId))
      ]),
      tap(([{ }, chat, smartResponses]) => {
        if (!smartResponses?.smartResponses?.length){
          const logDimensions: SmartResponseNoResultsLog = {
            isError: false
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SmartResponsesNoResults, chat, logDimensions);
        }
      })
    ),
    { dispatch: false }
  );

  smartResponseClicked$ = createEffect(() =>
    this.action.pipe(
      ofType(UiActions.SmartResponseClicked),
      concatLatestFrom(() => this.store.select(fromChat.getSelectedChatTextEditorContent)),
      map(([, content]) => {
        return UiActions.UpdateSmartResponseFilterText({content});
      }        
    )
  ));  
  
  logSendSmartResponse$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.SendSmartResponse),
      concatLatestFrom(({ chatMessage, smartResponseToSend }) => [
        this.store.select(fromChat.getChat(chatMessage.chatId)),
        this.store.select(fromSmartResponses.getSmartResponsesWithUpdatedPlaceholdersByMessageId(smartResponseToSend.parentMessageId)),
        this.store.select(fromUi.getClickedSmartResponse),
        this.store.select(fromAgent.getAgentGroups),
        this.store.select(fromAgent.getBusinessUnits),
        this.store.select(fromSettings.getSmartResponseSourceResultCount),
        this.store.select(fromUi.getSmartResponseFilterText),
        this.store.select(fromChat.getDisplayedFilteredSmartResponses),
        this.store.select(fromChat.getDisplayedSmartResponses),
        this.store.select(fromUi.getFilteredSmartResponsesByFilteredText),
        this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.SmartResponsesAppliedAi)),
        this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator))
      ]),
      filter(([, chat]) => Boolean(chat)),
      tap(([{ chatMessage, smartResponseToSend }, chat, smartResponses, clickedSmartResponse, agentGroups, businessUnits, smartResponseDisplayCount, smartResponseFilterText, displayedFilteredSmartResponses, displayedSmartResponses, filteredSmartResponses, showSmartResponseAppliedAi, hasLanguageTranslatorFeatureFlag]) => {
        const {value, type, confidence, modelId} = smartResponseToSend;
        const clickedSmartResponseVm = clickedSmartResponse?.smartResponse;
        const smartResponsesForMessageId = clickedSmartResponse?.smartResponsesDisplayed;
        const smartResponsesWithoutSent = smartResponsesForMessageId?.filter(sr => sr !== clickedSmartResponseVm);
        const otherResponses = this.translateSmartResponsesForLog(smartResponsesWithoutSent) ?? [];
        if (!showSmartResponseAppliedAi){
          this.updateSmartResponseFeedback(chat, agentGroups, smartResponseToSend, businessUnits, chatMessage.message, smartResponses, smartResponseDisplayCount, displayedSmartResponses, displayedFilteredSmartResponses, filteredSmartResponses, smartResponseFilterText, hasLanguageTranslatorFeatureFlag);
        }

        const logDimensions: SendSmartResponseLog = {
          sentMessage: chatMessage.message,
          suggestedMessage: value,
          source: SmartResponseType[type],
          confidence,
          otherResponses,
          modelId
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendSmartResponse, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  sendMessageUpdateSmartResponseFeedback$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.SendMessage),
      concatLatestFrom(({ chatMessage }) => [
        this.store.select(fromChat.getChat(chatMessage.chatId)), 
        this.store.select(fromSmartResponses.getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage),        
        this.store.select(fromAgent.getAgentGroups),
        this.store.select(fromAgent.getBusinessUnits),
        this.store.select(fromSettings.getSmartResponseSourceResultCount),
        this.store.select(fromChat.getDisplayedFilteredSmartResponses),
        this.store.select(fromChat.getDisplayedSmartResponses),
        this.store.select(fromUi.getFilteredSmartResponsesByFilteredText),        
        this.store.select(fromChat.getSelectedMessageId(chatMessage.chatId)),
        this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.SmartResponsesAppliedAi)),
        this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator))
      ]),
      filter(([, chat, , , , , , , , , showSmartResponseAppliedAi]) => Boolean(chat) && !showSmartResponseAppliedAi),
      tap(([{ chatMessage }, chat, smartResponses, agentGroups, businessUnits, smartResponseDisplayCount, displayedFilteredSmartResponses, displayedSmartResponses, filteredSmartResponses, selectedMessageId, hasLanguageTranslatorFeatureFlag]) => {
        this.updateSmartResponseFeedbackNoSmartResponseUsed(chat, agentGroups, selectedMessageId, businessUnits, chatMessage.message, smartResponses, smartResponseDisplayCount, displayedSmartResponses, displayedFilteredSmartResponses, filteredSmartResponses, hasLanguageTranslatorFeatureFlag);
      })
    ),
    { dispatch: false }
  );

  sendSuggestionUpdateSmartResponseFeedback$ = createEffect(() =>
  this.action.pipe(
    ofType(ChatActions.SendSuggestion),
    concatLatestFrom(({ payload }) => [
      this.store.select(fromChat.getChat(payload.chatId)), 
      this.store.select(fromSmartResponses.getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage),             
      this.store.select(fromAgent.getAgentGroups),
      this.store.select(fromAgent.getBusinessUnits),
      this.store.select(fromSettings.getSmartResponseSourceResultCount),
      this.store.select(fromChat.getDisplayedFilteredSmartResponses),
      this.store.select(fromChat.getDisplayedSmartResponses),
      this.store.select(fromUi.getFilteredSmartResponsesByFilteredText),        
      this.store.select(fromChat.getSelectedMessageId(payload.chatId)),
      this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LanguageTranslator))
    ]),
    filter(([, chat]) => Boolean(chat)),
    tap(([{ payload }, chat, smartResponses, agentGroups, businessUnits, smartResponseDisplayCount, displayedFilteredSmartResponses, displayedSmartResponses, filteredSmartResponses, selectedMessageId, hasLanguageTranslatorFeatureFlag]) => {
      this.updateSmartResponseFeedbackNoSmartResponseUsed(chat, agentGroups, selectedMessageId, businessUnits, payload?.body?.message, smartResponses, smartResponseDisplayCount, displayedSmartResponses, displayedFilteredSmartResponses, filteredSmartResponses, hasLanguageTranslatorFeatureFlag);
    })
  ),
  { dispatch: false }
);

  logDebugSmartResponseFeedback$ = createEffect(() =>
  this.action.pipe(
    ofType(SmartResponsesActions.getSmartResponses),
    concatLatestFrom(({ chatId }) => [
      this.store.select(fromChat.getChat(chatId)),
      this.store.select(fromChat.getSmartResponseFeedback(chatId))
    ]),
    filter(([, chat, feedback]) => Boolean(chat) && Boolean(feedback)),
    tap(([, , feedback]) => {        
        const logPayload: LogInfo = {
          logType: LogType.debug,
          operationName: DebugEvents.SmartResponseFeedback,
          data: { feedback }
        };
        this.store.dispatch(LogActions.logDebug({logPayload}));
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return SmartResponsesActions.hydrate();
  }

  translateDialogForLog(dialog: SmartResponseDialog[]): SmartResponseDialogLog[]{
    return dialog.map(d => {
      return {
        message: d.utterance,
        sender: d.speakerId === SmartResponsesConstants.speakerId.customer ? 'customer' : 'agent'
      };
    });
  }

  translateSmartResponsesForLog(smartResponses: SmartResponseVm[]): SmartResponseLog[]{
    return smartResponses?.map(s => {
      const { value, confidence, type } = s;
      return {
        message: value,
        confidence,
        source: SmartResponseType[type]
      };
    });
  }

  updateSmartResponseFeedbackNoSmartResponseUsed(chat: Chat, agentGroups: AgentGroup[], selectedMessageId: string, businessUnits: BusinessUnit[], message: string, smartResponses: SmartResponsesSearchVm, smartResponseDisplayCount: number, displayedSmartResponses: string[], displayedFilteredSmartResponses: string[], filteredSmartResponses: SmartResponseVm[], hasLanguageTranslatorFeatureFlag: boolean){
    const smartResponseToSend: SmartResponseToSendVm = {        
      parentMessageId: selectedMessageId,
      answerType: null,
      category: null,
      confidence: null,
      id: null,
      type: null,
      value: null,
      modelId : smartResponses?.smartResponses?.length > 0 ? smartResponses?.smartResponses[0].modelId : null,
      smartResponsesDisplayed: null         
    };
    this.updateSmartResponseFeedback(chat, agentGroups, smartResponseToSend, businessUnits, message, smartResponses, smartResponseDisplayCount, displayedSmartResponses, displayedFilteredSmartResponses, filteredSmartResponses, message, hasLanguageTranslatorFeatureFlag);
  }
  updateSmartResponseFeedback(chat: Chat, agentGroups: AgentGroup[], smartResponseToSend: SmartResponseToSendVm, businessUnits: BusinessUnit[], message: string, smartResponses: SmartResponsesSearchVm, smartResponseDisplayCount: number, displayedSmartResponses: string[], displayedFilteredSmartResponses: string[], filteredSmartResponses: SmartResponseVm[], smartResponseFilterText: string, hasLanguageTranslatorFeatureFlag: boolean){
    const agentGroupName = agentGroups?.find(f => f.id === chat.agentGroupId)?.name;
    const businessUnitName = businessUnits?.find(f => f.id === chat.businessUnitId)?.name;
    const feedback = SmartResponsesHelper.getSmartResponsesFeedback(chat, this.userIdentityService?.username , agentGroupName, businessUnitName, message, smartResponseToSend, smartResponses, smartResponseFilterText, smartResponseDisplayCount, displayedSmartResponses, displayedFilteredSmartResponses, filteredSmartResponses, this.maskingService, hasLanguageTranslatorFeatureFlag);
    this.store.dispatch(ChatActions.UpdateSmartResponseFeedBack({chatId : chat.chatId, feedback}));
  }
}
