// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { DetailsSelectedTab, LoadingState } from '../domain/models/enums';
import { RecommendedTabBannerVm } from '../domain/models/recommended-tab-banner-vm';
import { SmartResponses } from '../domain/smart-responses/smart-responses.model';
import { BannerTypes } from './enums';

export class RecommendedTabHelper {
  public static getBannerVm(isItgInProgress: boolean, smartResponses: SmartResponses): RecommendedTabBannerVm {
    if (isItgInProgress){
      const disabledVm: RecommendedTabBannerVm = {
        visible: true,
        showTryAgain: false,
        text: 'Recommended responses disabled while ITG is in progress',
        type: BannerTypes.Info
      };
      return disabledVm;
    }
    if (smartResponses?.state === LoadingState.Failed){
      const failedVm: RecommendedTabBannerVm = {
        visible: true,
        showTryAgain: true,
        text: 'Could not load recommendations',
        type: BannerTypes.Error
      };
      return failedVm;
    }
    const noBanner: RecommendedTabBannerVm = {
      visible: false,
      showTryAgain: false,
      text: '',
      type: BannerTypes.Info
    };
    return noBanner;
  }

  public static isSelectedTabOnRecommended(selectedTab: string, defaultTab: string): boolean {
    return selectedTab
      ? selectedTab === DetailsSelectedTab.Recommended
      : defaultTab === DetailsSelectedTab.Recommended;
  }
}
