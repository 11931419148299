<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-script-search-banner"
  *ngIf="!hasCustomScriptGroups && searchSystemScriptsCount === 0">
  <cee-banner [type]="'error'">
    No Responses found.
    <button
      class="cee-btn cee-btn--link"
      (click)="clearSearch()">Clear Search</button>
  </cee-banner>
</div>
<div class="merc-script-search-banner"
  *ngIf="!showSystemScripts && searchSystemScriptsCount > 0">
  <cee-banner [type]="'warning'">
    <span *ngIf="searchSystemScriptsCount === 1">1 result is hidden.</span>
    <span *ngIf="searchSystemScriptsCount > 1">{{ searchSystemScriptsCount }} results are hidden.</span>
    <br>
    <button
      class="cee-btn cee-btn--link"
      style="padding:2px 0"
      (click)="onSystemScriptChange(true)">Show hidden results</button>
  </cee-banner>
</div>
