// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HtmlHelperService {
  constructor() { }

  MoveCursorToEndOfEditableDiv(elem: HTMLDivElement) {
    let range: Range;
    let selection: Selection;

    range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(elem); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
  }


  /**
   * converts characters that are not allowed in HTML into character-entity equivalents
   * @param text
   * @returns encoded string
   */
  EscapeHtml(text: string): string {
    const element = document.createElement('p');
    element.textContent = text;
    return element.innerHTML;
  }
  /**
   * converts HTML character-entities to their equivalents
   * @param text
   * @returns decoded string
   */
  UnescapeHtml(text: string): string {
    const element = document.createElement('p');
    element.innerHTML = text;
    return element.innerText;
  }
}
