// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { ItgActionType, ItgState } from 'projects/entities/src/lib/domain/models/enums';
import { ItgAction } from 'projects/entities/src/lib/domain/models/itgAction';
import { ItgMetadata } from 'projects/entities/src/lib/domain/models/itgMetadata';
import { ItgSuggestion } from 'projects/entities/src/lib/domain/models/itgSuggestion';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { ItgHelper } from 'projects/entities/src/lib/utils/itg-helper';
import { AppState, ChatUiActions, FeatureFlags, TimeZone, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'merc-convo-activities',
  templateUrl: './convo-activities.component.html',
  styleUrls: ['./convo-activities.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoActivitiesComponent implements OnInit {
  suggestedITGs$: Observable<ItgSuggestion[]>;
  inProgressITGs$: Observable<ItgMetadata[]>;
  activeITGs$: Observable<ItgMetadata>;
  xaSolvesFeatureFlag$: Observable<boolean>;
  selectedChatId$: Observable<string>;
  chatTimeZone$: Observable<TimeZone>;

  itgState = ItgState;

  @Input() chatId: string;

  constructor(
    private store: Store<AppState>,
    private timeService: DayTimeService
  ) { }

  ngOnInit() {
    this.suggestedITGs$ = this.store.select(fromChat.getSelectedChatItgsNotInProgress).pipe(distinctUntilChanged());
    this.inProgressITGs$ = this.store.select(fromChat.getSelectedChatItgsInProgress).pipe(distinctUntilChanged());
    this.activeITGs$ = this.store.select(fromChat.getSelectedChatItgRunningOrPaused).pipe(distinctUntilChanged());
    this.xaSolvesFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.XaSolves));
    this.selectedChatId$ = this.store.select(fromChat.getSelectedChatId).pipe(distinctUntilChanged());
    this.chatTimeZone$ = this.store.select(fromChat.getSelectedChatTimeZone);
  }

  startITG(itgSuggestion: ItgSuggestion, activeItg: ItgMetadata) {
    if (activeItg) {
      this.store.dispatch(UiActions.ToggleItgModal({
        toggleItgModal: true,
        action: ItgActionType.Start,
        suggestedItg: itgSuggestion
      }));
    } else {
      const payload = {
        itgSuggestion: itgSuggestion,
        chatId: this.chatId,
        timestamp: this.timeService.unix(),
        source: 'activities'
      };

      this.store.dispatch(ChatUiActions.startItg(payload));
    }

  }

  getTimestamp(actions: ItgAction[]): number {
    if (!actions?.length) { return null; }
    return actions[actions.length - 1].timestamp;
  }

  isErrorState(itgState: ItgState): boolean{
    return ItgHelper.isErrorState(itgState);
  }
}
