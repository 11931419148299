// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HubConnectionState } from '@cxt-cee-chat/merc-ng-core';
import { createReducer, on } from '@ngrx/store';
import { HubNames } from '../constants/hub-names';
import { HubsActions } from './actions';
import { HubConnectionData } from './models/hub-connection-data';
import { HubEchoData } from './models/hub-echo-data';

export const hubsFeatureKey = 'hubs';

export interface State {
  hubsInitialized: boolean;
  initializingHubs: boolean;
  chatRequestHubConnection: HubConnectionState;
  voiceHubConnection: HubConnectionState;
  niagaraLogHubConnection: HubConnectionState;
  disconnectedHubs: string[];
  echos: HubEchoData[];
  chatHubLastReconnecting: HubConnectionData;
  showConnectionLostModal: boolean;
}

export const initialState: State = {
  hubsInitialized: false,
  initializingHubs: false,
  chatRequestHubConnection: HubConnectionState.Disconnected,
  voiceHubConnection: HubConnectionState.Disconnected,
  niagaraLogHubConnection: HubConnectionState.Disconnected,
  disconnectedHubs: [],
  echos: [],
  chatHubLastReconnecting: { timestamp: 0 },
  showConnectionLostModal: false
};

export const reducer = createReducer<State>(
  initialState,
  on(
    HubsActions.initializeHubs,
    (state): State => ({
      ...state,
      initializingHubs: true
    })
  ),
  on(
    HubsActions.hubsInitialized,
    (state): State => ({
      ...state,
      hubsInitialized: true,
      initializingHubs: false
    })
  ),
  on(
    HubsActions.connectChatRequestHub,
    (state): State => ({
      ...state,
      chatRequestHubConnection: HubConnectionState.Connecting
    })
  ),
  on(
    HubsActions.connectVoiceHub,
    (state): State => ({
      ...state,
      voiceHubConnection: HubConnectionState.Connecting
    })
  ),
  on(
    HubsActions.connectNiagaraLogHub,
    (state): State => ({
      ...state,
      niagaraLogHubConnection: HubConnectionState.Connecting
    })
  ),
  on(
    HubsActions.echoCompleted,
    (state, { echo }): State => ({
      ...state,
      echos: [...state.echos, echo]
    })
  ),
  on(
    HubsActions.chatRequestHubStateUpdated,
    (state, { connectionState, error, timestamp }): State => {
      let { disconnectedHubs, chatHubLastReconnecting, showConnectionLostModal } = state;

      switch (connectionState) {
        case HubConnectionState.Reconnecting: {
          const { name, message, stack } = error ?? {}; // Error may not be serializable, so getting the properties
          chatHubLastReconnecting = { error: { name, message, stack }, timestamp };
          disconnectedHubs = disconnectedHubs.concat(HubNames.FixAgentChatRequestHub);
          break;
        }
        case HubConnectionState.Reconnected:
        case HubConnectionState.Connected: {
          disconnectedHubs = disconnectedHubs.filter(h => h !== HubNames.FixAgentChatRequestHub);
          showConnectionLostModal = false;
          break;
        }
      }

      return {
        ...state,
        chatRequestHubConnection: connectionState,
        disconnectedHubs,
        chatHubLastReconnecting,
        showConnectionLostModal
      };
    }
  ),
  on(
    HubsActions.voiceHubStateUpdated,
    (state, { connectionState }): State => ({
      ...state,
      voiceHubConnection: connectionState
    })
  ),
  on(
    HubsActions.niagaraLogHubStateUpdated,
    (state, { connectionState }): State => ({
      ...state,
      niagaraLogHubConnection: connectionState
    })
  ),
  on(
    HubsActions.showConnectionLostModal,
    (state): State => ({
      ...state,
      showConnectionLostModal: Boolean(state.disconnectedHubs.length)
    })
  ),
);
