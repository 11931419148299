// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { ArrayEnumeratorService } from '@cxt-cee-chat/merc-ng-core';
import { TrackColorUse } from '../domain/models/trackColorUse';
import { Color } from '../domain/models/color';

export const COLOR_ENUMERATOR_SERVICE_COLORS_INJECTION_TOKEN: string = 'CES_COLORS_INJECTION_TOKEN';

@Injectable({
  providedIn: 'root'
})
export class ColorEnumeratorService extends ArrayEnumeratorService<Color> {
  private _trackedColors: TrackColorUse[];

  constructor(@Inject(COLOR_ENUMERATOR_SERVICE_COLORS_INJECTION_TOKEN) colors: Color[])
  {
    super(colors);
    this._trackedColors = this._getColorTrackingArray();
  }

  public next(): Color
  {
    // get the first color with the least useCount
    // return that color and increase the useCount
    const min = this._findMinUsed(this._trackedColors);
    const minUsedColor = this._trackedColors.find(el => el.useCount === min);
    const color = minUsedColor.color;
    this.setColorInUse(color);
    return color;
  }

  public releaseColor(toRelease: Color): void {
    //decrease useCount and move to end
    const index = this._trackedColors.findIndex(el => el.color.id === toRelease.id);
    const colorReleased = this._trackedColors.splice(index, 1)[0];
    colorReleased.useCount = colorReleased.useCount - 1;
    this._trackedColors.push(colorReleased);
  }

  public setColorInUse(inUse: Color): void {
    //increase useCount
    const colorToUpdate = this._trackedColors.find(el => el.color.id === inUse.id);
    colorToUpdate.useCount = colorToUpdate.useCount + 1;
  }

  private _getColorTrackingArray(): TrackColorUse[] {
    return this.values
    .map(color => {
      const trackColor = new TrackColorUse();
      trackColor.color = color;
      trackColor.useCount = 0;
      return trackColor;
    });
  }

  private _findMinUsed(trackingArray: TrackColorUse[]): number{
    if (trackingArray.length <= 0){
      return 0;
    }
    let min = trackingArray[0].useCount;
    for (let i = 1; i < trackingArray.length; i++){
      min = (trackingArray[i].useCount < min) ? trackingArray[i].useCount : min;
    }
    return min;
  }
}
