// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgentSummaryHeaders, AgentSummarySubHeaders } from 'projects/entities/src/lib/domain/models/enums';
import { FormattedAgentSummaryAgentGroupResponse, FormattedAgentSummaryResponse } from 'projects/entities/src/lib/domain/models/responses/get-agent-availability-summary-response';
import { GetAgentSalesConversionMetricsResponse } from 'projects/entities/src/lib/domain/models/responses/get-agent-sales-conversion-metrics-response';
import { fromAgent, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState, FeatureFlags, hasFeatureFlag } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-agent-summary',
  templateUrl: './agent-summary.component.html',
  styleUrls: ['./agent-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentSummaryComponent implements OnInit {
  agentSummaryOpen$: Observable<boolean>;
  headers: AgentSummaryHeaders[];
  subheaders: AgentSummarySubHeaders[];
  groups: FormattedAgentSummaryResponse[];
  tableData$: Observable<FormattedAgentSummaryAgentGroupResponse[]>;
  hasAgentSalesConversionStats$: Observable<boolean>;
  salesConversionStats$: Observable<GetAgentSalesConversionMetricsResponse>;

  constructor(
    private ngEntityStore: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.agentSummaryOpen$ = this.ngEntityStore.select(fromUi.getAgentSummaryOpen);
    this.tableData$ = this.ngEntityStore.select(fromAgent.selectAgentAvailabilityTableGroups);
    this.hasAgentSalesConversionStats$ = this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.AgentSalesConversionStats));
    this.salesConversionStats$ = this.ngEntityStore.select(fromAgent.getAgentSalesConversionMetrics);

    // set headers (hard coded, not availabile from data)
    this.headers = [
      AgentSummaryHeaders.BusinessUnit,
      AgentSummaryHeaders.AgentGroup,
      AgentSummaryHeaders.ChatsinQueue,
      AgentSummaryHeaders.Agents,
      AgentSummaryHeaders.ChatSlots
    ];

    // set subheaders (hard coded, not availabile from data)
    this.subheaders = [
      AgentSummarySubHeaders.BlankSubHeader,
      AgentSummarySubHeaders.BlankSubHeader,
      AgentSummarySubHeaders.BlankSubHeader,
      AgentSummarySubHeaders.Agents,
      AgentSummarySubHeaders.ChatSlots
    ];
  }
}
