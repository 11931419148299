// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ReportProfanity {
  illegalWord: string;
  transcriptLine: string;
  chatId: string;
  siteId: string;
  businessUnitId: string;
  agentGroupId: string;
}
