// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { FeedContentType } from './enums';

export class FeedContent {
  type: FeedContentType;
  id: string;
}
