// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class SelectMessageLog {
  messageType: SelectMessageType;
  method: SelectMessageMethod;
}

export enum SelectMessageType {
  Historic = 'historic',
  XaCard= 'xaCard',
  CustomerUtterance = 'customerUtterance',
  AgentUtterance = 'agentUtterance'
}

export enum SelectMessageMethod {
  DirectClick = 'directClick',
  PrevButton = 'prevButton',
  NextButton = 'nextButton'
}
