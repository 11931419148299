// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  ICustomProperties,
  IMetricTelemetry
} from '@microsoft/applicationinsights-web';
import { Metric } from 'web-vitals';

@Injectable({
  providedIn: 'root'
})
export class WebVitalsTranslator {
  constructor() {}

  public toMetricTelemetry(webVitalMetric: Metric): IMetricTelemetry {
    const { name, value } = webVitalMetric;
    const prefix = 'WebVitals.';

    return {
      name: prefix + name,
      average: value
    };
  }

  public toCustomProperties(webVitalMetric: Metric): ICustomProperties {
    const { id, delta } = webVitalMetric;

    return {
      id,
      delta
    };
  }
}
