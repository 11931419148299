// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { CtiWebSocketService } from 'projects/cee-ctilib/src/lib/cee-cti-websocket.service';
import { Call } from 'projects/entities/src/lib/domain/voice/call/call.model';
import { AppState, Chat, LogHelper } from 'projects/entities/src/public_api';
import { CtiRequest } from '../models/cti-request';

@Injectable({
  providedIn: 'root'
})
export class MercuryCtiService extends CtiWebSocketService {
  constructor(protected ngEntityStore: Store<AppState>,
    protected timeService: DayTimeService,
    protected loggingFactory: LoggingFactoryService) {
    super(loggingFactory);
  }

  sendCtiChatMessage(chat: Chat, chatRequest: CtiRequest): void {
    if (!this.isCtiConnected) { return ; }
    this.send(JSON.stringify({
      msg: chatRequest.messageName,
      data: chatRequest
    }));
    LogHelper.logCtiMessageChatEvent(this.loggingFactory, chat, chatRequest.messageName, 'Outbound');
  }

  sendCtiCallMessage(callRequest: CtiRequest, call?: Call): void {
    if (!this.isCtiConnected) { return ; }
    this.send(JSON.stringify({
      msg: callRequest.messageName,
      data: callRequest
    }));

    if (call) {
      LogHelper.logCtiMessageCallEvent(this.loggingFactory, call, callRequest.messageName, 'Outbound');
    }
  }
}
