// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { NuanceCredentialService } from './nuance-credential-service';

@Injectable({
  providedIn: 'root'
})
export class NuanceCredentialRequestService {
  constructor(
    protected nuanceCredentialService: NuanceCredentialService
  ) {}

  public async createRequest(model?: any): Promise<any> {
    const credential = await this.nuanceCredentialService.getCredential();
    return {
      ...model,
      ...credential
    };
  }
}
