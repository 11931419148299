// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { CallSummary } from 'projects/entities/src/lib/domain/models/voice/call-summary';
import { CallSummaryDisplay, SessionSummaryState } from 'projects/entities/src/lib/domain/models/voice/enums';
import { fromCall } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { CallActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';

@Component({
  selector: 'merc-close-call-modal',
  templateUrl: './close-call-modal.component.html',
  styleUrls: ['./close-call-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CloseCallModalComponent extends SubscriberComponent implements OnInit, OnDestroy {
  callSummary$: Observable<CallSummary>;
  currentSessionSummaryState$: Observable<SessionSummaryState>;
  currentCallId$: Observable<string>;

  sessionSummaryState = SessionSummaryState;

  constructor(
    private store: Store<AppState>
    ) {
    super();
  }

  ngOnInit() {
    this.callSummary$ = this.store.select(fromCall.getCallSummary);
    this.currentSessionSummaryState$ = this.store.select(fromCall.getSessionSummaryState);
    this.currentCallId$ = this.store.select(fromCall.getCurrentCallId);
  }

  getControls(callId: string, state: SessionSummaryState, callSummary: CallSummary){
    if (callSummary?.error){
      return [
        {
          text: 'Retry',
          type: 'primary',
          controlFunction: () => this.store.dispatch(CallActions.getCallSummary({callId, display: CallSummaryDisplay.Modal, traceId: Guid.raw(), isRetry: true}))
        },
        {
          text: 'Close Session',
          type: 'negative-link',
          controlFunction: () => this.store.dispatch(CallActions.callEnded({callId, isManual: true, source: 'closeSession'}))
        }
      ];
    }

    const cancelButton = {
      text: 'Cancel',
      type: 'link',
      controlFunction: () => this.store.dispatch(CallActions.updateShowSessionSummaryModal({show: false, source: 'button'}))
    };

    switch (state){
      case SessionSummaryState.NextSession:
        return [
          {
            text: 'Next Session',
            type: 'primary',
            controlFunction: () => this.store.dispatch(CallActions.callEnded({callId, isManual: true, source: 'nextCall'}))
          },
          cancelButton
        ];
      case SessionSummaryState.CloseSession:
      default:
        return [
          {
            text: 'Close Session',
            type: callId ? 'negative' : 'primary',
            controlFunction: () => this.store.dispatch(CallActions.callEnded({callId, isManual: true, source: 'closeSession'}))
          },
          cancelButton
        ];
    }
  }

  closeModal(){
    this.store.dispatch(CallActions.updateShowSessionSummaryModal({show: false, source: 'x'}));
  }

}

