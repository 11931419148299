// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getCLS, getFID, getLCP, Metric } from 'web-vitals';
import { WebVitalsLogger } from './web-vitals-logger';
import { WebVitalsTranslator } from './web-vitals-translator';

@Injectable({
  providedIn: 'root'
})
export class WebVitalsInitializer {
  constructor(
    @Inject(WebVitalsTranslator)
    private webVitalsTranslator: WebVitalsTranslator
  ) {}

  public createWebVitalsLogger(appInsights: ApplicationInsights): WebVitalsLogger {
    return new WebVitalsLogger(
      appInsights,
      this.webVitalsTranslator
    );    
  }

  public addMetricTracking(logger: WebVitalsLogger) {
    // see: https://github.com/GoogleChrome/web-vitals
    getCLS((metric: Metric) => logger.trackMetric(metric));
    getFID((metric: Metric) => logger.trackMetric(metric));
    getLCP((metric: Metric) => logger.trackMetric(metric));
  }
}
