<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
headerText="Are you still there? {{ timer.value | async | formatTime:'0:00' }}"
  [active]="active"
  [clickOutsideToClose]="false"
  (modalClose)="closeModal()"
  [hideCloseButton]="true"
  [controls]="controls">
  <p>It looks like you have stepped away from your computer. You will be automatically logged out in 1 minute and any active conversations will be transferred or closed.</p>
</cee-modal>
