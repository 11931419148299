// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class CtiRequests {
  static ChatDialogStart = 'ChatDialogStart';
  static ChatDialogStop = 'ChatDialogStop';
  static ChatDialogResume = 'ChatDialogResume';
  static ChatDialogAppUpdate = 'ChatDialogAppUpdate';
  static ChatDialogSecureCollectionInitiated = 'ChatDialogSecureCollectionInitiated';
  static ChatDialogSecureCollectionStatus = 'ChatDialogSecureCollectionStatus';
  static ChatDialogWsRequest = 'ChatDialogWSRequest';
  static ChatDialogSecureCollectionStart = 'ChatDialogSecureCollectionStart';
  static ChatDialogSecureCollectionCancel = 'ChatDialogSecureCollectionCancel';
}

export class CtiResponses {
  static CallStart = 'ChatDialogStartResponse';
  static ChatDialogStart = 'ChatDialogStartResponse';
  static ChatDialogStop = 'ChatDialogStopResponse';
  static ChatDialogResume = 'ChatDialogResumeResponse';
  static ChatDialogAppUpdate = 'ChatDialogAppUpdateResponse';
  static ChatDialogSecureCollectionStart = 'ChatDialogSecureCollectionStartResponse';
  static ChatDialogSecureCollectionCancel = 'ChatDialogSecureCollectionCancelResponse';
  static ChatDialogSecureCollectionInitiated = 'ChatDialogSecureCollectionInitiatedResponse';
  static ChatDialogSecureCollectionStatus = 'ChatDialogSecureCollectionStatusResponse';
  static ChatDialogWSResponse = 'ChatDialogWSResponse';
  static Success = 'Success';
}

export class ChatDialogAppUpdateOptions {
  static readonly Actions = {
    Refresh: 'refresh',
    LaunchItg: 'request-launch-itg'
  };
  static readonly Area = {
    Recommendations: 'recommendations'
  };
  static readonly Applications = {
    Einstein360: 'Einstein360'
  };
}

export class ChatDialogWSOptions {
  static readonly Actions = {
    WrapCall: 'wrap-call',
    EndSession: 'end-session'
  };
  static readonly Status = {
    Success: 'Success'
  };
}
