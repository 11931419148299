// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { CtiBeginCallRequestData } from 'src/app/models/cti-begin-call-request';
import { CtiChatDialogAppUpdateResponseData } from 'src/app/models/cti-chat-dialog-app-update-data';
import { CtiChatDialogResumeResponseData } from 'src/app/models/cti-chat-dialog-resume-response-data';
import { CtiChatDialogSecureCollectionCancelResponseData } from 'src/app/models/cti-chat-dialog-secure-collection-cancel-response-data';
import { CtiChatDialogSecureCollectionStartResponseData } from 'src/app/models/cti-chat-dialog-secure-collection-start-response-data';
import { CtiChatDialogStartResponseData } from 'src/app/models/cti-chat-dialog-start-response-data';
import { CtiChatDialogStopResponseData } from 'src/app/models/cti-chat-dialog-stop-response-data';
import { CtiChatDialogWsRequestData } from 'src/app/models/cti-chat-dialog-ws-request-data';
import { CtiDataResumeData } from 'src/app/models/cti-data-resume';
import { CtiDialogSecureCollectionStatus } from 'src/app/models/cti-dialog-secure-collection-status-data';
import { CtiEndCallRequestData } from 'src/app/models/cti-end-call-request';
import { CtiRegisterMetadata } from 'src/app/models/cti-register-response';
import { CtiSecureDataCollectionInitiatedData } from 'src/app/models/cti-secure-data-collection-initiated-data';

export const UpdateChatServiceConnection = createAction(
  '[OneCti] Update Chat Service Connection',
  props<{ isConnected: boolean }>()
);

export const UpdateReconnectOneCtiComplete = createAction(
  '[OneCti] Update Reconnect OneCti complete'
);

export const UpdateListenerServiceConnection = createAction(
  '[OneCti] Update Listener Service Connection',
  props<{ isConnected: boolean }>()
);

export const ChatDialogWsRequest = createAction(
  '[OneCti] Chat Dialog Ws Request',
  props<{ data: CtiChatDialogWsRequestData, message: string }>()
);

export const ChatDialogSecureCollectionInitiated = createAction(
  '[OneCti] Chat Dialog Secure Collection Initiated',
  props<{ data: CtiSecureDataCollectionInitiatedData, message: string }>()
);

export const ChatDialogSecureCollectionStatus = createAction(
  '[OneCti] Chat Dialog Secure Collection Status',
  props<{ data: CtiDialogSecureCollectionStatus, message: string }>()
);

export const ChatDialogAppUpdateResponse = createAction(
  '[OneCti] Chat Dialog App Update',
  props<{ data: CtiChatDialogAppUpdateResponseData, message: string }>()
);

export const ChatDialogSecureCollectionStartResponse = createAction(
  '[OneCti] Chat Dialog Secure Collection Start Response',
  props<{ data: CtiChatDialogSecureCollectionStartResponseData, message: string }>()
);

export const ChatDialogSecureCollectionCancelResponse = createAction(
  '[OneCti] Chat Dialog Secure Collection Cancel Response',
  props<{ data: CtiChatDialogSecureCollectionCancelResponseData, message: string }>()
);

export const ChatDialogStartResponse = createAction(
  '[OneCti] Chat Dialog Start Response',
  props<{ data: CtiChatDialogStartResponseData, message: string }>()
);

export const ChatDialogResumeResponse = createAction(
  '[OneCti] Chat Dialog Resume Response',
  props<{ data: CtiChatDialogResumeResponseData, message: string }>()
);

export const ChatDialogStopResponse = createAction(
  '[OneCti] Chat Dialog Stop Response',
  props<{ data: CtiChatDialogStopResponseData, message: string }>()
);

export const BeginCall = createAction(
  '[OneCti] Begin Call',
  props<{ data: CtiBeginCallRequestData }>()
);

export const EndCall = createAction(
  '[OneCti] End Call',
  props<{ data: CtiEndCallRequestData }>()
);

export const DataResume = createAction(
  '[OneCti] Data Resume',
  props<{ data: CtiDataResumeData }>()
);

export const Register = createAction(
  '[OneCti] Register',
  props<{ data: CtiRegisterMetadata }>()
);

export const ReconnectOneCti = createAction(
  '[OneCti] ReconnectOneCti'
);

export const RefreshChatsToOneCti = createAction(
  '[OneCti] RefreshChatsToOneCti'
);
