<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div *ngIf="{
  agentSummaryOpen: (agentSummaryOpen$ | async), 
  hasAgentSalesConversionStats: (hasAgentSalesConversionStats$ | async), 
  salesConversionStats: (salesConversionStats$ | async)} 
  as data">
  <div class="merc-agent-summary" [ngClass]="{'open': data.agentSummaryOpen}">
    <div class="merc-agent-summary-information" [ngClass]="{'metrics': data.hasAgentSalesConversionStats}">
      <div class="merc-agent-summary-availability">
        Availability
      </div>
      <div class="merc-agent-summary-update">
        <i class="cee-body-detail">Updated every 10 seconds</i>
      </div>
      <div class="merc-agent-summary-table">
        <cee-table 
          [headers]="headers" 
          [subheaders]="subheaders" 
          [groups]="tableData$ | async">
        </cee-table>
      </div>
    </div>
    <div class="merc-agent-summary-information agent-metrics" *ngIf="data.hasAgentSalesConversionStats">
      <div class="merc-metrics-text">
        Metrics
      </div>
      <div class="merc-agent-metrics">
        <div>
          <span>Requests Assigned</span>
          <span>{{data.salesConversionStats?.assignedCount}}</span>
        </div>
        <div>
          <span>Conversion %</span>
          <span>{{data.salesConversionStats?.percentConversion}}%</span>
        </div>
        <div>
          <span>Conversions</span>
          <span>{{data.salesConversionStats?.conversionCount}}</span>
        </div>
      </div>
    </div>
  </div>
</div>