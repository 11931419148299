// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { StringUtil } from './string.util';

export class ObjectUtil {
	public static transformJsonObjectKeysInToCamelCase(jsonObject: any) {
		let transformedObject, origKey, newKey, value;

		if (jsonObject instanceof Array) {
			transformedObject = [];
			for (origKey in jsonObject) {
				if (origKey) {
					value = jsonObject[origKey];
					if (typeof value === 'object') {
						value = ObjectUtil.transformJsonObjectKeysInToCamelCase(value);
					}
					transformedObject.push(value);
				}
			}
		} else {
			transformedObject = {};
			for (origKey in jsonObject) {
				if (jsonObject.hasOwnProperty(origKey)) {
					newKey = (StringUtil.toCamelCase(origKey) || origKey).toString();
					value = jsonObject[origKey];
					if (value !== null && value.constructor === Object) {
						value = ObjectUtil.transformJsonObjectKeysInToCamelCase(value);
					}
					transformedObject[newKey] = value;
				}
			}
		}
		return transformedObject;
	}

	public static circularStringify(jsonObject) {
		if (jsonObject) {
			const cache = new Map();
			return JSON.stringify(jsonObject, function(_key, value) {
				if (
					value !== undefined &&
					typeof value === 'object' &&
					value !== null
				) {
					if (cache.get(value)) {
						// Circular reference found, discard key
						return;
					}
					// Store value in our collection
					cache.set(value, true);
				}
				return value;
			});
		} else {
			return '';
		}
	}

	public static isEqual(value: any, other: any): boolean {
		if (value && other && typeof value !== typeof other) {
			return false;
		}

		if (
			!(value || other) ||
			(typeof value === 'string' &&
				typeof other === 'string' &&
				value === other) ||
			(typeof value === 'number' &&
				typeof other === 'number' &&
				value === other)
		) {
			return true;
		}

		// Get the value type
		const type = Object.prototype.toString.call(value);

		// If the two objects are not the same type, return false
		if (type !== Object.prototype.toString.call(other)) {
			return false;
		}

		// If items are not an object or array, return false
		if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
			return false;
		}

		// Compare the length of the length of the two items
		const valueLen =
			type === '[object Array]' ? value.length : Object.keys(value).length;
		const otherLen =
			type === '[object Array]' ? other.length : Object.keys(other).length;
		if (valueLen !== otherLen) {
			return false;
		}

		// Compare two items
		const compare = function(item1, item2) {
			// Get the object type
			const itemType = Object.prototype.toString.call(item1);

			// If an object or array, compare recursively
			if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
				if (!ObjectUtil.isEqual(item1, item2)) {
					return false;
				}
			} else {
				// Otherwise, do a simple comparison
				// If the two items are not the same type, return false
				if (itemType !== Object.prototype.toString.call(item2)) {
					return false;
				}

				// Else if it's a function, convert to a string and compare
				// Otherwise, just compare
				if (itemType === '[object Function]') {
					if (item1.toString() !== item2.toString()) {
						return false;
					}
				} else {
					if (item1 !== item2) {
						return false;
					}
				}
			}
		};

		// Compare properties
		if (type === '[object Array]') {
			for (let i = 0; i < valueLen; i++) {
				if (compare(value[i], other[i]) === false) {
					return false;
				}
			}
		} else {
			for (const key in value) {
				if (value.hasOwnProperty(key)) {
					if (compare(value[key], other[key]) === false) {
						return false;
					}
				}
			}
		}

		// If nothing failed, return true
		return true;
	}
}
