// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { PlaceholderConstants } from '../constants/placeholders.constants';
import { PlaceholderEditorState } from '../domain/models/enums';
import { SmartResponseRequestEntity } from '../domain/models/requests/get-smart-responses';
import { GetSmartResponsesResponse } from '../domain/models/responses/get-smart-responses-responses';
import { Placeholder } from '../domain/placeholders/placeholder.model';
import { StringHelper } from './string-helper';

export class PlaceholderHelper {
  public static getSelectId({chatId, key}: {chatId: string, key: string}): string{
    return `${chatId}_${key}`;
  }

  public static extractPlaceholders(response: GetSmartResponsesResponse, chatId: string): Placeholder[] {
    const result = [];

    response.smartResponses?.nuance?.responses?.forEach(sr => {
      sr.entities?.forEach(e => {
        if (!result.find(r => r.key === e.key)){
          const {key, label, values: options} = e;
          const placeholder: Placeholder = {
            chatId,
            options,
            key,
            label
          };
          result.push(placeholder);
        }
      });
    });

    return result;
  }

  public static getNamedEntities(chatPlaceholders: Placeholder[]): SmartResponseRequestEntity[]{
    const result = [];

    chatPlaceholders.forEach(p => {
      if (p.selectedValue){
        const namedEntity: SmartResponseRequestEntity = {
          key: p.key,
          values: [
            {
              value: p.selectedValue,
              confidence: 1.0
            }
          ]
        };
        result.push(namedEntity);
      }
    });

    return result;
  }

  public static getEditPlaceholderTitle(state: PlaceholderEditorState): string {
    switch (state){
      case PlaceholderEditorState.MessageEditor:
        return 'Fill to continue';
      case PlaceholderEditorState.QuickSend:
        return 'Fill to send';
      case PlaceholderEditorState.EditPlaceholder:
      default:
        return 'Update Placeholder';
    }
  }

  public static getEditPlaceholderDescription(state: PlaceholderEditorState): string {
    switch (state){
      case PlaceholderEditorState.MessageEditor:
        return 'Message will be saved to editor.';
      case PlaceholderEditorState.QuickSend:
        return 'Message will be sent to customer.';
      case PlaceholderEditorState.EditPlaceholder:
      default:
        return 'Save to update this placeholder.';
    }
  }

  public static getEditPlaceholderErrorMessage(state: PlaceholderEditorState): string {
    switch (state){
      case PlaceholderEditorState.MessageEditor:
        return 'Must fill placeholder before continuing';
      case PlaceholderEditorState.QuickSend:
        return 'Must fill placeholder before sending';
      case PlaceholderEditorState.EditPlaceholder:
      default:
        return '';
    }
  }

  public static getEditPlaceholderProfanityErrorMessage(state: PlaceholderEditorState): string {
    switch (state){
      case PlaceholderEditorState.EditPlaceholder:
      case PlaceholderEditorState.MessageEditor:
        return 'Remove inappropriate words before saving.';
      case PlaceholderEditorState.QuickSend:
        return 'Remove inappropriate words before sending.';
      default:
        return '';
    }
  }

  public static shouldValidatePlaceholder(state: PlaceholderEditorState): boolean {
    return Boolean(this.getEditPlaceholderErrorMessage(state));
  }

  public static getEditPlaceholderSubmitButtonText(state: PlaceholderEditorState): string {
    switch (state){
      case PlaceholderEditorState.QuickSend:
        return 'Send';
      case PlaceholderEditorState.MessageEditor:
      case PlaceholderEditorState.EditPlaceholder:
      default:
        return 'Save';
    }
  }

  public static getEditPlaceholderShowSendIcon(state: PlaceholderEditorState): boolean {
    return this.getEditPlaceholderSubmitButtonText(state) === 'Send';
  }

  public static shouldEditSmartResponsePlaceholder(keys: string[], placeholders: Placeholder[]): boolean{
    if (!keys || keys.length === 0) { return false; }

    let shouldEdit = false;

    keys.forEach(k => {
      const placeholder = placeholders.find(p => p.key === k);
      //placeholder does not exist in state or does not have selectedValue, send to editor
      if (!placeholder?.selectedValue){
        shouldEdit = true;
        return;
      }
    });

    return shouldEdit;
  }

  public static updateWithPlaceholders(str: string, keys: string[], placeholders: Placeholder[]): string{
    if (!str) { return str; }
    if (!keys || keys.length === 0) { return str; }

    let updatedString = str;
    keys.forEach(k => {
      const placeholder = placeholders.find(p => p.key === k);
      if (placeholder){
        updatedString = StringHelper.replaceAll(
          updatedString,
          k,
          placeholder.selectedValue ? placeholder.selectedValue : `{${placeholder.label}}`,
          true
        );
      }
    });

    return updatedString;
  }

  public static updateChatIdInPlaceholders(chatId: string, responses: Placeholder[]): Placeholder[] {
    return responses.map(r =>
      {
        return {
        ...r,
        chatId
      };
    });
  }

  public static getNewChatPlaceholders(agentAlias: string, chatId: string, placeholders?: Placeholder[]): Placeholder[]{
    let result = [];

    if (placeholders){
      result = agentAlias ?
        [...placeholders.filter(p => p.key !== PlaceholderConstants.Keys.AgentName)] :
        [...placeholders];
    }

    if (agentAlias){
      const agentNamePlaceholder: Placeholder = {
        chatId,
        key: PlaceholderConstants.Keys.AgentName,
        label: PlaceholderConstants.Labels.AgentName,
        selectedValue: agentAlias
      };
      result.push(agentNamePlaceholder);
    }

    return result;
  }
}
