// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatTranscript } from 'projects/entities/src/public_api';

export class PriorEngagementAccordionChange{
    chatId: string;
    priorEngagement: ChatTranscript;
    isActive: boolean;
}
