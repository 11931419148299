// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { AppState, OneCtiActions } from 'projects/entities/src/public_api';
import { MercuryCtiService } from './mercury-cti.service';

@Injectable({
  providedIn: 'root'
})
export class MercuryCtiListenerService extends MercuryCtiService {
  constructor(protected ngEntityStore: Store<AppState>,
    protected timeService: DayTimeService,
    protected loggingFactory: LoggingFactoryService){
    super(ngEntityStore, timeService, loggingFactory);
      this.openCtiConnection$.subscribe((isConnected: boolean) => {
        this.ngEntityStore.dispatch(OneCtiActions.UpdateListenerServiceConnection({isConnected}));
      });
  }

  public webSocketMessageEventHandler(messageEvent: MessageEvent) {
    super.webSocketMessageEventHandler(messageEvent);
    this.parseEvent(messageEvent);
  }
  private parseEvent(webSocketResponseMessage: MessageEvent): void {
    if (!webSocketResponseMessage?.data) { return; }

    const eventData = this.convertXmlMessageToJsonMessage(webSocketResponseMessage.data);

    if (!eventData) { return; }

    const action = this.getOneCtiAction(eventData);

    if (!action) { return; }

    this.ngEntityStore.dispatch(action);
  }

  private getOneCtiAction(eventData: any) {
    if (eventData.ctiData?.ctiBeginCall) {
      return OneCtiActions.BeginCall({ data: eventData.ctiData });
    }
    else if (eventData.ctiData?.ctiEndCall) {
      return OneCtiActions.EndCall({ data: eventData.ctiData });
    }
    else if (eventData.ctiDataResume?.action === 'Focus') {
      return OneCtiActions.DataResume({ data: eventData.ctiDataResume });
    }
    else if (eventData.ctiRegister?.ctimetadata) {
      return OneCtiActions.Register({ data: eventData.ctiRegister.ctimetadata });
    }

    return null;
  }
}
