// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NgEntityEffects } from './effects';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ChatActions, ScriptActions, ScriptTreeActions, toPayload } from './actions';
import { filter, map, tap } from 'rxjs/operators';
import { UpdateCustomScripts, ScriptCollection } from './models/requests/updateCustomScripts';
import { Chat } from './models/chat';
import { SystemScriptsLoaded } from './models/systemScriptsLoaded';
import { GetSystemScripts } from './models/requests/getSystemScripts';
import { HttpResponse } from '@angular/common/http';
import { LogHelper } from '../utils/logHelper';
import { AgentOperations, ChatOperations } from '../constants/event-logs.constants';
import { PageInitializationConstants } from '../constants/page-initialization.constants';
import { Injectable } from '@angular/core';
import { ScriptGroup } from './scripts/';
import { getChat } from './chat.selectors';
import { ScriptsResponse } from './models/responses/scripts-response';
import * as fromScripts from 'projects/entities/src/lib/domain/scripts/scripts-selectors';
import * as fromCustomResponses from 'projects/entities/src/lib/domain/scripts/custom-responses-selectors';
import { ScriptType } from './models/enums';
import { ScriptGroupActions } from './scripts/actions';
import { defaultCustomScriptGroup, defaultCustomScriptTreeId } from './scripts/custom-responses.constants';
import { Dictionary } from '@ngrx/entity';
import { Script } from './scripts/script.model';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Scripts extends NgEntityEffects {

  getSystemScripts$ = createEffect(() =>
      this.actions.pipe(
        ofType(ChatActions.GetSystemScripts),
        map(action => toPayload<Chat>(action)),
        concatLatestFrom(chat => this.ngEntityStore.select(fromScripts.getScriptTree(chat.scriptTreeId))),
        tap(([chat, existingScriptTree]) => {
          const { chatId, scriptTreeId } = chat;
          if (existingScriptTree) {
            // script tree is already loaded
            // chats with the same scriptTreeId share the system tree/group/scripts including ui state like expanded/collapsed
            this.ngEntityStore.dispatch(ChatActions.SystemScriptsLoaded({ chatId }));
          }
          else {
            const getAgentScripts: GetSystemScripts = {
              siteId: this.config.siteId,
              scriptsTreeId: scriptTreeId,
            };

            this.fixAgentApiService.getScripts(getAgentScripts)
            .then((response: HttpResponse<ScriptsResponse>) => this._loadSystemScripts(chatId, scriptTreeId, response));
          }
      })
      ),
      { dispatch: false }
    );

  systemScriptsLoaded$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SystemScriptsLoaded),
      map(action => toPayload<SystemScriptsLoaded>(action)),
      concatLatestFrom(action => this.ngEntityStore.select(getChat(action.chatId))),
      tap(([_, selectedChat]) => {
        if (selectedChat && !selectedChat.accountNumber) {
          const { chatId, isFirstLoad } = selectedChat;
          const logData = { isFirstLoad };

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatLoadSuccess, selectedChat, logData);

          if (selectedChat.isFirstLoad) {
            this.ngEntityStore.dispatch(ChatActions.ChatFirstLoadSuccess(chatId));
          }
        }
      })
    ),
    { dispatch: false }
  );

  loadCustomScripts$ = createEffect(() =>
    this.actions.pipe(
      ofType(ScriptTreeActions.loadCustomResponses),
      concatLatestFrom(() => this.ngEntityStore.select(fromCustomResponses.selectIsLoaded)),
      filter(([_action, isLoaded]) => !isLoaded),
      tap(() => {
        this.pageInitHelper.updateStatePending(PageInitializationConstants.CustomScripts);
        const getCustomScriptsPromise = this.fixAgentApiService.getCustomScripts();
        getCustomScriptsPromise.then(
          (response: HttpResponse<any>) => {
            if (response && response.body){
              this._updateCustomScripts(response.body.scriptCollection, null);
            }
        });
        this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.CustomScripts, getCustomScriptsPromise);
      })
    ),
    { dispatch: false }
  );

  updateCustomScripts$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        ScriptActions.addScript,
        ScriptActions.updateScriptValue,
        ScriptActions.deleteScript,
        ScriptActions.moveScript,
        ScriptGroupActions.addScriptGroup,
        ScriptGroupActions.updateScriptGroupName,
        ScriptGroupActions.deleteScriptGroup,
        ScriptGroupActions.moveScriptGroup
      ),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromCustomResponses.selectCustomScriptGroupBase),
        this.ngEntityStore.select(fromScripts.selectScriptGroupEntities),
        this.ngEntityStore.select(fromScripts.selectScriptEntities)
      ]),
      tap(([_, customScriptGroup, allScriptGroups, allScripts]) => {
        const updateCustomScripts = new UpdateCustomScripts();

        const [scriptCollection, excessScriptGroups] = this.scriptsService.createScriptCollection(customScriptGroup, allScriptGroups, allScripts);

        updateCustomScripts.scriptCollection = scriptCollection;

        this._logCustomScripts(_, allScriptGroups, allScripts, excessScriptGroups);

        this.fixAgentApiService.updateCustomScripts(updateCustomScripts);
        //TODO: update to have an error state when scripts aren't updated
      })
    ),
    { dispatch: false }
  );

  createCustomScript$ = createEffect(() =>
  this.actions.pipe(
    ofType(
      ScriptGroupActions.createScriptGroup,
      ScriptActions.createScript
    ),
    tap(({method, type}) => {
      const operation = type === ScriptGroupActions.createScriptGroup.type ? AgentOperations.CreateCustomHeader : AgentOperations.CreateCustomResponse;
      LogHelper.logAgentEvents(this.loggingFactory, operation, { method });
    })
  ),
  { dispatch: false }
  );

  manageScripts$ = createEffect(() =>
  this.actions.pipe(
    ofType(
      ScriptActions.manageScripts
    ),
    tap(({userAction}) => {
      if ( userAction ) {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ManageCustomResponses);
      }
    })
  ),
  { dispatch: false }
  );


  private _logCustomScripts({ type }, allScriptGroups: Dictionary<ScriptGroup>, allScripts: Dictionary<Script>, excessScriptGroups: ScriptGroup[]) {
    const logData = {
      headersCount: Object.entries(allScriptGroups).filter(([_, entry]) => entry.scriptTreeId === defaultCustomScriptTreeId && entry.id !== defaultCustomScriptGroup.id).length,
      responsesCount: Object.entries(allScripts).filter(([_, entry]) => entry.scriptTreeId === defaultCustomScriptTreeId).length,
      source: 'manageResponses',
      ...(excessScriptGroups?.length > 0) && { excessScriptGroups }
    };

    switch (type) {
      case ScriptGroupActions.addScriptGroup.type:
        logData.source = 'addHeader';
        break;
      case ScriptActions.addScript.type:
        logData.source = 'addResponse';
        break;
      case ScriptActions.updateScriptValue.type:
        logData.source = 'editResponse';
        break;
      case ScriptActions.deleteScript.type:
        logData.source = 'deleteResponse';
        break;
      case ScriptGroupActions.updateScriptGroupName.type:
        logData.source = 'editHeader';
        break;
      case ScriptGroupActions.deleteScriptGroup.type:
        logData.source = 'deleteHeader';
        break;
      case ScriptGroupActions.moveScriptGroup.type:
        logData.source = 'moveHeader';
        break;
      case ScriptActions.moveScript.type:
        logData.source = 'moveScript';
        break;
    }

    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SaveCustomScripts, logData);
  }

  private _updateCustomScripts(scriptCollection: ScriptCollection, customScriptGroup: ScriptGroup) {
    if (!scriptCollection) {
      return;
    }
    const [scriptTree, scriptGroups, scripts] = this.scriptsService.createCustomScriptTree(scriptCollection, customScriptGroup);
    this.ngEntityStore.dispatch(ScriptTreeActions.upsertScriptCollection({ scriptTree, scriptGroups, scripts }));
	}

  private _loadSystemScripts(chatId: string, scriptTreeId: string, response: HttpResponse<ScriptsResponse>) {
    const scriptCollection = response?.body?.scriptCollection;
    if (!scriptCollection) {
      return;
    }

    const { childCollections, name } = scriptCollection;
    const [scriptTree, scriptGroups, scripts] = this.scriptsService.createScriptTree(scriptTreeId, name, ScriptType.System, childCollections);

    if (scriptTree && scriptTree.scriptGroups.length > 0) {

      this.ngEntityStore.dispatch(ScriptTreeActions.upsertScriptCollection({ scriptTree, scriptGroups, scripts }));

      this.ngEntityStore.dispatch(ChatActions.SystemScriptsLoaded({ chatId }));
    }
  }

}
