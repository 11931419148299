// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpClient } from '@angular/common/http';
import { TokenStoreService, JwtTokenFactoryService, TokenProvider, MockTokenProviderService, OotbAadTokenProviderService, AccessToken } from '@cxt-cee-chat/merc-ng-core';
import { isDevMode } from '@angular/core';

export const aadTokenProviderFactory = (http: HttpClient, tokenStore: TokenStoreService, tokenFactory: JwtTokenFactoryService) => {
    let result: TokenProvider;
    if (isDevMode()) 
    {
        const token = new AccessToken();
        token.accessToken = 'test';
        token.refreshToken = 'refreshtoken';
    
         const mock = new MockTokenProviderService(tokenFactory, sessionStorage);
         mock.storageKey = 'mockAadToken';
         localStorage.setItem( mock.storageKey, JSON.stringify(token));         
         result = mock;
    }
    else
    {
        result = new OotbAadTokenProviderService(http, tokenStore, tokenFactory);
    }
    return result;
};

export let AzureActiveDirectoryTokenProvider = { 
    provide: 'AadTokenProvider', 
    useFactory: aadTokenProviderFactory,
    deps: [
        HttpClient,
        TokenStoreService,
        JwtTokenFactoryService
    ]
};