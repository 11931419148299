<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-banner [type]="bannerType" [active]="active" dataQa="testing-environment-notification">
  <p>You are in a <strong>testing environment</strong>. If you are not testing, please use <a href="https://mercury.comcast.net/" data-qa="mercury-hyperlink">Mercury.Comcast.net</a></p>
  <a class="cee-btn--link" target="_blank" [href]="reportBugUrl">
    <svg class="cee-icon cee-icon--md" data-qa="info-icon">
      <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-manage'"></use>
    </svg>
    Report Bug
  </a>
</cee-banner>
