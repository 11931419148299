// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Guid } from 'guid-typescript';

export class GuidHelper {
  public static IsFalseOrEmpty(guid: string) {
    return !guid || guid === Guid.EMPTY;
  }
}
