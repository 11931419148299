<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-chat-detail-item merc-chat-detail-item--ask-me-anything"
    *ngIf="{
    selectedAskMeAnythingSearches: selectedAskMeAnythingSearches$ | async,
    selectedAskMeAnythingId: selectedAskMeAnythingId$ | async,
    selectedChatColor: selectedChatColor$ | async
} as data">
    <div class="merc-knowledge-banner">
        <cee-feature-banner
          title="Knowledge Answers"
          isBeta="true"
          body="Provide feedback to help us improve."
          color="green"
          icon="knowledge"
        ></cee-feature-banner>
    </div>
    <div class="merc-ask-me-anything-searches-container" (scroll)="onScroll($event)" #scrollWindow>
        <ng-container *ngIf="data.selectedAskMeAnythingSearches?.length > 0; else emptyState">
            <div *ngFor="let search of data.selectedAskMeAnythingSearches; trackBy: trackBySearchId" #searches [attr.id]="search.searchId">
                <merc-ask-me-anything-content [search]="search" [color]="data.selectedChatColor" [chatId]="data.selectedAskMeAnythingId"></merc-ask-me-anything-content>
            </div>
        </ng-container>
        <ng-template #emptyState>
            <div class="merc-ask-me-anything-searches-container--empty">
                <span>Ask a question in the input below. For example, "What is Storm-ready Wifi?"</span>
            </div>
        </ng-template>
    </div>
    <div class="merc-ask-me-anything-input">
        <div class="merc-ask-me-anything-input-form-elements">
            <textarea #amaTextarea [(ngModel)]="amaTextareaValue" (keydown.enter)="onEnterKey($event, data.selectedAskMeAnythingId)" id="amaTextarea" placeholder="Type a question..." maxlength="750" (keyup)="amaCharCount()"></textarea>
            <cee-button type="primary" (buttonClick)="amaSend(data.selectedAskMeAnythingId)" [disabled]="isDisabled">
                <span class="cee-accessibly-hidden">Send</span>
                <svg class="cee-icon" focusable="false" width="16" height="16" viewBox="0 0 16 16">
                    <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-paper-airplane'"></use>
                </svg>
            </cee-button>
        </div>
        <div class="merc-char-count-wrapper" [ngClass]="{'max-limit': amaTextareaValue.length === 750}">
            <span id="mercCharCount">0</span>/750 Characters
        </div>
    </div>
</div>
<merc-ask-me-anything-feedback-modal></merc-ask-me-anything-feedback-modal>