// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromApp } from 'projects/entities/src/lib/domain/selectors';
import { AgentActions, AppActions, AppState, FeatureFlags, hasFeatureFlag } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { NavigationSources } from 'projects/entities/src/lib/constants/constants';


@Component({
  selector: 'merc-page-initialization',
  templateUrl: './page-initialization.component.html',
  styleUrls: ['./page-initialization.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageInitializationComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @Input() pageName: string;
  @Input() errorMessage: string = 'Something went wrong. If this problem persists, please contact your supervisor for assistance.';

  pageInitErrorList$: Observable<string[]>;
  pageInitSucceeded$: Observable<boolean>;
  pageInitCriticalError$: Observable<boolean>;
  isMySupportFlagOn$: Observable<boolean>;

  constructor(
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(AppActions.SetCurrentPage({pageName: this.pageName}));


    this.pageInitErrorList$ = this.store.select(fromApp.getPageInitializationErrorList);
    this.pageInitSucceeded$ = this.store.select(fromApp.getPageInitializationSucceeded);
    this.pageInitCriticalError$ = this.store.select(fromApp.getHasCriticalPageInitializationError);
    this.isMySupportFlagOn$ = this.store.select(hasFeatureFlag(FeatureFlags.MySupportOutboundLinks));
  }

  onTryAgain() {
    window.location.reload();
  }

  public onPageInitMySupportClicked() {
    this.store.dispatch(AgentActions.NavigateToSupportPage({ source: NavigationSources.pageInit }));
  }
}

