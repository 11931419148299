// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { fromVoiceUi } from 'projects/entities/src/lib/domain/selectors';
import { VoiceUiActions } from 'projects/entities/src/lib/domain/actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-live-transcript-drawer',
  templateUrl: './live-transcript-drawer.component.html',
  styleUrls: ['./live-transcript-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveTranscriptDrawerComponent extends SubscriberComponent implements OnInit, OnDestroy {
  isTranscriptFeedCollapsed$: Observable<boolean>;

  constructor(
    private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.isTranscriptFeedCollapsed$ = this.store.select(fromVoiceUi.getIsTranscriptFeedCollapsed);
  }

  public toggleTransferFeedCollapse(isCurrentlyCollapsed: boolean, isBottomButton?: boolean) {
    this.store.dispatch(VoiceUiActions.ToggleLiveTranscriptCollapse({ isCollapsed: !isCurrentlyCollapsed, isBottomButton }));
  }
}

