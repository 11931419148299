// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chat, AppState, ScriptActions, ChatUiActions, FeatureFlags } from 'projects/entities/src/public_api';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { ScriptGroupActions } from 'projects/entities/src/lib/domain/scripts';
import { fromAgent, fromChat, fromPlaceholders, fromSettings, } from 'projects/entities/src/lib/domain/selectors';
import { CeeTabComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { DetailsSelectedTab } from 'projects/entities/src/lib/domain/models/enums';
import { Placeholder } from 'projects/entities/src/lib/domain/placeholders/placeholder.model';

const CREATE_HEADER_ID = 'Create-Header';
const CREATE_RESPONSE_ID = 'Create-Response';
const MANAGE_ID = 'Manage';
@Component({
  selector: 'merc-convo-details-base',
  templateUrl: './convo-details-base.component.html',
  styleUrls: ['./convo-details-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoDetailsBaseComponent extends SubscriberComponent implements OnInit {
  selectedActiveChat$: Observable<Chat>;
  tabSelected$: Observable<string>;
  isCustomScriptsEnabled$: Observable<boolean>;
  hasSmartResponseNotification$: Observable<boolean>;
  hasActivitiesNotification$: Observable<boolean>;
  scroll$: BehaviorSubject<number>;
  showRecommendedTab$: Observable<boolean>;
  placeholders$: Observable<Placeholder[]>;
  defaultTab$: Observable<string>;
  xaSolvesFeatureFlag$: Observable<boolean>;
  askMeAnythingFeatureFlag$: Observable<boolean>;

  tabNames = DetailsSelectedTab;

  scriptsOptions = [
    {
      id: CREATE_HEADER_ID,
      dataQa: 'open-create-custom-header',
      displayText: '<span style="padding: 0 4px"><svg class="cee-icon cee-icon--secondary"><use xlink:href="/assets/img/svg-sprite.svg#icon-plus"></use></svg></span> Create custom header'
    },
    {
      id: CREATE_RESPONSE_ID,
      dataQa: 'open-create-custom-response',
      displayText: '<span style="padding: 0 4px"><svg class="cee-icon cee-icon--secondary"><use xlink:href="/assets/img/svg-sprite.svg#icon-plus"></use></svg></span> Create custom response'
    },
    {
      id: MANAGE_ID,
      dataQa: 'open-manage-headers-responses',
      displayText: '<span style="padding: 0 4px"><svg class="cee-icon cee-icon--secondary"><use xlink:href="/assets/img/svg-sprite.svg#icon-manage"></use></svg></span> Manage headers and responses'
    }
  ];

  constructor(
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    this.selectedActiveChat$ =  this.store.select(fromChat.getSelectedChat);
    this.isCustomScriptsEnabled$ = this.store.select(fromAgent.getEnableCustomScripts);
    this.hasSmartResponseNotification$ = this.store.select(fromChat.getHasSmartResponseNotification);
    this.hasActivitiesNotification$ = this.store.select(fromChat.getHasActivitiesNotification);
    this.tabSelected$ = this.store.select(fromChat.getDetailsPanelTabSelected);
    this.showRecommendedTab$ = this.store.select(fromSettings.showRecommendedTab);
    this.placeholders$ = this.store.select(fromPlaceholders.getAllPlaceholdersForSelectedChatId);
    this.defaultTab$ = this.store.select(fromSettings.getDetailsPanelDefaultTab);
    this.xaSolvesFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.XaSolves));
    this.askMeAnythingFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.AskMeAnything));
  }

  onScriptOptionClicked(optionId: string) {
    switch (optionId) {
      case CREATE_HEADER_ID:
        this.store.dispatch(ScriptGroupActions.createScriptGroup({method: 'dropdown'}));
        break;
      case CREATE_RESPONSE_ID:
        this.store.dispatch(ScriptActions.createScript({method: 'dropdown'}));
        break;
      case MANAGE_ID:
        this.store.dispatch(ScriptActions.manageScripts({userAction: true}));
        break;
    }
  }

  onTabClicked(tab: CeeTabComponent, chatId: string, tabSelected: DetailsSelectedTab) {
    if (tabSelected !== tab.title){
      this.store.dispatch(ChatUiActions.updateDetailsPanelTabSelected({chatId, detailsPanelTabSelected: DetailsSelectedTab[tab.title]}));
    }
  }

  showFeatureTab(tabName: string, defaultTab: string, chat: Chat, hasFeatureFlag: boolean): boolean {
    if (!hasFeatureFlag) { return false; }
    return this.showTab(tabName, defaultTab, chat);
  }

  showTab(tabName: string, defaultTab: string, chat: Chat): boolean{
    const isDefaultTab = tabName === defaultTab;

    return isDefaultTab || Boolean(chat);
  }

  getEnabledNotifications(showRecommendedTab: boolean, showActivitiesTab: boolean, hasSmartResponseNotification: boolean, hasActivitiesNotification: boolean, showKnowledgeTab: boolean){
    const notifications = [];
    if (showRecommendedTab) {
      notifications.push(hasSmartResponseNotification);
    }
    notifications.push(false); // for responses tab
    if (showKnowledgeTab){
      notifications.push(false);
    }
    if (showActivitiesTab) {
      notifications.push(hasActivitiesNotification);
    }
    return notifications;
  }
}
