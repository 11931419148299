<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-template #innerTemplate>

  <h2 class="cee-h2 merc-agent-settings-tab-header">{{title}}</h2>
  
  <div class="merc-agent-settings-tab-body merc-agent-settings-tab-body--advanced">
    <merc-settings-banner></merc-settings-banner>
    <ng-container *ngIf="hasScriptAutocomplete$ | async;">
        <h2 class="cee-h2">Input Options</h2>
        <div class="cee-form-row--group">
          <input
            id="show-autocomplete"
            type="checkbox"
            name="show-autocomplete"
            [(ngModel)]="settings.scriptAutocomplete"
            (ngModelChange)="onSettingsModelChange()"
            class="cee-checkbox"
          />
          <label
            class="cee-checkbox-label"
            for="show-autocomplete"
            >Show auto-complete suggestions
          <p class="merc-agent-settings-message">With this checked, use TAB ENTER to send suggested message</p></label>
        </div>
    </ng-container>
    <ng-container *ngIf="haslanguageTranslatorFeatureFlag$ | async;">
      <h2 class="cee-h2">Translate conversations</h2>
        <div class="cee-form-row--group">
          <input
            id="show-translate"
            type="checkbox"
            name="show-translate"
            class="cee-checkbox"
            [(ngModel)]="settings.translateMessages"
            (ngModelChange)="onSettingsModelChange()"
          />
          <label
            class="cee-checkbox-label"
            for="show-translate"
            >Automatically translate customer messages to English
          <p class="merc-agent-settings-message">Sent messages will also be translated to the customer's preferred language</p></label>
        </div>
    </ng-container>
    <ng-container *ngIf="(!hasScriptAutocomplete$ | async) && (!haslanguageTranslatorFeatureFlag$ | async)">
      <em class="merc-agent-settings-message">Exciting things coming soon…</em>
    </ng-container>
  </div>

</ng-template>
