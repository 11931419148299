// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatTranscript } from './chatTranscript';

export class SetPriorEngagements{
    chatId: string;
    totalEngagements: number;
    engagements: ChatTranscript[];
    clearEngagements?: boolean;
}