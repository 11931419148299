<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-login-banner-wrappper">
  <cee-banner
    *ngIf="bannerContent$ | async"
    [type]="(bannerContent$ | async).type"
    [dismissable]="false"
    [active]="(bannerContent$ | async).active"
    class="merc-login-banner"
    ><p>{{ (bannerContent$ | async).bannerText }}</p>
  </cee-banner>
</div>
