// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { ServiceConfig } from 'projects/entities/src/lib/domain/models/service-config';
import { SigRConfig } from 'projects/entities/src/lib/domain/models/sigrConfig';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  public chatRequest: SigRConfig;
  public voiceHub: SigRConfig;
	public fixAgentAuthRest: ServiceConfig;
	public fixAgentFormAuthRest: ServiceConfig;
	public customerInfo: ServiceConfig;
	public fixAgentRest: ServiceConfig;
	public appConfig: ServiceConfig;
	public niagaraLog: SigRConfig;
	public siteId: string;
	public useTestHarness: boolean;
	public appLauncher: string[][];
  public newChatSound: string;
  public newMessageInactiveChatSound: string;
  public newMessageActiveChatSound: string;
  public outgoingMessageSound: string;
  public chatEndsSound: string;
  public bouncedChatSound: string;
  public unpinnedChatSound: string;
  public testSound: string;
  public npsSurveyUrlBase: string;
  public oneCtiClientName: string;
  public oneCtiChatRegisterUrl: string;
  public oneCtiRegisterUrl: string;
  public uatBugTrackingUrl: string;
  public releaseDate: string;
  public releaseLabel: string;
  public featureAnnouncementText: string;
  public einsteinChatUrl: string;
  public ecmUrl: string;
  public customerAwaitingReplySound: string;
  public askMeAnythingUrl: string;
}
