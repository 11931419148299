// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { PostChatSummaryData } from '../postChatSummaryData';
import { TransferAgentsFeatureFlags } from '../transfer-agents-featureflags';
import { TransferItgs as TransferItgs } from '../transfer-itgs';
import { TransferSecureDataRequests } from '../transfer-secure-data-requests';
import { TransferTranscriptEvents } from '../transfer-transcript-events';
import { TransferXaSuggestions } from '../transfer-xa-suggestions';
import { TransferAgentOption } from '../transferAgentOption';
import { TransferSmartResponses } from '../transferSmartResponses';
import { ChatInteractionRequest } from './chat-interact-request';
export class TransferChat extends ChatInteractionRequest {
    notes: string;
    targetQueueId: string;
    targetQueueName: string;
    targetQueueBusinessUnitId: string;
    targetQueueBusinessUnitName: string;
    targetAgentId?: string;
}

export class TransferChatRequest extends TransferChat {
    itgData: TransferItgs;
    secureDataRequestsData: TransferSecureDataRequests;
    xaSuggestionsData: TransferXaSuggestions;
    smartResponsesData: TransferSmartResponses;
    agentsFeatureFlagsData: TransferAgentsFeatureFlags;
    transcriptEventsData: TransferTranscriptEvents;
    isAsync: boolean;
    postChatSummaryData?: PostChatSummaryData;
    accountNumber?: string;
    previousQueueName?: string;
}

export class TransferOption {
    displayText: string;
}

export class QueueTransferOption extends TransferOption {
    queueId: string;
    availableAgents: TransferAgentOption[];
}
