// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HubConnectionState } from '@cxt-cee-chat/merc-ng-core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HubNames } from '../constants/hub-names';
import * as fromHubs from './hubs.reducer';
import { HubEchoData } from './models/hub-echo-data';
import { ConnectionEchoLog } from './models/logDimensions/connection-echo-log';
import { AppState } from './state';

const selectHubsFeature = createFeatureSelector<AppState, fromHubs.State>(fromHubs.hubsFeatureKey);

export const getHubsInitialized = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state.hubsInitialized
);

export const getChatRequestHubConnectionState = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state.chatRequestHubConnection
);

export const getVoiceHubConnectionState = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state.voiceHubConnection
);

export const getDisconnectedHubs = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state.disconnectedHubs
);

export const getChatHubLastReconnecting = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state?.chatHubLastReconnecting ?? fromHubs.initialState.chatHubLastReconnecting
);

export const getShowConnectionLostModal = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state.showConnectionLostModal
);

export const getIsChatRequestHubConnected = createSelector(
  getChatRequestHubConnectionState,
  (state: HubConnectionState) => isHubConnected(state)
);

export const getIsVoiceHubConnected = createSelector(
  getVoiceHubConnectionState,
  (state: HubConnectionState) => isHubConnected(state)
);

function isHubConnected(state: HubConnectionState): boolean {
  return state === HubConnectionState.Connected || state === HubConnectionState.Reconnected;
}

const selectEchos = createSelector(
  selectHubsFeature,
  (state: fromHubs.State) => state.echos ?? []
);

const selectChatEchos = createSelector(
  selectEchos,
  (echos: HubEchoData[]) => echos.filter(e => e.hubName === HubNames.FixAgentChatRequestHub)
);

export const selectChatConnectionLogData = createSelector(
  selectChatEchos,
  (echos: HubEchoData[]) => {
    if (echos.length === 0) {
      return {};
    }
    const {hubName, duration} = echos.slice(-1)[0];
    const logData: ConnectionEchoLog = {
      hubName,
      duration,
      echos: echos.map(e => e.duration)
    };
    return logData;
  }
);

export const selectIsChatConnectionPoor = createSelector(
  getIsChatRequestHubConnected,
  selectChatEchos,
  (isConnected: boolean, echos: HubEchoData[]) => {
    if (!isConnected) {
      return false;
    }
    const lastEcho = echos.slice(-1)[0];
    return Boolean(lastEcho && lastEcho.duration > 275);
  }
);
