// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { StatePersister } from '../../services/state-persister';
import * as fromChatUi from './chat-ui.reducer';

@Injectable({
  providedIn: 'root'
})
export class ChatUiPersisterService extends StatePersister<fromChatUi.State> {
  constructor() {
    super(localStorage, fromChatUi.chatUiFeatureKey);
  }

  afterGet(state: fromChatUi.State): fromChatUi.State {
    if (state?.entities) {
      Object.entries(state.entities).forEach(([_, entity]) => {
        // remove properties that should not be loaded from storage
        delete entity.isCustomerTyping;
        delete entity.textEditorContent;
      });
      state.unFocusedChat = null;
    }
    return state;
  }
}
