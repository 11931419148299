// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, Inject, OnInit, AfterViewInit, NgZone, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoggingFactoryService, AuthService, AccessToken, DecrementingTimerService, EnvironmentService, DayTimeService, TimerFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { skip, takeUntil, tap, distinctUntilChanged, map, take, filter } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import { SubscriberComponent } from 'src/app/subscribed-container';
import {
  AgentCustomSettings,
  Chat, AppState, AgentAuthActions,
  LogHelper, AgentOperations, AgentStatePersisterService, AvailabilityChange, getNpsSurveyData, AgentActions, AppActions, DispositionSelection, UiActions, hasFeatureFlag, FeatureFlags, ChatOperations, LogActions, ChatActions, SettingsActions
} from 'projects/entities/src/public_api';
import { MercuryRoutes } from '../../constants/constants';
import { AvailabilityType, NavigationButtons } from 'projects/entities/src/lib/domain/models/enums';
import { SessionLockOutSettings } from 'projects/entities/src/lib/domain/models/sessionLockOutSettings';
import { NpsSurveyService } from 'projects/entities/src/lib/services/nps-survey.service';
import { SurveyResponse } from 'projects/entities/src/lib/domain/models/SurveyResponse';
import { NpsSurveyData } from 'projects/entities/src/lib/domain/models/nps-survey-data';
import { fromAgent, fromChat, fromConnectAccount, fromHubs, fromPlaceholders, fromSettings, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { NavigationSources, PageNames } from 'projects/entities/src/lib/constants/constants';
import { CeeLeftNavigationButton } from '@cxt-cee-chat/merc-pattern-lib/components/left-navigation/cee-left-navigation-button/cee-left-navigation-button';
import { UnFocusedChat } from 'projects/entities/src/lib/domain/models/unFocusedChat';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { AppStateService } from 'projects/entities/src/lib/services/app-state-service';
import { AppConfigService } from 'src/app/services/app-config.service';

const mercuryButton = {
  id: NavigationButtons.Mercury,
  text: 'Mercury',
  imagePath: '/assets/img/mercury-logo-square-darkbg.png',
  clickable: false
} as CeeLeftNavigationButton;

const helpButton = {
  id: NavigationButtons.Help,
  iconPath: '/assets/img/svg-sprite.svg#icon-help',
  text: 'Help',
  clickable: true,
  tooltipText: 'Go to mySupport',
  tooltipWidth: 140
} as CeeLeftNavigationButton;

const previousSummaryButton = {
  id: NavigationButtons.PreviousSummaries,
  iconPath: '/assets/img/svg-sprite.svg#icon-previoussummaries',
  text: 'Previous Summaries',
  clickable: true,
  tooltipText: 'Previous Summaries',
  tooltipWidth: 140
} as CeeLeftNavigationButton;

@Component({
  selector: 'merc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends SubscriberComponent implements OnInit, AfterViewInit, OnDestroy {
  pageName: string = PageNames.home;
  hasActiveChats: boolean = false;
  hasEnvBanner: boolean = false;
  sessionTimer: DecrementingTimerService;
  lockoutWarningOpened: boolean = false;
  timeoutId;
  warningDuration: number;
  countdownDuration: number;
  timeElapsed: number;
  logoffCountdownDuration: number;

  isConnectAccountModalActive$: Observable<boolean>;
  isCloseChatModalActive$: Observable<boolean>;
  isConversationSummaryModalActive$: Observable<boolean>;
  isTransferModalActive$: Observable<boolean>;
  npsModalSurveyUrl$: Observable<string>;
  npsSurveyUrl$: Observable<string>;
  showConnectionLostModal$: Observable<boolean>;
  selectedDisposition$: Observable<DispositionSelection>;
  surveyPromptActions$: Observable<SurveyResponse>;
  hasActiveChats$: Observable<boolean>;
  isChatAsync$: Observable<boolean>;
  selectedChatId$: Observable<string>;
  agentSettings$: Observable<AgentCustomSettings>;
  agentSummaryOpen$: Observable<boolean>;
  hideAnimations$: Observable<boolean>;
  isSessionLockoutWarningModalActive$: Observable<boolean>;
  hasBanner$: Observable<boolean>;
  bottomButtons$: Observable<CeeLeftNavigationButton[]>;
  topButtons$: Observable<CeeLeftNavigationButton[]>;
  bottomButtonsAboveLine$: Observable<CeeLeftNavigationButton[]>;
  themeDark$: Observable<boolean>;
  isMySupportFlagOn$: Observable<boolean>;
  activeLink$: Observable<string>;
  itgModalOpen$: Observable<boolean>;
  showRateConnectionQuality$: Observable<boolean>;
  showRateConnectionQualityModals$: Observable<boolean>;
  lastClosedChatId$: Observable<string>;
  isEditPlaceholderPanelOpen$: Observable<boolean>;
  isCloseTransferModalActive$: Observable<boolean>;
  showDismissSuggestionFeedbackModal$: Observable<boolean>;
  lastDismissedSuggestionChatId$: Observable<string>;
  showConversationSummary$: Observable<boolean>;
  isCxGptFlagEnabled$: Observable<boolean>;
  hasDispositions$: Observable<boolean>;


  constructor(
    private appStore: Store<AppState>,
    private appStateService: AppStateService,
    private router: Router,
    private loggingFactory: LoggingFactoryService,
    private agentStatePersisterService: AgentStatePersisterService,
    private timerFactory: TimerFactoryService,
    private ngZone: NgZone,
    private environmentService: EnvironmentService,
    private npsSurveyService: NpsSurveyService,
    private timeService: DayTimeService,
    private appConfigService: AppConfigService,
    @Inject(AuthService) protected authService: AuthService
  ) {
    super();
    const beforeunload$ = fromEvent(window, 'beforeunload');
    const unload$ = fromEvent(window, 'unload');
    const mouseMove$ = fromEvent(window, 'mousemove');
    const keyDown$ = fromEvent(window, 'keydown');

    this.ngZone.runOutsideAngular(() => {
      mouseMove$.pipe(
        tap(() => {
          this.onAgentActivity();
        }),
        takeUntil(this.destroyed$)
      ).subscribe();

      keyDown$.pipe(
        tap(() => {
          this.onAgentActivity();
        }),
        takeUntil(this.destroyed$)
      ).subscribe();
    });

    beforeunload$.pipe(
      tap(() => this.appStore.dispatch(ChatActions.persistChats())),
      tap((e: Event) => {
        this.onBeforeUnload(e);
      }),
      takeUntil(this.destroyed$)
    ).subscribe();

    unload$.pipe(
      tap(() => {
        this.unloadHandler();
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  ngOnInit() {
    // prevent more than one window/tab from running the application
    this.subscriptions.push(
      this.appStateService.preventMultipleInstances().subscribe()
    );

    // reroutes to session timeout if timed out
    const sessionTimeoutInfoSub = this.appStore.select(fromAgent.getSessionTimeoutInformation)
      .pipe(take(1))
      .subscribe(sessionTimeoutInformation => {
        if (sessionTimeoutInformation){
          this.router.navigate([MercuryRoutes.SessionTimeoutComponent]);
        }
      }
    );
    this.subscriptions.push(sessionTimeoutInfoSub);

    this.sessionTimer = this.timerFactory.createDecrementingTimer(this.ngZone);

    this.authService.getToken().then((token: AccessToken) => {
      if (!token.advancedAgent) { return; }

      if (this.agentStatePersisterService.selectedGroups) {
        this.appStore.dispatch(AgentActions.UpdateSelectedAgentGroups(this.agentStatePersisterService.selectedGroups));
        if (!this.agentStatePersisterService.registeredId || !this.agentStatePersisterService.username) {
          this.appStore.dispatch(AgentAuthActions.logIn());
        }
      } else {
        this.router.navigate([MercuryRoutes.AdvancedLogin]);
      }
    });

    this.appStore.select(fromHubs.getHubsInitialized)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (!response) {
          this.appStore.dispatch(AppActions.AppInitialized());
        }
      });

    this.hasActiveChats$ = this.appStore.select(fromChat.hasChats);
    this.hasActiveChats$.pipe(takeUntil(this.destroyed$)).subscribe((hasActiveChats: boolean) => this.hasActiveChats = hasActiveChats);

    const lockoutSub = this.appStore
      .select(fromSettings.getSessionLockOutSettings)
      .pipe(
        skip(1),
        distinctUntilChanged()
      )
      .subscribe((data: SessionLockOutSettings) => {
        this.warningDuration = data.warningDuration;
        this.countdownDuration = data.countdownDuration;
        this.logoffCountdownDuration = data.logoffCountdownDuration;
        this.checkTimeOut();
      });
    this.subscriptions.push(lockoutSub);

    this.showConnectionLostModal$ = this.appStore.select(fromHubs.getShowConnectionLostModal);

    this.hideAnimations$ = this.appStore.select(fromSettings.hideAnimations);

    // to track total time in busy state for session lockout
    if (!this.agentStatePersisterService.availabilityStatus) {
      const availArgs: AvailabilityChange = {
        available: AvailabilityType.Unavailable,
        reason: 'Busy',
        timestamp: Date.now()
      };
      this.agentStatePersisterService.storeAvailability(availArgs);
    }

    this.hasEnvBanner = !this.environmentService.isProd();

    this.hasBanner$ = this.appStore.select(fromUi.getShowNotificationBanner);

    this.appStore.select(fromChat.getUnFocusedChat)
      .pipe(
        filter((payload) => !!payload),
        distinctUntilChanged(),
        takeUntil(this.destroyed$))
      .subscribe((chat: UnFocusedChat) => {
        const logPayload = {
          logType: LogType.chat,
          operationName: ChatOperations.ChatUnfocus,
          data: {
            elapsedTime: this.timeService.unix() - chat.focusedTimestamp
          },
          chat: new Chat({ chatId: chat.chatId})
        };
      this.appStore.dispatch(LogActions.logChatAction({logPayload}));
    });

    this.themeDark$ = this.appStore.select(fromSettings.getIsDarkTheme);

    const sessionTimerSub = this.sessionTimer.timeRemaining.subscribe((timer) => {
      this.timeElapsed = this.countdownDuration - timer;
      if (timer === this.warningDuration) {
        this.onLockoutOpen();
      } else if (timer === 0) {
        this.appStore.dispatch(AgentActions.UpdateLockout({ isLockedOut: true }));
      }
    });
    this.subscriptions.push(sessionTimerSub);


    this.npsModalSurveyUrl$ = this.appStore.select(getNpsSurveyData).pipe(
      map((npsSurveyData: NpsSurveyData) => {
        const {surveyConfiguration, lastSurveyCompleteDate, featureFlags, agentGroups, businessUnits, agentNetworkId} = npsSurveyData;
        const showSurvey = this.npsSurveyService.showNpsSurvey(surveyConfiguration, lastSurveyCompleteDate, featureFlags, agentGroups, businessUnits, true);
        return showSurvey ? this.npsSurveyService.generateUrl(agentGroups, businessUnits, featureFlags, agentNetworkId) : '';
      }),
      distinctUntilChanged()
    );

    this.npsSurveyUrl$ = this.appStore.select(getNpsSurveyData).pipe(
      map((npsSurveyData: NpsSurveyData) => {
        const {featureFlags, agentGroups, businessUnits, agentNetworkId} = npsSurveyData;
        return this.npsSurveyService.generateUrl(agentGroups, businessUnits, featureFlags, agentNetworkId);
      }),
      distinctUntilChanged()
    );

    this.topButtons$ = this.appStore.select(fromUi.getTopButtonsData)
      .pipe(
        takeUntil(this.destroyed$),
        map(({hasChatNotification, agentSummaryOpen, hasAgentSummary}) => {
          return this.getTopButtons(hasChatNotification, agentSummaryOpen, hasAgentSummary);
    }));

    this.isMySupportFlagOn$ = this.appStore.select(hasFeatureFlag(FeatureFlags.MySupportOutboundLinks));
    
    this.showConversationSummary$ = this.appStore.select(fromSettings.getShowConversationSummary);

    this.bottomButtonsAboveLine$ = this.showConversationSummary$
    .pipe(
      takeUntil(this.destroyed$),    
      map((showConversationSummary) => {
        return this.getBottomButtonsAboveLine(showConversationSummary);
    }));

    this.bottomButtons$ = this.appStore.select(fromUi.getBottomButtonsData)
      .pipe(
        takeUntil(this.destroyed$),
        map(({settingsActive, showSettingsNotification, agentFeatureBannerAckTimestamp, isBannerFlagOn}) => {
          return this.getBottomButtons(settingsActive, showSettingsNotification, agentFeatureBannerAckTimestamp, isBannerFlagOn);
    }));

    this.selectedChatId$ = this.appStore.select(fromChat.getSelectedChatId);
    this.isConnectAccountModalActive$ = this.appStore.select(fromConnectAccount.getIsModalOpen);
    this.isCloseChatModalActive$ = this.appStore.select(fromUi.getIsCloseChatModalOpen);
    this.isConversationSummaryModalActive$ = this.appStore.select(fromUi.getIsConversationSummaryModalOpen);
    this.isTransferModalActive$ = this.appStore.select(fromUi.transferChatModal.getIsModalOpen);
    this.selectedDisposition$ = this.appStore.select(fromChat.getSelectedDisposition);
    this.surveyPromptActions$ = this.appStore.select(fromAgent.getSurveyPrompt);
    this.isChatAsync$ = this.appStore.select(fromChat.getSelectedChatIsAsync);
    this.agentSettings$ = this.appStore.select(fromSettings.getCustomSettings).pipe(distinctUntilChanged());
    this.agentSummaryOpen$ = this.appStore.select(fromUi.getAgentSummaryOpen);
    this.isSessionLockoutWarningModalActive$ = this.appStore.select(fromUi.getIsSessionLockoutWarningModalOpen);
    this.activeLink$ = this.appStore.select(fromUi.getLeftNavigationActiveLinkId);
    this.itgModalOpen$ = this.appStore.select(fromUi.getItgModalOpen);
    this.showRateConnectionQuality$ = this.appStore.select(fromUi.getShowRateConnectionQuality);
    this.showRateConnectionQualityModals$ = this.appStore.select(fromUi.getShowRateConnectionModals);
    this.lastClosedChatId$ = this.appStore.select(fromAgent.getLastClosedChatId);
    this.isEditPlaceholderPanelOpen$ = this.appStore.select(fromPlaceholders.getIsEditPlaceholderPanelOpen);
    this.isCloseTransferModalActive$ = this.appStore.select(fromUi.getCloseTransferModalOpen);
    this.isCxGptFlagEnabled$ = this.appStore.select(fromSettings.hasFeatureFlag(FeatureFlags.CxGpt));
    this.showDismissSuggestionFeedbackModal$ = this.appStore.select(fromUi.getShowDismissSuggestionFeedbackModal);
    this.lastDismissedSuggestionChatId$ = this.appStore.select(fromAgent.getLastDismissedSuggestionChatId);
    this.hasDispositions$ = this.appStore.select(fromChat.hasDispositions);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sessionTimer.dispose();
  }

  onAgentActivity() {
    // if the timer is running and the lockout warning isnt opened, stop timer
    if (this.sessionTimer.isRunning && !this.lockoutWarningOpened) {
      this.sessionTimer.stop();
      clearTimeout(this.timeoutId);
      this.checkTimeOut();
    }
  }

  checkTimeOut() {
    // wait 10 seconds, start checking for inactivity again
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => {
        if (!this.sessionTimer.isRunning) {
          this.sessionTimer.start(this.countdownDuration);
        }
      }, 10000);
    });
  }

  ngAfterViewInit() {
    if (this.agentStatePersisterService.registeredId) {
      //REFRESH
      this.loggingFactory.setUserContext({
        userId: this.agentStatePersisterService.username
      });
      this.loggingFactory.setSessionContext({
        sessionId: this.agentStatePersisterService.registeredId
      });

      LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.WindowLoad,
        {
          message: 'Refresh',
          sessionId: this.agentStatePersisterService.registeredId,
          userId: this.agentStatePersisterService.username
        });
    } else {
      //New Session either first time login, login after logout or new window
      LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.WindowLoad, { message: 'New Session' });
    }
  }

  onBeforeUnload(e: Event) {
    if (this.hasActiveChats) {
      this.preventUnloadEvent(e);
    }
  }

  preventUnloadEvent(e: Event){
    (e || window.event).returnValue = false;
  }

  unloadHandler() {
    this.sessionTimer.dispose();

    LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.WindowUnload,
      {
        sessionId: this.agentStatePersisterService.registeredId,
        userId: this.agentStatePersisterService.username
      });
  }

  onNavButtonClick(buttonId) {
    switch (buttonId) {
      case NavigationButtons.Help:
        this.appStore.dispatch(AgentActions.NavigateToSupportPage({ source: NavigationSources.actionBar }));
        break;
      case NavigationButtons.Settings:
        this.appStore.dispatch(UiActions.ToggleAgentSettingsPanel({ isOpen: true }));
        break;
      case NavigationButtons.Conversations:
        this.appStore.dispatch(UiActions.ToggleAgentSummaryPanel({ toggleAgentSummaryPanel: false }));
        break;
      case NavigationButtons.Summary:
        this.appStore.dispatch(UiActions.ToggleAgentSummaryPanel({ toggleAgentSummaryPanel: true }));
        break;
      case NavigationButtons.PreviousSummaries:
        this.appStore.dispatch(UiActions.TogglePreviousChatSummariesPanel({ isOpen: true }));
        break;
    }
  }

  getTopButtons(hasNotification: boolean, agentSummaryOpen: boolean, hasAgentSummary: boolean) {
    const conversationIcon =
    agentSummaryOpen ?
    'icon-conversations-outline' : 'icon-conversations';

    const summaryIcon =
    agentSummaryOpen ?
    'icon-agents' : 'icon-agents-outline';

    const conversationButton = {
      id: NavigationButtons.Conversations,
      iconPath: `/assets/img/svg-sprite.svg#${conversationIcon}`,
      text: 'Conversations',
      tooltipText: 'Conversations',
      clickable: hasAgentSummary,
      hasNotification
    } as CeeLeftNavigationButton;

    const summaryButton = {
      id: NavigationButtons.Summary,
      iconPath: `/assets/img/svg-sprite.svg#${summaryIcon}`,
      text: 'Agent Availability Summary',
      clickable: true,
      tooltipText: 'Agent availability summary',
      tooltipWidth: 140,
    } as CeeLeftNavigationButton;

    if (hasAgentSummary) {
      return [
        conversationButton,
        summaryButton
      ];
    } else {
      return [conversationButton];
    }
  }

  getBottomButtonsAboveLine(showConversationSummary: boolean) {    
    const bottomButtonsAboveLine = showConversationSummary ? [
      previousSummaryButton,
      helpButton      
    ] : [helpButton];

    return bottomButtonsAboveLine;
  }

  getBottomButtons(settingsActive: boolean, showSettingsNotification: boolean, agentFeatureBannerAckTimestamp: string, isBannerFlagOn: boolean) {
    const settings: CeeLeftNavigationButton = {
      id: NavigationButtons.Settings,
      iconPath: settingsActive ? '/assets/img/svg-sprite.svg#icon-settings-fill' : '/assets/img/svg-sprite.svg#icon-settings',
      text: 'Settings',
      clickable: true,
      notificationBubble: {
        text: this.appConfigService.featureAnnouncementText,
        icon: 'present',
        height: 50,
        width: 320,
        active: this.shouldShowNotificationBubble(settingsActive, agentFeatureBannerAckTimestamp, isBannerFlagOn)
      },
      hasNotification: showSettingsNotification
    };

    return [
      settings,
      mercuryButton
    ];
  }

  onLockoutOpen(): void {
    this.lockoutWarningOpened = true;
    this.appStore.dispatch(UiActions.openSessionLockoutWarningModal({elapsedTime: this.timeElapsed}));
  }

  onLockoutClosed(): void {
    this.lockoutWarningOpened = false;
    // stop session timer, wait 10 seconds and restart check for inactivity
    this.sessionTimer.stop();
    this.checkTimeOut();

    const warningElapsedTime = this.warningDuration - (this.countdownDuration - this.timeElapsed);
    this.appStore.dispatch(UiActions.closeSessionLockoutWarningModal({elapsedTime: this.timeElapsed, warningElapsedTime}));
  }

  onNotificationClicked(id: string, closeClicked: boolean) {
    switch (id) {
      case NavigationButtons.Settings:
        this.appStore.dispatch(SettingsActions.sendNewFeatureBannerAcknowledgement({ featureBannerAcknowledgementTimeStamp: new Date(), isCloseAction: closeClicked }));
        break;
    }
  }

  private shouldShowNotificationBubble(settingsActive: boolean, agentFeatureBannerAckTimestamp: string, isBannerFlagOn: boolean) {
    const featureTimestamp = this.appConfigService.releaseDate;
    const isNewestFeatureAcknowledged = agentFeatureBannerAckTimestamp && featureTimestamp
      && (this.timeService.unix(agentFeatureBannerAckTimestamp) >= this.timeService.unix(featureTimestamp));

    return isBannerFlagOn && !settingsActive && !isNewestFeatureAcknowledged;
  }
}

