<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
    callId: currentCallId$ | async,
    callSummary: callSummary$ | async,
    customerName: customerName$ | async
  } as data">
  <div class="merc-session-summary--error" *ngIf="data.callSummary?.error; else callSummary">
    <div class="merc-session-summary--error-icon">
      <svg class="cee-icon merc-session-summary--error-icon-news">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-news"></use>
      </svg>
      <svg class="cee-icon cee-icon--lg merc-session-summary--error-icon-alert">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-alert-fill"></use>
      </svg>
    </div>
    <cee-banner-v2 type="error">
      Unable to retrieve session summary.
    </cee-banner-v2>
    <cee-button-v2 *ngIf="sessionSummaryState === SessionSummaryState.Default" type="primary" (buttonClick)="retrySessionSummary(data.callId)">
      <span>Retry</span>
    </cee-button-v2>
  </div>
  <ng-template #callSummary>
    <div class="merc-session-summary--description">
      {{ getDescription() }}
    </div>
    <div *ngIf="showCallerName()" class="merc-session-summary--caller-detail">
      <cee-avatar [customerName]="data.customerName" customerNumber="1"></cee-avatar>
      <span class="merc-session-summary--caller-detail-name">{{ data.customerName }}</span>
    </div>
    <div class="merc-session-summary--detail">
      <h4 class="merc-session-summary--detail-header">Issue</h4>
      <span *ngIf="data.callSummary; else loading">{{ data.callSummary.issue }}</span>
    </div>
    <div class="merc-session-summary--detail">
      <h4 class="merc-session-summary--detail-header">Resolution</h4>
      <span *ngIf="data.callSummary; else loading">{{ data.callSummary.resolution }}</span>
    </div>
    <div class="merc-session-summary--footer" *ngIf="data.callSummary && !data.callSummary.error">
      <div class="merc-session-summary--footer-rating">
        <div class="merc-session-summary--footer-rating-feedback" *ngIf="data.callSummary.rated; else rateSummary">
          <span *ngIf="data.callSummary.isPositiveRating">Thanks for your feedback!</span>
          <span *ngIf="!data.callSummary.isPositiveRating">Your feedback helps up improve. Please edit the summary before saving in Einstein.</span>
        </div>
        <ng-template #rateSummary>
          <cee-thumb-rating ratingLabel="Is this summary accurate?"
            (positiveRating)="positiveRating()"
            (negativeRating)="negativeRating()"
          >
          </cee-thumb-rating>
        </ng-template>
      </div>
      <div class="merc-session-summary--footer-copy-content">
        <cee-button-v2
          type="link"
          [tooltipText]="copySummaryTooltipText"
          tooltipId="copy-summary"
          tooltipDirection="bottom"
          tooltipAppearanceDelayMs="0"
          tooltipPersistDirective="true"
          [disabled]="!data.callSummary"
          (buttonClick)="copyToClipboard(data.callSummary.issue, data.callSummary.resolution)"
          (mouseout)="resetCopyText()">
          <svg class="cee-icon cee-icon--md" >
            <use [attr.href]="'/assets/img/svg-sprite.svg#icon-copy-v2'"></use>
          </svg> <span>Copy to Clipboard</span>
        </cee-button-v2>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <div class="loading-full-line"></div>
  <div class="loading-partial-line"></div>
</ng-template>
