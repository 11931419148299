// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromUi } from 'projects/entities/src/lib/domain/selectors';
import { ChatImageMessage, AppState, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-convo-image-modal',
  templateUrl: './convoImageModal.component.html',
  styleUrls: ['./convoImageModal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoImageModalComponent {
  public image$: Observable<ChatImageMessage>;

  constructor(private store: Store<AppState>)
  {
    this.image$ = this.store.select(fromUi.getImageModalValue);
  }

  public modalClose() {
    this.store.dispatch(UiActions.ImageModalClosed());
  }
}
