<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-login-background cee-theme-dark"  *ngIf="!(isLoading$ | async)">
    <div class="merc-login-logo">
        <img src="../../../assets/img/mercury-with-text.png" class="merc-login-image"/>
    </div>
    <merc-login-banner></merc-login-banner>
    <merc-login-form></merc-login-form>
    <div class="login-switch-link">
      <cee-button type="link" (buttonClick)="switchToSso()">SSO Login</cee-button>
    </div>
</div>

