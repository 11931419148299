// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingFactoryService, LoggingLevel } from '@cxt-cee-chat/merc-ng-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { OpenSettingsSources } from '../../constants/constants';
import { AgentOperations } from '../../constants/event-logs.constants';
import { FeatureFlags } from '../../constants/featureFlags.constants';
import { PageInitializationConstants } from '../../constants/page-initialization.constants';
import { AppConfigApiService } from '../../services/app-config-api.service';
import { PageInitializationHelperService } from '../../services/page-initialization-helper.service';
import { LogHelper } from '../../utils/logHelper';
import { SettingsActions, UiActions } from '../actions';
import { ItgDetails } from '../models/itg-details';
import { fromSettings } from '../selectors';
import { AppState } from '../state';
import { SettingsPersisterService } from './settings-persister.service';

/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class SettingsEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: SettingsPersisterService,
    private loggingFactory: LoggingFactoryService,
    private appConfigService: AppConfigApiService,
    private pageInitHelper: PageInitializationHelperService
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(SettingsActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? SettingsActions.hydrateSuccess({ state })
          : SettingsActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(
    () =>
      this.action.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for Settings state change
        ofType(SettingsActions.hydrateSuccess, SettingsActions.hydrateFailure),
        switchMap(() => this.store.pipe(select(fromSettings.selectSettingsFeature))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(state => {
          this.persisterService.storeState(state);
        })
      ),
    { dispatch: false }
  );

  updateDebugLoggingLevel$ = createEffect(() =>
    this.action.pipe(
      ofType(SettingsActions.applicationConfigurationLoaded),
      concatLatestFrom(() => this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.LogDebug))),
      filter(([, logDebug]) => logDebug),
      tap(() => {
        this.loggingFactory.setGlobalLoggingLevel(LoggingLevel.Debug);
      })
    ),
    { dispatch: false }
  );

  openSettingsPanel$ = createEffect(() =>
    this.action.pipe(
      ofType(UiActions.ToggleAgentSettingsPanel),
      filter(({isOpen}) => isOpen),
      tap(() => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.OpenSettingsPanel, { source: OpenSettingsSources.menu });
      })
    ),
    { dispatch: false }
  );

  openSettingsFromNewFeatureAnnouncement$ = createEffect (() =>
    this.action.pipe(
      ofType(SettingsActions.sendNewFeatureBannerAcknowledgement),
      filter(({isCloseAction}) => !isCloseAction),
      tap(() => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.OpenSettingsPanel, { source: OpenSettingsSources.announcement });
      })
    ),
    { dispatch: false }
  );

  updateAgentSettingsActiveTab$ = createEffect(() =>
    this.action.pipe(
      ofType(UiActions.UpdateAgentSettingsActiveTab),
      filter(({activeTabTitle}) => Boolean(activeTabTitle)),
      tap(({activeTabTitle}) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.NavSettingsTab, { tabName: activeTabTitle });
      })
    ),
    { dispatch: false }
  );

  getItgList$ = createEffect(() =>
    this.action.pipe(
      ofType(SettingsActions.getItgList),
      tap(() => {
        this.pageInitHelper.updateStatePending(PageInitializationConstants.ItgList);
        const getItgListPromise = this.appConfigService.itgList();
        getItgListPromise.then((response: HttpResponse<ItgDetails[]>) => {
          this.store.dispatch(SettingsActions.ItgListLoaded({ list: response.body }));
        });
        this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.ItgList, getItgListPromise);
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return SettingsActions.hydrate();
  }
}
