// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export const ErrorMessages = {
  profanityCheckFailedOnSave: 'Remove inappropriate words before saving.',
  sendMessageFailed: 'Oops, something is wrong here.  Wait a few moments and try to enter the message again. If problem persists, please contact your supervisor for assistance.',
  customerTranslationFailed: 'Failed to translate message.',
  failedTranslateMessage: 'Failed to translate message. Message not sent.'
};

export const ItgErrorMessages = {
  retrieveNextStepFailed: 'Failed to retrieve next step',
  sendStepFailed: 'Failed to send next step',
  disconnect: 'Agent disconnected'
};

export const MessageInputErrorMessages = {
  maskingFailed: 'Masking failed, try again.',
  generateResponseError: 'An error occurred while generating the response.',
  enhanceError: 'Something happened. Unable to Enhance message.'
};

export const MessageInputInfoMessages = {
  generativeContent: 'Always review generative content before sending.'
};
