<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div
  class="merc-convo-message--xa-response"
  [class.merc-convo-message--xa-response--has-card]="chatMessage.xaMessage"
  [ngClass]="'merc-convo-message--xa-response--' + (chatMessage.status | toMessageState)">
  <ng-container *ngIf="chatMessage.xaMessage">
    <cee-chat-message
      [messageSenderType]="'agent'"
      [messageDateTime]="this.getFormattedTime(chatMessage.timestamp)"
      [messageText]="formatDataCard(chatMessage.xaMessage)"
      [showPin]="_showPin && (hasPinFeatureFlag$ | async)"
      [isPinned]="isPinned$ | async"
      (togglePin)="togglePin($event)"
    ></cee-chat-message>
  </ng-container>
  <cee-chat-message *ngIf="{
    translatedMessageText: translatedMessageText$ | async
  } as data"
    [messageSenderType]="'agent'"
    [messageDateTime]="this.getFormattedTime(chatMessage.timestamp)"
    [messageText]="chatMessage.message"
    [languageTooltipText]="data.translatedMessageText"
    [languageTooltipDirection]="'left'"
    [isStacked]="chatMessage.xaMessage"
    [messageCustomerNumber]="colorId"
    [showPin]="_showPin && (hasPinFeatureFlag$ | async)"
    [isSelected]="isMessageSelected"
    [isPinned]="isPinned$ | async"
    (togglePin)="togglePin($event)"
    [messageState]="chatMessage.status | toMessageState"
    [failedStateMessage]="failedStateMessage"
    [hideAnimation]="hideAnimations$ | async"
    (messageClick)="selectMessage()"
    (retryClick)="retrySendXaSuggestion()"
    (deleteClick)="deleteMessage()"
  ></cee-chat-message>
</div>
