// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { EngagementTranscript } from 'projects/entities/src/lib/domain/models/chatTranscript';
import { SenderType } from 'projects/entities/src/public_api';

/*
 * Sorts Engagement Transcripts by date, 
 * from earliest to most recent
 * Usage:
 *   EngagementTranscript[] | sortAsyncEngagement
*/


@Pipe({name: 'sortAsyncEngagement'})
export class SortAsyncEngagementPipe implements PipeTransform {
  transform(transcript: EngagementTranscript[]): EngagementTranscript[] {
    let sortedResult: EngagementTranscript[];

    if (transcript) {
      sortedResult = transcript.slice();
      sortedResult = sortedResult.sort((a, b) => {
          return a.createDate - b.createDate;
        })
        .map(e => {
          const newEngagement = Object.assign(new EngagementTranscript(), e, {
            interactions: e.interactions.filter(
              i => i.sender !== SenderType.System
            )
          });

        return newEngagement;
      });

      return sortedResult;
    }
  }
}