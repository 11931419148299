<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-confirmation-modal
  [headerText]="(dispositions$ | async)?.length > 0 ? 'Dispositions' : 'End Session'"
  [active]="active"
  [disabledConfirmButton]="(asyncResolution == '' && isAsync) || ((dispositions$ | async)?.length > 0 && disableConfirmation)"
  (modalClose)="closeModal($event)"
  (confirmClicked)="closeChat()"
  (cancelClicked)="closeModal($event)"
  confirmText="End session"
  cancelText="Cancel"
>
  <div *ngIf="!isAsync; else asyncClose">
    <ng-container *ngIf="(dispositions$ | async)?.length > 0; else closeChat">
      <p>
        Please select a status for this conversation:
      </p>
      <merc-dispositions [parentContainer]="'close'" [chatId]="chatId" [isV2]="hasHideSummaryFeatureFlag$ | async"></merc-dispositions>
      <p>
        Ending this session will remove it from your desktop. Are you sure you want to end?
      </p>
    </ng-container>
    <ng-template #closeChat>
      <p>
        Do you want to end this session? It will be removed from your desktop.
      </p>
    </ng-template>
  </div>
  <ng-template #asyncClose>
    <p class="cee-body">Please select a status for this conversation:</p>
    <div class="cee-form-row">
      <input
        id="async-convo-state-resolved"
        type="radio"
        name="async-convo-state"
        value="resolved"
        [(ngModel)]="asyncResolution"
        class="cee-radio"
      />
      <label class="cee-radio-label" for="async-convo-state-resolved">
        Resolved - Customer is all set
      </label>
    </div>
    <div class="cee-form-row">
      <input
        id="async-convo-state-unresolved"
        type="radio"
        name="async-convo-state"
        value="unresolved"
        [(ngModel)]="asyncResolution"
        class="cee-radio"
      />
      <label class="cee-radio-label" for="async-convo-state-unresolved">
        Paused - Customer is not done yet
      </label>
    </div>
    <div
      class="cee-form-row cee-form-row--child"
      *ngIf="asyncResolution === 'unresolved'"
    >
      <label class="cee-form-label">Reason for contact: </label>
      <input
        type="text"
        class="cee-form-input"
        id="contact-reason"
        name="contact-reason"
        aria-describedBy="account-error-msg"
        [(ngModel)]="asyncContactReason"
      />
    </div>
    <p class="cee-body">
      Ending this session will remove it from your desktop. Are you sure
      you want to end?
    </p>
  </ng-template>
</cee-confirmation-modal>
