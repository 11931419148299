// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ErrorHandler, NgModule  } from '@angular/core';

import { APP_MODULE_DECLARATIONS } from './app-module.declarations';
import { APP_MODULE_IMPORTS } from './app-module.imports';
import { APP_MODULE_BOOTSTRAP } from './app-module.bootstrap';
import { APP_MODULE_SCHEMAS } from './app-module.schemas';
import { APP_MODULE_PROVIDERS } from './app-module.providers';
import { authenticationProvider } from '../authentication-factory';
import { GlobalErrorHandlerService } from '@cxt-cee-chat/merc-ng-core';

@NgModule({
   declarations: APP_MODULE_DECLARATIONS,
   imports: APP_MODULE_IMPORTS,
   providers: [
      APP_MODULE_PROVIDERS,
      authenticationProvider,
      { 
         provide: ErrorHandler, 
         useClass: GlobalErrorHandlerService 
      }
   ],
   bootstrap: APP_MODULE_BOOTSTRAP,
   schemas: APP_MODULE_SCHEMAS  
})
export class AppModule { }
