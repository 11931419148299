// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { Customer, AppState, Color, ChatOperations, ChatActions, Chat } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import {CtiFocusApplicationType} from 'projects/entities/src/lib/domain/models/enums';
import {CtiFocusApplication} from '../../../../projects/entities/src/lib/domain/models/ctiFocusApplication';
import { EINSTEIN360 } from '../convo-list/convo-card/constants';
import { logChatAction } from 'projects/entities/src/lib/domain/log-actions.actions';
import { SubscriberComponent } from 'src/app/subscribed-container';



@Component({
  selector: 'merc-customer-info-heading',
  templateUrl: './customer-info-heading.component.html',
  styleUrls: ['./customer-info-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class CustomerInfoHeadingComponent extends SubscriberComponent implements OnInit {
  copyTooltipText: string;
  chatId$: Observable<string>;
  color$: Observable<Color>;
  customer$: Observable<Customer>;
  chat$: Observable<Chat>;
  isAuthenticated$: Observable<boolean>;

  constructor(
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    this.chatId$ = this.store.select(fromChat.getSelectedChatId);
    this.color$ = this.store.select(fromChat.getSelectedChatColor).pipe(distinctUntilChanged());
    this.customer$ = this.store.select(fromChat.getSelectedChatCustomer).pipe(distinctUntilChanged());
    this.chat$ = this.store.select(fromChat.getSelectedChat).pipe(distinctUntilChanged());
    this.isAuthenticated$ = this.store.select(fromChat.getSelectedChatAuthenticated).pipe(distinctUntilChanged());
  }

  focusButtonClick(chat: Chat, isAuthenticated: boolean){
    if (!isAuthenticated) { return; }
    const focusModel: CtiFocusApplication = {
      chatId: chat.chatId,
      applicationFocusType: CtiFocusApplicationType.Einstein360
    };
    const logPayload  = {
      logType: LogType.chat,
      operationName: ChatOperations.ApplicationFocus,
      chat,
      data: {
        method: 'customer-info-heading-focus',
        appName: EINSTEIN360
      }
    };
    this.store.dispatch(ChatActions.CtiApplicationFocus(focusModel));
    this.store.dispatch(logChatAction({logPayload}));
  }
}
