// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class Constants {
	static ConsumerId = '2F8F4AC1-894A-E911-B8B3-00155DC3367C';
	static AgentUserAliasId = 'FF5D39D0-6E4F-E911-B8B3-00155DC3367C';
	static ComcastToken = 'comcastSsoToken';
	static ComcastSupportUrl = 'https://mysupport.service-now.com/sp';
  static AutoGreetingContextCodes: string = 'E0,4H,GG,13,B6,FW,US,S1,HA,DT,AB,8P,L8,AM,F5,QK,GW,7O,H7,S2,B1,X4,BZ,TU,55,A1,R1,18,AO,CC,1I,83,HR,1O,FR,23,4C,14,DG,46,RK,JT,BD,QL,AL,G1,Z4,02,2L,EP,51,DF,IX,1K,GO,5O,6G,I6,36,28,2Q,1L,JV,Y0,2S,82,53,4A,UA,32,BL,HJ,Y7,4O,G2,5P,PC,GN,2E,44,FM,80,QV,01,BR,HB,C2,4B,4M,1M,BC,5B,20,58,47,GV,EF,1H,RL,MT,HU,11,NF,1V,B7,3R,2C,YB,10,R9,48,G9,12,4N,GJ,27,45,G5,21,15,16,3E,4G,22,IU,CH,29,BF,4W,FZ,85,KQ,IT,2R,33,GH,B3,24,25,37,CA,JF,Y9,2J,1X,H0,NG,2K,N0,34,CL,FU,5T,CV,40,68,C1,41,B2,1Y,HI,84,Q7,5R,N7,GU,UQ,0K,ER,QQ,C8,35,0T,CQ,7M,60,ML,1E,43,50,Y6,U8,IH,42,BM,ET,4E,C4,74,J5,HQ,HO,CZ,BU,09,64,76,JM,5C,57,MO,38,2W,LB,CI,H4,Z7,CT,3H,2G,E1,QW,65,RC,66,39,1N,LF,CX,C7,61,D3,54,Y1,2M,1P,G7,AD,HY,DB,4I,1Z,2U,2P,DA,63,2N,3B,R2,0Y,3K,62,DL,I5,P1,YX,3D,0M,0O,G8,07,MV,CS,04,3L,5Q,CW,XJ,HM,5J,HK,DN,2Y,JX,DH,03,1A,2O,67,6B,90,6Y,5K,IR,91,Z5,2T,TS,5A,GA,Q8,B5,IM,L1,BJ,CG,CN,IN,Z9,KW,7N,4P,4R,L2,06,GB,9J,IP,MQ,0N,JB,4L,BH,4K,D2,6R,5L,DM,G0,0C,0B,3Q,IE,HG,OT,D9,M4,HE,3Y,IZ,0D,C3,N4,J2,IS,4D,AA,DC,J8,3M,Y4,DJ,2A,0H,U7,0F,3G,RI,3S,N1,JO,AC,7E,UB,0E,3U,BA,7H,HD,Q6,7G,7S,ID,KI,GD,7U,QR,JL,D8,JG,JH,JZ,0W,0G,SE,S4,SD,BK,S9,LM,AF,I0,0X,JW,MX,N8,NZ,70,49,1Q,A3,IG,HZ,3V,IV,1R,HS,7Q,6M,QU,2D,JQ,7I,5S,6S,DS,5G,NP,SF,CF,JD,71,72,75,6T,73,79,95,96,94,52,LE,BX,6Z,8E,6W,5N,7Y,KA,JP,8J,7P,K5,NS,6Q,LR,LT,K4,4F,K6,NC,UX,Z6,BG,BE,K9,2B,JK';
}

export class PriorEngagementConstants {
	static initialMaxPagesLoaded = 5;
}

export const CustomerTypingStatusTimeoutInSeconds = 60;

export const AgentDefaultAvailabilityReasons = {
	Available: 'Available',
	Busy: 'Busy',
	Error: 'Unable to set availability',
	IncomingChat: 'Incoming chat'
};

export class NavigationSources {
	static actionBar = 'actionbar';
	static pageInit = 'pageinit';
  static feedbackModal = 'feedbackModal';
}

export class BounceBannerText {
  static warning = 'One of your conversations will be reassigned shortly. Please respond immediately.';
  static bounced = 'A conversation has been reassigned, so we\'ve set your status to Busy.';
}

export class AgentSettingsBannerText {
  static cancelled = 'Changes have been cancelled.';
  static saved = 'Changes have been saved.';
}

export class ThemeConstants {
  static dark = 'dark';
  static light = 'light';
}

export class DataCollectionButtonText {
  static requestAvailable = 'Request secure data link';
  static linkRequested = 'Link Request Pending';
}

export class DataCollectionBannerText {
  static requesting = 'Request pending. Finish request by clicking the iGuard button in E360 or XM360.';
  static expired = 'Personalized iGuard link expired. Request a new link to continue this transaction.';
  static canceled = 'The secure data form has been cancelled. To request a new link, click "Request Secure Data link" in Mercury and then click the iGuard button in E360 or XM360.';
}

export class DataCollectionSystemMessages {
  static customerStartedForm = 'Customer started form';
  static customerApplied = 'Customer applied';
  static customerClosed = 'Customer closed out of form';
  static customerCompleted = 'Customer completed form';
}

export class DataCollectionSuggestionTitles {
  static linkAvailable = 'PERSONALIZED IGUARD LINK';
  static customerApplied = 'SECURE INFORMATION FORM APPLIED';
}

export class SuggestionTitles {
  static recommendedResponse = 'RECOMMENDED RESPONSE';
  static customerReturned = 'CUSTOMER RETURNED';
  static transferred = 'Recommended Greeting';
  static defaultXa = 'Xfinity Assistant suggests';
}

export class GetUpdatesPreventedSources {
  static client = 'client';
  static server = 'server';
  static updatesComplete = 'updatesComplete';
}

export class StartGetUpdatesSources {
  static reLogIn = 'reLogIn';
  static reconnected = 'reconnected';
  static connected = 'connected';
}

export class StopGetUpdatesSources {
  static getTokenError = 'getTokenError';
  static logOut = 'logOut';
  static lockout = 'lockout';
  static platformForceDisconnect = 'platformForceDisconnect';
  static lockoutHandleOpenChats = 'lockoutHandleOpenChats';
  static reconnecting = 'reconnecting';
  static disconnected = 'disconnected';
}

export class OpenSettingsSources {
  static menu = 'menu';
  static announcement = 'annoucement';
}

export class MessageEditorPlaceholderText {
  static itgRunning = 'Pause ITG in order to send message';
}

export class PageNames {
  static home = 'Home';
  static voice = 'Voice';
}

export class Location {
  static National = '1273';
}

export const CustomerAwaitingReplyMessages = ['Thanks for waiting. Please allow me another moment', 'I appreciate your patience. I\'ll need just a few more minutes', 'I apologize it\'s taking longer than expected. I\'ll need a few more minutes'];

export const FeedbackOptions = [
 'This message was offensive or inappropriate',
  'This message was incorrect',
  'This message was not helpful'
];
