// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export const DESKTOP_TEXT: string = 'Desktop';
export const DESKTOP_ICON_PATH: string = DESKTOP_TEXT.toLowerCase();
export const WEB_TEXT: string = 'Web';
export const WEB_ICON_PATH: string = WEB_TEXT.toLowerCase();
export const SMS_TEXT: string = 'SMS';
export const SMS_ICON_PATH: string = SMS_TEXT.toLowerCase();
export const MOBILE_TEXT: string = 'Mobile';
export const MOBILE_ICON_PATH: string = MOBILE_TEXT.toLowerCase();

export const TYPING_ELLIPSIS_ANIMATED_CLASS = 'cee-ellipsis-dot';
export const TYPING_ELLIPSIS_UNANIMATED_CLASS = `${TYPING_ELLIPSIS_ANIMATED_CLASS}-hide-animation`;

export const PIN_SVG_CLASS_PINNED = 'cee-card--pin-on';
export const PIN_SVG_CLASS_UNPINNED = 'cee-card--pin-off';

export const PIN_SVG_PATH_PINNED = '/assets/img/svg-sprite.svg#icon-pin-on';
export const PIN_SVG_PATH_UNPINNED = '/assets/img/svg-sprite.svg#icon-pin-off';

export const ACSR = 'ACSR';
export const EINSTEIN360 = 'einstein360';
export const EINSTEINCHAT = 'einsteinchat';
export const ECM = 'ecm';
export const PIN = 'pin';
export const SECURE_DATA_COLLECTION = 'secureDataCollection';

export const CTI_NOT_CONNECTED_TOOLTIP = 'Turn on OneCTI to request a link';
export const ACCOUNT_NOT_CONNECTED_TOOLTIP = 'Connect account to request link';
export const PIN_DISABLED_TOOLTIP = 'Pin Unavailable';
export const PIN_DISABLED_TOOLTIP_SUBTEXT =
  'You may not pin until the customer has responded.';
export const PIN_DISABLED_RESPOND_TOOLTIP_SUBTEXT =
  'You may only pin after you respond.';