<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-template #innerTemplate>

  <h2 class="cee-h2 merc-agent-settings-tab-header">Profile</h2>
  <div class="merc-agent-settings-tab-body">
    <table class="merc-agent-settings-table">
      <tr>
        <!-- <th>Logged in as</th>
        <th>Display Name</th> -->
        <th>User Name</th>
        <!-- <th>Agent Role</th>
        <th>Location</th>
        <th>Session Id</th> -->
      </tr>
      <tr>
        <!-- <td>Name</td>
        <td></td> -->
        <td>{{username | async}}</td>
        <!-- <td></td>
        <td></td> -->
      </tr>
    </table>
  </div>

</ng-template>
