// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SenderType } from './enums';

export class ChatMember {
    type: SenderType;
    id: string;
    displayName?: string;
    enterTimestamp?: number;
    exitTimestamp?: number;
}