// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Language } from './enums';

export class UpdateUui {
    chatId: string;
    guid: string;
    uui: string;
    xaTranscriptSessionId: string;
    previousEngagementId: string;
    firstName: string;
    lastName: string;
    contactMethod: string;
    language: Language;
    customerPhoneNumber: string;
}
