// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class PageInitializationConstants {
  static VerifySessionLogIn: string = 'Verify Session/Log In';
  static ChatHubConnection: string = 'Chat Hub Connection';
  static ApplicationConfiguration: string = 'Application Configuration';
  static AgentSettings: string = 'Agent Settings';
  static Profanity: string = 'Profanity List';
  static CustomScripts: string = 'Custom Scripts';
  static CustomSettings: string = 'Custom Settings';
  static NiagaraLogHubConnection: string = 'Analytics Hub Connection';
  static GetAgentGroups: string = 'Get Agent Groups';
  static VoiceHubConnection: string = 'Voice Hub Connection';
  static ItgList: string = 'ITG List';
}
