// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { ChatContext } from 'projects/entities/src/public_api';
import { BehaviorSubject, Subject } from 'rxjs';
import { TimerComponent } from '../../timer/timer.component';
import { StyleClasses } from 'src/app/constants/constants';

@Component({
    selector: 'merc-async-closing-timer',
    templateUrl: '../../timer/timer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsyncClosingTimerComponent extends TimerComponent implements OnDestroy
{
    private _destroyed$: Subject<{}>;

    constructor(changeDetector: ChangeDetectorRef, timeHelper: TimeHelperService) {
      super(changeDetector, timeHelper);
      this._destroyed$ = new Subject<{}>();
    }

    @Input()
    public set chatContext(chatCtx: ChatContext) {
        this.timerRef = chatCtx?.customerActivityTimer;
        this.styleClass = new BehaviorSubject<string>(StyleClasses.Warn);
    }

    public ngOnDestroy(): void {
        this._destroyed$.next(null);
    }
}
