// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { NgEntityEffects } from './effects';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { LogActions, ProfileActions, SettingsActions, toPayload, UiActions } from './actions';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AgentCustomSettings } from './models/agentCustomSettings';
import { HttpResponse } from '@angular/common/http';
import { PageInitializationConstants } from '../constants/page-initialization.constants';
import { GetAgentCustomSettingsResponse } from './models/responses/get-agent-custom-settings-response';
import { Injectable } from '@angular/core';
import { fromChat, fromSettings } from './selectors';
import { LogHelper } from '../utils/logHelper';
import { AgentOperations, DebugEvents } from '../constants/event-logs.constants';
import { LogInfo, LogType } from './models/LogTypeInterfaces';
import { ObjectHelper } from '../utils/object-helper';
import { ChatHelper } from '../utils/chatHelper';
import { hasFeatureFlag } from './settings/settings.selectors';
import { FeatureFlags } from '../constants/featureFlags.constants';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Profile extends NgEntityEffects {
    loadCustomSettings$ = createEffect(() =>
        this.actions.pipe(
            ofType(ProfileActions.Load),
            tap(() => {
                this.pageInitHelper.updateStatePending(PageInitializationConstants.CustomSettings);
                const getAgentCustomSettingsPromise = this.fixAgentApiService.getAgentCustomSettings();
                getAgentCustomSettingsPromise.then(
                    (response: HttpResponse<GetAgentCustomSettingsResponse>) => {
                        if (response?.body?.settings) {
                            this.ngEntityStore.dispatch(ProfileActions.Loaded(response.body.settings));
                        }
                    }
                );
                this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.CustomSettings, getAgentCustomSettingsPromise);
            })),
        { dispatch: false}
    );
    customsettingsUpdate$ = createEffect(() =>
        this.actions.pipe(
            ofType(ProfileActions.Update),
            map(action => toPayload<AgentCustomSettings>(action)),
            concatLatestFrom(() => [this.ngEntityStore.select(fromSettings.getCustomSettings),
              this.ngEntityStore.select(fromChat.getChats),
              this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.LanguageTranslator)),
            ]),
            tap(([updateCustomSettings, originalCustomSettings, chats, isTranslationFlagOn]) => {
              this.fixagentProfileService.updateCustomSettings(updateCustomSettings);

              if (originalCustomSettings.soundMuteAll !== updateCustomSettings.soundMuteAll) {
                const logDimensions = {
                  source: 'settings'
                };
                if (updateCustomSettings.soundMuteAll) {
                  LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.MuteVolume, logDimensions);
                } else {
                  LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.UnmuteVolume, logDimensions);
                }
              } else if (originalCustomSettings.soundVolume !== updateCustomSettings.soundVolume) {
                const logDimensions = {
                  volumeLevel: updateCustomSettings.soundVolume,
                  source: 'settings'
                };

                LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChangeVolume, logDimensions);
              }

              if (isTranslationFlagOn && originalCustomSettings.translateMessages !== updateCustomSettings.translateMessages) {
                const filteredChats = chats.filter(c => {
                  const chatLanguage = ChatHelper.getCustomerQueueLanguage(c.customerLanguage, c.defaultGroupTranslationLanguage);
                  return ChatHelper.isTranslatedChat(chatLanguage, isTranslationFlagOn);
                });
                const activeTranslatableChats = filteredChats.length
                ? filteredChats.map(c => {
                  return {
                    engagementId: c.chatId,
                    ...(c.isAsync && { conversationId: c.conversationId })
                  };
                })
                : [];
                const logDimensions = {
                  isTranslating: updateCustomSettings.translateMessages,
                  activeTranslatableChats
                };
                
                LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.TranslationToggled, logDimensions);
              }

              LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SaveSettings, {changes: ObjectHelper.getDiff(originalCustomSettings, updateCustomSettings)});
            })),
        { dispatch: false}
    );

    updateLastSurveyCompleteDate$ = createEffect(() =>
      this.actions.pipe(
        ofType(SettingsActions.updateLastSurveyCompleteDate),
        withLatestFrom(
          this.ngEntityStore.select(fromSettings.getCustomSettings)
        ),
        tap(([ action, agentCustomSettings]) => {
          const updatedSettings: AgentCustomSettings = {
            ...agentCustomSettings,
            lastSurveyCompleteDate: action.surveyTimestamp
          };
          this.fixagentProfileService.updateCustomSettings(updatedSettings);
        })
      ),
      { dispatch : false }
    );

    sendQualityCheckResponse$ = createEffect(() =>
      this.actions.pipe(
        ofType(SettingsActions.sendQualityCheckResponse),
        withLatestFrom(
          this.ngEntityStore.select(fromSettings.getCustomSettings)
        ),
        tap(([{qualityCheckResponse, qualityCheckDateTime}, agentCustomSettings]) => {
          const {rating, chatId} = qualityCheckResponse;
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.QualityCheckResponse, {rating, chatId}, true);

          this._updateCustomSettings(agentCustomSettings, qualityCheckDateTime);

          if (rating <= 3) {
            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.QualityCheckFollowupOffer, {chatId}, true);
          }
        })
      ),
      { dispatch : false }
    );

    dismissQualityCheck$ = createEffect(() =>
      this.actions.pipe(
        ofType(UiActions.DismissFeedbackPrompt),
        withLatestFrom(
          this.ngEntityStore.select(fromSettings.getCustomSettings)
        ),
        filter(([{dismissFeedbackPrompt}]) => dismissFeedbackPrompt
        ),
        tap(([{chatId, qualityCheckDateTime}, agentCustomSettings]) => {
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.QualityCheckResponse, {rating: 0, chatId}, true);

          this._updateCustomSettings(agentCustomSettings, qualityCheckDateTime);
        })
      ),
      { dispatch : false }
    );

    sendNewFeatureBannerAcknowledgement$ = createEffect(() =>
      this.actions.pipe(
        ofType(SettingsActions.sendNewFeatureBannerAcknowledgement),
        withLatestFrom(
          this.ngEntityStore.select(fromSettings.getCustomSettings)
        ),
        tap(([{featureBannerAcknowledgementTimeStamp, isCloseAction}, agentCustomSettings]) => {
          const updatedSettings: AgentCustomSettings = {
            ...agentCustomSettings,
            newFeatureBannerAckTimestamp: featureBannerAcknowledgementTimeStamp.toJSON()
          };
          this.fixagentProfileService.updateCustomSettings(updatedSettings);

          const logDimensions = {
            releaseDate: this.config.releaseDate,
            releaseLabel: this.config.releaseLabel
          };
          LogHelper.logAgentEvents(this.loggingFactory, isCloseAction ? AgentOperations.DismissNewFeatureBanner : AgentOperations.ViewNewFeatureDetails, logDimensions);
        })
      ),
      { dispatch : false }
    );

    sendQualityCheckFollowupResponse$ = createEffect(() =>
      this.actions.pipe(
        ofType(SettingsActions.sendQualityCheckFollowupResponse),
        withLatestFrom(
          this.ngEntityStore.select(fromSettings.getCustomSettings)
        ),
        tap(([{qualityCheckFollowupResponse, qualityCheckDateTime}, agentCustomSettings]) => {
          this._updateCustomSettings(agentCustomSettings, qualityCheckDateTime);
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.QualityCheckFollowupResponse, qualityCheckFollowupResponse, true);
        })
      ),
      { dispatch : false }
    );

    saveVolume$ = createEffect(() =>
      this.actions.pipe(
        ofType(ProfileActions.saveVolume),
        concatLatestFrom(() => this.ngEntityStore.select(fromSettings.getCustomSettings)),
        tap(([{ volume, isMuted, source }, agentCustomSettings]) => {
          const newSettings: AgentCustomSettings = {
            ...agentCustomSettings,
            soundVolume: volume,
            soundMuteAll: isMuted
          };
          this.fixagentProfileService.updateCustomSettings(newSettings);

          const logDimensions = {
            volumeLevel: volume,
            source: source
          };
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChangeVolume, logDimensions);

        })
      ),
      { dispatch : false }
    );

    muteVolume$ = createEffect(() =>
      this.actions.pipe(
        ofType(ProfileActions.muteVolume),
        concatLatestFrom(() => this.ngEntityStore.select(fromSettings.getCustomSettings)),
        tap(([{ isMuted }, agentCustomSettings]) => {
          const newSettings: AgentCustomSettings = {
            ...agentCustomSettings,
            soundMuteAll: isMuted
          };

          this.fixagentProfileService.updateCustomSettings(newSettings);

          const logDimensions = {
            source: 'main'
          };

          if (isMuted) {
            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.MuteVolume, logDimensions);
          } else {
            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.UnmuteVolume, logDimensions);
          }
        })
      ),
      { dispatch : false }
  );

  loaded$ = createEffect(() =>
      this.actions.pipe(
        ofType(ProfileActions.Loaded),
        map(action => toPayload<AgentCustomSettings>(action)),
        tap((settings) => {
          const logPayload: LogInfo = {
            logType: LogType.debug,
            operationName: DebugEvents.QualityCheckDateTimeLoaded,
            data: {
              lastQualityCheckDateTime: settings.lastQualityCheckDateTime
            }
          };
          this.ngEntityStore.dispatch(LogActions.logDebug({logPayload}));
        })
      ),
      { dispatch : false }
  );

  updated$ = createEffect(() =>
      this.actions.pipe(
        ofType(ProfileActions.Updated),
        map(action => toPayload<AgentCustomSettings>(action)),
        tap((settings) => {
          const logPayload: LogInfo = {
            logType: LogType.debug,
            operationName: DebugEvents.QualityCheckDateTimeUpdated,
            data: {
              lastQualityCheckDateTime: settings.lastQualityCheckDateTime
            }
          };
          this.ngEntityStore.dispatch(LogActions.logDebug({logPayload}));
        })
      ),
      { dispatch : false }
  );

  private _updateCustomSettings(agentCustomSettings: AgentCustomSettings, qualityCheckDate: Date) {
    const updatedSettings: AgentCustomSettings = {
      ...agentCustomSettings,
      lastQualityCheckDateTime: qualityCheckDate.toJSON()
    };
    this.fixagentProfileService.updateCustomSettings(updatedSettings);
	}
}
