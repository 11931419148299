// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatUiActions } from 'projects/entities/src/lib/domain/actions';
import { SelectMessageMethod } from 'projects/entities/src/lib/domain/models/logDimensions/select-message-log';
import { AppState, ChatInteraction } from 'projects/entities/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class ConvoMessageService {
  constructor(private ngEntityStore: Store<AppState>) {
  }

  /** Make sure to pass in the active chat id to avoid potentially passing in a prior conversation's past engagement id */
  selectMessage(canSelectMessage: boolean, isMessageSelected: boolean, chatId: string, chatMessage: ChatInteraction, isValidMessageType: boolean) {
    if (canSelectMessage && !isMessageSelected) {
      const canSearchSmartResponses = isValidMessageType && !chatMessage.isHistoric;
      this.ngEntityStore.dispatch(ChatUiActions.messageSelected({ chatId, chatMessage, canSearchSmartResponses, selectionMethod: SelectMessageMethod.DirectClick }));
    }
  }
}
