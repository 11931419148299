// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Metric } from 'web-vitals';
import { WebVitalsTranslator } from './web-vitals-translator';

export class WebVitalsLogger {
  private appInsights: ApplicationInsights;
  private webVitalsTranslator: WebVitalsTranslator;

  constructor(
    appInsights: ApplicationInsights,
    webVitalsTranslator: WebVitalsTranslator
  ) {
    this.appInsights = appInsights;
    this.webVitalsTranslator = webVitalsTranslator;
  }

  public trackMetric(webVitalMetric: Metric): void {
    const metric = this.webVitalsTranslator.toMetricTelemetry(webVitalMetric);
    const props = this.webVitalsTranslator.toCustomProperties(webVitalMetric);
    this.appInsights.trackMetric(metric, props);
  }
}
