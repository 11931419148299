// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { DispositionSelection } from 'projects/entities/src/public_api';
import { CloseChatBase } from './close-chat-base';

export class CloseChat extends CloseChatBase {
  unresolvedContactReason?: string;
  dispositions?: DispositionSelection;
}
