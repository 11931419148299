// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    // Observable string sources
    private themeChangeSource = new Subject<any>();
    
    // Observable string streams
    changeEmitted$ = this.themeChangeSource.asObservable();

    // Service message commands
    themeChange(change: any) {
        this.themeChangeSource.next(change);
    }
}