<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-live-transcript-header-container"
*ngIf="{
  callState: (callState$ | async),
  callErrorState: (callErrorState$ | async),
  isTranscriptFeedCollapsed: (isTranscriptFeedCollapsed$ | async),
  isShowAgentTranscriptEnabled: (isShowAgentTranscriptEnabled$ | async),
  voiceSessionId: (voiceSessionId$ | async)
} as data">
  <div class="merc-live-transcript-header merc-section-header"
  [ngClass]="{ 'merc-live-transcript-header--single-column': !shouldShowExpandCollapse }">
    <cee-kebab-menu labelText="Context Menu" [tooltipText]="'More Options'" tooltipDirection="top" hasNoPadding="true" data-qa="more-live-transcript-options">
      <cee-button
        customClass="merc-transcript-header-button"
        (buttonClick)="toggleShowAgentTranscript(data.isShowAgentTranscriptEnabled)"
        type="dropdown-item"
        role="option">
        <span>Show Agent Transcript</span>
        <svg class="cee-icon" *ngIf="data.isShowAgentTranscriptEnabled">
          <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-check-thin'"></use>
        </svg>
      </cee-button>
    </cee-kebab-menu>

    <div class="merc-live-transcript-title" data-qa="live-transcript-title"><b>Live Transcript</b></div>

    <cee-button size="link" type="icon" (buttonClick)="toggleTransferFeedCollapse(data.isTranscriptFeedCollapsed)"
      *ngIf="shouldShowExpandCollapse"
      customClass="merc-user-voice-button merc-collapse-expand-button" dataQa="toggle-collapse-live-transcript">
      <svg class="cee-icon">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-next"></use>
      </svg>
    </cee-button>
  </div>

  <cee-banner-v2 type="error" *ngIf="isActiveCallErrored(data.callState, data.callErrorState)" dataQa="transcript-banner-error">
    Unable to load new transcript.
  </cee-banner-v2>
</div>
