// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, concatMap, withLatestFrom, tap, filter, distinctUntilChanged, delay } from 'rxjs/operators';
import { AudioService, LoggingFactoryService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { of } from 'rxjs';
import { MercuryNotificationService } from 'src/app/services/mercury-notification.service';
import { MercuryCtiChatService } from 'src/app/services/mercury-cti-chat.service';
import { CtiBeginChatRequest } from 'src/app/models/cti-begin-chat-request';
import { CtiEndChatRequest } from 'src/app/models/cti-end-chat-request';
import { CtiResumeChatRequest } from '../models/cti-resume-chat-request';
import {
  AppState, AgentCustomSettings,
  Chat, GetChatAccountLocality,
  UpdateUui, BounceTimerDetail,
  BounceTimerType, SenderType, SystemMessageType,
  ChatInteraction, SystemChatMessage,
  ChatState, UserIdentityService,
  ChatOperations, LogHelper, ChannelType,
  CustomerActivityStatus, ProfileActions, getChat,
  ChatUiActions, toPayload, AgentActions, ChatActions, UiActions, AgentOperations, AppActions, FeatureFlags
} from 'projects/entities/src/public_api';
import { GetAsyncEngagements } from 'projects/entities/src/lib/domain/models/getAsyncEngagements';
import { AcceptChatFailLog } from 'projects/entities/src/lib/domain/models/logDimensions/accept-chat-fail-log';
import { ChatNotFoundLog } from 'projects/entities/src/lib/domain/models/logDimensions/chat-not-found-log';
import { ChannelInformation } from 'projects/entities/src/lib/domain/models/channelInformation';
import { MarkAsMercuryChatRequest } from 'projects/entities/src/lib/domain/models/markAsMercuryChatRequest';
import { GetEngagementById } from 'projects/entities/src/lib/domain/models/getEngagementById';
import { CtiFocusApplication } from 'projects/entities/src/lib/domain/models/ctiFocusApplication';
import { fromAgent, fromAgentAvailability, fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { ChatPersisterService } from 'projects/entities/src/lib/domain/chat/chat-persister.service';
import { ChatLoadingConfiguration } from '../constants/constants';
import { CtiAppUpdateRequest } from '../models/cti-app-update-request';
import { ChatDialogAppUpdateOptions } from '../constants/cti.constants';
import { CtiDialogCollectionStartRequest } from '../models/cti-dialog-collection-start-request';
import { CtiDialogCollectionCancelRequest } from '../models/cti-dialog-collection-cancel-request';
import { Guid } from 'guid-typescript';
import { ChatStartGreetingType, CtiFocusApplicationType, Language, SecureDataRequestCancelReason, SuggestionType } from 'projects/entities/src/lib/domain/models/enums';
import { RateConnectionQualityHelper } from 'projects/entities/src/lib/utils/rate-connection-quality-helper';
import { GreetingSuggestion } from 'projects/entities/src/lib/domain/models/greetingSuggestion';
import { ChatStartGreetingHelper } from 'projects/entities/src/lib/utils/chat-start-greeting-helper';
import { SendSuggestion } from 'projects/entities/src/lib/domain/models';
import { SendAutoGreetingMessage } from 'projects/entities/src/lib/domain/models/sendAutoGreetingMessage';

@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_Chat {
  constructor(
    protected ngEntityStore: Store<AppState>,
    protected actions: Actions,
    protected notificationService: MercuryNotificationService,
    protected ctiService: MercuryCtiChatService,
    protected audioService: AudioService,
    protected chatPersisterService: ChatPersisterService,
    protected loggingFactory: LoggingFactoryService,
    protected userIdentityService: UserIdentityService,
    private timeService: DayTimeService,
  ) {}

  acceptNewChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AcceptNewChat),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromSettings.getFeatureFlags),
        this.ngEntityStore.select(fromChat.selectChatIds),
        this.ngEntityStore.select(fromAgent.getMaxChats),
        this.ngEntityStore.select(fromChat.getTranslationLanguage(_action.chat.chatId))
      ]),
      tap(([{ chat: newChat }, featureFlags, chatIds, maxConcurrency, translationLanguage]) => {
        this.chatPersisterService.storeEntity(newChat);

        let logDimensions: { engagementId: string, isAsync: boolean; featureFlag: string[]; engagementIds: string[], maxConcurrency: number, language: Language } = {
          engagementId: newChat.chatId,
          ...(newChat?.isAsync && { conversationId: newChat.conversationId }),          
          isAsync: newChat.isAsync,
          featureFlag: featureFlags,
          engagementIds: chatIds.filter(id => newChat.chatId !== id),
          maxConcurrency,
          language: translationLanguage
        };

        if (!newChat.isAsync && newChat.transferred) {
          logDimensions = Object.assign(logDimensions, this.getChatAgents(newChat));
          const channelInformation: ChannelInformation = {channel: newChat.channel, chatId: newChat.chatId};

          this.ngEntityStore.dispatch(ChatActions.ChannelInformation(channelInformation));
        }
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.NewEngagement, newChat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  chatAccepted$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChatAccepted),
      concatLatestFrom(() => this.ngEntityStore.pipe(select(fromChat.getSelectedChat))),
      tap(([{chat}, selectedChat]) => {
        const { chatId } = chat;
        if (!selectedChat) {
          const lastViewedTimestamp = this.timeService.unix();
          this.ngEntityStore.dispatch(ChatUiActions.selectChat({ chatId, lastViewedTimestamp }));
        }
        const mercuryChat: MarkAsMercuryChatRequest = {
          siteId: chat.siteId,
          nuanceCustomerId: chat.nuanceCustomerId,
          chatId: chat.chatId
        };
        this.ngEntityStore.dispatch(ChatActions.MarkAsMercuryChat(mercuryChat));
        this.ngEntityStore.dispatch(ChatActions.GetDispositions(chat.chatId));

        if (chat.isAsync && chat.conversationId) {
          const getAsyncEngagements: GetAsyncEngagements = {
            conversationId: chat.conversationId,
            chatId
          };
          this.ngEntityStore.dispatch(ChatActions.GetAsyncEngagements(getAsyncEngagements));
        }

        if (chat.authenticated || chat.isAccountConnected) {
          const getLocality = new GetChatAccountLocality();
          getLocality.accountNumber = chat.accountNumber;
          getLocality.chatId = chat.chatId;

          this.ngEntityStore.dispatch(ChatActions.GetChatAccountLocality(getLocality));

          this.ngEntityStore.dispatch(ChatActions.GetPriorEngagements({ chat }));
        }
        else if (chat.transferred){
          this.ngEntityStore.dispatch(ChatActions.GetCustomerInformation(chatId));
        }

        if (chat.previousEngagementId) {
          const engagementById = new GetEngagementById();
          engagementById.chatId = chat.chatId;
          engagementById.engagementId = chat.previousEngagementId;

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ReconnectEngagement, chat, {
            previousEngagementId: chat.previousEngagementId
          });
          this.ngEntityStore.dispatch(ChatActions.GetEngagementById(engagementById));
        }

        this.notificationService.triggerNewChatNotifications(chat);
        if (chat?.uui) {
          const colorGenericName = 'color' + chat.color.id;

          this.ctiService.sendCtiChatMessage(chat, new CtiBeginChatRequest(chat.uui, chatId, chat.color.hexCode, colorGenericName, chat.customerGuid));
        }
      })
    ),
    { dispatch: false }
  );

  // do not know if the uui will come in
  // wait for configured time if uui has not come in and chat has not fully loaded, call GetCustomerInformation
  waitForUui$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChatAccepted),
      delay(ChatLoadingConfiguration.UuiWaitTimeInSeconds * 1000),
      concatLatestFrom(({chat}) => this.ngEntityStore.pipe(select(fromChat.getChat(chat.chatId)))),
      filter(([, chat]) => chat?.isLoading && !chat.uui),
      tap(([{chat}]) => {
        const {chatId} = chat;
        this.ngEntityStore.dispatch(ChatActions.GetCustomerInformation(chatId));
      })
    ),
    { dispatch: false }
  );

  chatAcceptFail$ = createEffect(() =>
  this.actions.pipe
    (
      ofType(ChatActions.ChatAcceptanceFailed),
      map((action) => toPayload<Chat>(action)),
      tap((chat: Chat) => {
        // when the response from the hub in a callback that was unsuccessful
        const event: AcceptChatFailLog = { engagementId: chat.chatId };
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.AcceptChatFailed, event);
      })
    ),
    { dispatch: false }
  );

  chatAcceptanceUnknown$ = createEffect(() =>
  this.actions.pipe
    (
      ofType(ChatActions.ChatAcceptanceUnknown),
      map((action) => toPayload<Chat>(action)),
      tap((chat: Chat) => {
        // when the call to the server fails
        const event: AcceptChatFailLog = { engagementId: chat.chatId };
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChatAcceptanceUnknown, event);
        this.ngEntityStore.dispatch(AppActions.ChatStateDirty());
      })
    ),
    { dispatch: false }
  );

  newMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewMessage),
      map(action => toPayload<ChatInteraction>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.pipe(select(fromChat.getChats)),
            this.ngEntityStore.pipe(select(fromChat.getSelectedChat))
          )
        )
      ),
      tap(([chatMessage, chats, activeChat]) => {
        const chat = chats.find(c => c.chatId === chatMessage.chatId);
        if (!chat) {
          const event: ChatNotFoundLog = {
            action: ChatActions.NewMessage.type,
            engagementId: chatMessage.chatId
          };
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChatNotFound, event);
          return;
        }

        if (chat.state !== ChatState.PendingAcceptance && chat.state !== ChatState.AcceptanceUnknown) {
          if (chat && chatMessage !== chat.messages[0] && !this.isInfoMessage(chatMessage)) {
            const isChatSelected: boolean = chatMessage?.chatId === activeChat?.chatId;
            this.notificationService.triggerNewMessageNotifications(chatMessage, chat, isChatSelected);
          }
        }
      })
    ),
    { dispatch: false }
  );

  sendMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SendMessage),
      tap(() => {
        this.notificationService.triggerOutgoingMessageSentNotifications();
      })
    ),
    { dispatch: false }
  );

  channelInformation$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChannelInformation),
      map(action => toPayload<ChannelInformation>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.pipe(select(fromChat.getChats)),
          )
        )
      ),
      tap(([channelInfo, chats]) => {
        const chat = chats.find(c => c.chatId === channelInfo.chatId);
        const logDimensions = Object.assign({}, {
          channel: ChannelType[channelInfo.channel.type],
          device: channelInfo.channel.device
        });

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChannelInfoUpdate, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  updateUui$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateUui),
      map(action => toPayload<UpdateUui>(action)),
      concatLatestFrom(() => this.ngEntityStore.pipe(select(fromChat.getChats))),
      tap(([updateUui, chats]) => {
        const chat = chats.find(c => c.chatId === updateUui.chatId);
        if (!updateUui) { return; }

        this.ngEntityStore.dispatch(ChatActions.GetCustomerInformation(updateUui.chatId));

        if (updateUui.uui && chat) {
          const colorGenericName = 'color' + chat.color.id;

          this.ctiService.sendCtiChatMessage(chat, new CtiBeginChatRequest(updateUui.uui, updateUui.chatId, chat.color.hexCode, colorGenericName, updateUui.guid));
        }

        if (updateUui.previousEngagementId){
          const engagementById = new GetEngagementById();
          const prevEngLogDimensions = {
            previousEngagementId: updateUui.previousEngagementId
          };

          engagementById.chatId = updateUui.chatId;
          engagementById.engagementId = updateUui.previousEngagementId;

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ReconnectEngagement, chat, prevEngLogDimensions);
          this.ngEntityStore.dispatch(ChatActions.GetEngagementById(engagementById));
        }
        if (!this.ctiService.isCtiConnected){
          const autoGreetingMessage: SendAutoGreetingMessage = {
            contextText: null,
            uui: updateUui.uui
          };
          this.ngEntityStore.dispatch(ChatActions.SendChatStartMessages({chatId: updateUui.chatId, autoGreetingMessage}));
        }
      })
    ),
    { dispatch: false }
  );

  selectChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.selectChat, ChatUiActions.agentSelectedChat),
      concatLatestFrom(() => this.ngEntityStore.select(fromChat.getSelectedChat)),
      filter(([, chat]) => !!chat?.uui),
      tap(([, chat]) => {
        const { chatId, uui } = chat;
        this.ctiService.sendCtiChatMessage(chat, new CtiResumeChatRequest(uui, chatId));
      })
    ),
    { dispatch: false }
  );

  chatFocus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.selectChat, ChatUiActions.agentSelectedChat),
      concatLatestFrom(() => [this.ngEntityStore.select(fromChat.getSelectedChat), this.ngEntityStore.select(fromChat.getChats)]),
      filter(([, chat]) => !!chat),
      distinctUntilChanged(([, prev], [, curr]) => prev.chatId === curr.chatId),
      tap(([, chat, chats]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatFocus, chat, { numActiveChats: chats.length });
      })
    ),
    { dispatch: false }
  );

  ctiApplicationFocus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CtiApplicationFocus),
      map(action => toPayload<CtiFocusApplication>(action)),
      concatLatestFrom((payload) => this.ngEntityStore.select(getChat(payload.chatId))),
      tap(([applicationFocus, chat]) => {
        const { chatId, uui } = chat;
        if (uui) {
          this.ctiService.sendCtiChatMessage(chat, new CtiResumeChatRequest(uui, chatId, applicationFocus.applicationFocusType));
        }
      })
    ),
    { dispatch: false }
  );

  chatBounced$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Bounced),
      map(action => toPayload<Chat>(action)),
      tap((chat) => {
        if (chat?.uui) {
          this.notificationService.triggerChatBouncedNotifications();
        }

        const bounceTimerDetail = new BounceTimerDetail({
          type: BounceTimerType.Bounced,
          chatId: chat.chatId
        });
        this.ngEntityStore.dispatch(UiActions.BounceTimerTriggered({ bounceTimerDetail }));
      })
    ),
    { dispatch: false }
  );

  notifyEndChat$ = createEffect(() =>
        this.actions.pipe(
            ofType(ChatActions.CustomerExited, ChatActions.CloseEngagementInstruction, ChatActions.CustomerHeartbeatExpired),
            concatLatestFrom(() => this.ngEntityStore.select(fromChat.getChats)),
            tap(([chatData, allChats]) => {
                const targetChat = allChats.find(c => c.chatId === chatData.stateChangeResponse.chatId);
                if (targetChat) {
                    this.notificationService.triggerChatEndsNotifications();
                }
            })
        ),
        { dispatch: false}
    );

  chatTransferred$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Transferred),
      tap(() => {
        this.ngEntityStore.dispatch(AgentActions.LoadAgentAnalytics({
          source: 'automatic'
        }));
      })
    ),
    { dispatch: false }
  );

  unpinChat$ = createEffect(() =>
      this.actions.pipe(
        ofType(ChatUiActions.updateCustomerActivityStatus),
        filter(({status, lastStatus}) =>
				    status === CustomerActivityStatus.Active &&
            lastStatus === CustomerActivityStatus.Pinned
			  ),
        concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChat(chatId))),
      tap(([, chat]) => {
          if (chat) {
            this.notificationService.triggerChatUnpinnedNotifications();
          }
      })),
      { dispatch: false }
    );

    itgExit$ = createEffect(() =>
      this.actions.pipe(
        ofType(ChatUiActions.itgCompleted, ChatUiActions.itgSchedulerCompleted),
        concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChat(chatId))),
        filter(([, chat]) => Boolean(chat?.uui)),
        tap(([, chat]) => {
          const action = ChatDialogAppUpdateOptions.Actions.Refresh;
          const area = ChatDialogAppUpdateOptions.Area.Recommendations;
          this.ctiService.sendCtiChatMessage(chat, new CtiAppUpdateRequest(chat.uui, chat.chatId, action, area));
        })
      ),
      { dispatch: false }
    );

  setAudioSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProfileActions.Updated),
      map(action => toPayload<AgentCustomSettings>(action)),
      tap((agentCustomSettings) => {
          this.setAudioSettings(agentCustomSettings);
      })),
    { dispatch: false }
  );

  testVolume$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProfileActions.testVolume),
      tap((action) => {
        const { audioOptions } = action;
        this.notificationService.triggerTestSound(audioOptions);
        const logDimensions = {
          volumeLevel: audioOptions.volume * 50
        };
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.TestVolume, logDimensions);

      })
    ),
    { dispatch : false }
  );

  chatRemoved$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Bounced, ChatActions.Transferred, ChatActions.Closed),
      map(action => toPayload<Chat>(action)),
      concatLatestFrom(() => [
        this.ngEntityStore.pipe(select(fromChat.getSelectedChat)),
        this.ngEntityStore.pipe(select(fromChat.getChats)),
        this.ngEntityStore.pipe(select(fromAgent.getMaxChats)),
        this.ngEntityStore.pipe(select(fromAgentAvailability.getAvailableForExtraChats))
      ]),
      tap(([chat, selectedChat, allChats, maxChats, extraChatRequested]) => {
        if (chat?.uui) {
          this.ctiService.sendCtiChatMessage(chat, new CtiEndChatRequest(chat.uui, chat.chatId));
        }

        this.chatPersisterService.removeEntity(chat);
        this.setNextActiveChat(selectedChat, allChats);

        this.toggleRequestExtraChatsOff(extraChatRequested, allChats.length, maxChats);

      })
    ),
    { dispatch : false }
  );

  newExternalSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewExternalSuggestion),
      concatLatestFrom((action) => this.ngEntityStore.select(fromChat.getChat(action.chatId))),
      tap(([{chatId, suggestion}, chat]) => {
        const latestSuggestion = chat.suggestions.find(s => s.isLatest);
        if (latestSuggestion && !chat.suggestions.some(x => x.body?.message === suggestion.body?.message && x.visible)) {
          const sendSuggestion: SendSuggestion = {
            ...latestSuggestion,
            chatId
          };

          LogHelper.logDismissedSuggestion(this.loggingFactory, chat, sendSuggestion, true);
        }

        this.ngEntityStore.dispatch(ChatActions.AddExternalSuggestions({ chatId, suggestion }));
      })
    ),
    {dispatch: false}
  );

  getGreetingSuggestions$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateAsyncEngagements),
      concatLatestFrom(({updateAsyncEngagements: engagements}) => [
        this.ngEntityStore.pipe(select(fromChat.getChat(engagements.chatId))),
        this.ngEntityStore.pipe(select(fromChat.getIsGreetingSuggestionPreviouslyOffered(engagements.chatId))),
        this.ngEntityStore.pipe(select(fromChat.getChatStartGreeting(engagements.chatId))),
        this.ngEntityStore.pipe(select(fromChat.getChatStartGreetingType(engagements.chatId)))
      ]),
      tap(([, chat, isGreetingSuggestionPreviouslyOffered, greetingMessage, greetingType]) => {
        this.addGreetingSuggestion(chat, isGreetingSuggestionPreviouslyOffered, greetingMessage, greetingType);
      })
    ),
    { dispatch: false }
  );

  transferChatAccepted$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChatAccepted),
      concatLatestFrom(({chat}) => [
        this.ngEntityStore.pipe(select(fromChat.getChat(chat.chatId))),
        this.ngEntityStore.pipe(select(fromChat.getIsGreetingSuggestionPreviouslyOffered(chat.chatId))),
        this.ngEntityStore.pipe(select(fromChat.getChatStartGreeting(chat.chatId))),
        this.ngEntityStore.pipe(select(fromChat.getChatStartGreetingType(chat.chatId)))
      ]),
      filter(([, chat]) => chat.transferred),
      tap(([, chat, isGreetingSuggestionPreviouslyOffered, greetingMessage, greetingType]) => {
        this.addGreetingSuggestion(chat, isGreetingSuggestionPreviouslyOffered, greetingMessage, greetingType);
      })
    ),
    { dispatch: false }
  );

  offerQualityCheck$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Transferred, ChatActions.Closed),
      concatLatestFrom(() => [
        this.ngEntityStore.pipe(select(fromSettings.hasFeatureFlag(FeatureFlags.QualityCheck))),
        this.ngEntityStore.pipe(select(fromAgent.getOfferFirstQualityCheck)),
        this.ngEntityStore.pipe(select(fromAgent.getLastQualityCheckDate)),
        this.ngEntityStore.pipe(select(fromSettings.getQualityCheckConfiguration)),
        this.ngEntityStore.pipe(select(fromAgentAvailability.getChatsClosedSinceAvailable))
      ]),
      filter(([, hasQualityCheckFeatureFlag]) => hasQualityCheckFeatureFlag),
      tap(([{payload}, _ff, offerFirstQualityCheck, lastQualityCheckDate, qualityCheckConfiguration, chatsClosedSinceAvailable]) => {
        const {qualityCheckFrequency, qualityCheckFirstOfferChance, qualityCheckOfferChance} = qualityCheckConfiguration;
        const {chatId} = payload;
        const isFirstChatClosedSinceAvailable = chatsClosedSinceAvailable === 1;
        const offerQualityCheck = RateConnectionQualityHelper.offerQualityCheck(
          lastQualityCheckDate,
          offerFirstQualityCheck,
          qualityCheckFrequency,
          qualityCheckFirstOfferChance,
          qualityCheckOfferChance,
          isFirstChatClosedSinceAvailable,
          chatId
        );
        this.ngEntityStore.dispatch(AgentActions.UpdateOfferQualityCheck(offerQualityCheck));
      })
    ),
    { dispatch : false }
  );

  startDataCollectionRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.StartDataCollectionRequest),
      concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChat(chatId))),
      tap(([, chat]) => {
        if (chat?.uui && chat?.accountNumber) {
          const requestId = Guid.raw();
          this.ctiService.sendCtiChatMessage(chat, new CtiDialogCollectionStartRequest(chat.uui, chat.chatId, chat.accountNumber, CtiFocusApplicationType.Einstein360, requestId));

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SecureDataRequestStart, chat, { requestId });
        }
      })
    ),
    { dispatch : false }
  );

  cancelDataCollectionRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CancelDataCollectionRequest),
      concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChat(chatId))),
      tap(([, chat]) => {
        if (chat?.uui) {
          this.ctiService.sendCtiChatMessage(chat, new CtiDialogCollectionCancelRequest(chat.uui, chat.chatId, Guid.raw()));

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SecureDataRequestCancel, chat, { reason: SecureDataRequestCancelReason.DismissLink });
        }
      })
    ),
    { dispatch : false }
  );

  private toggleRequestExtraChatsOff(isToggledOn, activeChatCount, maxChatCount) {
    if (isToggledOn && activeChatCount <= maxChatCount) {
      this.ngEntityStore.dispatch(AgentActions.RequestExtraChats({enabled: false}));
    }
  }

  private isInfoMessage(chatMessage: ChatInteraction): boolean {
    return chatMessage.sender === SenderType.System && (<SystemChatMessage>chatMessage).systemType === SystemMessageType.Navigation;
  }
  private setNextActiveChat(selectedChat: Chat, allChats: Chat[]): void {
    if (!selectedChat) {
      if (allChats.length > 0) {
        const { chatId } = allChats[0];
        const lastViewedTimestamp = this.timeService.unix();
        this.ngEntityStore.dispatch(ChatUiActions.selectChat({ chatId, lastViewedTimestamp }));
      } else {
        this.ngEntityStore.dispatch(ChatUiActions.selectChat({ chatId: null, lastViewedTimestamp: null }));
      }
    }
  }

  private setAudioSettings(settings: AgentCustomSettings): void {
    // must convert volume from 0-100 scale to 0-2 scale
    // 0 is mute
    // 50 is 100% volume
    // 100 is 200% volume
    let volume: number = settings.soundVolume ? settings.soundVolume / 50 : 0;

    if (settings != null && settings.soundMuteAll) {
      volume = 0;
    }

    this.audioService.volumeOverride = volume;
  }

  private getChatAgents(chat: Chat) {
    let lastAgentId = '';
    const agentIds: string[] = [];
    if (chat.users) {
      chat.users.filter(p => {
        return p.type === SenderType.FixAgent && p.id;
      }).map(p => {
        agentIds.push(p.id);
      });
      agentIds.push(this.userIdentityService.username);
      // when this is fired the current agent would not have been able to answer the chat yet
      // so the last agent would be the last message
      if (chat.messages) {
        const transferMessageList = chat.messages.length > 0 ?
          chat.messages.filter(p => {
            return p.sender === SenderType.FixAgent;
          }) : [];
        lastAgentId = transferMessageList.length > 0 ? transferMessageList[transferMessageList.length - 1].senderId : '';
      }
    }
    return {
      lastAgentId: lastAgentId,
      agentIds: agentIds,
    };
  }

  private addGreetingSuggestion(chat: Chat, isGreetingSuggestionPreviouslyOffered: boolean, greetingMessage: string, greetingType: ChatStartGreetingType){
    const isChatAccepted = chat.state === ChatState.Open;
      if (!isGreetingSuggestionPreviouslyOffered && isChatAccepted && greetingMessage !== '') {
        const title = ChatStartGreetingHelper.GetChatStartGreetingTitle(greetingType);
        const greetingSuggestion: GreetingSuggestion = {
          body: {
            message: greetingMessage,
            buttons: null,
            image: null
          },
          queryId: null,
          title,
          isLatest: true,
          json: null,
          messageId: null,
          timesSent: 0,
          suggestionType: SuggestionType.Greeting
        };
        this.ngEntityStore.dispatch(ChatActions.AddGreetingSuggestion({ chatId: chat.chatId, suggestion: greetingSuggestion }));
      }
  }
}
