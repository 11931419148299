// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import { AuthService } from '@cxt-cee-chat/merc-ng-core';
import { AgentStatePersisterService } from './agent-state-persister.service';

@Injectable({
  providedIn: 'root'
})
export class NuanceAuthGuardService implements CanActivate {

  constructor(
    @Inject(AuthService) protected authService: AuthService,   
    protected agentStatePersister: AgentStatePersisterService
    ) { }
  
  canActivate(_route: ActivatedRouteSnapshot): Promise<boolean> {
  
    return new Promise((resolve, reject) => {
        return this.authService.isAuthenticated
            .then((authenticated: boolean) => {
                if (authenticated) {
                    resolve(true);
                } else {
                    this.authService.redirectToAuthenticationProvider();
                }
            })
            .catch(err => reject(err));
        });
    }
}
