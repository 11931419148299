// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'merc-convo-script-search-results-banner',
  templateUrl: './script-search-results-banner.component.html',
  styleUrls: ['./script-search-results-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptSearchResultsBannerComponent {
  @Input() hasCustomScriptGroups: boolean = false;
  @Input() searchSystemScriptsCount: number = 0;
  @Input() showSystemScripts: boolean = true;
  @Output() clearSearchClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleShowSystemScripts: EventEmitter<any> = new EventEmitter<any>();
  constructor(
  ) {}

  clearSearch() {
    this.clearSearchClicked.emit();
  }

  onSystemScriptChange(showSystemScripts: boolean){
    this.showSystemScripts = showSystemScripts;
    this.toggleShowSystemScripts.emit(showSystemScripts);
  }
}
