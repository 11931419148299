// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../state';
import * as fromLanguageTranslation from './language-translation.reducer';
import { ChatLanguageTranslation } from './language-translation.model';
import { LanguageMessageTranslationData } from '../models/message-translation-data';
import { LanguageTranslationHelper } from '../../utils/language-translations-helper';

export const selectLanguageTranslationFeature = createFeatureSelector<AppState, fromLanguageTranslation.State>(fromLanguageTranslation.featureKey);

const {
  selectEntities: selectLanguageTranslationEntities,
} = fromLanguageTranslation.adapter.getSelectors(selectLanguageTranslationFeature);

export const getIsAlreadyAsyncTranslated = (chatId: string) => createSelector(
  selectLanguageTranslationEntities,
  (entities: Dictionary<ChatLanguageTranslation>) => entities[chatId]?.isAsyncTranslated
);

export const getLanguageTranslationDataByChatId = (chatId: string) => createSelector(
  selectLanguageTranslationEntities,
  (entities: Dictionary<ChatLanguageTranslation>) => entities[chatId]?.translationData ?? []
);

export const getTranslationText = (chatId: string, traceId: string, messageId?: string) => createSelector(
  getLanguageTranslationDataByChatId(chatId),
  (messageTranslationDataList: LanguageMessageTranslationData[]): string => messageTranslationDataList.find(d => LanguageTranslationHelper.doesTranslationIdMatch(traceId, messageId, d.traceId))?.translatedMessage
);
