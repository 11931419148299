// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { PlaceholderEditorState, PlaceholderEditorType } from '../models/enums';
import { State } from './placeholders.reducer';

export const hydrate = createAction('[Placeholders] Hydrate');

export const hydrateSuccess = createAction(
  '[Placeholders] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[Placeholders] Hydrate Failure');

export const chatsRemoved = createAction(
  '[Placeholders] Chats Removed',
  props<{ chatIds: string[] }>()
);

export const openEditPlaceholderPanel = createAction(
  '[Placeholders] Open Edit Placeholder Panel',
  props<{ editorState: PlaceholderEditorState, chatId: string, placeholderKeys: string[], editorType: PlaceholderEditorType }>()
);

export const closeEditPlaceholderPanel = createAction(
  '[Placeholders] Close Edit Placeholder Panel'
);

export const submitEditPlaceholderPanel = createAction(
  '[Placeholders] Submit Edit Placeholder Panel',
  props<{ formValues: string[] }>()
);
