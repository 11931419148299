// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
	LoggingFactoryService,
	HubResponse,
	ResponseHandler
} from '@cxt-cee-chat/merc-ng-core';
import { TransferChatRequest } from '../domain/models/requests/transferChat';
import { BounceChat } from '../domain/models/requests/bounceChat';
import { ReportImage } from '../domain/models/requests/reportImage';
import { UpdateAccountNumberRequest } from '../domain/models/requests/updateAccountNumberRequest';
import { SendChatMessage } from '../domain/models/sendChatMessage';
import { ChatInteractionRequest } from '../domain/models/requests/chat-interact-request';
import { SendAgentTypingStatus } from '../domain/models/sendAgentTypingStatus';
import { GetAsyncEngagements } from '../domain/models/getAsyncEngagements';
import { CloseConversation } from '../domain/models/closeConversation';
import { CloseChatRequest } from '../domain/models/closeChatRequest';
import { AcceptChat } from '../domain/models/acceptChat';
import { GetPriorEngagements } from '../domain/models/getPriorEngagements';
import { JoinChats } from '../domain/models/joinChats';
import { GetActiveEngagements } from '../domain/models/authentication/ActiveEngagements';
import { GetActiveChatTranscript } from '../domain/models/getActiveChatTranscript';
import { MaskTextRequest } from '../domain/models/requests/maskTextRequest';
import { UnlinkAccount } from '../domain/models/unlinkAccount';
import { UnauthorizedVerifySession } from '../domain/models/unauthorizedVerifySession';
import { GetXaSuggestionsRequest } from '../domain/models/requests/getXaSuggestionsRequest';
import { NuanceCredentialRequestService } from './nuance-credential/nuance-credential-request-service';
import { NuanceCredentialHubService } from './nuance-credential/nuance-credential-hub-service';
import { SendSuggestion } from '../domain/models/requests/send-suggestion';
import { MarkAsMercuryChatRequest } from '../domain/models/markAsMercuryChatRequest';
import { GetDispositions } from '../domain/models/getDispositions';
import { AgentAuthActions } from '../domain/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../domain/state';
import { RefuseChat } from '../domain/models/requests/refuse-chat';
import { SendMessageResponse } from '../domain/models/responses/sendMessageResponse';
import { MaskTextResponse } from '../domain/models/responses/mask-text-response';
import { GetXaSuggestionsResponse } from '../domain/models/responses/getXaSuggestionsResponse';
import { IHubChatResponse } from '../domain/models/hub-response';
import { GetUpdatesResponse } from '../domain/models/responses/get-updates-response';
import { GetSmartResponses } from '../domain/models/requests/get-smart-responses';
import { GetSmartResponsesResponse } from '../domain/models/responses/get-smart-responses-responses';
import { JoinChatsResponse } from '../domain/models/responses/join-chats-response';
import { GetChatSummaryDataRequest } from '../domain/models/requests/get-chat-summary-data-request';
import { GetCxGptMessagesRequest } from '../domain/models/requests/get-cx-gpt-messages-request';
import { GetCxGptMessagesResponse } from '../domain/models/responses/get-cx-gpt-messages-response';
import { SendCloseChatInstructionsRequest } from '../domain/models/requests/send-close-chat-instructions-request';
import { GetEnhancedChatMessageRequest } from '../domain/models/requests/get-enhanced-chat-message-request';
import { GetEnhancedChatMessageResponse } from '../domain/models/responses/get-enhanced-chat-message-response';
import { SendUnifiedNotesRequest } from '../domain/models/requests/send-unified-notes-request';
import { SendUnifiedNotesResponse } from '../domain/models/responses/send-unified-notes-response';
import { GetAnswerResponse } from '../domain/models/responses/get-answer-response';
import { GetAnswerRequest } from '../domain/models/requests/get-answer-request';
import { RateAnswerRequest } from '../domain/models/requests/rate-answer-request';
import { MarkAbandonedInAsyncQueueRequest } from '../domain/models/mark-abandoned-in-async-queue-request';
import { TranslationRequest } from '../domain/models/requests/translate-request';
import { TranslatableChatInfo } from '../domain/models/translatableChatInfo';
import { TranslationResponse } from '../domain/models/responses/translation-response';
import { GetRewriteRequest } from '../domain/models/requests/get-rewrite-request';
import { GetRewriteResponse } from '../domain/models/responses/get-rewrite-response';

@Injectable({
	providedIn: 'root'
})
export class ChatRequestApiService extends NuanceCredentialHubService {
	private _unauthorizedVerifySessionHandler: ResponseHandler;
	constructor(
		protected nuanceCredentialRequestService: NuanceCredentialRequestService,
		loggingService: LoggingFactoryService,
		protected ngEntityStore: Store<AppState>) {
		super(nuanceCredentialRequestService, loggingService);
		this._unauthorizedVerifySessionHandler = new ResponseHandler();
		this._unauthorizedVerifySessionHandler.handle401 = (opName, data) => {
			const operationData: UnauthorizedVerifySession = {
				operationName: opName,
				data: data
			};
			this.ngEntityStore.dispatch(AgentAuthActions.unauthorizedVerifySession(operationData));
		};
	}

	public getUpdates( data: {traceId: string, translationList: TranslatableChatInfo[] } ): Promise<HubResponse<GetUpdatesResponse>> {
		return this.callServerWithCredentials<GetUpdatesResponse>('GetUpdates_v2', data, this._unauthorizedVerifySessionHandler);
	}

	public sendMessage(message: SendChatMessage): Promise<HubResponse<SendMessageResponse>> {
		const data = {
			message: message.message,
			chatId: message.chatId,
			type: message.sendMessageType,
			scriptTreeId: message.scriptTreeId,
			traceId: message.traceId,
			translateTo: message.translateTo,
			autoEnhanceData: message.autoEnhanceData,
			isUsingCustomScript: message.isUsingCustomScript ?? false
		};
		return this.callServerWithCredentials('SendTextMessage_v2', data, this._unauthorizedVerifySessionHandler);
	}

	public closeRequest(model: CloseChatRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('CloseChatRequest', model, this._unauthorizedVerifySessionHandler);
	}

	public closeConversation(model: CloseConversation): Promise<HubResponse> {
		return this.callServerWithCredentials('CloseConversation', model, this._unauthorizedVerifySessionHandler);
	}

	public getTransferOptions(model: ChatInteractionRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('GetTransferOptions', model, this._unauthorizedVerifySessionHandler);
	}

	public transferChat(model: TransferChatRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('TransferChat', model, this._unauthorizedVerifySessionHandler);
	}

	public bounceChat(model: BounceChat): Promise<HubResponse> {
		return this.callServerWithCredentials('BounceChat', model, this._unauthorizedVerifySessionHandler);
	}

	public reportImage(model: ReportImage): Promise<HubResponse> {
		return this.callServer('ReportImage', model);
	}

	public updateAccountNumber(model: UpdateAccountNumberRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('UpdateAccountNumber', model);
	}

	public sendAgentTypingStatus(model: SendAgentTypingStatus): Promise<HubResponse> {
		return this.callServerWithCredentials('SendAgentTypingStatus_v2', model, this._unauthorizedVerifySessionHandler);
	}

	public getConnectionId() {
		return this.callServer('GetConnectionId', null);
	}

	public getPriorEngagements(model: GetPriorEngagements): Promise<HubResponse> {
		return this.callServer('GetPriorEngagements', model);
	}

	public getCustomerInformation(model: any): Promise<HubResponse> {
		return this.callServer('GetCustomerInformation', model);
	}

	public getAsyncEngagements(model: GetAsyncEngagements): Promise<HubResponse> {
		return this.callServer('GetAsyncEngagements', model);
	}

	public acceptChat(model: AcceptChat): Promise<HubResponse<IHubChatResponse>> {
		return this.callServerWithCredentials('AcceptChat_v2', model, this._unauthorizedVerifySessionHandler);
	}

	public refuseChat(model: RefuseChat): Promise<HubResponse> {
		return this.callServerWithCredentials('RefuseChat', model, this._unauthorizedVerifySessionHandler);
	}

	public getActiveEngagements(model: GetActiveEngagements): Promise<HubResponse> {
		return this.callServer('GetActiveEngagements', model);
	}

	public getActiveChatTranscript(model: GetActiveChatTranscript): Promise<HubResponse> {
		return this.callServer('GetActiveChatTranscript', model);
	}

	public joinChats(model: JoinChats): Promise<HubResponse<JoinChatsResponse>> {
		return this.callServerWithCredentials('JoinChats_v2', model, this._unauthorizedVerifySessionHandler);
	}

	public maskText(model: MaskTextRequest): Promise<HubResponse<MaskTextResponse>> {
		return this.callServerWithCredentials('MaskText_v2', model);
	}

	public unlinkAccount(model: UnlinkAccount): Promise<HubResponse> {
		return this.callServerWithCredentials('UnlinkAccount', model);
	}

	public getXaSuggestions(model: GetXaSuggestionsRequest): Promise<HubResponse<GetXaSuggestionsResponse>> {
		return this.callServerWithCredentials('GetXaSuggestions_v2', model);
	}

	public sendXaSuggestion(model: SendSuggestion): Promise<HubResponse<SendMessageResponse>> {
		return this.callServerWithCredentials('SendXaSuggestion_v2', model, this._unauthorizedVerifySessionHandler);
	}

	public MarkAsMercuryChat(model: MarkAsMercuryChatRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('MarkAsMercuryChat_v2', model);
	}

	public GetEngagementById(model: any): Promise<HubResponse> {
		return this.callServer('GetEngagementById', model);
	}

	public getChatDispositions(model: GetDispositions): Promise<HubResponse> {
		return this.callServerWithCredentials('GetDispositions', model);
	}

  	public getSmartResponses(model: GetSmartResponses): Promise<HubResponse<GetSmartResponsesResponse>> {
		return this.callServerWithCredentials('GetSmartResponses_v2', model);
	}

	public getChatSummaryData(model: GetChatSummaryDataRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('ConversationSummary', model);
	}

  	public getCxGptResponses(model: GetCxGptMessagesRequest): Promise<HubResponse<GetCxGptMessagesResponse>>{
		return this.callServerWithCredentials('GetCxGptMessages', model);
	}

	public sendCloseChatInstruction(model: SendCloseChatInstructionsRequest): Promise<HubResponse<any>>{
		return this.callServerWithCredentials('sendCloseChatInstruction', model);
	}

	public sendUnifiedNotes(model: SendUnifiedNotesRequest): Promise<HubResponse<SendUnifiedNotesResponse>>{
		return this.callServerWithCredentials('SendUnifiedNotes', model);
	}

	public getAnswer(model: GetAnswerRequest): Promise<HubResponse<GetAnswerResponse>>{
		return this.callServerWithCredentials('GetAnswer', model);
	}

	public rateAnswer(model: RateAnswerRequest): Promise<HubResponse>{
		return this.callServerWithCredentials('RateAnswer', model);
	}

	public translateTexts(model: TranslationRequest): Promise<HubResponse<TranslationResponse>>{
		return this.callServerWithCredentials('TranslateTexts', model);
	}

	public MarkAbandonedInAsyncQueue(model: MarkAbandonedInAsyncQueueRequest): Promise<HubResponse> {
		return this.callServerWithCredentials('MarkAbandonedInAsyncQueue_v2', model);
	}

	public getRewrite(model: GetRewriteRequest): Promise<HubResponse<GetRewriteResponse>>{
		return this.callServerWithCredentials('GetRewrite', model);
	}

	public getEnhancedChatMessage(model: GetEnhancedChatMessageRequest): Promise<HubResponse<GetEnhancedChatMessageResponse>>{
		return this.callServerWithCredentials('EnhanceMessage', model);
	}
}
