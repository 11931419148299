// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SendChatMessageSettings } from '../../constants/application-health.constants';
import { CustomerAwaitingReplyMessages, DataCollectionSuggestionTitles, DataCollectionSystemMessages } from '../../constants/constants';
import { ItgActionText } from '../../constants/itg-configuration.constants';
import { ChatHelper } from '../../utils/chatHelper';
import { AgentActions, AskMeAnythingActions, ChatUiActions, SmartResponsesActions, UiActions } from '../actions';
import { ChannelType, ChatImageMessage, ChatInteraction, ChatMember, ChatMessage, ChatMessageType, ChatState, ChatTranscript, Customer, Disposition, DispositionCategory, DispositionSelection, ScriptSent, SendChatMessage, SenderType, SetSentScript, SmsDeviceInformation, SystemChatMessage } from '../models';
import { AgentChatMessage } from '../models/agent-chat-message';
import { Chat } from '../models/chat';
import { AsyncEngagementTranscript, EngagementTranscript } from '../models/chatTranscript';
import { ChatMessageStatus, DataCollectionState, DismissSuggestionReason, LoadingState, SendMessageType, SendSuggestionSource, SuggestionType, SystemInfoMessageType, SystemMessageType, UpdateMessageType } from '../models/enums';
import { ItgSuggestion } from '../models/itgSuggestion';
import { ChatTranscriptResponse } from '../models/responses/chat-transcript-response';
import { SchedulerSuggestion } from '../models/schedulerSuggestion';
import { SentMessage } from '../models/scriptSent';
import { SecureDataRequest } from '../models/secureDataRequest';
import { XaSuggestionChatMessage } from '../models/xa-suggestion-chat-message';
import { Suggestion } from '../models/suggestion';
import * as ChatActions from './chat.actions';
import { SmartResponseData, SmartResponseSent } from '../models/smartResponsesSent';
import { ScriptInUse } from '../models/setScriptInUse';
import { SecureDataCollectionHelper } from '../../utils/secure-data-collection-helper';
import { TranscriptEvent } from '../models/NlpTranscript';
import { NlpTranscriptEventName } from '../../constants/nlp-transcript.constants';
import { CustomerDetails } from '../models/customerDetails';
import { ChatSummaryData } from '../models/chatSummaryData';
import { CxGptResponseSent } from '../models/cx-gpt-response-sent';
import { ChatMessageTranslationData } from '../models/chatMessageTranslationData';
import * as LanguageTranslationActions from '../language-translation/language-translation.actions';

export const featureKey = 'chats';

export interface State extends EntityState<Chat> { }

function sortByStartTimestamp(a: Chat, b: Chat): number {
  return b.startTimestamp - a.startTimestamp; // sort descending by startTimestamp (newest first)
}

export const adapter: EntityAdapter<Chat> = createEntityAdapter<Chat>({
  selectId: (chat: Chat) => chat.chatId,
  sortComparer: sortByStartTimestamp,
});

export const initialState: State = adapter.getInitialState({});

function updateChat({ chatId }: { chatId: string; }, changes: Partial<Chat>, state: State): State {
  const update: Update<Chat> = {
    id: chatId,
    changes,
  };

  return adapter.updateOne(update, state);
}

function getChat({ chatId }: { chatId: string; }, state: State): Chat {
  return state.entities[chatId];
}

export const reducer = createReducer<State>(
  initialState,
  on(ChatActions.updateActiveEngagements,
    (state, { engagements, storageChats, serverSyncTimestamp }) => {
      const updatedChatState = updateChatsFromActiveEngagements(
        state,
        engagements,
        (chatId, _state) => getChat({chatId}, _state) ?? storageChats.find(c => c.chatId === chatId)
      );

      /*
          At this point, chats that were not in the response engagements are still in the updatedChatState
          There is an edge case where a new chat can arrive from GetUpdates during the GetActiveEngagements call, these chats should be retained
          other chats no longer belong to the agent and should be removed
          remove any chats that are at ChatState.AcceptanceUnknown those chats were not able to be accepted and do not belong to the agent
          (not in engagements array and is AcceptanceUnknown or before serverSync started)
      */
      const idsToRemove = Object.entries(updatedChatState.entities)
        .filter(([_, entity]) => !(engagements.find(eng => eng.engagementId === entity.chatId)) &&
          (entity.state === ChatState.AcceptanceUnknown || entity.startTimestamp < serverSyncTimestamp))
        .map(([key, _]) => key);

      return adapter.removeMany(idsToRemove, updatedChatState);
    }
  ),

  on(ChatActions.AcceptNewChat,
    (state, { chat }): State => {
      return adapter.addOne(chat, state);
    }
  ),

  on(ChatActions.Bounced, ChatActions.ChatAcceptanceFailed, ChatActions.Closed, ChatActions.Transferred,
    (state, { payload }): State => {
      return adapter.removeOne(payload.chatId, state);
    }
  ),

  on(ChatActions.ChatAcceptanceUnknown,
    (state, { payload }): State => updateChat(payload, {
      state: ChatState.AcceptanceUnknown
    }, state)
  ),

  on(ChatActions.SetPriorEngagements,
    (state, { payload }): State => {
      const chat = getChat(payload, state);

      let engagementHistory = [];
      if (!payload.clearEngagements) {
        engagementHistory = chat.engagementHistory ? [...chat.engagementHistory, ...payload.engagements] : payload.engagements;
      }

      return updateChat(payload, {
        engagementHistory,
        isLoading: false
      }, state);
    }
  ),

  on(ChatActions.MaskText,
    (state, { maskText }): State => {
      const chat = getChat(maskText, state);
      const { chatMessage, traceId } = maskText;
      const messagesMasked = chat.messages.map(f => {
        if (f.messageId === chatMessage.messageId) {
          return {
            ...f,
            traceId,
            isMasking: true
          };
        } else {
          return f;
        }
      });

      return updateChat(maskText, {
        messages: messagesMasked
      }, state);
    }
  ),

  on(ChatActions.TextMasked,
    (state, { maskText, messageId }): State => {
      const { maskedChunks, traceId, chatId, translatedMaskedChunks } = maskText;
      return updateMessagesByTraceId(state, chatId, traceId, messageId, (m) => {
        return {
          ...m,
          messageId,
          maskedChunks,
          traceId,
          isMasking: false,
          translatedMaskedChunks
        };
      });
    }
  ),

  on(ChatActions.MaskTextFailed,
    (state, { maskText }): State => {
      const { chatId, traceId } = maskText;
      const chat = getChat({ chatId }, state);
      const messages = chat.messages.map(m => {
        if (m.traceId === traceId) {
          return {
            ...m,
            traceId,
            isMasking: false
          };
        }
        return m;
      });

      return updateChat({chatId}, { messages: messages }, state);
    }
  ),

  on(ChatActions.NewMessage,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      if (chat) {
        const duplicateMessage: ChatInteraction = chat.messages.find(f =>
          f.type === payload.type && f.timestamp === payload.timestamp &&
          ((f.type === ChatMessageType.Message && (<ChatMessage>f).message === (<ChatMessage>payload).message) ||
            (f.type === ChatMessageType.Image && (<ChatImageMessage>f).retrievalUrl === (<ChatImageMessage>payload).retrievalUrl)));
        if (!duplicateMessage) {
          const newMessage = { ...payload };
          const pageMarkers = ChatHelper.formatPageMarkerData(chat.pageMarkers, newMessage as ChatMessage);
          return updateChat(payload, {
            messages: [
              ...chat.messages,
              newMessage
            ],
            ...(pageMarkers && { pageMarkers })
          }, state);
        }
      }
      return state;
    }
  ),

  on(ChatActions.UpdateMessages,
    (state, { payload }): State => {
      let newState = state;
      const { update, messages } = payload;
      switch (update) {
        case UpdateMessageType.Merge: {
          const chat = getChat(payload, state);
          const mergedMessages = ChatHelper.mergeChatMessagesWithServerResponse(messages, chat.messages);
          newState = updateChat(payload, {
            messages: mergedMessages
          }, state);
          break;
        }
        case UpdateMessageType.Prepend: {
          const chat = getChat(payload, state);
          newState = updateChat(payload, { messages: [...messages, ...chat.messages] }, state);
          break;
        }
      }
      return newState;
    }
  ),

  on(ChatActions.AddXaTranscript,
    (state, payload): State => {
      const { messages, xaTranscriptEndTimestamp } = payload;
      const chat = getChat(payload, state);
      return updateChat(payload, { messages: [...messages, ...chat.messages], xaTranscriptEndTimestamp }, state);
    }
  ),
  on(ChatActions.SendMessage,
    (state, { chatMessage }): State => {
      const chat = getChat(chatMessage, state);
      const { scriptsSent, scriptsInUse } = handleSentScripts(chat, chatMessage);

      return updateChat(chatMessage, {
        messages: [...chat.messages, chatMessage],
        scriptsSent,
        scriptsInUse,
      }, state);
    }
  ),

  on(ChatActions.Transfer,
    (state, { payload }): State => updateChat(payload, {
      agentTransferNotes: payload.notes,
      targetQueueId: payload.targetQueueId,
      targetQueueBusinessUnitId: payload.targetQueueBusinessUnitId
    }, state)
  ),

  on(ChatActions.CustomerExited,
    ChatActions.CloseEngagementInstruction,
    (state, { stateChangeResponse }): State => updateChat({ chatId: stateChangeResponse.chatId }, {
      messagingEnabled: false,
      isTransferrable: false,
      state: ChatState.Closed
    }, state)
  ),

  on(ChatActions.Close,
    (state, { payload }): State => updateChat(payload, {
      messagingEnabled: false,
      isTransferrable: false,
      state: ChatState.Closed
    }, state)
  ),

  on(ChatActions.CustomerDisconnected,
    (state, { payload: chatId }): State => updateChat({ chatId }, {
      messagingEnabled: false,
      isTransferrable: false,
      state: ChatState.Disconnected
    }, state)
  ),

  on(ChatActions.CustomerReconnected,
    (state, { payload: chatId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat.state === ChatState.Disconnected) {
        return updateChat({ chatId }, {
          messagingEnabled: true,
          isTransferrable: true,
          state: ChatState.Open
        }, state);
      }
      return state;
    }
  ),

  on(ChatActions.ChatAccepted,
    (state, { chat }): State => updateChat(chat, {
      state: ChatState.Open
    }, state)
  ),

  on(ChatActions.DispositionsReceived,
    (state, { payload }): State => updateChat(payload, {
      dispositions: payload.dispositions
    }, state)
  ),

  on(ChatActions.StoredTextToSend,
    (state, { chatId, storedTextToSend}): State => updateChat({ chatId }, {
      storedTextToSend: storedTextToSend
    }, state)
  ),

  on(ChatActions.UpdateTransferOptions,
    (state, { payload }): State => updateChat(payload, {
      transferOptions: payload.businessUnits
    }, state)
  ),

  on(ChatActions.ChannelInformation,
    (state, { payload }): State => {
      if (payload.channel.type === ChannelType.Sms) {
        const smsDevice = payload.channel.device as SmsDeviceInformation;
        return updateChat(payload, {
          channel: payload.channel,
          selectedPhoneNumber: smsDevice.phoneNumber
        }, state);
      }
      else {
        return updateChat(payload, {
          channel: payload.channel
        }, state);
      }
    }
  ),

  on(ChatActions.History_EngagementSelected,
    (state, { payload: selectedHistoryEngagement }): State => {
      const chat = getChat(selectedHistoryEngagement, state);
      return chat && chat.selectedHistoryEngagement?.priorEngagementId !== selectedHistoryEngagement.priorEngagementId
        ? updateChat(selectedHistoryEngagement, { selectedHistoryEngagement }, state)
        : state;
    }
  ),

  on(ChatActions.SetChatAccountLocality,
    (state, { payload }): State => updateChat(payload, {
      timeZone: payload.timeZone
    }, state)
  ),

  on(ChatActions.PriorEngagementAccordionChange,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      const priorEngagementChangeId = payload.priorEngagement ? payload.priorEngagement.id : null;

      return updateChat(payload, {
        engagementHistory: Object.assign([], chat.engagementHistory.map<ChatTranscript>((pe: ChatTranscript) => {

          if (pe.id === priorEngagementChangeId) {
            return Object.assign(new ChatTranscript(), pe, {
              expanded: !pe.expanded
            });
          }

          return pe;
        }))
      }, state);
    }
  ),

  on(ChatActions.ReportImage,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      return updateChat(payload, {
        messages: chat.messages.map((msg: ChatInteraction) => {
          const castedMsg = msg as ChatImageMessage;
          if (castedMsg === payload) {
            return Object.assign({}, castedMsg, {
              reported: true
            });
          }
          return msg;
        }),
        engagementHistory: chat.engagementHistory.map((eng: ChatTranscript) => {
          if (eng.transcripts) {
            return Object.assign(new ChatTranscript(), eng, {
              transcripts: eng.transcripts.map((engTranscript: EngagementTranscript) => {
                if (engTranscript.interactions) {
                  return Object.assign(new EngagementTranscript(), engTranscript, {
                    interactions: engTranscript.interactions.map((msg: ChatInteraction) => {
                      const castedMsg = msg as ChatImageMessage;
                      if (castedMsg === payload) {
                        return Object.assign({}, castedMsg, {
                          reported: true
                        });
                      }
                      return msg;
                    })
                  });
                }
                return engTranscript;
              })
            });
          }
          return eng;
        })
      }, state);
    }
  ),

  on(ChatActions.UpdateAccountNumber,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      let displayName = '';
      if (!payload.displayName) { return state; }
      const updatedUser = chat.users.map((user: ChatMember) => {
        if (user.type === SenderType.Requester) {
          const customer = user as Customer;
          displayName = payload.displayName;
          const name = ChatHelper.getCustomerName(displayName, customer.firstName, customer.lastName);
          return {
            ...customer,
            displayName,
            name
          };
        }
        return user;
      });
      return updateChat(payload, {
        users: updatedUser
      }, state);
    }
  ),

  on(ChatActions.UpdateAccountInformation,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      let displayName = '';
      const updatedUser = chat.users.map((user: ChatMember) => {
        if (user.type === SenderType.Requester) {
          const customer = user as Customer;
          displayName = payload.updateDisplayName ? payload.displayName : customer.displayName;
          const firstName = payload.firstName ?? customer.firstName;
          const lastName = payload.lastName ?? customer.lastName;
          const name = ChatHelper.getCustomerName(displayName, firstName, lastName);
          return {
            ...customer,
            firstName,
            lastName,
            displayName,
            name
          };
        }
        return user;
      });

      let { asyncEngagements } = chat;

      if (displayName && asyncEngagements) {
        asyncEngagements = asyncEngagements.map(ae => {
          return {
            ...ae,
            participants: ae.participants.map(p => {
              if (p.type === SenderType.Requester) {
                return {
                  ...p,
                  displayName: displayName
                };
              }
              return p;
            })
          } as AsyncEngagementTranscript;
        });
      }

      return updateChat(payload, {
        users: updatedUser,
        asyncEngagements,
        accountNumber: payload.accountNumber,
        authenticated: payload.authenticated,
        isAccountConnected: payload.isAccountConnected
      }, state);
    }
  ),

  on(ChatActions.LoadContext,
    (state, { payload }): State => updateChat(payload, {
      contactReason: payload.contactReason,
      uid: payload.uid
    }, state)
  ),

  on(ChatActions.UpdateUui,
    (state, { payload }): State => {
      const customerDetails: CustomerDetails = {
        firstName: payload.firstName,
        lastName: payload.lastName
      };
      return updateChat(payload, {
      customerGuid: payload.guid,
      uui: payload.uui,
      xaTranscriptSessionId: payload.xaTranscriptSessionId,
      previousEngagementId: payload.previousEngagementId,
      isReconnectEngagement: payload.previousEngagementId ? true : false,
      customerDetails: customerDetails,
      contactMethod: payload.contactMethod,
      customerLanguage: payload.language,
      customerPhoneNumber: payload.customerPhoneNumber,
    }, state);
  }
  ),

  on(ChatActions.ChatLoadComplete,
    (state, { payload: chatId }): State => updateChat({ chatId }, {
      isLoading: false
    }, state)
  ),

  on(ChatActions.ChatFirstLoadSuccess,
    (state, { payload: chatId }): State => updateChat({ chatId }, {
      isFirstLoad: false
    }, state)
  ),

  on(ChatActions.SetScriptInUse, UiActions.ScriptClicked,
    (state, { scriptInUse }): State => {
      if (!scriptInUse.scriptId) { return state; }
      const chat = getChat(scriptInUse, state);
      const containsScript = chat.scriptsInUse.some(s => s.scriptId === scriptInUse.scriptId);
      if (!containsScript) {
        return updateChat(scriptInUse, {
          scriptsInUse: [...chat.scriptsInUse, scriptInUse]
        }, state);
      }
      return state;
    }
  ),

  on(ChatActions.ClearScriptsInUse,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      if ( chat?.scriptsInUse?.length ) {
        return updateChat({ chatId }, {
          scriptsInUse: []
        }, state);
      }
      return state;
    }
  ),
  on(ChatUiActions.itgCompleted,
    (state, { chatId, title, timestamp }): State => {
      const chat = getChat({chatId}, state);

      const messages = appendItgActionMessage(chat.messages, chatId, ItgActionText.Completed, title, timestamp);

      return updateChat({chatId}, {
        messages
      }, state);
    }
  ),

  on(ChatActions.UpdateAsyncEngagements,
    (state, { updateAsyncEngagements }): State => updateChat(updateAsyncEngagements, {
      asyncEngagements: updateAsyncEngagements.engagements,
      unresolvedContactReason: updateAsyncEngagements.unresolvedContactReason
    }, state)
  ),

  on(ChatActions.AddXaSuggestions,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      const lastCustomerMessage = chat.messages.filter(m => m.sender === SenderType.Requester).sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)[0];
      const messageId = payload?.latestActiveItgMessageId ?? (<ChatMessage>lastCustomerMessage).messageId;
      const responseSuggestions: Suggestion[] = [];
      const responseItgSuggestions: ItgSuggestion[] = [];
      const responseSchedulerSuggestions: SchedulerSuggestion[] = [];

      const ignoreSuggestionOverwrite = chat.itgSuggestions?.some(s => s.isLatest);
      const hasDriftOccurred = Boolean(payload.itgDriftIntents?.length);
      const isNewSuggestionAdded = ChatHelper.parseSuggestions(chat, payload, lastCustomerMessage, payload?.latestActiveItgMessageId, responseItgSuggestions, responseSuggestions, responseSchedulerSuggestions, ignoreSuggestionOverwrite, hasDriftOccurred);

      const itgSuggestions = setXaSuggestionIsLatest(chat.itgSuggestions, messageId, isNewSuggestionAdded, !Boolean(responseItgSuggestions.length), hasDriftOccurred);
      const schedulerSuggestions = setXaSuggestionIsLatest(chat.schedulerSuggestions, messageId, isNewSuggestionAdded, ignoreSuggestionOverwrite, hasDriftOccurred);
      const suggestions = setXaSuggestionIsLatest(chat.suggestions, messageId, isNewSuggestionAdded, ignoreSuggestionOverwrite, hasDriftOccurred);

      return updateChat(payload, {
        suggestions: [].concat(...suggestions, responseSuggestions),
        itgSuggestions: [].concat(...itgSuggestions, responseItgSuggestions),
        schedulerSuggestions: [].concat(...schedulerSuggestions, responseSchedulerSuggestions),
        xaSuggestionsSessionId: payload.sessionId,
        xaResponseErrorMessages: payload.errorMessages,
        isTimeSlotTakenError: payload.isTimeSlotTakenError
      }, state);
    }
  ),
  on(ChatActions.AddRecommendation,
    (state, { addRecommendation }): State => {
      let responseItgSuggestions: ItgSuggestion[] = [];
      const startedItgIntents: string[] = [];
      const suggestion = addRecommendation.suggestion;
      const chat = getChat(addRecommendation, state);
      if (chat?.itgSuggestions?.length > 0){
        responseItgSuggestions = chat.itgSuggestions;
      }
      let contactReason = chat.contactReason;
      //suggestion can be null as we may get itg or/and xa cards
     if (suggestion?.isItgStarted){
        contactReason = suggestion.itgIntent?.reason;
        startedItgIntents.push(suggestion.intent);
     }
     else if (suggestion){
        const itgSuggestion: ItgSuggestion = {
          ...suggestion,
          queryId: addRecommendation.queryId,
          messageId: addRecommendation.messageId,
          isLatest: true,
          json: addRecommendation.json,
          timesSent: 0,
          visible: true
        };
        responseItgSuggestions.push(itgSuggestion);
      }

      return updateChat(addRecommendation, {
        itgSuggestions: responseItgSuggestions,
        recommendedIntents: addRecommendation?.intents,
        xaSuggestionsSessionId: addRecommendation.sessionId,
        startedItgIntents,
        contactReason
      }, state);
    }
  ),

  on(ChatActions.AddExternalSuggestions,
    ChatActions.AddGreetingSuggestion,
    (state, { chatId, suggestion }): State => {
    const chat = getChat({chatId}, state);
    let suggestions = chat.suggestions;
    if (!chat.suggestions.some(x => x.body?.message === suggestion?.body?.message)) {
      suggestions = clearIsLatest(chat.suggestions);
      suggestions.push(suggestion);
    }
    else{
      suggestions = chat.suggestions.map<Suggestion>((s: Suggestion) => {
        return {
          ...s,
          isLatest: (s.body?.message === suggestion?.body?.message)
        };
      });
    }
    return updateChat({ chatId },
      {
        suggestions: suggestions
      }, state);
  }),
  on(ChatActions.ClearLatestItgSuggestions, (state, {chatId}): State => {
    const chat = getChat({ chatId }, state);
    const suggestions = clearIsLatest(chat.itgSuggestions);

    return updateChat({ chatId },
      {
        itgSuggestions: suggestions
      }, state);
  }),
  on(ChatUiActions.itgCompleted, ChatUiActions.cancelItg, ChatUiActions.itgError, (state, {chatId, title}): State => {
    const chat = getChat({ chatId }, state);

    const suggestions = clearIsLatestExceptForStartItg(chat.itgSuggestions, title);

    return updateChat({ chatId },
      {
        itgSuggestions: suggestions
      }, state);
  }),

  on(ChatActions.ClearLatestSuggestions,
    (state, { payload: chatId }): State => {
      const chat = getChat({ chatId }, state);
      const suggestions = clearIsLatest(chat.suggestions);

      return updateChat({ chatId },
        {
          suggestions: suggestions
        }, state);
    }
  ),

  on(ChatActions.ClickSuggestion,
    (state, { payload }): State => updateChat(payload,
      {
        clickedSuggestion: payload
      }, state)
  ),

  on(ChatActions.ClearClickedSuggestion,
    (state, { payload: chatId }): State => updateChat({ chatId },
      {
        clickedSuggestion: null
      }, state)
  ),

  on(ChatActions.SendSuggestion,
    (state, { payload }): State => {

      const chat = getChat(payload, state);
      const senderType = payload.source === SendSuggestionSource.AutoPilot ? SenderType.XA : SenderType.FixAgent;
      const newMessage: XaSuggestionChatMessage = {
        ...payload,
        timestamp: payload.timestamp,
        type: ChatMessageType.XaSuggestion,
        sender: senderType,
        message: payload.body?.message,
        xaMessage: payload.heading,
        autoPilot: (payload.source === SendSuggestionSource.AutoPilot),
        status: ChatMessageStatus.Pending
      };

      const suggestions = chat.suggestions.map<Suggestion>((suggestion: Suggestion) => {
        return {
          ...suggestion,
          timesSent: (payload.intent === suggestion.intent && suggestion.visible) ? suggestion.timesSent + 1 : suggestion.timesSent
        };
      });

      if (payload?.suggestionType === SuggestionType.External) {
        const chatMessage: SendChatMessage = {
          chatId: chat.chatId,
          message: payload.body.message,
          timestamp: payload.timestamp,
          sender: SenderType.FixAgent,
          type: ChatMessageType.Message,
          sendMessageType: SendMessageType.Script,
          scriptTreeId: chat.scriptTreeId,
          traceId: payload.traceId,
          status: ChatMessageStatus.Pending
        };

        let dataCollectionState = chat.secureDataCollection?.dataCollectionState;
        if (payload.title === DataCollectionSuggestionTitles.linkAvailable) {
          dataCollectionState = DataCollectionState.LinkSent;
        }

        return updateChat(payload,
          {suggestions: suggestions,
            messages: [...chat.messages, chatMessage],
            secureDataCollection: {
              ...chat.secureDataCollection,
              dataCollectionState
            }
          }, state);
      }

      return updateChat(payload,
        {
          messages: [
            ...chat.messages, newMessage
          ],
          suggestions: suggestions
        }, state);
    }
  ),
  on(ChatActions.SendSmartResponse,
    (state, { chatMessage, smartResponseToSend }): State => {
      const chat = getChat(chatMessage, state);
      const { id, type, value, parentMessageId, smartResponsesDisplayed } = smartResponseToSend;

      const smartResponses: SmartResponseData[] = [
        {
          id,
          type,
          value
        }
      ];

      const smartResponseSent: SmartResponseSent = {
        smartResponses,
        sentMessageId: chatMessage.messageId,
        traceId: chatMessage.traceId,
        parentMessageId,
        smartResponsesDisplayed
      };

      const {scriptsSent, scriptsInUse} = handleSentScripts(chat, chatMessage);

      return updateChat(chatMessage,
        {
          messages: [
            ...chat.messages, chatMessage
          ],
          smartResponsesSent : [
            ...chat.smartResponsesSent, smartResponseSent
          ],
          scriptsSent,
          scriptsInUse
        }, state);
    }
  ),
  on(ChatActions.SendCxGptResponse,
    (state, { chatMessage, cxGptResponse }): State => {
      const chat = getChat(chatMessage, state);

      const cxGptResponseSent: CxGptResponseSent = {
        cxGptResponse,
        sentMessageId: chatMessage.messageId,
        traceId: chatMessage.traceId
      };

      return updateChat(chatMessage,
        {
          messages: [
            ...chat.messages, chatMessage
          ],
          cxGptResponseSent : [
            ...chat.cxGptResponseSent, cxGptResponseSent
          ]
        }, state);
    }
  ),
  on(ChatActions.messageSent,
    (state, { traceId, messageId, chatId, timestamp, translatedText }): State => {
      const updatedTraceIdState = updateMessagesByTraceId(state, chatId, traceId, messageId, (m) => {
        return {
          ...m,
          messageId,
          status: ChatMessageStatus.Sent,
          ...((<ChatMessage>m).translationData && { translationData: {
            ...(<ChatMessage>m).translationData,
            hasError: false
          } })
        };
      });

      const chat = getChat({ chatId }, updatedTraceIdState);
      let message = chat.messages.find(m => m.traceId === traceId);
      if (message){
        if (translatedText) {
          const messageWithTranslationData = <ChatMessage>message;

          if (!messageWithTranslationData.translationData) {
            messageWithTranslationData.translationData = new ChatMessageTranslationData();
          }
          messageWithTranslationData.translationData.translatedMessage = translatedText;
          message = messageWithTranslationData;
        }
        message.timestamp = timestamp;
        const messages = [...chat.messages].filter(m => m.traceId !== traceId);
        const messageAfterIndex = messages.findIndex(m => m.timestamp > timestamp);

        if (messageAfterIndex === -1){
          messages.push(message);
        }
        else{
          messages.splice(messageAfterIndex, 0, message);
        }

        return updateChat({chatId}, {messages}, updatedTraceIdState);
      }

      return updatedTraceIdState;
    }
  ),
  on(ChatActions.updateMessageId,
    (state, { traceId, messageId, chatId }): State =>
    updateMessagesByTraceId(state, chatId, traceId, messageId, (m) => {
      return {
        ...m,
        messageId
      };
    })
  ),

  on(ChatActions.SelectedDispositions,
    (state, { payload }): State => {
      const chat = getChat(payload, state);
      let disposition = new Disposition();
      let dispositionCollection = new DispositionCategory();
      chat.dispositions.map((group) => {
        if (group.id === payload.collectionId) {
          group.dispositions.map((currentDisposition) => {
            if (currentDisposition.id === payload.dispositionId) {
              disposition = currentDisposition;
              dispositionCollection = group;
            }
          });
        }
      });

      const selectedDisposition: DispositionSelection = {
        chatId: chat.chatId,
        collectionId: dispositionCollection.id,
        collectionDisplayText: dispositionCollection.displayText,
        disposition: disposition,
        notes: payload.notes
      };

      return updateChat(payload, { selectedDispositions: selectedDisposition }, state);
    }
  ),

  on(ChatActions.UpdateSendCustomerQuickResponses,
    (state, { payload }): State => updateChat(payload, { sendQuickResponses: payload.sendButtons }, state)
  ),

  on(ChatActions.UpdateSelectedSchedule,
    (state, payload): State => {
      return updateChat(payload, { selectedSchedule: payload.selectedSchedule }, state);
    }
  ),

  on(ChatUiActions.startItg,
    (state, { chatId, itgSuggestion, timestamp }): State => {
      const clearLatestSuggestion = clearIsLatest(state.entities[chatId].itgSuggestions);
      const nextState = updateChat({ chatId }, {itgSuggestions: clearLatestSuggestion}, state);
      return addItgActionMessage(chatId, nextState, ItgActionText.Started, itgSuggestion.itgIntent.title, timestamp);
    }
  ),

  on(ChatUiActions.resumeItg,
    (state, { chatId, title, timestamp }): State => addItgActionMessage(chatId, state, ItgActionText.Resumed, title, timestamp)
  ),

  on(ChatActions.sendMessageFailed,
    ChatActions.sendSuggestionFailed,
    (state, { chatId, traceId, isTranslationError }): State => {
      const chat = getChat({chatId}, state);
      const messages = chat.messages.map((m: AgentChatMessage) => {
        if (m.traceId === traceId) {
          const status = m.retryAttempts >= SendChatMessageSettings.MaxRetryAttempts ? ChatMessageStatus.Failed : ChatMessageStatus.Error;
          return {
            ...m,
            status,
            translationData: {
              ...m.translationData,
              hasError: isTranslationError
            }
          };
        }
        return m;
      });
      return updateChat({chatId}, { messages: messages }, state);
    }
  ),

  on(ChatActions.retrySendXaSuggestion,
    ChatActions.retrySendMessage,
    (state, { chatMessage, newTraceId }): State => {
      // assign a new traceId to be sent with
      const chat = getChat(chatMessage, state);
      const messages = chat.messages.map((m: AgentChatMessage) => {
        if (m.traceId === chatMessage.traceId) {
          return {
            ...m,
            status: ChatMessageStatus.Retrying,
            retryAttempts: m.retryAttempts ? m.retryAttempts + 1 : 1,
            traceId: newTraceId
          };
        }
        return m;
      });
      const smartResponsesSent = chat.smartResponsesSent?.map((smartResponse) => {
        if (smartResponse.traceId === chatMessage.traceId) {
          return {
            ...smartResponse,
            traceId: newTraceId
          };
        }
        return smartResponse;
      });
      return updateChat(chatMessage, { messages: messages, smartResponsesSent }, state);
    }
  ),

  on(ChatActions.retryTranslateMessage,
    (state, { chatMessage }): State => {
      const chat = getChat(chatMessage, state);
      const messages = chat.messages.map((m: AgentChatMessage) => {
        if (m.messageId === chatMessage.messageId) {
          return {
            ...m,
            status: ChatMessageStatus.Retrying
          };
        }
        return m;
      });
      return updateChat(chatMessage, { messages: messages }, state);
    }
  ),

  on(ChatActions.retryTranslateMessageUpdated,
    (state, { chatMessage, isError, translationResponse }): State => {
      const chat = getChat(chatMessage, state);
      const messages = chat.messages.map((m: AgentChatMessage) => {
        if (m.messageId === chatMessage.messageId) {
          const translationData: ChatMessageTranslationData = isError ? { hasError: true } : { 
            translatedMessage: translationResponse?.translatedMessages?.length
              ? translationResponse?.translatedMessages[0].text
              : undefined,
            hasError: false
          };
          return {
            ...m,
            status: ChatHelper.getCustomerMessageStatus(isError),
            translationData
          };
        }
        return m;
      });
      return updateChat(chatMessage, { messages: messages }, state);
    }
  ),

  on(ChatActions.deleteChatMessage,
    (state, { chatMessage }): State => {
      const chat = getChat(chatMessage, state);
      const messages = chat.messages.filter(m => m !== chatMessage);
      return updateChat(chatMessage, { messages: messages }, state);
    }
  ),

  on(ChatActions.UpdateSecureDataCollectionState,
    (state, { chatId, requestState, timestamp }): State => {
      const chat = getChat({chatId}, state);
      let secureDataStateMessage;
      const isDataCollectionFinished = SecureDataCollectionHelper.isDataCollectionFinished(requestState);
      switch (requestState){
        case DataCollectionState.CustomerStarted:
          secureDataStateMessage = DataCollectionSystemMessages.customerStartedForm;
          break;
        case DataCollectionState.CustomerApplied:
          secureDataStateMessage = DataCollectionSystemMessages.customerApplied;
          break;
        case DataCollectionState.CustomerClosed:
          secureDataStateMessage = DataCollectionSystemMessages.customerClosed;
          break;
        case DataCollectionState.CustomerCompleted:
          secureDataStateMessage = DataCollectionSystemMessages.customerCompleted;
          break;
      }

      const messages = secureDataStateMessage
                      ? appendSystemNavigationMessage(chat.messages, chatId, secureDataStateMessage, timestamp)
                      : chat.messages;

      // Add entry to secureDataRequests. SecureDataCollection's state is set for banner purposes, but other values are wiped for future requests
      if (isDataCollectionFinished) {
        const secureDataRequest: SecureDataRequest = {
          startTime: chat.secureDataCollection.startTime,
          endTime: timestamp,
          finalStatus: requestState
        };
        return updateChat({ chatId }, {
          secureDataCollection: {
            dataCollectionState: requestState
          },
          secureDataRequests: [
            ...chat.secureDataRequests,
            secureDataRequest
          ],
          messages
          }, state);

      }

      return updateChat({ chatId }, { secureDataCollection: { ...chat.secureDataCollection, dataCollectionState: requestState }, messages }, state);
    }
  ),

  on(ChatActions.InitiateSecureDataCollection,
    (state, { requestData }): State => {
      const chat = getChat({ chatId: requestData.chatId }, state);

      return updateChat(requestData, {
          secureDataCollection: {
            ...chat.secureDataCollection,
            dataCollectionState: DataCollectionState.Available,
            dataCollectionUrl: requestData.collectionUrl
          }
        }, state);
    }
  ),

  on(ChatActions.StartDataCollectionRequest,
    (state, { chatId, startTime }): State => {
      const chat = getChat({ chatId }, state);
      //Only change state if cti message is sent in chat.effects
      if (chat?.uui && chat?.accountNumber) {
        return updateChat({ chatId }, {
            secureDataCollection: {
              ...chat.secureDataCollection,
              dataCollectionState: DataCollectionState.Requesting,
              startTime
            }
          }, state);
      }

      return state;
    }
  ),

  on(ChatActions.CancelDataCollectionRequest,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      //Only change state if cti message is sent in chat.effects
      if (chat?.uui) {
        return updateChat({ chatId }, {
            secureDataCollection: {
              ...chat.secureDataCollection,
              dataCollectionState: DataCollectionState.Cancelling
            }
          }, state);
      }

      return state;
    }
  ),

  on(ChatActions.oneCtiStartVerified, (state, action) =>
    updateChat(action, {
      oneCtiConnected: true
    }, state)
  ),
  on(ChatActions.UpdateSmartResponseFeedBack, (state, {chatId, feedback}) =>
    updateChat({chatId}, {
      smartResponseFeedback: feedback
    }, state)
  ),
  //making smartResponseFeedback to null when we get new smartResponses
  on(SmartResponsesActions.updateSmartResponses, (state, {chatId}) =>
    updateChat({chatId}, {
      smartResponseFeedback: null
    }, state)
  ),
  on(ChatActions.hideSuggestion,
    (state, { chatId, suggestion }): State => {
      const chat = getChat({ chatId }, state);
      if (chat){
        const suggestions = chat.suggestions?.map(s => {
          if (s === suggestion){
            return {
              ...s,
              visible: false
            };
          }
          return s;
        });

        return updateChat({ chatId }, {
          suggestions
        }, state);
      }

      return state;
    }
  ),
  on(
    ChatActions.DismissLatestSuggestions,
    (state, { sendSuggestion, timestamp }): State => {
      const chat = getChat({ chatId: sendSuggestion?.chatId }, state);
      if (chat && sendSuggestion) {
        const feedbackEntry = {
          queryId: sendSuggestion.queryId ?? null,
          reason: DismissSuggestionReason.Unknown
        };

        const transcriptEvent: TranscriptEvent = {
          eventName: NlpTranscriptEventName.DismissXaCard,
          timestamp,
          data: feedbackEntry
        };

        const events = [
          ...chat.events,
          transcriptEvent
        ];

        return updateChat({ chatId: sendSuggestion.chatId }, {
          events
        }, state);
      }

      return state;
    }
  ),
  on(AgentActions.SubmitDismissSuggestionFeedback,
    (state, { reason, dismissedSuggestion, timestamp }): State => {
      if (!dismissedSuggestion) {
        return state;
      }

      const { chatId, queryId } = dismissedSuggestion;
      const chat = getChat({ chatId }, state);
      if (chat){
        const feedbackEntry = {
          queryId: queryId ?? null,
          reason
        };

        const newTranscriptEvent: TranscriptEvent = {
          eventName: NlpTranscriptEventName.DismissXaCard,
          timestamp,
          data: feedbackEntry
        };

        let events = [...chat.events];
        const suggestionFeedbackEvents = events.filter(event => event.eventName === NlpTranscriptEventName.DismissXaCard);
        if (suggestionFeedbackEvents.length) {
          // Latest feedback event should always have an entry with reason = Unknown when this is called
          const index = events.indexOf(suggestionFeedbackEvents[suggestionFeedbackEvents.length - 1]);
          events[index] = newTranscriptEvent;
        }
        else {
          events = events.concat([newTranscriptEvent]);
        }

        return updateChat({ chatId }, {
          events
        }, state);
      }

      return state;
    }
  ),
  on(ChatActions.rateSessionSummary, (state, { isPositiveRating, chatId }) => {
    const chat = getChat({ chatId }, state);
    if (!chat) {
      return state;
    }
    const summary = chat.summaryData;
    if (!summary) {
      return state;
    }

    const updatedChat = {
      ...chat,
      summaryData: {
        ...summary,
      isPositiveRating,
      rated: true,
      }
    };
    return adapter.upsertOne(updatedChat, state);
  }),
  on(ChatActions.resetSessionSummaryRating, (state, { chatId }) => {
    const chat = getChat({ chatId }, state);
    if (!chat) {
      return state;
    }
    const summary = chat.summaryData;
    if (!summary) {
      return state;
    }

    const updatedChat = {
      ...chat,
      summaryData: {
        ...summary,
        isPositiveRating: undefined,
        rated: false,
      }
    };
    return adapter.upsertOne(updatedChat, state);
  }),
  on(UiActions.GetChatSummaryData,
    (state, { chatId, isRetry, isAsyncEngagementsRequest, traceId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat) {
        const numRetries = chat.summaryData?.getSummaryNumRetries ?? 0;
        const updatedSummaryData: ChatSummaryData = {
          recap: '',
          isLoading: true,
          getSummaryNumRetries: isRetry ? numRetries + 1 : numRetries,
          traceId
        };

        if (isAsyncEngagementsRequest){
          return updateChat({ chatId }, {
            asyncEngagementsSummaryData: updatedSummaryData
          }, state);
        }
        else{
          return updateChat({ chatId }, {
            summaryData: updatedSummaryData
          }, state);
        }
      }

      return state;
    }
  ),
  on(UiActions.CancelGetChatSummaryData,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat) {
        const updatedSummaryData: ChatSummaryData = {
          ...chat?.summaryData,
          isLoading: false
        };

        return updateChat({ chatId }, {
          summaryData: updatedSummaryData
        }, state);
      }

      return state;
    }
  ),
  on(ChatActions.UpdateChatSummary,
    (state, { chatId, chatSummaryData, error }): State => {
      if (chatId) {
        const chat = getChat({ chatId }, state);
        
        if (chat) {
          let updatesToSummaryData;
          if (error) {
            updatesToSummaryData = {
              isLoading: false,
              hasError: true
            };
          }
          else {
            updatesToSummaryData = {
              ...chatSummaryData,
              isLoading: false
            };
          }
          
          const { traceId } = chatSummaryData;

          if (chat.asyncEngagementsSummaryData?.traceId === traceId){
            const newSummaryData: ChatSummaryData = {
              ...chat.asyncEngagementsSummaryData,
              ...updatesToSummaryData
            };
            return updateChat({ chatId }, {
              asyncEngagementsSummaryData: newSummaryData
            }, state);
          }
          else if (chat.summaryData?.isLoading){
            const newSummaryData: ChatSummaryData = {
              ...chat.summaryData,
              ...updatesToSummaryData
            };
            return updateChat({ chatId }, {
              summaryData: newSummaryData
            }, state);
          }
          
        }
      }

      return state;
    }
  ),
  on(ChatActions.UpdateBotConversationSummary,
    (state, { chatId, botConversationSummary }): State => {
      if (chatId) {
        const chat = getChat({ chatId }, state);        
        if (chat) {
          return updateChat({ chatId }, {
            botConversationSummary
          }, state);
        }
      }
      return state;
    }
  ),
  on(UiActions.SendAutoSaveSummary,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat) {
        const updatedSummaryData: ChatSummaryData = {
          ...chat.summaryData,
          autoSaveState: LoadingState.Pending,
          autoSaveNumRetries: 0
        };

        return updateChat({ chatId }, {
          summaryData: updatedSummaryData
        }, state);
      }

      return state;
    }
  ),
  on(UiActions.BackgroundAutoSaveSummaryTriggered,
    (state, { pendingChatSummaryData }): State => {
      const chatId = pendingChatSummaryData.chatId;
      const chat = getChat({ chatId }, state);
      if (chat) {
        const updatedSummaryData: ChatSummaryData = {
          ...chat.summaryData,
          autoSaveState: LoadingState.Pending
        };

        return updateChat({ chatId }, {
          summaryData: updatedSummaryData
        }, state);
      }

      return state;
    }
  ),
  on(UiActions.UpdateAutoSaveSummary,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat) {
        const updatedSummaryData: ChatSummaryData = {
          ...chat.summaryData,
          autoSaveState: LoadingState.Success
        };

        return updateChat({ chatId }, {
          summaryData: updatedSummaryData
        }, state);
      }

      return state;
    }
  ),
  on(UiActions.AutoSaveSummaryFailed,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat) {
        const updatedSummaryData: ChatSummaryData = {
          ...chat.summaryData,
          autoSaveState: LoadingState.Failed
        };

        return updateChat({ chatId }, {
          summaryData: updatedSummaryData
        }, state);
      }

      return state;
    }
  ),
  on(ChatActions.rateAsyncEngagementsSummary, (state, { isPositiveRating, chatId }) => {
    const chat = getChat({ chatId }, state);
    if (!chat) {
      return state;
    }
    const summary = chat.asyncEngagementsSummaryData ?? new ChatSummaryData();

    const updatedChat = {
      ...chat,
      asyncEngagementsSummaryData: {
        ...summary,
        isPositiveRating,
        rated: true,
      }
    };
    return adapter.upsertOne(updatedChat, state);
  }),
  on(UiActions.RetryAutoSaveSummary,
    (state, { chatId }): State => {
      const chat = getChat({ chatId }, state);
      if (chat) {
        const numRetries = chat.summaryData?.autoSaveNumRetries ?? 0;
        const updatedSummaryData: ChatSummaryData = {
          ...chat.summaryData,
          getSummaryNumRetries: numRetries + 1
        };

        return updateChat({ chatId }, {
          summaryData: updatedSummaryData
        }, state);
      }

      return state;
    }
  ),
  on(ChatActions.AddCustomerAwaitingReplySuggestion, (state, {chatId}) =>
    {
      const chat = getChat({chatId}, state);
      let suggestions = [...chat.suggestions];
      suggestions = clearIsLatest(chat.suggestions);
      let customerAwaitingReplyMessagePriority = (chat.customerAwaitingReplyMessagePriority === CustomerAwaitingReplyMessages.length) ? 0 : chat.customerAwaitingReplyMessagePriority;
     
      const message = CustomerAwaitingReplyMessages[customerAwaitingReplyMessagePriority];
      customerAwaitingReplyMessagePriority = customerAwaitingReplyMessagePriority + 1;
      const customerAwaitingReplySuggestion: Suggestion =  {       
          body: {
            message,
            buttons: null,
            image: null
          },
          queryId: null,
          title: 'Recommended response',
          isLatest: true,
          json: null,
          messageId: null,
          timesSent: 0,
          suggestionType: SuggestionType.CustomerAwaitingReply
        };
      
      suggestions = [...suggestions, customerAwaitingReplySuggestion];
      return updateChat({ chatId },
        {
          suggestions: suggestions,
          customerAwaitingReplyMessagePriority
        }, state);
    }
  ),
  on(ChatActions.EnhanceChatMessage, (state, {chatId}) =>
    {
      const chat = getChat({chatId}, state);
      const messageData = chat?.enhancedChatMessageData;

      return updateChat({ chatId },
        {
          enhancedChatMessageData: {
            ...messageData,
            hasError: false,
            isLoading: true,
            message: ''
          }
        }, state);
    }
  ),
  on(ChatUiActions.messageInputUndoClicked,
    ChatUiActions.clearAskMeAnythingRewriteAndEnhanceContent,
    (state, {chatId}) =>
    {
      const chat = getChat({chatId}, state);
      const messageData = chat?.enhancedChatMessageData;

      return updateChat({ chatId },
            {
              enhancedChatMessageData: {
                ...messageData,
                message: '',
              }
            }, state);
    }
  ),
  on(ChatActions.UpdateEnhanceChatMessage, (state, {chatId, message, error}) =>
    {
      const chat = getChat({chatId}, state);
      const messageData = chat?.enhancedChatMessageData;

      return updateChat({ chatId },
        {
          enhancedChatMessageData: {
            ...messageData,
            isLoading: false,
            message,
            hasError: error
          }
        }, state);
    }
  ),
  // Ensure logging is correct based on if rewrite or enhance was used most recently
  on(AskMeAnythingActions.addRewrite,
    (state, { chatId }): State => {
      const chat = getChat({chatId}, state);
      const messageData = chat?.enhancedChatMessageData;

      return updateChat({ chatId },
        {
          enhancedChatMessageData: {
            ...messageData,
            message: '',
          }
        }, state);
    }
  ),
  on(LanguageTranslationActions.chatMessagesTranslationUpdated,
    (state, { chatId, translatedMessages, isError }): State => {
      const chat = getChat({ chatId }, state);
      if (!chat) {
        return state;
      }
      const hasTranslationIssue = isError || !translatedMessages?.length;
      const updatedMessages = chat.messages.map((m: ChatMessage) => {
        if (!hasTranslationIssue) {
          //Update target message with new translation data
          const targetTranslationData = translatedMessages.find(tm => ChatHelper.doesTraceOrMessageIdMatch(tm.traceId, tm.traceId, m.traceId, m.messageId));
          if (targetTranslationData) {
            return { 
              ...m,
              translationData: {
                ...m.translationData,
                isLoading: false,
                translatedMessage: targetTranslationData.text
              }
            };
          }
        }
        //Simply mark every message that has translationData loading as true with an error
        else if (m.translationData?.isLoading) {
          return { 
            ...m,
            translationData: {
              ...m.translationData,
              isLoading: false,
              hasError: true
            }
          };
        }
        
        return m;
      });

      return updateChat({ chatId }, {
        messages: updatedMessages
      }, state);
    }
  ),
  on(ChatActions.TranslationToggled,
    (state, { chatId, isEnabled }): State => {
      return updateChat({ chatId }, {
        isTranslationConfigEnabled: isEnabled
      }, state);
    }
  ),
  on(ChatActions.CustomerHeartbeatChecked,
    (state, { chatId }): State => {
      return updateChat({ chatId }, {
        heartbeatChecked: true
      }, state);
    }
  ),
);

function addItgActionMessage(chatId: string, state: State, actionMessage: string, title: string, timestamp: number): State{
  const chat = getChat({ chatId }, state);
  const messages = appendItgActionMessage(chat.messages, chatId, actionMessage, title, timestamp);

  return updateChat({ chatId }, { messages }, state);
}

function appendItgActionMessage(messages: ChatInteraction[], chatId: string, actionMessage: string, title: string, timestamp: number): ChatInteraction[]{
  const message = `${actionMessage} ${title}`;
  const itgActionMessage: ChatMessage = {
    chatId,
    sender: SenderType.XA,
    timestamp,
    type: ChatMessageType.ItgAction,
    message
  };
  return [...messages, itgActionMessage];
}

function appendSystemNavigationMessage(messages: ChatInteraction[], chatId: string, message: string, timestamp: number): ChatInteraction[] {
  const systemNavigationMessage: SystemChatMessage = {
    chatId,
    sender: SenderType.System,
    timestamp,
    type: ChatMessageType.Message,
    message,
    systemType: SystemMessageType.Navigation,
    systemInfoMessageType: SystemInfoMessageType.SecureDataCollection
  };
  return [...messages, systemNavigationMessage];
}


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

function setXaSuggestionIsLatest(chatSuggestions: Suggestion[], messageId: string, isNewSuggestionAdded: boolean, ignoreSuggestionOverwrite: boolean, hasDriftOccurred: boolean){

  if (!isNewSuggestionAdded) { return chatSuggestions; }

  return chatSuggestions.map<Suggestion>((data: Suggestion) => {
    return {
      ...data,
      isLatest: ignoreSuggestionOverwrite || hasDriftOccurred ? data.isLatest : messageId === data.messageId
    };
  });
}

function clearIsLatest(suggestions) {
  return suggestions.map((suggestion) => {
    return {
      ...suggestion,
      isLatest: false
    };
  });
}

function clearIsLatestExceptForStartItg(suggestions, title) {
  return suggestions.map((suggestion) => {
    return {
      ...suggestion,
      //set isLatest to false if isItgStart is false and Itg is started or End
      isLatest: (suggestion.isItgStart && suggestion.itgIntent?.title !== title) ? suggestion.isLatest : false
    };
  });
}

function mapScriptsInUseToScriptsSent(message: string, chat: Chat): ScriptSent[] {
  const { chatId } = chat;
  let { scriptsSent } = chat;
  chat.scriptsInUse.forEach(({ scriptId, scriptValue, scriptGroupName }) => {
    const sendArgs: SetSentScript = {
      chatId,
      scriptId,
      scriptValue,
      scriptGroupName,
      message,
    };
    scriptsSent = reduceScriptsSent(chat, sendArgs);
  });
  return scriptsSent;
}

function reduceScriptsSent(chat: Chat, setSentScript: SetSentScript): ScriptSent[] {
  const { message, scriptId, scriptGroupName, scriptValue } = setSentScript;
  const scriptToSet = chat.scriptsSent.find(s => s.id === scriptId);
  const sentMessage: SentMessage = {
    message,
  };

  if (scriptToSet) {
    return chat.scriptsSent.map<ScriptSent>((ss: ScriptSent) => {
        if (ss.id === scriptId) {
          return {
            ...ss,
            timesSent: ss.timesSent + 1,
            sentMessages: [...ss.sentMessages, sentMessage]
          };
        }
        return ss;
      });
  }
  else {
    const firstSent: ScriptSent = {
      id: scriptId,
      timesSent: 1,
      sentMessages: [sentMessage],
      scriptGroupName,
      scriptValue,
    };
    return [...chat.scriptsSent, firstSent];
  }
}

function updateMessagesByTraceId(state: State, chatId: string, traceId: string, messageId: string, transform: (message: ChatInteraction) => ChatInteraction): State{
  const chat = getChat({ chatId }, state);
  const messages = chat.messages.map(m => {
    if (m.traceId === traceId) {
      return transform(m);
    }
    return m;
  });
  const smartResponsesSent = chat.smartResponsesSent?.map((smartResponse) => {
    if (smartResponse.traceId === traceId) {
      return {
        ...smartResponse,
        sentMessageId: messageId
      };
    }
    return smartResponse;
  });
  const cxGptResponseSent = chat.cxGptResponseSent?.map((cxGptResponse) => {
    if (cxGptResponse.traceId === traceId) {
      return {
        ...cxGptResponse,
        sentMessageId: messageId
      };
    }
    return cxGptResponse;
  });
  return updateChat({chatId}, { messages: messages, smartResponsesSent, cxGptResponseSent }, state);
}

function updateChatsFromActiveEngagements(state: State, engagements: ChatTranscriptResponse[], getChatFromSource: (chatId: string, chatsState: State) => Chat): State {
  let updatedChatState = state;

  engagements.forEach(engagement => {
    const chatInState = getChatFromSource(engagement.engagementId, updatedChatState);

    if (chatInState) {
      const responseInteractions = ChatHelper.parseTranscriptInteractions(engagement.engagementId, engagement.participants, engagement.interactions);
      const users = ChatHelper.syncUsersFromTranscriptResponse(engagement.participants, chatInState.users);
      const messages = ChatHelper.mergeChatMessagesWithServerResponse(responseInteractions, chatInState.messages);
      const { state: chatState, messagingEnabled, isTransferrable } = ChatHelper.getUpdatedChatStateFromMessages(messages, chatInState);
      const chat = {
        ...chatInState,
        users,
        messages,
        state: chatState,
        messagingEnabled,
        isTransferrable
      };
      updatedChatState = adapter.upsertOne(chat, updatedChatState);
    }
    else {
      // TODO: log chat not found and reject? or do we try and load it?
    }
  });

  return updatedChatState;
}

function handleSentScripts(chat: Chat, chatMessage: ChatMessage): {scriptsSent: ScriptSent[] , scriptsInUse: ScriptInUse[]} {
  let { scriptsSent, scriptsInUse } = chat;
  const { sender, type, message } = chatMessage;

  // if message sent by agent and scripts in use then move scripts in use to sent scripts
  if (scriptsInUse?.length && sender === SenderType.FixAgent && type === ChatMessageType.Message) {
    scriptsSent = mapScriptsInUseToScriptsSent(message, chat);
    scriptsInUse = [];
  }

  return {scriptsSent, scriptsInUse};
}
