// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { GetUpdatesData } from './models/get-updates-data';
import { PageInitializationItem } from './models/page-initialization-item';

export const AppInitialized = createAction('[App] Initialized');

export const VoiceAppInitialized = createAction('[App] Voice Initialized');

export const AppStopped = createAction(
  '[App] Stopped',
  props<{ sourceWindowId: string }>()
);

export const InitializeService = createAction('[App] Initialize Service');

export const InitializeVoiceService = createAction('[App] Initialize Voice Service');

export const initializeSessionTimeoutPageServices = createAction('[App] Initialize Session Timeout Page Services');

export const ServiceInitialized = createAction('[App] Service Initialized');

export const VoiceServiceInitialized = createAction('[App] Voice Service Initialized');

export const PageInitializationSuccess = createAction(
  '[App] Page Initialization Success'
);

export const UpdatePageInitialization = createAction(
  '[App] Update Page Initialization',
  props<{ data: PageInitializationItem }>()
);

export const SetCurrentPage = createAction(
  '[App] Set Current Page',
  props<{ pageName: string }>()
);

export const StartGetUpdates = createAction(
  '[App] Start Get Updates',
  props<{ source: string }>()
);

export const StopGetUpdates = createAction(
  '[App] Stop Get Updates',
  props<{ source: string }>()
);

export const UpdatesComplete = createAction(
  '[App] Updates Complete',
  props<{ traceId: string, timestamp: number }>());

export const GetUpdatesPrevented = createAction(
  '[App] Get Updates Prevented',
  props<{ traceId: string, getUpdatesData: GetUpdatesData, source: string }>()
);

export const GetUpdates = createAction(
  '[App] Get Updates',
  props<{ traceId: string }>()
);

export const ChatStateDirty = createAction('[App] Chat State Dirty');

export const PendingServerSync = createAction(
  '[App] Pending Server Sync',
  props<{ timestamp: number }>()
);

export const ServerSyncFailed = createAction('[App] Server Sync Failed');
