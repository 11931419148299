// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { MercuryCtiChatService } from 'src/app/services/mercury-cti-chat.service';
import { AgentAuthActions, AppActions, AppState, CallActions, CallOperations, Chat, ChatActions, ChatHelper, ChatOperations, ChatUiActions, ErrorEvents, LogHelper, OneCtiActions } from 'projects/entities/src/public_api';
import { ChatDialogWSOptions } from '../constants/cti.constants';
import { fromApp, fromCall, fromChat, fromOneCti } from 'projects/entities/src/lib/domain/selectors';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Customer, ExternalSuggestion, LoadContext, SecureDataCollectionInfo, UpdateAccountNumber } from 'projects/entities/src/lib/domain/models';
import { Guid } from 'guid-typescript';
import { DataCollectionState, SenderType, SuggestionType } from 'projects/entities/src/lib/domain/models/enums';
import { CtiChatResponse } from '../models/cti-chat-response';
import { CtiSecureDataCollectionInitiatedResponse } from '../models/cti-secure-data-collection-initiated-response';
import { CtiChatDialogWsResponse } from '../models/cti-chat-dialog-ws-response';
import { CtiBeginCallRequestData } from '../models/cti-begin-call-request';
import { CustomerInfo } from 'projects/entities/src/lib/domain/models/voice/customerInfo';
import { Call } from 'projects/entities/src/lib/domain/voice/call/call.model';
import { CallSummaryDisplay, RecommendedActionState } from 'projects/entities/src/lib/domain/models/voice/enums';
import { AppConfigService } from '../services/app-config.service';
import { MercuryCtiListenerService } from '../services/mercury-cti-listener.service';
import { isOneCtiReconnect } from 'projects/entities/src/lib/domain/one-cti/one-cti.selectors';
import { VoiceHelper } from 'projects/entities/src/lib/utils/voice-helper';
import { CtiChatDialogWsRequestData } from '../models/cti-chat-dialog-ws-request-data';
import { CtiBeginChatRequest } from '../models/cti-begin-chat-request';


@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_OneCti {

  constructor(
    protected ngEntityStore: Store<AppState>,
    protected actions: Actions,
    protected ctiService: MercuryCtiChatService,
    protected loggingFactory: LoggingFactoryService,
    protected timeService: DayTimeService,
    protected config: AppConfigService,
    protected ctiListenerService: MercuryCtiListenerService,
  ) {}

  logEvent_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        OneCtiActions.ChatDialogWsRequest,
        OneCtiActions.ChatDialogAppUpdateResponse,
        OneCtiActions.ChatDialogSecureCollectionInitiated,
        OneCtiActions.ChatDialogSecureCollectionStatus,
        OneCtiActions.ChatDialogSecureCollectionStartResponse,
        OneCtiActions.ChatDialogSecureCollectionCancelResponse,
        OneCtiActions.ChatDialogStartResponse,
        OneCtiActions.ChatDialogResumeResponse,
        OneCtiActions.ChatDialogStopResponse
      ),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, , pageName]) => ChatHelper.isChatPage(pageName)),
      tap(([{data, message}, chat]) => {
        LogHelper.logCtiMessageChatEvent(this.loggingFactory, chat ?? { chatId: data.sessionId } as Chat, message, 'Inbound');
      })
    ),
    { dispatch: false }
  );

  chatDialogWsRequest_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogWsRequest),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, chat, pageName]) => Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}, chat]) => {
        if (data.datastring){
          const suggestion: ExternalSuggestion = {
            body: {
              message: data.datastring,
              buttons: null,
              image: null
            },
            title: '',
            heading: null,
            intent: Guid.raw(),
            isLatest: true,
            queryId: data.requestId,
            json: null,
            messageId: null,
            visible: true,
            timesSent: 0,
            suggestionType: SuggestionType.External
          };

          this.ctiService.sendCtiChatMessage(chat, new CtiChatResponse(data.requestId, data.sessionId, data.datastring));
          this.ngEntityStore.dispatch(ChatActions.NewExternalSuggestion({ chatId: chat.chatId, suggestion }));
        }
      })
    ),
    { dispatch: false }
  );

  chatDialogWsRequest_Voice$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogWsRequest),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromApp.getPageName),
        this.ngEntityStore.select(fromCall.getCalls)
      ]),
      filter(([, pageName]) => VoiceHelper.isVoicePage(pageName)),
      tap(([{data}, , calls]) => {
        this.ctiService.sendCtiCallMessage(new CtiChatDialogWsResponse({...data, status: ChatDialogWSOptions.Status.Success }));
        const callId = this.getUid(data); // TODO: look up call in state by UCID instead (OneCti docs do not include UCID on this event. but it may have been added)?
        const call = calls.find(c => c.callId === callId);

        switch (data.action) {
          case ChatDialogWSOptions.Actions.WrapCall:
            this.ngEntityStore.dispatch(CallActions.getCallSummary({callId, display: CallSummaryDisplay.Panel, traceId: Guid.raw()}));
            LogHelper.logCallEvent(this.loggingFactory, CallOperations.WrapCall, call);
            return;
          case ChatDialogWSOptions.Actions.EndSession:
            this.ngEntityStore.dispatch(CallActions.callEnded({callId, isManual: false}));
            LogHelper.logCallEvent(this.loggingFactory, CallOperations.EndSession, call);
            return;
        }
      })
    ),
    { dispatch: false }
  );

  logReconnectOneCti$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.UpdateChatServiceConnection),
      concatLatestFrom(() => [
        this.ngEntityStore.select(isOneCtiReconnect)
      ]),
      filter(([, isCtiReconnect]) => isCtiReconnect),
      tap(([{isConnected}]) => {
        const logDimensions = {
          message: this.ctiService.disconnectReason,
          success: isConnected
        };

        LogHelper.logAgentEvents(this.loggingFactory, ChatOperations.InformationDeliveryReconnect, logDimensions);
        this.ngEntityStore.dispatch(OneCtiActions.UpdateReconnectOneCtiComplete());
      })
    ),
    { dispatch: false }
  );

  refreshChatStatesToOneCti$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.UpdateChatServiceConnection, OneCtiActions.RefreshChatsToOneCti),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromOneCti.isChatServiceConnected),
        this.ngEntityStore.select(fromChat.getChats)
      ]),
      filter(([, isConnected, chats]) => Boolean(isConnected && chats?.length)),
      tap(([, , chats]) => {
        chats.forEach(chat => {
          if (chat?.uui) {
            const colorGenericName = 'color' + chat.color.id;
  
            this.ctiService.sendCtiChatMessage(chat, new CtiBeginChatRequest(chat.uui, chat.chatId, chat.color.hexCode, colorGenericName, chat.customerGuid, true));
          }
        });
      })
    ),
    { dispatch: false }
  );

  connectOneCti$ = createEffect(() =>
  this.actions.pipe(
      ofType(AppActions.PageInitializationSuccess, OneCtiActions.ReconnectOneCti),
      tap(() => {
        const { oneCtiClientName, oneCtiChatRegisterUrl, oneCtiRegisterUrl } = this.config;
        this.ctiService.initWebSocketConnection(oneCtiChatRegisterUrl, oneCtiClientName, true);
        this.ctiListenerService.initWebSocketConnection(oneCtiRegisterUrl, oneCtiClientName);
      })
    ),
    { dispatch: false }
  );

  chatDialogSecureCollectionInitiated_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogSecureCollectionInitiated),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, chat, pageName]) => Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}, chat]) => {
        const { sessionId, collectionUrl, requestId } = data;
        const secureDataCollection: SecureDataCollectionInfo = {
          chatId: sessionId,
          collectionUrl,
          requestId
        };
        this.ctiService.sendCtiChatMessage(chat, new CtiSecureDataCollectionInitiatedResponse(sessionId, requestId));
        this.ngEntityStore.dispatch(ChatActions.InitiateSecureDataCollection({ requestData: secureDataCollection }));
      })
    ),
    { dispatch: false }
  );

  chatDialogSecureCollectionStatus_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogSecureCollectionStatus),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, chat, pageName]) => Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}]) => {
        const { sessionId, status } = data;
        this.ngEntityStore.dispatch(ChatActions.UpdateSecureDataCollectionState({ chatId: sessionId, requestState: DataCollectionState[status], timestamp: this.timeService.unix() }));
      })
    ),
    { dispatch: false }
  );

  chatDialogAppUpdate_Voice$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogAppUpdateResponse),
      concatLatestFrom(() => this.ngEntityStore.select(fromApp.getPageName)),
      filter(([, pageName]) => VoiceHelper.isVoicePage(pageName)),
      tap(([{data}]) => {
        if (data?.actionResult) {
          if (data.actionResult !== ChatDialogWSOptions.Status.Success) {
            this.ngEntityStore.dispatch(CallActions.callLaunchItgError({ requestId: data?.requestId }));
            LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.ChatDialogAppUpdateError);
          }
          else {
            this.ngEntityStore.dispatch(CallActions.updateRecommendedActionState({requestId: data?.requestId, actionState: RecommendedActionState.Success}));
          }
        }
      })
    ),
    { dispatch: false }
  );

  chatDialogSecureCollectionStartResponse_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogSecureCollectionStartResponse),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, chat, pageName]) => Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}]) => {
        if (data.success) {
          this.ngEntityStore.dispatch(ChatActions.UpdateSecureDataCollectionState({ chatId: data.sessionId, requestState: DataCollectionState.Requested, timestamp: this.timeService.unix() }));
        }
        else {
          LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.SecureDataRequestError, { errorType: data.errorMessage });
        }
      })
    ),
    { dispatch: false }
  );

  chatDialogSecureCollectionCancelResponse_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogSecureCollectionCancelResponse),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, chat, pageName]) => Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}]) => {
        if (data.success) {
          this.ngEntityStore.dispatch(ChatActions.UpdateSecureDataCollectionState({ chatId: data.sessionId, requestState: DataCollectionState.AgentCancelled, timestamp: this.timeService.unix() }));
        }
        else {
          LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.SecureDataRequestError, { errorType: data.errorMessage });
        }
      })
    ),
    { dispatch: false }
  );

  chatDialogStartResponse_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.ChatDialogStartResponse),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data.sessionId)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([, chat, pageName]) => Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}]) => {
        const { sessionId } = data;
        this.ngEntityStore.dispatch(ChatActions.oneCtiStartVerified({ chatId: sessionId }));
      })
    ),
    { dispatch: false }
  );

  beginCall_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.BeginCall),
      concatLatestFrom(({data}) => [
        this.ngEntityStore.select(fromChat.getChat(data?.ucid)),
        this.ngEntityStore.select(fromApp.getPageName)
      ]),
      filter(([{data}, chat, pageName]) => Boolean(data) && Boolean(chat) && ChatHelper.isChatPage(pageName)),
      tap(([{data}, chat]) => {
        this.updateAccount(data, chat);
        this.updateContext(data);

        const logDimensions = {
          message: 'NewChat',
          success: true
        };

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.InformationDeliveryBeginCallResponse, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  beginCall_Voice$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.BeginCall),
      concatLatestFrom(({ data }) => [
        this.ngEntityStore.select(fromApp.getPageName),
        this.ngEntityStore.select(fromCall.getCall(data?.uid))
      ]),
      filter(([{data}, pageName]) => Boolean(data) && VoiceHelper.isVoicePage(pageName)),
      tap(([{data}, , storedCall]) => {
        // Ignore start requests for calls that already exist in cases like refreshing the page
        if (storedCall) {
          return;
        }

        const customerInfo: CustomerInfo = {
          name: data.identity,
          accountNumber: data.account
        };

        const call = new Call();
        call.callId = data.uid;
        call.ucid = data.ucid;
        call.customerInfo = customerInfo;
        call.sessionId = data.sessionId;
        call.uui = data.uui;
        call.customerContext = data.customerContext;

        this.ngEntityStore.dispatch(CallActions.newCallReceived({ call, startTimestamp: this.timeService.unix() }));
      })
    ),
    { dispatch: false }
  );

  dataResume_Chat$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.DataResume),
      concatLatestFrom(() => this.ngEntityStore.select(fromApp.getPageName)),
      filter(([{data}, pageName]) => Boolean(data) && ChatHelper.isChatPage(pageName)),
      tap(([{data}]) => {
        const { ucid: chatId } = data;
        const lastViewedTimestamp = this.timeService.unix();
        this.ngEntityStore.dispatch(ChatUiActions.selectChat({ chatId, lastViewedTimestamp }));
      })
    ),
    { dispatch: false }
  );

  register$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.Register),
      filter(({data}) => Boolean(data)),
      tap(({data}) => {
        const { stationId: phoneExtension, agentId: avayaAgentId } = data;
        this.ngEntityStore.dispatch(AgentAuthActions.oneCtiRegistered({phoneExtension, avayaAgentId}));
      })
    ),
    { dispatch: false }
  );

  endCall_Voice$ = createEffect(() =>
    this.actions.pipe(
      ofType(OneCtiActions.EndCall),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromApp.getPageName),
        this.ngEntityStore.select(fromCall.getCalls)
      ]),
      filter(([{data}, pageName]) => Boolean(data) && VoiceHelper.isVoicePage(pageName)),
      tap(([{data}, , calls]) => {
        const call = calls.find(c => c.ucid === data.ucid);
        LogHelper.logCallEvent(this.loggingFactory, CallOperations.CallEnd, call);
      })
    ),
    { dispatch: false }
  );

  private updateAccount(eventData: CtiBeginCallRequestData, chat: Chat) {
    const customer = chat.users.find(u => u.type === SenderType.Requester) as Customer;
    if (!customer || customer.lastName !== 'Guest') { return; } // TODO this is gross and a refactoring needs to occur

    const updateAccountNumberArgs: UpdateAccountNumber = {
      manualConnect: false,
      displayName: eventData.identity ? eventData.identity : '',
      accountNumber: eventData.account ? eventData.account : '',
      authenticated: eventData.idauth === 'Fully Verified',
      chatId: eventData.ucid
    };

    LogHelper.logChatEvent(this.loggingFactory, ChatOperations.InformationDeliveryBeginCallResponse, chat);
    this.ngEntityStore.dispatch(ChatActions.UpdateAccountNumber(updateAccountNumberArgs));
    this.ngEntityStore.dispatch(ChatActions.ChatLoadComplete(eventData.ucid));
  }

  private updateContext(eventData: CtiBeginCallRequestData) {
    const loadContext: LoadContext = {
      chatId: eventData.ucid,
      contactReason: eventData.customerContext,
      uid: eventData.uid,
      uui: eventData.uui
    };
    this.ngEntityStore.dispatch(ChatActions.LoadContext(loadContext));
  }

  private getUid(data: CtiChatDialogWsRequestData): string {
    // UID is the in the 7th position of the UUI
    return data?.uui ? data.uui.split(',')[6] : null;
  }
}
