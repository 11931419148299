// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScriptType } from '../models/enums';
import { defaultCustomScriptGroup, defaultCustomScriptTree, defaultCustomScriptTreeId } from './custom-responses.constants';
import { Script, ScriptGroup, ScriptTree } from './models';
import { ManagedScript } from './script.model';
import * as fromScriptsSearch from './scripts-search-selectors';
import * as fromScripts from './scripts-selectors';
import * as fromScriptGroup from './script-group.reducer';
import * as fromSelectedChatScripts from './selected-chat-selectors';
import { createFilteredScriptGroups } from './scripts-selectors';
import { AppState } from '../state';

const selectScriptGroupState = createFeatureSelector<AppState, fromScriptGroup.State>(fromScriptGroup.scriptGroupsFeatureKey);

const selectCustomResponsesScriptTrees = createSelector(
    fromScripts.selectScriptTrees,
    (scriptTrees: ScriptTree[]) => scriptTrees.filter(scriptTree => scriptTree.type === ScriptType.Custom)
);

export const selectIsLoaded = createSelector(
    selectCustomResponsesScriptTrees,
    (scriptTrees: ScriptTree[]) => scriptTrees.length > 0
);

export const selectCustomResponsesScriptTree = createSelector(
    selectCustomResponsesScriptTrees,
    (scriptTrees: ScriptTree[]) => scriptTrees[0] || defaultCustomScriptTree
    // TODO: what if there is more than one custom script tree?
);

const selectCustomResponsesScriptTreeId = createSelector(
    selectCustomResponsesScriptTree,
    (scriptTree: ScriptTree) => scriptTree?.id
);

const selectCustomResponsesScriptTreeScriptGroupIds = createSelector(
    selectCustomResponsesScriptTree,
    (scriptTree: ScriptTree) => scriptTree?.scriptGroups || []
);

const selectCustomResponsesScriptTreeScriptGroups = createSelector(
    selectCustomResponsesScriptTreeScriptGroupIds,
    fromScripts.selectAllScriptGroups,
    (ids: string[], scriptGroups: ScriptGroup[]) =>
        scriptGroups?.filter(sg => ids.includes(sg.id))
);

const selectAllScriptGroupsInTree = createSelector(
    selectCustomResponsesScriptTreeId,
    fromScripts.selectAllScriptGroups,
    (scriptTreeId: string, groups: ScriptGroup[]) => {
        const customGroups = groups.filter(group => group.scriptTreeId === scriptTreeId);
        return customGroups.length > 0 ? customGroups : [defaultCustomScriptGroup];
    }
);

// gets the top level script group that holds the scripts that aren't in a header
export const selectCustomScriptGroupBase = createSelector(
    selectAllScriptGroupsInTree,
    (groups: ScriptGroup[]) => groups.find(group => group.id === defaultCustomScriptTreeId)
);

// gets the groups that are seen as headers for custom scripts
export const selectCustomScriptGroups = createSelector(
  selectAllScriptGroupsInTree,
  (groups: ScriptGroup[]) => groups.filter(group => group.id !== defaultCustomScriptTreeId)
);

const selectAllCustomScriptIds = createSelector(
    selectAllScriptGroupsInTree,
    (groups: ScriptGroup[]): string[] => {
        // get script ids across all groups
        const scriptIds: string[] = [].concat(...groups.map(g => g.scripts));
        return scriptIds;
    }
);

const selectAllCustomScripts = createSelector(
    selectAllCustomScriptIds,
    fromScripts.selectScriptEntities,
    (scriptIds: string[], scripts: Dictionary<Script>) => {
        // filter(Boolean) will remove any undefined elements from the result array
        return scriptIds ? scriptIds.map(id => scripts[id]).filter(Boolean) : [];
    }
);

const selectAllScriptGroupsInTreeInOrder = createSelector(
    selectCustomResponsesScriptTreeScriptGroups,
    fromScripts.selectScriptGroupEntities,
    (groupsInTree: ScriptGroup[], allGroups: Dictionary<ScriptGroup>) => {
        const result: ScriptGroup[] = [];
        groupsInTree.forEach(group => {
            result.push(...getScriptGroups(group, allGroups, 0));
        });
        return result;
    }
);

function getScriptGroups(group: ScriptGroup, allGroups: Dictionary<ScriptGroup>, depth: number): ScriptGroup[] {
    ++depth;
    if (depth > 2) {
        // script trees are max two levels deep
        return [];
    }

    const groups: ScriptGroup[] = [group];

    if ( !group?.scriptGroups ) {
        return groups;
    }

    group.scriptGroups.forEach(id => {
        groups.push(...getScriptGroups(allGroups[id], allGroups, depth));
    });
    
    return groups;
}

const selectAllCustomScriptIdsInOrder = createSelector(
    selectAllScriptGroupsInTreeInOrder,
    (groups: ScriptGroup[]): string[] => {
        // get script ids across all groups
        const scriptIds: string[] = [].concat(...groups.map(g => g.scripts));
        return scriptIds;
    }
);

const selectAllCustomScriptsInOrder = createSelector(
    selectAllCustomScriptIdsInOrder,
    fromScripts.selectScriptEntities,
    (scriptIds: string[], scripts: Dictionary<Script>) => {
        // filter(Boolean) will remove any undefined elements from the result array
        return scriptIds ? scriptIds.map(id => scripts[id]).filter(Boolean) : [];
    }
);

export const selectAllScriptsCustomFirst = createSelector(
  selectAllCustomScriptsInOrder,
  fromSelectedChatScripts.selectSystemScripts,
  fromSelectedChatScripts.showSystemScripts,
  (customScripts: Script[], systemScripts: Script[], showSystemScripts) => {
      return showSystemScripts ? [...customScripts, ...systemScripts] : [...customScripts];
  }
);

const selectManagedScriptGroup = createSelector(
  selectScriptGroupState,
  fromScriptGroup.getManagedScriptGroup
);

export const selectCurrentManagedScriptGroup = createSelector(
  selectManagedScriptGroup,
  (managedScriptGroup: ManagedScript) => managedScriptGroup?.status ? managedScriptGroup : null
);

export const selectScriptGroupToEdit = createSelector(
  selectManagedScriptGroup,
  fromScripts.selectScriptGroupEntities,
  (managedScript: ManagedScript, scriptGroups: Dictionary<ScriptGroup>) =>
    managedScript?.status === 'EDIT' ? scriptGroups[managedScript.id] : null
);

export const selectScriptGroupToDelete = createSelector(
  selectManagedScriptGroup,
  fromScripts.selectScriptGroupEntities,
  (managedScript: ManagedScript, scriptGroups: Dictionary<ScriptGroup>) =>
    managedScript?.status === 'DELETE' ? scriptGroups[managedScript.id] : null
);

export const selectFilteredManageScripts = createSelector(
    fromScripts.selectManageScriptQuery,
    selectAllCustomScripts,
    (query: string, scripts: Script[]) => fromScriptsSearch.filterScripts(query, scripts)
);

export const selectFilteredManageScriptsCount = createSelector(
    selectFilteredManageScripts,
    (scripts: Script[]) => scripts?.length || 0
);

export const selectFilteredCustomScriptGroups = createSelector(
    selectCustomResponsesScriptTreeScriptGroups,
    fromScriptsSearch.selectFilteredScripts,
    fromScriptsSearch.selectScriptGroupsContainingFilteredScripts,
    (scriptGroups: ScriptGroup[], scripts: Script[], groupsWithScripts: ScriptGroup[]) =>
        createFilteredScriptGroups({ scriptGroups, scripts, groupsWithScripts })
);
