// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { fromCall } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { CallOperations, LogActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs/internal/Observable';
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'merc-voice-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VoiceFooterComponent implements OnInit {
  callId$: Observable<string>;
  buildVersion: string;

  copyTooltipText: string = '';

  constructor(
    private store: Store<AppState>,
    private clipboardService: ClipboardService,
    private appConfigService: AppConfigService) { }

  ngOnInit() {
    this.callId$ = this.store.select(fromCall.getCurrentCallId);
    this.buildVersion = this.appConfigService.releaseLabel;
  }

  copyToClipboard(callId: string){
    const logPayload = {
      logType: LogType.call,
      operationName: CallOperations.CallIDCopied
    };
    this.store.dispatch(LogActions.logCurrentCallAction({ logPayload }));
    this.clipboardService.copyTo(callId);

    this.copyTooltipText = 'Copied!';
  }

  resetCopyText(){
    this.copyTooltipText = '';
  }
}
