// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AvailabilityType } from './enums';

export class AvailabilityChange {
    available: AvailabilityType;
    reason: string;
    timestamp: number;
}