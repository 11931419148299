// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationSources } from 'projects/entities/src/lib/constants/constants';
import { fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AgentActions, AppState, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-rate-connection-followup-modal',
  templateUrl: './rate-connection-followup-modal.component.html',
  styleUrls: ['./rate-connection-followup-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RateConnectionFollowUpModalComponent implements OnInit {
  @Input() chatId: string;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  showModal$: Observable<boolean>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.showModal$ = this.store.select(fromUi.getShowRateConnectionFollowUpModal);
  }

  onMySupportClick() {
    this.store.dispatch(AgentActions.NavigateToSupportPage({source: NavigationSources.feedbackModal}));
  }

  closeModal(): void {
    this.store.dispatch(UiActions.ToggleShowRateConnectionFollowUpModal({toggleShowRateConnectionFollowUpModal: false}));
    this.modalClose.emit(true);
  }
}
