<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-confirmation-modal
  *ngIf="(script$ | async)  as script"
  headerText="Delete Custom Response"
  [active]="script"
  (modalClose)="closeModal()"
  (confirmClicked)="deleteScript(script)"
  (cancelClicked)="closeModal()">
    <p>Are you sure you want to delete this custom response?</p>
    <p class="script-highlight-style" [innerHTML]="script?.value | safeHtml"></p>
</cee-confirmation-modal>
