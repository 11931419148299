// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { AuthService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { Actions, OnInitEffects, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AppSettings } from 'src/app/constants/constants';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AgentFacingMetricsHelper } from '../../utils/agent-facing-metrics-helper';
import { AgentAvailabilityActions, AgentFacingMetricsActions, ChatActions } from '../actions';
import { fromAgentAvailability, fromAgentFacingMetrics, fromChat } from '../selectors';
import { AppState } from '../state';
import { AgentFacingMetricsPersisterService } from './agent-facing-metrics-persister.service';

@Injectable()
// tslint:disable-next-line: class-name
export class AgentFacingMetricsEffects implements OnInitEffects {
  constructor(
    @Inject(AuthService) protected authService: AuthService,
    private ngEntityStore: Store<AppState>,
    private actions: Actions,
    private persisterService: AgentFacingMetricsPersisterService,
    private timeService: DayTimeService,
    protected config: AppConfigService,
  ) {
  }

  hydrate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentFacingMetricsActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? AgentFacingMetricsActions.hydrateSuccess({ state })
          : AgentFacingMetricsActions.hydrateFailure();
      })
    )
  );

  saveInLocalStorage$ = createEffect(
    () =>
      this.actions.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for Agent Facing Metrics state change
        ofType(AgentFacingMetricsActions.hydrateSuccess, AgentFacingMetricsActions.hydrateFailure),
        switchMap(() => this.ngEntityStore.pipe(select(fromAgentFacingMetrics.selectAgentFacingMetricsFeature))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(state => {
          this.persisterService.storeState(state);
        })
      ),
    { dispatch: false }
  );

  availabilityTimerInterval$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentAvailabilityActions.availabilityUpdated),
      //Executes every time the availability is updated and automatically if not called within the interval
      switchMap(action => timer(0, AppSettings.statusMetricTimerInterval).pipe(map(timerValue => ({...action, isAutomatic: !!timerValue})))),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromAgentFacingMetrics.getLastStatusMetricTimestamp),
        this.ngEntityStore.select(fromAgentAvailability.isAgentAvailable),
        this.ngEntityStore.select(fromAgentAvailability.wasPreviousStatusAvailable)
      ]),
      tap(async ([{isAutomatic}, lastStatusMetricTimestamp, isAvailable, wasAvailable]) => {
        const latestTimestamp = this.timeService.unix();
        const durationAdded = AgentFacingMetricsHelper.calculateTimestampDuration(lastStatusMetricTimestamp, latestTimestamp);
        // Pad 5 seconds for leeway in case of timer drift. If it's greater, the previous time can be discarded and ignored
        if (durationAdded > AppSettings.statusMetricTimerInterval + 5) {
          this.ngEntityStore.dispatch(AgentFacingMetricsActions.resetLastMetricStatusTimestamp({ timestamp: latestTimestamp }));
        }
        else {
          const available = isAutomatic ? isAvailable : wasAvailable;
          this.ngEntityStore.dispatch(AgentFacingMetricsActions.updateMetricStatusTimer({ durationAdded, available, latestTimestamp }));
        }
      })
    ),
    { dispatch: false }
  );

  updateMetricFirstResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.messageSent),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromAgentFacingMetrics.getMetricsFirstResponseTime(chatId)),
        this.ngEntityStore.select(fromChat.getAgentRespondedStatus(chatId)),
        this.ngEntityStore.select(fromChat.getStartTimestamp(chatId)),
      ]),
      filter(([{timestamp}, firstResponseTime, hasResponded, startTimestamp]) => Boolean(!firstResponseTime && hasResponded && startTimestamp && timestamp)),
      tap(async ([{chatId, timestamp}, , , startTimestamp]) => {
        const duration = AgentFacingMetricsHelper.calculateTimestampDuration(startTimestamp, timestamp);
        this.ngEntityStore.dispatch(AgentFacingMetricsActions.updateMetricResponseTime({ chatId, duration }));
      })
    ),
    { dispatch: false }
  );

  updateMetricHandleTime$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Transferred, ChatActions.Closed),
      tap((action) => {
        const {chatId, startTimestamp} = action.payload;
        if (chatId && startTimestamp) {
          const duration = AgentFacingMetricsHelper.calculateTimestampDuration(startTimestamp, this.timeService.unix());
          const isTransfer = action.type === ChatActions.Transferred.type;
          this.ngEntityStore.dispatch(AgentFacingMetricsActions.updatePostChatMetrics({ chatId, duration, isTransfer }));
        }
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return AgentFacingMetricsActions.hydrate();
  }
}

