// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../state';
import * as ScriptActions from './script.actions';
import { ScriptGroupPersisterService } from './script-group-persister.service';
import { ScriptPersisterService } from './script-persister.service';
import { ScriptTreePersisterService } from './script-tree-persister.service';
import { fromScripts } from './selectors';
/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/

@Injectable()
export class ScriptEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private scriptPersisterService: ScriptPersisterService,
    private scriptGroupPersisterService: ScriptGroupPersisterService,
    private scriptTreePersisterService: ScriptTreePersisterService
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(ScriptActions.hydrate),
      map(() => {
        const scriptState = this.scriptPersisterService.getState();
        const scriptGroupState = this.scriptGroupPersisterService.getState();
        const scriptTreeState = this.scriptTreePersisterService.getState();
        return scriptState || scriptGroupState || scriptTreeState
          ? ScriptActions.hydrateSuccess({ scriptState, scriptGroupState, scriptTreeState })
          : ScriptActions.hydrateFailure();
      })
    )
  );

  saveScriptStateInSessionStorage$ = createEffect(
    () =>
      this.action.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for Scripts state change
        ofType(ScriptActions.hydrateSuccess, ScriptActions.hydrateFailure),
        switchMap(() => this.store.pipe(select(fromScripts.selectScriptState))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(scriptsState => {
          this.scriptPersisterService.storeState(scriptsState);
        })
      ),
    { dispatch: false }
  );

  saveScriptGroupStateInSessionStorage$ = createEffect(
    () =>
      this.action.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for ScriptGroup state change
        ofType(ScriptActions.hydrateSuccess, ScriptActions.hydrateFailure),
        switchMap(() => this.store.pipe(select(fromScripts.selectScriptGroupState))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(scriptGroupState => {
          this.scriptGroupPersisterService.storeState(scriptGroupState);
        })
      ),
    { dispatch: false }
  );

  saveScriptTreeStateInSessionStorage$ = createEffect(
    () =>
      this.action.pipe(
        // wait for hydrateSuccess or hydrateFailure then switch to listen for ScriptTree state change
        ofType(ScriptActions.hydrateSuccess, ScriptActions.hydrateFailure),
        switchMap(() => this.store.pipe(select(fromScripts.selectScriptTreeState))),
        // TODO: maybe throttle/debounce/auditTime ????
        tap(scriptTreeState => {
          this.scriptTreePersisterService.storeState(scriptTreeState);
        })
      ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return ScriptActions.hydrate();
  }
}
