<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div #transcriptContainer>
    <cee-accordion #transcriptAccordion
        [titleText]="title"
        [dateTime]="this.transcript.createDate | formatDateShortMonth: selectedActiveChat.timeZone"
        [channels]= "transcript.channel | formatChannels"
        [active]="isActive"
        [disabled]="isAsync"
        (accordionChange)="accordionChange()"
        type="conversation">
        <div *ngFor="let t of (transcript.transcripts | sortAsyncEngagement); let last = last; trackBy: trackByTranscript">
          <ng-container *ngIf="transcript.transcripts.length > 1; else syncConversation">
            <merc-convo-async-engagement
                  [engagement]="t"
                  [selectedActiveChat]="selectedActiveChat"
                  [isResolved]="last"
                  >
              </merc-convo-async-engagement>
          </ng-container>
          <ng-template #syncConversation>
            <div *ngFor="let interaction of t.interactions; let i = index; trackBy: trackByInteraction">
              <merc-convo-message
                  [chatMessage]="interaction"
                  [chatId]="selectedActiveChat.chatId"
                  [channelType]="selectedActiveChat.channel?.type"
                  [colorId]="selectedActiveChat.color.id"
                  [timeZone]="selectedActiveChat.timeZone"
                  [previousMessage]="t.interactions[i - 1]"
                  [participants]="t.participants"
                  [customerDetails]="selectedActiveChat.customerDetails">
              </merc-convo-message>
            </div>
          </ng-template>
        </div>
    </cee-accordion>
</div>
