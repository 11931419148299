// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomeHeaderErrorState } from 'projects/entities/src/lib/domain/models/voice/enums';
import { fromVoiceUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState } from 'projects/entities/src/lib/domain/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-voice-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceHeaderComponent implements OnInit {
  errorState$: Observable<HomeHeaderErrorState>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.errorState$ = this.store.select(fromVoiceUi.getHeaderErrorState);
  }

  getErrorMessage(errorState: HomeHeaderErrorState): string{
    switch (errorState){
      case HomeHeaderErrorState.Temporary:
        return 'Attempting to reconnect';
      case HomeHeaderErrorState.Critical:
        return 'Transcript experiencing issues';
      case HomeHeaderErrorState.MultipleCritical:
        return 'Multiple systems experiencing issues';
      default:
        return '';
    }
  }

  getErrorIcon(errorState: HomeHeaderErrorState): string{
    switch (errorState){
      case HomeHeaderErrorState.Temporary:
        return 'wifi-alert-fill';
      case HomeHeaderErrorState.Critical:
      case HomeHeaderErrorState.MultipleCritical:
        return 'alert-fill';
      default:
        return '';
    }
  }
}
