<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<merc-page-initialization [pageName]="pageName">
  <div [class.cee-theme-dark]="themeDark$ | async" id="merc-page-container">
    <div class="cee-page">
      <cee-left-navigation
        [activeLinkId]="activeLink$ | async"
        [topButtons]="topButtons$ | async"
        [bottomButtonsAboveLine]="bottomButtonsAboveLine$ | async"
        [bottomButtons]="bottomButtons$ | async"
        (buttonClicked)="onNavButtonClick($event)"
        (notificationCloseClicked)="onNotificationClicked($event, true)"
        (notificationClicked)="onNotificationClicked($event, false)"
      >
      </cee-left-navigation>
    <merc-agent-summary *ngIf="agentSummaryOpen$ | async"></merc-agent-summary>
    <merc-survey-modal [active]="!(surveyPromptActions$ | async).ignored" *ngIf="(npsModalSurveyUrl$ | async) && !(surveyPromptActions$ | async).dismissed && !(hasActiveChats$ | async)" [surveyUrl]="npsModalSurveyUrl$ | async"></merc-survey-modal>
    <merc-agent-settings-base
        [hasActiveChats]="hasActiveChats$ | async"
        [surveyUrl]="npsSurveyUrl$ | async"
      ></merc-agent-settings-base>
      <merc-previous-summary-card></merc-previous-summary-card>
      <div class="cee-page-content" [class.has-banner]="hasBanner$ | async" [class.has-env-banner]="hasEnvBanner">
        <div class="cee-header">
          <merc-header-base></merc-header-base>
        </div>
        <div class="cee-sidebar" *ngIf="{
            showRateConnectionQuality: (showRateConnectionQuality$ | async),
            showRateConnectionQualityModals: (showRateConnectionQualityModals$ | async),
            lastClosedChatId: (lastClosedChatId$ | async),
            showDismissSuggestionFeedbackModal: (showDismissSuggestionFeedbackModal$ | async),
            lastDismissedSuggestionChatId: (lastDismissedSuggestionChatId$ | async)
          } as sidebarData">
          <merc-convo-list-base></merc-convo-list-base>
          <merc-rate-connection-modal *ngIf="sidebarData.showRateConnectionQuality" [chatId]="sidebarData.lastClosedChatId"></merc-rate-connection-modal>
          <merc-rate-connection-followup-modal *ngIf="sidebarData.showRateConnectionQualityModals" [chatId]="sidebarData.lastClosedChatId"></merc-rate-connection-followup-modal>
          <merc-dismiss-suggestion-feedback
            *ngIf="
              sidebarData.showDismissSuggestionFeedbackModal
              && sidebarData.lastDismissedSuggestionChatId
              && !sidebarData.showRateConnectionQuality
              && !sidebarData.showRateConnectionQualityModals">
          </merc-dismiss-suggestion-feedback>
        </div>
        <div class="cee-chatheader">
          <merc-convo-header-base></merc-convo-header-base>
        </div>
        <div class="cee-chatmessage">
          <merc-convo-window-base></merc-convo-window-base>
        </div>
        <div [ngClass]="'cee-chatutil ' + ((hasDispositions$ | async) ? 'cee-chatutil--has-dispositions' : '')">
          <merc-edit-placeholder *ngIf="isEditPlaceholderPanelOpen$ | async"></merc-edit-placeholder>
           <merc-convo-details-base *ngIf="!(isCxGptFlagEnabled$ | async)"></merc-convo-details-base>
          <merc-cxgpt-details *ngIf="isCxGptFlagEnabled$| async"></merc-cxgpt-details> 
          <merc-dispositions-container *ngIf="!(isChatAsync$ | async)" [selectedDisposition]="selectedDisposition$ | async" [chatId]="selectedChatId$ | async"></merc-dispositions-container>
        </div>
        <merc-close-convo *ngIf="isCloseChatModalActive$ | async" [chatId]="selectedChatId$ | async"></merc-close-convo>
        <merc-end-session *ngIf="isConversationSummaryModalActive$ | async" [chatId]="selectedChatId$ | async"></merc-end-session>
        <cee-modal
          *ngIf="showConnectionLostModal$ | async"
          x-test-target="connection-lost-modal"
          headerText="It looks like you lost your internet connection"
          active="true"
          [clickOutsideToClose]="false"
          [hideCloseButton]="true"
        >
          <p>Hang tight while we try to reconnect you.</p>
          <cee-loading-indicator
            [hideAnimation]="hideAnimations$ | async"
          ></cee-loading-indicator>
        </cee-modal>
      </div>
    </div>
    <merc-convo-image-modal></merc-convo-image-modal>
    <merc-itg-modal *ngIf="itgModalOpen$ | async" [chatId]="selectedChatId$ | async"></merc-itg-modal>
    <merc-lockout-warning-modal *ngIf="isSessionLockoutWarningModalActive$ | async" [timer]="sessionTimer" (modalClose)="onLockoutClosed()"></merc-lockout-warning-modal>
    <merc-connect-account-modal *ngIf="isConnectAccountModalActive$ | async"></merc-connect-account-modal>
    <merc-transfer-chat-modal *ngIf="isTransferModalActive$ | async" [chatId]="selectedChatId$ | async"></merc-transfer-chat-modal>
    <merc-convo-header-confirm-close-transfer-modal *ngIf="isCloseTransferModalActive$ | async" ></merc-convo-header-confirm-close-transfer-modal>
    <merc-previous-summary-modal *ngIf="showConversationSummary$ | async"></merc-previous-summary-modal>
  </div>
</merc-page-initialization>
