// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable } from '@angular/core';
import { LoggingFactoryService, ResponseHandler } from '@cxt-cee-chat/merc-ng-core';
import { VerifySession } from '../domain/models/authentication/verifySession';
import { HttpHandler, HttpResponse } from '@angular/common/http';
import { NUANCE_BEARER_TOKEN_HANDLER } from './nuance-bearer-token-http-handler';
import { LoginResponse } from '../domain/models/authentication/logInResponse';
import { VerifySessionResponse } from '../domain/models/authentication/verifySessionResponse';
import { LogIn } from '../domain/models/authentication/logIn';
import { ReLogIn } from '../domain/models/authentication/reLogIn';
import { NuanceCredentialRestApiService } from './nuance-credential/nuance-credential-rest-api-service';
import { NuanceCredentialRequestService } from './nuance-credential/nuance-credential-request-service';
import { AgentAuthActions } from '../domain/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../domain/state';
import { LoggedOutMethod } from '../domain/models/enums';

@Injectable({
  providedIn: 'root'
})
export class FixAgentAuthApiService extends NuanceCredentialRestApiService {
  private _unauthorizedLogOutHandler: ResponseHandler;
  constructor
  (
    protected nuanceCredentialRequestService: NuanceCredentialRequestService,
    @Inject(NUANCE_BEARER_TOKEN_HANDLER) httpHandler: HttpHandler,
    loggingService: LoggingFactoryService,
    protected ngEntityStore: Store<AppState>
  )
  {
    super(nuanceCredentialRequestService, loggingService, httpHandler);
    this._unauthorizedLogOutHandler = new ResponseHandler();
    this._unauthorizedLogOutHandler.handle401 = (_opName, _data) => {
      this.ngEntityStore.dispatch(AgentAuthActions.loggedOut(LoggedOutMethod.UnauthorizedCall));
    };
  }

  logIn(model: LogIn): Promise<HttpResponse<LoginResponse>> {
    return this.post<LoginResponse>('auth/login', model, this._unauthorizedLogOutHandler);
  }

  reLogIn(model: ReLogIn): Promise<HttpResponse<LoginResponse>> {
    return this.post<LoginResponse>('auth/relogin', model, this._unauthorizedLogOutHandler);
  }

  logOut(): Promise<HttpResponse<void>> {
    return this.postWithCredentials<void>('auth/logout', {}, this._unauthorizedLogOutHandler);
  }

  verifySession(model: VerifySession, responseHandler?: ResponseHandler): Promise<HttpResponse<VerifySessionResponse>> {
    return this.post<VerifySessionResponse>('auth/verifysession', model, responseHandler);
  }

  lockout(): Promise<HttpResponse<void>> {
    return this.postWithCredentials<void>('auth/lockout', {});
  }
}
