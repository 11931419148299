// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, ChatActions, ChatOperations, LogActions, FeatureFlags, UiActions } from 'projects/entities/src/public_api';
import { ButtonControl, ButtonType } from '@cxt-cee-chat/merc-pattern-lib';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { TransferChat } from 'projects/entities/src/lib/domain/models/requests/transferChat';
import { BusinessUnitTransferOption } from 'projects/entities/src/lib/domain/models/businessUnitTransferOption';
import { SelectOption } from 'src/app/models/select-option';
import { BusinessUnitSelectOption } from 'src/app/models/business-unit-select-option';
import { fromChat, fromSettings, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AgentGroupSelectOption } from 'src/app/models/agent-group-select-option';
import { SearchResult } from '@cxt-cee-chat/merc-pattern-lib/components/forms/cee-typeahead-search/search-results';
import { ChatSummaryData } from 'projects/entities/src/lib/domain/models/chatSummaryData';
import { timer } from 'rxjs';
import { ClipboardService } from '@cxt-cee-chat/merc-ng-core';
import { LogInfo, LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { Guid } from 'guid-typescript';

const transferButtonText = 'Transfer';
const closeButtonText = 'Cancel';
const selectAgentGroupDefault: SelectOption = {id: '', label: 'Select Agent Group'};

@Component({
  selector: 'merc-transfer-chat-modal',
  templateUrl: './transfer-chat-modal.component.html',
  styleUrls: ['./transfer-chat-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class TransferChatModalComponent extends SubscriberComponent implements OnInit {
  active: boolean = false;
  businessUnits: BusinessUnitSelectOption[];
  businessUnitId: string = '';
  selectedBUValue: string = '';
  agentGroups: AgentGroupSelectOption[];
  transferNotes: string = '';
  disableTransferFields: boolean = false;
  queueId: string = '';
  agentId: string = '';
  
  hideAnimations$: Observable<boolean>;
  transferFailed$: Observable<boolean>;
  controls$: Observable<ButtonControl[]>;
  businessUnits$: Observable<BusinessUnitSelectOption[]>;
  queues$: BehaviorSubject<AgentGroupSelectOption[]>;
  availableAgents$: BehaviorSubject<SearchResult[]>;
  isRefreshingBusinessUnits$: Observable<boolean>;
  isTransferToAgentFlagEnabled$: Observable<boolean>;
  isChatConversationSummaryFlagEnabled$: Observable<boolean>;
  isHideSummaryFlagEnabled$: Observable<boolean>;
  chatSummaryPayload$: Observable<string>;
  copySummaryTooltipText: string = '';

  private selectedActiveChatId: string;
  private selectedQueueValue: string = '';
  private hasSelectedQueueValue$: BehaviorSubject<boolean>;
  private selectedAgentId: string = '';

  chatSummary$: Observable<ChatSummaryData>;
  showDelayMessage: boolean = false;
  timeoutMessageDuration: number = 10000;

  @Input() chatId: string;

  constructor(
    private ngEntityStore: Store<AppState>,
    private changeDetection: ChangeDetectorRef,
    private clipboardService: ClipboardService
   
  ) {
    super();
  }

  ngOnInit() {
    this.hideAnimations$ = this.ngEntityStore.select(fromSettings.hideAnimations);
    this.isRefreshingBusinessUnits$ = this.ngEntityStore.select(fromUi.getIsLoadingTransferOptions);
    this.businessUnits$ = this.getTransferOptions();
    this.isTransferToAgentFlagEnabled$ = this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.TransferToAgent));
    this.isChatConversationSummaryFlagEnabled$ = this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.ChatConversationSummary));
    this.isHideSummaryFlagEnabled$ = this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.HideSummary));
    this.transferFailed$ = this.ngEntityStore.select(fromUi.transferChatModal.getTransferFailed);
    this.queues$ = new BehaviorSubject<SelectOption[]>(null);
    this.availableAgents$ = new BehaviorSubject<SearchResult[]>(null);
    
    this.hasSelectedQueueValue$ = new BehaviorSubject<boolean>(false);
    this.controls$ = new Observable<ButtonControl[]>((subscriber: Subscriber<ButtonControl[]>) => {
      this.hasSelectedQueueValue$
        .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe((selected: boolean) => {
          subscriber.next([
            {
              text: transferButtonText,
              type: ButtonType.Primary,
              disabled: !selected,
              dataQa: 'transfer',
              controlFunction: () => this.transferChat()
            },
            {
              text: closeButtonText,
              type: ButtonType.Secondary,
              disabled: false,
              dataQa: 'cancel',
              controlFunction: () => this.closeModal()
            },
          ]);
        });
    });

    this.ngEntityStore.select(fromChat.getSelectedChatId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((chatId: string) => {
        this.selectedActiveChatId = chatId;
      });

    this.ngEntityStore.dispatch(UiActions.GetChatSummaryData({chatId: this.chatId, traceId: Guid.raw()}));
    const delayMessageTimerSub = timer(this.timeoutMessageDuration).subscribe(() => {
      this.showDelayMessage = true;
      this.changeDetection.detectChanges();
    });
    this.subscriptions.push(delayMessageTimerSub);
    this.chatSummary$ = this.ngEntityStore.select(fromChat.getChatSummaryData(this.chatId));
    this.chatSummaryPayload$ = this.ngEntityStore.select(fromChat.getSummaryPayload(this.chatId));
    this.resetForm();
  }

  closeModal(){
    this.ngEntityStore.dispatch(UiActions.TransferModalClosed());
  }

  public transferChat(): void {
    const transferChatModel: TransferChat = {
      chatId: this.selectedActiveChatId,
      targetQueueId: this.queueId,
      targetQueueName: this.selectedQueueValue,
      targetQueueBusinessUnitId: this.businessUnitId,
      targetQueueBusinessUnitName: this.selectedBUValue,
      targetAgentId: this.selectedAgentId,
      notes: this.transferNotes
    };
    this.ngEntityStore.dispatch(ChatActions.Transfer(transferChatModel));
  }

  public selectedBusinessUnit(selectedBU, transferFailed: boolean): void {
    if (selectedBU?.target?.selectedOptions && selectedBU.target.selectedOptions[0].value !== '') {
      this.selectedBUValue = selectedBU.target.selectedOptions[0].text;
      this.businessUnitId = selectedBU.target.value;
      this.businessUnits.forEach(elm => {
        if (this.selectedBUValue === elm.label) {
          this.queueId = '';
          this.agentId = '';
          this.agentGroups = [selectAgentGroupDefault];
          this.agentGroups = this.agentGroups.concat(elm.queues);
          this.queues$.next(this.agentGroups);
        }
      });
      if (transferFailed) {
        this.ngEntityStore.dispatch(UiActions.ClearTransferFailed());
      }
    }
    else {
      this.setSelectedQueueValue('');
      this.selectedBUValue = '';
      this.queues$.next([selectAgentGroupDefault]);
      this.resetAvailableAgents();
    }
  }

  public selectedQueue(selectedQueue): void {
    if (selectedQueue?.target?.selectedOptions && selectedQueue.target.selectedOptions[0].value !== '') {
      this.setSelectedQueueValue(selectedQueue.target.selectedOptions[0].text);
      this.queueId = selectedQueue.target.value;
      this.agentGroups?.forEach(elm => {
        if (this.selectedQueueValue === elm.label) {
          this.agentId = '';
          let agents = [];
          agents = agents.concat(elm.availableAgents);
          this.availableAgents$.next(agents);
        }
      });   
    } else {
      this.setSelectedQueueValue('');
      this.resetAvailableAgents();
    }
  }

  onTextFieldChange(fieldType: string, text: string) {
    switch (fieldType) {
      case 'notes':
        this.transferNotes = text;
        break;
    }
  }

  public selectedAgent(selectedAgent): void {
    if (selectedAgent?.id) {
      this.selectedAgentId = selectedAgent.id;
    } else {
      this.selectedAgentId = '';
    }
  }
 
  public refreshBusinessUnits(): void {
    this.ngEntityStore.dispatch(UiActions.RefreshTransferOptions());
  }

  public clearSearch() {
  this.resetSelections();
  }

  public isDropdownFieldDisabled(options): boolean {
    // <= 1 because cee-select should always have at least one default option
    return !options || options.length <= 1;
  }

  private resetSelections(): void {
    this.resetBusinessUnits();
    this.resetAgentGroups();
    this.resetAvailableAgents(); 
  }
  
  private resetBusinessUnits(): void {
    this.businessUnitId = '';
    this.selectedBUValue = '';
  }

  private resetAgentGroups(): void {
    this.queues$.next([selectAgentGroupDefault]);
    this.queueId = '';
    this.setSelectedQueueValue('');
  }

  private resetAvailableAgents(): void {
    this.availableAgents$.next(null);
    this.selectedAgentId = '';
  }

  private resetForm(): void{
    this.active = true;
    this.resetSelections();
    this.transferNotes = '';
    this.changeDetection.detectChanges();
  }

  private setSelectedQueueValue(queueValue: string) {
    this.selectedQueueValue = queueValue;
    this.hasSelectedQueueValue$.next(queueValue !== '');
  }

  private getTransferOptions(): Observable<BusinessUnitSelectOption[]> {
    return this.ngEntityStore.pipe(
      select(fromChat.getTransferOptions),
      map((transferOptions) => this.transferOption(transferOptions))
    );
  }

  private transferOption(transferOptions: BusinessUnitTransferOption[]): BusinessUnitSelectOption[] {
    if (transferOptions) {
      if (!transferOptions.length) {
        this.disableTransferFields = true;
        this.businessUnits = [{id: '', label: 'Select a business unit'}];
      }
      else {
        this.businessUnits = [{id: '', label: 'Select a business unit'}];
        this.businessUnits = this.businessUnits.concat(transferOptions.map(opt => {
          return this.translateBusinessUnitToSelectOption(opt);
        }));
        this.disableTransferFields = false;
      }
    }
    this.resetSelections();
    return this.businessUnits;
  }

  private translateBusinessUnitToSelectOption(businessUnit: BusinessUnitTransferOption): BusinessUnitSelectOption{
    const selectOption: BusinessUnitSelectOption = {
      id: businessUnit.id,
      label: businessUnit.displayText || businessUnit.id,
      queues: businessUnit.queues.map(queue => {
        return {
          label: queue.displayText,
          id: queue.queueId,
          availableAgents: queue.availableAgents
            ? queue.availableAgents.map(agent => {
              return {
                name: `${agent.firstName} ${agent.lastName}`,
                id: agent.agentId
              };
            })
            : []
        };
      })
    };
    return selectOption;
  }

  copyToClipboard() {
    this.clipboardService.copyTo(`Notes: ${this.transferNotes}`);
    this.copySummaryTooltipText = 'Copied!';
    const logPayload: LogInfo = {
      logType: LogType.chat,
      operationName: ChatOperations.NotesCopied,
      data: {
        source: 'transfer'
      }
    };
    this.ngEntityStore.dispatch(LogActions.logSelectedChatAction({ logPayload }));
  }

  resetCopyText() {
    this.copySummaryTooltipText = '';
  }

  positiveRating() {
    this.ngEntityStore.dispatch(ChatActions.rateSessionSummary({ isPositiveRating: true, chatId: this.chatId, useNotes: true }));
  }

  negativeRating() {
    this.ngEntityStore.dispatch(ChatActions.rateSessionSummary({ isPositiveRating: false, chatId: this.chatId, useNotes: true }));
  }
}
