// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class PlaceholderConstants{
  public static readonly Keys = {
    AgentName: '__person_agent__'
  };
  public static readonly Labels = {
    AgentName: 'Agent Name'
  };
}
