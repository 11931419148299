// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { ScriptGroup } from './models';

export const createScriptGroup = createAction(
  '[ScriptGroup] Create Script Group',
  props<{ method: string }>()
);


export const addScriptGroup = createAction(
  '[ScriptGroup] Add Script Group',
  props<{ scriptGroup: ScriptGroup, parentScriptGroupId: string }>()
);

export const updateScriptGroupName = createAction(
  '[ScriptGroup] Update Script Group Name',
  props<{ id: string, name: string }>()
);

export const editScriptGroup = createAction(
  '[ScriptGroup] Edit Script Group Name',
  props<{ id: string }>()
);

export const deleteScriptGroup = createAction(
  '[ScriptGroup] Delete Script Group',
  props<{ id: string }>()
);

export const deleteScriptGroupConfirmation = createAction(
  '[Script] Delete Script Group Confirmation',
  props<{ id: string }>()
);

export const moveScriptGroup = createAction(
  '[ScriptGroup] Move Script Group',
  props<{ parentScriptGroupId: string, scriptGroupIds: string[]}>()
);
