// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ChatMessageTranslationData {
    translatedMessage?: string;
    isLoading?: boolean;
    hasError?: boolean;
    errorMessage?: string;
}
