// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { WebSocketConstants } from './websocket.constants';
import { IAbstractWebSocketFactory } from './abstract-websocket.factory';
import { WebSocketErrorHandler } from './error/handler/websocket-error.handler';
import * as Rx from 'rxjs';

export class WebSocketFactory implements IAbstractWebSocketFactory {
	private isOpenCtiConnection = false;
	private webSocketErrorHandler: WebSocketErrorHandler = null;
	public sendMessage: any = null;
	public openCtiConnection$: Rx.BehaviorSubject<
		boolean
	> = new Rx.BehaviorSubject<boolean>(false);

	/**
	 * This is the place where all the WebSocket — RxJS patching takes place.
	 * To create an RxJS ReplaySubject we need to provide observable.
	 * RxJS ReplaySubject is the entity that will receive data from the socket and push it further to the subscribers.
	 */
	public create(
		wssUrl: string,
		protocols?: string | Array<string>
	): Rx.ReplaySubject<MessageEvent> {
		this.webSocketErrorHandler = new WebSocketErrorHandler();
		const webSocketClient: WebSocket = this.getWebSocketClient(
			wssUrl,
			protocols
		);
		// Custom Observable to override success, error and complete callback to provide
		// onmessage, onerror and onclose
		const observable = Rx.Observable.create(
			(obs: Rx.Observer<MessageEvent>) => {
				webSocketClient.onmessage = obs.next.bind(obs);
				webSocketClient.onerror = obs.error.bind(obs);
				webSocketClient.onclose = obs.complete.bind(obs);

				return webSocketClient.close.bind(webSocketClient);
			}
		);

		/**
		 * This function will take care of sending information back to the WebSocket if needed in future.
		 */
		const observer = {
			next: (data: any) => {
				if (webSocketClient.readyState === WebSocket.OPEN) {
					// AppUtil.log(
					// 	"WebSocketFactory : next() : opened WebSocket connection with url ===> [%o]",
					// 	data
					// );
					webSocketClient.send(data);
				}
			}
		};
		this.sendMessage = observer;
		// WebSockets Event callbacks - controles comes here if not subscribed into component.
		webSocketClient.onopen = (openEvent: Event) => {
			this.setWebSocketConnectionOpen(true);
			this.webSocketOpenEventHandler(
				openEvent,
				wssUrl,
				this.isWebSocketConnectionOpen()
			);
		};

		webSocketClient.onmessage = (messageEvent: MessageEvent) => {
			this.webSocketMessageEventHandler(messageEvent);
		};

		webSocketClient.onerror = (errorEvent: Event) => {
			this.setWebSocketConnectionOpen(false);
			this.webSocketErrorHandler.handleWebSocketError(
				errorEvent,
				WebSocketConstants.CAUSED_BY_ON_ERROR_EVENT
			);
			this.webSocketErrorEventHandler(
				errorEvent,
				this.isWebSocketConnectionOpen()
			);
		};

		webSocketClient.onclose = (closeEvent: CloseEvent) => {
			this.setWebSocketConnectionOpen(false);
			this.webSocketCloseEventHandler(
				closeEvent,
				this.isWebSocketConnectionOpen()
			);
		};

		return Rx.ReplaySubject.create(observer, observable);
	}

	/**
	 */
	private isWebSocketConnectionOpen(): boolean {
		return this.isOpenCtiConnection;
	}

	private setWebSocketConnectionOpen(open: boolean): void {
		this.isOpenCtiConnection = open;
	}

	// WebSockets Event handlers - controles comes here if not overridden into child class.
	public webSocketOpenEventHandler(
		_openEvent: Event,
		_wssUrl: string,
		_isOpen: boolean
	) {
		// AppUtil.log(
		// 	"WebSocketFactory : webSocketOpenEventHandler() : Opened WebSocket connection at ===> %o & OpenEvent ===> %o & isOpen ===> %o",
		// 	wssUrl,
		// 	openEvent,
		// 	isOpen
		// );
	}

	public webSocketMessageEventHandler(_messageEvent: MessageEvent) {
		// AppUtil.log(
		// 	"WebSocketFactory : webSocketMessageEventHandler() : messageEvent ===> [%o]",
		// 	messageEvent
		// );
	}

	public webSocketErrorEventHandler(_errorEvent: Event, _isOpen: boolean) {
		// AppUtil.log(
		// 	"WebSocketFactory : webSocketErrorEventHandler() : errorEvent ===> [%o] & isOpen ===> [%o]",
		// 	errorEvent,
		// 	isOpen
		// );
	}

	public webSocketCloseEventHandler(_closeEvent: CloseEvent, _isOpen: boolean) {
		// AppUtil.log(
		// 	"WebSocketFactory : webSocketCloseEventHandler() : closeEvent ===> [%o] & isOpen ===> [%o]",
		// 	closeEvent,
		// 	isOpen
		// );
	}

	/**
	 */
	private getWebSocketClient(
		wssUrl: string,
		protocols?: string | Array<string>
	): WebSocket {
		// AppUtil.log(
		// 	"WebSocketFactory : getWebSocketClient() : creating WebSocket connection with url ===> [%o]",
		// 	wssUrl
		// );
		try {
			return typeof protocols === 'undefined' ||
				protocols === null ||
				protocols === ''
				? new WebSocket(wssUrl)
				: new WebSocket(wssUrl, protocols);
		} catch (webSocketError) {
			// AppUtil.log(
			// 	"A websocket server has failed to correctly perform opening handshake"
			// );
			this.setWebSocketConnectionOpen(false);
			return this.webSocketErrorHandler.handleWebSocketError(
				webSocketError,
				WebSocketConstants.CAUSED_BY_OPEN_HANDSHAKE_EVENT
			);
		}
	}
}
