// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, EventEmitter, Output, forwardRef, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { CeeTabPanelItemComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { AgentCustomSettings } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-agent-settings-display-tab',
  templateUrl: './display-tab.component.html',
  styleUrls: ['./display-tab.component.scss'],
  providers: [
    {
      provide: CeeTabPanelItemComponent,
      useExisting: forwardRef(() => DisplayTabComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayTabComponent extends CeeTabPanelItemComponent implements OnInit {
  title = 'Display';
  showControls = true;
  @Input() settings: AgentCustomSettings;
  @Output() settingsModelChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
    super();
   }

  ngOnInit() {
  }
  onSettingsModelChange() {
    this.settingsModelChange.emit(this.settings);
  }

  onAnimationSettingsModelChange() {
    this.settings.animationsOff = !this.settings.animationsOff;
    this.onSettingsModelChange();
  }
  onCustomerActionsSettingsModelChange(){
    this.settings.hideCustomerActions = !this.settings.hideCustomerActions;
    this.onSettingsModelChange();
  }
  onChangeTheme(theme: string){
    const settings = {
      ...this.settings,
      theme
    };
    this.settingsModelChange.emit(settings);
  }
}
