// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConnectAccount from './connect-account.reducer';
import { AppState } from './state';

const selectConnectAccountFeature = createFeatureSelector<
  AppState,
  fromConnectAccount.State
>(fromConnectAccount.featureKey);

export const getIsModalOpen = createSelector(
  selectConnectAccountFeature,
  (state: fromConnectAccount.State) => state.isModalOpen
);

export const getAccountUsersResponse = createSelector(
  selectConnectAccountFeature,
  (state: fromConnectAccount.State) => state.accountUsersResponse
);

export const getUnlinkAccountResponse = createSelector(
  selectConnectAccountFeature,
  (state: fromConnectAccount.State) => state.unlinkResponse
);
