// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ArrayComparer {
  /**
   * if an array will compare all elements in a to b
   *
   * @param a
   * @param b
   * @returns true if a === b or if an array and all elements ===
   */
  static IsEqual(a: any, b: any): boolean {
    // default ngrx equality check
    if (a === b) {
      return true;
    }

    if (!Array.isArray(a) || !Array.isArray(b)) {
      return false; // not strictly equal and at least one is not an array
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }
}
