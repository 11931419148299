// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AppSettings } from 'src/app/constants/constants';
import { ChatMetricsData } from '../domain/models/agent-metrics-data';

export class AgentFacingMetricsHelper {
  static calculateTimestampDuration(prevTimestamp: number, currentTimestamp: number) {
    return currentTimestamp - prevTimestamp;
  }

  static isMetricTimestampGreaterThanThreshold(previousTimestamp: number, currentTimestamp: number): boolean {
    return this.calculateTimestampDuration(previousTimestamp, currentTimestamp) > AppSettings.statusMetricTimerInterval;
  }

  static getMetricsAvgTime(chatMetrics: Record<string, ChatMetricsData>, isResponseTime: boolean): number {
    if (!chatMetrics) { return 0; }
    
    const metricsArray = Object.values(chatMetrics);
    if (!metricsArray?.length) { return 0; }

    const validMetrics = metricsArray.filter(data => 
      isResponseTime
        ? data.firstResponseTime > 0
        : data.engagementHandleTime > 0);
    if (!validMetrics.length) { return 0; }

    const sum = validMetrics.reduce((total, metric) => 
      isResponseTime
        ? total + metric.firstResponseTime
        : total + metric.engagementHandleTime
      , 0);

    return sum / validMetrics.length;
  }
}