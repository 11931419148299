// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable, Inject } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { IDependencyTelemetry } from '@microsoft/applicationinsights-common';
import { ErrorEvent } from '../../domain/models/events/error-event';
import { DependencyFailureValidator } from './dependency-failure-validator';

@Injectable({
  providedIn: 'root'
})
export class TelemetryItemTranslator {
  constructor(
    @Inject(DependencyFailureValidator)
    private validator: DependencyFailureValidator
  ) {}

  public tryCreateErrorEvent(
    telemetryItem: ITelemetryItem
  ): [boolean, ErrorEvent] {
    if (!this.validator.isValid(telemetryItem)) {
      return [false, null];
    }
    const remoteData = telemetryItem.baseData as IDependencyTelemetry;
    const event = this.createErrorEvent(remoteData);

    return [Boolean(event), event];
  }

  private createErrorEvent(remoteData: IDependencyTelemetry): ErrorEvent {
    const { name: operationName, properties, responseCode } = remoteData;
    const data = {
      responseCode,
      message: properties ? properties['responseText'] : null
    };
    const logDimensions = {
      operationName,
      data
    };

    return new ErrorEvent(logDimensions);
  }
}
