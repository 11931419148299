// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { createPayloadAction } from './action-adapters';
import { State } from './availability.reducer';
import { AvailabilityChange } from './models/availabilityChange';

export const updateAvailability = createPayloadAction<AvailabilityChange>(
  '[Agent Availability] Update Availability'
);

export const availabilityUpdated = createPayloadAction<AvailabilityChange>(
  '[Agent Availability] Availability Updated'
);

export const loadSettings = createAction('[Agent Availability] Load Settings');

export const settingsLoaded = createPayloadAction<string[]>(
  '[Agent Availability] Settings Loaded'
);

export const requestExtraChatsUpdated = createAction(
  '[Agent Availability] Request Extra Chats Updated',
  props<{ requestExtraChats: boolean }>()
);

export const hydrate = createAction('[Agent Availability] Hydrate');

export const hydrateSuccess = createAction(
  '[Agent Availability] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[Agent Availability] Hydrate Failure');
