// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpHandler, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggingFactoryService, RestApiService } from '@cxt-cee-chat/merc-ng-core';
import { GetChatAccountLocality } from '../domain/models/requests/getChatAccountLocality';
import { GetChatAccountUsers } from '../domain/models/requests/getChatAccountUsers';
import { AccountUsersResponse } from '../domain/models/responses/accountUsersResponse';
import { GetChatAccountLocalityResponse } from '../domain/models/responses/getChatAccountLocalityResponse';
import { NUANCE_BEARER_TOKEN_HANDLER } from './nuance-bearer-token-http-handler';

@Injectable({
  providedIn: 'root'
})
export class CustomerInfoApiService extends RestApiService {
  constructor(
      @Inject(NUANCE_BEARER_TOKEN_HANDLER) httpHandler: HttpHandler,
      loggingService: LoggingFactoryService
    ) {
		super(loggingService, httpHandler);
  }

  public getChatAccountLocality(model: GetChatAccountLocality): Promise<HttpResponse<GetChatAccountLocalityResponse>> {
    return this.post<GetChatAccountLocalityResponse>('customer/getaccountlocality', model);
  }

  public getChatAccountUsers(model: GetChatAccountUsers): Promise<HttpResponse<AccountUsersResponse>> {
    return this.post<AccountUsersResponse>('customer/getaccountusers', model);
  }
}
