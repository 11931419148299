// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { ChatRequestApiService } from '../../services/chat-request-api.service';
import { ChatActions, AskMeAnythingActions, ChatUiActions } from '../actions';
import { fromChat, fromAskMeAnything, fromAgent, fromSettings } from '../selectors';
import { AppState } from '../state';
import { AskMeAnythingPersisterService } from './ask-me-anything-persister.service';
import { RatingState, AskMeAnythingSource } from '../models/voice/enums';
import { LogHelper } from '../../utils/logHelper';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { AgentOperations, ChatOperations } from '../../constants/event-logs.constants';
import { Chat } from '../models';
import { UserIdentityService } from '../../services/user-identity.service';
import { AskMeAnythingSettings } from '../../constants/application-health.constants';


/*
hydrating the state with refresh
https://nils-mehlhorn.de/posts/ngrx-keep-state-refresh
*/
@Injectable()
export class AskMeAnythingEffects implements OnInitEffects {
  constructor(
    private action: Actions,
    private store: Store<AppState>,
    private persisterService: AskMeAnythingPersisterService,
    private chatRequestApiService: ChatRequestApiService,
    private loggingFactory: LoggingFactoryService,
    private timeService: DayTimeService,
    private userIdentityService: UserIdentityService,
  ) { }

  hydrate$ = createEffect(() =>
    this.action.pipe(
      ofType(AskMeAnythingActions.hydrate),
      map(() => {
        const state = this.persisterService.getState();
        return state
          ? AskMeAnythingActions.hydrateSuccess({ state })
          : AskMeAnythingActions.hydrateFailure();
      })
    )
  );

  saveInSessionStorage$ = createEffect(() =>
    this.action.pipe(
      // wait for hydrateSuccess or hydrateFailure then switch to listen for ChatUi state change
      ofType(AskMeAnythingActions.hydrateSuccess, AskMeAnythingActions.hydrateFailure),
      switchMap(() => this.store.pipe(select(fromAskMeAnything.selectAskMeAnythingFeature))),
      // TODO: maybe throttle/debounce/auditTime ????
      tap(state => {
        this.persisterService.storeState(state);
      })
    ),
    { dispatch: false }
  );

  updateActiveEngagements$ = createEffect(() =>
    this.action.pipe(
      ofType(ChatActions.updateActiveEngagements),
      concatLatestFrom(() =>
        this.store.select(fromChat.selectOrphanedChatUiChatIds)
      ),
      filter(([, chatIds]) => chatIds.length > 0),
      map(([, chatIds]) => {
        return AskMeAnythingActions.chatsRemoved({ chatIds });
      })
    )
  );
  
  search$ = createEffect(() => 
    this.action.pipe(
      ofType(AskMeAnythingActions.search),
      concatLatestFrom(({chatId}) => [
      this.store.select(fromAgent.getAgentAttributes),
      this.store.select(fromChat.getChat(chatId))]
      ),
      tap(([{chatId, searchId, question, isCustomerQuestion, knowledgeFocused}, userAttributes, chat]) => {
        if (knowledgeFocused){
          this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeFocused);
        }
        const logDimensions = {
          timestamp: this.timeService.unix(),
          searchId: searchId,
          source: isCustomerQuestion ? AskMeAnythingSource.Customer : AskMeAnythingSource.Agent
        };
        this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeSearchSent, logDimensions);
        this.getAnswer(chatId, searchId, question, userAttributes.role, userAttributes.locationId, this.userIdentityService.username, chat);
      })
    ),
    { dispatch: false }
  );

  retrySearch$ = createEffect(() => 
    this.action.pipe(
      ofType(AskMeAnythingActions.retrySearch),
      concatLatestFrom(({chatId, searchId}) => [
        this.store.select(fromAskMeAnything.getAskMeAnythingSearchBySearchId(chatId, searchId)),
        this.store.select(fromAgent.getAgentAttributes),
        this.store.select(fromChat.getChat(chatId))]
      ),
      filter(([, search]) => Boolean(search)),
      tap(([{chatId, searchId}, {question}, userAttributes, chat]) => {
        this.getAnswer(chatId, searchId, question, userAttributes.role, userAttributes.locationId, this.userIdentityService.username, chat);
      })
    ),
    { dispatch: false }
  );

  rateAnswer$ = createEffect(() => 
    this.action.pipe(
      ofType(AskMeAnythingActions.rateAnswer),
      concatLatestFrom(({chatId, searchId}) => [
        this.store.select(fromAskMeAnything.getAskMeAnythingSearchBySearchId(chatId, searchId)),
        this.store.select(fromChat.getChat(chatId))]
      ),
      tap(([{chatId, searchId}, {rating: ratingState, questionId}, chat]) => {
        const rating = ratingState === RatingState.Positive ? 1 : 0;
        const logDimensions = {
          positive: rating,
          searchId: searchId,
          questionId: questionId
        };
        this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeFeedback, logDimensions);
        this.chatRequestApiService.rateAnswer({rating, questionId});
      })
    ),
    { dispatch: false }
  );

  logSourceClicked$ = createEffect(() =>
      this.action.pipe(
        ofType(AskMeAnythingActions.logSourceClicked),
        concatLatestFrom(({chatId}) => [
          this.store.select(fromChat.getChat(chatId))]
        ),
        tap(([{chatId, documentId, searchId, questionId}, chat]) => {
          const logDimensions = {
            articleId: documentId,
            searchId: searchId,
            questionId: questionId
          };
          this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeSourceClicked, logDimensions);
        })
      ),
      { dispatch: false }
  );

  submitFeedbackFollowup$ = createEffect(() =>
    this.action.pipe(
      ofType(AskMeAnythingActions.submitFeedback),
      concatLatestFrom(({chatId}) => [
        this.store.select(fromChat.getChat(chatId))]
      ),
      tap(([{chatId, searchId, questionId, selectedFeedback, optionalFeedback}, chat]) => {
        const logDimensions = {
          searchId: searchId,
          questionId: questionId,
          reasons: selectedFeedback,
          description: optionalFeedback
        };
        this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeFeedbackFollowup, logDimensions);
      })
    ),
    { dispatch: false }
  );

  rewriteAnswer$ = createEffect(() => 
    this.action.pipe(
      ofType(AskMeAnythingActions.rewriteAnswer),
      concatLatestFrom(({chatId}) => [
        this.store.select(fromSettings.selectAskMeAnythingPromptId),
        this.store.select(fromChat.getChat(chatId))
      ]),
      tap(([{chatId, search}, promptId, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AskMeAnythingRewriteClicked, chat, {questionId: search.questionId});
        const {question, answer: content, searchId, questionId } = search;
        this.chatRequestApiService.getRewrite({question, content, userId: this.userIdentityService.username, promptId})
          .then((response) => {
            if (response?.success && response?.body?.output){
              this.store.dispatch(AskMeAnythingActions.addRewrite({chatId, rewrite: {rewriteAnswer: response.body.output, rewriteId: response.body.rewriteId, searchId, questionId }}));
            }
            else{
              this.store.dispatch(AskMeAnythingActions.rewriteError({chatId, questionId}));
            }
          })
          .catch(() => {
            this.store.dispatch(AskMeAnythingActions.rewriteError({chatId, questionId}));
          });
      })
    ),
    { dispatch: false }
  );

  clearErrorMessage$ = createEffect(() => 
    this.action.pipe(
      ofType(AskMeAnythingActions.rewriteError),
      delay(AskMeAnythingSettings.ErrorBannerDismissTimeInSeconds * 1000),
      tap(({chatId}) => {
        this.store.dispatch(ChatUiActions.clearMessageInputErrorMessage({chatId}));
      })
    ),
    { dispatch: false }
  );

  rewriteError$ = createEffect(() => 
    this.action.pipe(
      ofType(AskMeAnythingActions.rewriteError),
      concatLatestFrom(({chatId}) => this.store.select(fromChat.getChat(chatId))),
      tap(([{questionId}, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AskMeAnythingRewriteError, chat, {questionId});
      })
    ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return AskMeAnythingActions.hydrate();
  }

  private getAnswer(chatId: string, searchId: string, question: string, role: string, location: string, userId: string, chat: Chat){
    this.chatRequestApiService.getAnswer({question, role, location, userId, chatId, conversationId: chat?.conversationId, accountNumber: chat?.accountNumber})
    .then(response => {
      if (response.success){
        const {questionId, answer, citations} = response.body;
        const logDimensions = {
          searchId: searchId,
          questionId: response.body?.questionId,
          isBlank: (response.body?.answer == null),
          timestamp: this.timeService.unix()
        };
        this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeResponse, logDimensions);
        this.store.dispatch(AskMeAnythingActions.updateAnswer({chatId, searchId, questionId, answer, citations}));
      }
      else{
        const logDimensions = {
          errorMessage: response.body?.error,
          searchId: searchId,
          questionId: response.body?.questionId
        };
        this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeError, logDimensions);
        this.store.dispatch(AskMeAnythingActions.searchFailed({chatId, searchId}));
      }
    })
    .catch((error) => {
      const logDimensions = {
        errorMessage: error?.message,
        searchId: searchId
      };
      this.logAmaEvent(chatId, chat, AgentOperations.KnowledgeError, logDimensions);
      this.store.dispatch(AskMeAnythingActions.searchFailed({chatId, searchId}));
    });
  }

  private logAmaEvent(chatId: string, chat: Chat, operation: string, additionalDimensions?: object){
    const logDimensions = {
      ...(chatId != null && { engagementId: chatId }),
      ...(chat?.isAsync && { conversationId: chat.conversationId }),
      ...additionalDimensions
    };
    LogHelper.logAgentEvents(this.loggingFactory, operation, logDimensions);
  }
}
