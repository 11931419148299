<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div *ngIf="chatId" class="merc-disposition">
  <cee-accordion-radio
    [isV2]="isV2"
    (radioChange)="onRadioChange($event)"
    [radioGroupsLabel]="dispositionLabel" 
    [selected]="selectedDispositionId"
    [radioGroups]="formattedDispositionGroups$ | async">
  </cee-accordion-radio>
  <cee-accordion *ngIf="!isV2 && !(hasHideSummaryFeatureFlag$ | async)" titleText="Add Notes" [customIcon]="customIcon" [type]="'tertiary'">
    <div class="cee-disposition-notes" [ngClass]="{ 'has-error': showProfanityError }">
        <label class="cee-form-label cee-accessibly-hidden" for="disposition-notes">Add notes</label>
        <input (keyup)="profanityCheckNotes()" [(ngModel)]="dispositionNotes" type="text" class="cee-form-input" [ngClass]="{ 'error': showProfanityError }" name="disposition-notes" id="disposition-notes" placeholder="Add notes" aria-describedby="disposition-error-msg"/>
        <p *ngIf="showProfanityError" class="cee-form-error-msg" id="disposition-error-msg">You will need to remove any inappropriate words before sending your message.</p>
    </div>
  </cee-accordion>
</div>