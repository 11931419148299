// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { State } from './language-translation.reducer';
import { TranslatedTextData } from '../models/responses/translation-response';

export const hydrate = createAction('[LanguageTranslation] Hydrate');

export const hydrateSuccess = createAction(
  '[LanguageTranslation] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[LanguageTranslation] Hydrate Failure');

export const chatMessagesTranslationUpdated = createAction(
  '[Language Translation] Chat Message Translations Updated',
  props<{ chatId: string, translatedMessages: TranslatedTextData[], isError?: boolean, isAsyncTranslated?: boolean }>()
);
