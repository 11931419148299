<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<header class="merc-voice-header" *ngIf="{
  errorState: (errorState$ | async)
  } as data">
  <img class="merc-feature-image" data-qa="merc-logo" src="../../../../assets/img/mercury-logo-square-darkbg.png"
    alt="mercury-logo" />
  <div class="merc-voice-header--error-state" [ngClass]="'merc-voice-header--error-state-' + getErrorIcon(data.errorState)" *ngIf="data.errorState">
    <span class="merc-voice-header--error-message">{{getErrorMessage(data.errorState)}}</span>
    <svg class="cee-icon">
      <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-' + getErrorIcon(data.errorState)"></use>
    </svg>
  </div>
</header>
