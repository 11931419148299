// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SenderType } from '../enums';
import { FeedContentType } from './enums';
import { FeedContent } from './feed-content';
import { TranscriptMessage } from './transcriptMessage';

export class HighlightedMessage extends FeedContent {
  type: FeedContentType.PinnedMessage;
  utteranceSequence: number;
  sender: SenderType;
  message: string;

  constructor(transcriptMessage: TranscriptMessage, id: string) {
    super();
    this.type = FeedContentType.PinnedMessage;
    this.utteranceSequence = transcriptMessage.utteranceSequence;
    this.sender = transcriptMessage.sender;
    this.message = transcriptMessage.message;
    this.id = id;
  }
}
