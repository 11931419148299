// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class LanguageTranslationHelper {
    public static doesTranslationIdMatch(pendingTraceId: string, pendingMessageId: string, translationId: string): boolean {
        return Boolean((pendingTraceId && pendingTraceId === translationId) 
            || (pendingMessageId && pendingMessageId === translationId));
    }
}
