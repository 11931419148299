// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { CloseAsyncChat } from 'projects/entities/src/lib/domain/models/closeAsyncChat';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { AppState, ChatActions, DispositionCategory, DispositionSelection, FeatureFlags, ProfanityService, UiActions } from 'projects/entities/src/public_api';
import { Store } from '@ngrx/store';
import { CloseMethod } from '@cxt-cee-chat/merc-pattern-lib';
import { CloseChat } from 'projects/entities/src/lib/domain/models/closeChat';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-close-convo',
  templateUrl: './close-convo.component.html',
  styleUrls: ['./close-convo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CloseConvoComponent extends SubscriberComponent implements OnInit {
  active: boolean = false;
  isAsync: boolean;
  asyncContactReason: string;
  asyncResolution: string;
  selectedDisposition: DispositionSelection;
  disposition: DispositionSelection;
  headerText: string;
  disableConfirmation: boolean;
  dispositions$: Observable<DispositionCategory[]>;
  hasHideSummaryFeatureFlag$: Observable<boolean>;

  @Input() chatId: string;

  constructor(
    private store: Store<AppState>,
    private changeDetection: ChangeDetectorRef,
    private profanityService: ProfanityService
    ) {
    super();
  }

  ngOnInit() {
    this.dispositions$ = this.store.select(fromChat.getDispositions);
    this.hasHideSummaryFeatureFlag$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.HideSummary));

    this.store.select(fromChat.getSelectedChatIsAsync)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((isAsync: boolean) => {
        this.isAsync = isAsync;
        this.changeDetection.detectChanges();
    });

    this.store.select(fromChat.getSelectedDisposition)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((disposition: DispositionSelection) => {
        if (this.isAsync) { return; }

        if (disposition && disposition.collectionId) {
          const notes = disposition.notes ?? '';
          const profanity = this.profanityService.test(notes);
          this.selectedDisposition = disposition;
          
          if (profanity.length > 0) {
            this.disableConfirmation = true;
          } else {
            this.disableConfirmation = false;
          }
        } else {
          this.disableConfirmation = true;
        }
      });
      
    this.resetForm();
  }

  closeModal(method: CloseMethod): void {
    if (method !== CloseMethod.submit) {
      this.store.dispatch(UiActions.ExitPromptCloseEngagement({ method }));
    }
  }

  public closeChat(): void {
    if (this.isAsync) {
      this.closeAsyncChat();
    } else {
      const closeChat: CloseChat = {
        chatId: this.chatId,
        dispositions: this.selectedDisposition
      };
      
      this.store.dispatch(ChatActions.Close(closeChat));
    }
  }

  private resetForm(): void {
    this.active = true;
    this.asyncResolution = '';
    this.asyncContactReason = '';
    this.changeDetection.detectChanges();
  }

  private closeAsyncChat(): void {
    const resolved = this.asyncResolution === 'resolved';
    const closeModel: CloseAsyncChat = {
      chatId: this.chatId,
      resolved: resolved,
      contactReason: this.asyncContactReason
    };
    this.store.dispatch(ChatActions.CloseAsyncChat(closeModel));
  }
}
