// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Chunk } from 'highlight-words-core';
import { ChatMessage } from './chatMessage';

export class MaskText {
  maskedChatLine: string;
  /**
   * An array of chunk objects representing both masked (highlight: true) and unmasked pieces of the message.
   */
  maskedChunks: Chunk[];
  chatId: string;
  chatMessage: ChatMessage;
  traceId: string;
  translatedMaskedChatLine?: string;
  translatedMaskedChunks?: Chunk[];
}
