// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatInteraction } from './chatInteraction';
import { UpdateMessageType } from './enums';

export class UpdateMessages{
  chatId: string;
  messages: ChatInteraction[];
  update: UpdateMessageType;
  currentTime: number;
}
