// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ScriptActions, ScriptGroupActions, ScriptTreeActions } from './actions';
import { defaultCustomScriptGroup } from './custom-responses.constants';
import { ScriptGroup } from './models';
import { ManagedScript } from './script.model';

export const scriptGroupsFeatureKey = 'scriptGroups';

export interface State extends EntityState<ScriptGroup> {
  managedScriptGroup: ManagedScript | null;
}

export const adapter: EntityAdapter<ScriptGroup> = createEntityAdapter<ScriptGroup>();

export const initialState: State = adapter.getInitialState({
  managedScriptGroup: null
});

export const reducer = createReducer(
  initialState,
  on(ScriptTreeActions.upsertScriptCollection,
    (state, action) => adapter.upsertMany(action.scriptGroups, state)
  ),
  on(ScriptTreeActions.deleteScriptCollection,
    (state, { scriptTreeId }) => {
      const ids = Object.entries(state.entities)
        .filter(([_, entity]) => entity.scriptTreeId === scriptTreeId)
        .map(([key, _]) => key);
      return adapter.removeMany(ids, state);
    }
  ),
  on(ScriptActions.addScript,
    (state, { script: { id, scriptGroupId }, scriptGroup, parentScriptGroupId }) => {
      const addedScriptGroupState = addScriptGroup(state, scriptGroup, parentScriptGroupId);
      let scriptGroupFromState = addedScriptGroupState.entities[scriptGroupId];
      if (!scriptGroupFromState && scriptGroupId === defaultCustomScriptGroup.id){
        scriptGroupFromState = defaultCustomScriptGroup;
      }
      if (!scriptGroupFromState || scriptGroupFromState.scripts.includes(id) || scriptGroupFromState.id !== scriptGroupId) {
        return state;
      }

      const scripts = scriptGroupFromState.scripts || [];
      const update: ScriptGroup = {
        ...scriptGroupFromState,
        scripts: [id, ...scripts],
      };

      return {
        ...adapter.upsertOne(update, addedScriptGroupState),
        managedScriptGroup: null
      };
    }
  ),
  on(ScriptActions.deleteScript,
    (state, { id, scriptGroupId }) => {
      const scriptGroup = state.entities[scriptGroupId];
      if (!scriptGroup?.scripts.includes(id)) {
        return state;
      }

      const update: ScriptGroup = {
        ...scriptGroup,
        scripts: scriptGroup.scripts.filter(scriptId => scriptId !== id),
      };

      return adapter.upsertOne(update, state);
    }
  ),
  on(ScriptGroupActions.addScriptGroup,
    (state, { scriptGroup, parentScriptGroupId}) => {
      return addScriptGroup(state, scriptGroup, parentScriptGroupId);
    }
  ),
  on(ScriptActions.updateScriptValue,
    (state, { id, scriptGroupId, newScriptGroup, newScriptGroupParentId }) => {
      if (!newScriptGroup || scriptGroupId === newScriptGroup.id){
        return state;
      }
      const addedScriptGroupState = addScriptGroup(state, newScriptGroup, newScriptGroupParentId);

      const newParent = addedScriptGroupState.entities[newScriptGroup.id];
      const oldParent = addedScriptGroupState.entities[scriptGroupId];
      if (!newParent || !oldParent) { return state; }

      const updatedNewParent: ScriptGroup = {
        ...newParent,
        scripts: [id, ...newParent.scripts]
      };
      const updatedOldParent: ScriptGroup = {
        ...oldParent,
        scripts: oldParent.scripts.filter(scriptId => scriptId !== id)
      };

      const update: ScriptGroup[] = [updatedNewParent, updatedOldParent];

      return {
        ...adapter.upsertMany(update, addedScriptGroupState),
        managedScriptGroup: null
      };
    }
  ),
  on(ScriptGroupActions.createScriptGroup,
    (state) => {
      return {
        ...state,
        managedScriptGroup: {id: null, status: 'CREATE'}
      };
  }),
  on(ScriptGroupActions.editScriptGroup,
    (state, { id }) => {
      return {
        ...state,
        managedScriptGroup: {id: id, status: 'EDIT'}
      };
  }),
  on(ScriptActions.manageScriptsClosed,
    (state) => ({
      ...state,
      managedScriptGroup: null,
  })),
  on(ScriptActions.deleteScriptConfirmationDismiss,
    (state) => ({
      ...state,
      managedScriptGroup: null,
  })),
  on(ScriptActions.manageScripts,
    (state) => ({
      ...state,
      managedScriptGroup: { id: null, status: 'MANAGE' },
    })
  ),
  on(ScriptGroupActions.updateScriptGroupName,
    (state, { id , name}) => {
      const update: Update<ScriptGroup> = {
        id,
        changes: {
          name
        }
      };

      return {
        ...adapter.updateOne(update, state) ,
        managedScriptGroup: { id: null, status: 'EDIT HEADER' }
      };
    }
  ),
  on(ScriptGroupActions.deleteScriptGroupConfirmation,
    (state, { id }) => {
      return {
        ...state,
        managedScriptGroup: {id, status: 'DELETE'}
      };
    }
  ),
  on(ScriptGroupActions.deleteScriptGroup,
    (state, { id }) => {
      const existingScriptGroup = state.entities[id];
      if (!existingScriptGroup){
        return state;
      }

      // remove the current script group
      const removedState = adapter.removeOne(id, state);
      const [_, parentGroup] = Object.entries(state.entities)
        .find(([_key, entity]) => entity.scriptGroups.find(scriptGroupId => scriptGroupId === id));

      if (!parentGroup){
        return removedState;
      }

      // remove reference to the id from the parent
      const update: Update<ScriptGroup> = {
        id: parentGroup.id,
        changes: {
          scriptGroups: parentGroup.scriptGroups.filter(scriptGroupId => scriptGroupId !== id),
          scripts: [...parentGroup.scripts, ...existingScriptGroup.scripts]
        }
      };

      return {
        ...adapter.updateOne(update, removedState),
        managedScriptGroup: {id, status: 'DELETE'}
      };
    }
  ),
  on(ScriptGroupActions.moveScriptGroup,
    (state, { parentScriptGroupId, scriptGroupIds }) => {
      const update: Update<ScriptGroup> = {
        id: parentScriptGroupId,
        changes: {
          scriptGroups: scriptGroupIds
        }
      };

      return adapter.updateOne(update, state);
    }
  ),
  on(ScriptActions.moveScript,
    (state, { id, scriptGroupId, newScriptGroupId, scriptIds }) => {
      if (!newScriptGroupId || scriptGroupId === newScriptGroupId){
        //no change in script group just moved inside same script group
        const update: Update<ScriptGroup> = {
          id: scriptGroupId,
          changes: {
            scripts: scriptIds
          }
        };
        return adapter.updateOne(update, state);
      }
      else{
        // otherwise remove it from the old parent, and update the order in the new parent
        const newParent = state.entities[newScriptGroupId];
        const oldParent = state.entities[scriptGroupId];
        if (!newParent || !oldParent) { return state; }

        const updatedNewParent: ScriptGroup = {
          ...newParent,
          scripts: scriptIds
        };
        const updatedOldParent: ScriptGroup = {
          ...oldParent,
          scripts: oldParent.scripts.filter(scriptId => scriptId !== id)
        };
        const update: ScriptGroup[] = [updatedNewParent, updatedOldParent];

        return adapter.upsertMany(update, state);
      }
    }
  ),
  on(ScriptActions.hydrateSuccess, (state, action) => {
    return action.scriptGroupState ? action.scriptGroupState : state;
  })
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getManagedScriptGroup = (state: State) => state.managedScriptGroup;

function addScriptGroup(state: State, scriptGroup: ScriptGroup, parentScriptGroupId: string): State{
  const exists = state.entities[scriptGroup?.id];
  let parentScriptGroup = state.entities[parentScriptGroupId];
  if (!parentScriptGroup && parentScriptGroupId === defaultCustomScriptGroup.id){
    parentScriptGroup = defaultCustomScriptGroup;
  }
  if (exists || !parentScriptGroup || !scriptGroup){
    return state;
  }

  const updatedParent: ScriptGroup = {
    ...parentScriptGroup,
    scriptGroups: [scriptGroup.id, ...parentScriptGroup.scriptGroups]
  };

  const update: ScriptGroup[] = [updatedParent, scriptGroup];
  return {
    ...adapter.upsertMany(update, state),
    managedScriptGroup: { id: null, status: 'NEW HEADER' }
  };
}
