// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createSelector } from '@ngrx/store';
import { ScriptsConstants } from 'src/app/constants/constants';
import { ChatUi } from '../chat-ui/chat-ui.model';
import { getSelectedChatUi } from '../chat.selectors';
import { Script, ScriptGroup } from './models';
import * as fromScripts from './scripts-selectors';

export const getSearchTerm = createSelector(
  getSelectedChatUi,
  (chatUi: ChatUi) => chatUi?.scriptSearchTerm || ''
);

export const isSearchTermValid = (searchTerm: string) =>
  searchTerm?.length >= ScriptsConstants.SEARCHING_LENGTH;

export const isSelectedChatSearchingScripts = createSelector(
  getSearchTerm,
  (searchTerm: string) => isSearchTermValid(searchTerm)
);

export const selectFilteredScripts = createSelector(
  getSearchTerm,
  fromScripts.selectScripts,
  (searchTerm: string, scripts: Script[]) => filterScripts(searchTerm, scripts)
);

export const selectScriptGroupsContainingFilteredScripts = createSelector(
  selectFilteredScripts,
  fromScripts.selectAllScriptGroups,
  (filteredScripts: Script[], scriptGroups: ScriptGroup[]) =>
    scriptGroups.filter(sg => sg.scripts.some(id => filteredScripts.some(script => script.id === id)))
);

export const filterScripts = (query: string, scripts: Script[]): Script[] => {
  if (!isSearchTermValid(query)) {
    return scripts;
  }
  const search = query.toLowerCase();
  return scripts.filter(s => s?.value?.toLowerCase().includes(search));
};

