// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { AuthService } from '@cxt-cee-chat/merc-ng-core';
import { UserIdentityService } from '../../services/user-identity.service';
import { NuanceCredentials } from '../../domain/models/credentials';

@Injectable({
  providedIn: 'root'
})
export class NuanceCredentialService {
  constructor(
    protected authService: AuthService,
    protected userIdentityService: UserIdentityService
  ) {}

  public async getCredential(): Promise<NuanceCredentials> {
    const { accessToken } = await this.authService.getToken();
    const {
      registeredId: agentId,
      username: agentUsername
    } = this.userIdentityService;
    
    return { accessToken, agentId, agentUsername };
  }
}
