<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-voice-footer" *ngIf="{ callId: callId$ | async } as data">
  <div class="merc-voice-footer--call-id">
    <span>Call ID:</span>
    <cee-button-v2 type="icon"
        [tooltipText]="copyTooltipText"
        tooltipId="copy-call-id"
        tooltipDirection="top"
        tooltipAppearanceDelayMs="0"
        tooltipPersistDirective="true"
        (buttonClick)="copyToClipboard(data.callId)"
        (mouseout)="resetCopyText()">
      <span>{{ data.callId }}</span>
      <svg class="cee-icon">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-copy-v2"></use>
      </svg>
    </cee-button-v2>
  </div>
  <span class="merc-voice-footer--build-version">Build {{ buildVersion }}</span>
</div>
