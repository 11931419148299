// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { EventLogNames } from '../../../constants/event-logs.constants';
import { ChatEvent } from './chat-event';

export class AbandonedChatAutomationEvent extends ChatEvent {
  elapsedTime: number;

  constructor(data: {}) {
    super(EventLogNames.AbandonedChatAutomation, data);
  }
}
