// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Guid } from 'guid-typescript';
import { PageNames } from '../constants/constants';
import { SenderType } from '../domain/models/enums';
import { ItgDetails } from '../domain/models/itg-details';
import { RecommendedActionControlTexts, RecommendedActionErrorMessages } from '../domain/models/voice/constants/recommendedActionConstants';
import { CustomerInfo } from '../domain/models/voice/customerInfo';
import { CallErrorState, CallState, FeedContentType, RatingState, RecommendedActionState, RecommendedActionType } from '../domain/models/voice/enums';
import { RecommendedAction } from '../domain/models/voice/recommended-action';
import { TranscriptRecommendedActionResponse } from '../domain/models/voice/responses/transcript-recommended-action-response';

export class VoiceHelper {
  public static isCustomer(sender: SenderType) {
    return sender === SenderType.Requester;
  }

  public static isCallInactive(callState: CallState) {
    return callState === CallState.Inactive;
  }

  public static hasCallError(callErrorState: CallErrorState) {
    return callErrorState !== CallErrorState.None;
  }

  public static getCustomerName(customerName: string) {
    return customerName ?? 'New Customer';
  }

  public static createItgRecommendedAction(response: TranscriptRecommendedActionResponse, itgList: ItgDetails[]): RecommendedAction {
    const itgDetails = itgList?.find(i => i.id === response.recommendedActionName);
    const recommendedAction: RecommendedAction = {
      type: FeedContentType.ItgRecommendation,
      message: response.message,
      utteranceSequenceReference: response.messageSequence,
      actionType: RecommendedActionType.Itg,
      actionState: RecommendedActionState.Recommendation,
      titleIcon: 'connect',
      controlText: RecommendedActionControlTexts.genericItg,
      errorMessage: RecommendedActionErrorMessages.genericItg,
      id: Guid.raw(),
      intent: response.recommendedActionName,
      ratingState: RatingState.None,
      actionTitle: itgDetails?.title ?? '',
      description: itgDetails?.description ?? ''
    };
    return recommendedAction;
  }

  public static getRecommendedActionLoggingTypeName(type: FeedContentType): string {
    switch (type) {
      case FeedContentType.ItgRecommendation:
        return 'Itg';
      default:
        return 'Unknown';
    }
  }

  public static isVoicePage(pageName: string){
    return pageName === PageNames.voice;
  }

  public static isAuthenticatedCall(customerInfo: CustomerInfo){
    return Boolean(typeof customerInfo?.accountNumber === 'string' && customerInfo?.accountNumber?.trim());
  }
}
