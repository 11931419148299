// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CeeTabPanelItemComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { AgentCustomSettings, AppState, FeatureFlags, hasFeatureFlag } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-agent-settings-advanced-features-tab',
  templateUrl: './advanced-features-tab.component.html',
  styleUrls: ['./advanced-features-tab.component.scss'],
  providers: [
    {
      provide: CeeTabPanelItemComponent,
      useExisting: forwardRef(() => AdvancedFeaturesTabComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedFeaturesTabComponent extends CeeTabPanelItemComponent implements OnInit {
  title = 'Advanced Features';
  showControls = true;
  hasScriptAutocomplete$: Observable<boolean>;
  haslanguageTranslatorFeatureFlag$: Observable<boolean>;

  @Input() settings: AgentCustomSettings;
  @Output() settingsModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.haslanguageTranslatorFeatureFlag$ = this.store.select(hasFeatureFlag(FeatureFlags.LanguageTranslator));
    this.hasScriptAutocomplete$ = this.store
      .select(hasFeatureFlag(FeatureFlags.ScriptAutocomplete));
  }

  onSettingsModelChange() {         
    this.settingsModelChange.emit(this.settings);
  }

}
