// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class AutoGreetMessageConstants {
  static VerifiedAllowedContextMessage = (customerName: string, agentName: string, context: string) => `Hi ${customerName}, thank you for contacting Xfinity. My name is ${agentName}. ${context} Is that correct?`;
  static VerifiedNotAllowedContextMessage = (customerName: string, agentName: string) => `Hi ${customerName}, thank you for contacting Xfinity. My name is ${agentName}. How may I help you today?`;
  static NotVerifiedAllowedContextMessage = (agentName: string, context: string) => `Hello, thank you for contacting Xfinity. My name is ${agentName}. ${context} Is that correct?`;
  static NotVerifiedNotAllowedContextMessage = (agentName: string) => `Hello, thank you for contacting Xfinity. My name is ${agentName}. How may I help you today?`;  
  static Transferred = (agentName: string) => `Hi, my name is ${agentName}. I see you have been chatting with another team member. Let me review that information so I can pick up right where you left off.`;
  static ReturnAsync = (customerName: string, agentName: string) => {
    if (customerName) {
      return `Hi ${customerName}, welcome back. This is ${agentName}, please give me a few moments to review the conversation so we can pick up right where you left off.`;
    }
    else {
      return `Welcome back. This is ${agentName}, please give me a few moments to review the conversation so we can pick up right where you left off.`;
    }
  }
}
