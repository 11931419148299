// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { EventLogNames } from '../constants/event-logs.constants';
import { RenderEvent } from '../domain/models/events/render-event';

@Injectable({
  providedIn: 'root'
})
export class PerformanceApiService{
  constructor(private loggingFactory: LoggingFactoryService) {
  }

  /**
   * If you have two concurrent actions with the exact same operation name, this will NOT return two separate logs and will only return one.
   * Ensure to have separate specific names for similar actions if possible (eg. chat load appended with engagement id)
   * @param operationName the name of the operation to measure
   */
  public StartMeasureTime(operationName: string) {
    performance.mark(operationName + '_Start');
  }

  public StopAndLogMeasureTime(operationName: string) {
    const operationNameStart = operationName + '_Start';
    const operationNameEnd = operationName + '_End';
    
    performance.mark(operationNameEnd);

    try {
      const logDimensions = {
        operationName,
        time: performance.measure(operationName, operationNameStart, operationNameEnd)['duration']
      };
  
      this.loggingFactory.logEvent(new RenderEvent(EventLogNames.RenderEvent, logDimensions));
    }
    finally {
      performance.clearMarks(operationNameStart);
      performance.clearMarks(operationNameEnd);
      performance.clearMeasures(operationName);
    }
  }
}
