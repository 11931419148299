<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-script-context-menu">
  <cee-context-menu
    [options]="options$ | async"
    [disabledText]="disabledText$ | async"
    [isOpen]="isOpen$ | async"
    [menuX]="menuX$ | async"
    [menuY]="menuY$ | async"
    (menuChange)="onMenuChange($event)"
    (optionClicked)="onOptClicked($event)">
  </cee-context-menu>
</div>
