<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-convo-toast" *ngIf="(toastDetails$ | async)?.active">
  <div class="merc-convo-toast-details">
    <svg *ngIf="(toastDetails$ | async)?.hasIcon" class="cee-icon" width="16" height="16" viewBox="0 0 16 16">
      <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-'+(toastDetails$ | async)?.hasIcon"></use>
    </svg>
    <span *ngIf="(toastDetails$ | async)?.text; else openSettings">
      {{ (toastDetails$ | async)?.text }}
    </span>
  </div>
  <div *ngIf="(toastDetails$ | async)?.animation && !(hideAnimations$ | async)">
    <cee-countdown-indicator [customClass]="'merc-custom-animation-background'"></cee-countdown-indicator>
  </div>
  <button
    *ngIf="(toastDetails$ | async)?.dismissible"
    class="cee-btn cee-btn--link cee-banner-dismiss"
    (click)="onDismiss()"
  >
    <svg class="cee-icon cee-icon--md">
      <use xlink:href="/assets/img/svg-sprite.svg#icon-close"></use>
    </svg>
    <div class="cee-accessibly-hidden">Dismiss</div>
  </button>
</div>

<!-- This component is reused in a few places, 
  but this spec needed a method added to the text
  and because angular sanitizes any JS, it needs to be hard coded here.
  This section can also be used for future, JS specific requests for inline toasts
-->
<ng-template #openSettings>
  <span>Give feedback any time in <button class="merc-toast-button cee-btn cee-btn--link" (click)="triggerOpenSettings()">Settings</button></span>
</ng-template>