// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Pipe, PipeTransform } from '@angular/core';
import { ChatMessageState } from '@cxt-cee-chat/merc-pattern-lib';
import { ChatMessageStatus } from 'projects/entities/src/lib/domain/models/enums';

/*
 * Translates ChatMessageStatus to ChatMessageState
 * Usage:
 *   ChatMessageStatus | ChatMessageState
*/

@Pipe({
  name: 'toMessageState'
})
export class ToMessageStatePipe implements PipeTransform {
  transform(type: ChatMessageStatus): ChatMessageState {
    switch (type)
    {
      case ChatMessageStatus.Pending:
        return ChatMessageState.Pending;
      case ChatMessageStatus.Error:
        return ChatMessageState.Error;
      case ChatMessageStatus.Retrying:
        return ChatMessageState.Retrying;
      case ChatMessageStatus.Failed:
        return ChatMessageState.Failed;
      default:
        return ChatMessageState.Success;
    }
  }
}
