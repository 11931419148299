// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { AgentAvailabilityActions, ChatActions } from './actions';
import { AvailabilityChange } from './models/availabilityChange';
import { AvailabilityType } from './models/enums';

export const featureKey = 'agentAvailability';

export interface State {
  settingAvailability: boolean;
  availability: AvailabilityChange;
  settings: string[];
  availableForExtraChats: boolean;
  chatsClosedSinceAvailable: number;
  wasPreviousStatusAvailable: boolean;
}

export const initialState: State = {
  settingAvailability: false,
  availability: {
    available: AvailabilityType.Unavailable,
    reason: 'Busy',
    timestamp: Date.now()
  },
  wasPreviousStatusAvailable: false,
  settings: [],
  availableForExtraChats: false,
  chatsClosedSinceAvailable: 0
};

export const reducer = createReducer<State>(
  initialState,

  on(
    AgentAvailabilityActions.updateAvailability,
    (state): State => ({
      ...state,
      settingAvailability: true
    })
  ),
  on(
    AgentAvailabilityActions.availabilityUpdated,
    (state, action): State => ({
      ...state,
      settingAvailability: false,
      availability: action.payload,
      wasPreviousStatusAvailable: state?.availability?.available === AvailabilityType.Available,
      chatsClosedSinceAvailable: action.payload?.available === AvailabilityType.Available ? 0 : state.chatsClosedSinceAvailable
    })
  ),
  on(
    AgentAvailabilityActions.settingsLoaded,
    (state, action): State => ({
      ...state,
      settings: action.payload
    })
  ),
  on(
    AgentAvailabilityActions.requestExtraChatsUpdated,
    (state, { requestExtraChats }): State => ({
      ...state,
      availableForExtraChats: requestExtraChats
    })
  ),
  on(
    ChatActions.Closed,
    (state, ): State => ({
      ...state,
      chatsClosedSinceAvailable: state.chatsClosedSinceAvailable + 1
    })
  ),
  on(AgentAvailabilityActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
);
