// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ChatActions, ChatUiActions } from 'projects/entities/src/public_api';
import { ButtonControl, ButtonType } from '@cxt-cee-chat/merc-pattern-lib';
import { fromChat, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { ItgActionType } from 'projects/entities/src/lib/domain/models/enums';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { ItgMetadata } from 'projects/entities/src/lib/domain/models/itgMetadata';
import { ItgSuggestion } from 'projects/entities/src/lib/domain/models/itgSuggestion';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';

const cancelButton = 'Cancel';

const controlText = {
  cancel: {
    buttonText: 'Yes, cancel the ITG',
  },
  pause: {
    buttonText: 'Yes, send message',
  }
};

@Component({
  selector: 'merc-itg-modal',
  templateUrl: './itg-modal.component.html',
  styleUrls: ['./itg-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ItgModalComponent extends SubscriberComponent implements OnInit {
  @Input() chatId: string;
  active: boolean = true;
  headerText: string;
  modalText: string;
  controls: ButtonControl[];
  itgTitle: string;
  latestItg: ItgSuggestion;
  itgSuggestion: ItgSuggestion;
  headerText$: Observable<string>;
  modalText$: Observable<string>;
  
  constructor(
    private ngEntityStore: Store<AppState>,
    private timeService: DayTimeService
  ) {
    super();
  }

  ngOnInit() {
    this.headerText$ = this.ngEntityStore.select(fromUi.getItgHeaderText);
    this.modalText$ = this.ngEntityStore.select(fromUi.getItgModalText);

    this.ngEntityStore.select(fromUi.getItgModalAction).pipe(takeUntil(this.destroyed$)).subscribe((action) => {
      const buttonText = this.updateButtonText(action);

      this.controls = [{
        text: cancelButton,
        type: ButtonType.Link,
        disabled: false,
        controlFunction: () => this.closeModal()
      },
      {
        text: buttonText,
        type: ButtonType.Primary,
        disabled: false,
        controlFunction: () => this.onConfirm(action)
      }];
    });

    this.ngEntityStore.select(fromChat.getCurrentItg(this.chatId)).pipe(takeUntil(this.destroyed$)).subscribe((currentItg: ItgMetadata) => {
      this.itgTitle = currentItg?.title;
    });
    
    this.ngEntityStore.select(fromUi.getItgModalSuggestedItg).pipe(takeUntil(this.destroyed$)).subscribe((itgSuggestion: ItgSuggestion) => {
      this.itgSuggestion = itgSuggestion;
    });

    this.active = true;
  }

  updateButtonText(action) {
    switch (action) {
      case ItgActionType.Cancel :
        return controlText.cancel.buttonText;
      case ItgActionType.Pause :
        return controlText.pause.buttonText;
      case ItgActionType.Start :
        return controlText.cancel.buttonText;
    }
  }

  onConfirm(action) {
    const payload = {
      chatId: this.chatId,
      title: this.itgTitle,
      timestamp: this.timeService.unix()
    };

    switch (action) {
      case ItgActionType.Cancel :
        this.ngEntityStore.dispatch(ChatUiActions.cancelItg(payload));
        break;
      case ItgActionType.Pause :
        this.ngEntityStore.dispatch(ChatUiActions.pauseItg(payload));
        break;
      case ItgActionType.Start :
        this.ngEntityStore.dispatch(ChatUiActions.startItg(
          {...payload, itgSuggestion: this.itgSuggestion, source: 'activities'}
        ));
        break;
    }
    this.closeModal();
  }

  closeModal(){
    this.ngEntityStore.dispatch(ChatActions.StoredTextToSend({chatId: this.chatId, storedTextToSend: null}));
    this.active = false;
  }
}
