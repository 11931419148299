// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { AgentAuthActions, VoiceUiActions } from '../../actions';
import { CallErrorState, CallState, HomeHeaderErrorState, SingleColumnHomeDisplayMode } from '../../models/voice/enums';

export const featureKey = 'voice-ui';

export interface State {
  callState: CallState;
  callErrorState: CallErrorState;
  singleColumnHomeDisplayMode: SingleColumnHomeDisplayMode;
  isTranscriptFeedCollapsed: boolean;
  isShowAgentTranscriptEnabled: boolean;
  headerErrorState: HomeHeaderErrorState;
  hasRecommendationsError: boolean;
}

export const initialState: State = {
  callState: CallState.Inactive,
  callErrorState: CallErrorState.None,
  singleColumnHomeDisplayMode: SingleColumnHomeDisplayMode.HighlightFeed,
  isTranscriptFeedCollapsed: false,
  isShowAgentTranscriptEnabled: true,
  headerErrorState: HomeHeaderErrorState.None,
  hasRecommendationsError: false
};

export const reducer = createReducer(
  initialState,
  on(VoiceUiActions.hydrateSuccess, (state, action) => {
    return { ...state, ...action.state };
  }),
  on(VoiceUiActions.ToggleHomeDisplayMode, (state, {displayMode}) => {
    return {
      ...state,
      singleColumnHomeDisplayMode: displayMode
    };
  }),
  on(VoiceUiActions.ToggleLiveTranscriptCollapse, (state, {isCollapsed}) => {
    return {
      ...state,
      isTranscriptFeedCollapsed: isCollapsed
    };
  }),
  on(VoiceUiActions.ToggleShowAgentTranscript, (state, {isEnabled}) => {
    return {
      ...state,
      isShowAgentTranscriptEnabled: isEnabled
    };
  }),
  on(AgentAuthActions.createVoiceSession, (state, {errorState}) => {
    return {
      ...state,
      headerErrorState: errorState
    };
  }),
  on(VoiceUiActions.UpdateHeaderErrorState, (state, {errorState}) => {
    return {
      ...state,
      headerErrorState: errorState
    };
  })
);
