// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlags } from '../../constants/featureFlags.constants';
import { SmartResponsesConstants } from '../../constants/smart-responses.constants';
import { SmartResponsesHelper } from '../../utils/smart-responses-helper';
import { getSelectedChatId, getSelectedChatSelectedMessageId, getSelectedChatTextEditorContent, getSelectedMessageIdSuggestions } from '../chat.selectors';
import { SmartResponseComponentDisplayType, SmartResponseType } from '../models/enums';
import { SelectedMessage } from '../models/selectedMessage';
import { SmartResponseStateNotificationVm } from '../models/smart-response-state-notification-vm';
import { SmartResponsesSearchVm } from '../models/smart-responses-search-vm';
import { Suggestion } from '../models/suggestion';
import { Placeholder } from '../placeholders/placeholder.model';
import { getAllPlaceholdersForSelectedChatId } from '../placeholders/placeholders.selectors';
import { fromScriptsSearch } from '../scripts';
import { fromChat } from '../selectors';
import { hasFeatureFlag } from '../settings/settings.selectors';
import { AppState } from '../state';
import { SmartResponses } from './smart-responses.model';
import * as fromSmartResponses from './smart-responses.reducer';

export const selectSmartResponsesFeature = createFeatureSelector<AppState, fromSmartResponses.State>(fromSmartResponses.featureKey);

const {
  selectEntities: selectSmartResponseEntities,
  selectAll: selectAllSmartResponses
} = fromSmartResponses.adapter.getSelectors(selectSmartResponsesFeature);


export const getAllSmartResponses = createSelector(
  selectAllSmartResponses,
  (smartResponses: SmartResponses[]) => smartResponses
);

export const getSmartResponsesByChatId = (chatId: string) => createSelector(
  getAllSmartResponses,
  (smartResponses: SmartResponses[]) => smartResponses.filter(s => s.chatId === chatId)
);

export const getSmartResponsesForSelectedChatMessage = createSelector(
  getSelectedChatId,
  getSelectedChatSelectedMessageId,
  selectSmartResponseEntities,
  (chatId: string, messageId: string, entities: Dictionary<SmartResponses>) => {
    const key = SmartResponsesHelper.getKey({chatId, messageId});
    return entities[key];
  }
);

export const getSmartResponsesByMessageId = (messageId: string) => createSelector(
  getSelectedChatId,
  selectSmartResponseEntities,
  (chatId: string, entities: Dictionary<SmartResponses>) => {
    const key = SmartResponsesHelper.getKey({chatId, messageId});
    return entities[key];
  }
);

export const getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage = createSelector(
  getSmartResponsesForSelectedChatMessage,
  getAllPlaceholdersForSelectedChatId,
  (smartResponses: SmartResponses, placeholders: Placeholder[]): SmartResponsesSearchVm => {
    return SmartResponsesHelper.getSearchSmartResponses(smartResponses, placeholders);
  }
);

export const getSmartResponsesWithUpdatedPlaceholdersByMessageId = (messageId: string) => createSelector(
  getSmartResponsesByMessageId(messageId),
  getAllPlaceholdersForSelectedChatId,
  (smartResponses: SmartResponses, placeholders: Placeholder[]): SmartResponsesSearchVm => {
    return SmartResponsesHelper.getSearchSmartResponses(smartResponses, placeholders);
  }
);

export const filterValidSearchTerm = (filterText: string) => {
  return fromScriptsSearch.isSearchTermValid(filterText) ? filterText : null;
};
export const splitSearchText = (filterText: string) => {
  return filterText?.split(/\s+|\b/)?.filter(t => fromScriptsSearch.isSearchTermValid(t)) ?? [];
};

export const selectSmartResponseSearchText = createSelector(
  getSelectedChatTextEditorContent,
  (filterText: string): string => filterValidSearchTerm(filterText)
);

export const selectSmartResponseSplitSearchText = createSelector(
  selectSmartResponseSearchText,
  (filterText: string): string[] => splitSearchText(filterText)
);

export const isUnfilteredSmartResponsesEmpty = createSelector(
  getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage,
  getSelectedMessageIdSuggestions,
  (smartResponsesObject: SmartResponses, suggestions: Suggestion[]): boolean => !smartResponsesObject?.smartResponses?.length && !suggestions?.length
);

export const getFilteredSmartResponsesForSelectedChatMessage = createSelector(
  getSmartResponsesWithUpdatedPlaceholdersForSelectedChatMessage,
  selectSmartResponseSplitSearchText,
  hasFeatureFlag(FeatureFlags.SmartResponsesAppliedAi),
  (smartResponses: SmartResponses, filterTexts: string[], showAppliedAiResponses: boolean): SmartResponses =>
    SmartResponsesHelper.getFilteredSmartResponses(smartResponses, filterTexts, true, showAppliedAiResponses ? SmartResponseType.AppliedAi : SmartResponseType.Nuance)
);

export const getSmartResponsesWithKey = (chatId: string, messageId: string) => createSelector(
  selectSmartResponseEntities,
  (entities: Dictionary<SmartResponses>) => {
    const key = SmartResponsesHelper.getKey({chatId, messageId});
    return entities[key];
  }
);

export const getSmartResponseComponentDisplayType = createSelector(
  fromChat.getSelectedChatSelectedMessage,
  getFilteredSmartResponsesForSelectedChatMessage,
  getSelectedMessageIdSuggestions,
  (selectedMessage: SelectedMessage, smartResponsesObject: SmartResponses, suggestions: Suggestion[]): SmartResponseComponentDisplayType => {
    return SmartResponsesHelper.getSmartResponseComponentDisplayType(selectedMessage, smartResponsesObject, suggestions);
  }
);

export const getSmartResponseStateNotificationVm = createSelector(
  fromChat.getIsSelectedChatSelectedMessageHistoric,
  isUnfilteredSmartResponsesEmpty,
  (isHistoric: boolean, isUnfilteredListEmpty: boolean): SmartResponseStateNotificationVm => {
    if (isHistoric){
      return SmartResponsesConstants.notifications.historic;
    }
    else if (isUnfilteredListEmpty) {
      return SmartResponsesConstants.notifications.empty;
    }
    else {
      return SmartResponsesConstants.notifications.filteredEmpty;
    }
  }
);
