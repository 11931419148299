// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TimeHelperService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { CustomerDetails } from 'projects/entities/src/lib/domain/models/customerDetails';
import { fromChat } from 'projects/entities/src/lib/domain/selectors';
import { AppState, ChannelType, ChatHelper, ChatInteraction, ChatMember, ChatMessageType, TimeZone } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'merc-convo-message',
  templateUrl: './convoMessage.component.html',
  styleUrls: ['./convoMessage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvoMessageComponent implements OnInit{
  @Input() chatMessage: ChatInteraction;
  @Input() previousMessage: ChatInteraction;
  @Input() participants: ChatMember[];
  @Input() chatId: string;
  @Input() channelType: ChannelType;
  @Input() colorId: number;
  @Input() timeZone: TimeZone;
  @Input() customerDetails: CustomerDetails;

  isSelectedMessageId$: Observable<boolean>;
  canSelectMessage$: Observable<boolean>;

  constructor(
    private timeHelper: TimeHelperService,
    protected store: Store<AppState>
  )
  {
  }

  ngOnInit() {
    this.isSelectedMessageId$ = this.store.select(fromChat.isSelectedMessageId(this.chatId, this.chatMessage.messageId));
    this.canSelectMessage$ = this.store.select(fromChat.canSelectChatMessage);
  }

  getFormattedTime(unix: number): string {
    return this.timeHelper.formatTimeTwoHourDigitSeconds(unix, this.timeZone?.name);
  }

  getFormattedDate(unix: number): string {
    return this.timeHelper.formatUnix(unix, 'MMMM D, YYYY');
  }

  isXaResponse(type: ChatMessageType): boolean {
    return type === ChatMessageType.XaSuggestion;
  }

  get isSystemMessage(): boolean {
    return ChatHelper.isSystemMessage(this.chatMessage);
  }
}
