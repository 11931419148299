// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, ViewEncapsulation, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginBannerHelper } from 'projects/entities/src/lib/utils/loginBannerHelper';
import { AuthenticationProviderTypes, AgentStatePersisterService } from 'projects/entities/src/public_api';
import { MercuryRoutes } from '../../constants/constants';
import { BannerContent } from 'projects/entities/src/lib/domain/models/bannerContent';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'merc-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoLoginComponent implements OnInit {
  bannerContent$: Observable<BannerContent>;

  constructor(
    private router: Router,
    private statePersister: AgentStatePersisterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // get query string for any banner content
    this.bannerContent$ = this.route.queryParams.pipe(map(params => {
      if (params && params.bannerReason) {
        return LoginBannerHelper.getBannerContentByQueryString(params);
      }
    }));
  }

  onLoginClick() {
    const authenticationType = environment.auth.useComcastSso ? AuthenticationProviderTypes.ComcastNuanceSso : AuthenticationProviderTypes.NuanceSso;
    this.statePersister.storeAuthenticationProvider(authenticationType);
    
    this.statePersister.clearRegisteredId();
    this.statePersister.clearSelectedGroupIds();
    window.location.href = MercuryRoutes.Home;
  }

  switchToLogin(){
    this.router.navigate([MercuryRoutes.FormLogin]);
  }
}
