// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable, NgZone } from '@angular/core';
import { DayTimeService, TimeHelperService, TimerFactoryService, BrowserWindowService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { ItgActionText } from 'projects/entities/src/lib/constants/itg-configuration.constants';
import { CustomerActivityTimerDetails } from 'projects/entities/src/lib/domain/models/customer-activity-timer-details';
import { BounceTimerType, ChannelType, DataCollectionState, DeviceType} from 'projects/entities/src/lib/domain/models/enums';
import { ItgMetadata } from 'projects/entities/src/lib/domain/models/itgMetadata';
import { LogInfo, LogType } from 'projects/entities/src/lib/domain/models/LogTypeInterfaces';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { AppState, BounceTimerDetail, Channel, Chat, ChatActions, ChatAutomationSettings, ChatContext, ChatHelper, ChatInteraction, ChatMessage, ChatMessageType, ChatOperations, ChatUiActions, CustomerActivityStatus, LogActions, SenderType, UiActions } from 'projects/entities/src/public_api';
import { filter, take } from 'rxjs/operators';
import { MercuryNotificationService } from 'src/app/services/mercury-notification.service';
import { SubscriberService } from 'src/app/subscribed-container';
import { StringUtils } from 'src/app/utils/string-helper';
import { DESKTOP_TEXT, DESKTOP_ICON_PATH, MOBILE_TEXT, MOBILE_ICON_PATH, SMS_TEXT, SMS_ICON_PATH, WEB_TEXT, WEB_ICON_PATH } from './constants';
import { ConvoMedium } from './convo-medium';

@Injectable({
  providedIn: 'root'
})
export class ConvoCardService extends SubscriberService {
  
  engagementInactiveWarningCountdownDuration: number;
  private _chatContexts: ChatContext[];

  constructor(
    private store: Store<AppState>,
    private ngZone: NgZone,
    private timerFactory: TimerFactoryService,
    private timeService: DayTimeService,
    private timeFormatter: TimeHelperService,
    private browserWindowService: BrowserWindowService,
    private mercuryNotificationService: MercuryNotificationService
  )
  {
    super();
    this._chatContexts = [];
    this.engagementInactiveWarningCountdownDuration = 0;
    const chatsSub = this.store.select((fromChat.getChats)).subscribe((chats: Chat[]) => {
      if (this._chatContexts.length > chats.length){
        this._cleanUpContexts(chats);
      }
    });
    this.subscriptions.push(chatsSub);


    const inactiveWarningCountdownDurationSub = this.store.select((fromSettings.getEngagementInactiveWarningCountdownDuration))
    .subscribe((time: number) => {
      this.engagementInactiveWarningCountdownDuration = time;
    });
    this.subscriptions.push(inactiveWarningCountdownDurationSub);    
  }

  private _cleanUpContexts(chats: Chat[]){
    const toDispose = this._chatContexts.filter(context => !chats.some(c => c.chatId === context.chat.chatId));

    this._chatContexts = this._chatContexts.filter(context => chats.some(c => c.chatId === context.chat.chatId));

    toDispose.forEach(context => context.dispose());
  }

  public getPinnedTooltipText(customerActivityTimerValue: number): string {
    const time = this.timeFormatter.formatTime(customerActivityTimerValue);
    return `Remaining active for ${time}`;
  }

  public getUnpinnedTooltipText(pinDurationConfiguration?: number): string {
    let minutes = 5;
    if (pinDurationConfiguration) {
      minutes = Math.floor(pinDurationConfiguration / 60);
    }
    return `Keep active for ${minutes} minutes`;
  }

  public getCustomerActivityStatusPreviewText(chat: Chat, automationSettings: ChatAutomationSettings, warningIndex: number, status: CustomerActivityStatus): string {
    if (automationSettings && !chat.isAsync) {
      const warningCount = automationSettings?.warnings
        ? automationSettings.warnings.length + 1
        : 0;
      switch (status) {
        case CustomerActivityStatus.Inactive:
          return `<i>Auto-close warning 1 of ${warningCount}</i>`;
        case CustomerActivityStatus.InactiveWarning:
          return `<i>Auto-close warning ${warningIndex + 2} of ${warningCount}</i>`;
      }
    }
    if (status === CustomerActivityStatus.InactiveClosing){
      return '<i>Auto-closing conversation...</i>';
    }
    return null;
  }

  public getPreviewText(chat: Chat, status: CustomerActivityStatus, lastMessage: ChatMessage, automationSettings: ChatAutomationSettings, warningIndex: number, isItgRunning: ItgMetadata, secureDataCollectionState: DataCollectionState, hasLanguageTranslatorFeatureFlag: boolean): string {
    let previewText = this.getCustomerActivityStatusPreviewText(chat, automationSettings, warningIndex, status);

    if (isItgRunning) {
      // Running ITG should take over the convo card text
      previewText = `${isItgRunning.title} in progress`;
    }
    else if (secureDataCollectionState === DataCollectionState.Available){
      previewText = 'Secure data link available';
    }
    else if (!previewText && lastMessage) {
      if (lastMessage.type === ChatMessageType.Image) {
        previewText = 'Image';
      } else if (lastMessage.type === ChatMessageType.ItgAction && lastMessage.message.indexOf(ItgActionText.Completed) === 0 ) {
        previewText = `${lastMessage.title} complete`;
      }
      else {
        const translatedText = lastMessage.translationData?.translatedMessage;
        const useTranslatedText = ChatHelper.shouldUseTranslatedText(hasLanguageTranslatorFeatureFlag, lastMessage.sender, translatedText, lastMessage.translationData?.hasError);
        previewText = useTranslatedText ? translatedText : lastMessage.message;
        previewText?.replace(/(<([^>]+)>)/gi, '');

        if (lastMessage.sender === SenderType.FixAgent || lastMessage.sender === SenderType.System) {
          previewText = `You: ${previewText}`;
        }
      }
    }


    previewText = StringUtils.truncate(previewText, 50);
    return previewText;
  }

  public getConvoMediums(channel: Channel, deviceType: DeviceType): ConvoMedium[] {
    const mediums: ConvoMedium[] = [];

    if (deviceType === DeviceType.Desktop
      || (deviceType === DeviceType.Unknown && channel.type === ChannelType.WebChat)) {
      mediums.push({
        text: DESKTOP_TEXT,
        iconPath: DESKTOP_ICON_PATH
      });
    }
    else if (deviceType === (DeviceType.Phone || DeviceType.Tablet) || channel.type === ChannelType.Sms) {
      mediums.push({
        text: MOBILE_TEXT,
        iconPath: MOBILE_ICON_PATH
      });
    }

    if (channel.type === ChannelType.Sms) {
      mediums.push({
        text: SMS_TEXT,
        iconPath: SMS_ICON_PATH
      });
    }

    if (deviceType === (DeviceType.Desktop || DeviceType.Tablet) || channel.type === ChannelType.WebChat) {
      mediums.push({
        text: WEB_TEXT,
        iconPath: WEB_ICON_PATH
      });
    }

    return mediums;
  }

  public getChatContext(chat: Chat, chatAutomationEnabled: boolean): ChatContext{
    let context = this._chatContexts.find(c => c.chat.chatId === chat.chatId);
    if (!context){
      context = this.createChatContext(chat, chatAutomationEnabled);
      this._chatContexts.push(context);
    }

    return context;
  }

  private createChatContext(chat: Chat, chatAutomationEnabled: boolean): ChatContext {
    const context = new ChatContext();
    context.chat = chat;
    const chatDurTimer = this.timerFactory.createIncrementingTimer(this.ngZone);
    context.chatDurationTimer = chatDurTimer;
    chatDurTimer.start(this.timeService.unix() - chat.startTimestamp);
    
    this.createAgentResponseTimer(chat, context); 

    if (chat.autoTransfer && !this.hasAgentResponded(chat)) {
      this.createBounceTimer(chat, context);
      context.bounceTimer.start(0, chat.autoTransferSeconds);
    }

    if (chat.autoCloseChat) {
      this.createAutoCloseTimer(chat, context);
    }

    if (chatAutomationEnabled){
      this.createCustomerActivityTimer(chat, context);
    }

    return context;
  }

  private createAgentResponseTimer(chat: Chat, context: ChatContext){
    const agentTimer = this.timerFactory.createIncrementingTimer(this.ngZone);
    if (chat.agentResponseMaxSlaInSeconds && chat.agentResponseMidSlaInSeconds){
    const agentTimerLog = agentTimer.value.subscribe((value: number) => {
      const logPayload: LogInfo = {
        logType: LogType.chat,
        operationName: ChatOperations.AgentInactive,
        chat: context.chat,
        data: { 
          agentResponseMidSlaInSeconds: context.chat.agentResponseMidSlaInSeconds,
          agentResponseMaxSlaInSeconds: context.chat.agentResponseMaxSlaInSeconds     
        }
      };
      if (value === chat.agentResponseMidSlaInSeconds) {
        if (!chat.isAsync) {
          if (!this.browserWindowService.windowHasFocus){
            this.mercuryNotificationService.triggerCustomerAwaitingReplyNotifications(chat, 0);          
          }
          this.store.dispatch(ChatActions.AddCustomerAwaitingReplySuggestion({chatId: chat.chatId}));
        }
        logPayload.data.level = 'mid',
        this.store.dispatch(LogActions.logChatAction({logPayload}));
        }
        else if (value === chat.agentResponseMaxSlaInSeconds){
          if (!chat.isAsync){
            this.store.dispatch(ChatActions.TriggerCustomerAwaitingReplyNotification({chatId: chat.chatId, windowHasFocus: this.browserWindowService.windowHasFocus, value })); 
            this.store.dispatch(ChatActions.AddCustomerAwaitingReplySuggestion({chatId: chat.chatId}));
          }          
          logPayload.data.level = 'max',
          this.store.dispatch(LogActions.logChatAction({logPayload}));
        }
        
    }); 
      context.subscriptions.push(agentTimerLog);
  }
      context.agentResponseTimer = agentTimer;
  }


  private createBounceTimer(chat: Chat, context: ChatContext): void {
    const bounceTimer = this.timerFactory.createIncrementingTimer(this.ngZone);
    const bounceTimerExpiredSub = bounceTimer.timerExpired
      .subscribe(() => {
        this.store.dispatch(ChatActions.Bounce(chat.chatId));
      });
      context.subscriptions.push(bounceTimerExpiredSub);

    const bounceTimerSub = bounceTimer.value.subscribe((value: number) => {
      if (value === chat.autoTransferWarningSeconds) {
        const bounceTimerDetail = new BounceTimerDetail({
          type: BounceTimerType.Warning,
          chatId: chat.chatId
        });
        this.store.dispatch(UiActions.BounceTimerTriggered({ bounceTimerDetail }));
      }
    });
    context.subscriptions.push(bounceTimerSub);

    context.bounceTimer = bounceTimer;
  }

  private createAutoCloseTimer(chat: Chat, context: ChatContext): void {
    const autoCloseTimer = this.timerFactory.createIncrementingTimer(this.ngZone);

    const autoCloseTimerExpirySub = autoCloseTimer.timerExpired.subscribe(() => {
      this.store.dispatch(ChatActions.AutoClose({chatId: chat.chatId}));
    });
    context.subscriptions.push(autoCloseTimerExpirySub);

    context.autoCloseTimer = autoCloseTimer;

  }

  private createCustomerActivityTimer(chat: Chat, context: ChatContext): void {
    const customerActivityTimer = this.timerFactory.createDecrementingTimer(this.ngZone);
    const customerActivityTimerSub = customerActivityTimer.timerExpired.subscribe(() => {
      this.store.dispatch(ChatUiActions.nextCustomerActivityStatus({chatId: chat.chatId}));
    });
    context.subscriptions.push(customerActivityTimerSub);
    this.createEngagementInactiveWarningTimer(context);

    context.customerActivityTimer = customerActivityTimer;
  }

  private createEngagementInactiveWarningTimer(context: ChatContext): void {
    const engagementInactiveWarningTimer = this.timerFactory.createDecrementingTimer(this.ngZone);
    context.engagementInactiveWarningTimer = engagementInactiveWarningTimer;
  }

  public startCustomerActivityTimer(context: ChatContext, timerDetails: CustomerActivityTimerDetails){
    const {customerActivityStatus, duration, activityTimestamp} = timerDetails;
    const currentTime = this.timeService.unix();
    const isCustomerActivityExists: boolean = (activityTimestamp && activityTimestamp !== 0);
    const lastCustomerActivityTimestamp = isCustomerActivityExists ? activityTimestamp : currentTime;
    const startValue = duration - (currentTime - lastCustomerActivityTimestamp);

    if (context.engagementInactiveWarningCountdownSubscription) { context.engagementInactiveWarningCountdownSubscription.unsubscribe(); }
    context.customerActivityTimer.start(startValue);

    if (customerActivityStatus === CustomerActivityStatus.Active && context.engagementInactiveWarningTimer) {
      // When the customer State is active & timer is started
      // the engagementInactiveWarning should start counting down
      // when the time remaining matches the engagementInactiveWarningDuration, as its counting down and hits that value
      // timeRemaining < duration, for refresh edge case, to start at the amount of time remaining
      context.engagementInactiveWarningCountdownSubscription = context.customerActivityTimer.timeRemaining
        .pipe(
          filter(timeRemaining => timeRemaining <= this.engagementInactiveWarningCountdownDuration),
          take(1)
        )
        .subscribe((timeRemaining: number) => {
          if (!context.engagementInactiveWarningTimer.isRunning){
            context.engagementInactiveWarningTimer.start(timeRemaining);
            this.ngZone.run((() => {
              // dispatch inside zone because timer subscriptions running outside of angular zone
              this.store.dispatch(ChatUiActions.showInactiveWarningPinTimer({chatId: context.chat.chatId}));
            }));
          }
        });
    }
  }

  public getResponseTimerStartValue(messages: ChatInteraction[]): number {
    let response = -1;
    let latestMessage: ChatInteraction;
    let customerMsgFound: boolean = false;

    for (let i = 1; i <= messages.length; i++) {
      latestMessage = messages[messages.length - i];
      if (typeof latestMessage.sender !== 'undefined' && latestMessage.sender === SenderType.Requester) {
        customerMsgFound = true;
        response = this.getTimeSinceMessage(latestMessage);
      } else if (typeof latestMessage.sender !== 'undefined' && latestMessage.sender === SenderType.FixAgent) {
        return !customerMsgFound ? -1 : response;
      } else if (latestMessage.type === ChatMessageType.TransferCard || latestMessage.type === ChatMessageType.SummaryCard) { // This is for TransferCard and SummaryCard on transfer - timer will start with 0
        return this.getTimeSinceMessage(latestMessage);
      }
    }
    return response;
  }

  private getTimeSinceMessage(message: ChatInteraction): number {
    // don't return negative number (was happening occasionally, probably due to clock skew, and would prevent timer from starting)
    return Math.max(0, this.timeService.unix() - message.timestamp);
  }

  private hasAgentResponded(chat: Chat): boolean {
    const reverseMessages = chat.messages.slice().reverse();
    const length = reverseMessages.findIndex(m =>  m.type === ChatMessageType.TransferCard);
    const splicedChatMessage = reverseMessages.splice(0, length);
    const messagesToSearch = splicedChatMessage.length > 0 ? splicedChatMessage : chat.messages;
    return messagesToSearch.find(msg => msg.sender === SenderType.FixAgent) ? true : false;
  }
}
