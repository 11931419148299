// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { PlayAudioOptions } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { AppState, ProfileActions } from 'projects/entities/src/public_api';

@Component({
  selector: 'merc-sound-bar',
  templateUrl: './sound-bar.component.html',
  styleUrls: ['./sound-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoundBarComponent implements OnInit {
  testVolume: number;
  @ViewChild('volumeSlider') inputElem: ElementRef;
  @Input() testButton: boolean = false;
  @Input() volume: number;
  @Input() isMuted: boolean;
  @Output() volumeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() volumeMuted: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    protected store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.testVolume = this.volume;
  }

  handleChange(event) {
    const volume = Number(event.target.value); 
    this.testVolume = volume;
    this.volumeChanged.emit(volume);
  }

  updateSlider(isMuted){
    if (isMuted) {
      this.inputElem.nativeElement.value = '0';
    } else {
      this.inputElem.nativeElement.value = String(this.volume);
    }
  }

  toggleMute(isMuted) {
    const mute = !isMuted;
    this.volumeMuted.emit(mute);
    this.updateSlider(mute);
  }

  playTestSound() {
    // must convert volume from 0-100 scale to 0-2 scale
    // 0 is mute
    // 50 is 100% volume
    // 100 is 200% volume
    const testVolume = this.isMuted ? 0 : this.testVolume / 50;
    const audioOptions: PlayAudioOptions = {
      volume: testVolume,
      ignoreVolumeOverride: true
    };
    this.store.dispatch(ProfileActions.testVolume({audioOptions: audioOptions}));
  }
}