<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<button
  *ngIf="smartResponse"
  class="cee-btn cee-btn--response"
  (click)="handleResponseClicked($event)"
  (dblclick)="responseDoubleClicked($event)"
  [disabled]="disabled"
  [attr.id]="smartResponse.id"
>
  <merc-highlighter
    [textToHighlight]="smartResponse.value"
    [highlightCustomClass]="highlightCustomClass"
    [replacements]="highlightOptions"
    [searchText]="searchText"
  ></merc-highlighter>
</button>
