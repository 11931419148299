// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { NgEntityEffects } from '../domain/effects';
import { NgCoreModule } from '@cxt-cee-chat/merc-ng-core';
import { MercEffects_Auth } from '../domain/auth.effects';
import { MercEffects_Hubs } from '../domain/hubs.effects';
import { MercEffects_Availability } from '../domain/availability.effects';
import { MercEffects_Chat } from '../domain/chat.effects';
import { MercEffects_Scripts } from '../domain/scripts.effects';
import { MercEffects_Profile } from '../domain/profile.effects';
import { MercEffects_Agent } from '../domain/agent/agent.effects';
import { MercEffects_Profanity } from '../domain/profanity.effects';
import { MercEffects_Services } from '../domain/services.effects';
import { MercEffects_App } from '../domain/app.effects';
import { ChatUiEffects } from '../domain/chat-ui/chat-ui-effects';
import {MercEffects_Log} from '../domain/log.effects';
import { ScriptEffects } from '../domain/scripts/script.effects';
import { SettingsEffects } from '../domain/settings/settings.effects';
import { UiEffects } from '../domain/ui/ui.effects';
import { SmartResponsesEffects } from '../domain/smart-responses/smart-responses.effects';
import { PlaceholdersEffects } from '../domain/placeholders/placeholders.effects';
import { MercEffects_Call } from '../domain/voice/call/call.effects';
import { VoiceUiEffects } from '../domain/voice/ui/ui.effects';
import { CxGptResponsesEffects } from '../domain/cxGptResponses/cx-gpt-responses.effects';
import { AskMeAnythingEffects } from '../domain/ask-me-anything/ask-me-anything.effects';
import { LanguageTranslationsEffects } from '../domain/language-translation/language-translation.effects';
import { AgentFacingMetricsEffects } from '../domain/agent-facing-metrics/agent-facing-metrics.effects';

export const NG_ENTITY_MODULE_IMPORTS = [
	CommonModule,
	EffectsModule.forFeature([
		NgEntityEffects,
		MercEffects_Auth,
		MercEffects_Hubs,
		MercEffects_Agent,
		MercEffects_Availability,
		MercEffects_Call,
		MercEffects_Chat,
		MercEffects_Scripts,
		MercEffects_Services,
		MercEffects_Profile,
		MercEffects_Profanity,
		MercEffects_App,
		MercEffects_Log,
		ChatUiEffects,
		ScriptEffects,
		SettingsEffects,
		UiEffects,
		SmartResponsesEffects,
		PlaceholdersEffects,
		VoiceUiEffects,
		CxGptResponsesEffects,
		LanguageTranslationsEffects,
		AskMeAnythingEffects,
		AgentFacingMetricsEffects
	]),
	NgCoreModule,
];
