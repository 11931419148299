// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { CheckboxDetails } from 'projects/entities/src/lib/domain/models/checkboxDetails';
import { QualityCheckFollowupResponse } from 'projects/entities/src/lib/domain/models/qualityCheckFollowupResponse';
import { fromSettings, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { AppState, ProfanityService, SettingsActions, UiActions } from 'projects/entities/src/public_api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'merc-rate-connection-followup',
  templateUrl: './rate-connection-followup.component.html',
  styleUrls: ['./rate-connection-followup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RateConnectionFollowUpComponent implements OnInit {
  @Input() chatId: string;
  @Input() showCloseButton: boolean = false;
  feedback: any = [];
  submitDisabled: boolean = false;
  showTextArea: boolean;
  otherDescription: string = '';
  showErrorMessage: boolean = false;
  additionalFeedbackEnabled$: Observable<boolean>;
  isModal$: Observable<boolean>;
  options$: Observable<CheckboxDetails[]>;
  @Output() closeFeedback: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private store: Store<AppState>,
    private profanityService: ProfanityService
  ) {
  }

  ngOnInit() {
    this.isModal$ = this.store.select(fromUi.getShowRateConnectionFollowUpModal);
    this.additionalFeedbackEnabled$ = this.store.select(fromUi.getEnableRateConnectionAdditionalFeedback);
    this.options$ = this.store.select(fromSettings.getQualityCheckConfiguration).pipe(map(data => {
      if (data?.qualityCheckOptions) {
        return this.formatOptions(data.qualityCheckOptions);
      }
    }));
  }

  formatOptions(options) {
    const formatted = [];
    options.forEach(option => {
      const id = option.split(' ').join('-').toLowerCase();
      const formattedOption: CheckboxDetails = {
        value: id,
        label: option
      };
      formatted.push(formattedOption);
    });
    return formatted;
  }

  onCloseFeedback() {
    this.closeFeedback.emit(true);
  }

  updateFeedback(event) {
    if (event.target.checked) {
      this.feedback.push(event.target.value);
    } else {
      this.feedback = this.feedback.filter(reason => reason !== event.target.value);
    }
  }

  updateShowTextArea(event) {
    this.showTextArea = event.target.checked;
    this.otherDescription = !event.target.checked ? '' : this.otherDescription;
    this.updateFeedback(event);
  }

  isValidDescription() {
    const profanity = this.profanityService.test(this.otherDescription);
    const hasProfanity = profanity.length > 0;
    this.submitDisabled = hasProfanity;
    this.showErrorMessage = hasProfanity;
  }

  sendFeedback() {
    if (this.feedback.length){
      const qualityCheckFollowupResponse: QualityCheckFollowupResponse = {
        reasons: this.feedback,
        chatId: this.chatId,
        description: this.otherDescription
      };
  
      this.store.dispatch(SettingsActions.sendQualityCheckFollowupResponse({qualityCheckFollowupResponse, qualityCheckDateTime: new Date()}));
    }
    
    this.store.dispatch(UiActions.SettingsConnectionRatingSubmitted());
    this.onCloseFeedback();
  }
}
