<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-template #innerTemplate>

  <h2 class="cee-h2 merc-agent-settings-tab-header">Display</h2>
  <div class="merc-agent-settings-tab-body">
    <merc-settings-banner></merc-settings-banner>
    <div class="cee-form-row--group">
      <input
        id="display-tab-page-nav"
        type="checkbox"
        name="display-tab-page-nav"
        [(ngModel)]="settings.showPageNavigation"
        (ngModelChange)="onSettingsModelChange()"
        class="cee-checkbox"
        data-qa="show-page-navigation"
      />
      <label
        class="cee-checkbox-label"
        for="display-tab-page-nav"
        >Show page navigation</label>
    </div>
    <div class="cee-form-row--group">
      <input
        id="display-tab-animation"
        type="checkbox"
        name="display-tab-animation"
        [(ngModel)]="!settings.animationsOff"
        (ngModelChange)="onAnimationSettingsModelChange()"
        class="cee-checkbox"
        data-qa="show-animations"
      />
      <label
        class="cee-checkbox-label"
        for="display-tab-animation"
        >Show animations</label>
    </div>
    <div class="cee-form-row--group">
      <input
        id="display-tab-cust-action"
        type="checkbox"
        name="display-tab-cust-action"
        [(ngModel)]="!settings.hideCustomerActions"
        (ngModelChange)="onCustomerActionsSettingsModelChange()"
        class="cee-checkbox"
      />
      <label
        class="cee-checkbox-label"
        for="display-tab-cust-action"
        >Show customer actions</label>
    </div>

    <h2 class="cee-h2">Zoom and font size</h2>
    <div class="merc-agent-settings-tab-section">
      <table class="merc-agent-settings-table">
        <tr>
          <th>Make everything larger</th>
          <th>Make everything smaller</th>
        </tr>
        <tr>
          <td>
            <b>Press Ctrl and +</b>
          </td>
          <td>
            <b>Press Ctrl and -</b>
          </td>
        </tr>
      </table>
      <table style="margin-top: 16px;" class="merc-agent-settings-table">
        <tr>
          <th>Font size</th>
        </tr>
        <tr>
          <td>
            <span>To increase or decrease text-size only in Chrome, at the top right of the browser, click:</span>
            <br>
            <span>
              <svg class="cee-icon">
                <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-kebab'"></use>
              </svg>
              <b> > Settings > Appearance > Change font size</b>
            </span>
            <br>
            <span>Next to "Font size," click the Down arrow.</span>
          </td>

        </tr>
      </table>

    </div>
    <h2 class="cee-h2">Theme</h2>

    <div class="cee-form-row">
      <div class="theme-option">
        <button class="button-theme" (click)="onChangeTheme('light')">
            <svg>
              <use [attr.xlink:href]="'/assets/img/mercury-sprite.svg#settings-light-theme'"></use>
            </svg>
        </button>
        <div class="cee-form-row--group">
          <input
            id="display-tab-theme-light"
            type="radio"
            name="display-tab-theme"
            value="light"
            [(ngModel)]="settings.theme"
            (ngModelChange)="onSettingsModelChange()"
            class="cee-radio"
            data-qa="light-theme"
          />
          <label
            class="cee-radio-label"
            for="display-tab-theme-light">

            Light theme
          </label>
        </div>
      </div>
      <div class="theme-option">
        <button class="button-theme" (click)="onChangeTheme('dark')">
            <svg>
              <use [attr.xlink:href]="'/assets/img/mercury-sprite.svg#settings-dark-theme'"></use>
            </svg>
        </button>
        <div class="cee-form-row--group">
          <input
            id="display-tab-theme-dark"
            type="radio"
            name="display-tab-theme"
            value="dark"
            [(ngModel)]="settings.theme"
            (ngModelChange)="onSettingsModelChange()"
            class="cee-radio"
            data-qa="dark-theme"
          />
          <label
            class="cee-radio-label"
            for="display-tab-theme-dark">
            Dark theme
          </label>
        </div>
      </div>
    </div>

    <h2 class="cee-h2">Shortcut to send response without editing</h2>

    <div class="cee-form-row">
        <div class="cee-form-row--group">
        <input
          id="display-tab-scripts-shift"
          type="radio"
          name="display-tab-scripts-send"
          [value]="0"
          [(ngModel)]="settings.scriptQuickSend"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-radio"
          data-qa="shift-click"
        />
        <label
          class="cee-radio-label"
          for="display-tab-scripts-shift">
          Shift + click
        </label>
      </div>
      <div class="cee-form-row--group">
        <input
          id="display-tab-scripts-double"
          type="radio"
          name="display-tab-scripts-send"
          [value]="1"
          [(ngModel)]="settings.scriptQuickSend"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-radio"
          data-qa="double-click"
        />
        <label
          class="cee-radio-label"
          for="display-tab-scripts-double">
          Double click
        </label>
      </div>
    </div>


  </div>

</ng-template>
