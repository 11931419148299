<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  *ngIf="{
    isFeedbackSubmitted: (isFeedbackSubmitted$ | async),
    chatColorId: (chatColorId$ | async),
    customerName: (customerName$ | async),
    chat: (chat$ | async),
    dismissedSuggestion: (dismissedSuggestion$ | async),
    suggestionTitle: (suggestionTitle$ | async)
  } as data"
  [active]="true"
  [customModalClass]="'merc-dismiss-suggestion-feedback-modal'"
  [customBackdropClass]="'cee-modal-backdrop--hidden merc-dismiss-suggestion-feedback-backdrop'"
  (modalClose)="closeModal(data.isFeedbackSubmitted, data.chat)"
  [clickOutsideToClose]="false"
>
  <div class="merc-dismiss-suggestion-feedback" *ngIf="!data.isFeedbackSubmitted; else feedbackSubmittedMessage" data-qa="dismiss-suggestion-feedback">
    <b>Help us improve</b><br>
    <div class="merc-feedback-description">
      <span>Why did you decide not to use the {{ data.suggestionTitle ?? "suggestion" }} card for
        <div class="merc-customer-identification">
          <div class="merc-customer-block--{{ data.chatColorId }}"></div><b>{{ data.customerName }}</b>?
        </div>
      </span>
    </div>

    <div class="merc-feedback-option-buttons">
      <ng-container *ngFor="let option of feedbackOptions">
        <cee-button customClass="merc-feedback-option" (buttonClick)="submitFeedback(option, data.dismissedSuggestion)" dataQa="feedback-option">{{option}}</cee-button>
      </ng-container>
    </div>
  </div>

  <ng-template #feedbackSubmittedMessage>
    <div class="merc-dismiss-suggestion-feedback--submitted">
      <svg class="cee-icon cee-icon--md" data-qa="feedback-submitted-icon">
        <use href="/assets/img/svg-sprite.svg#icon-check-circle"></use>
      </svg>
      <span>Your feedback helps improve Mercury!</span>
    </div>
  </ng-template>
</cee-modal>
