// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { SenderType, ChatMessageType, SystemMessageType } from './enums';

export class ChatInteraction {
    chatId: string;
    conversationId?: string;
    timestamp: number;
    sender: SenderType;
    type: ChatMessageType;
    senderId?: string;
    notes?: string;
    queue?: IQueue;
    previousQueue?: IQueue;
    title?: string;
    systemType?: SystemMessageType;
    traceId?: string;
    messageId?: string;
    isHistoric?: boolean;
}

interface IQueue{
    id: string;
    name: string;
}
