<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-customer-info-heading"
  *ngIf="{
    customer: (customer$ | async),
    color: (color$ | async),
    chat: (chat$ | async),
    isAuthenticated: (isAuthenticated$ | async)
  } as data">
  <cee-customer-avatar
    [customerName]="data.customer?.name"
    [customerNumber]="data.color?.id">
  </cee-customer-avatar>
  <div class="flex-baseline">
    <div>
      <span class="customer-name">{{ data.customer?.name }}</span>
      <span class="customer-account-number" *ngIf="data.chat?.accountNumber">{{ data.chat?.accountNumber }}</span>
    </div>
    <div>
      <cee-button id="focusButton"
        *ngIf="data.chat?.accountNumber"
        type="link"
        class="einstein-focus"
        (click)="focusButtonClick(data.chat, data.isAuthenticated)"
        [customClass]="data.isAuthenticated ? '' : 'disabled'"
        [tooltipText]="data.isAuthenticated ? 'Einstein 360' : 'Unable to focus account. Please access in Einstein manually.'"
        tooltipDirection="bottom"
        [tooltipWidth]="data.isAuthenticated ? 100 : 190"
        [tooltipType]="data.isAuthenticated ? 'default' : 'error'" >
        <svg viewBox="0 0 16 13">
          <use xlink:href="/assets/img/svg-sprite.svg#icon-focus"></use>
        </svg>
        <span>Focus</span>
      </cee-button>
    </div>
  </div>
</div>
