// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'merc-cust-card-list',
  templateUrl: './cust-card-list.component.html',
  styleUrls: ['./cust-card-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustCardListComponent {
  @Input() listTitle: string;
  @Input() dataQaTitle: string;

  constructor(){}
}
