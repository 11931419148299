// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatInteractionRequest } from './requests/chat-interact-request';

export class MarkAsMercuryChatRequest extends ChatInteractionRequest {
    nuanceCustomerId: string;
    siteId: string;
}
