<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-container *ngIf="{
  chatId: chatId$ | async,
  searchId: searchId$ | async,
  showModal: showAskMeAnythingFeedbackModal$ | async
} as data">
  <cee-modal
    headerText="Provide Additional Feedback"
    [active]="data.showModal"
    [clickOutsideToClose]="true"
    (modalClose)="closeModal()"
  >
    <div *ngFor="let option of options" class="cee-form-row--group">
      <input
        id="{{ option.value }}"
        type="checkbox"
        name="{{ option.value }}"
        [checked]="option.checked"
        (change)="updateFeedback($event, option)"
        class="cee-checkbox"
      />
      <label class="cee-checkbox-label" for="{{ option.value }}">{{
        option.label
      }}</label>
    </div>
    <div class="merc-ask-me-anything-feedback-modal--textbox">
      <cee-textarea
        [placeholder]="'(Optional) Provide more details.'"
        (textChange)="onFeedbackChange($event)"
        [textInput]="optionalFeedback"
      ></cee-textarea>
    </div>
    <div class="merc-ask-me-anything-feedback-modal--submit cee-form-row">
      <cee-button
        id="ask-me-anything-submit-feedback"
        [type]="'primary'"
        (buttonClick)="submitFeedback(data.chatId, data.searchId, data.questionId)"
      >
        Submit Feedback
      </cee-button>
    </div>
  </cee-modal>
</ng-container>
