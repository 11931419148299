// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@cxt-cee-chat/merc-ng-core';
import { BannerTypes } from 'projects/entities/src/public_api';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'merc-header-environment-banner',
  templateUrl: './environment-banner.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./environment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentBannerComponent implements OnInit {
  bannerType: BannerTypes = BannerTypes.Info;
  active: boolean = false;
  reportBugUrl: string;

  constructor(
    private environmentService: EnvironmentService,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit() {
    if (!this.environmentService.isProd()) {
      this.active = true;
      this.reportBugUrl = this.appConfigService.uatBugTrackingUrl;
    }
  }
}
