// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatAutomationInactive } from './chatAutomationInactive';

export class ChatAutomationSettings {
  inactive?: ChatAutomationInactive;
  warnings?: ChatAutomationInactive[];
  abandonedAutoCloseThreshold?: number;
  abandonedAutoCloseMessagingDuration?: number;
  pinDuration?: number;
  pinningEnabled?: boolean;
}
