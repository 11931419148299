// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class ParserConstants {
	public static readonly TEXT_XML: string = 'text/xml';
	public static readonly XML_TEXT_NODE: string = '#text';
	public static readonly XML_COMMENT_NODE: string = '#comment';
	public static readonly OBJECT_TYPE: string = 'object';
	public static readonly UNDEFINED_TYPE: string = 'undefined';
	public static readonly XML_ATTRIBUTE_NODE: string = '@attributes';
}
