// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgentHelper } from '../utils/agentHelper';
import * as fromAgent from './agent.reducer';
import { SendSuggestion } from './models';
import { GetAgentAvailabilitySummaryResponse } from './models/responses/get-agent-availability-summary-response';
import { AppState } from './state';

export const selectAgentFeature = createFeatureSelector<
  AppState,
  fromAgent.State
>(fromAgent.featureKey);

export const getAgentGroups = createSelector(
  selectAgentFeature,
  (agent: fromAgent.State) => agent?.agentGroups
);

export const getBusinessUnits = createSelector(
  selectAgentFeature,
  (agent: fromAgent.State) => agent?.businessUnits
);

export const getSurveyPrompt = createSelector(
  selectAgentFeature,
  (agent: fromAgent.State) => agent?.surveyPrompt
);

export const getIsLockedOut = createSelector(
  selectAgentFeature,
  (agent: fromAgent.State) => agent?.isLockedOut
);

export const getIsAgentUnauthorized = createSelector(
  selectAgentFeature,
  (agent: fromAgent.State) => agent?.isAgentUnauthorized
);

export const getAvgEngagementHandleTime = createSelector(
  selectAgentFeature,
  (agent: fromAgent.State) => agent.avgEngagementHandleTime
);

export const
  getAvgInitialAgentResponseTime = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.avgInitialAgentResponseTime
  );

export const
  getCompletedChatCount = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.completedChatCount
  );

export const
  getEnableCustomScripts = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.enableCustomScripts
  );

export const
  getSelectedAgentGroups = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.selectedAgentGroups ?? fromAgent.initialState.selectedAgentGroups
  );

export const
  getAvailabilityOptions = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.availabilityOptions ?? fromAgent.initialState.availabilityOptions
  );

export const
  getSessionTimeoutInformation = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.sessionTimeoutInformation
  );

  export const
  getMaxChats = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.maxChats ?? 0
  );

  export const
  getMaxExtraChats = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.maxExtraChats ?? 0
  );

  export const
  getAgentAvailabilitySummary = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.agentAvailabilitySummary ?? []
  );

  export const selectAgentAvailabilityTableGroups = createSelector(
    getAgentAvailabilitySummary,
    (data: GetAgentAvailabilitySummaryResponse) => AgentHelper.formatAgentSummaryTableData(data)
  );

  export const
  getAgentSalesConversionMetrics = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.agentSalesConversionMetrics
  );

  export const getOfferFirstQualityCheck = createSelector(
    selectAgentFeature,
    (agent: fromAgent.State) => agent.offerFirstQualityCheck
  );

  export const getLastQualityCheckResponse = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.lastQualityCheckResponse
  );

  export const getLastQualityCheckFollowupResponse = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.lastQualityCheckFollowupResponse
  );

  export const getLastQualityCheckDate = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.lastQualityCheckDateTime ? new Date(state.lastQualityCheckDateTime) : null
  );

  export const getLastClosedChatId = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.lastClosedChatId
  );

  export const getLastDismissedSuggestion = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.lastDismissedSuggestion
  );

  export const getLastDismissedSuggestionChatId = createSelector(
    getLastDismissedSuggestion,
    (lastDismissedSuggestion: SendSuggestion) => lastDismissedSuggestion?.chatId
  );

  export const getLastDismissedSuggestionTitle = createSelector(
    getLastDismissedSuggestion,
    (lastDismissedSuggestion: SendSuggestion) => lastDismissedSuggestion?.title
  );

  export const getAgentNetworkId = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.agentNetworkId
  );
  
  export const getPernrId = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.pernrId
  );

  export const getFilteredPreviousChatsummaries = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.previousChatSummaries.filter(f => (f.customerName?.toLowerCase().includes(state.previousChatSummaryFilterText) || f.chatId?.toLowerCase().includes(state.previousChatSummaryFilterText)))
  );

  export const getSelectedChatSummary = createSelector(
    selectAgentFeature,    
    (state: fromAgent.State) => state.previousChatSummaries.find(f => f.chatId === state.selectedPreviousChatSummaryId)
  );

  export const getunusedSlotTimes = createSelector(
    selectAgentFeature,    
    (state: fromAgent.State) => state.unusedSlotTimes
  );

  export const getAgentAttributes = createSelector(
    selectAgentFeature,
    (state: fromAgent.State) => state.userAttributes
  );
  