// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService {
  private broadcastChannel: BroadcastChannel;
  private onMessage = new Subject<MessageEvent>();

  constructor(
    @Inject(BROADCAST_CHANNEL_INJECTION_TOKEN) channel: BroadcastChannel
  ) {
    this.broadcastChannel = channel;
    this.broadcastChannel.onmessage = (message: MessageEvent) =>
      this.onMessage.next(message);
  }

  publish(message: any): void {
    this.broadcastChannel.postMessage(message);
  }

  messages(): Observable<MessageEvent> {
    return this.onMessage.pipe();
  }

  close(): void {
    this.broadcastChannel.close();
  }
}

export const BROADCAST_CHANNEL_INJECTION_TOKEN = new InjectionToken<
  BroadcastChannel
>('BroadcastChannelServiceToken', {
  providedIn: 'root',
  factory: () => new BroadcastChannel('mercury-broadcast-channel')
});
