// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AgentGroup } from '../agentGroup';
import { MiddlewareResponse } from '../middlewareResponse';

export class AgentGroupsResponseBase extends MiddlewareResponse {
    public agentGroups: AgentGroup[];

    constructor(data: any) {
        super(data);

        if (data?.success) {
            this.agentGroups = [];
            if (data.agentGroups) {
                data.agentGroups.forEach(element => {
                    const ag = Object.assign(new AgentGroup(), element);
                    this.agentGroups.push(ag);
                });
            }
        }
    }
}
