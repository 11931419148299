// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { DayTimeService, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import { AppState, OneCtiActions } from 'projects/entities/src/public_api';
import { CtiRequests, CtiResponses } from '../constants/cti.constants';
import { MercuryCtiService } from './mercury-cti.service';

@Injectable({
  providedIn: 'root'
})
export class MercuryCtiChatService extends MercuryCtiService {
  constructor(protected ngEntityStore: Store<AppState>,
    protected timeService: DayTimeService,
    protected loggingFactory: LoggingFactoryService){
    super(ngEntityStore, timeService, loggingFactory);
      this.openCtiConnection$.subscribe((isConnected: boolean) => {
        this.ngEntityStore.dispatch(OneCtiActions.UpdateChatServiceConnection({isConnected}));
      });
  }

  public webSocketMessageEventHandler(messageEvent: MessageEvent) {
    super.webSocketMessageEventHandler(messageEvent);
    if (!messageEvent?.data) { return; }

    const eventData = JSON.parse(messageEvent.data);

    if (!eventData?.data || !eventData?.msg) { return; }

    const { data, msg: message } = eventData;

    const action = this.getOneCtiAction(message);

    if (!action) { return; }

    this.ngEntityStore.dispatch(action({ data, message }));
  }

  private getOneCtiAction(message: string) {
    switch (message) {
      case CtiRequests.ChatDialogWsRequest:
        return OneCtiActions.ChatDialogWsRequest;
      case CtiRequests.ChatDialogSecureCollectionInitiated:
        return OneCtiActions.ChatDialogSecureCollectionInitiated;
      case CtiRequests.ChatDialogSecureCollectionStatus:
        return OneCtiActions.ChatDialogSecureCollectionStatus;
      case CtiResponses.ChatDialogAppUpdate:
          return OneCtiActions.ChatDialogAppUpdateResponse;
      case CtiResponses.ChatDialogSecureCollectionStart:
        return OneCtiActions.ChatDialogSecureCollectionStartResponse;
      case CtiResponses.ChatDialogSecureCollectionCancel:
        return OneCtiActions.ChatDialogSecureCollectionCancelResponse;
      case CtiResponses.ChatDialogStart:
        return OneCtiActions.ChatDialogStartResponse;
      case CtiResponses.ChatDialogResume:
        return OneCtiActions.ChatDialogResumeResponse;
      case CtiResponses.ChatDialogStop:
        return OneCtiActions.ChatDialogStopResponse;
      default:
        return null;
    }
  }
}
