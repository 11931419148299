<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<cee-modal
  class="connect-account-modal"
  headerText="Connect Account"
  [active]="active"
  (modalClose)="closeModal()"
  [controls]="(controls$ | async)">
  <form class="form-connect-account" #form="ngForm">
    <div class="cee-form-row connect-account-number--instructions">
      <ng-container *ngIf="currentAccountNumber$ | async as currentAccountNumber">
        <p x-test-target="account-connected">
          Currently connected to:
          <svg class="cee-icon cee-icon--md icon-account-connected">
            <use [attr.href]="'/assets/img/svg-sprite.svg#icon-check-circle'"></use>
          </svg>
          <span class="cee-h4">{{currentAccountNumber}}</span>
          <button class="cee-btn cee-btn--link" type="button" (click)="onUnlink()" data-qa="disconnect">
            Disconnect
          </button>
        </p>
        <p x-test-target="account-connected">
          You can close this pop-up to stay connected to the currently selected account or enter a new account number below to begin connecting to a different account.
        </p>
      </ng-container>
      <ng-container *ngIf="unlinkSuccessful$ | async as unlinkSuccessful">
        <cee-banner
          *ngIf="unlinkSuccessful.success"
          id="login--error-banner"
          [type]="'success'"
          dataQa="unlink-successful"
        >
          Successfully disconnected from: <strong>{{ unlinkSuccessful.accountNumber }}</strong>
        </cee-banner>
      </ng-container>
      <ng-container *ngIf="!(currentAccountNumber$ | async)">
        <p x-test-target="no-account-connected">
          To connect a customer's account to the conversation, copy and paste or type their account number. You'll then be able to select one of the identities associated with that account.
        </p>
        <p x-test-target="no-account-connected">
          If the wrong account comes up, simply enter a new account number and hit Lookup.
        </p>
      </ng-container>
    </div>
    <div class="cee-form-row" [ngClass]="{'has-error': showErrorMessage}">
      <label
        class="cee-form-label" for="connect-account-number">
        Account Number
      </label>
      <input appDelayedFocusout 
        type="text"
        class="cee-form-input"
        id="connect-account-number"
        name="connect-account-number"
        placeholder="Enter account number"
        aria-describedBy="account-error-msg"
        [(ngModel)]="accountNumberInput"
        (delayedFocusout)="onAccountNumberInput_Blur()"
        (focusin)="onAccountNumberInput_Focus()"
        (input)="validateAccountNumber()"
        (keyup.enter)="onLookupAccount()"
        [disabled]="isLookingUpUsers"
        #accountNumberElm />
      <svg class="cee-icon cee-icon--md icon-account-length" *ngIf="validAccountNumber">
        <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-check-circle'"></use>
      </svg>
      <cee-button
        id="connect-account-number--lookup-users"
        [type]="'primary'"
        [disabled]="!validAccountNumber || isLookingUpUsers"
        (buttonClick)="onLookupAccount()">
        Lookup
      </cee-button>
      <cee-banner *ngIf="showErrorMessage"
        id="connect-account-number--error-banner"
        [type]="'error'"
        dataQa="connect-account-number-error">
        {{ errorMessage }}
      </cee-banner>
    </div>
    <div class="cee-form-row" *ngIf="(isLookingUpUsers || hasLookedUpUsers) && validatedAccountNumber !== '' && lookupAccountNumber === accountNumberInput">
      <cee-select
        name="account-identity"
        [options]="isLookingUpUsers ? [] : options"
        [label]="isLookingUpUsers ? 'Loading identities...' : 'Identities'"
        [selectedOption]="selectedUserId"
        (handleChange)="onIdentitySelect($event)"
        [disabled]="!validAccountNumber || isLookingUpUsers"
        class="cee-select--account-identities">
      </cee-select>
    </div>
    <div class="cee-form-row" *ngIf="selectedUserId === 'Other' && lookupAccountNumber === accountNumberInput">
      <div class="connect-modal-name">
        <label class="cee-form-label" for="connect-display-name">Full name</label>
        <input (keyup)="validateModalData()" type="text" class="cee-form-input" name="connect-display-name" id="connect-display-name" placeholder="Enter name" [(ngModel)]="displayNameInput"/>
      </div>
    </div>
  </form>
</cee-modal>
