// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ProfanityActions } from './actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { PageInitializationConstants } from '../constants/page-initialization.constants';
import { Injectable } from '@angular/core';
import { fromChat, fromProfanity } from './selectors';
import { ReportProfanity } from './models';
import { AppState } from './state';
import { FixAgentApiService } from '../services/fix-agent-api.service';
import { PageInitializationHelperService } from '../services/page-initialization-helper.service';
import { Store } from '@ngrx/store';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Profanity {
  constructor(
    private ngEntityStore: Store<AppState>,
    private actions: Actions,
    private fixAgentApiService: FixAgentApiService,
    private pageInitHelper: PageInitializationHelperService,
  ) { }

	loadProfanityList$ = createEffect(() =>
		this.actions.pipe(
			ofType(ProfanityActions.Load),
			withLatestFrom(
				this.ngEntityStore.select(fromProfanity.selectProfanityList)
			),
			tap(([_action, profanityList]) => {
				if (profanityList) {
					return;
				}
        this.pageInitHelper.updateStatePending(PageInitializationConstants.Profanity);
				const getProfanityListPromise = this.fixAgentApiService.getProfanityList();
				getProfanityListPromise.then(
					(response) => {
						this.ngEntityStore.dispatch(ProfanityActions.Loaded({ profanityList: response.body }));
					}
				);
				this.pageInitHelper.updatePageInitializationStateFromPromise(PageInitializationConstants.Profanity, getProfanityListPromise);
			})),
		{ dispatch: false }
	);

  reportProfanity$ = createEffect(() =>
		this.actions.pipe(
			ofType(ProfanityActions.reportIllegalWords),
			concatLatestFrom(({ chatId }) => [
				this.ngEntityStore.select(fromChat.getChat(chatId))
			]),
			tap(([{ chatId, message, illegalWords }, chat]) => {
				const { agentGroupId, businessUnitId, siteId } = chat || {};
				illegalWords.forEach(illegalWord => {
					const reportModel: ReportProfanity = {
						chatId,
						agentGroupId,
						businessUnitId,
						siteId,
						illegalWord,
						transcriptLine: message
					};

					this.fixAgentApiService.reportProfanity(reportModel);
				});
			})
		),
    { dispatch: false }
  );
}
