// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { WebSocketClient } from './websockets/WebSocketClient';
import { IWebSocketMessageModel } from './models/websocket-message.model';
import {ReplaySubject} from 'rxjs';
import { initialXmlToJsonParserConfig } from './models/xml-to-json-parser-config.model';
import { XmlToJsonParser } from './utils/xml-to-json.parser';
import {AppUtil} from './utils/app.util';
import { LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { AgentOperations, ErrorEvents, LogHelper } from 'projects/entities/src/public_api';

@Injectable({
    providedIn: 'root'
  })
export class CtiWebSocketService extends WebSocketClient {
    public messages$: Rx.ReplaySubject<any> = new ReplaySubject<IWebSocketMessageModel>();    
    private xmlToJsonParser: XmlToJsonParser = null;
    //private isCtiTransferable: boolean = false;
    //private isCtiCpniCompatible: boolean = false;
    public isCtiConnected: boolean = false;
    public disconnectReason: string = '';
    //private permissionService: PermissionService;
    private jsonMessage: any = null;
    private clientName: string;
    private isChatRegister: boolean;
    private wssUrl: string;
    messageEvent: MessageEvent = null;

    constructor(protected loggingFactory: LoggingFactoryService) {
        super();
        this.xmlToJsonParser = new XmlToJsonParser();
    }

    public initWebSocketConnection(wssUrl: string, clientName: string, isChatRegister: boolean = false) {
        try {
            this.clientName = clientName;
            this.isChatRegister = isChatRegister;
            this.disconnectReason = '';
            this.pushToCtiDebugLog('InitCTI: Init CTI');
            this.pushToCtiDebugLog('--- InvokeCTI: CTI Settings ---');
            if (!wssUrl) {
                wssUrl = 'wss://localhost:40003';
            }
            this.wssUrl = wssUrl;

            try {
                    this.connect(wssUrl);
                } catch (error) {
                    this.pushToCtiDebugLog(
                        'CtiWebSocketService : Exception on initializing conneciton : Error: ===> Message: ' +
                            error.message +
                            ' : Stack trace: ' +
                            error.stack
                    );
                    this.logTransaction(
                        'ACTIVATE',
                        'JSON:{"ActivateCTI":"Failed"}'
                    );
                    return error;
                }
            }
            catch (error) {
            this.pushToCtiDebugLog(
                'CtiWebSocketService : Exception on fetching configuration : Error: ===>  Message: ' +
                    error.message +
                    ' : Stack trace: ' +
                    error.stack
            );
            return error;
        }
    }

    public webSocketOpenEventHandler(openEvent: Event, wssUrl: string, isOpen: boolean) {
        AppUtil.log(
            'CtiWebSocketService : webSocketOpenEventHandler() : Opened WebSocket connection at ===> %o & OpenEvent ===> %o & isOpen ===> %o',
            wssUrl,
            openEvent,
            isOpen
        );
        this.pushToCtiDebugLog(
            'CtiWebSocketService : webSocketOpenEventHandler() : Opened WebSocket connection at ===> ' +
                wssUrl +
                ' & isOpen ===> ' +
                isOpen
        );
        this.logTransaction('ACTIVATE', 'JSON:{"ActivateCTI":"Success"}');
        if (true) {
            this.pushToCtiDebugLog('Registering with CTI');
            /**
             * Send Message to CTI
             */
            const wsClientRegisterData = this.getClientRegisterData();

            this.send(wsClientRegisterData);
            this.logTransaction('CLIENT_REGISTER', wsClientRegisterData);
        }
        this.isCtiConnected = isOpen;
        this.openCtiConnection$.next(isOpen);
    }
    private getClientRegisterData()
    {
        let data: string;
        if (this.isChatRegister)
        {
            data =
                JSON.stringify({
                msg: 'ChatRegister',
                data: { clientName: this.clientName, browser: navigator.appName, browserVersion: navigator.appVersion }
              });
        }
        else{
            data =
            `<WSClientRegister><METAData><ClientName>${this.clientName}</ClientName><Browser>Chrome</Browser><BrowserVersion>70</BrowserVersion></METAData></WSClientRegister>`;
        }
        return data;
    }
    public webSocketMessageEventHandler(messageEvent: MessageEvent) {
        AppUtil.log(
            'CtiWebSocketService : webSocketMessageEventHandler() : messageEvent ===> [%o]',
            messageEvent
        );
        this.pushToCtiDebugLog(
            'CtiWebSocketService : webSocketMessageEventHandler() : messageEvent : Received message'
        );
        this.validateWebSocketResponseMessage(messageEvent);
        this.messageEvent = messageEvent;
        // AppUtil.log("message event input:", this.messageEvent);
    }

    public webSocketErrorEventHandler(errorEvent: Event, isOpen: boolean) {
        LogHelper.logErrorEvent(this.loggingFactory, ErrorEvents.CtiConnectionError, { connection: this.wssUrl });
        AppUtil.log(
            'CtiWebSocketService : webSocketErrorEventHandler() : errorEvent ===> [%o] & isOpen ===> [%o]',
            errorEvent,
            isOpen
        );
        this.pushToCtiDebugLog(
            'CtiWebSocketService : webSocketMessageEventHandler() : errorEvent : Error occurred & isOpen: ' +
                isOpen
        );
        this.disconnect();
    }

    public webSocketCloseEventHandler(closeEvent: CloseEvent, isOpen: boolean) {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.CtiConnectionClosed, { connection: this.wssUrl });
        this.logTransaction('ACTIVATE', 'JSON:{"ActivateCTI":"Closed"}');
        AppUtil.log(
            'CtiWebSocketService : webSocketCloseEventHandler() : closeEvent ===> [%o] & isOpen ===> [%o]',
            closeEvent,
            isOpen
        );
        this.pushToCtiDebugLog(
            'CtiWebSocketService : webSocketMessageEventHandler() : closeEvent : Websocket Closed'
        );
        this.isCtiConnected = isOpen;        
        this.disconnectReason = this.getReason(closeEvent);   
        this.openCtiConnection$.next(isOpen);
        this.disconnect();    
    }

    private getReason(event: CloseEvent){
        let reason: string;
        switch (event.code)
        {
            case 1000:
                reason = 'Normal closure, meaning that the purpose for which the connection was established has been fulfilled.';
                break;
            case 1001:
                reason = 'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
                break;
            case 1002:
                reason = 'An endpoint is terminating the connection due to a protocol error';
                break;
            case 1003:
                reason = 'An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).';
                break;
            case 1004:
                reason = 'Reserved. The specific meaning might be defined in the future.';
                break;
            case 1005:
                reason = 'No status code was actually present.';
                break;
            case 1006:
                reason = 'The connection was closed abnormally, e.g., without sending or receiving a Close control frame';
                break;
            case 1007:
                reason = 'An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).';
                break;
            case 1008:
                reason = 'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.';
                break;
            case 1009:
                reason = 'An endpoint is terminating the connection because it has received a message that is too big for it to process.';
                break;
            case 1010:
                reason = 'An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn\'t return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: ' + event.reason;
                break;
            case 1011:
                reason = 'A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.';
                break;
            case 1015:
                reason = 'The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can\'t be verified).';
                break;
            default:
                reason = 'Unknown reason';
                break;

        }       
        return reason;
    }

    /**
     * This method will handle error scenarios and add message to cti debug log for error and non-error scenario
     * @param data
     */
    public send(data: any) {
        try {
            this.pushToCtiDebugLog(data);
            if (this.sendMessage) {
                this.sendMessage.next(data);
            }
        } catch (error) {
            this.pushToCtiDebugLog('Error while sending message to CTI');
            this.pushToCtiDebugLog('Error Details: ' + error.message);
        }
    }

    /**
     * This method will not handle the exception and debug logging
     * User have to handle the error and debug logging manually
     * @param data
     */
    public sendWithCustomHandling(data: any) {
        if (this.sendMessage) {
            this.sendMessage.next(data);
        }
    }

    public logTransaction(transactionName, _transactionDetails) {
        /**
         * This method is used for logging purpose.
         * If logging is needed we should implement this class in microapp and log from there
         * like eing-header
         */
        AppUtil.log('SharedModule ====> logTransaction  ====> %o', transactionName);
    }

    private validateWebSocketResponseMessage(webSocketResponseMessage: MessageEvent): void {
        if (
            webSocketResponseMessage !== null &&
            typeof webSocketResponseMessage.data !== 'undefined' &&
            webSocketResponseMessage.data !== null &&
            webSocketResponseMessage.data !== ''
        ) {
            /**
             * Parese Message
             */
            this.jsonMessage = null;
            this.convertXmlMessageToJsonMessage(webSocketResponseMessage.data);

            /**
             * Log Transaction
             */
            let transactionName: string = '';
            let msgType: string = '';
            if (webSocketResponseMessage.data.indexOf('CTIRegister') !== -1) {
                msgType = 'CTIRegister';
            } else if (webSocketResponseMessage.data.indexOf('CTIBeginCall') !== -1) {
                msgType = 'CTIBeginCall';
            } else if (webSocketResponseMessage.data.indexOf('CTIEndCall') !== -1) {
                msgType = 'CTIEndCall';
            } else if (webSocketResponseMessage.data.indexOf('WSClientTransfer') !== -1) {
                msgType = 'CTITransfer';
            } else if (webSocketResponseMessage.data.indexOf('WSClientCPNIOB') !== -1) {
                msgType = 'CTICPNIOBTransfer';
            } else if (webSocketResponseMessage.data.indexOf('WSClientCPNIOBResponse') !== -1) {
                msgType = 'WSClientCPNIOBResponse';
            }
            switch (msgType) {
                case 'CTIRegister': {
                    this.pushToCtiDebugLog('Received Acknowledgement for CTI Registration');
                    this.pushToCtiDebugLog(webSocketResponseMessage.data);
                    transactionName = 'CLIENT_REGISTER_ACK';
                    break;
                }
                case 'CTIBeginCall': {
                    this.pushToCtiDebugLog('---------------------------');
                    this.pushToCtiDebugLog('Received BeginCall request');
                    this.pushToCtiDebugLog(webSocketResponseMessage.data);
                    try {
                        const childNodes =
                            '<WSAck>true</WSAck><WSResult>Recieved</WSResult></CTIData>';
                        let ctiAckData: string =
                            '<CTIDataResponse>' +
                            webSocketResponseMessage.data +
                            '</CTIDataResponse>';
                        if (ctiAckData.match('</CTIData>')) {
                            ctiAckData = ctiAckData.replace('</CTIData>', childNodes);
                        }
                        this.pushToCtiDebugLog(ctiAckData);
                        this.sendWithCustomHandling(ctiAckData);
                    } catch (error) {
                        this.pushToCtiDebugLog(
                            'Error while sending Acknowledgement for CTI BeginCall'
                        );
                        this.pushToCtiDebugLog('Error Details: ' + error.message);
                    }
                    transactionName = 'SCREEN_POP';
                    break;
                }
                case 'CTIEndCall': {
                    this.pushToCtiDebugLog('Received EndCall request');
                    this.pushToCtiDebugLog(webSocketResponseMessage.data);
                    transactionName = 'SCREEN_POP';
                    break;
                }
                case 'CTICPNIOBTransfer': {
                    this.pushToCtiDebugLog('Received CPNI Outbound Transfer Acknowledge');
                    this.pushToCtiDebugLog(webSocketResponseMessage.data);
                    transactionName = 'OUTBOUND_ACKNOWLEDGE';
                    break;
                }
                case 'WSClientCPNIOBResponse': {
                    this.pushToCtiDebugLog('Received CPNI Outbound Transfer Acknowledge');
                    this.pushToCtiDebugLog(webSocketResponseMessage.data);
                    transactionName = 'OUTBOUND_ACKNOWLEDGE';
                    break;
                }
                case 'CTITransfer': {
                    this.pushToCtiDebugLog('Received Transfer Call Response');
                    this.pushToCtiDebugLog(webSocketResponseMessage.data);
                    if (
                        this.jsonMessage &&
                        this.jsonMessage.wsClientTransferResponse &&
                        this.jsonMessage.wsClientTransferResponse.success &&
                        this.jsonMessage.wsClientTransferResponse.success
                            .toString()
                            .toLowerCase() === 'true'
                    ) {
                        transactionName = 'TRANSFER_SECCESS';
                    } else {
                        transactionName = 'TRANSFER_FAILURE';
                    }
                    break;
                }
                default: {
                    transactionName = '';
                    break;
                }
            }
            if (transactionName !== '') {
                this.logTransaction(transactionName, webSocketResponseMessage.data);
            }
        }
    }

    protected convertXmlMessageToJsonMessage(xmlMessageString: string) {
        AppUtil.log('Shared Module ==> xml string message %o', xmlMessageString);
        try {
            const msgJsonData: any = this.xmlToJsonParser.parse(
                xmlMessageString,
                initialXmlToJsonParserConfig
            );
            try {
                AppUtil.log('msgJsonData ===> %o', msgJsonData);
                if (typeof msgJsonData !== 'undefined' && msgJsonData !== null) {
                    AppUtil.log(
                        'CtiWebSocketService : constructor() : Message converted into JSON Data ===> %o',
                        msgJsonData
                    );
                    this.messages$.next(msgJsonData);
                    this.jsonMessage = msgJsonData;
                    if (
                        msgJsonData &&
                        msgJsonData.ctiRegister &&
                        msgJsonData.ctiRegister.ctimetadata
                    ) {
                        // if (msgJsonData.ctiRegister.ctimetadata.oneCtiVersion) {
                        //     if (this.isCtiTransferable === false) {
                        //         this.isCtiTransferable = this.checkCtiTransferable(
                        //             msgJsonData.ctiRegister.ctimetadata.oneCtiVersion
                        //         );
                        //         this.pushToCtiDebugLog(
                        //             "CTI Transfer Capable : " + this.isCtiTransferable.toString()
                        //         );
                        //     }
                        //     if (!this.isCtiCpniCompatible) {
                        //         this.isCtiCpniCompatible = this.checkCpniCompatible(
                        //             msgJsonData.ctiRegister.ctimetadata.oneCtiVersion
                        //         );
                        //         this.pushToCtiDebugLog(
                        //             "CTI CPNI Outbound Capable  : " +
                        //                 this.isCtiCpniCompatible.toString()
                        //         );
                        //     }
                        // }
                    //     let ctiAgenModel: SharedModuleModels.ctiAgentDetails = Object.assign({});
                    //     ctiAgenModel.agentId = msgJsonData.ctiRegister.ctimetadata.agentId;
                    //     ctiAgenModel.ipAddress = msgJsonData.ctiRegister.ctimetadata.ipAddress;
                    //     ctiAgenModel.machineName = msgJsonData.ctiRegister.ctimetadata.machineName;
                    //     ctiAgenModel.oneCtiVersion =
                    //         msgJsonData.ctiRegister.ctimetadata.oneCtiVersion;
                    //     ctiAgenModel.stationId = msgJsonData.ctiRegister.ctimetadata.stationId;
                    //     ctiAgenModel.isCtiCpniOutboundCompatible = this.isCtiCpniCompatible;
                    //     ctiAgenModel.isCtiConnected = this.isCtiConnected;
                    //     ctiAgenModel.isCtiTransferable = this.isCtiTransferable;
                    //     this.store.dispatch(
                    //         this.sharedModuleActions.createCtiAgentDetails(ctiAgenModel)
                    //     );
                    }
                }
            } catch (error) {
                this.pushToCtiDebugLog(
                    'CtiWebSocketService : webSocketMessageEventHandler() : errorEvent : Error on next action CTIMessage:  Message: ' +
                        error.message +
                        ' : Stack trace: ' +
                        error.stack
                );
            } finally {
                this.pushToCtiDebugLog(
                    'CtiWebSocketService : webSocketMessageEventHandler() : JSON Message: ' +
                        JSON.stringify(msgJsonData)
                );
            }
            return msgJsonData;
        } catch (error) {
            this.pushToCtiDebugLog(
                'CtiWebSocketService : webSocketMessageEventHandler() : errorEvent : Error on parsing CTIMessage:  Message: ' +
                    error.message +
                    ' : Stack trace: ' +
                    error.stack
            );
        } finally {
            this.pushToCtiDebugLog(
                'CtiWebSocketService : webSocketMessageEventHandler() : XML Message: ' +
                    xmlMessageString
            );
        }
    }

    private pushToCtiDebugLog(debugLogItem) {
        AppUtil.log(debugLogItem);
    }

    public sendCTIAuditLog(wsAck: string, wsResult: string, ctiData: any): MessageEvent {
        //let appConfig = <SharedModuleModels.SharedModuleModel>AppConfigUtil.getProperties();
        const isEnableCTIacknowledgement: boolean = true;
        let data: any = '';
        let ackData: any = '';
        if (isEnableCTIacknowledgement) {
            data = this.jsonToXmlConversion(ctiData);
            if (data.match('</CTIData>')) {
                const childNodes =
                    '<WSAck>' + wsAck + '</WSAck><WSResult>' + wsResult + '</WSResult></CTIData>';
                ackData = data.replace('</CTIData>', childNodes);
                if (!ackData.match('<CTIDataResponse>')) {
                    ackData = '<CTIDataResponse>' + ackData + '</CTIDataResponse>';
                }
                // this.store.dispatch(this.sharedModuleActions.setCtiData(ctiData));
                this.pushToCtiDebugLog(ackData);
                if (this.sendMessage) {
                    this.sendMessage.next(ackData);
                }
            }
            return ackData;
        }
    }

    public jsonToXmlConversion(ctiData: any) {
        let result = '';
        for (const element in ctiData) {
          if (ctiData.hasOwnProperty(element)) {
            const value = ctiData[element];
            result = result + '<' + element + '>' + value + '</' + element + '>';
          }
        }
        if (!result.toLowerCase().match('<ctidata>')) {
            result = '<CTIData>' + result + '</CTIData>';
        }
        return result;
    }
}
