// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export enum SingleColumnHomeDisplayMode {
  HighlightFeed,
  LiveTranscript,
  SessionSummary
}

export enum HomeHeaderErrorState {
  None,
  Temporary,
  Critical,
  MultipleCritical
}

export enum SessionSummaryState {
  Default,
  CloseSession,
  NextSession
}

export enum RecommendedActionState{
  Recommendation,
  Launched,
  Error,
  Success
}

export enum FeedContentType{
  ItgRecommendation,
  PinnedMessage
}

export enum RatingState{
  None,
  Positive,
  Negative
}

export enum CallState {
  Inactive,
  Queued,
  Ended,
  Active
}

export enum CallErrorState {
  None,
  BecauseISaidSo
}

export enum RecommendedActionResponseType {
  Unknown,
  Itg
}

export enum RecommendedActionType {
  Itg
}

export enum CallSummaryDisplay{
  Panel,
  Modal
}

export enum AskMeAnythingSource {
  Customer = 'customer',
  Agent = 'agent'
}