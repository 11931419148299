// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { RecommendationsResponse } from './responses/recommendationsResponse';

export class AddRecommendation extends RecommendationsResponse{
  isNewItgSuggested: boolean;
  isNewItgAdded?: boolean;
}
