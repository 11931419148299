// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
	providedIn: 'root'
})
export class JwtService {
	constructor() {}

	Decode(value: string): any {
		const helper = new JwtHelperService();
		return helper.decodeToken(value);
	}
}
