<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div *ngIf="{
  action: confirmAction$ | async, 
  chatState: chatState$ | async,
  isAsync: isAsync$ | async,
  hasDispositions: hasDispositions$ | async,
  selectedChatId: selectedChatId$ | async
} as data">
  <cee-confirmation-modal 
    headerText="Are you sure you want to close this conversation?"
    [active]="active"
    confirmText="Yes, Close"
    cancelText="Cancel"
    [clickOutsideToClose]="false"
    (modalClose)="closeModal()"
    (confirmClicked)="confirm(data.action, data.chatState, data.isAsync, data.hasDispositions, data.selectedChatId)"
    (cancelClicked)="closeModal()">
    <p>This ITG is still actively responding to the customer. Closing or transferring the conversation will also cancel the ITG. We recommend you check to see if the customer is ready to resume troubleshooting before closing or transferring.</p>    
  </cee-confirmation-modal>
</div>