// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChangeDetectionStrategy, Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { CeeTabPanelItemComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { NavigationSources } from 'projects/entities/src/lib/constants/constants';
import { AgentActions, AppState, FeatureFlags, UiActions } from 'projects/entities/src/public_api';
import { fromSettings, fromUi } from 'projects/entities/src/lib/domain/selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'merc-agent-settings-feedback-tab',
  templateUrl: './feedback-tab.component.html',
  styleUrls: ['./feedback-tab.component.scss'],
  providers: [
    {
      provide: CeeTabPanelItemComponent,
      useExisting: forwardRef(() => FeedbackTabComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackTabComponent extends CeeTabPanelItemComponent implements OnInit {
  title = 'Feedback';
  showControls = false;
  showFollowup$: Observable<boolean>;
  featureFlagMySupport$: Observable<boolean>;
  isRatingSubmitted$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
  ) { 
    super();
  }

  ngOnInit() {
    this.isRatingSubmitted$ = this.store.select(fromUi.getSettingsConnectionRatingSubmitted);
    this.showFollowup$ = this.store.select(fromUi.getShowRateConnectionFollowUp);
    this.featureFlagMySupport$ = this.store.select(fromSettings.hasFeatureFlag(FeatureFlags.MySupportOutboundLinks));
  }

  public onPageInitMySupportClicked() {
    this.store.dispatch(AgentActions.NavigateToSupportPage({ source: NavigationSources.feedbackModal }));
  }

  onShowFollowup(rating) {
    const enableRateConnectionAdditionalFeedback = rating < 4;
    this.store.dispatch(UiActions.ToggleShowRateConnectionFollowUp({
      toggleShowRateConnectionFollowUp: true,
      enableRateConnectionAdditionalFeedback: enableRateConnectionAdditionalFeedback
    }));
  }
}
