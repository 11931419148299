// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiRequests } from '../constants/cti.constants';
import { CtiChatRequest } from './cti-chat-request';

export class CtiDialogCollectionStartRequest extends CtiChatRequest{
    account: string;
    messageName: string = CtiRequests.ChatDialogSecureCollectionStart;
    focusApplication: string;
    requestId: string;
    public constructor(uui: string, sessionId: string, account: string, focusApplication: string, requestId: string){
      super(uui, sessionId);
      this.account = account;
      this.focusApplication = focusApplication;
      this.requestId = requestId;
    }
}
