// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HubConnectionState } from '@cxt-cee-chat/merc-ng-core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAgentAuth from './auth.reducer';
import { getChatRequestHubConnectionState } from './hubs.selectors';
import { AppState } from './state';

export const selectAgentAuthFeature = createFeatureSelector<
  AppState,
  fromAgentAuth.State
>(fromAgentAuth.featureKey);

export const getLoggingIn = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.attemptingLogIn
);

export const getLogInStatus = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.loggedIn
);

export const getLoggingOut = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.loggingOut
);

export const getContinuedSession = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.continuedSession
);

export const getVerifyingSession = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.verifyingSession
);

export const getIsFormAuthenticationSuccess = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication?.isFormAuthenticationSuccess
);

export const getSessionValidated = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.sessionValidated
);

export const chatRequestProcessable = createSelector(
  getSessionValidated,
  getChatRequestHubConnectionState,
  (sessionValidated: boolean, connectionState: HubConnectionState) => {
    const isConnected = connectionState === HubConnectionState.Connected || connectionState === HubConnectionState.Reconnected;
    return isConnected && sessionValidated;
  }
);

export const getVoiceSessionId = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.voiceSessionId
);

export const getAvayaAgentId = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.avayaAgentId
);

export const getPhoneExtension = createSelector(
  selectAgentAuthFeature,
  (authentication: fromAgentAuth.State) => authentication.phoneExtension
);
