// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createAction, props } from '@ngrx/store';
import { AvailabilityOption } from 'src/app/models/availability-options';
import { createPayloadAction } from '../action-adapters';
import { State } from '../agent.reducer';
import { SendSuggestion } from '../models';
import { UnusedSlotTimes } from '../models/unusedSlotTimes';
import { AgentGroup } from '../models/agentGroup';
import { DismissSuggestionReason } from '../models/enums';
import { GetAgentAnalyticsResponse } from '../models/getAgentAnalyticsResponse';
import { LoadAgentAnalytics as LoadAgentAnalyticsModel } from '../models/loadAgentAnalytics';
import { PreviousChatSummary } from '../models/previousChatSummary';
import { GetAgentAvailabilitySummaryResponse } from '../models/responses/get-agent-availability-summary-response';
import { GetAgentMetricsResponse } from '../models/responses/get-agent-metrics-response';
import { GetAgentSalesConversionMetricsResponse } from '../models/responses/get-agent-sales-conversion-metrics-response';
import { SurveyResponse } from '../models/SurveyResponse';
import { UserAttributes } from '../models/userAttributes';

export const LoadAgentMetrics = createAction('[Agent] Load Agent Metrics');

export const AgentMetricsLoaded = createPayloadAction<GetAgentMetricsResponse>(
  '[Agent] Agent Metrics Loaded'
);

export const UpdateAgentStateSettings = createAction(
  '[Agent] Update UpdateAgentStateSettings',
  props<{ maxChats: number, maxExtraChats: number, enableCustomScripts: boolean, availabilityOptions: AvailabilityOption[], agentNetworkId: string, pernrId: string, userGuid: string }>()
);

export const UpdateCompletedChatCount = createPayloadAction<number>(
  '[Agent] Update Completed Chat Count'
);

export const UpdateAgentAnalytics = createPayloadAction<
  GetAgentAnalyticsResponse
>('[Agent] Update Agent Analytics');

export const GetAgentAnalyticsFailed = createAction(
  '[Agent] Get Agent Analytics Failed'
);

export const UpdateAgentGroups = createPayloadAction<AgentGroup[]>(
  '[Agent] Update Agent Groups'
);

export const UpdateSelectedAgentGroups = createPayloadAction<AgentGroup[]>(
  '[Agent] Update Selected Agent Groups'
);

export const LoadAgentAnalytics = createPayloadAction<LoadAgentAnalyticsModel>(
  '[Agent] Load Agent Analytics'
);

export const LoadAgentGroups = createAction('[Agent] Load Agent Groups');

export const SessionLockoutPageLoad = createAction('[Agent] Session Lockout Page Load');

export const HandleOpenChats = createAction('[Agent] Handle Open Chats');

export const SessionTimeout = createAction('[Agent] Session Timeout');

export const UpdateSurveyPrompt = createPayloadAction<SurveyResponse>(
  '[Agent] Update Survey Prompt'
);

export const UpdateLockout = createAction(
  '[Agent] Update Lockout',
  props<{ isLockedOut: boolean }>()
);

export const UpdateBounceChatId = createAction(
  '[Agent] Update BounceChatId',
  props<{ chatId: string }>()
);

export const RequestExtraChats = createAction(
  '[Agent] RequestExtraChats',
  props<{ enabled: boolean }>()
);

export const NavigateToSupportPage = createAction(
  '[UI] Navigate To Support Page',
  props<{ source: string }>()
);

export const GetAgentAvailabilitySummary = createAction(
  '[Agent] Get Agent Availability Summary'
);

export const UpdateAgentAvailabilitySummary = createAction(
  '[Agent] Update Agent Availability Summary',
  props<{ agentAvailabilityResponse: GetAgentAvailabilitySummaryResponse }>()
);

export const UpdateAgentSalesConversionMetrics = createAction(
  '[Agent] Update Agent Sales Conversion Metrics',
  props<{ agentSalesConversionMetrics: GetAgentSalesConversionMetricsResponse }>()
);

export const UpdateOfferQualityCheck = createAction(
  '[Agent] Update Offer Quality Check',
  props<{ offerQualityCheck: boolean, offerFirstQualityCheck: boolean, chatId: string }>()
);

export const SubmitDismissSuggestionFeedback = createAction(
  '[Agent] Submit Dismiss Suggestion Feedback',
  props<{ reason: DismissSuggestionReason, dismissedSuggestion: SendSuggestion, timestamp: number }>()
);

export const hydrate = createAction('[Agent] Hydrate');

export const hydrateSuccess = createAction(
  '[Agent] Hydrate Success',
  props<{ state: State }>()
);

export const hydrateFailure = createAction('[Agent] Hydrate Failure');

export const cancelSessionTimeout = createAction('[Agent] Cancel Session Timeout');

export const sessionTimeoutExpired = createAction('[Agent] Session Timeout Expired');

export const sessionTimeoutReauthenticate = createAction('[Agent] Session Timeout Reauthenticate');

export const rehydrateUserIdentityService = createAction('[Agent] Rehydrate User Identity Service');

export const UpdatePreviousChatSummaries = createAction(
  '[Agent] Update Previous chat summaries',
  props<{ chatSummary: PreviousChatSummary }>()
);

export const UpdatePreviousChatSummaryFilterText = createAction(
  '[Agent] Update Previous chat summary filterText',
  props<{ filteredText: string }>()
);

export const UpdateUnusedSlotTimes = createAction(
  '[Agent] Update unused Slot Times',
  props<{ unusedSlotTimes: UnusedSlotTimes }>()
);

export const UpdateAgentUserAttributes = createAction(
  '[Agent] Update Agent user attributes',
  props<{ agentAttributes: UserAttributes }>()
);

export const updateIsAgentUnauthorized = createAction(
  '[Agent] update IsAgentUnauthorized',
  props<{ isAgentUnauthorized: boolean}>()
);
