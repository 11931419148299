// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { HttpErrorResponse, HttpEvent, HttpHandler } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { AccessToken, BearerTokenHttpHandler } from '@cxt-cee-chat/merc-ng-core';
import { Observable, throwError } from 'rxjs';
import { ComcastSsoAuthService } from './comcast-auth-sso-service';

@Injectable()
export class ComcastBearerTokenHttpHandler extends BearerTokenHttpHandler
{
    constructor
    (        
        private readonly comcastSsoService: ComcastSsoAuthService,     
        next: HttpHandler
    )
    {
        super(next);
    }

    getAccessToken(): Promise<AccessToken> {
        return this.comcastSsoService.getToken();
    }

    handleResponseError(error: HttpErrorResponse): Observable<HttpEvent<any>>{             
       return throwError(error);
    }   
}

export const COMCAST_BEARER_TOKEN_HANDLER = new InjectionToken<HttpHandler>('comcastBearerTokenHandler');