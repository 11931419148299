// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ofType, createEffect, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ApiSettings } from '@cxt-cee-chat/merc-ng-core';
import { AppActions } from './actions';
import { Injectable } from '@angular/core';
import { AppState } from './state';
import { Store } from '@ngrx/store';
import { FixAgentAuthApiService } from '../services/fix-agent-auth-api.service';
import { FixAgentFormAuthApiService } from '../services/fix-agent-form-auth-api.service';
import { FixAgentApiService } from '../services/fix-agent-api.service';
import { CustomerInfoApiService } from '../services/customer-info-api.service';
import { AppConfigApiService } from '../services/app-config-api.service';
import { AppConfigService } from 'src/app/services/app-config.service';


@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Services {
  constructor(
		protected ngEntityStore: Store<AppState>,
		protected actions: Actions,
		protected authRestApiService: FixAgentAuthApiService,
		protected fixAgentFormAuthRestApiService: FixAgentFormAuthApiService,
		protected fixAgentApiService: FixAgentApiService,
		protected customerInfoService: CustomerInfoApiService,
		protected appConfigService: AppConfigApiService,
		protected config: AppConfigService,
	) { }

    // Initialize Rest api service
    initService$ = createEffect(() =>
        this.actions.pipe(
            ofType(AppActions.InitializeService),
            tap(() => {
                this.authRestApiService.init(this.getSettings(this.config.fixAgentAuthRest.endpoint));
                this.fixAgentFormAuthRestApiService.init(this.getSettings(this.config.fixAgentAuthRest.endpoint));
                this.fixAgentApiService.init(this.getSettings(this.config.fixAgentRest.endpoint));
                this.appConfigService.init(this.getSettings(this.config.appConfig.endpoint));
                this.customerInfoService.init(this.getSettings(this.config.customerInfo.endpoint));
                this.ngEntityStore.dispatch(AppActions.ServiceInitialized());
            }
        )),
        { dispatch: false }
      );

      initVoiceService$ = createEffect(() =>
        this.actions.pipe(
            ofType(AppActions.InitializeVoiceService),
            tap(() => {
                this.authRestApiService.init(this.getSettings(this.config.fixAgentAuthRest.endpoint));
                this.fixAgentApiService.init(this.getSettings(this.config.fixAgentRest.endpoint));
                this.appConfigService.init(this.getSettings(this.config.appConfig.endpoint));
                this.ngEntityStore.dispatch(AppActions.VoiceServiceInitialized());
            }
        )),
        { dispatch: false }
      );

    getSettings(endpoint: string): ApiSettings{
        const restApiSettings: ApiSettings = {
            endpoint: endpoint
        };
        return restApiSettings;
    }

}
