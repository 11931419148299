<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div
  *ngIf="{
    cxGptResponse: cxGptResponse$ | async,
    selectedActiveChat: selectedActiveChat$ | async,
    isCxgptResponseFailed: isCxgptResponseFailed$ | async
  } as data"
>
  <div class="merc-cxgpt-banner-response">
    <cee-feature-banner
      title="Auto Generated Responses"
      body="Always review messages before sending."
      icon="autogenerate"
      color="purple"
    ></cee-feature-banner>
  </div>

  <ng-container *ngIf="data?.selectedActiveChat; else emptyRecommended">
    <ng-container *ngIf="data.isCxgptResponseFailed">
      <div class="merc-error-message">
        Sorry, could not generate a response.
      </div>
    </ng-container>

    <ng-container *ngIf="!data.isCxgptResponseFailed">
      <ng-container *ngIf="data.cxGptResponse && !data.cxGptResponse.sent">
        <div class="merc-cxgpt-response-card">
          <cee-response-card
            (feedbackClicked)="openFeedback(data.cxGptResponse)"
            (editClicked)="
              populateEditor(data.cxGptResponse, data.selectedActiveChat)
            "
            (messageSend)="
              sendMessage(data.cxGptResponse, data.selectedActiveChat)
            "
          >
            <p>{{ data?.cxGptResponse?.text }}</p>
          </cee-response-card>
        </div>
      </ng-container>

      <ng-container *ngIf="!data.cxGptResponse">
        <cee-loading-skeleton
          class="cee-loading-skeleton-container"
          [hideAnimation]="data.hideAnimations"
          [iterations]="2"
          [isParagraphBlock]="false"
        ></cee-loading-skeleton>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #emptyRecommended>
    <div class="merc-empty-recommended">
      Responses will load once a conversation has begun.
    </div>
  </ng-template>

  <cee-modal
    headerText="Provide Additional Feedback"
    [active]="showFeedbackModal"
    [clickOutsideToClose]="true"
    (modalClose)="closeModal()"
  >
    <div *ngFor="let option of options$ | async" class="cee-form-row--group">
      <input
        id="{{ option.value }}"
        type="checkbox"
        name="{{ option.value }}"
        [checked]="option.checked"
        (change)="updateFeedback($event, option)"
        class="cee-checkbox"
      />
      <label class="cee-checkbox-label" for="{{ option.value }}">{{
        option.label
      }}</label>
    </div>
    <div class="merc-cxgpt-textbox">
      <cee-textarea
        [placeholder]="'(Optional) Provide more details.'"
        (textChange)="onFeedbackChange($event)"
        [textInput]="optionalFeedback"
      ></cee-textarea>
    </div>
    <div class="merc-cxgpt-submit-feedback">
      <cee-button
        id="connect-submit-feedback"
        [type]="'primary'"
        (buttonClick)="submitFeedback(data.cxGptResponse,data.selectedActiveChat)"
      >
        Submit Feedback
      </cee-button>
    </div>
  </cee-modal>
</div>
