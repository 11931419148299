// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AppState } from 'projects/entities/src/public_api';

@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_Availability {
    constructor(
		protected ngEntityStore: Store<AppState>,
		protected actions: Actions
	) { }
}