// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { BusinessUnit } from '../business-unit';

export class GetAgentMetricsResponse {
  completedChatCount: number;
  businessUnits: BusinessUnit[];
  agentFullName: string;
  callCenter: string;
  supplierId: string;
  ntOrBpId: string;
  supervisor: string;
}
