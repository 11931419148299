// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { CtiRequests } from '../constants/cti.constants';
import { CtiChatRequest } from './cti-chat-request';

export class CtiBeginChatRequest extends CtiChatRequest{
    COLORHexCode: string;
    COLORGenericName: string;
    messageName: string = CtiRequests.ChatDialogStart;
    custGuid: string;
    recover: boolean;

    public constructor(uui: string, sessionId: string, colorHexcode: string, colorGenericName: string, custGuid: string, recover: boolean = false) {
      super(uui, sessionId);
      this.COLORHexCode = colorHexcode;
      this.COLORGenericName = colorGenericName;
      this.custGuid = custGuid;
      this.recover = recover;
    }
}
