// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ScriptType } from 'projects/entities/src/public_api';

export interface Script {
  id: string;
  name: string;
  value: string;
  type: ScriptType;
  scriptGroupId: string; // "foreign key" to lookup category for nlp transcripts
  scriptTreeId: string; // "foreign key" to make it easier to query and delete
}

export interface ManagedScript {
  id: string;
  status: 'UPDATED' | 'NEW' | 'EDIT' | 'MANAGE' | 'CREATE' | 'DELETE' | 'DELETED' | 'MOVED' | 'NEW HEADER' | 'EDIT HEADER' | 'DELETE HEADER' | null;
}

export function generateMockScript(): Script {
  return {
    id: 'script1',
    name: 'scriptName',
    value: 'scriptValue',
    type: ScriptType.Custom,
    scriptGroupId: 'scriptGroup1',
    scriptTreeId: 'scriptTree1',
  };
}
