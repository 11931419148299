<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<ng-template #innerTemplate>

  <h2 class="cee-h2 merc-agent-settings-tab-header">Sound</h2>
  <div class="merc-agent-settings-tab-body">
    <merc-settings-banner></merc-settings-banner>
    <div class="cee-form-row--group merc-soundbar-wrapper">
      <merc-sound-bar [volume]="settings.soundVolume" [isMuted]="settings.soundMuteAll" (volumeChanged)="onVolumeChange($event)" (volumeMuted)="onMuted($event)" [testButton]="true"></merc-sound-bar>
    </div>
    <div class="cee-form-row--group">
      <input
        id="sound-tab-mute-all"
        type="checkbox"
        name="sound-tab-mute-all"
        [(ngModel)]="settings.soundMuteAll"
        (ngModelChange)="onSettingsModelChange()"
        class="cee-checkbox"
      />
      <label
        class="cee-checkbox-label"
        for="sound-tab-mute-all"
        >Mute all sounds</label>
    </div>


    <h2 class="cee-h2">Notification sounds</h2>
    <div class="merc-agent-settings-tab-section">
      <div class="cee-form-row--group">
        <input
          id="sound-tab-new-convo"
          type="checkbox"
          name="sound-tab-new-convo"
          [(ngModel)]="settings.soundNewConversation"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
          [disabled]="settings.soundMuteAll"
        />
        <label
          class="cee-checkbox-label"
          for="sound-tab-new-convo"
          >New conversation</label>
      </div>
      <div class="cee-form-row--group">
        <input
          id="sound-tab-new-msg-current"
          type="checkbox"
          name="sound-tab-new-msg-current"
          [(ngModel)]="settings.soundNewMessageCurrent"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
          [disabled]="settings.soundMuteAll"
        />
        <label
          class="cee-checkbox-label"
          for="sound-tab-new-msg-current"
          >New message (current conversation)</label>
      </div>
      <div class="cee-form-row--group">
        <input
          id="sound-tab-new-msg-other"
          type="checkbox"
          name="sound-tab-new-msg-other"
          [(ngModel)]="settings.soundNewMessageOther"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
          [disabled]="settings.soundMuteAll"
        />
        <label
          class="cee-checkbox-label"
          for="sound-tab-new-msg-other"
          >New Message (other conversation)</label>
      </div>
      <div class="cee-form-row--group">
        <input
          id="sound-tab-customer-awaiting-reply"
          type="checkbox"
          name="sound-tab-customer-awaiting-reply"
          [(ngModel)]="settings.soundCustomerAwaitingReply"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
          [disabled]="settings.soundMuteAll"
        />
        <label
          class="cee-checkbox-label"
          for="sound-tab-customer-awaiting-reply"
          >Customer awaiting reply</label>
      </div>
      <div class="cee-form-row--group">
        <input
          id="sound-tab-outgoing-msg"
          type="checkbox"
          name="sound-tab-outgoing-msg"
          [(ngModel)]="settings.soundOutgoingMessage"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
          [disabled]="settings.soundMuteAll"
        />
        <label
          class="cee-checkbox-label"
          for="sound-tab-outgoing-msg"
          >Outgoing message</label>
      </div>
      <div class="cee-form-row--group">
        <input
          id="sound-tab-convo-ends"
          type="checkbox"
          name="sound-tab-convo-ends"
          [(ngModel)]="settings.soundConversationEnds"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
          [disabled]="settings.soundMuteAll"
        />
        <label
          class="cee-checkbox-label"
          for="sound-tab-convo-ends"
          >Conversation ends</label>
      </div>

    </div>
    <h2 class="cee-h2 merc-agent-settings-tab-header">Notifications</h2>

    <div class="cee-form-row">
      <div class="cee-form-row--group">
        <input
          id="notification-tab-desktop"
          type="checkbox"
          name="notification-tab-desktop"
          [(ngModel)]="settings.desktopNotifications"
          (ngModelChange)="onSettingsModelChange()"
          class="cee-checkbox"
        />
        <label
          class="cee-checkbox-label"
          for="notification-tab-desktop"
          >Desktop notifications</label>
      </div>
    </div>
  </div>

</ng-template>
