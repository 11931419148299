// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { DayTimeService, HubConnectionState, LoggingFactoryService } from '@cxt-cee-chat/merc-ng-core';
import { Store } from '@ngrx/store';
import {
  AgentAuthActions, AppState, AgentOperations, LogHelper, HubsActions, AppActions
} from 'projects/entities/src/public_api';
import { PageInitializationHelperService } from 'projects/entities/src/lib/services/page-initialization-helper.service';
import { PageInitializationConstants } from 'projects/entities/src/lib/constants/page-initialization.constants';
import { getChatHubLastReconnecting } from 'projects/entities/src/lib/domain/hubs.selectors';
import { ConnectionLostModalSettings } from 'projects/entities/src/lib/constants/application-health.constants';
import { asyncScheduler } from 'rxjs';
import { fromAgentAuth, fromChat } from 'projects/entities/src/lib/domain/selectors';
import { ChatPersisterService } from 'projects/entities/src/lib/domain/chat/chat-persister.service';
import { StartGetUpdatesSources, StopGetUpdatesSources } from 'projects/entities/src/lib/constants/constants';

@Injectable()
// tslint:disable-next-line: class-name
export class RunnerAppEffects_Hubs {
  constructor(
    private ngEntityStore: Store<AppState>,
    private actions: Actions,
    private pageInitHelper: PageInitializationHelperService,
    private loggingFactory: LoggingFactoryService,
    private timeService: DayTimeService,
    private chatPersisterService: ChatPersisterService,
  ) { }

  chatRequestHubConnectionUpdate$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.chatRequestHubStateUpdated),
      concatLatestFrom(() => [
        this.ngEntityStore.select(getChatHubLastReconnecting),
        this.ngEntityStore.select(fromAgentAuth.getLogInStatus)
      ]),
      tap(([{connectionState, error}, reconnectingHubData, loggedIn]) =>  {
        this._updatePageInitState(PageInitializationConstants.ChatHubConnection, connectionState, error);
        switch (connectionState)
        {
          case HubConnectionState.Reconnecting:
            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.Reconnecting, { error }, true);
            this.ngEntityStore.dispatch(AppActions.StopGetUpdates({ source: StopGetUpdatesSources.reconnecting }));
            break;
          case HubConnectionState.Reconnected:
            if (loggedIn) {
              const { timestamp: reconnectingTimestamp, error: reconnectingError } = reconnectingHubData;
              const elapsedTime = this.timeService.unix() - reconnectingTimestamp;
              LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.Reconnected, { elapsedTime, reconnectingError }, true);
              this.ngEntityStore.dispatch(AgentAuthActions.verifySession());
              this.ngEntityStore.dispatch(AppActions.StartGetUpdates({ source: StartGetUpdatesSources.reconnected }));
            }
            else{
              // on internet disconnect, if the agent is logged out, the disconnectChatRequestHub action is fired,
              // but will not reach the hub to actually disconnect
              // once they are reconnected, we can disconnect the hub
              this.ngEntityStore.dispatch(HubsActions.disconnectChatRequestHub());
            }
            break;
          case HubConnectionState.Disconnected:
            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.Disconnect, { error });
            this.ngEntityStore.dispatch(AppActions.StopGetUpdates({ source: StopGetUpdatesSources.disconnected }));
            this.ngEntityStore.dispatch(AgentAuthActions.clearSession());
            break;
          case HubConnectionState.Connected:
            this.ngEntityStore.dispatch(AppActions.StartGetUpdates({ source: StartGetUpdatesSources.connected }));
            break;
        }
      })
    ),
    {dispatch: false}
  );

  checkReconnecting$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.chatRequestHubStateUpdated),
      filter(({connectionState}) => connectionState === HubConnectionState.Reconnecting),
      throttleTime(ConnectionLostModalSettings.WaitTimeInSeconds * 1000, asyncScheduler, ConnectionLostModalSettings.ThrottleConfig),
      concatLatestFrom(() => this.ngEntityStore.select(fromChat.getChats)),
      tap(([, chats]) =>  {
        this.ngEntityStore.dispatch(HubsActions.showConnectionLostModal());
        this.chatPersisterService.storeEntities(chats);
      })
    ),
    {dispatch: false}
  );

  connectChatHub$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.connectChatRequestHub),
      tap(() => {
        this.pageInitHelper.updateStatePending(PageInitializationConstants.ChatHubConnection);
      })
    ),
    { dispatch: false}
  );

    niagaraLogHubConnectionUpdate$ = createEffect(() =>
      this.actions.pipe(
        ofType(HubsActions.niagaraLogHubStateUpdated),
        tap(({ connectionState, error }) => {
          this._updatePageInitState(PageInitializationConstants.NiagaraLogHubConnection, connectionState, error);
        })
      ),
      { dispatch: false }
    );

    connectNiagaraLogHub$ = createEffect(() =>
      this.actions.pipe(
        ofType(HubsActions.connectNiagaraLogHub),
        tap(() => {
          this.pageInitHelper.updateStatePending(PageInitializationConstants.NiagaraLogHubConnection);
        })
      ),
      { dispatch: false }
    );

  connectVoiceHub$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.connectVoiceHub),
      tap(() => {
        this.pageInitHelper.updateStatePending(PageInitializationConstants.VoiceHubConnection);
      })
    ),
    { dispatch: false}
  );

  voiceHubConnectionUpdate$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.voiceHubStateUpdated),
      tap(({ connectionState, error }) => {
        this._updatePageInitState(PageInitializationConstants.VoiceHubConnection, connectionState, error);
      })
    ),
    { dispatch: false }
  );

  private _updatePageInitState(pageInitItem: string, hubConnectionState: HubConnectionState, error: Error){
    switch (hubConnectionState) {
      case HubConnectionState.Connected:
        this.pageInitHelper.updateStateSuccess(pageInitItem);
        break;
      case HubConnectionState.Errored:
        this.pageInitHelper.updateStateError(pageInitItem, error);
        break;
    }
  }
}
