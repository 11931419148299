// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { UpdateMessageResponse } from './update-message-response';

export class SendMessageResponse extends UpdateMessageResponse {
  timestamp: string;
  translatedText: string;
  translationError: boolean;
}
