<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<div class="merc-voice-recommended-action-wrapper">
  <div class="merc-voice-recommended-action">
    <div class="merc-voice-recommended-action--title-wrapper">
      <svg class="cee-icon">
        <use [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-' + recommendedAction.titleIcon"></use>
      </svg>
      <h3 class="merc-voice-recommended-action--title">
        {{recommendedAction.actionTitle}}
        <ng-container *ngIf="recommendedAction.actionState === recommendedActionState.Launched"> launched</ng-container>
      </h3>
    </div>
    <div *ngIf="recommendedAction.actionState === recommendedActionState.Recommendation"
      class="merc-voice-recommended-action--description">
      <ng-container *ngIf="recommendedAction.ratingState === ratingState.Negative; else description">
        We'll try to make better recommendations in the future. Thank you for your feedback!
      </ng-container>
      <ng-template #description>{{recommendedAction.description}}</ng-template>
    </div>
    <cee-banner-v2
      *ngIf="recommendedAction.actionState === recommendedActionState.Error"
      type="error">
      {{recommendedAction.errorMessage}}
    </cee-banner-v2>
    <cee-button-v2
      *ngIf="showAction()"
      customClass="merc-voice-recommended-action--control"
      (buttonClick)="controlClicked()">
      <span>{{recommendedAction.controlText}}</span>
    </cee-button-v2>
    <div
      *ngIf="showAction()"
      class="merc-voice-recommended-action--footer">
      <ng-container [ngSwitch]="recommendedAction.ratingState">
        <cee-thumb-rating *ngSwitchCase="ratingState.None"
          [ratingLabel]="'Was this a helpful recommendation?'"
          (positiveRating)="positiveRating()"
          (negativeRating)="negativeRating()"
        ></cee-thumb-rating>
        <span *ngSwitchCase="ratingState.Positive"
          class="merc-voice-recommended-action--footer-rating-feedback">
          Thanks for your feedback!
        </span>
      </ng-container>
    </div>
  </div>
</div>
<merc-voice-acknowledgement *ngIf="recommendedAction.actionState === recommendedActionState.Success"></merc-voice-acknowledgement>
