// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { createReducer, on } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { GuidHelper } from '../utils/guid-helper';
import { PageInitializationHelper } from '../utils/page-initialization-helper';
import { AppActions, ChatActions, OneCtiActions } from './actions';
import { PageInitializationItem } from './models/page-initialization-item';

export const featureKey = 'app';

export interface State {
  checkForUpdates: boolean;
  lastUpdatesCompleteTimestamp: number;
  updatesInProgressTraceId: string;
  pageInitializationState: PageInitializationItem[];
  pageInitializationSucceeded: boolean;
  pageName: string;
  isChatStateDirty: boolean;
  pendingServerSync: boolean;
  serverSyncTimestamp: number;
  shouldOneCtiHydrateChatStart: boolean;
}

export const initialState: State = {
  checkForUpdates: false,
  lastUpdatesCompleteTimestamp: null,
  updatesInProgressTraceId: null,
  pageInitializationState: [],
  pageInitializationSucceeded: false,
  isChatStateDirty: false,
  pendingServerSync: false,
  serverSyncTimestamp: null,
  pageName: null,
  shouldOneCtiHydrateChatStart: true,
};

export const reducer = createReducer<State>(
  initialState,
  on(
    AppActions.AppStopped,
    (state): State => ({
      ...state,
      checkForUpdates: false
    })
  ),
  on(
    AppActions.StartGetUpdates,
    (state): State => ({
      ...state,
      updatesInProgressTraceId: Guid.EMPTY,
      checkForUpdates: true
    })
  ),
  on(
    AppActions.StopGetUpdates,
    (state): State => ({
      ...state,
      updatesInProgressTraceId: Guid.EMPTY,
      checkForUpdates: false
    })
  ),
  on(
    AppActions.GetUpdates,
    (state, { traceId }): State => ({
      ...state,
      updatesInProgressTraceId: traceId
    })
  ),
  on(
    AppActions.GetUpdatesPrevented,
    (state, { traceId }): State => {
      // clear updatesInProgressTraceId if it matches the traceId of the request that was prevented
      if (state.updatesInProgressTraceId === traceId) {
        return ({
          ...state,
          updatesInProgressTraceId: null
        });
      }

      return state;
    }
  ),
  on(
    AppActions.UpdatesComplete,
    (state, { traceId, timestamp }): State => {
      const { updatesInProgressTraceId } = state;
      // update lastUpdatesCompleteTimestamp if the traceId matches the in last get updates request or there is no known request in progress
      if (
        GuidHelper.IsFalseOrEmpty(updatesInProgressTraceId) ||
        traceId === updatesInProgressTraceId
      ) {
        return {
          ...state,
          lastUpdatesCompleteTimestamp: timestamp
        };
      }

      return state;
    }
  ),
  on(
    AppActions.UpdatePageInitialization,
    (state, {data}): State => ({
      ...state,
      pageInitializationState: PageInitializationHelper.updatePageInitializationStates(state.pageInitializationState, data)
    })
  ),
  on(
    AppActions.PageInitializationSuccess,
    (state): State => ({
      ...state,
      pageInitializationSucceeded: true
    })
  ),
  on(
    AppActions.SetCurrentPage,
    (state, {pageName}): State => ({
      ...state,
      pageName,
      pageInitializationSucceeded: false
    })
  ),
  on(
    AppActions.ChatStateDirty,
    (state): State => ({
      ...state,
      isChatStateDirty: true
    })
  ),
  on(
    ChatActions.getActiveEngagementsResponse,
    (state): State => ({
      ...state,
      pendingServerSync: false,
      isChatStateDirty: false
    })
  ),
  on(
    AppActions.PendingServerSync,
    (state, { timestamp: serverSyncTimestamp }): State => ({
      ...state,
      pendingServerSync: true,
      serverSyncTimestamp
    })
  ),
  on(
    AppActions.ServerSyncFailed,
    (state): State => ({
      ...state,
      pendingServerSync: false,
      isChatStateDirty: true
    })
  ),
  on(OneCtiActions.RefreshChatsToOneCti,
    (state): State => ({
      ...state,
      shouldOneCtiHydrateChatStart: false
    })
  ),
);
