// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { LoadingState } from './enums';

export class ChatSummaryData {
    isLoading?: boolean;
    hasError?: boolean;
    autoSaveState?: LoadingState;
    getSummaryNumRetries?: number;
    autoSaveNumRetries?: number;
    recap: string;
    rated?: boolean;
    isPositiveRating?: boolean;
    traceId: string;
}
