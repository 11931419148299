<!--
SPDX-FileCopyrightText: 2024 Comcast

SPDX-License-Identifier: LicenseRef-Comcast
-->

<merc-page-initialization
[pageName]="pageName"
errorMessage="Unable to connect to Mercury for Voice. If this problem persists, please contact your supervisor for assistance."
*ngIf="{
  singleColumnHomeDisplayMode: (singleColumnHomeDisplayMode$ | async),
  isTranscriptFeedCollapsed: (isTranscriptFeedCollapsed$ | async),
  isSessionSummaryCollapsed: (isSessionSummaryCollapsed$ | async),
  showSessionSummaryDrawer: (showSessionSummaryDrawer$ | async),
  showSessionSummaryModal: (showSessionSummaryModal$ | async),
  hasVoiceFeatureFlag: (hasVoiceFeatureFlag$ | async)
  } as data">
  <merc-voice-header></merc-voice-header>
  <ng-container *ngIf="data.hasVoiceFeatureFlag; else unauthorized">
    <section class="merc-user-voice-home">
      <merc-voice-call-header></merc-voice-call-header>

      <div class="merc-user-voice-content"
        [ngClass]="{
          'collapsed': data.isTranscriptFeedCollapsed,
          'merc-session-summary-visible': data.showSessionSummaryDrawer,
          'merc-session-summary-collapsed': data.isSessionSummaryCollapsed
        }">
        <section>
          <merc-highlight-feed></merc-highlight-feed>
          <section *ngIf="data.showSessionSummaryDrawer" class="merc-session-summary-container">
            <div class="merc-session-summary">
              <cee-collapsible-drawer [isCollapsed]="data.isSessionSummaryCollapsed" title="Session Summary" (collapseToggled)="toggleSessionSummaryCollapse(data.isSessionSummaryCollapsed)">
                <merc-session-summary></merc-session-summary>
              </cee-collapsible-drawer>
            </div>
          </section>
        </section>
        <merc-live-transcript-drawer></merc-live-transcript-drawer>
      </div>
    </section>

    <!-- Smaller Resolution/Higher Zoom -->
    <section class="merc-user-voice-home--single-column"
      [class.merc-user-voice-home--single-column--session-summary]="data.singleColumnHomeDisplayMode == singleColumnHomeDisplayMode.SessionSummary">
      <div [ngSwitch]="data.singleColumnHomeDisplayMode"
        class="merc-user-voice-home--single-column-content-{{data.singleColumnHomeDisplayMode}}"
        [class.merc-user-voice-home--single-column--highlight-feed]="data.singleColumnHomeDisplayMode == singleColumnHomeDisplayMode.HighlightFeed">
        <ng-container *ngSwitchCase="singleColumnHomeDisplayMode.HighlightFeed">
          <merc-voice-call-header></merc-voice-call-header>
          <merc-highlight-feed class="merc-highlight-feed-wrapper"></merc-highlight-feed>
        </ng-container>
        <ng-container *ngSwitchCase="singleColumnHomeDisplayMode.LiveTranscript">
          <merc-live-transcript-header [shouldShowExpandCollapse]="false"></merc-live-transcript-header>
          <merc-live-transcript class="merc-live-transcript-wrapper"></merc-live-transcript>
        </ng-container>
        <ng-container *ngSwitchCase="singleColumnHomeDisplayMode.SessionSummary">
          <div class="merc-session-summary">
            <merc-session-summary></merc-session-summary>
          </div>
        </ng-container>
      </div>
      <div class="merc-single-column-display-buttons">
        <cee-button-v2
          type="secondary"
          [customClass]="data.showSessionSummaryDrawer ? 'merc-user-voice-button merc-user-voice-button--top' : 'merc-user-voice-button'"
          [tooltipText]="getSingleColumnButtonTooltipText(singleColumnHomeDisplayMode.LiveTranscript, data.singleColumnHomeDisplayMode)"
          tooltipId="single-column-transcript-button"
          tooltipDirection="left"
          tooltipAppearanceDelayMs="0"
          tooltipWidth="125"
          (buttonClick)="toggleSingleColumnDisplayMode(singleColumnHomeDisplayMode.LiveTranscript, data.singleColumnHomeDisplayMode)"
          dataQa="toggle-live-transcript"
          *ngIf="getSingleColumnButtonIcon(singleColumnHomeDisplayMode.LiveTranscript, data.singleColumnHomeDisplayMode) as buttonIcon">
          <svg class="cee-icon cee-icon--md" [ngClass]="'cee-icon--' + buttonIcon">
            <use
              [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-' + buttonIcon">
            </use>
          </svg>
        </cee-button-v2>
        <cee-button-v2
          *ngIf="data.showSessionSummaryDrawer"
          type="secondary"
          customClass="merc-user-voice-button merc-user-voice-button--bottom"
          [tooltipText]="getSingleColumnButtonTooltipText(singleColumnHomeDisplayMode.SessionSummary, data.singleColumnHomeDisplayMode)"
          tooltipId="single-column-summary-button"
          tooltipDirection="left"
          tooltipAppearanceDelayMs="0"
          tooltipWidth="135"
          (buttonClick)="toggleSingleColumnDisplayMode(singleColumnHomeDisplayMode.SessionSummary, data.singleColumnHomeDisplayMode)"
          dataQa="toggle-session-summary">
          <ng-container *ngIf="getSingleColumnButtonIcon(singleColumnHomeDisplayMode.SessionSummary, data.singleColumnHomeDisplayMode) as buttonIcon">
            <svg class="cee-icon cee-icon--md" [ngClass]="'cee-icon--' + buttonIcon">
              <use
                [attr.xlink:href]="'/assets/img/svg-sprite.svg#icon-' + buttonIcon">
              </use>
            </svg>
          </ng-container>
        </cee-button-v2>
      </div>
    </section>
    <merc-voice-footer></merc-voice-footer>

    <merc-close-call-modal *ngIf="data.showSessionSummaryModal"></merc-close-call-modal>
  </ng-container>

  <ng-template #unauthorized>
    <div class="merc-voice-unauthorized">
      <svg class="cee-icon">
        <use xlink:href="/assets/img/svg-sprite.svg#icon-lock"></use>
      </svg>
      <span class="merc-voice-unauthorized-message">
        Your account does not have access to Mercury for Voice. If this is a mistake, please contact an administrator.
      </span>
    </div>
  </ng-template>

</merc-page-initialization>
